export const DANE = {
  Legnica: {
    procentyWOkreguSrednia: {
      PIS: 36.798884998381105,
      KO: 28.100927428309255,
      TD: 12.15845782380587,
      KONF: 8.584416173599344,
      LEWICA: 14.35731357590443,
    },
    odchylenieWOkregu: {
      PIS: 1.3442724727779847,
      KO: 1.8214850031222718,
      TD: 2.013774734692939,
      LEWICA: 1.5159901104486262,
      KONF: 0.5853467600292002,
    },
    wykresRozkladu: {
      "40.9%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "32.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "11.7%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "8.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "4.1%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "2.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "0%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "81.5%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
      "18%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "0.4%": {
        DEMO: 5,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "34": {
          "4": 1524,
          "5": 88,
        },
        "35": {
          "4": 3325,
          "5": 1772,
        },
        "36": {
          "4": 1715,
          "5": 7887,
        },
        "37": {
          "4": 272,
          "5": 10654,
        },
        "38": {
          "4": 4,
          "5": 7722,
        },
        "39": {
          "5": 3463,
          "6": 3,
        },
        "40": {
          "5": 981,
          "6": 71,
        },
        "36.4": {
          "4": 950,
          "5": 9754,
        },
        "40.4": {
          "5": 511,
          "6": 70,
        },
        "34.8": {
          "4": 3124,
          "5": 1176,
        },
        "38.8": {
          "5": 4163,
        },
        "38.6": {
          "5": 4947,
        },
        "36.2": {
          "4": 1278,
          "5": 8900,
        },
        "37.2": {
          "4": 157,
          "5": 10714,
        },
        "35.4": {
          "4": 2964,
          "5": 4185,
        },
        "37.4": {
          "4": 71,
          "5": 9814,
        },
        "35.8": {
          "4": 2124,
          "5": 6716,
        },
        "36.8": {
          "4": 408,
          "5": 10454,
        },
        "35.6": {
          "4": 2550,
          "5": 5455,
        },
        "36.6": {
          "4": 658,
          "5": 10421,
        },
        "38.2": {
          "5": 6765,
        },
        "37.6": {
          "4": 35,
          "5": 9244,
        },
        "35.2": {
          "4": 3217,
          "5": 2825,
        },
        "39.2": {
          "5": 2885,
          "6": 13,
        },
        "37.8": {
          "4": 13,
          "5": 8442,
        },
        "34.6": {
          "4": 2837,
          "5": 620,
        },
        "38.4": {
          "5": 5888,
        },
        "34.4": {
          "4": 2292,
          "5": 336,
        },
        "39.6": {
          "5": 1764,
          "6": 45,
        },
        "39.4": {
          "5": 2277,
          "6": 18,
        },
        "40.6": {
          "5": 357,
          "6": 77,
        },
        "34.2": {
          "4": 1928,
          "5": 165,
        },
        "33.4": {
          "4": 1758,
          "5": 9,
        },
        "39.8": {
          "5": 1332,
          "6": 58,
        },
        "40.2": {
          "5": 683,
          "6": 89,
        },
        "33.8": {
          "4": 1070,
          "5": 26,
        },
        "40.8": {
          "5": 686,
          "6": 335,
        },
        "33.6": {
          "4": 848,
          "5": 18,
        },
      },
      KO: {
        "24": {
          "3": 334,
        },
        "25": {
          "3": 1348,
        },
        "26": {
          "3": 4094,
          "4": 4,
        },
        "27": {
          "3": 7321,
          "4": 660,
        },
        "28": {
          "3": 6693,
          "4": 3709,
        },
        "29": {
          "3": 1587,
          "4": 7189,
        },
        "30": {
          "3": 53,
          "4": 4712,
        },
        "31": {
          "4": 1743,
        },
        "32": {
          "4": 449,
        },
        "33": {
          "4": 63,
          "5": 2,
        },
        "28.2": {
          "3": 5520,
          "4": 4760,
        },
        "26.8": {
          "3": 6981,
          "4": 397,
        },
        "27.8": {
          "3": 7340,
          "4": 2736,
        },
        "29.8": {
          "3": 151,
          "4": 5594,
        },
        "27.6": {
          "3": 7884,
          "4": 2088,
        },
        "26.6": {
          "3": 6143,
          "4": 184,
        },
        "27.4": {
          "3": 7808,
          "4": 1449,
        },
        "29.4": {
          "3": 572,
          "4": 6544,
        },
        "30.6": {
          "4": 2852,
        },
        "28.6": {
          "3": 3366,
          "4": 6412,
        },
        "28.4": {
          "3": 4382,
          "4": 5786,
        },
        "25.6": {
          "3": 2761,
        },
        "29.2": {
          "3": 945,
          "4": 7024,
        },
        "30.2": {
          "3": 20,
          "4": 4053,
        },
        "26.2": {
          "3": 4833,
          "4": 25,
        },
        "26.4": {
          "3": 5628,
          "4": 112,
        },
        "25.2": {
          "3": 1793,
        },
        "27.2": {
          "3": 7696,
          "4": 1028,
        },
        "24.6": {
          "3": 793,
        },
        "29.6": {
          "3": 290,
          "4": 6178,
        },
        "25.4": {
          "3": 2213,
        },
        "25.8": {
          "3": 3357,
        },
        "31.2": {
          "4": 1401,
        },
        "24.4": {
          "3": 587,
        },
        "28.8": {
          "3": 2362,
          "4": 6857,
        },
        "30.4": {
          "3": 5,
          "4": 3433,
        },
        "30.8": {
          "4": 2244,
        },
        "23.8": {
          "3": 192,
        },
        "23.6": {
          "2": 3,
          "3": 431,
        },
        "31.6": {
          "4": 751,
        },
        "31.4": {
          "4": 1061,
        },
        "24.2": {
          "3": 456,
        },
        "24.8": {
          "3": 1062,
        },
        "31.8": {
          "4": 626,
        },
        "32.6": {
          "4": 186,
        },
        "33.6": {
          "4": 31,
          "5": 3,
        },
        "32.4": {
          "4": 211,
        },
        "32.2": {
          "4": 269,
        },
        "32.8": {
          "4": 91,
          "5": 1,
        },
        "33.2": {
          "4": 48,
          "5": 1,
        },
        "33.4": {
          "4": 29,
        },
      },
      TD: {
        "8": {
          "1": 666,
        },
        "9": {
          "1": 1945,
        },
        "10": {
          "1": 4250,
        },
        "11": {
          "1": 6910,
        },
        "12": {
          "1": 8790,
        },
        "13": {
          "1": 7940,
          "2": 3,
        },
        "14": {
          "1": 3266,
          "2": 2063,
        },
        "15": {
          "1": 6,
          "2": 2517,
        },
        "16": {
          "2": 813,
        },
        "17": {
          "2": 189,
        },
        "18": {
          "2": 32,
        },
        "12.6": {
          "1": 8675,
        },
        "12.4": {
          "1": 8898,
        },
        "11.6": {
          "1": 8344,
        },
        "15.6": {
          "2": 1392,
        },
        "14.2": {
          "1": 1715,
          "2": 3099,
        },
        "9.8": {
          "1": 3685,
        },
        "12.8": {
          "1": 8558,
        },
        "13.2": {
          "1": 7557,
          "2": 69,
        },
        "10.8": {
          "1": 6669,
        },
        "11.4": {
          "1": 8122,
        },
        "11.2": {
          "1": 7456,
        },
        "12.2": {
          "1": 8910,
        },
        "13.6": {
          "1": 6034,
          "2": 509,
        },
        "11.8": {
          "1": 8668,
        },
        "13.4": {
          "1": 6755,
          "2": 237,
        },
        "14.6": {
          "1": 180,
          "2": 3313,
        },
        "13.8": {
          "1": 4808,
          "2": 1108,
        },
        "14.8": {
          "1": 30,
          "2": 3008,
        },
        "8.8": {
          "1": 1683,
        },
        "9.2": {
          "1": 2285,
        },
        "10.4": {
          "1": 5358,
        },
        "14.4": {
          "1": 603,
          "2": 3491,
        },
        "10.6": {
          "1": 5935,
        },
        "9.4": {
          "1": 2808,
        },
        "10.2": {
          "1": 4859,
        },
        "9.6": {
          "1": 3196,
        },
        "8.4": {
          "1": 1093,
        },
        "8.6": {
          "1": 1346,
        },
        "15.8": {
          "2": 1043,
        },
        "7.2": {
          "0": 763,
          "1": 182,
        },
        "17.6": {
          "2": 74,
        },
        "16.4": {
          "2": 512,
        },
        "15.2": {
          "1": 2,
          "2": 2041,
        },
        "7.4": {
          "0": 46,
          "1": 286,
        },
        "15.4": {
          "2": 1719,
        },
        "16.8": {
          "2": 291,
        },
        "7.8": {
          "1": 574,
        },
        "8.2": {
          "1": 807,
        },
        "16.2": {
          "2": 653,
        },
        "7.6": {
          "0": 4,
          "1": 444,
        },
        "16.6": {
          "2": 359,
        },
        "17.4": {
          "2": 103,
        },
        "18.2": {
          "2": 61,
        },
        "17.2": {
          "2": 150,
        },
        "17.8": {
          "2": 40,
        },
      },
      KONF: {
        "8": {
          "1": 16754,
        },
        "9": {
          "1": 20643,
        },
        "10": {
          "1": 1883,
        },
        "8.8": {
          "1": 24269,
        },
        "9.6": {
          "1": 6652,
        },
        "8.2": {
          "1": 21786,
        },
        "8.4": {
          "1": 25035,
        },
        "8.6": {
          "1": 26021,
        },
        "7.4": {
          "0": 53,
          "1": 3769,
        },
        "9.2": {
          "1": 15327,
        },
        "7.8": {
          "1": 11504,
        },
        "7.6": {
          "0": 1,
          "1": 7160,
        },
        "9.8": {
          "1": 3692,
        },
        "9.4": {
          "1": 10746,
        },
        "10.2": {
          "1": 922,
        },
        "7.2": {
          "0": 1054,
          "1": 2058,
        },
        "10.4": {
          "1": 671,
        },
      },
      LEWICA: {
        "11": {
          "1": 588,
        },
        "12": {
          "1": 2735,
        },
        "13": {
          "1": 7082,
          "2": 8,
        },
        "14": {
          "1": 6874,
          "2": 4367,
        },
        "15": {
          "1": 67,
          "2": 10440,
        },
        "16": {
          "2": 5671,
        },
        "17": {
          "2": 1864,
        },
        "18": {
          "2": 343,
        },
        "19": {
          "2": 82,
        },
        "12.6": {
          "1": 5023,
        },
        "16.4": {
          "2": 3824,
        },
        "15.2": {
          "1": 7,
          "2": 9676,
        },
        "11.2": {
          "1": 859,
        },
        "15.4": {
          "2": 8749,
        },
        "12.2": {
          "1": 3405,
        },
        "17.2": {
          "2": 1364,
        },
        "15.6": {
          "2": 7745,
        },
        "14.8": {
          "1": 323,
          "2": 10754,
        },
        "13.2": {
          "1": 8083,
          "2": 94,
        },
        "13.8": {
          "1": 8498,
          "2": 2264,
        },
        "14.2": {
          "1": 4602,
          "2": 6694,
        },
        "13.4": {
          "1": 8665,
          "2": 394,
        },
        "14.4": {
          "1": 2485,
          "2": 9100,
        },
        "11.6": {
          "1": 1642,
        },
        "13.6": {
          "1": 8803,
          "2": 1077,
        },
        "12.8": {
          "1": 6183,
        },
        "14.6": {
          "1": 999,
          "2": 10438,
        },
        "16.8": {
          "2": 2354,
        },
        "15.8": {
          "2": 6581,
        },
        "12.4": {
          "1": 4147,
        },
        "16.6": {
          "2": 3012,
        },
        "11.8": {
          "1": 2127,
        },
        "16.2": {
          "2": 4877,
        },
        "17.4": {
          "2": 935,
        },
        "10.6": {
          "1": 843,
        },
        "17.6": {
          "2": 692,
        },
        "11.4": {
          "1": 1145,
        },
        "18.4": {
          "2": 143,
        },
        "17.8": {
          "2": 456,
        },
        "10.8": {
          "1": 440,
        },
        "18.8": {
          "2": 65,
        },
        "18.2": {
          "2": 210,
        },
        "18.6": {
          "2": 102,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 3140,
      TD: 2264,
      LEWICA: 6650,
    },
    potencjalneMandaty: {
      KO: 71499,
      TD: 48170,
      LEWICA: 74617,
    },
    ostatnieMandaty: {
      KO: 59610,
      TD: 14618,
      LEWICA: 42645,
    },
    dawcyGlosow: {
      KO: 6978,
      TD: 4916,
      LEWICA: 15217,
    },
  },
  Wałbrzych: {
    procentyWOkreguSrednia: {
      PIS: 34.21263534574462,
      KO: 36.03577402667704,
      TD: 11.186326372368024,
      KONF: 7.497731047044817,
      LEWICA: 11.067533208165514,
    },
    odchylenieWOkregu: {
      PIS: 2.0460104798768386,
      KO: 3.373002521383525,
      TD: 2.013774734692939,
      LEWICA: 1.2617034690109885,
      KONF: 0.8506170147620943,
    },
    wykresRozkladu: {
      "74%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "12.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 0,
      },
      "6.4%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 1,
      },
      "4.5%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "1.6%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.7%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 1,
      },
      "0.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "92.6%": {
        DEMO: 5,
        NIEDEMO: 3,
      },
      "6.7%": {
        DEMO: 4,
        NIEDEMO: 4,
      },
      "0.7%": {
        DEMO: 6,
        NIEDEMO: 2,
      },
    },
    histogram: {
      PIS: {
        "29": {
          "2": 529,
          "3": 211,
        },
        "30": {
          "2": 118,
          "3": 634,
        },
        "31": {
          "2": 5,
          "3": 2088,
        },
        "32": {
          "2": 1,
          "3": 4405,
        },
        "33": {
          "3": 6722,
        },
        "34": {
          "3": 8039,
          "4": 2,
        },
        "35": {
          "3": 7335,
          "4": 31,
        },
        "36": {
          "3": 4906,
          "4": 383,
        },
        "37": {
          "3": 2183,
          "4": 737,
        },
        "38": {
          "3": 734,
          "4": 587,
        },
        "39": {
          "3": 184,
          "4": 313,
        },
        "40": {
          "3": 24,
          "4": 136,
        },
        "34.6": {
          "3": 7812,
          "4": 16,
        },
        "34.2": {
          "3": 8078,
          "4": 4,
        },
        "35.2": {
          "3": 7025,
          "4": 75,
        },
        "36.4": {
          "3": 3774,
          "4": 492,
        },
        "32.6": {
          "3": 5931,
        },
        "32.4": {
          "3": 5450,
        },
        "35.4": {
          "3": 6700,
          "4": 111,
        },
        "33.6": {
          "3": 7653,
        },
        "36.2": {
          "3": 4378,
          "4": 450,
        },
        "35.8": {
          "3": 5365,
          "4": 225,
        },
        "32.2": {
          "2": 1,
          "3": 4949,
        },
        "35.6": {
          "3": 6039,
          "4": 153,
        },
        "33.8": {
          "3": 7815,
          "4": 2,
        },
        "37.4": {
          "3": 1463,
          "4": 767,
        },
        "34.4": {
          "3": 8028,
          "4": 5,
        },
        "33.4": {
          "3": 7611,
        },
        "38.2": {
          "3": 567,
          "4": 527,
        },
        "30.6": {
          "2": 32,
          "3": 1374,
        },
        "30.4": {
          "2": 64,
          "3": 1119,
        },
        "31.4": {
          "2": 2,
          "3": 2886,
        },
        "31.8": {
          "3": 3857,
        },
        "33.2": {
          "3": 7105,
        },
        "34.8": {
          "3": 7695,
          "4": 22,
        },
        "36.6": {
          "3": 3137,
          "4": 641,
        },
        "31.6": {
          "3": 3332,
        },
        "36.8": {
          "3": 2724,
          "4": 666,
        },
        "37.2": {
          "3": 1843,
          "4": 771,
        },
        "32.8": {
          "3": 6427,
        },
        "31.2": {
          "2": 1,
          "3": 2473,
        },
        "37.8": {
          "3": 925,
          "4": 702,
        },
        "30.8": {
          "2": 17,
          "3": 1799,
        },
        "29.8": {
          "2": 139,
          "3": 469,
        },
        "38.8": {
          "3": 225,
          "4": 381,
        },
        "38.6": {
          "3": 342,
          "4": 425,
        },
        "37.6": {
          "3": 1112,
          "4": 780,
        },
        "30.2": {
          "2": 83,
          "3": 892,
        },
        "39.2": {
          "3": 136,
          "4": 284,
        },
        "40.2": {
          "3": 19,
          "4": 90,
        },
        "38.4": {
          "3": 459,
          "4": 495,
        },
        "39.8": {
          "3": 47,
          "4": 184,
        },
        "29.4": {
          "2": 131,
          "3": 218,
        },
        "39.4": {
          "3": 100,
          "4": 244,
        },
        "29.2": {
          "2": 116,
          "3": 140,
        },
        "39.6": {
          "3": 57,
          "4": 196,
        },
        "40.4": {
          "3": 12,
          "4": 390,
        },
        "29.6": {
          "2": 130,
          "3": 317,
        },
      },
      KO: {
        "28": {
          "2": 27,
          "3": 20,
        },
        "29": {
          "2": 38,
          "3": 93,
        },
        "30": {
          "2": 10,
          "3": 375,
        },
        "31": {
          "2": 1,
          "3": 908,
        },
        "32": {
          "2": 1,
          "3": 1733,
        },
        "33": {
          "3": 3008,
        },
        "34": {
          "3": 4502,
          "4": 1,
        },
        "35": {
          "3": 5754,
          "4": 36,
        },
        "36": {
          "3": 6164,
          "4": 425,
        },
        "37": {
          "3": 4756,
          "4": 1317,
        },
        "38": {
          "3": 2940,
          "4": 1811,
        },
        "39": {
          "3": 1332,
          "4": 1727,
        },
        "40": {
          "3": 369,
          "4": 1370,
        },
        "41": {
          "3": 31,
          "4": 779,
        },
        "42": {
          "3": 1,
          "4": 304,
        },
        "43": {
          "4": 87,
          "5": 1,
        },
        "44": {
          "4": 20,
          "5": 2,
        },
        "45": {
          "4": 4,
          "5": 3,
        },
        "36.6": {
          "3": 5512,
          "4": 917,
        },
        "36.8": {
          "3": 5158,
          "4": 1040,
        },
        "35.2": {
          "3": 6087,
          "4": 63,
        },
        "33.6": {
          "3": 3871,
        },
        "33.8": {
          "3": 4220,
        },
        "35.6": {
          "3": 6317,
          "4": 197,
        },
        "30.8": {
          "2": 3,
          "3": 703,
        },
        "39.4": {
          "3": 864,
          "4": 1625,
        },
        "34.8": {
          "3": 5670,
          "4": 18,
        },
        "32.8": {
          "3": 2648,
        },
        "41.2": {
          "3": 13,
          "4": 649,
        },
        "36.2": {
          "3": 5882,
          "4": 545,
        },
        "34.4": {
          "3": 5035,
          "4": 2,
        },
        "38.6": {
          "3": 2029,
          "4": 1809,
        },
        "35.8": {
          "3": 6102,
          "4": 286,
        },
        "40.4": {
          "3": 205,
          "4": 1139,
        },
        "34.6": {
          "3": 5404,
          "4": 7,
        },
        "39.8": {
          "3": 554,
          "4": 1501,
        },
        "37.8": {
          "3": 3396,
          "4": 1751,
        },
        "33.2": {
          "3": 3208,
        },
        "37.2": {
          "3": 4446,
          "4": 1415,
        },
        "32.6": {
          "2": 1,
          "3": 2421,
        },
        "38.4": {
          "3": 2320,
          "4": 1838,
        },
        "40.2": {
          "3": 251,
          "4": 1185,
        },
        "38.8": {
          "3": 1679,
          "4": 1750,
        },
        "39.2": {
          "3": 1123,
          "4": 1734,
        },
        "38.2": {
          "3": 2594,
          "4": 1857,
        },
        "31.8": {
          "2": 1,
          "3": 1498,
        },
        "37.6": {
          "3": 3656,
          "4": 1732,
        },
        "37.4": {
          "3": 4081,
          "4": 1548,
        },
        "36.4": {
          "3": 5809,
          "4": 760,
        },
        "35.4": {
          "3": 6068,
          "4": 114,
        },
        "34.2": {
          "3": 4718,
          "4": 4,
        },
        "41.8": {
          "3": 1,
          "4": 367,
        },
        "31.6": {
          "2": 2,
          "3": 1379,
        },
        "39.6": {
          "3": 727,
          "4": 1636,
        },
        "31.2": {
          "2": 4,
          "3": 965,
        },
        "41.4": {
          "3": 6,
          "4": 523,
        },
        "33.4": {
          "3": 3604,
        },
        "32.2": {
          "3": 1981,
        },
        "32.4": {
          "3": 2150,
        },
        "40.8": {
          "3": 62,
          "4": 914,
        },
        "40.6": {
          "3": 103,
          "4": 976,
        },
        "30.6": {
          "2": 4,
          "3": 618,
        },
        "27.6": {
          "2": 121,
          "3": 8,
        },
        "29.8": {
          "2": 19,
          "3": 278,
        },
        "28.6": {
          "2": 40,
          "3": 58,
        },
        "29.6": {
          "2": 27,
          "3": 237,
        },
        "30.4": {
          "2": 5,
          "3": 501,
        },
        "31.4": {
          "2": 3,
          "3": 1153,
        },
        "30.2": {
          "2": 11,
          "3": 458,
        },
        "29.4": {
          "2": 34,
          "3": 171,
        },
        "28.2": {
          "2": 39,
          "3": 26,
        },
        "42.6": {
          "4": 147,
        },
        "41.6": {
          "3": 1,
          "4": 433,
        },
        "28.8": {
          "2": 28,
          "3": 75,
        },
        "42.2": {
          "4": 229,
        },
        "42.4": {
          "4": 225,
        },
        "42.8": {
          "4": 116,
          "5": 2,
        },
        "43.8": {
          "4": 32,
          "5": 2,
        },
        "29.2": {
          "2": 31,
          "3": 132,
        },
        "43.2": {
          "4": 76,
          "5": 2,
        },
        "44.6": {
          "4": 12,
        },
        "28.4": {
          "2": 43,
          "3": 38,
        },
        "43.6": {
          "4": 39,
          "5": 3,
        },
        "43.4": {
          "4": 49,
          "5": 2,
        },
        "27.8": {
          "2": 38,
          "3": 10,
        },
        "46.2": {
          "5": 3,
        },
        "44.2": {
          "4": 17,
          "5": 3,
        },
        "44.4": {
          "4": 7,
          "5": 1,
        },
        "45.2": {
          "4": 2,
        },
        "44.8": {
          "4": 3,
        },
        "45.4": {
          "4": 2,
        },
        "45.6": {
          "4": 2,
        },
        "45.8": {
          "4": 1,
        },
      },
      TD: {
        "7": {
          "0": 759,
        },
        "8": {
          "0": 1970,
        },
        "9": {
          "0": 3875,
          "1": 507,
        },
        "10": {
          "0": 271,
          "1": 6679,
        },
        "11": {
          "1": 8517,
        },
        "12": {
          "1": 7927,
        },
        "13": {
          "1": 5330,
        },
        "14": {
          "1": 2702,
        },
        "15": {
          "1": 988,
        },
        "16": {
          "1": 272,
        },
        "17": {
          "1": 56,
        },
        "12.8": {
          "1": 5979,
        },
        "9.6": {
          "0": 1383,
          "1": 4488,
        },
        "10.4": {
          "0": 5,
          "1": 7912,
        },
        "13.4": {
          "1": 4321,
        },
        "9.8": {
          "0": 726,
          "1": 5643,
        },
        "14.6": {
          "1": 1537,
        },
        "8.2": {
          "0": 2393,
        },
        "11.2": {
          "1": 8644,
        },
        "14.8": {
          "1": 1260,
        },
        "8.4": {
          "0": 2729,
        },
        "11.6": {
          "1": 8403,
        },
        "10.2": {
          "0": 62,
          "1": 7337,
        },
        "13.2": {
          "1": 4725,
        },
        "8.6": {
          "0": 3237,
          "1": 8,
        },
        "12.6": {
          "1": 6318,
        },
        "11.4": {
          "1": 8641,
        },
        "6.6": {
          "0": 453,
        },
        "9.2": {
          "0": 3182,
          "1": 1491,
        },
        "13.6": {
          "1": 3679,
        },
        "8.8": {
          "0": 3682,
          "1": 82,
        },
        "14.4": {
          "1": 1858,
        },
        "10.6": {
          "1": 8178,
        },
        "14.2": {
          "1": 2271,
        },
        "9.4": {
          "0": 2355,
          "1": 3012,
        },
        "11.8": {
          "1": 8205,
        },
        "12.2": {
          "1": 7446,
        },
        "7.6": {
          "0": 1370,
        },
        "12.4": {
          "1": 7133,
        },
        "7.8": {
          "0": 1649,
        },
        "13.8": {
          "1": 3235,
        },
        "15.6": {
          "1": 440,
        },
        "6.2": {
          "0": 1071,
        },
        "10.8": {
          "0": 1,
          "1": 8332,
        },
        "7.2": {
          "0": 930,
        },
        "15.2": {
          "1": 768,
        },
        "6.4": {
          "0": 302,
        },
        "6.8": {
          "0": 561,
        },
        "15.4": {
          "1": 616,
        },
        "7.4": {
          "0": 1116,
        },
        "15.8": {
          "1": 364,
        },
        "16.2": {
          "1": 199,
        },
        "16.6": {
          "1": 104,
        },
        "16.4": {
          "1": 131,
        },
        "16.8": {
          "1": 69,
        },
        "17.2": {
          "1": 107,
          "2": 4,
        },
      },
      KONF: {
        "6": {
          "0": 3993,
        },
        "7": {
          "0": 15756,
        },
        "8": {
          "0": 15425,
        },
        "9": {
          "0": 3793,
          "1": 174,
        },
        "10": {
          "0": 208,
          "1": 514,
        },
        "8.4": {
          "0": 10474,
        },
        "8.2": {
          "0": 13160,
        },
        "9.2": {
          "0": 2444,
          "1": 340,
        },
        "6.6": {
          "0": 10976,
        },
        "7.8": {
          "0": 17427,
        },
        "6.8": {
          "0": 13600,
        },
        "6.2": {
          "0": 5951,
        },
        "6.4": {
          "0": 8229,
        },
        "8.8": {
          "0": 5785,
          "1": 33,
        },
        "7.6": {
          "0": 18459,
        },
        "9.4": {
          "0": 1397,
          "1": 390,
        },
        "7.2": {
          "0": 17650,
        },
        "7.4": {
          "0": 18282,
        },
        "5.6": {
          "0": 1592,
        },
        "5.8": {
          "0": 2463,
        },
        "5.4": {
          "0": 1795,
        },
        "8.6": {
          "0": 8036,
          "1": 4,
        },
        "9.8": {
          "0": 301,
          "1": 323,
        },
        "9.6": {
          "0": 629,
          "1": 397,
        },
      },
      LEWICA: {
        "8": {
          "0": 1422,
        },
        "9": {
          "0": 2705,
          "1": 573,
        },
        "10": {
          "0": 265,
          "1": 8569,
        },
        "11": {
          "1": 12820,
        },
        "12": {
          "1": 9791,
        },
        "13": {
          "1": 3794,
        },
        "14": {
          "1": 823,
        },
        "9.2": {
          "0": 2457,
          "1": 1641,
        },
        "10.2": {
          "0": 46,
          "1": 10114,
        },
        "12.8": {
          "1": 4817,
        },
        "11.8": {
          "1": 10821,
        },
        "11.2": {
          "1": 12804,
        },
        "11.4": {
          "1": 12732,
        },
        "10.6": {
          "0": 1,
          "1": 12121,
        },
        "9.8": {
          "0": 665,
          "1": 6859,
        },
        "11.6": {
          "1": 11758,
        },
        "13.2": {
          "1": 2956,
        },
        "12.6": {
          "1": 5866,
        },
        "10.8": {
          "1": 12559,
        },
        "9.4": {
          "0": 1976,
          "1": 3211,
        },
        "12.4": {
          "1": 7325,
        },
        "12.2": {
          "1": 8498,
        },
        "13.4": {
          "1": 2178,
        },
        "8.4": {
          "0": 1146,
          "1": 1,
        },
        "8.6": {
          "0": 1676,
          "1": 15,
        },
        "13.6": {
          "1": 1590,
        },
        "9.6": {
          "0": 1308,
          "1": 5001,
        },
        "10.4": {
          "0": 5,
          "1": 11166,
        },
        "8.8": {
          "0": 2155,
          "1": 140,
        },
        "14.4": {
          "1": 376,
        },
        "14.2": {
          "1": 552,
        },
        "14.8": {
          "1": 394,
        },
        "13.8": {
          "1": 1220,
        },
        "8.2": {
          "0": 880,
        },
        "14.6": {
          "1": 208,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2393,
      TD: 7519,
      LEWICA: 10584,
    },
    potencjalneMandaty: {
      KO: 35547,
      TD: 33041,
      LEWICA: 22830,
    },
    ostatnieMandaty: {
      KO: 48337,
      TD: 44542,
      LEWICA: 55636,
    },
    dawcyGlosow: {
      KO: 6163,
      TD: 19592,
      LEWICA: 26554,
    },
  },
  Wrocław: {
    procentyWOkreguSrednia: {
      PIS: 28.36343647802033,
      KO: 35.70917660727912,
      TD: 10.946870075138186,
      KONF: 9.539453584839212,
      LEWICA: 15.44106325472315,
    },
    odchylenieWOkregu: {
      PIS: 2.329163673580161,
      KO: 1.77942955222598,
      TD: 2.013774734692939,
      LEWICA: 2.537628065793719,
      KONF: 1.4360461521433758,
    },
    wykresRozkladu: {
      "36.3%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "22.7%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "19.7%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "11.3%": {
        KO: 5,
        LEWICA: 3,
        NIEDEMO: 5,
        TD: 1,
      },
      "3.1%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "2.4%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "1.2%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "1.1%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.6%": {
        KO: 6,
        LEWICA: 3,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.5%": {
        KO: 5,
        LEWICA: 3,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.3%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.2%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "73.1%": {
        DEMO: 9,
        NIEDEMO: 5,
      },
      "24.5%": {
        DEMO: 8,
        NIEDEMO: 6,
      },
      "2.2%": {
        DEMO: 10,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "23": {
          "3": 194,
          "4": 10,
        },
        "24": {
          "3": 425,
          "4": 355,
        },
        "25": {
          "3": 193,
          "4": 1794,
        },
        "26": {
          "3": 8,
          "4": 4106,
        },
        "27": {
          "4": 6278,
        },
        "28": {
          "4": 7767,
          "5": 4,
        },
        "29": {
          "4": 6843,
          "5": 773,
        },
        "30": {
          "4": 2902,
          "5": 2691,
        },
        "31": {
          "4": 527,
          "5": 2836,
        },
        "32": {
          "4": 33,
          "5": 1575,
        },
        "33": {
          "5": 623,
        },
        "34": {
          "5": 166,
          "6": 1,
        },
        "35": {
          "5": 32,
          "6": 4,
        },
        "29.4": {
          "4": 5417,
          "5": 1577,
        },
        "26.8": {
          "4": 5666,
        },
        "27.8": {
          "4": 7573,
        },
        "29.2": {
          "4": 6082,
          "5": 1147,
        },
        "27.2": {
          "4": 6810,
        },
        "30.8": {
          "4": 785,
          "5": 2983,
        },
        "28.8": {
          "4": 7367,
          "5": 434,
        },
        "28.6": {
          "4": 7515,
          "5": 252,
        },
        "24.6": {
          "3": 313,
          "4": 1083,
        },
        "29.8": {
          "4": 3736,
          "5": 2354,
        },
        "26.4": {
          "3": 1,
          "4": 4990,
        },
        "25.2": {
          "3": 113,
          "4": 2142,
        },
        "28.2": {
          "4": 7745,
          "5": 25,
        },
        "29.6": {
          "4": 4556,
          "5": 1922,
        },
        "23.6": {
          "3": 340,
          "4": 102,
        },
        "31.4": {
          "4": 198,
          "5": 2369,
        },
        "25.6": {
          "3": 32,
          "4": 3151,
        },
        "27.4": {
          "4": 7080,
        },
        "24.4": {
          "3": 337,
          "4": 759,
        },
        "24.2": {
          "3": 377,
          "4": 530,
        },
        "27.6": {
          "4": 7348,
        },
        "30.4": {
          "4": 1638,
          "5": 3173,
        },
        "26.2": {
          "3": 1,
          "4": 4403,
        },
        "31.2": {
          "4": 334,
          "5": 2720,
        },
        "26.6": {
          "4": 5471,
        },
        "30.2": {
          "4": 2395,
          "5": 2920,
        },
        "25.4": {
          "3": 75,
          "4": 2595,
        },
        "25.8": {
          "3": 21,
          "4": 3572,
        },
        "31.6": {
          "4": 104,
          "5": 2032,
        },
        "32.2": {
          "4": 16,
          "5": 1289,
        },
        "28.4": {
          "4": 7607,
          "5": 129,
        },
        "23.4": {
          "3": 323,
          "4": 43,
        },
        "31.8": {
          "4": 68,
          "5": 1832,
        },
        "33.2": {
          "5": 450,
        },
        "32.8": {
          "4": 1,
          "5": 779,
        },
        "32.6": {
          "4": 2,
          "5": 867,
        },
        "30.6": {
          "4": 1103,
          "5": 3021,
        },
        "23.8": {
          "3": 405,
          "4": 201,
        },
        "24.8": {
          "3": 220,
          "4": 1418,
        },
        "33.6": {
          "5": 305,
        },
        "22.6": {
          "3": 471,
        },
        "34.6": {
          "5": 74,
          "6": 4,
        },
        "32.4": {
          "4": 12,
          "5": 1079,
        },
        "22.8": {
          "3": 165,
          "4": 2,
        },
        "23.2": {
          "3": 292,
          "4": 24,
        },
        "33.4": {
          "5": 376,
        },
        "33.8": {
          "5": 221,
        },
        "34.2": {
          "5": 149,
          "6": 3,
        },
        "34.4": {
          "5": 92,
          "6": 1,
        },
        "35.4": {
          "5": 43,
          "6": 25,
        },
        "35.2": {
          "5": 26,
          "6": 5,
        },
        "34.8": {
          "5": 44,
          "6": 8,
        },
      },
      KO: {
        "32": {
          "4": 18,
          "5": 1088,
        },
        "33": {
          "4": 1,
          "5": 2977,
        },
        "34": {
          "5": 5570,
          "6": 80,
        },
        "35": {
          "5": 6531,
          "6": 1428,
        },
        "36": {
          "5": 4084,
          "6": 4251,
        },
        "37": {
          "5": 1356,
          "6": 5195,
        },
        "38": {
          "5": 186,
          "6": 3845,
        },
        "39": {
          "5": 11,
          "6": 1992,
        },
        "40": {
          "6": 751,
          "7": 20,
        },
        "41": {
          "6": 740,
          "7": 299,
        },
        "32.8": {
          "4": 1,
          "5": 2460,
        },
        "33.6": {
          "5": 4639,
          "6": 1,
        },
        "33.2": {
          "5": 3482,
        },
        "34.6": {
          "5": 6734,
          "6": 597,
        },
        "34.2": {
          "5": 6021,
          "6": 188,
        },
        "36.8": {
          "5": 1757,
          "6": 5123,
        },
        "35.8": {
          "5": 4732,
          "6": 3698,
        },
        "37.4": {
          "5": 692,
          "6": 4823,
        },
        "37.8": {
          "5": 303,
          "6": 4200,
        },
        "39.4": {
          "5": 1,
          "6": 1350,
          "7": 3,
        },
        "33.8": {
          "5": 5135,
          "6": 24,
        },
        "37.6": {
          "5": 450,
          "6": 4581,
        },
        "36.4": {
          "5": 2825,
          "6": 4948,
        },
        "34.4": {
          "5": 6489,
          "6": 389,
        },
        "35.2": {
          "5": 6355,
          "6": 1976,
        },
        "37.2": {
          "5": 973,
          "6": 5092,
        },
        "36.2": {
          "5": 3500,
          "6": 4572,
        },
        "38.6": {
          "5": 39,
          "6": 2661,
        },
        "35.4": {
          "5": 5830,
          "6": 2454,
        },
        "35.6": {
          "5": 5319,
          "6": 3039,
        },
        "34.8": {
          "5": 6752,
          "6": 1046,
        },
        "39.2": {
          "5": 4,
          "6": 1657,
          "7": 3,
        },
        "36.6": {
          "5": 2269,
          "6": 5019,
        },
        "38.2": {
          "5": 115,
          "6": 3411,
        },
        "38.4": {
          "5": 66,
          "6": 2889,
        },
        "31.8": {
          "4": 15,
          "5": 821,
        },
        "39.6": {
          "6": 1038,
          "7": 5,
        },
        "31.4": {
          "4": 41,
          "5": 499,
        },
        "32.6": {
          "4": 4,
          "5": 2059,
        },
        "33.4": {
          "5": 4037,
        },
        "40.2": {
          "6": 621,
          "7": 20,
        },
        "31.2": {
          "4": 355,
          "5": 1112,
        },
        "31.6": {
          "4": 31,
          "5": 621,
        },
        "32.4": {
          "4": 3,
          "5": 1755,
        },
        "32.2": {
          "4": 9,
          "5": 1453,
        },
        "40.6": {
          "6": 378,
          "7": 32,
        },
        "38.8": {
          "5": 12,
          "6": 2267,
        },
        "40.4": {
          "6": 485,
          "7": 21,
        },
        "39.8": {
          "6": 899,
          "7": 7,
        },
        "40.8": {
          "6": 255,
          "7": 35,
        },
      },
      TD: {
        "6": {
          "0": 972,
          "1": 161,
        },
        "7": {
          "1": 977,
        },
        "8": {
          "1": 2383,
        },
        "9": {
          "1": 4887,
        },
        "10": {
          "1": 7539,
        },
        "11": {
          "1": 8560,
        },
        "12": {
          "1": 3914,
          "2": 3624,
        },
        "13": {
          "1": 21,
          "2": 4780,
        },
        "14": {
          "2": 2179,
        },
        "15": {
          "2": 752,
        },
        "16": {
          "2": 170,
        },
        "17": {
          "2": 86,
          "3": 11,
        },
        "10.6": {
          "1": 8311,
        },
        "9.4": {
          "1": 6047,
        },
        "10.8": {
          "1": 8750,
        },
        "10.4": {
          "1": 8173,
        },
        "11.8": {
          "1": 5666,
          "2": 2019,
        },
        "8.8": {
          "1": 4381,
        },
        "13.8": {
          "2": 2627,
        },
        "7.8": {
          "1": 2020,
        },
        "9.6": {
          "1": 6572,
        },
        "11.6": {
          "1": 7410,
          "2": 883,
        },
        "10.2": {
          "1": 7839,
        },
        "14.6": {
          "2": 1170,
        },
        "15.4": {
          "2": 400,
        },
        "11.2": {
          "1": 8420,
          "2": 6,
        },
        "8.6": {
          "1": 3815,
        },
        "12.6": {
          "1": 359,
          "2": 5476,
        },
        "6.6": {
          "1": 625,
        },
        "9.2": {
          "1": 5525,
        },
        "11.4": {
          "1": 8232,
          "2": 196,
        },
        "12.8": {
          "1": 93,
          "2": 5053,
        },
        "9.8": {
          "1": 7083,
        },
        "12.2": {
          "1": 2073,
          "2": 4710,
        },
        "13.4": {
          "2": 3596,
        },
        "7.4": {
          "1": 1376,
        },
        "6.8": {
          "1": 715,
        },
        "13.2": {
          "1": 1,
          "2": 4142,
        },
        "12.4": {
          "1": 991,
          "2": 5360,
        },
        "7.2": {
          "1": 1152,
        },
        "8.2": {
          "1": 2853,
        },
        "14.8": {
          "2": 981,
        },
        "13.6": {
          "2": 3082,
        },
        "8.4": {
          "1": 3368,
        },
        "14.2": {
          "2": 1893,
        },
        "15.6": {
          "2": 342,
        },
        "6.4": {
          "0": 8,
          "1": 451,
        },
        "15.2": {
          "2": 570,
        },
        "6.2": {
          "0": 68,
          "1": 293,
        },
        "14.4": {
          "2": 1549,
        },
        "15.8": {
          "2": 226,
        },
        "7.6": {
          "1": 1685,
        },
        "16.2": {
          "2": 137,
        },
        "16.4": {
          "2": 97,
        },
        "16.8": {
          "2": 46,
        },
        "16.6": {
          "2": 68,
        },
      },
      KONF: {
        "6": {
          "0": 1080,
          "1": 196,
        },
        "7": {
          "1": 1993,
        },
        "8": {
          "1": 6229,
        },
        "9": {
          "1": 10786,
        },
        "10": {
          "1": 10912,
        },
        "11": {
          "1": 6531,
        },
        "12": {
          "1": 1217,
          "2": 989,
        },
        "13": {
          "1": 3,
          "2": 470,
        },
        "10.6": {
          "1": 8592,
        },
        "8.4": {
          "1": 8098,
        },
        "7.6": {
          "1": 4115,
        },
        "12.2": {
          "1": 602,
          "2": 1211,
        },
        "10.8": {
          "1": 7590,
        },
        "8.6": {
          "1": 9433,
        },
        "6.8": {
          "1": 1604,
        },
        "8.2": {
          "1": 7211,
        },
        "10.4": {
          "1": 9385,
        },
        "11.8": {
          "1": 2307,
          "2": 778,
        },
        "12.4": {
          "1": 233,
          "2": 1018,
        },
        "8.8": {
          "1": 10273,
        },
        "7.8": {
          "1": 5198,
        },
        "9.4": {
          "1": 11700,
        },
        "11.2": {
          "1": 5572,
          "2": 11,
        },
        "10.2": {
          "1": 10328,
        },
        "11.4": {
          "1": 4537,
          "2": 80,
        },
        "13.6": {
          "2": 146,
        },
        "9.2": {
          "1": 11399,
        },
        "9.6": {
          "1": 11626,
        },
        "9.8": {
          "1": 11642,
        },
        "6.6": {
          "0": 1,
          "1": 1252,
        },
        "7.2": {
          "1": 2726,
        },
        "7.4": {
          "1": 3417,
        },
        "6.2": {
          "0": 113,
          "1": 477,
        },
        "11.6": {
          "1": 3288,
          "2": 384,
        },
        "13.2": {
          "2": 334,
        },
        "12.6": {
          "1": 75,
          "2": 894,
        },
        "13.8": {
          "2": 251,
        },
        "6.4": {
          "0": 27,
          "1": 813,
        },
        "12.8": {
          "1": 15,
          "2": 613,
        },
        "13.4": {
          "2": 225,
        },
      },
      LEWICA: {
        "9": {
          "0": 12,
          "1": 662,
        },
        "10": {
          "1": 445,
        },
        "11": {
          "1": 1078,
        },
        "12": {
          "1": 1232,
          "2": 976,
        },
        "13": {
          "1": 15,
          "2": 3872,
        },
        "14": {
          "2": 5707,
        },
        "15": {
          "2": 6934,
        },
        "16": {
          "2": 6891,
        },
        "17": {
          "2": 5581,
          "3": 66,
        },
        "18": {
          "2": 1849,
          "3": 1870,
        },
        "19": {
          "2": 92,
          "3": 1835,
        },
        "20": {
          "3": 836,
        },
        "21": {
          "3": 263,
        },
        "22": {
          "3": 75,
        },
        "23": {
          "3": 36,
          "4": 4,
        },
        "15.8": {
          "2": 6943,
        },
        "19.2": {
          "2": 23,
          "3": 1645,
        },
        "21.6": {
          "3": 128,
        },
        "14.8": {
          "2": 6899,
        },
        "15.2": {
          "2": 7054,
        },
        "17.4": {
          "2": 4283,
          "3": 612,
        },
        "14.2": {
          "2": 6139,
        },
        "13.2": {
          "1": 1,
          "2": 4335,
        },
        "17.2": {
          "2": 5048,
          "3": 253,
        },
        "17.8": {
          "2": 2600,
          "3": 1486,
        },
        "12.4": {
          "1": 428,
          "2": 2477,
        },
        "16.2": {
          "2": 6917,
        },
        "13.4": {
          "2": 4646,
        },
        "12.6": {
          "1": 168,
          "2": 2882,
        },
        "18.6": {
          "2": 401,
          "3": 2205,
        },
        "12.8": {
          "1": 43,
          "2": 3460,
        },
        "12.2": {
          "1": 788,
          "2": 1691,
        },
        "16.8": {
          "2": 6019,
          "3": 8,
        },
        "20.4": {
          "3": 502,
        },
        "19.4": {
          "2": 11,
          "3": 1513,
        },
        "13.6": {
          "2": 5054,
        },
        "13.8": {
          "2": 5320,
        },
        "16.6": {
          "2": 6391,
        },
        "14.4": {
          "2": 6362,
        },
        "20.6": {
          "3": 455,
        },
        "17.6": {
          "2": 3485,
          "3": 1114,
        },
        "14.6": {
          "2": 6672,
        },
        "18.8": {
          "2": 177,
          "3": 2070,
        },
        "18.4": {
          "2": 691,
          "3": 2278,
        },
        "9.4": {
          "1": 220,
        },
        "11.2": {
          "1": 1197,
          "2": 1,
        },
        "15.4": {
          "2": 7196,
        },
        "16.4": {
          "2": 6555,
        },
        "15.6": {
          "2": 7099,
        },
        "11.8": {
          "1": 1432,
          "2": 415,
        },
        "20.8": {
          "3": 313,
        },
        "19.6": {
          "2": 2,
          "3": 1279,
        },
        "19.8": {
          "3": 1061,
        },
        "18.2": {
          "2": 1190,
          "3": 2095,
        },
        "11.4": {
          "1": 1417,
          "2": 19,
        },
        "10.2": {
          "1": 514,
        },
        "9.8": {
          "1": 337,
        },
        "10.6": {
          "1": 782,
        },
        "10.4": {
          "1": 642,
        },
        "10.8": {
          "1": 862,
        },
        "11.6": {
          "1": 1497,
          "2": 139,
        },
        "21.4": {
          "3": 157,
        },
        "21.2": {
          "3": 225,
        },
        "20.2": {
          "3": 649,
        },
        "9.2": {
          "1": 175,
        },
        "21.8": {
          "3": 105,
        },
        "9.6": {
          "1": 266,
        },
        "22.2": {
          "3": 66,
        },
        "22.6": {
          "3": 26,
        },
        "22.8": {
          "3": 16,
        },
        "22.4": {
          "3": 43,
        },
      },
    },
    minimumMandatow: {
      KO: 4,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 1557,
      TD: 2697,
      LEWICA: 2624,
    },
    potencjalneMandaty: {
      KO: 57614,
      TD: 54173,
      LEWICA: 41711,
    },
    ostatnieMandaty: {
      KO: 53406,
      TD: 31093,
      LEWICA: 23004,
    },
    dawcyGlosow: {
      KO: 4017,
      TD: 6189,
      LEWICA: 6996,
    },
  },
  Bydgoszcz: {
    procentyWOkreguSrednia: {
      PIS: 30.52641048207479,
      KO: 34.13893447702299,
      TD: 13.707724530260938,
      KONF: 8.117811407144098,
      LEWICA: 13.509119103497174,
    },
    odchylenieWOkregu: {
      PIS: 2.7644315689453682,
      KO: 4.213879560302323,
      TD: 2.013774734692939,
      LEWICA: 2.114326155090626,
      KONF: 1.1801901874716998,
    },
    wykresRozkladu: {
      "22.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "21.6%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "19.8%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "9.5%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "8.6%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "8.4%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "3.6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "2.8%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "1.2%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.9%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 2,
      },
      "0.5%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "66.5%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "27.7%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
      "4.7%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
    },
    histogram: {
      PIS: {
        "24": {
          "3": 185,
        },
        "25": {
          "3": 526,
        },
        "26": {
          "3": 1173,
          "4": 11,
        },
        "27": {
          "3": 2007,
          "4": 323,
        },
        "28": {
          "3": 1875,
          "4": 1964,
        },
        "29": {
          "3": 599,
          "4": 4767,
        },
        "30": {
          "3": 68,
          "4": 6103,
        },
        "31": {
          "4": 6405,
        },
        "32": {
          "4": 5097,
          "5": 24,
        },
        "33": {
          "4": 3693,
          "5": 197,
        },
        "34": {
          "4": 1651,
          "5": 715,
        },
        "35": {
          "4": 533,
          "5": 784,
        },
        "36": {
          "4": 90,
          "5": 546,
        },
        "37": {
          "4": 6,
          "5": 269,
        },
        "38": {
          "5": 90,
        },
        "35.4": {
          "4": 272,
          "5": 761,
        },
        "28.6": {
          "3": 1079,
          "4": 3784,
        },
        "31.4": {
          "4": 6001,
          "5": 1,
        },
        "29.6": {
          "3": 190,
          "4": 5765,
        },
        "32.2": {
          "4": 5085,
          "5": 41,
        },
        "28.8": {
          "3": 861,
          "4": 4239,
        },
        "27.2": {
          "3": 2155,
          "4": 530,
        },
        "33.2": {
          "4": 3238,
          "5": 281,
        },
        "29.4": {
          "3": 305,
          "4": 5484,
        },
        "31.8": {
          "4": 5554,
          "5": 12,
        },
        "23.8": {
          "3": 158,
        },
        "31.6": {
          "4": 5712,
          "5": 3,
        },
        "30.8": {
          "4": 6279,
        },
        "30.4": {
          "3": 4,
          "4": 6323,
        },
        "33.8": {
          "4": 2108,
          "5": 609,
        },
        "25.2": {
          "3": 642,
        },
        "30.2": {
          "3": 31,
          "4": 6238,
        },
        "26.6": {
          "3": 1814,
          "4": 109,
        },
        "27.8": {
          "3": 2012,
          "4": 1510,
        },
        "29.2": {
          "3": 439,
          "4": 5457,
        },
        "26.2": {
          "3": 1427,
          "4": 34,
        },
        "26.8": {
          "3": 1893,
          "4": 212,
        },
        "28.2": {
          "3": 1685,
          "4": 2487,
        },
        "34.2": {
          "4": 1398,
          "5": 727,
        },
        "31.2": {
          "4": 6130,
        },
        "32.4": {
          "4": 4747,
          "5": 53,
        },
        "29.8": {
          "3": 103,
          "4": 5905,
        },
        "35.8": {
          "4": 107,
          "5": 603,
        },
        "32.6": {
          "4": 4336,
          "5": 99,
        },
        "24.8": {
          "3": 432,
        },
        "32.8": {
          "4": 4135,
          "5": 131,
        },
        "35.6": {
          "4": 177,
          "5": 659,
        },
        "27.6": {
          "3": 2129,
          "4": 1059,
        },
        "28.4": {
          "3": 1374,
          "4": 3079,
        },
        "30.6": {
          "4": 6289,
        },
        "34.6": {
          "4": 873,
          "5": 823,
        },
        "35.2": {
          "4": 356,
          "5": 804,
        },
        "34.4": {
          "4": 1101,
          "5": 795,
        },
        "33.4": {
          "4": 2826,
          "5": 417,
        },
        "33.6": {
          "4": 2543,
          "5": 498,
        },
        "34.8": {
          "4": 689,
          "5": 795,
        },
        "36.4": {
          "4": 24,
          "5": 467,
        },
        "26.4": {
          "3": 1543,
          "4": 57,
        },
        "27.4": {
          "3": 2119,
          "4": 780,
        },
        "24.4": {
          "3": 269,
        },
        "24.6": {
          "3": 352,
        },
        "25.8": {
          "3": 1031,
          "4": 5,
        },
        "38.8": {
          "5": 165,
          "6": 22,
        },
        "25.4": {
          "3": 765,
          "4": 1,
        },
        "38.6": {
          "5": 64,
          "6": 1,
        },
        "37.2": {
          "4": 3,
          "5": 217,
        },
        "37.8": {
          "5": 125,
        },
        "37.6": {
          "5": 159,
        },
        "36.6": {
          "4": 23,
          "5": 353,
        },
        "23.6": {
          "2": 18,
          "3": 472,
        },
        "25.6": {
          "3": 871,
        },
        "37.4": {
          "5": 170,
        },
        "36.2": {
          "4": 64,
          "5": 508,
        },
        "36.8": {
          "4": 14,
          "5": 279,
        },
        "38.2": {
          "5": 66,
        },
        "24.2": {
          "3": 217,
        },
        "38.4": {
          "5": 59,
        },
      },
      KO: {
        "24": {
          "3": 38,
        },
        "25": {
          "3": 107,
        },
        "26": {
          "3": 217,
          "4": 1,
        },
        "27": {
          "3": 371,
          "4": 56,
        },
        "28": {
          "3": 430,
          "4": 356,
        },
        "29": {
          "3": 292,
          "4": 1147,
        },
        "30": {
          "3": 60,
          "4": 2027,
        },
        "31": {
          "4": 2955,
        },
        "32": {
          "4": 3939,
          "5": 5,
        },
        "33": {
          "4": 4565,
          "5": 208,
        },
        "34": {
          "4": 3870,
          "5": 1187,
        },
        "35": {
          "4": 2231,
          "5": 2672,
        },
        "36": {
          "4": 584,
          "5": 3932,
        },
        "37": {
          "4": 52,
          "5": 3301,
        },
        "38": {
          "4": 1,
          "5": 2380,
          "6": 5,
        },
        "39": {
          "5": 1489,
          "6": 40,
        },
        "40": {
          "5": 689,
          "6": 117,
        },
        "41": {
          "5": 241,
          "6": 194,
        },
        "42": {
          "5": 45,
          "6": 148,
        },
        "43": {
          "5": 6,
          "6": 61,
        },
        "44": {
          "6": 22,
        },
        "45": {
          "6": 10,
        },
        "33.8": {
          "4": 3994,
          "5": 943,
        },
        "32.4": {
          "4": 4250,
          "5": 32,
        },
        "27.6": {
          "3": 459,
          "4": 150,
        },
        "36.2": {
          "4": 378,
          "5": 3732,
        },
        "30.4": {
          "3": 5,
          "4": 2488,
        },
        "33.6": {
          "4": 4280,
          "5": 670,
        },
        "30.6": {
          "4": 2631,
        },
        "35.6": {
          "4": 1068,
          "5": 3551,
        },
        "29.6": {
          "3": 129,
          "4": 1574,
        },
        "42.8": {
          "5": 10,
          "6": 72,
        },
        "32.6": {
          "4": 4421,
          "5": 65,
        },
        "31.8": {
          "4": 3807,
          "5": 2,
        },
        "42.2": {
          "5": 32,
          "6": 115,
        },
        "35.2": {
          "4": 1818,
          "5": 3078,
        },
        "40.6": {
          "5": 379,
          "6": 151,
        },
        "37.8": {
          "4": 2,
          "5": 2588,
          "6": 1,
        },
        "36.4": {
          "4": 239,
          "5": 3815,
        },
        "39.6": {
          "5": 976,
          "6": 87,
        },
        "41.6": {
          "5": 108,
          "6": 166,
        },
        "27.8": {
          "3": 474,
          "4": 251,
        },
        "29.4": {
          "3": 210,
          "4": 1493,
        },
        "34.4": {
          "4": 3383,
          "5": 1783,
        },
        "31.2": {
          "4": 3129,
        },
        "38.6": {
          "5": 1797,
          "6": 16,
        },
        "26.4": {
          "3": 268,
          "4": 5,
        },
        "33.2": {
          "4": 4531,
          "5": 334,
        },
        "27.2": {
          "3": 381,
          "4": 63,
        },
        "33.4": {
          "4": 4283,
          "5": 510,
        },
        "35.4": {
          "4": 1458,
          "5": 3403,
        },
        "29.8": {
          "3": 100,
          "4": 1873,
        },
        "37.6": {
          "4": 4,
          "5": 2777,
        },
        "31.6": {
          "4": 3570,
          "5": 1,
        },
        "38.2": {
          "5": 2231,
          "6": 11,
        },
        "36.8": {
          "4": 98,
          "5": 3633,
        },
        "31.4": {
          "4": 3366,
        },
        "34.2": {
          "4": 3680,
          "5": 1502,
        },
        "30.8": {
          "4": 2869,
        },
        "39.4": {
          "5": 1106,
          "6": 48,
        },
        "37.4": {
          "4": 18,
          "5": 2937,
        },
        "35.8": {
          "4": 756,
          "5": 3716,
        },
        "27.4": {
          "3": 411,
          "4": 98,
        },
        "38.4": {
          "5": 2032,
          "6": 14,
        },
        "36.6": {
          "4": 189,
          "5": 3718,
        },
        "32.8": {
          "4": 4543,
          "5": 111,
        },
        "34.6": {
          "4": 3033,
          "5": 2145,
        },
        "38.8": {
          "5": 1651,
          "6": 31,
        },
        "34.8": {
          "4": 2495,
          "5": 2299,
        },
        "30.2": {
          "3": 26,
          "4": 2152,
        },
        "28.6": {
          "3": 364,
          "4": 761,
        },
        "37.2": {
          "4": 30,
          "5": 3218,
        },
        "32.2": {
          "4": 4119,
          "5": 13,
        },
        "28.2": {
          "3": 461,
          "4": 445,
        },
        "26.8": {
          "3": 333,
          "4": 32,
        },
        "29.2": {
          "3": 215,
          "4": 1296,
        },
        "26.2": {
          "3": 262,
          "4": 5,
        },
        "40.2": {
          "5": 578,
          "6": 134,
        },
        "39.2": {
          "5": 1360,
          "6": 49,
        },
        "39.8": {
          "5": 876,
          "6": 103,
        },
        "28.8": {
          "3": 297,
          "4": 934,
        },
        "28.4": {
          "3": 394,
          "4": 598,
        },
        "26.6": {
          "3": 307,
          "4": 11,
        },
        "40.8": {
          "5": 312,
          "6": 175,
        },
        "40.4": {
          "5": 440,
          "6": 172,
        },
        "42.4": {
          "5": 17,
          "6": 90,
        },
        "25.2": {
          "3": 116,
        },
        "41.4": {
          "5": 135,
          "6": 159,
        },
        "41.2": {
          "5": 165,
          "6": 172,
        },
        "25.6": {
          "3": 161,
        },
        "44.6": {
          "6": 10,
        },
        "43.2": {
          "5": 4,
          "6": 44,
        },
        "23.6": {
          "2": 18,
          "3": 180,
        },
        "41.8": {
          "5": 67,
          "6": 130,
        },
        "43.6": {
          "5": 2,
          "6": 35,
        },
        "43.4": {
          "5": 4,
          "6": 50,
        },
        "44.2": {
          "5": 1,
          "6": 22,
        },
        "25.4": {
          "3": 129,
        },
        "44.4": {
          "5": 1,
          "6": 20,
        },
        "25.8": {
          "3": 168,
        },
        "24.6": {
          "3": 80,
        },
        "45.8": {
          "6": 3,
          "7": 1,
        },
        "24.4": {
          "3": 55,
        },
        "42.6": {
          "5": 20,
          "6": 76,
        },
        "44.8": {
          "6": 10,
        },
        "43.8": {
          "5": 1,
          "6": 35,
        },
        "24.8": {
          "3": 91,
        },
        "23.8": {
          "3": 41,
        },
        "45.6": {
          "6": 5,
        },
        "24.2": {
          "3": 46,
        },
        "45.4": {
          "6": 2,
          "7": 2,
        },
        "45.2": {
          "6": 4,
        },
        "46.8": {
          "7": 1,
        },
      },
      TD: {
        "9": {
          "1": 374,
        },
        "10": {
          "1": 1191,
        },
        "11": {
          "1": 3075,
        },
        "12": {
          "1": 5717,
        },
        "13": {
          "1": 7537,
          "2": 129,
        },
        "14": {
          "1": 3942,
          "2": 4455,
        },
        "15": {
          "1": 97,
          "2": 6563,
        },
        "16": {
          "2": 4000,
        },
        "17": {
          "2": 1851,
        },
        "18": {
          "2": 712,
        },
        "19": {
          "2": 206,
          "3": 1,
        },
        "11.2": {
          "1": 3470,
        },
        "11.8": {
          "1": 5086,
        },
        "13.8": {
          "1": 5265,
          "2": 3040,
        },
        "15.4": {
          "1": 1,
          "2": 5582,
        },
        "13.2": {
          "1": 7939,
          "2": 358,
        },
        "12.8": {
          "1": 7597,
          "2": 18,
        },
        "14.8": {
          "1": 312,
          "2": 6589,
        },
        "17.8": {
          "2": 811,
        },
        "12.6": {
          "1": 7206,
          "2": 2,
        },
        "16.6": {
          "2": 2626,
        },
        "13.6": {
          "1": 6411,
          "2": 1921,
        },
        "13.4": {
          "1": 7392,
          "2": 998,
        },
        "14.2": {
          "1": 2519,
          "2": 5591,
        },
        "10.6": {
          "1": 2231,
        },
        "10.2": {
          "1": 1456,
        },
        "16.8": {
          "2": 2264,
        },
        "14.4": {
          "1": 1324,
          "2": 6363,
        },
        "15.2": {
          "1": 12,
          "2": 6236,
        },
        "16.2": {
          "2": 3430,
        },
        "18.2": {
          "2": 473,
        },
        "15.8": {
          "2": 4585,
        },
        "12.2": {
          "1": 6123,
        },
        "15.6": {
          "2": 4970,
        },
        "14.6": {
          "1": 749,
          "2": 6850,
        },
        "10.8": {
          "1": 2653,
        },
        "11.4": {
          "1": 3957,
        },
        "12.4": {
          "1": 6611,
        },
        "9.8": {
          "1": 971,
        },
        "10.4": {
          "1": 1907,
        },
        "11.6": {
          "1": 4461,
        },
        "17.2": {
          "2": 1610,
        },
        "17.4": {
          "2": 1330,
        },
        "9.4": {
          "1": 606,
        },
        "16.4": {
          "2": 3125,
        },
        "17.6": {
          "2": 1083,
        },
        "9.2": {
          "1": 491,
        },
        "18.4": {
          "2": 427,
        },
        "8.6": {
          "0": 50,
          "1": 781,
        },
        "18.8": {
          "2": 275,
        },
        "9.6": {
          "1": 820,
        },
        "18.6": {
          "2": 338,
        },
        "8.8": {
          "1": 295,
        },
        "19.8": {
          "2": 156,
          "3": 63,
        },
        "19.2": {
          "2": 150,
          "3": 1,
        },
        "19.4": {
          "2": 109,
        },
        "19.6": {
          "2": 78,
          "3": 2,
        },
      },
      KONF: {
        "6": {
          "0": 2685,
        },
        "7": {
          "0": 3076,
          "1": 5690,
        },
        "8": {
          "1": 13347,
        },
        "9": {
          "1": 9833,
        },
        "10": {
          "1": 3823,
        },
        "11": {
          "1": 815,
        },
        "9.4": {
          "1": 7443,
        },
        "8.2": {
          "1": 13386,
        },
        "10.8": {
          "1": 1128,
        },
        "7.4": {
          "0": 230,
          "1": 11217,
        },
        "9.6": {
          "1": 6144,
        },
        "8.8": {
          "1": 11051,
        },
        "8.6": {
          "1": 12407,
        },
        "9.2": {
          "1": 8854,
        },
        "6.6": {
          "0": 5299,
          "1": 650,
        },
        "7.6": {
          "0": 18,
          "1": 12256,
        },
        "6.2": {
          "0": 3722,
        },
        "7.8": {
          "1": 13063,
        },
        "8.4": {
          "1": 12896,
        },
        "10.2": {
          "1": 2943,
        },
        "6.4": {
          "0": 4633,
          "1": 71,
        },
        "7.2": {
          "0": 1086,
          "1": 8914,
        },
        "10.6": {
          "1": 1572,
        },
        "9.8": {
          "1": 4920,
        },
        "6.8": {
          "0": 4722,
          "1": 2575,
        },
        "11.2": {
          "1": 567,
        },
        "11.4": {
          "1": 336,
        },
        "5.6": {
          "0": 1358,
        },
        "5.2": {
          "0": 1520,
        },
        "5.8": {
          "0": 1961,
        },
        "11.6": {
          "1": 626,
          "2": 3,
        },
        "10.4": {
          "1": 2265,
        },
        "5.4": {
          "0": 895,
        },
      },
      LEWICA: {
        "9": {
          "1": 611,
        },
        "10": {
          "1": 1605,
        },
        "11": {
          "1": 3617,
        },
        "12": {
          "1": 6058,
        },
        "13": {
          "1": 7657,
          "2": 107,
        },
        "14": {
          "1": 3634,
          "2": 4120,
        },
        "15": {
          "1": 78,
          "2": 6038,
        },
        "16": {
          "2": 3556,
        },
        "17": {
          "2": 1768,
        },
        "18": {
          "2": 622,
        },
        "19": {
          "2": 187,
        },
        "14.2": {
          "1": 2347,
          "2": 5264,
        },
        "16.6": {
          "2": 2293,
        },
        "12.6": {
          "1": 7101,
          "2": 2,
        },
        "13.8": {
          "1": 5146,
          "2": 3009,
        },
        "11.2": {
          "1": 4110,
        },
        "13.2": {
          "1": 7556,
          "2": 399,
        },
        "14.4": {
          "1": 1297,
          "2": 6014,
        },
        "17.2": {
          "2": 1425,
        },
        "18.6": {
          "2": 312,
        },
        "14.8": {
          "1": 265,
          "2": 6228,
        },
        "12.8": {
          "1": 7632,
          "2": 26,
        },
        "19.8": {
          "2": 156,
          "3": 43,
        },
        "11.6": {
          "1": 4885,
        },
        "12.4": {
          "1": 6741,
        },
        "10.8": {
          "1": 3125,
        },
        "13.6": {
          "1": 6132,
          "2": 1888,
        },
        "15.2": {
          "1": 11,
          "2": 5590,
        },
        "10.6": {
          "1": 2778,
        },
        "15.6": {
          "2": 4599,
        },
        "15.8": {
          "2": 3931,
        },
        "11.8": {
          "1": 5596,
        },
        "11.4": {
          "1": 4458,
        },
        "17.4": {
          "2": 1109,
        },
        "17.8": {
          "2": 803,
        },
        "14.6": {
          "1": 735,
          "2": 6286,
        },
        "13.4": {
          "1": 7228,
          "2": 961,
        },
        "16.4": {
          "2": 2778,
        },
        "12.2": {
          "1": 6502,
        },
        "10.4": {
          "1": 2391,
        },
        "16.2": {
          "2": 3178,
        },
        "8.6": {
          "1": 400,
        },
        "15.4": {
          "1": 1,
          "2": 5088,
        },
        "9.6": {
          "1": 1105,
        },
        "10.2": {
          "1": 2011,
        },
        "17.6": {
          "2": 1004,
        },
        "16.8": {
          "2": 2081,
        },
        "8.2": {
          "0": 137,
          "1": 772,
        },
        "9.8": {
          "1": 1427,
        },
        "9.4": {
          "1": 918,
        },
        "18.8": {
          "2": 254,
        },
        "18.4": {
          "2": 427,
        },
        "8.8": {
          "1": 448,
        },
        "9.2": {
          "1": 822,
        },
        "8.4": {
          "1": 283,
        },
        "19.4": {
          "2": 88,
          "3": 2,
        },
        "19.6": {
          "2": 84,
        },
        "18.2": {
          "2": 507,
        },
        "19.2": {
          "2": 153,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2854,
      TD: 4316,
      LEWICA: 3942,
    },
    potencjalneMandaty: {
      KO: 53115,
      TD: 63796,
      LEWICA: 61225,
    },
    ostatnieMandaty: {
      KO: 40973,
      TD: 34730,
      LEWICA: 32554,
    },
    dawcyGlosow: {
      KO: 6897,
      TD: 9603,
      LEWICA: 8900,
    },
  },
  Toruń: {
    procentyWOkreguSrednia: {
      PIS: 33.972000332168236,
      KO: 29.00037552706467,
      TD: 14.440064490449858,
      KONF: 8.796798716617243,
      LEWICA: 13.790760933699989,
    },
    odchylenieWOkregu: {
      PIS: 1.659785504086703,
      KO: 3.5311071919790473,
      TD: 2.013774734692939,
      LEWICA: 1.9222144155515066,
      KONF: 1.5840195748563044,
    },
    wykresRozkladu: {
      "27.7%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "26.7%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "17.2%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "16.4%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 2,
      },
      "4%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "3%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "2.6%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.5%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.3%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 7,
        TD: 1,
      },
      "0.2%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 3,
      },
    },
    wykresRozkladuDemo: {
      "63.4%": {
        DEMO: 7,
        NIEDEMO: 6,
      },
      "34.5%": {
        DEMO: 8,
        NIEDEMO: 5,
      },
      "1.5%": {
        DEMO: 6,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "30": {
          "4": 889,
          "5": 2,
        },
        "31": {
          "4": 2331,
          "5": 125,
        },
        "32": {
          "4": 3798,
          "5": 1221,
        },
        "33": {
          "4": 2956,
          "5": 4300,
        },
        "34": {
          "4": 1169,
          "5": 6903,
        },
        "35": {
          "4": 95,
          "5": 6934,
        },
        "36": {
          "5": 4604,
          "6": 5,
        },
        "37": {
          "5": 2363,
          "6": 91,
        },
        "38": {
          "5": 934,
          "6": 192,
        },
        "39": {
          "5": 673,
          "6": 1270,
          "7": 3,
        },
        "33.2": {
          "4": 2671,
          "5": 4924,
        },
        "34.2": {
          "4": 870,
          "5": 7230,
        },
        "35.6": {
          "5": 5487,
        },
        "35.2": {
          "4": 29,
          "5": 6410,
        },
        "33.4": {
          "4": 2317,
          "5": 5560,
        },
        "34.6": {
          "4": 333,
          "5": 7211,
        },
        "33.8": {
          "4": 1506,
          "5": 6589,
        },
        "37.4": {
          "5": 1706,
          "6": 165,
        },
        "32.2": {
          "4": 3856,
          "5": 1813,
        },
        "32.8": {
          "4": 3412,
          "5": 3644,
        },
        "34.8": {
          "4": 201,
          "5": 7102,
        },
        "33.6": {
          "4": 1960,
          "5": 6241,
        },
        "31.8": {
          "4": 3627,
          "5": 908,
        },
        "36.6": {
          "5": 3197,
          "6": 54,
        },
        "31.2": {
          "4": 2814,
          "5": 218,
        },
        "32.6": {
          "4": 3661,
          "5": 2879,
        },
        "30.8": {
          "4": 2073,
          "5": 67,
        },
        "34.4": {
          "4": 568,
          "5": 7505,
        },
        "30.4": {
          "4": 1506,
          "5": 14,
        },
        "37.2": {
          "5": 2077,
          "6": 132,
        },
        "30.6": {
          "4": 1687,
          "5": 28,
        },
        "36.4": {
          "5": 3709,
          "6": 24,
        },
        "32.4": {
          "4": 3687,
          "5": 2344,
        },
        "37.8": {
          "5": 1155,
          "6": 191,
        },
        "35.4": {
          "4": 11,
          "5": 6100,
        },
        "31.6": {
          "4": 3423,
          "5": 560,
        },
        "29.8": {
          "3": 10,
          "4": 2603,
          "5": 1,
        },
        "36.2": {
          "5": 4127,
          "6": 26,
        },
        "35.8": {
          "5": 4958,
          "6": 5,
        },
        "36.8": {
          "5": 2901,
          "6": 84,
        },
        "31.4": {
          "4": 3124,
          "5": 346,
        },
        "38.4": {
          "5": 551,
          "6": 214,
        },
        "30.2": {
          "4": 1102,
          "5": 5,
        },
        "38.8": {
          "5": 297,
          "6": 219,
        },
        "37.6": {
          "5": 1418,
          "6": 179,
        },
        "38.2": {
          "5": 678,
          "6": 225,
        },
        "38.6": {
          "5": 387,
          "6": 226,
        },
      },
      KO: {
        "21": {
          "3": 108,
        },
        "22": {
          "3": 270,
        },
        "23": {
          "3": 597,
        },
        "24": {
          "3": 1136,
          "4": 4,
        },
        "25": {
          "3": 1787,
          "4": 186,
        },
        "26": {
          "3": 1981,
          "4": 1078,
        },
        "27": {
          "3": 1034,
          "4": 3207,
        },
        "28": {
          "3": 68,
          "4": 5416,
        },
        "29": {
          "4": 5748,
        },
        "30": {
          "4": 5680,
          "5": 2,
        },
        "31": {
          "4": 4468,
          "5": 198,
        },
        "32": {
          "4": 2413,
          "5": 793,
        },
        "33": {
          "4": 608,
          "5": 1361,
        },
        "34": {
          "4": 49,
          "5": 1006,
        },
        "35": {
          "5": 452,
        },
        "36": {
          "5": 177,
          "6": 1,
        },
        "37": {
          "5": 52,
          "6": 4,
        },
        "38": {
          "5": 13,
          "6": 3,
        },
        "39": {
          "6": 1,
        },
        "25.2": {
          "3": 1946,
          "4": 285,
        },
        "30.2": {
          "4": 5427,
          "5": 27,
        },
        "23.2": {
          "3": 654,
        },
        "24.4": {
          "3": 1358,
          "4": 22,
        },
        "24.6": {
          "3": 1496,
          "4": 52,
        },
        "29.4": {
          "4": 5736,
        },
        "28.8": {
          "4": 5701,
        },
        "26.4": {
          "3": 1770,
          "4": 1928,
        },
        "22.8": {
          "3": 492,
        },
        "27.4": {
          "3": 547,
          "4": 4355,
        },
        "28.6": {
          "4": 5820,
        },
        "29.6": {
          "4": 5740,
          "5": 1,
        },
        "32.2": {
          "4": 2036,
          "5": 939,
        },
        "29.8": {
          "4": 5697,
          "5": 3,
        },
        "31.4": {
          "4": 3605,
          "5": 347,
        },
        "28.4": {
          "3": 3,
          "4": 5842,
        },
        "30.8": {
          "4": 4693,
          "5": 120,
        },
        "32.4": {
          "4": 1606,
          "5": 1073,
        },
        "27.2": {
          "3": 807,
          "4": 3792,
        },
        "25.8": {
          "3": 2065,
          "4": 875,
        },
        "33.6": {
          "4": 139,
          "5": 1150,
        },
        "27.6": {
          "3": 304,
          "4": 4739,
        },
        "28.2": {
          "3": 13,
          "4": 5520,
        },
        "32.8": {
          "4": 908,
          "5": 1347,
        },
        "30.6": {
          "4": 4883,
          "5": 86,
        },
        "26.6": {
          "3": 1601,
          "4": 2375,
        },
        "31.6": {
          "4": 3372,
          "5": 498,
        },
        "32.6": {
          "4": 1192,
          "5": 1239,
        },
        "25.6": {
          "3": 2074,
          "4": 579,
        },
        "30.4": {
          "4": 5324,
          "5": 26,
        },
        "26.8": {
          "3": 1374,
          "4": 2744,
        },
        "37.2": {
          "5": 38,
          "6": 2,
        },
        "25.4": {
          "3": 1970,
          "4": 451,
        },
        "31.2": {
          "4": 4051,
          "5": 244,
        },
        "26.2": {
          "3": 1954,
          "4": 1474,
        },
        "29.2": {
          "4": 5771,
        },
        "24.8": {
          "3": 1645,
          "4": 108,
        },
        "33.2": {
          "4": 423,
          "5": 1341,
        },
        "31.8": {
          "4": 2910,
          "5": 635,
        },
        "34.6": {
          "4": 5,
          "5": 643,
        },
        "34.4": {
          "4": 9,
          "5": 735,
        },
        "22.6": {
          "3": 407,
        },
        "27.8": {
          "3": 178,
          "4": 5114,
        },
        "33.4": {
          "4": 245,
          "5": 1356,
        },
        "33.8": {
          "4": 89,
          "5": 1166,
        },
        "23.8": {
          "3": 995,
        },
        "34.8": {
          "4": 2,
          "5": 495,
        },
        "20.4": {
          "2": 2,
          "3": 48,
        },
        "24.2": {
          "3": 1256,
          "4": 11,
        },
        "21.8": {
          "3": 228,
        },
        "22.2": {
          "3": 296,
        },
        "35.6": {
          "5": 254,
        },
        "22.4": {
          "3": 347,
        },
        "34.2": {
          "4": 19,
          "5": 860,
        },
        "35.8": {
          "5": 192,
        },
        "23.6": {
          "3": 902,
        },
        "23.4": {
          "3": 761,
        },
        "20.8": {
          "2": 4,
          "3": 91,
        },
        "36.2": {
          "5": 166,
        },
        "35.2": {
          "5": 393,
        },
        "36.6": {
          "5": 80,
          "6": 3,
        },
        "21.4": {
          "3": 162,
        },
        "35.4": {
          "5": 302,
        },
        "21.2": {
          "3": 149,
        },
        "37.4": {
          "5": 30,
          "6": 5,
        },
        "38.2": {
          "5": 8,
          "6": 4,
        },
        "37.6": {
          "5": 17,
          "6": 4,
        },
        "20.2": {
          "2": 108,
          "3": 171,
        },
        "36.4": {
          "5": 88,
        },
        "36.8": {
          "5": 49,
          "6": 2,
        },
        "37.8": {
          "5": 15,
          "6": 11,
        },
        "20.6": {
          "3": 54,
        },
        "21.6": {
          "3": 183,
        },
        "39.6": {
          "6": 4,
        },
        "38.4": {
          "5": 3,
          "6": 2,
        },
        "38.8": {
          "5": 3,
          "6": 3,
        },
        "39.4": {
          "5": 1,
          "6": 2,
        },
        "38.6": {
          "5": 1,
          "6": 1,
        },
        "39.2": {
          "6": 1,
        },
      },
      TD: {
        "10": {
          "1": 529,
        },
        "11": {
          "1": 1510,
        },
        "12": {
          "1": 3479,
          "2": 14,
        },
        "13": {
          "1": 3703,
          "2": 2873,
        },
        "14": {
          "1": 66,
          "2": 8368,
        },
        "15": {
          "2": 8278,
        },
        "16": {
          "2": 6062,
        },
        "17": {
          "2": 3361,
        },
        "18": {
          "2": 1323,
          "3": 3,
        },
        "19": {
          "2": 341,
          "3": 87,
        },
        "20": {
          "2": 15,
          "3": 72,
        },
        "17.4": {
          "2": 2333,
        },
        "14.6": {
          "2": 8698,
        },
        "15.2": {
          "2": 7840,
        },
        "14.4": {
          "2": 8744,
        },
        "18.6": {
          "2": 695,
          "3": 44,
        },
        "12.6": {
          "1": 4401,
          "2": 768,
        },
        "13.4": {
          "1": 1748,
          "2": 5562,
        },
        "15.8": {
          "2": 6510,
        },
        "13.8": {
          "1": 348,
          "2": 7688,
        },
        "16.2": {
          "2": 5409,
        },
        "14.2": {
          "1": 5,
          "2": 8581,
        },
        "12.8": {
          "1": 4264,
          "2": 1587,
        },
        "13.2": {
          "1": 2688,
          "2": 4056,
        },
        "12.2": {
          "1": 3958,
          "2": 73,
        },
        "15.4": {
          "2": 7482,
        },
        "11.8": {
          "1": 3190,
        },
        "15.6": {
          "2": 7043,
        },
        "13.6": {
          "1": 920,
          "2": 6956,
        },
        "17.2": {
          "2": 2795,
        },
        "11.6": {
          "1": 2646,
        },
        "17.6": {
          "2": 2073,
        },
        "16.6": {
          "2": 4357,
        },
        "10.6": {
          "1": 1069,
        },
        "17.8": {
          "2": 1634,
        },
        "12.4": {
          "1": 4422,
          "2": 308,
        },
        "19.6": {
          "2": 71,
          "3": 93,
        },
        "14.8": {
          "2": 8508,
        },
        "16.8": {
          "2": 3737,
        },
        "10.4": {
          "1": 807,
        },
        "18.4": {
          "2": 843,
          "3": 30,
        },
        "16.4": {
          "2": 4851,
        },
        "11.2": {
          "1": 1848,
        },
        "10.2": {
          "1": 650,
        },
        "9.4": {
          "0": 1,
          "1": 850,
        },
        "11.4": {
          "1": 2244,
        },
        "9.8": {
          "1": 363,
        },
        "10.8": {
          "1": 1323,
        },
        "20.4": {
          "2": 3,
          "3": 132,
        },
        "18.8": {
          "2": 447,
          "3": 53,
        },
        "18.2": {
          "2": 1079,
          "3": 14,
        },
        "19.2": {
          "2": 211,
          "3": 91,
        },
        "9.6": {
          "1": 306,
        },
        "19.4": {
          "2": 133,
          "3": 128,
        },
        "20.2": {
          "2": 12,
          "3": 58,
        },
        "19.8": {
          "2": 45,
          "3": 90,
        },
      },
      KONF: {
        "5": {
          "0": 481,
        },
        "6": {
          "0": 1948,
          "1": 9,
        },
        "7": {
          "0": 73,
          "1": 5210,
        },
        "8": {
          "1": 9129,
        },
        "9": {
          "1": 10661,
        },
        "10": {
          "1": 7696,
        },
        "11": {
          "1": 3582,
        },
        "12": {
          "1": 1124,
          "2": 3,
        },
        "13": {
          "1": 103,
          "2": 117,
        },
        "10.6": {
          "1": 5065,
        },
        "8.8": {
          "1": 10709,
        },
        "10.2": {
          "1": 7014,
        },
        "9.6": {
          "1": 9085,
        },
        "11.6": {
          "1": 1780,
        },
        "7.8": {
          "1": 8588,
        },
        "7.2": {
          "1": 5970,
        },
        "7.6": {
          "1": 7639,
        },
        "11.2": {
          "1": 2856,
        },
        "9.2": {
          "1": 10240,
        },
        "10.4": {
          "1": 5981,
        },
        "8.4": {
          "1": 10316,
        },
        "8.2": {
          "1": 9925,
        },
        "6.2": {
          "0": 2228,
          "1": 188,
        },
        "4.8": {
          "0": 1001,
        },
        "9.4": {
          "1": 9691,
        },
        "6.8": {
          "0": 546,
          "1": 3879,
        },
        "8.6": {
          "1": 10302,
        },
        "6.6": {
          "0": 1376,
          "1": 2222,
        },
        "12.2": {
          "1": 832,
          "2": 18,
        },
        "11.4": {
          "1": 2358,
        },
        "5.8": {
          "0": 1578,
        },
        "7.4": {
          "1": 6851,
        },
        "9.8": {
          "1": 8574,
        },
        "10.8": {
          "1": 4413,
        },
        "6.4": {
          "0": 2070,
          "1": 839,
        },
        "5.4": {
          "0": 878,
        },
        "12.6": {
          "1": 375,
          "2": 85,
        },
        "11.8": {
          "1": 1395,
        },
        "5.6": {
          "0": 1071,
        },
        "5.2": {
          "0": 607,
        },
        "13.4": {
          "1": 13,
          "2": 82,
        },
        "12.4": {
          "1": 534,
          "2": 41,
        },
        "13.6": {
          "1": 7,
          "2": 184,
        },
        "12.8": {
          "1": 215,
          "2": 100,
        },
        "13.2": {
          "1": 51,
          "2": 92,
        },
      },
      LEWICA: {
        "9": {
          "0": 5,
          "1": 916,
        },
        "10": {
          "1": 881,
        },
        "11": {
          "1": 2613,
        },
        "12": {
          "1": 5392,
          "2": 15,
        },
        "13": {
          "1": 4727,
          "2": 3408,
        },
        "14": {
          "1": 109,
          "2": 8977,
        },
        "15": {
          "2": 7171,
        },
        "16": {
          "2": 4044,
        },
        "17": {
          "2": 1838,
        },
        "18": {
          "2": 554,
          "3": 3,
        },
        "19": {
          "2": 88,
          "3": 23,
        },
        "17.2": {
          "2": 1394,
        },
        "14.8": {
          "2": 7667,
        },
        "13.2": {
          "1": 3449,
          "2": 4932,
        },
        "13.4": {
          "1": 2220,
          "2": 6533,
        },
        "16.2": {
          "2": 3645,
        },
        "16.6": {
          "2": 2542,
        },
        "12.4": {
          "1": 6266,
          "2": 376,
        },
        "13.6": {
          "1": 1134,
          "2": 7707,
        },
        "15.2": {
          "2": 6527,
        },
        "12.8": {
          "1": 5614,
          "2": 2030,
        },
        "11.2": {
          "1": 3159,
        },
        "12.6": {
          "1": 6316,
          "2": 979,
        },
        "16.8": {
          "2": 2247,
        },
        "13.8": {
          "1": 411,
          "2": 8553,
        },
        "18.4": {
          "2": 291,
          "3": 10,
        },
        "17.4": {
          "2": 1182,
        },
        "15.6": {
          "2": 5210,
        },
        "14.4": {
          "2": 8461,
        },
        "14.6": {
          "2": 7965,
        },
        "14.2": {
          "1": 2,
          "2": 8747,
        },
        "11.8": {
          "1": 4721,
        },
        "11.4": {
          "1": 3668,
        },
        "16.4": {
          "2": 2987,
        },
        "9.2": {
          "1": 325,
        },
        "12.2": {
          "1": 5927,
          "2": 113,
        },
        "11.6": {
          "1": 4194,
        },
        "9.4": {
          "1": 420,
        },
        "10.4": {
          "1": 1487,
        },
        "15.8": {
          "2": 4688,
        },
        "9.6": {
          "1": 543,
        },
        "19.6": {
          "2": 57,
          "3": 101,
        },
        "15.4": {
          "2": 5979,
        },
        "10.6": {
          "1": 1778,
        },
        "10.2": {
          "1": 1144,
        },
        "18.6": {
          "2": 220,
          "3": 9,
        },
        "9.8": {
          "1": 688,
        },
        "17.6": {
          "2": 931,
        },
        "17.8": {
          "2": 738,
        },
        "19.2": {
          "2": 56,
          "3": 34,
        },
        "10.8": {
          "1": 2171,
        },
        "18.8": {
          "2": 172,
          "3": 25,
        },
        "18.2": {
          "2": 431,
          "3": 2,
        },
        "19.4": {
          "2": 31,
          "3": 27,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3135,
      TD: 4811,
      LEWICA: 5189,
    },
    potencjalneMandaty: {
      KO: 47285,
      TD: 42408,
      LEWICA: 53204,
    },
    ostatnieMandaty: {
      KO: 44039,
      TD: 30844,
      LEWICA: 36039,
    },
    dawcyGlosow: {
      KO: 7776,
      TD: 12459,
      LEWICA: 12589,
    },
  },
  Lublin: {
    procentyWOkreguSrednia: {
      PIS: 47.32241413439936,
      KO: 22.60137815047705,
      TD: 11.209062079875038,
      KONF: 11.83192209155552,
      LEWICA: 7.035223543693021,
    },
    odchylenieWOkregu: {
      PIS: 5.355517368596007,
      KO: 1.1748595693094028,
      TD: 2.013774734692939,
      LEWICA: 1.0467039666902878,
      KONF: 2.911909018692629,
    },
    wykresRozkladu: {
      "28.4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 1,
      },
      "26.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 2,
      },
      "23.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 1,
      },
      "11.4%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 2,
      },
      "3.5%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 2,
      },
      "2.6%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 1,
      },
      "2.5%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 2,
      },
      "1.8%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 11,
        TD: 1,
      },
      "0.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 0,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 3,
      },
    },
    wykresRozkladuDemo: {
      "51.7%": {
        DEMO: 6,
        NIEDEMO: 9,
      },
      "34.7%": {
        DEMO: 5,
        NIEDEMO: 10,
      },
      "11.6%": {
        DEMO: 7,
        NIEDEMO: 8,
      },
    },
    histogram: {
      PIS: {
        "34": {
          "5": 36,
          "6": 9,
        },
        "35": {
          "5": 8,
          "6": 17,
        },
        "36": {
          "5": 1,
          "6": 35,
        },
        "37": {
          "6": 80,
        },
        "38": {
          "6": 122,
          "7": 2,
        },
        "39": {
          "6": 252,
          "7": 21,
        },
        "40": {
          "6": 339,
          "7": 147,
        },
        "41": {
          "6": 295,
          "7": 557,
        },
        "42": {
          "6": 144,
          "7": 1226,
        },
        "43": {
          "6": 17,
          "7": 2059,
          "8": 5,
        },
        "44": {
          "7": 2656,
          "8": 94,
        },
        "45": {
          "7": 3047,
          "8": 591,
        },
        "46": {
          "7": 2339,
          "8": 2068,
        },
        "47": {
          "7": 1063,
          "8": 3632,
        },
        "48": {
          "7": 246,
          "8": 4550,
          "9": 7,
        },
        "49": {
          "7": 17,
          "8": 4179,
          "9": 66,
        },
        "50": {
          "8": 3043,
          "9": 425,
        },
        "51": {
          "8": 1663,
          "9": 1008,
        },
        "52": {
          "8": 656,
          "9": 1160,
        },
        "53": {
          "8": 182,
          "9": 992,
          "10": 1,
        },
        "54": {
          "8": 27,
          "9": 595,
          "10": 13,
        },
        "55": {
          "9": 258,
          "10": 32,
        },
        "56": {
          "9": 115,
          "10": 31,
        },
        "57": {
          "9": 22,
          "10": 17,
        },
        "58": {
          "9": 5,
          "10": 13,
        },
        "59": {
          "10": 1,
        },
        "60": {
          "9": 1,
        },
        "46.6": {
          "7": 1614,
          "8": 3020,
        },
        "47.8": {
          "7": 346,
          "8": 4342,
          "9": 3,
        },
        "54.8": {
          "8": 4,
          "9": 302,
          "10": 25,
        },
        "48.6": {
          "7": 75,
          "8": 4251,
          "9": 24,
        },
        "41.8": {
          "6": 165,
          "7": 1162,
        },
        "49.2": {
          "7": 11,
          "8": 3926,
          "9": 124,
        },
        "40.2": {
          "6": 338,
          "7": 233,
        },
        "49.6": {
          "7": 1,
          "8": 3703,
          "9": 228,
        },
        "50.6": {
          "8": 2225,
          "9": 729,
        },
        "46.8": {
          "7": 1315,
          "8": 3285,
        },
        "47.4": {
          "7": 626,
          "8": 4006,
        },
        "43.8": {
          "7": 2651,
          "8": 45,
        },
        "46.4": {
          "7": 1853,
          "8": 2582,
        },
        "48.8": {
          "7": 35,
          "8": 4234,
          "9": 39,
        },
        "42.2": {
          "6": 131,
          "7": 1415,
        },
        "44.2": {
          "7": 2923,
          "8": 137,
        },
        "48.2": {
          "7": 164,
          "8": 4448,
          "9": 6,
        },
        "47.2": {
          "7": 825,
          "8": 3974,
        },
        "45.6": {
          "7": 2777,
          "8": 1225,
        },
        "49.4": {
          "7": 5,
          "8": 3949,
          "9": 159,
        },
        "45.2": {
          "7": 2989,
          "8": 801,
        },
        "45.8": {
          "7": 2607,
          "8": 1625,
        },
        "39.4": {
          "6": 313,
          "7": 74,
        },
        "50.8": {
          "8": 1917,
          "9": 840,
        },
        "48.4": {
          "7": 116,
          "8": 4464,
          "9": 20,
        },
        "46.2": {
          "7": 2154,
          "8": 2356,
        },
        "51.2": {
          "8": 1422,
          "9": 1059,
        },
        "50.4": {
          "8": 2558,
          "9": 640,
        },
        "45.4": {
          "7": 2803,
          "8": 1047,
        },
        "44.4": {
          "7": 2988,
          "8": 202,
        },
        "54.4": {
          "8": 10,
          "9": 447,
          "10": 19,
        },
        "44.6": {
          "7": 3076,
          "8": 323,
        },
        "49.8": {
          "8": 3264,
          "9": 326,
        },
        "52.2": {
          "8": 484,
          "9": 1142,
        },
        "47.6": {
          "7": 500,
          "8": 4277,
          "9": 1,
        },
        "52.4": {
          "8": 356,
          "9": 1132,
        },
        "43.6": {
          "7": 2528,
          "8": 22,
        },
        "42.6": {
          "6": 48,
          "7": 1750,
          "8": 1,
        },
        "40.8": {
          "6": 322,
          "7": 442,
        },
        "39.2": {
          "6": 293,
          "7": 45,
        },
        "41.4": {
          "6": 258,
          "7": 806,
        },
        "39.6": {
          "6": 334,
          "7": 79,
        },
        "51.8": {
          "8": 788,
          "9": 1136,
        },
        "54.6": {
          "8": 11,
          "9": 383,
          "10": 17,
        },
        "44.8": {
          "7": 3042,
          "8": 417,
        },
        "51.4": {
          "8": 1240,
          "9": 1131,
        },
        "43.4": {
          "6": 1,
          "7": 2425,
          "8": 17,
        },
        "42.4": {
          "6": 74,
          "7": 1540,
        },
        "43.2": {
          "6": 4,
          "7": 2125,
          "8": 7,
        },
        "38.8": {
          "6": 243,
          "7": 19,
        },
        "40.4": {
          "6": 365,
          "7": 251,
        },
        "52.6": {
          "8": 328,
          "9": 1069,
        },
        "50.2": {
          "8": 2764,
          "9": 508,
        },
        "55.6": {
          "9": 154,
          "10": 32,
        },
        "42.8": {
          "6": 29,
          "7": 1850,
          "8": 2,
        },
        "40.6": {
          "6": 337,
          "7": 396,
        },
        "38.2": {
          "6": 185,
          "7": 6,
        },
        "41.2": {
          "6": 297,
          "7": 658,
        },
        "55.2": {
          "8": 1,
          "9": 232,
          "10": 24,
        },
        "53.6": {
          "8": 73,
          "9": 722,
          "10": 8,
        },
        "41.6": {
          "6": 194,
          "7": 928,
        },
        "39.8": {
          "6": 312,
          "7": 117,
        },
        "51.6": {
          "8": 928,
          "9": 1121,
        },
        "53.4": {
          "8": 95,
          "9": 787,
          "10": 5,
        },
        "38.6": {
          "6": 202,
          "7": 14,
        },
        "52.8": {
          "8": 207,
          "9": 994,
        },
        "53.2": {
          "8": 124,
          "9": 845,
          "10": 4,
        },
        "34.8": {
          "5": 2,
          "6": 10,
        },
        "55.4": {
          "9": 179,
          "10": 20,
        },
        "37.2": {
          "6": 87,
        },
        "55.8": {
          "9": 130,
          "10": 25,
        },
        "38.4": {
          "6": 171,
          "7": 6,
        },
        "34.4": {
          "5": 7,
          "6": 9,
        },
        "54.2": {
          "8": 21,
          "9": 557,
          "10": 30,
        },
        "53.8": {
          "8": 35,
          "9": 615,
          "10": 12,
        },
        "56.8": {
          "9": 39,
          "10": 25,
        },
        "37.4": {
          "6": 106,
        },
        "37.8": {
          "6": 129,
        },
        "58.2": {
          "9": 5,
          "10": 11,
        },
        "36.2": {
          "6": 41,
        },
        "35.2": {
          "5": 3,
          "6": 27,
        },
        "57.2": {
          "9": 29,
          "10": 25,
        },
        "57.6": {
          "9": 8,
          "10": 9,
        },
        "36.4": {
          "5": 3,
          "6": 43,
        },
        "57.8": {
          "9": 12,
          "10": 14,
        },
        "37.6": {
          "6": 109,
        },
        "35.8": {
          "5": 1,
          "6": 32,
        },
        "35.6": {
          "5": 3,
          "6": 26,
        },
        "58.4": {
          "9": 8,
          "10": 12,
        },
        "36.8": {
          "5": 1,
          "6": 56,
        },
        "56.2": {
          "9": 61,
          "10": 29,
        },
        "56.4": {
          "9": 57,
          "10": 24,
        },
        "34.6": {
          "5": 4,
          "6": 7,
        },
        "56.6": {
          "9": 48,
          "10": 22,
        },
        "35.4": {
          "5": 7,
          "6": 20,
        },
        "57.4": {
          "9": 18,
          "10": 13,
        },
        "36.6": {
          "5": 1,
          "6": 47,
        },
        "58.6": {
          "9": 3,
          "10": 4,
        },
        "62.2": {
          "10": 1,
        },
        "59.4": {
          "10": 3,
        },
        "34.2": {
          "5": 5,
          "6": 4,
        },
        "58.8": {
          "9": 2,
          "10": 4,
        },
        "59.2": {
          "10": 2,
        },
        "60.2": {
          "10": 3,
        },
        "59.6": {
          "10": 1,
        },
        "60.4": {
          "11": 1,
        },
        "59.8": {
          "10": 1,
        },
      },
      KO: {
        "20": {
          "3": 2842,
        },
        "21": {
          "3": 6374,
        },
        "22": {
          "3": 8593,
          "4": 355,
        },
        "23": {
          "3": 4682,
          "4": 3935,
        },
        "24": {
          "3": 501,
          "4": 5678,
        },
        "25": {
          "4": 3467,
        },
        "26": {
          "4": 1516,
        },
        "23.2": {
          "3": 3659,
          "4": 4832,
        },
        "21.8": {
          "3": 8427,
          "4": 155,
        },
        "24.2": {
          "3": 253,
          "4": 5363,
        },
        "22.4": {
          "3": 7974,
          "4": 1344,
        },
        "21.2": {
          "3": 6897,
        },
        "20.8": {
          "3": 5527,
        },
        "22.8": {
          "3": 6024,
          "4": 2994,
        },
        "23.8": {
          "3": 1013,
          "4": 6097,
        },
        "22.6": {
          "3": 7006,
          "4": 2228,
        },
        "24.4": {
          "3": 122,
          "4": 4937,
        },
        "24.8": {
          "3": 4,
          "4": 3964,
        },
        "21.4": {
          "3": 7667,
          "4": 24,
        },
        "22.2": {
          "3": 8540,
          "4": 800,
        },
        "23.6": {
          "3": 1605,
          "4": 5781,
        },
        "21.6": {
          "3": 8170,
          "4": 48,
        },
        "25.2": {
          "4": 2926,
        },
        "25.4": {
          "4": 2493,
        },
        "20.4": {
          "3": 4158,
        },
        "20.2": {
          "3": 3458,
        },
        "23.4": {
          "3": 2494,
          "4": 5560,
        },
        "25.8": {
          "4": 1907,
        },
        "19.6": {
          "2": 86,
          "3": 6763,
        },
        "25.6": {
          "4": 2273,
        },
        "26.2": {
          "4": 6241,
          "5": 435,
        },
        "20.6": {
          "3": 4801,
        },
        "24.6": {
          "3": 27,
          "4": 4633,
        },
        "19.8": {
          "3": 2347,
        },
      },
      TD: {
        "7": {
          "1": 784,
        },
        "8": {
          "1": 2077,
        },
        "9": {
          "1": 4332,
        },
        "10": {
          "1": 6814,
        },
        "11": {
          "1": 8029,
          "2": 395,
        },
        "12": {
          "1": 515,
          "2": 7066,
        },
        "13": {
          "2": 5392,
        },
        "14": {
          "2": 2914,
        },
        "15": {
          "2": 1276,
        },
        "16": {
          "2": 435,
          "3": 1,
        },
        "17": {
          "2": 73,
          "3": 31,
        },
        "8.2": {
          "1": 2426,
        },
        "9.6": {
          "1": 5785,
        },
        "11.6": {
          "1": 3378,
          "2": 4798,
        },
        "13.6": {
          "2": 3855,
        },
        "9.4": {
          "1": 5287,
        },
        "13.8": {
          "2": 3325,
        },
        "10.4": {
          "1": 7611,
        },
        "9.2": {
          "1": 4733,
        },
        "12.2": {
          "1": 90,
          "2": 7042,
        },
        "12.8": {
          "2": 5705,
        },
        "10.6": {
          "1": 7771,
          "2": 3,
        },
        "9.8": {
          "1": 6342,
        },
        "8.4": {
          "1": 2851,
        },
        "14.4": {
          "2": 2127,
        },
        "12.4": {
          "1": 8,
          "2": 6800,
        },
        "11.2": {
          "1": 6971,
          "2": 1340,
        },
        "14.2": {
          "2": 2474,
        },
        "8.6": {
          "1": 3279,
        },
        "12.6": {
          "2": 6389,
        },
        "11.8": {
          "1": 1494,
          "2": 6280,
        },
        "10.8": {
          "1": 8079,
          "2": 47,
        },
        "11.4": {
          "1": 5315,
          "2": 2973,
        },
        "10.2": {
          "1": 7169,
        },
        "7.6": {
          "1": 1432,
        },
        "13.4": {
          "2": 4368,
        },
        "8.8": {
          "1": 3837,
        },
        "15.8": {
          "2": 481,
        },
        "14.6": {
          "2": 1749,
        },
        "13.2": {
          "2": 4787,
        },
        "7.8": {
          "1": 1632,
        },
        "7.2": {
          "1": 936,
        },
        "6.2": {
          "0": 526,
          "1": 545,
        },
        "16.2": {
          "2": 307,
          "3": 3,
        },
        "6.6": {
          "1": 493,
        },
        "15.6": {
          "2": 709,
        },
        "17.2": {
          "2": 100,
          "3": 216,
        },
        "14.8": {
          "2": 1456,
        },
        "6.4": {
          "1": 375,
        },
        "7.4": {
          "1": 1214,
        },
        "6.8": {
          "1": 602,
        },
        "15.2": {
          "2": 1000,
        },
        "15.4": {
          "2": 786,
        },
        "16.6": {
          "2": 173,
          "3": 12,
        },
        "16.4": {
          "2": 246,
          "3": 6,
        },
        "16.8": {
          "2": 107,
          "3": 21,
        },
      },
      KONF: {
        "5": {
          "0": 263,
        },
        "6": {
          "0": 65,
          "1": 541,
        },
        "7": {
          "1": 1201,
        },
        "8": {
          "1": 2119,
        },
        "9": {
          "1": 3298,
        },
        "10": {
          "1": 4721,
        },
        "11": {
          "1": 5358,
          "2": 267,
        },
        "12": {
          "1": 369,
          "2": 5666,
        },
        "13": {
          "2": 5573,
        },
        "14": {
          "2": 4318,
        },
        "15": {
          "2": 3009,
        },
        "16": {
          "2": 1699,
          "3": 3,
        },
        "17": {
          "2": 627,
          "3": 271,
        },
        "18": {
          "2": 27,
          "3": 377,
        },
        "19": {
          "3": 128,
        },
        "20": {
          "3": 47,
        },
        "14.8": {
          "2": 3124,
        },
        "13.6": {
          "2": 4860,
        },
        "8.6": {
          "1": 2912,
        },
        "7.2": {
          "1": 1350,
        },
        "12.6": {
          "2": 5818,
        },
        "16.6": {
          "2": 1066,
          "3": 101,
        },
        "15.2": {
          "2": 2744,
        },
        "12.4": {
          "1": 4,
          "2": 5781,
        },
        "11.4": {
          "1": 3653,
          "2": 2173,
        },
        "11.2": {
          "1": 4720,
          "2": 968,
        },
        "15.4": {
          "2": 2481,
        },
        "12.2": {
          "1": 71,
          "2": 5847,
        },
        "11.6": {
          "1": 2331,
          "2": 3667,
        },
        "9.2": {
          "1": 3563,
        },
        "13.8": {
          "2": 4713,
        },
        "13.4": {
          "2": 5164,
        },
        "15.6": {
          "2": 2265,
        },
        "8.2": {
          "1": 2327,
        },
        "10.8": {
          "1": 5471,
          "2": 32,
        },
        "10.4": {
          "1": 5158,
        },
        "10.2": {
          "1": 4910,
        },
        "16.2": {
          "2": 1453,
          "3": 14,
        },
        "17.2": {
          "2": 366,
          "3": 330,
        },
        "8.8": {
          "1": 3019,
        },
        "14.4": {
          "2": 3685,
        },
        "10.6": {
          "1": 5275,
          "2": 2,
        },
        "6.8": {
          "1": 1039,
        },
        "14.6": {
          "2": 3478,
        },
        "11.8": {
          "1": 998,
          "2": 4952,
        },
        "16.4": {
          "2": 1338,
          "3": 42,
        },
        "7.6": {
          "1": 1677,
        },
        "14.2": {
          "2": 4142,
        },
        "12.8": {
          "2": 5674,
        },
        "18.2": {
          "2": 5,
          "3": 316,
        },
        "18.6": {
          "3": 209,
        },
        "9.4": {
          "1": 3885,
        },
        "9.6": {
          "1": 4249,
        },
        "16.8": {
          "2": 862,
          "3": 207,
        },
        "7.8": {
          "1": 1885,
        },
        "15.8": {
          "2": 1953,
          "3": 1,
        },
        "13.2": {
          "2": 5246,
        },
        "17.6": {
          "2": 121,
          "3": 410,
        },
        "9.8": {
          "1": 4493,
        },
        "19.2": {
          "3": 115,
        },
        "7.4": {
          "1": 1442,
        },
        "8.4": {
          "1": 2591,
        },
        "4.8": {
          "0": 244,
        },
        "17.8": {
          "2": 47,
          "3": 399,
        },
        "18.4": {
          "2": 2,
          "3": 282,
        },
        "6.4": {
          "1": 809,
        },
        "17.4": {
          "2": 244,
          "3": 396,
        },
        "20.6": {
          "3": 71,
          "4": 4,
        },
        "6.6": {
          "1": 989,
        },
        "19.4": {
          "3": 100,
        },
        "4.6": {
          "0": 1007,
        },
        "19.8": {
          "3": 63,
        },
        "5.2": {
          "0": 299,
        },
        "6.2": {
          "0": 2,
          "1": 689,
        },
        "5.6": {
          "0": 404,
          "1": 54,
        },
        "5.4": {
          "0": 367,
          "1": 8,
        },
        "5.8": {
          "0": 193,
          "1": 287,
        },
        "18.8": {
          "3": 190,
        },
        "19.6": {
          "3": 81,
        },
        "20.2": {
          "3": 42,
        },
        "20.4": {
          "3": 32,
        },
      },
      LEWICA: {
        "5": {
          "0": 2357,
        },
        "6": {
          "0": 398,
          "1": 9053,
        },
        "7": {
          "1": 14621,
        },
        "8": {
          "1": 9750,
        },
        "9": {
          "1": 2910,
        },
        "10": {
          "1": 480,
        },
        "7.2": {
          "1": 14761,
        },
        "5.6": {
          "0": 4301,
          "1": 1768,
        },
        "8.4": {
          "1": 6467,
        },
        "8.6": {
          "1": 5206,
        },
        "6.8": {
          "1": 14794,
        },
        "4.8": {
          "0": 1585,
        },
        "7.8": {
          "1": 11212,
        },
        "6.2": {
          "0": 17,
          "1": 11225,
        },
        "5.8": {
          "0": 2108,
          "1": 5510,
        },
        "7.4": {
          "1": 13893,
        },
        "6.6": {
          "1": 13798,
        },
        "6.4": {
          "1": 12608,
        },
        "5.2": {
          "0": 3310,
          "1": 1,
        },
        "7.6": {
          "1": 12779,
        },
        "5.4": {
          "0": 4409,
          "1": 169,
        },
        "4.4": {
          "0": 1442,
        },
        "8.2": {
          "1": 8155,
        },
        "8.8": {
          "1": 3814,
        },
        "10.2": {
          "1": 726,
          "2": 39,
        },
        "9.6": {
          "1": 1039,
        },
        "9.2": {
          "1": 2102,
        },
        "9.4": {
          "1": 1513,
        },
        "4.6": {
          "0": 1006,
        },
        "9.8": {
          "1": 674,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2406,
      TD: 3790,
      LEWICA: 5728,
    },
    potencjalneMandaty: {
      KO: 52832,
      TD: 50779,
      LEWICA: 23138,
    },
    ostatnieMandaty: {
      KO: 46404,
      TD: 26680,
      LEWICA: 13779,
    },
    dawcyGlosow: {
      KO: 5805,
      TD: 9145,
      LEWICA: 16751,
    },
  },
  Chełm: {
    procentyWOkreguSrednia: {
      PIS: 52.26271647084358,
      KO: 17.40717886933349,
      TD: 13.07653919454835,
      KONF: 11.479766663058966,
      LEWICA: 5.7737988022156195,
    },
    odchylenieWOkregu: {
      PIS: 8.746083008674193,
      KO: 1.4942817841784481,
      TD: 2.013774734692939,
      LEWICA: 1.9762274531729267,
      KONF: 4.281915326206786,
    },
    wykresRozkladu: {
      "37.9%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 1,
      },
      "24.7%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 2,
      },
      "17%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
      "7.9%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "3.5%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "3.4%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 1,
      },
      "1.3%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "1.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "0.8%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 1,
      },
      "0.6%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 2,
      },
      "0.3%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "45.4%": {
        DEMO: 4,
        NIEDEMO: 8,
      },
      "39.3%": {
        DEMO: 3,
        NIEDEMO: 9,
      },
      "12.8%": {
        DEMO: 5,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "31": {
          "4": 4,
        },
        "32": {
          "4": 4,
        },
        "33": {
          "4": 6,
          "5": 3,
        },
        "34": {
          "4": 13,
          "5": 10,
        },
        "35": {
          "4": 11,
          "5": 13,
        },
        "36": {
          "4": 4,
          "5": 41,
        },
        "37": {
          "4": 2,
          "5": 71,
        },
        "38": {
          "5": 124,
        },
        "39": {
          "5": 164,
          "6": 6,
        },
        "40": {
          "5": 202,
          "6": 23,
        },
        "41": {
          "5": 203,
          "6": 99,
        },
        "42": {
          "5": 176,
          "6": 298,
        },
        "43": {
          "5": 94,
          "6": 537,
        },
        "44": {
          "5": 30,
          "6": 798,
        },
        "45": {
          "5": 5,
          "6": 1071,
          "7": 19,
        },
        "46": {
          "6": 1278,
          "7": 140,
        },
        "47": {
          "6": 1277,
          "7": 432,
        },
        "48": {
          "6": 1047,
          "7": 1026,
        },
        "49": {
          "6": 673,
          "7": 1684,
        },
        "50": {
          "6": 338,
          "7": 2423,
        },
        "51": {
          "6": 130,
          "7": 2886,
          "8": 26,
        },
        "52": {
          "6": 25,
          "7": 2852,
          "8": 203,
        },
        "53": {
          "6": 1,
          "7": 2614,
          "8": 602,
        },
        "54": {
          "7": 1968,
          "8": 1123,
        },
        "55": {
          "7": 1210,
          "8": 1667,
        },
        "56": {
          "7": 592,
          "8": 1921,
        },
        "57": {
          "7": 233,
          "8": 1856,
          "9": 2,
        },
        "58": {
          "7": 65,
          "8": 1606,
          "9": 28,
        },
        "59": {
          "7": 18,
          "8": 1237,
          "9": 84,
        },
        "60": {
          "7": 2,
          "8": 755,
          "9": 164,
        },
        "61": {
          "8": 430,
          "9": 203,
        },
        "62": {
          "8": 189,
          "9": 190,
        },
        "63": {
          "8": 101,
          "9": 178,
        },
        "64": {
          "8": 28,
          "9": 124,
        },
        "65": {
          "8": 7,
          "9": 63,
          "10": 2,
        },
        "66": {
          "8": 1,
          "9": 43,
          "10": 1,
        },
        "67": {
          "8": 1,
          "9": 21,
        },
        "68": {
          "9": 4,
          "10": 1,
        },
        "69": {
          "9": 6,
          "10": 2,
        },
        "70": {
          "9": 1,
        },
        "53.2": {
          "6": 4,
          "7": 2456,
          "8": 686,
        },
        "55.6": {
          "7": 855,
          "8": 1822,
        },
        "49.6": {
          "6": 433,
          "7": 2137,
        },
        "62.4": {
          "8": 152,
          "9": 180,
        },
        "55.8": {
          "7": 767,
          "8": 1845,
        },
        "46.8": {
          "6": 1262,
          "7": 402,
        },
        "51.6": {
          "6": 48,
          "7": 2975,
          "8": 111,
        },
        "46.6": {
          "6": 1232,
          "7": 294,
        },
        "60.4": {
          "8": 601,
          "9": 186,
        },
        "56.4": {
          "7": 449,
          "8": 1901,
        },
        "54.4": {
          "7": 1645,
          "8": 1295,
        },
        "52.4": {
          "6": 10,
          "7": 2870,
          "8": 310,
        },
        "59.2": {
          "7": 9,
          "8": 1089,
          "9": 116,
        },
        "33.8": {
          "4": 15,
          "5": 6,
        },
        "60.8": {
          "8": 484,
          "9": 212,
        },
        "47.8": {
          "6": 1082,
          "7": 889,
        },
        "51.4": {
          "6": 67,
          "7": 2942,
          "8": 63,
        },
        "50.4": {
          "6": 251,
          "7": 2706,
          "8": 2,
        },
        "54.2": {
          "7": 1835,
          "8": 1177,
        },
        "56.6": {
          "7": 410,
          "8": 1858,
        },
        "48.4": {
          "6": 866,
          "7": 1354,
        },
        "44.6": {
          "5": 15,
          "6": 980,
          "7": 6,
        },
        "52.2": {
          "6": 22,
          "7": 2951,
          "8": 233,
        },
        "54.6": {
          "7": 1533,
          "8": 1457,
        },
        "59.4": {
          "7": 13,
          "8": 982,
          "9": 127,
        },
        "56.8": {
          "7": 320,
          "8": 1879,
          "9": 2,
        },
        "55.4": {
          "7": 951,
          "8": 1802,
        },
        "46.4": {
          "6": 1386,
          "7": 211,
        },
        "45.2": {
          "5": 5,
          "6": 1127,
          "7": 44,
        },
        "43.6": {
          "5": 55,
          "6": 682,
        },
        "51.2": {
          "6": 96,
          "7": 2940,
          "8": 38,
        },
        "47.4": {
          "6": 1275,
          "7": 680,
        },
        "39.4": {
          "5": 175,
          "6": 15,
        },
        "49.4": {
          "6": 576,
          "7": 2081,
        },
        "48.8": {
          "6": 781,
          "7": 1579,
        },
        "61.4": {
          "8": 345,
          "9": 244,
        },
        "53.4": {
          "7": 2405,
          "8": 782,
        },
        "53.6": {
          "7": 2192,
          "8": 940,
        },
        "52.6": {
          "6": 4,
          "7": 2792,
          "8": 425,
        },
        "63.4": {
          "8": 50,
          "9": 133,
        },
        "49.8": {
          "6": 391,
          "7": 2265,
        },
        "59.6": {
          "7": 3,
          "8": 960,
          "9": 161,
        },
        "42.2": {
          "5": 148,
          "6": 347,
        },
        "45.4": {
          "5": 1,
          "6": 1126,
          "7": 54,
        },
        "44.2": {
          "5": 32,
          "6": 868,
          "7": 1,
        },
        "62.6": {
          "8": 114,
          "9": 206,
        },
        "48.2": {
          "6": 1036,
          "7": 1208,
        },
        "41.6": {
          "5": 203,
          "6": 215,
        },
        "58.6": {
          "7": 25,
          "8": 1289,
          "9": 68,
        },
        "43.8": {
          "5": 39,
          "6": 758,
        },
        "65.8": {
          "8": 3,
          "9": 47,
          "10": 3,
        },
        "51.8": {
          "6": 30,
          "7": 2979,
          "8": 150,
        },
        "43.4": {
          "5": 61,
          "6": 640,
        },
        "52.8": {
          "6": 8,
          "7": 2667,
          "8": 482,
        },
        "43.2": {
          "5": 77,
          "6": 557,
        },
        "45.6": {
          "5": 1,
          "6": 1170,
          "7": 62,
        },
        "55.2": {
          "7": 1093,
          "8": 1753,
        },
        "54.8": {
          "7": 1375,
          "8": 1577,
        },
        "50.8": {
          "6": 147,
          "7": 2818,
          "8": 20,
        },
        "48.6": {
          "6": 822,
          "7": 1425,
        },
        "62.8": {
          "8": 120,
          "9": 169,
        },
        "49.2": {
          "6": 622,
          "7": 1894,
        },
        "56.2": {
          "7": 513,
          "8": 1877,
        },
        "58.8": {
          "7": 16,
          "8": 1222,
          "9": 83,
        },
        "47.6": {
          "6": 1146,
          "7": 780,
        },
        "50.6": {
          "6": 199,
          "7": 2722,
          "8": 7,
        },
        "42.6": {
          "5": 118,
          "6": 456,
        },
        "61.8": {
          "8": 245,
          "9": 234,
        },
        "60.2": {
          "8": 695,
          "9": 185,
        },
        "58.2": {
          "7": 74,
          "8": 1500,
          "9": 53,
        },
        "53.8": {
          "7": 2088,
          "8": 1043,
        },
        "61.6": {
          "8": 268,
          "9": 219,
        },
        "47.2": {
          "6": 1263,
          "7": 580,
        },
        "57.6": {
          "7": 133,
          "8": 1729,
          "9": 19,
        },
        "38.6": {
          "5": 123,
        },
        "57.8": {
          "7": 99,
          "8": 1602,
          "9": 23,
        },
        "59.8": {
          "7": 4,
          "8": 838,
          "9": 159,
        },
        "40.6": {
          "5": 224,
          "6": 73,
        },
        "41.2": {
          "5": 185,
          "6": 159,
        },
        "57.2": {
          "7": 219,
          "8": 1831,
          "9": 7,
        },
        "39.2": {
          "5": 180,
          "6": 5,
        },
        "50.2": {
          "6": 291,
          "7": 2537,
          "8": 2,
        },
        "65.2": {
          "8": 4,
          "9": 73,
        },
        "34.8": {
          "4": 11,
          "5": 22,
        },
        "38.2": {
          "5": 120,
          "6": 1,
        },
        "37.6": {
          "5": 95,
        },
        "46.2": {
          "6": 1325,
          "7": 178,
        },
        "58.4": {
          "7": 44,
          "8": 1440,
          "9": 61,
        },
        "57.4": {
          "7": 178,
          "8": 1761,
          "9": 11,
        },
        "34.2": {
          "4": 13,
          "5": 6,
        },
        "41.8": {
          "5": 151,
          "6": 265,
        },
        "45.8": {
          "6": 1290,
          "7": 87,
        },
        "44.4": {
          "5": 21,
          "6": 888,
          "7": 3,
        },
        "61.2": {
          "8": 353,
          "9": 233,
        },
        "64.2": {
          "8": 14,
          "9": 121,
        },
        "37.4": {
          "4": 1,
          "5": 85,
        },
        "62.2": {
          "8": 184,
          "9": 185,
        },
        "40.8": {
          "5": 204,
          "6": 93,
        },
        "42.8": {
          "5": 120,
          "6": 512,
        },
        "38.8": {
          "5": 169,
          "6": 5,
        },
        "44.8": {
          "5": 7,
          "6": 1027,
          "7": 10,
        },
        "39.6": {
          "5": 205,
          "6": 18,
        },
        "63.8": {
          "8": 33,
          "9": 121,
        },
        "60.6": {
          "8": 557,
          "9": 219,
        },
        "32.8": {
          "4": 17,
          "5": 1,
        },
        "37.8": {
          "4": 2,
          "5": 93,
        },
        "40.2": {
          "5": 180,
          "6": 53,
        },
        "63.2": {
          "8": 74,
          "9": 174,
        },
        "41.4": {
          "5": 207,
          "6": 202,
        },
        "65.6": {
          "8": 2,
          "9": 53,
        },
        "33.6": {
          "4": 18,
          "5": 6,
        },
        "36.8": {
          "4": 2,
          "5": 63,
        },
        "64.8": {
          "8": 9,
          "9": 94,
          "10": 1,
        },
        "42.4": {
          "5": 135,
          "6": 357,
        },
        "64.6": {
          "8": 10,
          "9": 83,
          "10": 1,
        },
        "66.2": {
          "8": 2,
          "9": 30,
          "10": 1,
        },
        "63.6": {
          "8": 42,
          "9": 151,
          "10": 1,
        },
        "30.6": {
          "4": 7,
        },
        "68.2": {
          "9": 7,
        },
        "34.4": {
          "4": 11,
          "5": 9,
        },
        "65.4": {
          "8": 3,
          "9": 46,
        },
        "39.8": {
          "5": 195,
          "6": 23,
        },
        "67.8": {
          "9": 8,
          "10": 1,
        },
        "37.2": {
          "4": 2,
          "5": 82,
        },
        "36.2": {
          "4": 5,
          "5": 54,
        },
        "30.4": {
          "2": 1,
          "3": 20,
          "4": 46,
        },
        "38.4": {
          "5": 148,
          "6": 1,
        },
        "40.4": {
          "5": 198,
          "6": 59,
        },
        "35.8": {
          "4": 3,
          "5": 37,
        },
        "67.2": {
          "9": 18,
          "10": 3,
        },
        "66.8": {
          "8": 2,
          "9": 21,
        },
        "32.2": {
          "4": 14,
        },
        "33.2": {
          "4": 19,
        },
        "36.4": {
          "4": 2,
          "5": 50,
        },
        "66.4": {
          "8": 1,
          "9": 26,
        },
        "67.4": {
          "9": 13,
          "10": 1,
        },
        "35.4": {
          "4": 8,
          "5": 27,
        },
        "36.6": {
          "4": 3,
          "5": 61,
        },
        "64.4": {
          "8": 19,
          "9": 87,
          "10": 1,
        },
        "66.6": {
          "9": 26,
          "10": 1,
        },
        "32.4": {
          "4": 7,
          "5": 2,
        },
        "68.6": {
          "9": 4,
          "10": 1,
        },
        "68.4": {
          "9": 3,
        },
        "35.6": {
          "4": 7,
          "5": 32,
        },
        "69.6": {
          "9": 2,
          "10": 2,
        },
        "31.8": {
          "4": 6,
        },
        "34.6": {
          "4": 19,
          "5": 15,
        },
        "35.2": {
          "4": 15,
          "5": 21,
        },
        "30.8": {
          "4": 9,
        },
        "32.6": {
          "4": 10,
          "5": 1,
        },
        "67.6": {
          "9": 13,
          "10": 1,
        },
        "33.4": {
          "4": 12,
        },
        "68.8": {
          "9": 5,
        },
        "31.4": {
          "4": 7,
        },
        "31.2": {
          "4": 6,
        },
        "70.4": {
          "9": 1,
        },
        "69.2": {
          "10": 1,
        },
        "31.6": {
          "4": 9,
        },
        "69.4": {
          "9": 2,
        },
        "69.8": {
          "9": 1,
        },
        "70.6": {
          "9": 1,
        },
        "71.2": {
          "10": 1,
        },
        "70.2": {
          "9": 1,
        },
        "71.6": {
          "10": 1,
        },
      },
      KO: {
        "14": {
          "1": 438,
          "2": 1436,
        },
        "15": {
          "1": 7,
          "2": 4136,
        },
        "16": {
          "2": 6564,
        },
        "17": {
          "2": 7348,
        },
        "18": {
          "2": 6893,
        },
        "19": {
          "2": 5129,
          "3": 13,
        },
        "20": {
          "2": 2840,
          "3": 640,
        },
        "21": {
          "2": 653,
          "3": 1300,
        },
        "16.8": {
          "2": 7419,
        },
        "13.6": {
          "1": 3923,
          "2": 1074,
        },
        "17.2": {
          "2": 7461,
        },
        "17.6": {
          "2": 7327,
        },
        "17.8": {
          "2": 6947,
        },
        "20.2": {
          "2": 2139,
          "3": 761,
        },
        "18.8": {
          "2": 5607,
          "3": 1,
        },
        "14.6": {
          "1": 72,
          "2": 3170,
        },
        "14.4": {
          "1": 144,
          "2": 2570,
        },
        "18.2": {
          "2": 6632,
        },
        "14.8": {
          "1": 27,
          "2": 3653,
        },
        "20.8": {
          "2": 955,
          "3": 1247,
        },
        "20.4": {
          "2": 1737,
          "3": 956,
        },
        "18.4": {
          "2": 6399,
        },
        "15.2": {
          "1": 4,
          "2": 4743,
        },
        "19.4": {
          "2": 4268,
          "3": 141,
        },
        "18.6": {
          "2": 5829,
        },
        "15.6": {
          "2": 5565,
        },
        "14.2": {
          "1": 288,
          "2": 1971,
        },
        "19.2": {
          "2": 4844,
          "3": 54,
        },
        "19.6": {
          "2": 3786,
          "3": 274,
        },
        "16.2": {
          "2": 6763,
        },
        "16.4": {
          "2": 7167,
        },
        "17.4": {
          "2": 7460,
        },
        "16.6": {
          "2": 7187,
        },
        "15.8": {
          "2": 6031,
        },
        "15.4": {
          "2": 5228,
        },
        "21.8": {
          "2": 154,
          "3": 8213,
          "4": 101,
        },
        "13.8": {
          "1": 564,
          "2": 991,
        },
        "19.8": {
          "2": 3307,
          "3": 424,
        },
        "21.4": {
          "2": 234,
          "3": 1246,
        },
        "21.6": {
          "2": 134,
          "3": 1214,
        },
        "21.2": {
          "2": 462,
          "3": 1285,
        },
        "20.6": {
          "2": 1336,
          "3": 1114,
        },
      },
      TD: {
        "8": {
          "0": 223,
          "1": 1180,
        },
        "9": {
          "1": 1100,
        },
        "10": {
          "1": 2744,
        },
        "11": {
          "1": 4762,
        },
        "12": {
          "1": 6632,
        },
        "13": {
          "1": 6899,
          "2": 343,
        },
        "14": {
          "1": 1870,
          "2": 4477,
        },
        "15": {
          "1": 17,
          "2": 4711,
        },
        "16": {
          "2": 2911,
        },
        "17": {
          "2": 1546,
        },
        "18": {
          "2": 777,
        },
        "19": {
          "2": 334,
          "3": 1,
        },
        "12.6": {
          "1": 7229,
          "2": 8,
        },
        "14.2": {
          "1": 1087,
          "2": 4948,
        },
        "10.6": {
          "1": 4041,
        },
        "14.8": {
          "1": 74,
          "2": 4901,
        },
        "11.4": {
          "1": 5779,
        },
        "15.2": {
          "1": 3,
          "2": 4277,
        },
        "13.6": {
          "1": 4101,
          "2": 2714,
        },
        "13.2": {
          "1": 6298,
          "2": 921,
        },
        "11.6": {
          "1": 6053,
        },
        "12.2": {
          "1": 7018,
        },
        "10.8": {
          "1": 4432,
        },
        "13.4": {
          "1": 5464,
          "2": 1701,
        },
        "13.8": {
          "1": 2913,
          "2": 3724,
        },
        "14.4": {
          "1": 547,
          "2": 5284,
        },
        "19.2": {
          "2": 1259,
          "3": 456,
        },
        "9.8": {
          "1": 2350,
        },
        "11.2": {
          "1": 5228,
        },
        "11.8": {
          "1": 6488,
        },
        "16.4": {
          "2": 2281,
        },
        "10.2": {
          "1": 3009,
        },
        "16.6": {
          "2": 2038,
        },
        "12.8": {
          "1": 7285,
          "2": 79,
        },
        "15.8": {
          "2": 3311,
        },
        "8.8": {
          "1": 959,
        },
        "15.6": {
          "2": 3490,
        },
        "16.8": {
          "2": 1795,
        },
        "14.6": {
          "1": 252,
          "2": 5181,
        },
        "15.4": {
          "2": 3869,
        },
        "12.4": {
          "1": 7198,
        },
        "9.6": {
          "1": 1995,
        },
        "9.4": {
          "1": 1635,
        },
        "18.2": {
          "2": 646,
        },
        "10.4": {
          "1": 3504,
        },
        "8.6": {
          "1": 742,
        },
        "9.2": {
          "1": 1334,
        },
        "17.8": {
          "2": 901,
        },
        "16.2": {
          "2": 2626,
        },
        "17.4": {
          "2": 1178,
        },
        "17.6": {
          "2": 1077,
        },
        "17.2": {
          "2": 1345,
        },
        "18.6": {
          "2": 469,
        },
        "18.4": {
          "2": 570,
        },
        "8.2": {
          "1": 458,
        },
        "8.4": {
          "1": 592,
        },
        "18.8": {
          "2": 356,
        },
      },
      KONF: {
        "1": {
          "0": 164,
        },
        "2": {
          "0": 262,
        },
        "3": {
          "0": 444,
        },
        "4": {
          "0": 678,
        },
        "5": {
          "0": 1017,
        },
        "6": {
          "0": 1492,
        },
        "7": {
          "0": 578,
          "1": 1516,
        },
        "8": {
          "1": 2666,
        },
        "9": {
          "1": 3318,
        },
        "10": {
          "1": 3762,
        },
        "11": {
          "1": 4073,
        },
        "12": {
          "1": 4041,
        },
        "13": {
          "1": 3561,
          "2": 190,
        },
        "14": {
          "1": 869,
          "2": 2491,
        },
        "15": {
          "1": 11,
          "2": 2705,
        },
        "16": {
          "2": 2109,
        },
        "17": {
          "2": 1545,
        },
        "18": {
          "2": 1031,
        },
        "19": {
          "2": 625,
        },
        "20": {
          "2": 306,
          "3": 81,
        },
        "21": {
          "2": 50,
          "3": 127,
        },
        "22": {
          "2": 4,
          "3": 89,
        },
        "23": {
          "3": 48,
        },
        "24": {
          "3": 18,
        },
        "13.2": {
          "1": 3167,
          "2": 500,
        },
        "7.4": {
          "0": 44,
          "1": 2231,
        },
        "12.8": {
          "1": 3870,
          "2": 46,
        },
        "10.8": {
          "1": 3991,
        },
        "16.2": {
          "2": 1974,
        },
        "17.2": {
          "2": 1380,
        },
        "9.6": {
          "1": 3550,
        },
        "16.6": {
          "2": 1724,
        },
        "7.8": {
          "1": 2492,
        },
        "8.4": {
          "1": 2920,
        },
        "8.8": {
          "1": 3148,
        },
        "11.4": {
          "1": 4151,
        },
        "14.6": {
          "1": 120,
          "2": 2856,
        },
        "10.2": {
          "1": 3778,
        },
        "7.2": {
          "0": 213,
          "1": 1989,
        },
        "13.4": {
          "1": 2665,
          "2": 937,
        },
        "16.4": {
          "2": 1851,
        },
        "8.6": {
          "1": 3035,
        },
        "3.8": {
          "0": 589,
        },
        "18.8": {
          "2": 721,
        },
        "15.2": {
          "1": 1,
          "2": 2785,
        },
        "17.6": {
          "2": 1143,
        },
        "14.8": {
          "1": 32,
          "2": 2809,
        },
        "15.4": {
          "2": 2469,
        },
        "24.4": {
          "3": 77,
          "4": 9,
        },
        "6.6": {
          "0": 1585,
          "1": 245,
        },
        "11.6": {
          "1": 4063,
        },
        "2.6": {
          "0": 381,
        },
        "14.2": {
          "1": 506,
          "2": 2663,
        },
        "0.8": {
          "0": 1233,
        },
        "12.2": {
          "1": 4038,
        },
        "6.4": {
          "0": 1695,
          "1": 31,
        },
        "1.8": {
          "0": 250,
        },
        "20.2": {
          "2": 212,
          "3": 111,
        },
        "7.6": {
          "0": 2,
          "1": 2350,
        },
        "19.2": {
          "2": 556,
          "3": 12,
        },
        "10.6": {
          "1": 3955,
        },
        "12.4": {
          "1": 3919,
        },
        "10.4": {
          "1": 3945,
        },
        "13.8": {
          "1": 1394,
          "2": 1995,
        },
        "9.8": {
          "1": 3552,
        },
        "9.4": {
          "1": 3479,
        },
        "5.2": {
          "0": 1133,
        },
        "13.6": {
          "1": 1982,
          "2": 1491,
        },
        "2.8": {
          "0": 389,
        },
        "18.6": {
          "2": 787,
        },
        "5.6": {
          "0": 1338,
        },
        "9.2": {
          "1": 3427,
        },
        "11.2": {
          "1": 4021,
        },
        "12.6": {
          "1": 4002,
          "2": 7,
        },
        "14.4": {
          "1": 240,
          "2": 2853,
        },
        "8.2": {
          "1": 2812,
        },
        "6.2": {
          "0": 1608,
        },
        "5.4": {
          "0": 1263,
        },
        "18.4": {
          "2": 818,
        },
        "5.8": {
          "0": 1439,
        },
        "6.8": {
          "0": 1165,
          "1": 793,
        },
        "3.2": {
          "0": 460,
        },
        "19.8": {
          "2": 339,
          "3": 69,
        },
        "20.6": {
          "2": 105,
          "3": 123,
        },
        "11.8": {
          "1": 3990,
        },
        "3.4": {
          "0": 535,
        },
        "17.4": {
          "2": 1295,
        },
        "4.2": {
          "0": 786,
        },
        "21.2": {
          "2": 32,
          "3": 153,
        },
        "2.4": {
          "0": 332,
        },
        "16.8": {
          "2": 1608,
        },
        "4.6": {
          "0": 909,
        },
        "3.6": {
          "0": 611,
        },
        "15.6": {
          "2": 2268,
        },
        "4.8": {
          "0": 967,
        },
        "18.2": {
          "2": 899,
        },
        "21.8": {
          "2": 5,
          "3": 129,
        },
        "15.8": {
          "2": 2298,
        },
        "17.8": {
          "2": 1112,
        },
        "19.4": {
          "2": 513,
          "3": 22,
        },
        "1.4": {
          "0": 231,
        },
        "2.2": {
          "0": 344,
        },
        "22.4": {
          "3": 74,
        },
        "19.6": {
          "2": 397,
          "3": 24,
        },
        "1.6": {
          "0": 229,
        },
        "22.8": {
          "3": 63,
        },
        "4.4": {
          "0": 861,
        },
        "22.6": {
          "3": 73,
        },
        "22.2": {
          "3": 100,
        },
        "20.4": {
          "2": 170,
          "3": 119,
        },
        "23.2": {
          "3": 49,
        },
        "20.8": {
          "2": 85,
          "3": 159,
        },
        "21.4": {
          "2": 20,
          "3": 137,
        },
        "21.6": {
          "2": 9,
          "3": 130,
        },
        "1.2": {
          "0": 183,
        },
        "23.6": {
          "3": 34,
        },
        "24.2": {
          "3": 18,
        },
        "23.4": {
          "3": 32,
        },
        "23.8": {
          "3": 21,
        },
      },
      LEWICA: {
        "1": {
          "0": 404,
        },
        "2": {
          "0": 1210,
        },
        "3": {
          "0": 2921,
        },
        "4": {
          "0": 5279,
        },
        "5": {
          "0": 7546,
        },
        "6": {
          "0": 8096,
        },
        "7": {
          "0": 2173,
          "1": 4408,
        },
        "8": {
          "1": 4176,
        },
        "9": {
          "1": 2133,
        },
        "10": {
          "1": 806,
        },
        "11": {
          "1": 313,
        },
        "6.2": {
          "0": 7942,
          "1": 1,
        },
        "2.8": {
          "0": 2535,
        },
        "8.2": {
          "1": 3604,
        },
        "4.2": {
          "0": 5886,
        },
        "5.6": {
          "0": 8220,
        },
        "4.6": {
          "0": 6985,
        },
        "3.2": {
          "0": 3409,
        },
        "5.8": {
          "0": 8325,
        },
        "6.8": {
          "0": 4422,
          "1": 2645,
        },
        "7.2": {
          "0": 662,
          "1": 5440,
        },
        "2.6": {
          "0": 2248,
        },
        "11.6": {
          "1": 151,
        },
        "4.4": {
          "0": 6528,
        },
        "5.2": {
          "0": 7836,
        },
        "3.8": {
          "0": 4923,
        },
        "5.4": {
          "0": 8048,
        },
        "6.4": {
          "0": 7589,
          "1": 108,
        },
        "4.8": {
          "0": 7212,
        },
        "8.4": {
          "1": 3177,
        },
        "7.6": {
          "0": 10,
          "1": 5064,
        },
        "7.4": {
          "0": 120,
          "1": 5442,
        },
        "3.6": {
          "0": 4362,
        },
        "9.8": {
          "1": 1055,
        },
        "6.6": {
          "0": 6591,
          "1": 821,
        },
        "1.4": {
          "0": 650,
        },
        "9.2": {
          "1": 1780,
        },
        "1.2": {
          "0": 552,
        },
        "8.8": {
          "1": 2549,
        },
        "3.4": {
          "0": 3935,
        },
        "8.6": {
          "1": 2809,
        },
        "7.8": {
          "1": 4737,
        },
        "11.4": {
          "1": 168,
        },
        "2.4": {
          "0": 1833,
        },
        "2.2": {
          "0": 1510,
        },
        "11.2": {
          "1": 238,
        },
        "9.4": {
          "1": 1473,
        },
        "0.8": {
          "0": 1275,
        },
        "11.8": {
          "1": 452,
          "2": 30,
        },
        "1.8": {
          "0": 1002,
        },
        "10.2": {
          "1": 707,
        },
        "9.6": {
          "1": 1305,
        },
        "10.4": {
          "1": 548,
        },
        "1.6": {
          "0": 818,
        },
        "10.8": {
          "1": 355,
        },
        "10.6": {
          "1": 448,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2843,
      TD: 3180,
      LEWICA: 3610,
    },
    potencjalneMandaty: {
      KO: 33832,
      TD: 55123,
      LEWICA: 57030,
    },
    ostatnieMandaty: {
      KO: 19634,
      TD: 31263,
      LEWICA: 16276,
    },
    dawcyGlosow: {
      KO: 7609,
      TD: 7267,
      LEWICA: 7993,
    },
  },
  "Zielona Góra": {
    procentyWOkreguSrednia: {
      PIS: 27.972975599633912,
      KO: 33.40299940818469,
      TD: 16.326688942994643,
      KONF: 8.4561770285674,
      LEWICA: 13.841159020619342,
    },
    odchylenieWOkregu: {
      PIS: 2.2464884934013774,
      KO: 2.1786424038281726,
      TD: 2.013774734692939,
      LEWICA: 1.0552692241623405,
      KONF: 0.847335782372188,
    },
    wykresRozkladu: {
      "37.8%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "33.7%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "15.8%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "5.5%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "2.6%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "2.3%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.8%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 2,
      },
      "0.6%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "0%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 3,
      },
    },
    wykresRozkladuDemo: {
      "52.6%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
      "46.5%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "0.8%": {
        DEMO: 9,
        NIEDEMO: 3,
      },
    },
    histogram: {
      PIS: {
        "23": {
          "3": 254,
        },
        "24": {
          "3": 895,
        },
        "25": {
          "3": 2450,
        },
        "26": {
          "3": 4910,
          "4": 12,
        },
        "27": {
          "3": 6644,
          "4": 894,
        },
        "28": {
          "3": 3982,
          "4": 4542,
        },
        "29": {
          "3": 985,
          "4": 6465,
        },
        "30": {
          "3": 73,
          "4": 4577,
        },
        "31": {
          "4": 2298,
        },
        "32": {
          "4": 850,
        },
        "33": {
          "4": 218,
          "5": 2,
        },
        "34": {
          "4": 26,
          "5": 13,
        },
        "25.4": {
          "3": 3220,
        },
        "28.6": {
          "3": 1897,
          "4": 6287,
        },
        "26.4": {
          "3": 5876,
          "4": 112,
        },
        "32.8": {
          "4": 270,
          "5": 5,
        },
        "23.2": {
          "3": 322,
        },
        "30.6": {
          "4": 3160,
        },
        "28.4": {
          "3": 2582,
          "4": 5864,
        },
        "29.6": {
          "3": 282,
          "4": 5573,
        },
        "29.4": {
          "3": 417,
          "4": 5924,
        },
        "25.2": {
          "3": 2878,
        },
        "27.2": {
          "3": 6519,
          "4": 1389,
        },
        "32.2": {
          "4": 648,
          "5": 1,
        },
        "29.2": {
          "3": 714,
          "4": 6373,
        },
        "26.8": {
          "3": 6491,
          "4": 499,
        },
        "27.8": {
          "3": 4961,
          "4": 3658,
        },
        "27.6": {
          "3": 5527,
          "4": 2849,
        },
        "27.4": {
          "3": 6196,
          "4": 1970,
        },
        "30.8": {
          "4": 2709,
        },
        "26.2": {
          "3": 5476,
          "4": 37,
        },
        "26.6": {
          "3": 6179,
          "4": 237,
        },
        "28.2": {
          "3": 3245,
          "4": 5243,
        },
        "28.8": {
          "3": 1366,
          "4": 6425,
        },
        "25.8": {
          "3": 4304,
          "4": 1,
        },
        "30.4": {
          "3": 6,
          "4": 3627,
        },
        "31.4": {
          "4": 1470,
        },
        "29.8": {
          "3": 164,
          "4": 5126,
        },
        "30.2": {
          "3": 28,
          "4": 4114,
        },
        "32.4": {
          "4": 482,
          "5": 2,
        },
        "24.4": {
          "3": 1339,
        },
        "31.8": {
          "4": 1011,
        },
        "24.8": {
          "3": 1975,
        },
        "24.6": {
          "3": 1679,
        },
        "23.6": {
          "3": 531,
        },
        "22.8": {
          "2": 1,
          "3": 195,
        },
        "25.6": {
          "3": 3762,
        },
        "32.6": {
          "4": 366,
          "5": 3,
        },
        "31.6": {
          "4": 1239,
        },
        "22.4": {
          "2": 69,
          "3": 291,
        },
        "23.8": {
          "3": 701,
        },
        "34.4": {
          "4": 19,
          "5": 7,
        },
        "24.2": {
          "3": 1097,
        },
        "33.8": {
          "4": 62,
          "5": 11,
        },
        "31.2": {
          "4": 1838,
        },
        "33.4": {
          "4": 98,
          "5": 20,
        },
        "23.4": {
          "3": 430,
        },
        "33.2": {
          "4": 166,
          "5": 4,
        },
        "34.8": {
          "4": 5,
          "5": 15,
        },
        "33.6": {
          "4": 76,
          "5": 10,
        },
        "22.6": {
          "2": 3,
          "3": 132,
        },
        "34.6": {
          "4": 12,
          "5": 12,
        },
        "34.2": {
          "4": 15,
          "5": 11,
        },
      },
      KO: {
        "28": {
          "3": 267,
          "4": 102,
        },
        "29": {
          "3": 96,
          "4": 371,
        },
        "30": {
          "3": 34,
          "4": 1440,
        },
        "31": {
          "4": 3700,
        },
        "32": {
          "4": 6637,
          "5": 2,
        },
        "33": {
          "4": 8406,
          "5": 104,
        },
        "34": {
          "4": 7213,
          "5": 1158,
        },
        "35": {
          "4": 2900,
          "5": 2882,
        },
        "36": {
          "4": 425,
          "5": 2708,
        },
        "37": {
          "4": 40,
          "5": 1201,
        },
        "38": {
          "5": 366,
        },
        "39": {
          "5": 81,
          "6": 2,
        },
        "40": {
          "5": 38,
          "6": 8,
        },
        "33.8": {
          "4": 7616,
          "5": 860,
        },
        "36.4": {
          "4": 145,
          "5": 2073,
        },
        "32.6": {
          "4": 7978,
          "5": 30,
        },
        "35.4": {
          "4": 1544,
          "5": 3192,
        },
        "29.6": {
          "3": 73,
          "4": 888,
        },
        "31.6": {
          "4": 5357,
        },
        "38.8": {
          "5": 109,
        },
        "31.8": {
          "4": 6026,
          "5": 1,
        },
        "31.2": {
          "4": 4254,
        },
        "32.8": {
          "4": 8229,
          "5": 50,
        },
        "33.4": {
          "4": 8395,
          "5": 366,
        },
        "30.2": {
          "3": 18,
          "4": 1826,
        },
        "32.2": {
          "4": 6972,
          "5": 2,
        },
        "32.4": {
          "4": 7740,
          "5": 1,
        },
        "34.4": {
          "4": 5555,
          "5": 1906,
        },
        "34.8": {
          "4": 3871,
          "5": 2661,
        },
        "36.8": {
          "4": 68,
          "5": 1468,
        },
        "34.6": {
          "4": 4733,
          "5": 2362,
        },
        "29.8": {
          "3": 42,
          "4": 1220,
        },
        "34.2": {
          "4": 6336,
          "5": 1524,
        },
        "31.4": {
          "4": 4891,
        },
        "33.6": {
          "4": 8093,
          "5": 564,
        },
        "35.8": {
          "4": 688,
          "5": 2971,
        },
        "30.8": {
          "4": 3153,
        },
        "35.2": {
          "4": 2293,
          "5": 3171,
        },
        "28.4": {
          "3": 110,
          "4": 111,
        },
        "33.2": {
          "4": 8551,
          "5": 252,
        },
        "35.6": {
          "4": 1021,
          "5": 3097,
        },
        "30.6": {
          "4": 2634,
        },
        "37.6": {
          "4": 1,
          "5": 644,
        },
        "37.8": {
          "4": 2,
          "5": 496,
        },
        "36.6": {
          "4": 104,
          "5": 1817,
        },
        "36.2": {
          "4": 244,
          "5": 2404,
        },
        "30.4": {
          "3": 6,
          "4": 2257,
        },
        "29.4": {
          "3": 118,
          "4": 724,
        },
        "37.4": {
          "4": 12,
          "5": 807,
        },
        "38.6": {
          "5": 157,
        },
        "29.2": {
          "3": 114,
          "4": 483,
        },
        "37.2": {
          "4": 9,
          "5": 954,
        },
        "28.6": {
          "3": 102,
          "4": 137,
        },
        "28.8": {
          "3": 108,
          "4": 241,
        },
        "39.2": {
          "5": 56,
        },
        "38.4": {
          "5": 219,
        },
        "38.2": {
          "5": 271,
        },
        "28.2": {
          "3": 72,
          "4": 61,
        },
        "39.6": {
          "5": 30,
          "6": 4,
        },
        "39.4": {
          "5": 46,
        },
        "39.8": {
          "5": 25,
          "6": 3,
        },
      },
      TD: {
        "12": {
          "1": 474,
        },
        "13": {
          "1": 1614,
          "2": 2,
        },
        "14": {
          "1": 2048,
          "2": 1627,
        },
        "15": {
          "1": 53,
          "2": 6714,
        },
        "16": {
          "2": 8590,
        },
        "17": {
          "2": 8568,
        },
        "18": {
          "2": 5704,
        },
        "19": {
          "2": 2877,
        },
        "20": {
          "2": 956,
          "3": 101,
        },
        "21": {
          "2": 67,
          "3": 182,
        },
        "22": {
          "2": 3,
          "3": 51,
        },
        "15.2": {
          "1": 7,
          "2": 7450,
        },
        "15.8": {
          "2": 8601,
        },
        "15.4": {
          "2": 7822,
        },
        "14.6": {
          "1": 436,
          "2": 5083,
        },
        "18.4": {
          "2": 4726,
        },
        "16.2": {
          "2": 8928,
        },
        "14.4": {
          "1": 798,
          "2": 4157,
        },
        "16.8": {
          "2": 8695,
        },
        "16.6": {
          "2": 8871,
        },
        "17.6": {
          "2": 7033,
        },
        "16.4": {
          "2": 9143,
        },
        "14.2": {
          "1": 1373,
          "2": 2987,
        },
        "17.2": {
          "2": 7971,
        },
        "18.6": {
          "2": 3876,
        },
        "17.4": {
          "2": 7539,
        },
        "19.4": {
          "2": 1925,
          "3": 2,
        },
        "13.6": {
          "1": 2348,
          "2": 291,
        },
        "18.2": {
          "2": 5269,
        },
        "21.2": {
          "2": 50,
          "3": 153,
        },
        "17.8": {
          "2": 6338,
        },
        "15.6": {
          "2": 8183,
        },
        "13.8": {
          "1": 2439,
          "2": 731,
        },
        "12.8": {
          "1": 1284,
        },
        "14.8": {
          "1": 169,
          "2": 6063,
        },
        "11.2": {
          "1": 623,
        },
        "19.8": {
          "2": 1289,
          "3": 56,
        },
        "18.8": {
          "2": 3504,
        },
        "13.2": {
          "1": 1882,
          "2": 24,
        },
        "19.2": {
          "2": 2403,
        },
        "20.4": {
          "2": 411,
          "3": 209,
        },
        "21.6": {
          "2": 8,
          "3": 71,
        },
        "12.6": {
          "1": 1065,
        },
        "13.4": {
          "1": 2182,
          "2": 75,
        },
        "12.2": {
          "1": 627,
        },
        "19.6": {
          "2": 1592,
          "3": 20,
        },
        "12.4": {
          "1": 818,
        },
        "20.2": {
          "2": 632,
          "3": 134,
        },
        "20.8": {
          "2": 150,
          "3": 213,
        },
        "11.4": {
          "1": 210,
        },
        "20.6": {
          "2": 274,
          "3": 220,
        },
        "22.4": {
          "3": 57,
        },
        "11.8": {
          "1": 374,
        },
        "11.6": {
          "1": 262,
        },
        "21.8": {
          "2": 8,
          "3": 64,
        },
        "21.4": {
          "2": 26,
          "3": 115,
        },
        "22.2": {
          "2": 2,
          "3": 28,
        },
      },
      KONF: {
        "7": {
          "0": 1472,
          "1": 2736,
        },
        "8": {
          "1": 16494,
        },
        "9": {
          "1": 15327,
        },
        "10": {
          "1": 3645,
        },
        "11": {
          "1": 495,
        },
        "8.6": {
          "1": 18514,
        },
        "8.2": {
          "1": 18310,
        },
        "9.6": {
          "1": 7452,
        },
        "8.8": {
          "1": 17346,
        },
        "7.8": {
          "1": 13936,
        },
        "7.6": {
          "0": 6,
          "1": 11498,
        },
        "9.8": {
          "1": 5264,
        },
        "7.2": {
          "0": 596,
          "1": 5643,
        },
        "6.8": {
          "0": 1882,
          "1": 780,
        },
        "8.4": {
          "1": 18906,
        },
        "7.4": {
          "0": 124,
          "1": 8413,
        },
        "9.4": {
          "1": 9928,
        },
        "9.2": {
          "1": 12815,
        },
        "6.4": {
          "0": 1808,
          "1": 4,
        },
        "6.6": {
          "0": 1494,
          "1": 123,
        },
        "10.4": {
          "1": 1360,
        },
        "10.8": {
          "1": 447,
        },
        "10.6": {
          "1": 853,
        },
        "10.2": {
          "1": 2329,
        },
      },
      LEWICA: {
        "12": {
          "1": 3158,
        },
        "13": {
          "1": 11173,
          "2": 20,
        },
        "14": {
          "1": 7858,
          "2": 7014,
        },
        "15": {
          "1": 132,
          "2": 7964,
        },
        "16": {
          "2": 1970,
        },
        "17": {
          "2": 481,
        },
        "13.4": {
          "1": 13303,
          "2": 797,
        },
        "14.6": {
          "1": 1264,
          "2": 10427,
        },
        "13.6": {
          "1": 12613,
          "2": 2217,
        },
        "11.8": {
          "1": 2169,
        },
        "13.8": {
          "1": 10831,
          "2": 4414,
        },
        "13.2": {
          "1": 12569,
          "2": 201,
        },
        "14.2": {
          "1": 4896,
          "2": 9229,
        },
        "14.4": {
          "1": 2706,
          "2": 10388,
        },
        "15.2": {
          "1": 11,
          "2": 6521,
        },
        "12.6": {
          "1": 7629,
          "2": 1,
        },
        "15.8": {
          "2": 2732,
        },
        "15.4": {
          "2": 5035,
        },
        "12.2": {
          "1": 4443,
        },
        "14.8": {
          "1": 488,
          "2": 9469,
        },
        "12.4": {
          "1": 5766,
        },
        "12.8": {
          "1": 9356,
          "2": 1,
        },
        "15.6": {
          "2": 3817,
        },
        "11.2": {
          "1": 1392,
        },
        "16.2": {
          "2": 1286,
        },
        "11.6": {
          "1": 1504,
        },
        "16.6": {
          "2": 571,
        },
        "11.4": {
          "1": 942,
        },
        "16.4": {
          "2": 925,
        },
        "16.8": {
          "2": 317,
        },
      },
    },
    minimumMandatow: {
      KO: 3,
      TD: 1,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 1740,
      TD: 3935,
      LEWICA: 4124,
    },
    potencjalneMandaty: {
      KO: 50399,
      TD: 30389,
      LEWICA: 103643,
    },
    ostatnieMandaty: {
      KO: 27839,
      TD: 20003,
      LEWICA: 59809,
    },
    dawcyGlosow: {
      KO: 4538,
      TD: 11091,
      LEWICA: 7680,
    },
  },
  Łódź: {
    procentyWOkreguSrednia: {
      PIS: 25.428463769230703,
      KO: 39.00125245357515,
      TD: 9.452789842844835,
      KONF: 7.131864147883452,
      LEWICA: 18.98562978646587,
    },
    odchylenieWOkregu: {
      PIS: 2.397173965811894,
      KO: 4.0343444475366255,
      TD: 2.013774734692939,
      LEWICA: 1.954113506140949,
      KONF: 0.8149982473057397,
    },
    wykresRozkladu: {
      "54.1%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
      "17.5%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 2,
        TD: 1,
      },
      "15.1%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 0,
      },
      "5%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 0,
      },
      "4.1%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "1.2%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "1.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.5%": {
        KO: 4,
        LEWICA: 3,
        NIEDEMO: 3,
        TD: 0,
      },
      "0.3%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "0.1%": {
        KO: 5,
        LEWICA: 3,
        NIEDEMO: 2,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "74.1%": {
        DEMO: 7,
        NIEDEMO: 3,
      },
      "18.4%": {
        DEMO: 8,
        NIEDEMO: 2,
      },
      "7.5%": {
        DEMO: 6,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "20": {
          "2": 318,
        },
        "21": {
          "2": 941,
        },
        "22": {
          "2": 2070,
          "3": 19,
        },
        "23": {
          "2": 3187,
          "3": 624,
        },
        "24": {
          "2": 2639,
          "3": 3246,
        },
        "25": {
          "2": 543,
          "3": 6611,
        },
        "26": {
          "2": 78,
          "3": 7068,
        },
        "27": {
          "3": 5592,
        },
        "28": {
          "3": 3649,
        },
        "29": {
          "3": 1981,
          "4": 10,
        },
        "30": {
          "3": 783,
          "4": 64,
        },
        "31": {
          "3": 216,
          "4": 83,
        },
        "32": {
          "3": 38,
          "4": 58,
        },
        "27.6": {
          "3": 4472,
        },
        "25.6": {
          "2": 177,
          "3": 7052,
        },
        "30.2": {
          "3": 674,
          "4": 82,
        },
        "22.2": {
          "2": 2382,
          "3": 61,
        },
        "26.6": {
          "2": 14,
          "3": 6275,
        },
        "25.4": {
          "2": 247,
          "3": 7056,
        },
        "26.8": {
          "2": 5,
          "3": 5919,
        },
        "28.2": {
          "3": 3219,
        },
        "25.2": {
          "2": 323,
          "3": 6804,
        },
        "23.2": {
          "2": 3447,
          "3": 1008,
        },
        "19.4": {
          "2": 635,
        },
        "27.2": {
          "3": 5178,
        },
        "26.4": {
          "2": 29,
          "3": 6471,
        },
        "22.4": {
          "2": 2636,
          "3": 140,
        },
        "24.8": {
          "2": 828,
          "3": 6271,
        },
        "22.6": {
          "2": 2881,
          "3": 216,
        },
        "24.2": {
          "2": 2188,
          "3": 4006,
        },
        "21.4": {
          "2": 1318,
        },
        "23.4": {
          "2": 3355,
          "3": 1465,
        },
        "23.8": {
          "2": 2881,
          "3": 2558,
        },
        "30.4": {
          "3": 520,
          "4": 86,
        },
        "26.2": {
          "2": 46,
          "3": 6727,
        },
        "21.6": {
          "2": 1575,
          "3": 1,
        },
        "27.8": {
          "3": 4014,
        },
        "27.4": {
          "3": 4828,
        },
        "19.6": {
          "2": 207,
        },
        "23.6": {
          "2": 3274,
          "3": 1921,
        },
        "24.6": {
          "2": 1275,
          "3": 5524,
        },
        "31.4": {
          "3": 145,
          "4": 85,
        },
        "24.4": {
          "2": 1738,
          "3": 4853,
        },
        "21.8": {
          "2": 1914,
          "3": 5,
        },
        "20.8": {
          "2": 730,
        },
        "28.6": {
          "3": 2496,
        },
        "29.8": {
          "3": 962,
          "4": 53,
        },
        "19.8": {
          "2": 249,
        },
        "29.2": {
          "3": 1625,
          "4": 9,
        },
        "20.6": {
          "2": 632,
        },
        "32.6": {
          "3": 29,
          "4": 163,
        },
        "29.6": {
          "3": 1191,
          "4": 38,
        },
        "25.8": {
          "2": 109,
          "3": 7096,
        },
        "30.6": {
          "3": 405,
          "4": 72,
        },
        "29.4": {
          "3": 1381,
          "4": 25,
        },
        "28.4": {
          "3": 2892,
        },
        "22.8": {
          "2": 3192,
          "3": 419,
        },
        "21.2": {
          "2": 1137,
        },
        "28.8": {
          "3": 2250,
        },
        "32.2": {
          "3": 41,
          "4": 47,
        },
        "31.2": {
          "3": 165,
          "4": 75,
        },
        "30.8": {
          "3": 306,
          "4": 91,
        },
        "20.2": {
          "2": 381,
        },
        "20.4": {
          "2": 503,
        },
        "31.8": {
          "3": 76,
          "4": 77,
        },
        "31.6": {
          "3": 124,
          "4": 73,
        },
        "32.4": {
          "3": 17,
          "4": 40,
        },
      },
      KO: {
        "29": {
          "2": 1,
          "3": 117,
        },
        "30": {
          "3": 52,
          "4": 4,
        },
        "31": {
          "3": 77,
          "4": 51,
        },
        "32": {
          "3": 111,
          "4": 231,
        },
        "33": {
          "3": 134,
          "4": 579,
        },
        "34": {
          "3": 83,
          "4": 1222,
        },
        "35": {
          "3": 30,
          "4": 2032,
        },
        "36": {
          "3": 2,
          "4": 3050,
          "5": 5,
        },
        "37": {
          "4": 4218,
          "5": 88,
        },
        "38": {
          "4": 4877,
          "5": 432,
        },
        "39": {
          "4": 4384,
          "5": 1301,
        },
        "40": {
          "4": 2816,
          "5": 2585,
        },
        "41": {
          "4": 1031,
          "5": 3320,
        },
        "42": {
          "4": 111,
          "5": 3186,
        },
        "43": {
          "4": 3,
          "5": 2132,
        },
        "44": {
          "5": 1120,
          "6": 15,
        },
        "45": {
          "5": 547,
          "6": 28,
        },
        "46": {
          "5": 211,
          "6": 49,
        },
        "47": {
          "5": 41,
          "6": 59,
        },
        "48": {
          "5": 10,
          "6": 21,
        },
        "49": {
          "5": 4,
          "6": 7,
        },
        "50": {
          "6": 5,
        },
        "37.4": {
          "4": 4409,
          "5": 184,
        },
        "42.4": {
          "4": 28,
          "5": 2737,
        },
        "34.6": {
          "3": 40,
          "4": 1648,
        },
        "39.6": {
          "4": 3475,
          "5": 1875,
        },
        "33.6": {
          "3": 103,
          "4": 888,
        },
        "38.4": {
          "4": 4761,
          "5": 660,
        },
        "41.8": {
          "4": 197,
          "5": 3306,
        },
        "42.2": {
          "4": 81,
          "5": 2948,
        },
        "40.4": {
          "4": 2025,
          "5": 3002,
        },
        "44.6": {
          "5": 768,
          "6": 27,
        },
        "37.2": {
          "4": 4349,
          "5": 118,
        },
        "43.6": {
          "4": 1,
          "5": 1537,
          "6": 6,
        },
        "41.2": {
          "4": 748,
          "5": 3402,
        },
        "43.2": {
          "4": 1,
          "5": 1921,
          "6": 2,
        },
        "40.8": {
          "4": 1338,
          "5": 3183,
        },
        "44.4": {
          "5": 948,
          "6": 23,
        },
        "40.6": {
          "4": 1681,
          "5": 3106,
        },
        "42.8": {
          "4": 2,
          "5": 2290,
        },
        "41.4": {
          "4": 507,
          "5": 3442,
        },
        "33.4": {
          "3": 106,
          "4": 736,
        },
        "38.6": {
          "4": 4581,
          "5": 857,
        },
        "46.4": {
          "5": 121,
          "6": 65,
        },
        "39.8": {
          "4": 3244,
          "5": 2229,
        },
        "36.6": {
          "4": 3883,
          "5": 32,
        },
        "38.8": {
          "4": 4476,
          "5": 1052,
        },
        "40.2": {
          "4": 2470,
          "5": 2789,
        },
        "36.2": {
          "4": 3305,
          "5": 3,
        },
        "38.2": {
          "4": 4728,
          "5": 538,
        },
        "35.4": {
          "3": 12,
          "4": 2526,
        },
        "34.8": {
          "3": 35,
          "4": 1844,
        },
        "44.2": {
          "5": 992,
          "6": 11,
        },
        "42.6": {
          "4": 19,
          "5": 2466,
        },
        "37.6": {
          "4": 4609,
          "5": 250,
        },
        "43.4": {
          "4": 4,
          "5": 1656,
          "6": 1,
        },
        "34.2": {
          "3": 76,
          "4": 1398,
        },
        "35.8": {
          "3": 5,
          "4": 2986,
        },
        "35.2": {
          "3": 19,
          "4": 2229,
        },
        "46.2": {
          "5": 135,
          "6": 56,
        },
        "41.6": {
          "4": 371,
          "5": 3279,
        },
        "37.8": {
          "4": 4739,
          "5": 318,
        },
        "35.6": {
          "3": 10,
          "4": 2639,
        },
        "36.8": {
          "4": 4042,
          "5": 48,
        },
        "30.8": {
          "3": 72,
          "4": 40,
        },
        "34.4": {
          "3": 53,
          "4": 1472,
        },
        "30.6": {
          "3": 86,
          "4": 29,
        },
        "46.6": {
          "5": 75,
          "6": 49,
        },
        "43.8": {
          "4": 2,
          "5": 1386,
          "6": 8,
        },
        "45.4": {
          "5": 377,
          "6": 51,
        },
        "36.4": {
          "4": 3563,
          "5": 15,
        },
        "39.4": {
          "4": 3770,
          "5": 1746,
        },
        "44.8": {
          "5": 649,
          "6": 27,
        },
        "39.2": {
          "4": 4223,
          "5": 1503,
        },
        "31.2": {
          "3": 91,
          "4": 59,
        },
        "47.6": {
          "5": 16,
          "6": 40,
        },
        "45.8": {
          "5": 281,
          "6": 54,
        },
        "32.6": {
          "3": 136,
          "4": 378,
        },
        "30.2": {
          "3": 63,
          "4": 9,
        },
        "32.4": {
          "3": 133,
          "4": 335,
        },
        "45.2": {
          "5": 475,
          "6": 38,
        },
        "33.8": {
          "3": 83,
          "4": 1072,
        },
        "32.8": {
          "3": 127,
          "4": 478,
        },
        "47.4": {
          "5": 26,
          "6": 53,
        },
        "33.2": {
          "3": 106,
          "4": 628,
        },
        "32.2": {
          "3": 119,
          "4": 257,
        },
        "29.2": {
          "3": 27,
        },
        "31.8": {
          "3": 113,
          "4": 166,
        },
        "47.8": {
          "5": 10,
          "6": 32,
        },
        "45.6": {
          "5": 320,
          "6": 36,
        },
        "29.8": {
          "3": 38,
          "4": 4,
        },
        "47.2": {
          "5": 36,
          "6": 50,
        },
        "31.6": {
          "3": 116,
          "4": 105,
        },
        "46.8": {
          "5": 66,
          "6": 42,
        },
        "30.4": {
          "3": 71,
          "4": 20,
        },
        "29.6": {
          "3": 36,
        },
        "29.4": {
          "3": 26,
          "4": 1,
        },
        "49.6": {
          "6": 5,
        },
        "31.4": {
          "3": 98,
          "4": 101,
        },
        "48.2": {
          "5": 6,
          "6": 22,
        },
        "50.4": {
          "6": 2,
        },
        "50.2": {
          "6": 2,
        },
        "48.4": {
          "5": 2,
          "6": 18,
        },
        "49.4": {
          "6": 5,
        },
        "49.2": {
          "5": 1,
          "6": 6,
        },
        "48.6": {
          "6": 10,
        },
        "48.8": {
          "5": 1,
          "6": 7,
        },
        "49.8": {
          "6": 5,
        },
        "50.8": {
          "6": 1,
        },
        "50.6": {
          "6": 1,
        },
      },
      TD: {
        "5": {
          "0": 519,
        },
        "6": {
          "0": 1630,
        },
        "7": {
          "0": 3564,
        },
        "8": {
          "0": 2588,
          "1": 3743,
        },
        "9": {
          "0": 3,
          "1": 8135,
        },
        "10": {
          "1": 8193,
        },
        "11": {
          "1": 6114,
        },
        "12": {
          "1": 3300,
        },
        "13": {
          "1": 1449,
        },
        "14": {
          "1": 435,
        },
        "15": {
          "1": 105,
          "2": 6,
        },
        "11.2": {
          "1": 5450,
        },
        "6.6": {
          "0": 2792,
        },
        "10.8": {
          "1": 6520,
        },
        "9.6": {
          "1": 8548,
        },
        "8.6": {
          "0": 99,
          "1": 7486,
        },
        "12.4": {
          "1": 2496,
        },
        "7.4": {
          "0": 4499,
          "1": 160,
        },
        "8.4": {
          "0": 356,
          "1": 6880,
        },
        "9.2": {
          "1": 8388,
        },
        "11.4": {
          "1": 5009,
        },
        "10.2": {
          "1": 7831,
        },
        "12.2": {
          "1": 2944,
        },
        "11.8": {
          "1": 3819,
        },
        "4.8": {
          "0": 454,
        },
        "6.8": {
          "0": 3174,
        },
        "5.6": {
          "0": 1092,
        },
        "9.8": {
          "1": 8414,
        },
        "12.6": {
          "1": 2175,
        },
        "5.8": {
          "0": 1358,
        },
        "8.2": {
          "0": 1182,
          "1": 5393,
        },
        "9.4": {
          "1": 8482,
        },
        "5.2": {
          "0": 697,
        },
        "10.6": {
          "1": 7238,
        },
        "10.4": {
          "1": 7388,
        },
        "7.2": {
          "0": 4157,
          "1": 9,
        },
        "7.8": {
          "0": 3743,
          "1": 1862,
        },
        "7.6": {
          "0": 4376,
          "1": 705,
        },
        "11.6": {
          "1": 4484,
        },
        "13.4": {
          "1": 886,
        },
        "8.8": {
          "0": 31,
          "1": 7769,
        },
        "6.4": {
          "0": 2276,
        },
        "5.4": {
          "0": 902,
        },
        "4.4": {
          "0": 1052,
        },
        "6.2": {
          "0": 1971,
        },
        "14.6": {
          "1": 204,
          "2": 3,
        },
        "12.8": {
          "1": 1686,
        },
        "13.8": {
          "1": 533,
        },
        "13.6": {
          "1": 662,
        },
        "13.2": {
          "1": 1206,
        },
        "14.4": {
          "1": 284,
        },
        "15.2": {
          "1": 55,
          "2": 12,
        },
        "4.6": {
          "0": 357,
        },
        "15.4": {
          "1": 100,
          "2": 95,
        },
        "14.2": {
          "1": 312,
        },
        "14.8": {
          "1": 154,
          "2": 6,
        },
      },
      KONF: {
        "5": {
          "0": 1346,
        },
        "6": {
          "0": 7865,
        },
        "7": {
          "0": 18582,
        },
        "8": {
          "0": 7391,
          "1": 3561,
        },
        "9": {
          "0": 2,
          "1": 1920,
        },
        "6.4": {
          "0": 13337,
        },
        "7.4": {
          "0": 17488,
          "1": 143,
        },
        "6.6": {
          "0": 15590,
        },
        "7.8": {
          "0": 11230,
          "1": 2109,
        },
        "5.8": {
          "0": 5420,
        },
        "8.4": {
          "0": 1521,
          "1": 4599,
        },
        "6.2": {
          "0": 10436,
        },
        "8.2": {
          "0": 3679,
          "1": 4884,
        },
        "7.6": {
          "0": 15044,
          "1": 753,
        },
        "8.6": {
          "0": 462,
          "1": 3832,
        },
        "6.8": {
          "0": 17563,
        },
        "5.4": {
          "0": 2208,
        },
        "7.2": {
          "0": 18603,
          "1": 7,
        },
        "8.8": {
          "0": 92,
          "1": 2735,
        },
        "5.6": {
          "0": 3621,
        },
        "5.2": {
          "0": 1258,
        },
        "9.2": {
          "1": 1189,
        },
        "9.6": {
          "1": 825,
        },
        "9.4": {
          "1": 705,
        },
      },
      LEWICA: {
        "15": {
          "1": 748,
          "2": 38,
        },
        "16": {
          "1": 1105,
          "2": 1292,
        },
        "17": {
          "1": 111,
          "2": 4739,
        },
        "18": {
          "1": 3,
          "2": 7591,
        },
        "19": {
          "2": 8545,
        },
        "20": {
          "2": 7223,
        },
        "21": {
          "2": 4647,
        },
        "22": {
          "2": 2351,
          "3": 23,
        },
        "23": {
          "2": 787,
          "3": 148,
        },
        "24": {
          "2": 130,
          "3": 154,
        },
        "17.6": {
          "1": 13,
          "2": 6489,
        },
        "20.6": {
          "2": 5829,
        },
        "17.8": {
          "1": 6,
          "2": 7079,
        },
        "21.6": {
          "2": 3146,
          "3": 1,
        },
        "18.6": {
          "2": 8359,
        },
        "15.8": {
          "1": 1178,
          "2": 730,
        },
        "16.2": {
          "1": 922,
          "2": 1861,
        },
        "20.2": {
          "2": 6779,
        },
        "23.8": {
          "2": 164,
          "3": 164,
        },
        "16.6": {
          "1": 415,
          "2": 3451,
        },
        "16.4": {
          "1": 704,
          "2": 2575,
        },
        "20.4": {
          "2": 6425,
        },
        "17.2": {
          "1": 71,
          "2": 5420,
        },
        "18.4": {
          "2": 8078,
        },
        "21.4": {
          "2": 3719,
        },
        "18.2": {
          "2": 7842,
        },
        "19.2": {
          "2": 8462,
        },
        "19.6": {
          "2": 8040,
        },
        "19.4": {
          "2": 8238,
        },
        "23.4": {
          "2": 357,
          "3": 204,
        },
        "18.8": {
          "2": 8530,
        },
        "19.8": {
          "2": 7774,
        },
        "21.8": {
          "2": 2801,
          "3": 13,
        },
        "17.4": {
          "1": 43,
          "2": 6039,
        },
        "20.8": {
          "2": 5302,
        },
        "22.2": {
          "2": 1945,
          "3": 50,
        },
        "22.6": {
          "2": 1248,
          "3": 108,
        },
        "24.4": {
          "2": 38,
          "3": 118,
        },
        "22.4": {
          "2": 1534,
          "3": 66,
        },
        "16.8": {
          "1": 220,
          "2": 4222,
        },
        "14.6": {
          "1": 475,
          "2": 3,
        },
        "24.8": {
          "2": 17,
          "3": 336,
        },
        "23.2": {
          "2": 534,
          "3": 189,
        },
        "15.6": {
          "1": 1158,
          "2": 473,
        },
        "15.2": {
          "1": 972,
          "2": 80,
        },
        "15.4": {
          "1": 1090,
          "2": 232,
        },
        "21.2": {
          "2": 4161,
        },
        "22.8": {
          "2": 935,
          "3": 116,
        },
        "14.2": {
          "1": 1002,
        },
        "14.4": {
          "1": 359,
        },
        "24.2": {
          "2": 70,
          "3": 149,
        },
        "14.8": {
          "1": 635,
          "2": 17,
        },
        "23.6": {
          "2": 273,
          "3": 206,
        },
        "24.6": {
          "2": 20,
          "3": 91,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 2525,
      TD: 5822,
      LEWICA: 3924,
    },
    potencjalneMandaty: {
      KO: 52499,
      TD: 33697,
      LEWICA: 17645,
    },
    ostatnieMandaty: {
      KO: 50504,
      TD: 21731,
      LEWICA: 23788,
    },
    dawcyGlosow: {
      KO: 6168,
      TD: 15512,
      LEWICA: 10872,
    },
  },
  "Piotrków Trybunalski": {
    procentyWOkreguSrednia: {
      PIS: 48.880674239248016,
      KO: 17.84019904633159,
      TD: 12.900157015622492,
      KONF: 10.485930118255784,
      LEWICA: 9.89303958054212,
    },
    odchylenieWOkregu: {
      PIS: 7.07161243560413,
      KO: 1.4386052551425845,
      TD: 2.013774734692939,
      LEWICA: 1.1803236422031704,
      KONF: 1.4615412089945252,
    },
    wykresRozkladu: {
      "45.4%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "30.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "13.1%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "7.8%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "1.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.8%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 0,
      },
      "0.4%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.2%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 2,
      },
      "0%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "60.1%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
      "30.8%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
      "8.7%": {
        DEMO: 2,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "32": {
          "3": 7,
        },
        "33": {
          "3": 14,
          "4": 3,
        },
        "34": {
          "3": 16,
          "4": 6,
        },
        "35": {
          "3": 13,
          "4": 16,
        },
        "36": {
          "3": 13,
          "4": 56,
        },
        "37": {
          "3": 8,
          "4": 89,
        },
        "38": {
          "3": 4,
          "4": 140,
        },
        "39": {
          "3": 1,
          "4": 247,
        },
        "40": {
          "4": 434,
        },
        "41": {
          "4": 643,
          "5": 1,
        },
        "42": {
          "4": 938,
          "5": 13,
        },
        "43": {
          "4": 1288,
          "5": 84,
        },
        "44": {
          "4": 1510,
          "5": 294,
        },
        "45": {
          "4": 1549,
          "5": 808,
        },
        "46": {
          "4": 1225,
          "5": 1685,
        },
        "47": {
          "4": 712,
          "5": 2731,
        },
        "48": {
          "4": 219,
          "5": 3604,
        },
        "49": {
          "4": 23,
          "5": 4188,
          "6": 9,
        },
        "50": {
          "5": 3860,
          "6": 103,
        },
        "51": {
          "5": 3297,
          "6": 354,
        },
        "52": {
          "5": 2434,
          "6": 790,
        },
        "53": {
          "5": 1298,
          "6": 1118,
        },
        "54": {
          "5": 504,
          "6": 1248,
        },
        "55": {
          "5": 122,
          "6": 1014,
          "7": 1,
        },
        "56": {
          "5": 21,
          "6": 620,
          "7": 6,
        },
        "57": {
          "5": 2,
          "6": 311,
          "7": 19,
        },
        "58": {
          "6": 137,
          "7": 26,
        },
        "59": {
          "6": 40,
          "7": 30,
        },
        "60": {
          "6": 10,
          "7": 23,
        },
        "61": {
          "6": 3,
          "7": 5,
        },
        "62": {
          "7": 1,
        },
        "53.2": {
          "5": 1099,
          "6": 1220,
        },
        "45.8": {
          "4": 1378,
          "5": 1431,
        },
        "43.2": {
          "4": 1316,
          "5": 109,
        },
        "47.8": {
          "4": 314,
          "5": 3528,
          "6": 1,
        },
        "49.4": {
          "4": 2,
          "5": 3941,
          "6": 29,
        },
        "46.8": {
          "4": 835,
          "5": 2612,
        },
        "38.2": {
          "3": 5,
          "4": 168,
        },
        "48.2": {
          "4": 200,
          "5": 3809,
          "6": 1,
        },
        "50.2": {
          "5": 3829,
          "6": 129,
        },
        "42.4": {
          "4": 1060,
          "5": 34,
        },
        "48.8": {
          "4": 52,
          "5": 4073,
          "6": 6,
        },
        "48.6": {
          "4": 89,
          "5": 3895,
          "6": 5,
        },
        "46.2": {
          "4": 1154,
          "5": 1844,
        },
        "50.8": {
          "5": 3529,
          "6": 288,
        },
        "48.4": {
          "4": 125,
          "5": 3850,
          "6": 1,
        },
        "47.6": {
          "4": 354,
          "5": 3289,
        },
        "44.2": {
          "4": 1439,
          "5": 385,
        },
        "49.6": {
          "4": 2,
          "5": 4066,
          "6": 48,
        },
        "39.8": {
          "4": 428,
          "5": 2,
        },
        "50.4": {
          "5": 3830,
          "6": 159,
        },
        "46.6": {
          "4": 873,
          "5": 2329,
        },
        "44.8": {
          "4": 1571,
          "5": 666,
        },
        "37.8": {
          "3": 4,
          "4": 150,
        },
        "51.6": {
          "5": 2742,
          "6": 550,
        },
        "53.6": {
          "5": 781,
          "6": 1212,
        },
        "58.4": {
          "6": 89,
          "7": 21,
        },
        "52.2": {
          "5": 2208,
          "6": 819,
        },
        "51.4": {
          "5": 2978,
          "6": 484,
        },
        "50.6": {
          "5": 3601,
          "6": 208,
        },
        "41.2": {
          "4": 706,
          "5": 4,
        },
        "49.8": {
          "5": 4021,
          "6": 77,
        },
        "49.2": {
          "4": 9,
          "5": 3983,
          "6": 22,
        },
        "38.6": {
          "4": 234,
        },
        "45.2": {
          "4": 1473,
          "5": 917,
        },
        "44.6": {
          "4": 1565,
          "5": 568,
        },
        "51.2": {
          "5": 3127,
          "6": 428,
        },
        "54.2": {
          "5": 371,
          "6": 1244,
        },
        "55.6": {
          "5": 43,
          "6": 777,
          "7": 5,
        },
        "55.2": {
          "5": 75,
          "6": 965,
          "7": 2,
        },
        "46.4": {
          "4": 1071,
          "5": 2088,
        },
        "52.8": {
          "5": 1533,
          "6": 1098,
        },
        "52.6": {
          "5": 1738,
          "6": 1032,
        },
        "47.4": {
          "4": 466,
          "5": 3171,
          "6": 1,
        },
        "44.4": {
          "4": 1637,
          "5": 422,
        },
        "55.8": {
          "5": 33,
          "6": 718,
          "7": 6,
        },
        "43.8": {
          "4": 1494,
          "5": 201,
        },
        "37.4": {
          "3": 8,
          "4": 109,
        },
        "54.8": {
          "5": 190,
          "6": 1090,
          "7": 1,
        },
        "45.4": {
          "4": 1517,
          "5": 1096,
        },
        "38.8": {
          "4": 254,
        },
        "42.8": {
          "4": 1210,
          "5": 58,
        },
        "53.4": {
          "5": 928,
          "6": 1246,
        },
        "51.8": {
          "5": 2565,
          "6": 670,
        },
        "52.4": {
          "5": 1947,
          "6": 916,
        },
        "54.6": {
          "5": 255,
          "6": 1126,
          "7": 1,
        },
        "41.6": {
          "4": 807,
          "5": 8,
        },
        "45.6": {
          "4": 1439,
          "5": 1292,
        },
        "54.4": {
          "5": 301,
          "6": 1240,
        },
        "43.6": {
          "4": 1509,
          "5": 187,
        },
        "41.8": {
          "4": 856,
          "5": 14,
        },
        "58.6": {
          "6": 72,
          "7": 18,
        },
        "47.2": {
          "4": 631,
          "5": 2850,
        },
        "43.4": {
          "4": 1439,
          "5": 115,
        },
        "42.6": {
          "4": 1139,
          "5": 42,
        },
        "59.2": {
          "6": 36,
          "7": 21,
        },
        "56.6": {
          "5": 5,
          "6": 439,
          "7": 16,
        },
        "57.4": {
          "5": 1,
          "6": 259,
          "7": 26,
        },
        "53.8": {
          "5": 593,
          "6": 1306,
        },
        "56.8": {
          "5": 1,
          "6": 347,
          "7": 9,
        },
        "56.2": {
          "5": 6,
          "6": 566,
          "7": 11,
        },
        "41.4": {
          "4": 737,
          "5": 6,
        },
        "40.2": {
          "4": 441,
          "5": 1,
        },
        "35.8": {
          "3": 17,
          "4": 45,
        },
        "36.2": {
          "3": 18,
          "4": 44,
        },
        "39.4": {
          "4": 322,
        },
        "58.8": {
          "6": 54,
          "7": 25,
        },
        "40.8": {
          "4": 593,
          "5": 3,
        },
        "39.2": {
          "4": 305,
        },
        "42.2": {
          "4": 997,
          "5": 23,
        },
        "59.4": {
          "6": 25,
          "7": 23,
        },
        "55.4": {
          "5": 64,
          "6": 897,
          "7": 3,
        },
        "58.2": {
          "6": 96,
          "7": 27,
        },
        "56.4": {
          "5": 8,
          "6": 510,
          "7": 6,
        },
        "36.8": {
          "3": 12,
          "4": 73,
        },
        "35.4": {
          "3": 17,
          "4": 27,
        },
        "40.6": {
          "4": 544,
          "5": 1,
        },
        "38.4": {
          "3": 6,
          "4": 210,
        },
        "37.6": {
          "3": 6,
          "4": 141,
        },
        "36.4": {
          "3": 17,
          "4": 52,
        },
        "57.6": {
          "6": 195,
          "7": 20,
        },
        "39.6": {
          "4": 372,
        },
        "34.8": {
          "3": 13,
          "4": 24,
        },
        "40.4": {
          "4": 449,
        },
        "37.2": {
          "3": 11,
          "4": 108,
        },
        "60.2": {
          "6": 8,
          "7": 16,
        },
        "33.4": {
          "3": 13,
          "4": 4,
        },
        "36.6": {
          "3": 19,
          "4": 69,
        },
        "57.2": {
          "5": 1,
          "6": 285,
          "7": 21,
        },
        "33.8": {
          "3": 9,
          "4": 5,
        },
        "35.6": {
          "3": 11,
          "4": 37,
        },
        "32.8": {
          "3": 10,
          "4": 3,
        },
        "34.4": {
          "3": 15,
          "4": 12,
        },
        "57.8": {
          "6": 166,
          "7": 28,
        },
        "60.8": {
          "6": 3,
          "7": 10,
        },
        "31.2": {
          "2": 2,
          "3": 45,
        },
        "59.6": {
          "6": 18,
          "7": 16,
        },
        "33.2": {
          "3": 13,
          "4": 3,
        },
        "32.2": {
          "3": 6,
          "4": 1,
        },
        "60.6": {
          "6": 4,
          "7": 13,
        },
        "59.8": {
          "6": 11,
          "7": 17,
        },
        "32.6": {
          "3": 10,
          "4": 1,
        },
        "34.6": {
          "3": 11,
          "4": 15,
        },
        "34.2": {
          "3": 13,
          "4": 12,
        },
        "35.2": {
          "3": 16,
          "4": 16,
        },
        "31.4": {
          "3": 8,
        },
        "60.4": {
          "6": 6,
          "7": 12,
        },
        "31.8": {
          "3": 3,
        },
        "62.2": {
          "6": 1,
          "7": 1,
        },
        "61.2": {
          "7": 3,
        },
        "62.8": {
          "7": 1,
        },
        "31.6": {
          "3": 8,
        },
        "33.6": {
          "3": 7,
          "4": 8,
        },
        "61.4": {
          "6": 2,
          "7": 4,
        },
        "61.6": {
          "7": 3,
        },
        "32.4": {
          "3": 2,
          "4": 1,
        },
        "62.4": {
          "7": 2,
        },
        "61.8": {
          "7": 2,
        },
      },
      KO: {
        "15": {
          "1": 2371,
        },
        "16": {
          "1": 5580,
          "2": 22,
        },
        "17": {
          "1": 7519,
          "2": 749,
        },
        "18": {
          "1": 4680,
          "2": 3996,
        },
        "19": {
          "1": 816,
          "2": 5939,
        },
        "20": {
          "2": 4110,
        },
        "21": {
          "2": 2041,
        },
        "22": {
          "2": 836,
        },
        "15.6": {
          "1": 4113,
        },
        "20.8": {
          "2": 2506,
        },
        "20.6": {
          "2": 2800,
        },
        "18.4": {
          "1": 3005,
          "2": 5168,
        },
        "18.8": {
          "1": 1466,
          "2": 5949,
        },
        "21.2": {
          "2": 1724,
        },
        "22.2": {
          "2": 3390,
          "3": 31,
        },
        "19.6": {
          "1": 40,
          "2": 5155,
        },
        "19.2": {
          "1": 454,
          "2": 5958,
        },
        "18.6": {
          "1": 2226,
          "2": 5581,
        },
        "17.6": {
          "1": 6338,
          "2": 2575,
        },
        "17.2": {
          "1": 7474,
          "2": 1174,
        },
        "16.8": {
          "1": 7640,
          "2": 404,
        },
        "17.4": {
          "1": 6861,
          "2": 1909,
        },
        "21.8": {
          "2": 1009,
        },
        "15.8": {
          "1": 4807,
          "2": 3,
        },
        "20.2": {
          "2": 3741,
        },
        "17.8": {
          "1": 5502,
          "2": 3311,
        },
        "18.2": {
          "1": 4013,
          "2": 4525,
        },
        "14.6": {
          "1": 1513,
        },
        "16.4": {
          "1": 6745,
          "2": 78,
        },
        "20.4": {
          "2": 3168,
        },
        "19.4": {
          "1": 166,
          "2": 5597,
        },
        "15.2": {
          "1": 2907,
        },
        "16.2": {
          "1": 5990,
          "2": 32,
        },
        "16.6": {
          "1": 7015,
          "2": 207,
        },
        "19.8": {
          "1": 6,
          "2": 4596,
        },
        "15.4": {
          "1": 3597,
        },
        "14.2": {
          "1": 3104,
        },
        "14.8": {
          "1": 1944,
        },
        "21.6": {
          "2": 1234,
        },
        "21.4": {
          "2": 1402,
        },
        "14.4": {
          "1": 1188,
        },
      },
      TD: {
        "8": {
          "0": 328,
        },
        "9": {
          "0": 478,
          "1": 624,
        },
        "10": {
          "0": 1,
          "1": 2739,
        },
        "11": {
          "1": 5215,
        },
        "12": {
          "1": 7236,
        },
        "13": {
          "1": 7932,
        },
        "14": {
          "1": 6763,
        },
        "15": {
          "1": 4384,
        },
        "16": {
          "1": 2432,
          "2": 4,
        },
        "17": {
          "1": 975,
          "2": 84,
        },
        "18": {
          "1": 235,
          "2": 198,
        },
        "19": {
          "1": 32,
          "2": 476,
        },
        "12.2": {
          "1": 7472,
        },
        "11.8": {
          "1": 7132,
        },
        "14.6": {
          "1": 5452,
        },
        "14.8": {
          "1": 4935,
        },
        "11.6": {
          "1": 6589,
        },
        "12.4": {
          "1": 7804,
        },
        "12.6": {
          "1": 7908,
        },
        "10.2": {
          "1": 3150,
        },
        "11.4": {
          "1": 6247,
        },
        "12.8": {
          "1": 7978,
        },
        "10.8": {
          "1": 4603,
        },
        "14.4": {
          "1": 5789,
        },
        "13.2": {
          "1": 7772,
        },
        "9.4": {
          "0": 211,
          "1": 1546,
        },
        "13.8": {
          "1": 7134,
        },
        "15.2": {
          "1": 3964,
        },
        "16.2": {
          "1": 2021,
          "2": 9,
        },
        "15.6": {
          "1": 3179,
        },
        "14.2": {
          "1": 6412,
        },
        "17.8": {
          "1": 316,
          "2": 198,
        },
        "9.2": {
          "0": 334,
          "1": 1047,
        },
        "16.6": {
          "1": 1501,
          "2": 36,
        },
        "9.8": {
          "0": 31,
          "1": 2311,
        },
        "15.8": {
          "1": 2878,
          "2": 1,
        },
        "13.6": {
          "1": 7314,
        },
        "15.4": {
          "1": 3513,
        },
        "13.4": {
          "1": 7674,
        },
        "11.2": {
          "1": 5642,
        },
        "16.4": {
          "1": 1744,
          "2": 19,
        },
        "10.6": {
          "1": 4169,
        },
        "17.2": {
          "1": 817,
          "2": 105,
        },
        "9.6": {
          "0": 116,
          "1": 1883,
        },
        "17.6": {
          "1": 469,
          "2": 153,
        },
        "7.8": {
          "0": 1041,
        },
        "8.2": {
          "0": 421,
          "1": 5,
        },
        "17.4": {
          "1": 579,
          "2": 142,
        },
        "8.4": {
          "0": 550,
          "1": 43,
        },
        "10.4": {
          "1": 3661,
        },
        "8.8": {
          "0": 630,
          "1": 254,
        },
        "18.6": {
          "1": 46,
          "2": 152,
        },
        "8.6": {
          "0": 634,
          "1": 116,
        },
        "16.8": {
          "1": 1222,
          "2": 61,
        },
        "18.4": {
          "1": 96,
          "2": 164,
        },
        "18.2": {
          "1": 137,
          "2": 164,
        },
        "18.8": {
          "1": 29,
          "2": 139,
        },
      },
      KONF: {
        "7": {
          "0": 631,
        },
        "8": {
          "0": 2768,
          "1": 21,
        },
        "9": {
          "0": 2155,
          "1": 4513,
        },
        "10": {
          "1": 10136,
        },
        "11": {
          "1": 9617,
        },
        "12": {
          "1": 6159,
        },
        "13": {
          "1": 2757,
        },
        "14": {
          "1": 880,
        },
        "9.6": {
          "0": 198,
          "1": 8804,
        },
        "11.6": {
          "1": 7809,
        },
        "11.4": {
          "1": 8442,
        },
        "9.4": {
          "0": 640,
          "1": 7639,
        },
        "10.6": {
          "1": 10356,
        },
        "10.2": {
          "1": 10360,
        },
        "10.8": {
          "1": 10171,
        },
        "10.4": {
          "1": 10346,
        },
        "12.2": {
          "1": 5397,
        },
        "11.2": {
          "1": 9152,
        },
        "7.8": {
          "0": 2147,
        },
        "12.6": {
          "1": 3964,
        },
        "11.8": {
          "1": 6977,
        },
        "14.8": {
          "1": 1142,
          "2": 18,
        },
        "9.8": {
          "0": 38,
          "1": 9693,
        },
        "13.2": {
          "1": 2299,
        },
        "8.8": {
          "0": 3107,
          "1": 2690,
        },
        "8.4": {
          "0": 3741,
          "1": 459,
        },
        "12.4": {
          "1": 4620,
        },
        "8.6": {
          "0": 3658,
          "1": 1173,
        },
        "14.6": {
          "1": 436,
        },
        "7.4": {
          "0": 1233,
        },
        "8.2": {
          "0": 3260,
          "1": 128,
        },
        "9.2": {
          "0": 1284,
          "1": 6181,
        },
        "13.6": {
          "1": 1448,
        },
        "13.4": {
          "1": 1776,
        },
        "13.8": {
          "1": 1135,
        },
        "7.6": {
          "0": 1656,
        },
        "12.8": {
          "1": 3306,
        },
        "6.8": {
          "0": 1329,
        },
        "7.2": {
          "0": 883,
        },
        "14.2": {
          "1": 697,
        },
        "14.4": {
          "1": 571,
        },
      },
      LEWICA: {
        "7": {
          "0": 2214,
        },
        "8": {
          "0": 4091,
          "1": 29,
        },
        "9": {
          "0": 3243,
          "1": 6765,
        },
        "10": {
          "0": 1,
          "1": 12160,
        },
        "11": {
          "1": 8014,
        },
        "12": {
          "1": 3441,
        },
        "13": {
          "1": 959,
        },
        "9.2": {
          "0": 1973,
          "1": 8907,
        },
        "10.2": {
          "1": 11750,
        },
        "10.8": {
          "1": 9288,
        },
        "9.4": {
          "0": 929,
          "1": 10695,
        },
        "11.6": {
          "1": 5056,
        },
        "10.6": {
          "1": 10253,
        },
        "9.6": {
          "0": 275,
          "1": 11932,
        },
        "11.2": {
          "1": 7134,
        },
        "11.4": {
          "1": 6173,
        },
        "9.8": {
          "0": 41,
          "1": 12195,
        },
        "12.4": {
          "1": 2069,
        },
        "12.8": {
          "1": 1282,
        },
        "10.4": {
          "1": 10903,
        },
        "7.2": {
          "0": 1217,
        },
        "7.8": {
          "0": 3190,
          "1": 1,
        },
        "13.2": {
          "1": 749,
        },
        "8.8": {
          "0": 4709,
          "1": 4156,
        },
        "12.2": {
          "1": 2722,
        },
        "8.6": {
          "0": 5843,
          "1": 1907,
        },
        "7.6": {
          "0": 2506,
        },
        "11.8": {
          "1": 4275,
        },
        "8.4": {
          "0": 5705,
          "1": 666,
        },
        "7.4": {
          "0": 1757,
        },
        "8.2": {
          "0": 5135,
          "1": 150,
        },
        "12.6": {
          "1": 1678,
        },
        "13.4": {
          "1": 1860,
          "2": 2,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 4095,
      TD: 2018,
      LEWICA: 10213,
    },
    potencjalneMandaty: {
      KO: 63817,
      TD: 12919,
      LEWICA: 41800,
    },
    ostatnieMandaty: {
      KO: 53078,
      TD: 6689,
      LEWICA: 40944,
    },
    dawcyGlosow: {
      KO: 8712,
      TD: 6600,
      LEWICA: 25260,
    },
  },
  Sieradz: {
    procentyWOkreguSrednia: {
      PIS: 43.345779348619594,
      KO: 23.63887877718164,
      TD: 13.134742396312365,
      KONF: 8.70874281714308,
      LEWICA: 11.171856660743323,
    },
    odchylenieWOkregu: {
      PIS: 5.106757515250842,
      KO: 2.9800504922414737,
      TD: 2.013774734692939,
      LEWICA: 1.623484103138016,
      KONF: 0.7141982985720151,
    },
    wykresRozkladu: {
      "51.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "22.8%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "8.8%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "5%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
      "3%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "1.1%": {
        KO: 2,
        LEWICA: 2,
        NIEDEMO: 7,
        TD: 1,
      },
      "0.7%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.5%": {
        KO: 2,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 2,
      },
      "0.3%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.2%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "61.4%": {
        DEMO: 5,
        NIEDEMO: 7,
      },
      "32.3%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
      "5.2%": {
        DEMO: 4,
        NIEDEMO: 8,
      },
    },
    histogram: {
      PIS: {
        "31": {
          "4": 16,
        },
        "32": {
          "4": 38,
        },
        "33": {
          "4": 65,
          "5": 2,
        },
        "34": {
          "4": 105,
          "5": 42,
        },
        "35": {
          "4": 112,
          "5": 174,
        },
        "36": {
          "4": 60,
          "5": 454,
        },
        "37": {
          "4": 11,
          "5": 846,
        },
        "38": {
          "5": 1373,
        },
        "39": {
          "5": 2082,
          "6": 19,
        },
        "40": {
          "5": 2505,
          "6": 309,
        },
        "41": {
          "5": 2381,
          "6": 1219,
        },
        "42": {
          "5": 1726,
          "6": 2543,
        },
        "43": {
          "5": 834,
          "6": 3897,
        },
        "44": {
          "5": 246,
          "6": 4462,
        },
        "45": {
          "5": 22,
          "6": 4323,
          "7": 1,
        },
        "46": {
          "6": 3441,
          "7": 43,
        },
        "47": {
          "6": 2456,
          "7": 251,
        },
        "48": {
          "6": 1359,
          "7": 461,
        },
        "49": {
          "6": 541,
          "7": 537,
        },
        "50": {
          "6": 150,
          "7": 463,
        },
        "51": {
          "6": 20,
          "7": 244,
        },
        "52": {
          "6": 4,
          "7": 125,
        },
        "53": {
          "7": 64,
        },
        "54": {
          "7": 15,
          "8": 3,
        },
        "55": {
          "7": 8,
        },
        "56": {
          "7": 1,
        },
        "58": {
          "8": 1,
        },
        "41.2": {
          "5": 2337,
          "6": 1501,
        },
        "42.2": {
          "5": 1511,
          "6": 2876,
        },
        "47.4": {
          "6": 2071,
          "7": 331,
        },
        "42.8": {
          "5": 1034,
          "6": 3719,
        },
        "43.6": {
          "5": 434,
          "6": 4330,
        },
        "46.6": {
          "6": 2937,
          "7": 132,
        },
        "39.4": {
          "5": 2285,
          "6": 84,
        },
        "42.6": {
          "5": 1267,
          "6": 3508,
        },
        "45.2": {
          "5": 19,
          "6": 4186,
          "7": 3,
        },
        "40.4": {
          "5": 2454,
          "6": 665,
        },
        "41.8": {
          "5": 1776,
          "6": 2339,
        },
        "41.4": {
          "5": 2218,
          "6": 1699,
        },
        "37.2": {
          "4": 8,
          "5": 928,
        },
        "47.8": {
          "6": 1575,
          "7": 402,
        },
        "40.2": {
          "5": 2498,
          "6": 460,
        },
        "43.2": {
          "5": 729,
          "6": 4035,
        },
        "46.4": {
          "6": 3099,
          "7": 97,
        },
        "40.8": {
          "5": 2413,
          "6": 1020,
        },
        "43.8": {
          "5": 328,
          "6": 4299,
        },
        "41.6": {
          "5": 1975,
          "6": 2073,
        },
        "39.2": {
          "5": 2204,
          "6": 47,
        },
        "34.4": {
          "4": 118,
          "5": 77,
        },
        "32.6": {
          "4": 68,
        },
        "39.8": {
          "5": 2444,
          "6": 224,
        },
        "45.6": {
          "5": 4,
          "6": 3788,
          "7": 17,
        },
        "49.8": {
          "6": 219,
          "7": 477,
        },
        "48.2": {
          "6": 1158,
          "7": 520,
        },
        "42.4": {
          "5": 1328,
          "6": 3051,
        },
        "44.6": {
          "5": 68,
          "6": 4359,
        },
        "45.8": {
          "5": 1,
          "6": 3590,
          "7": 29,
        },
        "37.8": {
          "4": 1,
          "5": 1279,
        },
        "38.4": {
          "5": 1616,
          "6": 1,
        },
        "50.4": {
          "6": 94,
          "7": 405,
        },
        "38.2": {
          "5": 1505,
        },
        "47.2": {
          "6": 2213,
          "7": 264,
        },
        "45.4": {
          "5": 5,
          "6": 3967,
          "7": 5,
        },
        "44.4": {
          "5": 120,
          "6": 4494,
        },
        "38.6": {
          "5": 1685,
          "6": 4,
        },
        "43.4": {
          "5": 555,
          "6": 4123,
        },
        "44.2": {
          "5": 182,
          "6": 4405,
        },
        "48.8": {
          "6": 651,
          "7": 580,
        },
        "37.6": {
          "4": 1,
          "5": 1136,
        },
        "46.2": {
          "6": 3202,
          "7": 57,
        },
        "40.6": {
          "5": 2550,
          "6": 831,
        },
        "44.8": {
          "5": 42,
          "6": 4291,
          "7": 1,
        },
        "34.2": {
          "4": 119,
          "5": 42,
        },
        "39.6": {
          "5": 2289,
          "6": 115,
        },
        "46.8": {
          "6": 2676,
          "7": 171,
        },
        "35.8": {
          "4": 63,
          "5": 393,
        },
        "51.4": {
          "6": 16,
          "7": 198,
        },
        "48.4": {
          "6": 947,
          "7": 560,
        },
        "37.4": {
          "4": 3,
          "5": 1092,
        },
        "51.8": {
          "6": 3,
          "7": 163,
        },
        "35.4": {
          "4": 85,
          "5": 272,
        },
        "51.2": {
          "6": 23,
          "7": 239,
        },
        "36.6": {
          "4": 20,
          "5": 668,
        },
        "38.8": {
          "5": 1854,
          "6": 8,
        },
        "47.6": {
          "6": 1749,
          "7": 375,
        },
        "33.4": {
          "4": 94,
          "5": 9,
        },
        "51.6": {
          "6": 9,
          "7": 180,
        },
        "36.8": {
          "4": 20,
          "5": 776,
        },
        "48.6": {
          "6": 779,
          "7": 575,
        },
        "52.4": {
          "6": 3,
          "7": 96,
        },
        "50.8": {
          "6": 60,
          "7": 302,
        },
        "36.2": {
          "4": 43,
          "5": 553,
        },
        "50.6": {
          "6": 68,
          "7": 344,
        },
        "34.6": {
          "4": 117,
          "5": 78,
        },
        "49.6": {
          "6": 269,
          "7": 516,
        },
        "30.6": {
          "3": 7,
          "4": 61,
        },
        "49.2": {
          "6": 476,
          "7": 581,
        },
        "35.6": {
          "4": 72,
          "5": 295,
        },
        "36.4": {
          "4": 31,
          "5": 623,
        },
        "33.6": {
          "4": 95,
          "5": 19,
        },
        "50.2": {
          "6": 110,
          "7": 393,
        },
        "33.8": {
          "4": 100,
          "5": 27,
        },
        "49.4": {
          "6": 347,
          "7": 568,
        },
        "31.6": {
          "4": 21,
        },
        "34.8": {
          "4": 119,
          "5": 125,
        },
        "35.2": {
          "4": 106,
          "5": 253,
        },
        "33.2": {
          "4": 79,
          "5": 11,
        },
        "52.8": {
          "6": 1,
          "7": 52,
        },
        "53.4": {
          "7": 37,
          "8": 1,
        },
        "32.2": {
          "4": 45,
        },
        "53.6": {
          "7": 29,
        },
        "53.2": {
          "7": 38,
          "8": 1,
        },
        "52.2": {
          "6": 1,
          "7": 124,
          "8": 1,
        },
        "52.6": {
          "7": 67,
          "8": 2,
        },
        "31.8": {
          "4": 35,
        },
        "53.8": {
          "7": 15,
        },
        "32.4": {
          "4": 45,
        },
        "32.8": {
          "4": 70,
          "5": 2,
        },
        "31.4": {
          "4": 18,
        },
        "54.8": {
          "7": 5,
          "8": 1,
        },
        "55.4": {
          "7": 5,
        },
        "56.8": {
          "7": 1,
        },
        "54.2": {
          "7": 14,
          "8": 1,
        },
        "31.2": {
          "4": 16,
        },
        "54.6": {
          "7": 6,
          "8": 3,
        },
        "30.8": {
          "4": 16,
        },
        "56.2": {
          "7": 1,
          "8": 1,
        },
        "55.8": {
          "7": 5,
          "8": 2,
        },
        "54.4": {
          "7": 15,
        },
        "58.6": {
          "8": 1,
        },
        "55.2": {
          "7": 4,
        },
        "56.4": {
          "7": 1,
        },
      },
      KO: {
        "17": {
          "2": 258,
        },
        "18": {
          "2": 634,
        },
        "19": {
          "2": 1256,
        },
        "20": {
          "2": 2087,
          "3": 224,
        },
        "21": {
          "2": 1457,
          "3": 2170,
        },
        "22": {
          "2": 242,
          "3": 4887,
        },
        "23": {
          "2": 1,
          "3": 5704,
        },
        "24": {
          "3": 5953,
        },
        "25": {
          "3": 5227,
        },
        "26": {
          "3": 4003,
          "4": 20,
        },
        "27": {
          "3": 2180,
          "4": 460,
        },
        "28": {
          "3": 670,
          "4": 979,
        },
        "29": {
          "3": 73,
          "4": 696,
        },
        "30": {
          "3": 4,
          "4": 378,
        },
        "31": {
          "4": 140,
        },
        "32": {
          "4": 44,
        },
        "20.6": {
          "2": 2001,
          "3": 1143,
        },
        "19.6": {
          "2": 1903,
          "3": 20,
        },
        "24.8": {
          "3": 5288,
        },
        "25.2": {
          "3": 5003,
        },
        "22.4": {
          "2": 42,
          "3": 5494,
        },
        "25.6": {
          "3": 4584,
          "4": 2,
        },
        "23.8": {
          "3": 5928,
        },
        "26.2": {
          "3": 3748,
          "4": 45,
        },
        "21.8": {
          "2": 417,
          "3": 4303,
        },
        "27.2": {
          "3": 1769,
          "4": 616,
        },
        "25.8": {
          "3": 4354,
          "4": 7,
        },
        "22.6": {
          "2": 8,
          "3": 5523,
        },
        "21.6": {
          "2": 659,
          "3": 3805,
        },
        "23.4": {
          "3": 5883,
        },
        "23.2": {
          "3": 5938,
        },
        "24.6": {
          "3": 5579,
        },
        "25.4": {
          "3": 4765,
          "4": 2,
        },
        "29.4": {
          "3": 25,
          "4": 561,
        },
        "27.8": {
          "3": 849,
          "4": 870,
        },
        "20.4": {
          "2": 2111,
          "3": 743,
        },
        "22.2": {
          "2": 103,
          "3": 5055,
        },
        "24.4": {
          "3": 5769,
        },
        "20.2": {
          "2": 2170,
          "3": 449,
        },
        "21.4": {
          "2": 919,
          "3": 3278,
        },
        "21.2": {
          "2": 1208,
          "3": 2816,
        },
        "27.4": {
          "3": 1530,
          "4": 718,
        },
        "19.2": {
          "2": 1462,
          "3": 1,
        },
        "20.8": {
          "2": 1713,
          "3": 1618,
        },
        "27.6": {
          "3": 1162,
          "4": 838,
        },
        "24.2": {
          "3": 5849,
        },
        "18.8": {
          "2": 1126,
        },
        "16.6": {
          "2": 171,
        },
        "18.6": {
          "2": 1029,
        },
        "31.6": {
          "4": 85,
        },
        "26.4": {
          "3": 3395,
          "4": 123,
        },
        "26.8": {
          "3": 2650,
          "4": 367,
        },
        "23.6": {
          "3": 6082,
        },
        "22.8": {
          "2": 2,
          "3": 5658,
        },
        "19.4": {
          "2": 1675,
          "3": 4,
        },
        "28.4": {
          "3": 315,
          "4": 924,
        },
        "18.2": {
          "2": 765,
        },
        "28.2": {
          "3": 448,
          "4": 960,
        },
        "18.4": {
          "2": 832,
        },
        "29.2": {
          "3": 37,
          "4": 641,
        },
        "19.8": {
          "2": 2027,
          "3": 86,
        },
        "17.2": {
          "2": 316,
        },
        "28.8": {
          "3": 127,
          "4": 801,
        },
        "26.6": {
          "3": 3003,
          "4": 223,
        },
        "28.6": {
          "3": 215,
          "4": 842,
        },
        "30.4": {
          "4": 288,
        },
        "29.8": {
          "3": 4,
          "4": 420,
        },
        "30.2": {
          "4": 326,
        },
        "17.4": {
          "2": 365,
        },
        "32.6": {
          "4": 125,
          "5": 16,
        },
        "17.8": {
          "2": 495,
        },
        "17.6": {
          "2": 449,
        },
        "30.6": {
          "4": 215,
        },
        "16.2": {
          "1": 35,
          "2": 557,
        },
        "29.6": {
          "3": 10,
          "4": 468,
        },
        "31.8": {
          "4": 68,
        },
        "32.2": {
          "4": 34,
        },
        "31.2": {
          "4": 127,
        },
        "30.8": {
          "4": 165,
        },
        "16.8": {
          "2": 223,
        },
        "16.4": {
          "2": 164,
        },
        "31.4": {
          "4": 95,
        },
        "32.4": {
          "4": 29,
        },
      },
      TD: {
        "9": {
          "1": 791,
        },
        "10": {
          "1": 2162,
        },
        "11": {
          "1": 4476,
        },
        "12": {
          "1": 6971,
        },
        "13": {
          "1": 8234,
          "2": 37,
        },
        "14": {
          "1": 3751,
          "2": 3828,
        },
        "15": {
          "1": 44,
          "2": 5017,
        },
        "16": {
          "2": 2673,
        },
        "17": {
          "2": 1120,
        },
        "18": {
          "2": 368,
        },
        "19": {
          "2": 99,
        },
        "13.6": {
          "1": 6332,
          "2": 1568,
        },
        "13.2": {
          "1": 7927,
          "2": 208,
        },
        "16.4": {
          "2": 2033,
        },
        "12.4": {
          "1": 7793,
        },
        "13.4": {
          "1": 7440,
          "2": 845,
        },
        "8.2": {
          "0": 152,
          "1": 1022,
        },
        "12.6": {
          "1": 7958,
        },
        "11.2": {
          "1": 5048,
        },
        "12.8": {
          "1": 8277,
          "2": 3,
        },
        "9.6": {
          "1": 1491,
        },
        "15.4": {
          "2": 4194,
        },
        "14.8": {
          "1": 210,
          "2": 5504,
        },
        "15.8": {
          "2": 3247,
        },
        "14.6": {
          "1": 611,
          "2": 5569,
        },
        "11.8": {
          "1": 6635,
        },
        "13.8": {
          "1": 5006,
          "2": 2660,
        },
        "15.6": {
          "2": 3646,
        },
        "11.6": {
          "1": 6105,
        },
        "14.4": {
          "1": 1299,
          "2": 5312,
        },
        "10.8": {
          "1": 3980,
        },
        "10.4": {
          "1": 2984,
        },
        "11.4": {
          "1": 5531,
        },
        "10.2": {
          "1": 2597,
        },
        "16.2": {
          "2": 2327,
        },
        "10.6": {
          "1": 3463,
        },
        "12.2": {
          "1": 7161,
        },
        "18.4": {
          "2": 233,
        },
        "14.2": {
          "1": 2321,
          "2": 4728,
        },
        "9.8": {
          "1": 1785,
        },
        "16.6": {
          "2": 1660,
        },
        "16.8": {
          "2": 1414,
        },
        "9.4": {
          "1": 1247,
        },
        "8.8": {
          "1": 612,
        },
        "17.4": {
          "2": 726,
        },
        "9.2": {
          "1": 949,
        },
        "18.8": {
          "2": 150,
        },
        "15.2": {
          "1": 4,
          "2": 4719,
        },
        "8.6": {
          "1": 497,
        },
        "18.6": {
          "2": 189,
        },
        "18.2": {
          "2": 292,
        },
        "17.2": {
          "2": 948,
        },
        "17.8": {
          "2": 470,
        },
        "17.6": {
          "2": 622,
        },
        "19.2": {
          "2": 303,
          "3": 31,
        },
        "8.4": {
          "1": 391,
        },
      },
      KONF: {
        "7": {
          "0": 2184,
          "1": 2357,
        },
        "8": {
          "1": 13710,
        },
        "9": {
          "1": 17060,
        },
        "10": {
          "1": 6087,
        },
        "8.6": {
          "1": 18548,
        },
        "8.8": {
          "1": 18416,
        },
        "7.8": {
          "1": 10971,
        },
        "9.2": {
          "1": 15276,
        },
        "8.4": {
          "1": 17810,
        },
        "8.2": {
          "1": 16084,
        },
        "9.4": {
          "1": 12893,
        },
        "10.4": {
          "1": 3100,
        },
        "7.4": {
          "0": 70,
          "1": 5668,
        },
        "9.6": {
          "1": 10175,
        },
        "10.8": {
          "1": 3333,
          "2": 4,
        },
        "9.8": {
          "1": 8052,
        },
        "7.2": {
          "0": 308,
          "1": 3429,
        },
        "10.2": {
          "1": 4361,
        },
        "7.6": {
          "0": 1,
          "1": 8089,
        },
        "10.6": {
          "1": 2014,
        },
      },
      LEWICA: {
        "8": {
          "1": 1312,
        },
        "9": {
          "1": 3894,
        },
        "10": {
          "1": 7622,
        },
        "11": {
          "1": 9944,
        },
        "12": {
          "1": 8557,
        },
        "13": {
          "1": 5057,
          "2": 28,
        },
        "14": {
          "1": 1044,
          "2": 1114,
        },
        "15": {
          "1": 10,
          "2": 665,
        },
        "16": {
          "2": 546,
        },
        "15.4": {
          "2": 374,
        },
        "10.8": {
          "1": 9864,
        },
        "11.8": {
          "1": 9110,
        },
        "11.2": {
          "1": 9877,
        },
        "10.6": {
          "1": 9451,
        },
        "9.8": {
          "1": 7021,
        },
        "10.2": {
          "1": 8264,
        },
        "12.2": {
          "1": 8115,
        },
        "13.4": {
          "1": 3396,
          "2": 381,
        },
        "11.6": {
          "1": 9385,
        },
        "10.4": {
          "1": 8922,
        },
        "12.8": {
          "1": 5930,
          "2": 2,
        },
        "12.6": {
          "1": 6586,
        },
        "13.8": {
          "1": 1626,
          "2": 990,
        },
        "9.4": {
          "1": 5417,
        },
        "8.6": {
          "1": 2689,
        },
        "13.6": {
          "1": 2492,
          "2": 697,
        },
        "12.4": {
          "1": 7431,
        },
        "9.6": {
          "1": 6247,
        },
        "11.4": {
          "1": 9914,
        },
        "14.6": {
          "1": 97,
          "2": 951,
        },
        "13.2": {
          "1": 4287,
          "2": 154,
        },
        "8.8": {
          "1": 3186,
        },
        "9.2": {
          "1": 4610,
        },
        "14.4": {
          "1": 265,
          "2": 1108,
        },
        "14.2": {
          "1": 529,
          "2": 1219,
        },
        "8.2": {
          "1": 1722,
        },
        "7.2": {
          "0": 872,
          "1": 518,
        },
        "7.8": {
          "1": 1035,
        },
        "8.4": {
          "1": 2255,
        },
        "7.4": {
          "0": 21,
          "1": 547,
        },
        "15.6": {
          "2": 277,
        },
        "15.2": {
          "2": 523,
        },
        "14.8": {
          "1": 28,
          "2": 808,
        },
        "7.6": {
          "0": 4,
          "1": 791,
        },
        "15.8": {
          "2": 219,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3354,
      TD: 2968,
      LEWICA: 941,
    },
    potencjalneMandaty: {
      KO: 48803,
      TD: 62462,
      LEWICA: 28634,
    },
    ostatnieMandaty: {
      KO: 36849,
      TD: 36100,
      LEWICA: 8885,
    },
    dawcyGlosow: {
      KO: 8289,
      TD: 6502,
      LEWICA: 2871,
    },
  },
  Chrzanów: {
    procentyWOkreguSrednia: {
      PIS: 45.06136941760765,
      KO: 25.144662377406434,
      TD: 11.199968096602788,
      KONF: 11.236774561315299,
      LEWICA: 7.357225547067845,
    },
    odchylenieWOkregu: {
      PIS: 1.4196471115780063,
      KO: 1.3044978409864079,
      TD: 2.013774734692939,
      LEWICA: 0.9670138708260414,
      KONF: 2.368691148547858,
    },
    wykresRozkladu: {
      "83.8%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "10.6%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 0,
      },
      "2.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 0,
      },
      "1.7%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.9%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "0.8%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "86.8%": {
        DEMO: 3,
        NIEDEMO: 5,
      },
      "10.6%": {
        DEMO: 2,
        NIEDEMO: 6,
      },
      "2.6%": {
        DEMO: 4,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "42": {
          "4": 1907,
        },
        "43": {
          "4": 4628,
          "5": 27,
        },
        "44": {
          "4": 7327,
          "5": 309,
        },
        "45": {
          "4": 7886,
          "5": 1191,
        },
        "46": {
          "4": 5100,
          "5": 2593,
        },
        "47": {
          "4": 1862,
          "5": 2914,
        },
        "48": {
          "4": 351,
          "5": 1927,
        },
        "49": {
          "4": 29,
          "5": 798,
        },
        "45.2": {
          "4": 7498,
          "5": 1589,
        },
        "47.2": {
          "4": 1471,
          "5": 2804,
        },
        "44.6": {
          "4": 8136,
          "5": 763,
        },
        "45.8": {
          "4": 5849,
          "5": 2337,
        },
        "46.2": {
          "4": 4338,
          "5": 2837,
        },
        "44.8": {
          "4": 8196,
          "5": 1009,
        },
        "47.8": {
          "4": 531,
          "5": 2151,
        },
        "46.6": {
          "4": 3016,
          "5": 3080,
        },
        "42.6": {
          "4": 3477,
          "5": 5,
        },
        "43.4": {
          "4": 6021,
          "5": 93,
        },
        "43.2": {
          "4": 5233,
          "5": 49,
        },
        "47.6": {
          "4": 790,
          "5": 2417,
        },
        "45.4": {
          "4": 6859,
          "5": 1807,
        },
        "44.2": {
          "4": 7814,
          "5": 474,
        },
        "41.8": {
          "3": 2,
          "4": 1504,
        },
        "47.4": {
          "4": 1053,
          "5": 2643,
        },
        "43.8": {
          "4": 6854,
          "5": 202,
        },
        "43.6": {
          "4": 6395,
          "5": 121,
        },
        "46.8": {
          "4": 2472,
          "5": 2994,
        },
        "41.6": {
          "3": 5,
          "4": 4544,
        },
        "42.2": {
          "4": 2366,
          "5": 3,
        },
        "46.4": {
          "4": 3729,
          "5": 2895,
        },
        "42.8": {
          "4": 3952,
          "5": 12,
        },
        "42.4": {
          "4": 2812,
          "5": 4,
        },
        "48.6": {
          "4": 103,
          "5": 1237,
        },
        "44.4": {
          "4": 7918,
          "5": 617,
        },
        "45.6": {
          "4": 6401,
          "5": 2061,
        },
        "48.2": {
          "4": 274,
          "5": 1677,
        },
        "49.2": {
          "4": 20,
          "5": 650,
        },
        "48.4": {
          "4": 168,
          "5": 1430,
        },
        "49.4": {
          "4": 22,
          "5": 2260,
          "6": 13,
        },
        "48.8": {
          "4": 69,
          "5": 1025,
        },
      },
      KO: {
        "22": {
          "2": 629,
        },
        "23": {
          "2": 3163,
        },
        "24": {
          "2": 8328,
        },
        "25": {
          "2": 12120,
        },
        "26": {
          "2": 9768,
          "3": 61,
        },
        "27": {
          "2": 4053,
          "3": 501,
        },
        "28": {
          "2": 521,
          "3": 744,
        },
        "29": {
          "2": 26,
          "3": 606,
        },
        "25.8": {
          "2": 10547,
          "3": 26,
        },
        "27.4": {
          "2": 2062,
          "3": 780,
        },
        "24.2": {
          "2": 9225,
        },
        "26.6": {
          "2": 6202,
          "3": 255,
        },
        "26.4": {
          "2": 7514,
          "3": 198,
        },
        "28.8": {
          "2": 35,
          "3": 272,
        },
        "25.4": {
          "2": 11739,
          "3": 3,
        },
        "28.6": {
          "2": 81,
          "3": 397,
        },
        "23.8": {
          "2": 7302,
        },
        "24.8": {
          "2": 11697,
        },
        "25.2": {
          "2": 11944,
        },
        "25.6": {
          "2": 11194,
          "3": 16,
        },
        "23.6": {
          "2": 6192,
        },
        "23.4": {
          "2": 5019,
        },
        "24.6": {
          "2": 11156,
        },
        "23.2": {
          "2": 4059,
        },
        "24.4": {
          "2": 10512,
        },
        "22.4": {
          "2": 1281,
        },
        "26.8": {
          "2": 5171,
          "3": 373,
        },
        "26.2": {
          "2": 8524,
          "3": 103,
        },
        "27.8": {
          "2": 886,
          "3": 740,
        },
        "27.2": {
          "2": 2987,
          "3": 673,
        },
        "22.6": {
          "2": 1868,
        },
        "22.8": {
          "2": 2482,
        },
        "22.2": {
          "2": 920,
        },
        "21.8": {
          "2": 1159,
        },
        "27.6": {
          "2": 1469,
          "3": 799,
        },
        "28.2": {
          "2": 333,
          "3": 612,
        },
        "28.4": {
          "2": 155,
          "3": 518,
        },
      },
      TD: {
        "7": {
          "0": 704,
        },
        "8": {
          "0": 1901,
        },
        "9": {
          "0": 3055,
          "1": 1126,
        },
        "10": {
          "0": 7,
          "1": 6998,
        },
        "11": {
          "1": 8562,
        },
        "12": {
          "1": 8053,
        },
        "13": {
          "1": 5343,
        },
        "14": {
          "1": 2744,
        },
        "15": {
          "1": 996,
        },
        "16": {
          "1": 239,
        },
        "17": {
          "1": 42,
          "2": 2,
        },
        "11.4": {
          "1": 8804,
        },
        "11.6": {
          "1": 8588,
        },
        "10.2": {
          "0": 4,
          "1": 7409,
        },
        "13.2": {
          "1": 4890,
        },
        "9.8": {
          "0": 43,
          "1": 6373,
        },
        "8.2": {
          "0": 2247,
        },
        "12.6": {
          "1": 6561,
        },
        "9.2": {
          "0": 2020,
          "1": 2605,
        },
        "13.8": {
          "1": 3165,
        },
        "9.4": {
          "0": 884,
          "1": 4525,
        },
        "12.2": {
          "1": 7546,
        },
        "14.4": {
          "1": 1852,
        },
        "11.8": {
          "1": 8301,
        },
        "9.6": {
          "0": 227,
          "1": 5560,
        },
        "10.6": {
          "0": 1,
          "1": 8310,
        },
        "14.6": {
          "1": 1551,
        },
        "10.4": {
          "0": 2,
          "1": 7851,
        },
        "13.4": {
          "1": 4344,
        },
        "11.2": {
          "1": 8838,
        },
        "8.6": {
          "0": 3122,
          "1": 46,
        },
        "7.8": {
          "0": 1492,
        },
        "12.8": {
          "1": 6142,
        },
        "10.8": {
          "1": 8394,
        },
        "7.6": {
          "0": 1329,
        },
        "13.6": {
          "1": 3606,
        },
        "6.2": {
          "0": 996,
        },
        "12.4": {
          "1": 7139,
        },
        "8.4": {
          "0": 2703,
          "1": 1,
        },
        "15.6": {
          "1": 444,
        },
        "15.2": {
          "1": 733,
        },
        "8.8": {
          "0": 3373,
          "1": 285,
        },
        "6.4": {
          "0": 326,
        },
        "7.4": {
          "0": 1095,
        },
        "7.2": {
          "0": 880,
        },
        "14.8": {
          "1": 1154,
        },
        "14.2": {
          "1": 2170,
        },
        "15.8": {
          "1": 296,
        },
        "16.6": {
          "1": 99,
        },
        "15.4": {
          "1": 557,
        },
        "6.6": {
          "0": 425,
        },
        "6.8": {
          "0": 501,
        },
        "16.8": {
          "1": 57,
        },
        "16.2": {
          "1": 175,
        },
        "16.4": {
          "1": 107,
        },
        "17.2": {
          "1": 60,
          "2": 20,
        },
      },
      KONF: {
        "6": {
          "0": 429,
        },
        "7": {
          "0": 1039,
        },
        "8": {
          "0": 2320,
        },
        "9": {
          "0": 3367,
          "1": 911,
        },
        "10": {
          "0": 6,
          "1": 6153,
        },
        "11": {
          "1": 7438,
        },
        "12": {
          "1": 6960,
        },
        "13": {
          "1": 5360,
        },
        "14": {
          "1": 3282,
        },
        "15": {
          "1": 1552,
        },
        "16": {
          "1": 571,
        },
        "17": {
          "1": 137,
          "2": 1,
        },
        "18": {
          "1": 21,
          "2": 9,
        },
        "10.4": {
          "0": 1,
          "1": 6971,
        },
        "7.2": {
          "0": 1385,
        },
        "11.4": {
          "1": 7542,
        },
        "10.6": {
          "0": 1,
          "1": 7110,
        },
        "10.8": {
          "1": 7172,
        },
        "7.4": {
          "0": 1570,
        },
        "9.2": {
          "0": 2183,
          "1": 2471,
        },
        "8.8": {
          "0": 3710,
          "1": 205,
        },
        "12.8": {
          "1": 5700,
        },
        "12.6": {
          "1": 6146,
        },
        "8.6": {
          "0": 3391,
          "1": 26,
        },
        "6.8": {
          "0": 918,
        },
        "13.6": {
          "1": 4060,
        },
        "11.8": {
          "1": 7237,
        },
        "17.4": {
          "1": 70,
          "2": 9,
        },
        "11.6": {
          "1": 7595,
        },
        "8.2": {
          "0": 2719,
        },
        "16.4": {
          "1": 343,
        },
        "13.2": {
          "1": 5019,
        },
        "13.4": {
          "1": 4617,
        },
        "15.4": {
          "1": 1038,
        },
        "7.6": {
          "0": 1750,
        },
        "6.2": {
          "0": 582,
        },
        "12.4": {
          "1": 6585,
        },
        "16.2": {
          "1": 431,
        },
        "9.6": {
          "0": 249,
          "1": 5189,
        },
        "15.6": {
          "1": 857,
        },
        "10.2": {
          "0": 6,
          "1": 6532,
        },
        "11.2": {
          "1": 7618,
        },
        "9.8": {
          "0": 40,
          "1": 5847,
        },
        "12.2": {
          "1": 6732,
        },
        "7.8": {
          "0": 2080,
        },
        "14.2": {
          "1": 2804,
        },
        "15.8": {
          "1": 699,
        },
        "17.8": {
          "1": 34,
          "2": 10,
        },
        "13.8": {
          "1": 3683,
        },
        "9.4": {
          "0": 914,
          "1": 4102,
        },
        "14.8": {
          "1": 1783,
        },
        "14.4": {
          "1": 2452,
        },
        "17.2": {
          "1": 100,
          "2": 4,
        },
        "8.4": {
          "0": 3155,
        },
        "6.4": {
          "0": 674,
        },
        "17.6": {
          "1": 43,
          "2": 14,
        },
        "15.2": {
          "1": 1199,
        },
        "5.8": {
          "0": 329,
        },
        "14.6": {
          "1": 2130,
        },
        "5.4": {
          "0": 1035,
        },
        "6.6": {
          "0": 808,
        },
        "16.6": {
          "1": 241,
        },
        "18.4": {
          "1": 17,
          "2": 28,
        },
        "5.6": {
          "0": 284,
        },
        "16.8": {
          "1": 183,
        },
        "18.2": {
          "1": 9,
          "2": 3,
        },
      },
      LEWICA: {
        "5": {
          "0": 1574,
        },
        "6": {
          "0": 6004,
        },
        "7": {
          "0": 15916,
        },
        "8": {
          "0": 13401,
        },
        "9": {
          "0": 3359,
          "1": 356,
        },
        "10": {
          "0": 47,
          "1": 263,
        },
        "7.4": {
          "0": 16925,
        },
        "5.2": {
          "0": 1217,
        },
        "8.2": {
          "0": 11519,
        },
        "8.4": {
          "0": 9038,
          "1": 2,
        },
        "8.6": {
          "0": 6981,
          "1": 21,
        },
        "7.6": {
          "0": 16453,
        },
        "6.4": {
          "0": 10064,
        },
        "6.6": {
          "0": 12230,
        },
        "7.8": {
          "0": 15318,
        },
        "7.2": {
          "0": 16502,
        },
        "6.8": {
          "0": 14296,
        },
        "6.2": {
          "0": 8003,
        },
        "5.8": {
          "0": 4217,
        },
        "5.4": {
          "0": 1897,
        },
        "9.2": {
          "0": 1866,
          "1": 622,
        },
        "8.8": {
          "0": 5301,
          "1": 118,
        },
        "5.6": {
          "0": 2963,
        },
        "9.4": {
          "0": 848,
          "1": 742,
        },
        "9.6": {
          "0": 367,
          "1": 631,
        },
        "9.8": {
          "0": 176,
          "1": 385,
        },
        "10.2": {
          "0": 37,
          "1": 341,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 840,
      TD: 7414,
      LEWICA: 156,
    },
    potencjalneMandaty: {
      KO: 31384,
      TD: 28395,
      LEWICA: 54732,
    },
    ostatnieMandaty: {
      KO: 7821,
      TD: 49189,
      LEWICA: 3076,
    },
    dawcyGlosow: {
      KO: 3439,
      TD: 18738,
      LEWICA: 149,
    },
  },
  Kraków: {
    procentyWOkreguSrednia: {
      PIS: 32.38320263049725,
      KO: 33.29045694902861,
      TD: 11.734263364371625,
      KONF: 9.531881339333024,
      LEWICA: 13.060195716769488,
    },
    odchylenieWOkregu: {
      PIS: 6.506598575381509,
      KO: 2.78514342283019,
      TD: 2.013774734692939,
      LEWICA: 1.829287758210337,
      KONF: 2.9056879440005754,
    },
    wykresRozkladu: {
      "27.2%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "15.9%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "10.4%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "10.2%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "7.5%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "6.9%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 7,
        TD: 1,
      },
      "6.1%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 2,
      },
      "3.7%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "3.4%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "2.6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "2.5%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "1.6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "47%": {
        DEMO: 8,
        NIEDEMO: 6,
      },
      "26.4%": {
        DEMO: 9,
        NIEDEMO: 5,
      },
      "20%": {
        DEMO: 7,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "17": {
          "2": 52,
          "3": 1,
        },
        "18": {
          "2": 49,
          "3": 28,
        },
        "19": {
          "2": 12,
          "3": 103,
        },
        "20": {
          "3": 159,
        },
        "21": {
          "3": 240,
        },
        "22": {
          "3": 365,
        },
        "23": {
          "3": 512,
          "4": 15,
        },
        "24": {
          "3": 511,
          "4": 229,
        },
        "25": {
          "3": 231,
          "4": 798,
        },
        "26": {
          "3": 18,
          "4": 1304,
        },
        "27": {
          "4": 1659,
        },
        "28": {
          "4": 2136,
          "5": 1,
        },
        "29": {
          "4": 2399,
          "5": 142,
        },
        "30": {
          "4": 1894,
          "5": 999,
        },
        "31": {
          "4": 855,
          "5": 2353,
        },
        "32": {
          "4": 169,
          "5": 3181,
        },
        "33": {
          "4": 5,
          "5": 3541,
        },
        "34": {
          "5": 3271,
          "6": 26,
        },
        "35": {
          "5": 2708,
          "6": 295,
        },
        "36": {
          "5": 1588,
          "6": 1003,
        },
        "37": {
          "5": 569,
          "6": 1604,
        },
        "38": {
          "5": 99,
          "6": 1538,
        },
        "39": {
          "5": 5,
          "6": 1290,
        },
        "40": {
          "6": 859,
          "7": 21,
        },
        "41": {
          "6": 452,
          "7": 85,
        },
        "42": {
          "6": 171,
          "7": 160,
        },
        "43": {
          "6": 44,
          "7": 128,
        },
        "44": {
          "6": 5,
          "7": 102,
        },
        "45": {
          "7": 43,
        },
        "46": {
          "7": 26,
          "8": 1,
        },
        "47": {
          "7": 4,
          "8": 2,
        },
        "48": {
          "7": 4,
        },
        "49": {
          "8": 2,
        },
        "50": {
          "8": 1,
        },
        "37.8": {
          "5": 170,
          "6": 1577,
        },
        "25.2": {
          "3": 146,
          "4": 895,
        },
        "34.2": {
          "5": 3178,
          "6": 61,
        },
        "33.2": {
          "5": 3538,
        },
        "36.4": {
          "5": 1190,
          "6": 1340,
        },
        "31.4": {
          "4": 484,
          "5": 2723,
        },
        "27.6": {
          "4": 1864,
        },
        "27.8": {
          "4": 1975,
        },
        "35.4": {
          "5": 2246,
          "6": 598,
        },
        "29.2": {
          "4": 2263,
          "5": 237,
        },
        "31.2": {
          "4": 662,
          "5": 2629,
        },
        "33.4": {
          "5": 3385,
        },
        "31.6": {
          "4": 325,
          "5": 2919,
        },
        "43.2": {
          "6": 36,
          "7": 98,
        },
        "26.4": {
          "3": 3,
          "4": 1473,
        },
        "32.2": {
          "4": 111,
          "5": 3220,
        },
        "28.6": {
          "4": 2321,
          "5": 48,
        },
        "29.6": {
          "4": 2202,
          "5": 567,
        },
        "34.6": {
          "5": 2984,
          "6": 165,
        },
        "32.8": {
          "4": 6,
          "5": 3519,
        },
        "37.4": {
          "5": 315,
          "6": 1648,
        },
        "30.2": {
          "4": 1744,
          "5": 1266,
        },
        "39.2": {
          "5": 5,
          "6": 1068,
        },
        "26.6": {
          "4": 1551,
        },
        "30.8": {
          "4": 1135,
          "5": 2091,
        },
        "23.8": {
          "3": 510,
          "4": 151,
        },
        "35.2": {
          "5": 2608,
          "6": 414,
        },
        "28.4": {
          "4": 2262,
          "5": 20,
        },
        "30.6": {
          "4": 1289,
          "5": 1744,
        },
        "33.8": {
          "5": 3384,
          "6": 8,
        },
        "38.4": {
          "5": 42,
          "6": 1439,
        },
        "32.4": {
          "4": 53,
          "5": 3344,
        },
        "21.8": {
          "3": 332,
        },
        "35.8": {
          "5": 1847,
          "6": 866,
        },
        "39.8": {
          "6": 915,
          "7": 14,
        },
        "36.6": {
          "5": 975,
          "6": 1345,
        },
        "30.4": {
          "4": 1470,
          "5": 1581,
        },
        "24.2": {
          "3": 484,
          "4": 330,
        },
        "35.6": {
          "5": 2078,
          "6": 700,
        },
        "28.2": {
          "4": 2116,
          "5": 9,
        },
        "32.6": {
          "4": 31,
          "5": 3416,
        },
        "23.2": {
          "3": 554,
          "4": 43,
        },
        "31.8": {
          "4": 220,
          "5": 3223,
        },
        "33.6": {
          "5": 3324,
          "6": 1,
        },
        "34.4": {
          "5": 3110,
          "6": 85,
        },
        "24.4": {
          "3": 374,
          "4": 424,
        },
        "27.2": {
          "4": 1781,
        },
        "29.4": {
          "4": 2343,
          "5": 360,
        },
        "38.2": {
          "5": 68,
          "6": 1515,
        },
        "29.8": {
          "4": 2075,
          "5": 728,
        },
        "38.6": {
          "5": 31,
          "6": 1396,
        },
        "28.8": {
          "4": 2325,
          "5": 69,
        },
        "23.6": {
          "3": 538,
          "4": 125,
        },
        "34.8": {
          "5": 2882,
          "6": 233,
        },
        "40.6": {
          "6": 628,
          "7": 75,
        },
        "39.6": {
          "5": 1,
          "6": 993,
          "7": 9,
        },
        "37.2": {
          "5": 413,
          "6": 1700,
        },
        "24.8": {
          "3": 264,
          "4": 652,
        },
        "40.4": {
          "6": 676,
          "7": 57,
        },
        "36.2": {
          "5": 1389,
          "6": 1161,
        },
        "26.8": {
          "4": 1619,
        },
        "22.6": {
          "3": 457,
          "4": 4,
        },
        "24.6": {
          "3": 343,
          "4": 533,
        },
        "25.4": {
          "3": 88,
          "4": 1023,
        },
        "36.8": {
          "5": 820,
          "6": 1460,
        },
        "37.6": {
          "5": 227,
          "6": 1669,
        },
        "20.8": {
          "3": 229,
        },
        "38.8": {
          "5": 12,
          "6": 1308,
        },
        "26.2": {
          "3": 7,
          "4": 1368,
        },
        "40.8": {
          "6": 555,
          "7": 79,
        },
        "40.2": {
          "6": 739,
          "7": 37,
        },
        "16.2": {
          "0": 1,
          "1": 38,
          "2": 311,
        },
        "25.8": {
          "3": 37,
          "4": 1158,
        },
        "22.8": {
          "3": 523,
          "4": 4,
        },
        "27.4": {
          "4": 1796,
        },
        "45.8": {
          "7": 21,
        },
        "19.6": {
          "3": 138,
        },
        "21.4": {
          "3": 287,
        },
        "25.6": {
          "3": 66,
          "4": 1108,
        },
        "21.6": {
          "3": 305,
        },
        "41.8": {
          "6": 220,
          "7": 126,
        },
        "22.4": {
          "3": 425,
        },
        "23.4": {
          "3": 544,
          "4": 46,
        },
        "43.4": {
          "6": 17,
          "7": 134,
        },
        "42.8": {
          "6": 67,
          "7": 140,
        },
        "44.6": {
          "6": 5,
          "7": 58,
        },
        "39.4": {
          "6": 1090,
          "7": 4,
        },
        "42.6": {
          "6": 68,
          "7": 155,
        },
        "41.2": {
          "6": 392,
          "7": 116,
        },
        "22.2": {
          "3": 404,
        },
        "42.2": {
          "6": 157,
          "7": 150,
        },
        "16.8": {
          "2": 45,
        },
        "21.2": {
          "3": 257,
        },
        "20.6": {
          "3": 231,
        },
        "17.8": {
          "2": 51,
          "3": 21,
        },
        "17.2": {
          "2": 42,
          "3": 4,
        },
        "20.4": {
          "3": 186,
        },
        "20.2": {
          "3": 156,
        },
        "41.6": {
          "6": 288,
          "7": 143,
        },
        "42.4": {
          "6": 97,
          "7": 185,
        },
        "19.4": {
          "2": 2,
          "3": 118,
        },
        "18.2": {
          "2": 33,
          "3": 39,
        },
        "18.8": {
          "2": 16,
          "3": 75,
        },
        "43.6": {
          "6": 13,
          "7": 112,
        },
        "19.2": {
          "2": 9,
          "3": 117,
        },
        "44.4": {
          "6": 4,
          "7": 64,
        },
        "48.4": {
          "7": 1,
          "8": 3,
        },
        "45.6": {
          "7": 28,
          "8": 1,
        },
        "18.4": {
          "2": 34,
          "3": 58,
        },
        "19.8": {
          "3": 142,
        },
        "47.8": {
          "7": 3,
          "8": 1,
        },
        "17.4": {
          "2": 44,
          "3": 6,
        },
        "41.4": {
          "6": 320,
          "7": 102,
        },
        "18.6": {
          "2": 25,
          "3": 64,
        },
        "16.4": {
          "2": 38,
        },
        "16.6": {
          "2": 34,
        },
        "44.2": {
          "6": 2,
          "7": 81,
        },
        "48.6": {
          "7": 2,
          "8": 1,
        },
        "45.2": {
          "6": 1,
          "7": 34,
        },
        "17.6": {
          "2": 62,
          "3": 6,
        },
        "46.4": {
          "7": 10,
          "8": 1,
        },
        "44.8": {
          "6": 1,
          "7": 46,
        },
        "43.8": {
          "6": 13,
          "7": 86,
        },
        "46.8": {
          "7": 6,
          "8": 1,
        },
        "45.4": {
          "7": 27,
        },
        "47.2": {
          "7": 6,
          "8": 5,
        },
        "46.6": {
          "7": 8,
          "8": 3,
        },
        "46.2": {
          "7": 10,
          "8": 1,
        },
        "47.6": {
          "7": 3,
          "8": 2,
        },
        "47.4": {
          "7": 4,
          "8": 3,
        },
        "48.8": {
          "7": 2,
          "8": 1,
        },
        "49.6": {
          "8": 1,
        },
        "49.4": {
          "8": 1,
        },
      },
      KO: {
        "27": {
          "4": 520,
        },
        "28": {
          "4": 1100,
        },
        "29": {
          "4": 1862,
          "5": 125,
        },
        "30": {
          "4": 1937,
          "5": 1121,
        },
        "31": {
          "4": 977,
          "5": 3121,
        },
        "32": {
          "4": 224,
          "5": 4631,
        },
        "33": {
          "4": 2,
          "5": 5019,
        },
        "34": {
          "5": 4801,
          "6": 42,
        },
        "35": {
          "5": 3756,
          "6": 416,
        },
        "36": {
          "5": 2138,
          "6": 1192,
        },
        "37": {
          "5": 781,
          "6": 1682,
        },
        "38": {
          "5": 131,
          "6": 1552,
        },
        "39": {
          "5": 6,
          "6": 979,
        },
        "40": {
          "6": 624,
          "7": 9,
        },
        "41": {
          "6": 347,
          "7": 38,
        },
        "35.2": {
          "5": 3492,
          "6": 555,
        },
        "30.4": {
          "4": 1583,
          "5": 1838,
        },
        "30.6": {
          "4": 1435,
          "5": 2264,
        },
        "31.2": {
          "4": 777,
          "5": 3559,
        },
        "33.2": {
          "5": 5137,
        },
        "32.2": {
          "4": 134,
          "5": 4849,
        },
        "31.8": {
          "4": 298,
          "5": 4413,
        },
        "38.6": {
          "5": 27,
          "6": 1184,
        },
        "34.4": {
          "5": 4519,
          "6": 122,
        },
        "31.4": {
          "4": 562,
          "5": 3882,
        },
        "27.8": {
          "4": 939,
        },
        "37.8": {
          "5": 233,
          "6": 1581,
        },
        "30.2": {
          "4": 1772,
          "5": 1448,
        },
        "34.6": {
          "5": 4171,
          "6": 201,
        },
        "33.4": {
          "5": 5071,
          "6": 1,
        },
        "37.2": {
          "5": 574,
          "6": 1683,
        },
        "33.8": {
          "5": 4923,
          "6": 6,
        },
        "29.4": {
          "4": 2015,
          "5": 398,
        },
        "32.4": {
          "4": 73,
          "5": 4936,
        },
        "38.8": {
          "5": 19,
          "6": 1192,
        },
        "36.4": {
          "5": 1549,
          "6": 1430,
        },
        "32.8": {
          "4": 13,
          "5": 5161,
        },
        "40.4": {
          "6": 502,
          "7": 21,
        },
        "27.6": {
          "4": 880,
        },
        "40.2": {
          "6": 520,
          "7": 15,
        },
        "29.8": {
          "4": 1945,
          "5": 912,
        },
        "36.6": {
          "5": 1243,
          "6": 1565,
        },
        "36.8": {
          "5": 915,
          "6": 1663,
        },
        "35.4": {
          "5": 3163,
          "6": 700,
        },
        "32.6": {
          "4": 29,
          "5": 5018,
        },
        "28.6": {
          "4": 1557,
          "5": 40,
        },
        "34.2": {
          "5": 4629,
          "6": 63,
        },
        "29.6": {
          "4": 2037,
          "5": 568,
        },
        "33.6": {
          "5": 5020,
          "6": 6,
        },
        "34.8": {
          "5": 4097,
          "6": 303,
        },
        "30.8": {
          "4": 1222,
          "5": 2614,
        },
        "41.6": {
          "6": 937,
          "7": 1188,
          "8": 22,
        },
        "38.4": {
          "5": 58,
          "6": 1263,
        },
        "31.6": {
          "4": 402,
          "5": 4113,
        },
        "29.2": {
          "4": 1889,
          "5": 241,
        },
        "35.6": {
          "5": 2862,
          "6": 863,
        },
        "28.8": {
          "4": 1772,
          "5": 89,
        },
        "27.2": {
          "4": 637,
        },
        "41.4": {
          "6": 231,
          "7": 56,
        },
        "39.2": {
          "5": 4,
          "6": 977,
          "7": 1,
        },
        "36.2": {
          "5": 1771,
          "6": 1444,
        },
        "39.6": {
          "6": 798,
          "7": 6,
        },
        "40.6": {
          "6": 451,
          "7": 29,
        },
        "27.4": {
          "4": 685,
        },
        "26.4": {
          "3": 193,
          "4": 1632,
        },
        "28.4": {
          "4": 1442,
          "5": 19,
        },
        "39.8": {
          "6": 721,
          "7": 8,
        },
        "35.8": {
          "5": 2474,
          "6": 966,
        },
        "37.4": {
          "5": 425,
          "6": 1666,
        },
        "38.2": {
          "5": 113,
          "6": 1428,
        },
        "28.2": {
          "4": 1321,
          "5": 7,
        },
        "39.4": {
          "5": 1,
          "6": 845,
          "7": 2,
        },
        "37.6": {
          "5": 301,
          "6": 1626,
        },
        "26.6": {
          "4": 398,
        },
        "40.8": {
          "6": 385,
          "7": 36,
        },
        "41.2": {
          "6": 275,
          "7": 59,
        },
        "26.8": {
          "4": 469,
        },
      },
      TD: {
        "7": {
          "1": 387,
        },
        "8": {
          "1": 1415,
        },
        "9": {
          "1": 3023,
        },
        "10": {
          "1": 5650,
        },
        "11": {
          "1": 7481,
        },
        "12": {
          "1": 5005,
          "2": 2852,
        },
        "13": {
          "1": 93,
          "2": 6417,
        },
        "14": {
          "2": 4174,
        },
        "15": {
          "2": 2114,
        },
        "16": {
          "2": 894,
        },
        "17": {
          "2": 335,
          "3": 2,
        },
        "9.4": {
          "1": 4126,
        },
        "14.6": {
          "2": 2812,
        },
        "10.4": {
          "1": 6526,
        },
        "13.2": {
          "1": 7,
          "2": 5887,
        },
        "13.4": {
          "2": 5430,
        },
        "15.6": {
          "2": 1290,
        },
        "11.2": {
          "1": 7758,
          "2": 9,
        },
        "15.4": {
          "2": 1590,
        },
        "12.8": {
          "1": 340,
          "2": 6446,
        },
        "11.4": {
          "1": 7787,
          "2": 128,
        },
        "9.8": {
          "1": 5071,
        },
        "10.8": {
          "1": 7321,
        },
        "15.8": {
          "2": 1165,
        },
        "16.6": {
          "2": 527,
        },
        "9.2": {
          "1": 3519,
        },
        "8.8": {
          "1": 2782,
        },
        "11.6": {
          "1": 7444,
          "2": 528,
        },
        "12.6": {
          "1": 885,
          "2": 6275,
        },
        "13.8": {
          "2": 4579,
        },
        "12.4": {
          "1": 1878,
          "2": 5490,
        },
        "6.6": {
          "0": 390,
          "1": 620,
        },
        "13.6": {
          "2": 4956,
        },
        "14.2": {
          "2": 3540,
        },
        "10.2": {
          "1": 6025,
        },
        "16.2": {
          "2": 752,
        },
        "8.4": {
          "1": 1916,
        },
        "10.6": {
          "1": 6884,
        },
        "9.6": {
          "1": 4655,
        },
        "12.2": {
          "1": 3583,
          "2": 4127,
        },
        "8.6": {
          "1": 2220,
        },
        "11.8": {
          "1": 6441,
          "2": 1399,
        },
        "15.2": {
          "2": 1827,
        },
        "16.4": {
          "2": 594,
        },
        "7.8": {
          "1": 1147,
        },
        "7.4": {
          "1": 736,
        },
        "7.6": {
          "1": 876,
        },
        "17.8": {
          "2": 230,
          "3": 328,
        },
        "14.8": {
          "2": 2504,
        },
        "8.2": {
          "1": 1646,
        },
        "14.4": {
          "2": 3273,
        },
        "6.8": {
          "1": 335,
        },
        "7.2": {
          "1": 543,
        },
        "16.8": {
          "2": 393,
        },
        "17.2": {
          "2": 270,
          "3": 8,
        },
        "17.6": {
          "2": 131,
          "3": 20,
        },
        "17.4": {
          "2": 172,
          "3": 17,
        },
      },
      KONF: {
        "3": {
          "0": 363,
        },
        "4": {
          "0": 780,
        },
        "5": {
          "0": 1507,
        },
        "6": {
          "0": 1635,
          "1": 889,
        },
        "7": {
          "1": 3728,
        },
        "8": {
          "1": 4940,
        },
        "9": {
          "1": 5790,
        },
        "10": {
          "1": 5937,
        },
        "11": {
          "1": 5188,
        },
        "12": {
          "1": 2408,
          "2": 1375,
        },
        "13": {
          "1": 23,
          "2": 2594,
        },
        "14": {
          "2": 1455,
        },
        "15": {
          "2": 762,
        },
        "16": {
          "2": 356,
        },
        "17": {
          "2": 143,
          "3": 2,
        },
        "18": {
          "2": 30,
          "3": 14,
        },
        "9.6": {
          "1": 5767,
        },
        "12.6": {
          "1": 355,
          "2": 2708,
        },
        "10.6": {
          "1": 5266,
        },
        "6.8": {
          "1": 3525,
        },
        "9.2": {
          "1": 5759,
        },
        "13.8": {
          "2": 1683,
        },
        "10.8": {
          "1": 5242,
        },
        "4.8": {
          "0": 1372,
        },
        "13.4": {
          "2": 2098,
        },
        "11.6": {
          "1": 4112,
          "2": 322,
        },
        "9.4": {
          "1": 5769,
        },
        "6.4": {
          "0": 172,
          "1": 2879,
        },
        "7.4": {
          "1": 4277,
        },
        "9.8": {
          "1": 5830,
        },
        "15.4": {
          "2": 545,
        },
        "12.4": {
          "1": 779,
          "2": 2570,
        },
        "5.2": {
          "0": 1659,
        },
        "4.2": {
          "0": 901,
        },
        "6.2": {
          "0": 800,
          "1": 2033,
        },
        "11.4": {
          "1": 4604,
          "2": 78,
        },
        "8.8": {
          "1": 5684,
        },
        "6.6": {
          "0": 20,
          "1": 3268,
        },
        "4.6": {
          "0": 1217,
        },
        "8.4": {
          "1": 5283,
        },
        "11.2": {
          "1": 4944,
          "2": 4,
        },
        "10.2": {
          "1": 5666,
        },
        "14.4": {
          "2": 1179,
        },
        "7.2": {
          "1": 3916,
        },
        "7.6": {
          "1": 4450,
        },
        "10.4": {
          "1": 5585,
        },
        "12.8": {
          "1": 97,
          "2": 2751,
        },
        "7.8": {
          "1": 4770,
        },
        "15.2": {
          "2": 615,
        },
        "5.6": {
          "0": 2062,
          "1": 7,
        },
        "8.2": {
          "1": 5206,
        },
        "8.6": {
          "1": 5458,
        },
        "13.2": {
          "1": 3,
          "2": 2424,
        },
        "13.6": {
          "2": 1860,
        },
        "5.8": {
          "0": 2103,
          "1": 165,
        },
        "2.2": {
          "0": 1088,
        },
        "12.2": {
          "1": 1562,
          "2": 2105,
        },
        "16.8": {
          "2": 162,
        },
        "3.6": {
          "0": 600,
        },
        "5.4": {
          "0": 1827,
        },
        "11.8": {
          "1": 3323,
          "2": 759,
        },
        "15.6": {
          "2": 476,
        },
        "4.4": {
          "0": 1044,
        },
        "3.8": {
          "0": 664,
        },
        "17.8": {
          "2": 49,
          "3": 13,
        },
        "14.2": {
          "2": 1292,
        },
        "18.2": {
          "2": 48,
          "3": 137,
        },
        "14.8": {
          "2": 887,
        },
        "3.2": {
          "0": 418,
        },
        "16.2": {
          "2": 279,
        },
        "14.6": {
          "2": 1063,
        },
        "3.4": {
          "0": 507,
        },
        "17.2": {
          "2": 115,
          "3": 5,
        },
        "2.8": {
          "0": 347,
        },
        "17.4": {
          "2": 90,
          "3": 5,
        },
        "16.4": {
          "2": 235,
        },
        "2.4": {
          "0": 228,
        },
        "16.6": {
          "2": 211,
        },
        "15.8": {
          "2": 406,
        },
        "2.6": {
          "0": 231,
        },
        "17.6": {
          "2": 51,
          "3": 12,
        },
      },
      LEWICA: {
        "9": {
          "1": 702,
        },
        "10": {
          "1": 2250,
        },
        "11": {
          "1": 4684,
        },
        "12": {
          "1": 4360,
          "2": 2927,
        },
        "13": {
          "1": 79,
          "2": 8334,
        },
        "14": {
          "2": 7289,
        },
        "15": {
          "2": 4755,
        },
        "16": {
          "2": 2622,
        },
        "17": {
          "2": 1087,
          "3": 12,
        },
        "18": {
          "2": 238,
          "3": 149,
        },
        "12.6": {
          "1": 869,
          "2": 7356,
        },
        "15.6": {
          "2": 3355,
        },
        "12.4": {
          "1": 1739,
          "2": 6234,
        },
        "12.2": {
          "1": 3096,
          "2": 4661,
        },
        "8.8": {
          "1": 559,
        },
        "13.8": {
          "2": 7575,
        },
        "15.2": {
          "2": 4175,
        },
        "11.6": {
          "1": 5937,
          "2": 544,
        },
        "11.8": {
          "1": 5381,
          "2": 1468,
        },
        "12.8": {
          "1": 298,
          "2": 8183,
        },
        "11.4": {
          "1": 5761,
          "2": 111,
        },
        "10.6": {
          "1": 3687,
        },
        "9.8": {
          "1": 1800,
        },
        "15.8": {
          "2": 2917,
        },
        "17.6": {
          "2": 539,
          "3": 102,
        },
        "15.4": {
          "2": 3804,
        },
        "16.4": {
          "2": 1901,
        },
        "8.4": {
          "0": 6,
          "1": 1167,
        },
        "10.8": {
          "1": 4309,
        },
        "13.6": {
          "2": 7953,
        },
        "13.4": {
          "2": 8081,
        },
        "9.4": {
          "1": 1191,
        },
        "13.2": {
          "1": 4,
          "2": 8399,
        },
        "14.2": {
          "2": 6929,
        },
        "18.4": {
          "2": 82,
          "3": 182,
        },
        "11.2": {
          "1": 5437,
          "2": 8,
        },
        "10.4": {
          "1": 3034,
        },
        "16.6": {
          "2": 1566,
        },
        "14.4": {
          "2": 6362,
        },
        "9.2": {
          "1": 956,
        },
        "8.6": {
          "1": 429,
        },
        "16.8": {
          "2": 1369,
          "3": 2,
        },
        "16.2": {
          "2": 2200,
        },
        "14.6": {
          "2": 5809,
        },
        "10.2": {
          "1": 2652,
        },
        "18.6": {
          "2": 106,
          "3": 840,
        },
        "14.8": {
          "2": 5448,
        },
        "17.4": {
          "2": 737,
          "3": 54,
        },
        "17.2": {
          "2": 854,
          "3": 24,
        },
        "9.6": {
          "1": 1468,
        },
        "17.8": {
          "2": 371,
          "3": 114,
        },
        "18.2": {
          "2": 162,
          "3": 155,
        },
      },
    },
    minimumMandatow: {
      KO: 3,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2710,
      TD: 3729,
      LEWICA: 4692,
    },
    potencjalneMandaty: {
      KO: 58743,
      TD: 51720,
      LEWICA: 47345,
    },
    ostatnieMandaty: {
      KO: 43559,
      TD: 27453,
      LEWICA: 31125,
    },
    dawcyGlosow: {
      KO: 7105,
      TD: 8857,
      LEWICA: 11619,
    },
  },
  "Nowy Sącz": {
    procentyWOkreguSrednia: {
      PIS: 57.15711998323818,
      KO: 14.745963399365438,
      TD: 9.762714480764634,
      KONF: 12.756987785241341,
      LEWICA: 5.577214351390401,
    },
    odchylenieWOkregu: {
      PIS: 4.770328943693959,
      KO: 4.734191375458255,
      TD: 2.013774734692939,
      LEWICA: 1.0531250128930232,
      KONF: 3.31715070294445,
    },
    wykresRozkladu: {
      "47.7%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 1,
      },
      "28.1%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "9.5%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 0,
      },
      "6.1%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 0,
      },
      "5.1%": {
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 1,
      },
      "0.8%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "0.6%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 0,
      },
      "0.5%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.4%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 0,
      },
      "0.3%": {
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 0,
      },
      "0.1%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "57.6%": {
        DEMO: 2,
        NIEDEMO: 8,
      },
      "29.8%": {
        DEMO: 3,
        NIEDEMO: 7,
      },
      "6.1%": {
        DEMO: 1,
        NIEDEMO: 9,
      },
    },
    histogram: {
      PIS: {
        "46": {
          "5": 58,
          "6": 8,
        },
        "47": {
          "5": 87,
          "6": 60,
        },
        "48": {
          "5": 91,
          "6": 189,
        },
        "49": {
          "5": 97,
          "6": 370,
        },
        "50": {
          "5": 69,
          "6": 712,
        },
        "51": {
          "5": 22,
          "6": 1217,
          "7": 7,
        },
        "52": {
          "5": 2,
          "6": 1808,
          "7": 52,
        },
        "53": {
          "6": 2151,
          "7": 200,
        },
        "54": {
          "6": 2358,
          "7": 602,
        },
        "55": {
          "6": 2102,
          "7": 1332,
        },
        "56": {
          "6": 1559,
          "7": 2248,
        },
        "57": {
          "6": 1023,
          "7": 2968,
        },
        "58": {
          "6": 436,
          "7": 3380,
          "8": 4,
        },
        "59": {
          "6": 146,
          "7": 3290,
          "8": 55,
        },
        "60": {
          "6": 19,
          "7": 2772,
          "8": 160,
        },
        "61": {
          "6": 5,
          "7": 2088,
          "8": 332,
        },
        "62": {
          "6": 1,
          "7": 1445,
          "8": 497,
        },
        "63": {
          "7": 794,
          "8": 555,
        },
        "64": {
          "7": 428,
          "8": 523,
        },
        "65": {
          "7": 194,
          "8": 469,
        },
        "66": {
          "7": 78,
          "8": 333,
          "9": 3,
        },
        "67": {
          "7": 32,
          "8": 253,
          "9": 10,
        },
        "68": {
          "7": 8,
          "8": 150,
          "9": 12,
        },
        "69": {
          "7": 1,
          "8": 81,
          "9": 18,
        },
        "70": {
          "8": 55,
          "9": 20,
        },
        "71": {
          "8": 17,
          "9": 14,
        },
        "63.2": {
          "7": 737,
          "8": 558,
        },
        "60.2": {
          "6": 20,
          "7": 2640,
          "8": 188,
        },
        "53.4": {
          "6": 2241,
          "7": 368,
        },
        "57.6": {
          "6": 658,
          "7": 3162,
        },
        "56.4": {
          "6": 1391,
          "7": 2584,
        },
        "58.4": {
          "6": 346,
          "7": 3227,
          "8": 20,
        },
        "57.4": {
          "6": 726,
          "7": 3095,
        },
        "61.6": {
          "7": 1636,
          "8": 403,
        },
        "53.2": {
          "6": 2203,
          "7": 272,
        },
        "63.4": {
          "6": 1,
          "7": 709,
          "8": 559,
        },
        "62.2": {
          "6": 1,
          "7": 1296,
          "8": 443,
        },
        "62.6": {
          "7": 1031,
          "8": 527,
        },
        "65.4": {
          "7": 126,
          "8": 422,
          "9": 4,
        },
        "56.2": {
          "6": 1489,
          "7": 2447,
        },
        "54.8": {
          "6": 2217,
          "7": 1183,
        },
        "57.8": {
          "6": 546,
          "7": 3197,
          "8": 2,
        },
        "58.6": {
          "6": 246,
          "7": 3329,
          "8": 28,
        },
        "62.8": {
          "7": 890,
          "8": 564,
        },
        "59.4": {
          "6": 79,
          "7": 3086,
          "8": 108,
        },
        "59.2": {
          "6": 94,
          "7": 3193,
          "8": 74,
        },
        "54.2": {
          "6": 2277,
          "7": 726,
        },
        "58.8": {
          "6": 166,
          "7": 3249,
          "8": 40,
        },
        "49.8": {
          "5": 77,
          "6": 669,
        },
        "56.6": {
          "6": 1178,
          "7": 2727,
        },
        "65.6": {
          "7": 117,
          "8": 440,
          "9": 2,
        },
        "54.6": {
          "6": 2229,
          "7": 1021,
        },
        "50.4": {
          "5": 52,
          "6": 895,
        },
        "52.2": {
          "5": 1,
          "6": 1777,
          "7": 74,
        },
        "61.2": {
          "6": 1,
          "7": 1970,
          "8": 379,
        },
        "61.8": {
          "6": 1,
          "7": 1561,
          "8": 462,
        },
        "55.2": {
          "6": 1977,
          "7": 1564,
        },
        "64.4": {
          "7": 322,
          "8": 521,
        },
        "57.2": {
          "6": 820,
          "7": 3174,
        },
        "58.2": {
          "6": 388,
          "7": 3380,
          "8": 7,
        },
        "54.4": {
          "6": 2390,
          "7": 875,
        },
        "60.4": {
          "6": 6,
          "7": 2535,
          "8": 221,
        },
        "59.6": {
          "6": 49,
          "7": 3032,
          "8": 116,
        },
        "62.4": {
          "7": 1171,
          "8": 505,
        },
        "51.8": {
          "5": 4,
          "6": 1636,
          "7": 34,
        },
        "52.6": {
          "5": 1,
          "6": 2041,
          "7": 120,
        },
        "48.2": {
          "5": 124,
          "6": 222,
        },
        "64.2": {
          "7": 386,
          "8": 565,
        },
        "53.8": {
          "6": 2277,
          "7": 514,
        },
        "60.6": {
          "6": 6,
          "7": 2356,
          "8": 259,
        },
        "55.4": {
          "6": 1969,
          "7": 1765,
        },
        "56.8": {
          "6": 1083,
          "7": 2792,
        },
        "66.2": {
          "7": 55,
          "8": 302,
          "9": 3,
        },
        "65.2": {
          "7": 188,
          "8": 465,
          "9": 1,
        },
        "51.2": {
          "5": 7,
          "6": 1310,
          "7": 9,
        },
        "51.4": {
          "5": 8,
          "6": 1364,
          "7": 12,
        },
        "52.4": {
          "5": 2,
          "6": 1918,
          "7": 105,
        },
        "60.8": {
          "6": 5,
          "7": 2271,
          "8": 279,
        },
        "55.8": {
          "6": 1647,
          "7": 2062,
        },
        "53.6": {
          "6": 2292,
          "7": 424,
        },
        "55.6": {
          "6": 1815,
          "7": 1827,
        },
        "59.8": {
          "6": 33,
          "7": 3035,
          "8": 155,
        },
        "63.6": {
          "7": 571,
          "8": 572,
        },
        "52.8": {
          "6": 2050,
          "7": 169,
        },
        "49.6": {
          "5": 89,
          "6": 525,
        },
        "50.2": {
          "5": 53,
          "6": 807,
        },
        "50.6": {
          "5": 35,
          "6": 1035,
        },
        "64.8": {
          "7": 238,
          "8": 524,
        },
        "64.6": {
          "7": 273,
          "8": 522,
        },
        "47.6": {
          "5": 107,
          "6": 95,
        },
        "49.4": {
          "5": 98,
          "6": 547,
        },
        "63.8": {
          "7": 481,
          "8": 514,
        },
        "48.8": {
          "5": 113,
          "6": 324,
        },
        "61.4": {
          "6": 2,
          "7": 1746,
          "8": 360,
        },
        "49.2": {
          "5": 102,
          "6": 437,
        },
        "69.6": {
          "8": 49,
          "9": 23,
        },
        "50.8": {
          "5": 26,
          "6": 1122,
          "7": 1,
        },
        "48.4": {
          "5": 113,
          "6": 257,
        },
        "66.8": {
          "7": 33,
          "8": 273,
          "9": 5,
        },
        "51.6": {
          "5": 6,
          "6": 1515,
          "7": 19,
        },
        "68.2": {
          "7": 5,
          "8": 130,
          "9": 16,
        },
        "46.8": {
          "5": 84,
          "6": 43,
        },
        "46.2": {
          "5": 49,
          "6": 11,
        },
        "47.8": {
          "5": 103,
          "6": 123,
        },
        "45.6": {
          "5": 42,
          "6": 2,
        },
        "45.2": {
          "4": 1,
          "5": 184,
          "6": 8,
        },
        "71.4": {
          "8": 80,
          "9": 126,
          "10": 5,
        },
        "48.6": {
          "5": 112,
          "6": 258,
        },
        "67.2": {
          "7": 15,
          "8": 242,
          "9": 13,
        },
        "66.4": {
          "7": 58,
          "8": 297,
          "9": 8,
        },
        "70.4": {
          "8": 31,
          "9": 12,
        },
        "67.8": {
          "7": 5,
          "8": 166,
          "9": 15,
        },
        "70.6": {
          "8": 25,
          "9": 20,
        },
        "67.4": {
          "7": 19,
          "8": 222,
          "9": 22,
        },
        "47.4": {
          "5": 98,
          "6": 79,
        },
        "68.8": {
          "7": 2,
          "8": 116,
          "9": 15,
        },
        "66.6": {
          "7": 43,
          "8": 286,
          "9": 9,
        },
        "47.2": {
          "5": 88,
          "6": 70,
        },
        "46.6": {
          "5": 64,
          "6": 23,
        },
        "70.8": {
          "8": 25,
          "9": 19,
        },
        "69.8": {
          "8": 37,
          "9": 14,
        },
        "65.8": {
          "7": 82,
          "8": 367,
          "9": 3,
        },
        "67.6": {
          "7": 9,
          "8": 172,
          "9": 6,
        },
        "68.6": {
          "7": 7,
          "8": 118,
          "9": 18,
        },
        "69.2": {
          "8": 72,
          "9": 15,
        },
        "46.4": {
          "5": 51,
          "6": 22,
        },
        "68.4": {
          "7": 3,
          "8": 119,
          "9": 7,
        },
        "45.8": {
          "5": 45,
          "6": 7,
        },
        "70.2": {
          "8": 31,
          "9": 12,
        },
        "69.4": {
          "8": 62,
          "9": 15,
        },
        "45.4": {
          "5": 34,
          "6": 4,
        },
        "71.2": {
          "8": 12,
          "9": 8,
        },
      },
      KO: {
        "3": {
          "0": 1144,
        },
        "4": {
          "0": 164,
        },
        "5": {
          "0": 295,
        },
        "6": {
          "0": 451,
        },
        "7": {
          "0": 712,
        },
        "8": {
          "0": 395,
          "1": 665,
        },
        "9": {
          "1": 1423,
        },
        "10": {
          "1": 1979,
        },
        "11": {
          "1": 2503,
        },
        "12": {
          "1": 2945,
        },
        "13": {
          "1": 3513,
        },
        "14": {
          "1": 3850,
        },
        "15": {
          "1": 3641,
          "2": 213,
        },
        "16": {
          "1": 1582,
          "2": 2114,
        },
        "17": {
          "1": 68,
          "2": 3337,
        },
        "18": {
          "2": 2971,
        },
        "19": {
          "2": 2408,
        },
        "20": {
          "2": 1771,
        },
        "21": {
          "2": 1126,
        },
        "22": {
          "2": 776,
          "3": 7,
        },
        "23": {
          "2": 404,
          "3": 72,
        },
        "24": {
          "2": 125,
          "3": 132,
        },
        "25": {
          "2": 23,
          "3": 126,
        },
        "26": {
          "3": 64,
        },
        "27": {
          "3": 20,
        },
        "28": {
          "3": 15,
        },
        "29": {
          "3": 14,
          "4": 4,
        },
        "17.6": {
          "2": 3086,
        },
        "20.6": {
          "2": 1461,
        },
        "10.8": {
          "1": 2283,
        },
        "16.4": {
          "1": 750,
          "2": 2894,
        },
        "12.8": {
          "1": 3323,
        },
        "16.6": {
          "1": 391,
          "2": 3199,
        },
        "19.4": {
          "2": 2133,
        },
        "9.4": {
          "1": 1628,
        },
        "7.2": {
          "0": 790,
          "1": 1,
        },
        "14.8": {
          "1": 3819,
          "2": 79,
        },
        "13.6": {
          "1": 3508,
        },
        "15.8": {
          "1": 2133,
          "2": 1683,
        },
        "12.2": {
          "1": 3036,
        },
        "18.8": {
          "2": 2477,
        },
        "12.6": {
          "1": 3252,
        },
        "15.2": {
          "1": 3444,
          "2": 402,
        },
        "8.2": {
          "0": 170,
          "1": 939,
        },
        "16.2": {
          "1": 1127,
          "2": 2540,
        },
        "15.4": {
          "1": 3117,
          "2": 740,
        },
        "12.4": {
          "1": 3171,
        },
        "18.2": {
          "2": 2884,
        },
        "23.6": {
          "2": 219,
          "3": 115,
        },
        "11.2": {
          "1": 2504,
        },
        "7.4": {
          "0": 826,
          "1": 19,
        },
        "21.6": {
          "2": 936,
        },
        "14.2": {
          "1": 3787,
        },
        "19.2": {
          "2": 2279,
        },
        "18.4": {
          "2": 2634,
        },
        "22.6": {
          "2": 487,
          "3": 34,
        },
        "13.8": {
          "1": 3757,
        },
        "8.6": {
          "0": 7,
          "1": 1301,
        },
        "16.8": {
          "1": 210,
          "2": 3257,
        },
        "4.6": {
          "0": 243,
        },
        "13.2": {
          "1": 3506,
        },
        "15.6": {
          "1": 2616,
          "2": 1041,
        },
        "17.2": {
          "1": 19,
          "2": 3194,
        },
        "13.4": {
          "1": 3603,
        },
        "9.6": {
          "1": 1801,
        },
        "17.4": {
          "1": 2,
          "2": 3193,
        },
        "6.6": {
          "0": 635,
        },
        "18.6": {
          "2": 2526,
        },
        "7.6": {
          "0": 783,
          "1": 144,
        },
        "10.4": {
          "1": 2221,
        },
        "14.6": {
          "1": 3814,
          "2": 17,
        },
        "22.8": {
          "2": 475,
          "3": 58,
        },
        "10.2": {
          "1": 1973,
        },
        "23.2": {
          "2": 284,
          "3": 94,
        },
        "6.8": {
          "0": 681,
        },
        "11.8": {
          "1": 2921,
        },
        "24.2": {
          "2": 92,
          "3": 121,
        },
        "14.4": {
          "1": 3802,
        },
        "11.4": {
          "1": 2699,
        },
        "9.8": {
          "1": 1895,
        },
        "11.6": {
          "1": 2821,
        },
        "17.8": {
          "2": 3105,
        },
        "19.6": {
          "2": 1959,
        },
        "6.4": {
          "0": 549,
        },
        "27.2": {
          "3": 23,
        },
        "21.8": {
          "2": 827,
          "3": 3,
        },
        "19.8": {
          "2": 1906,
        },
        "5.4": {
          "0": 344,
        },
        "6.2": {
          "0": 501,
        },
        "20.4": {
          "2": 1583,
        },
        "20.8": {
          "2": 1307,
        },
        "4.4": {
          "0": 219,
        },
        "10.6": {
          "1": 2256,
        },
        "9.2": {
          "1": 1592,
        },
        "8.8": {
          "0": 1,
          "1": 1366,
        },
        "20.2": {
          "2": 1584,
        },
        "21.2": {
          "2": 1126,
        },
        "25.4": {
          "2": 3,
          "3": 114,
        },
        "22.2": {
          "2": 700,
          "3": 9,
        },
        "21.4": {
          "2": 997,
        },
        "5.2": {
          "0": 339,
        },
        "5.6": {
          "0": 397,
        },
        "22.4": {
          "2": 628,
          "3": 23,
        },
        "24.8": {
          "2": 23,
          "3": 117,
        },
        "8.4": {
          "0": 41,
          "1": 1145,
        },
        "7.8": {
          "0": 616,
          "1": 356,
        },
        "4.2": {
          "0": 232,
        },
        "4.8": {
          "0": 289,
        },
        "3.8": {
          "0": 158,
        },
        "23.4": {
          "2": 270,
          "3": 117,
        },
        "23.8": {
          "2": 160,
          "3": 116,
        },
        "5.8": {
          "0": 448,
        },
        "3.2": {
          "0": 128,
        },
        "24.6": {
          "2": 39,
          "3": 119,
        },
        "25.8": {
          "2": 1,
          "3": 80,
        },
        "24.4": {
          "2": 89,
          "3": 128,
        },
        "25.6": {
          "2": 2,
          "3": 70,
        },
        "3.4": {
          "0": 127,
        },
        "25.2": {
          "2": 4,
          "3": 105,
        },
        "3.6": {
          "0": 160,
        },
        "26.2": {
          "3": 57,
        },
        "26.4": {
          "3": 48,
        },
        "26.6": {
          "3": 28,
        },
        "26.8": {
          "3": 39,
        },
        "28.2": {
          "3": 13,
        },
        "27.6": {
          "3": 19,
        },
        "27.4": {
          "3": 13,
        },
        "27.8": {
          "3": 14,
        },
        "28.6": {
          "3": 4,
        },
        "28.8": {
          "3": 4,
        },
        "28.4": {
          "3": 2,
        },
      },
      TD: {
        "5": {
          "0": 436,
        },
        "6": {
          "0": 1308,
        },
        "7": {
          "0": 2979,
        },
        "8": {
          "0": 1861,
          "1": 3612,
        },
        "9": {
          "1": 7504,
        },
        "10": {
          "1": 8031,
        },
        "11": {
          "1": 6465,
        },
        "12": {
          "1": 4269,
        },
        "13": {
          "1": 2129,
        },
        "14": {
          "1": 898,
        },
        "15": {
          "1": 240,
          "2": 27,
        },
        "7.4": {
          "0": 3754,
          "1": 152,
        },
        "6.8": {
          "0": 2539,
        },
        "11.6": {
          "1": 5317,
        },
        "8.8": {
          "0": 3,
          "1": 7144,
        },
        "9.8": {
          "1": 7952,
        },
        "8.6": {
          "0": 26,
          "1": 6769,
        },
        "12.4": {
          "1": 3404,
        },
        "9.2": {
          "1": 7781,
        },
        "13.4": {
          "1": 1443,
        },
        "12.6": {
          "1": 2901,
        },
        "7.8": {
          "0": 2964,
          "1": 2070,
        },
        "9.6": {
          "1": 8235,
        },
        "12.2": {
          "1": 3726,
        },
        "13.6": {
          "1": 1193,
        },
        "8.4": {
          "0": 246,
          "1": 6216,
        },
        "11.8": {
          "1": 4514,
        },
        "10.4": {
          "1": 7575,
        },
        "11.4": {
          "1": 5669,
        },
        "7.2": {
          "0": 3549,
          "1": 4,
        },
        "8.2": {
          "0": 843,
          "1": 5254,
        },
        "10.2": {
          "1": 7858,
        },
        "9.4": {
          "1": 7955,
        },
        "10.6": {
          "1": 7343,
        },
        "7.6": {
          "0": 3614,
          "1": 841,
        },
        "5.8": {
          "0": 1033,
        },
        "11.2": {
          "1": 6114,
        },
        "6.2": {
          "0": 1537,
        },
        "6.6": {
          "0": 2298,
        },
        "10.8": {
          "1": 7029,
        },
        "12.8": {
          "1": 2524,
        },
        "13.8": {
          "1": 1039,
        },
        "15.8": {
          "1": 116,
          "2": 318,
        },
        "5.2": {
          "0": 538,
        },
        "4.8": {
          "0": 1289,
        },
        "14.4": {
          "1": 565,
        },
        "5.6": {
          "0": 831,
        },
        "13.2": {
          "1": 1674,
        },
        "15.4": {
          "1": 110,
          "2": 49,
        },
        "14.2": {
          "1": 693,
        },
        "15.6": {
          "1": 78,
          "2": 40,
        },
        "6.4": {
          "0": 1834,
        },
        "15.2": {
          "1": 173,
          "2": 45,
        },
        "14.8": {
          "1": 352,
          "2": 9,
        },
        "14.6": {
          "1": 428,
          "2": 2,
        },
        "5.4": {
          "0": 669,
        },
      },
      KONF: {
        "5": {
          "0": 222,
        },
        "6": {
          "0": 454,
        },
        "7": {
          "0": 904,
        },
        "8": {
          "0": 622,
          "1": 870,
        },
        "9": {
          "1": 2428,
        },
        "10": {
          "1": 3378,
        },
        "11": {
          "1": 4358,
        },
        "12": {
          "1": 5002,
        },
        "13": {
          "1": 5269,
        },
        "14": {
          "1": 4866,
        },
        "15": {
          "1": 3953,
          "2": 211,
        },
        "16": {
          "1": 1313,
          "2": 1710,
        },
        "17": {
          "1": 54,
          "2": 1885,
        },
        "18": {
          "2": 1110,
        },
        "19": {
          "2": 623,
        },
        "20": {
          "2": 292,
        },
        "21": {
          "2": 123,
        },
        "22": {
          "2": 61,
        },
        "14.8": {
          "1": 4129,
          "2": 76,
        },
        "13.6": {
          "1": 5107,
        },
        "10.2": {
          "1": 3651,
        },
        "12.4": {
          "1": 5332,
        },
        "8.6": {
          "0": 13,
          "1": 2034,
        },
        "13.4": {
          "1": 5123,
        },
        "9.8": {
          "1": 3218,
        },
        "9.6": {
          "1": 2894,
        },
        "10.6": {
          "1": 4106,
        },
        "12.8": {
          "1": 5312,
        },
        "6.8": {
          "0": 800,
        },
        "14.2": {
          "1": 4783,
        },
        "11.2": {
          "1": 4502,
        },
        "19.2": {
          "2": 545,
        },
        "4.8": {
          "0": 224,
        },
        "9.2": {
          "1": 2650,
        },
        "11.8": {
          "1": 4965,
        },
        "10.4": {
          "1": 3779,
        },
        "11.6": {
          "1": 4890,
        },
        "16.8": {
          "1": 129,
          "2": 2052,
        },
        "11.4": {
          "1": 4673,
        },
        "17.2": {
          "1": 10,
          "2": 1685,
        },
        "12.2": {
          "1": 5123,
        },
        "12.6": {
          "1": 5321,
        },
        "7.2": {
          "0": 979,
          "1": 3,
        },
        "17.4": {
          "1": 1,
          "2": 1566,
        },
        "14.6": {
          "1": 4297,
          "2": 20,
        },
        "15.4": {
          "1": 2932,
          "2": 745,
        },
        "5.6": {
          "0": 339,
        },
        "19.4": {
          "2": 451,
        },
        "8.8": {
          "0": 1,
          "1": 2222,
        },
        "8.4": {
          "0": 106,
          "1": 1786,
        },
        "15.2": {
          "1": 3403,
          "2": 402,
        },
        "14.4": {
          "1": 4610,
          "2": 3,
        },
        "9.4": {
          "1": 2876,
        },
        "18.2": {
          "2": 1043,
        },
        "15.6": {
          "1": 2440,
          "2": 1052,
        },
        "22.8": {
          "2": 66,
          "3": 27,
        },
        "13.8": {
          "1": 4878,
        },
        "10.8": {
          "1": 4290,
        },
        "7.8": {
          "0": 910,
          "1": 459,
        },
        "15.8": {
          "1": 1885,
          "2": 1370,
        },
        "13.2": {
          "1": 5205,
        },
        "21.6": {
          "2": 69,
        },
        "18.8": {
          "2": 737,
        },
        "6.2": {
          "0": 567,
        },
        "5.8": {
          "0": 405,
        },
        "16.4": {
          "1": 554,
          "2": 2052,
        },
        "16.6": {
          "1": 302,
          "2": 2083,
        },
        "18.4": {
          "2": 911,
        },
        "16.2": {
          "1": 859,
          "2": 1850,
        },
        "20.4": {
          "2": 205,
        },
        "8.2": {
          "0": 338,
          "1": 1364,
        },
        "7.4": {
          "0": 1077,
          "1": 24,
        },
        "4.6": {
          "0": 165,
        },
        "7.6": {
          "0": 1118,
          "1": 167,
        },
        "19.6": {
          "2": 375,
        },
        "17.6": {
          "2": 1504,
        },
        "20.6": {
          "2": 184,
        },
        "4.4": {
          "0": 925,
        },
        "18.6": {
          "2": 732,
        },
        "6.4": {
          "0": 573,
        },
        "17.8": {
          "2": 1332,
        },
        "5.2": {
          "0": 256,
        },
        "21.8": {
          "2": 58,
          "3": 1,
        },
        "19.8": {
          "2": 343,
        },
        "20.8": {
          "2": 143,
        },
        "22.6": {
          "2": 22,
        },
        "21.2": {
          "2": 115,
        },
        "6.6": {
          "0": 700,
        },
        "20.2": {
          "2": 236,
        },
        "5.4": {
          "0": 303,
        },
        "22.4": {
          "2": 20,
          "3": 1,
        },
        "21.4": {
          "2": 92,
        },
        "22.2": {
          "2": 36,
          "3": 1,
        },
      },
      LEWICA: {
        "3": {
          "0": 1793,
        },
        "4": {
          "0": 5067,
        },
        "5": {
          "0": 12846,
        },
        "6": {
          "0": 13351,
        },
        "7": {
          "0": 6189,
        },
        "8": {
          "0": 787,
          "1": 583,
        },
        "5.2": {
          "0": 13993,
        },
        "5.8": {
          "0": 14491,
        },
        "6.8": {
          "0": 7613,
        },
        "5.6": {
          "0": 14718,
        },
        "4.6": {
          "0": 10026,
        },
        "5.4": {
          "0": 14795,
        },
        "4.2": {
          "0": 6596,
        },
        "7.2": {
          "0": 4810,
          "1": 5,
        },
        "3.8": {
          "0": 3759,
        },
        "8.4": {
          "0": 128,
          "1": 596,
        },
        "6.2": {
          "0": 12167,
        },
        "6.4": {
          "0": 10600,
        },
        "8.2": {
          "0": 330,
          "1": 608,
        },
        "3.6": {
          "0": 2609,
        },
        "7.4": {
          "0": 3590,
          "1": 58,
        },
        "4.4": {
          "0": 8221,
        },
        "4.8": {
          "0": 11618,
        },
        "3.4": {
          "0": 1882,
        },
        "6.6": {
          "0": 8992,
        },
        "7.8": {
          "0": 1570,
          "1": 413,
        },
        "7.6": {
          "0": 2500,
          "1": 226,
        },
        "3.2": {
          "0": 1226,
        },
        "8.8": {
          "0": 5,
          "1": 774,
        },
        "8.6": {
          "0": 33,
          "1": 432,
        },
      },
    },
    minimumMandatow: {
      KO: 0,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3326,
      TD: 5379,
      LEWICA: 252,
    },
    potencjalneMandaty: {
      KO: 37640,
      TD: 29708,
      LEWICA: 35204,
    },
    ostatnieMandaty: {
      KO: 31263,
      TD: 22158,
      LEWICA: 2781,
    },
    dawcyGlosow: {
      KO: 8423,
      TD: 14245,
      LEWICA: 387,
    },
  },
  Tarnów: {
    procentyWOkreguSrednia: {
      PIS: 52.31049800497292,
      KO: 14.673862590499304,
      TD: 15.79027536778375,
      KONF: 12.122207001744968,
      LEWICA: 5.103157034999052,
    },
    odchylenieWOkregu: {
      PIS: 2.965868410260766,
      KO: 4.180607152628795,
      TD: 2.013774734692939,
      LEWICA: 1.0789792053947542,
      KONF: 2.882881337478319,
    },
    wykresRozkladu: {
      "51.2%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "23.8%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "18.1%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 2,
      },
      "3.8%": {
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "1.9%": {
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 1,
      },
      "1%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "51.2%": {
        DEMO: 2,
        NIEDEMO: 7,
      },
      "42%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
      "3.8%": {
        DEMO: null,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "45": {
          "4": 21,
          "5": 260,
        },
        "46": {
          "4": 14,
          "5": 638,
        },
        "47": {
          "4": 4,
          "5": 1236,
          "6": 2,
        },
        "48": {
          "5": 1978,
          "6": 55,
        },
        "49": {
          "5": 2635,
          "6": 363,
        },
        "50": {
          "5": 2884,
          "6": 1116,
        },
        "51": {
          "5": 2488,
          "6": 2177,
        },
        "52": {
          "5": 1645,
          "6": 3264,
        },
        "53": {
          "5": 919,
          "6": 3947,
        },
        "54": {
          "5": 395,
          "6": 3799,
        },
        "55": {
          "5": 119,
          "6": 3232,
          "7": 6,
        },
        "56": {
          "5": 29,
          "6": 2384,
          "7": 53,
        },
        "57": {
          "5": 6,
          "6": 1612,
          "7": 128,
        },
        "58": {
          "5": 1,
          "6": 920,
          "7": 208,
        },
        "59": {
          "6": 452,
          "7": 233,
        },
        "60": {
          "6": 208,
          "7": 172,
        },
        "61": {
          "6": 101,
          "7": 118,
        },
        "54.8": {
          "5": 174,
          "6": 3364,
          "7": 2,
        },
        "55.8": {
          "5": 49,
          "6": 2621,
          "7": 41,
        },
        "53.6": {
          "5": 552,
          "6": 3881,
        },
        "55.4": {
          "5": 85,
          "6": 2822,
          "7": 17,
        },
        "49.2": {
          "5": 2778,
          "6": 462,
        },
        "50.8": {
          "5": 2651,
          "6": 1973,
        },
        "52.2": {
          "5": 1472,
          "6": 3389,
        },
        "48.6": {
          "5": 2341,
          "6": 175,
        },
        "50.6": {
          "5": 2708,
          "6": 1738,
        },
        "47.6": {
          "4": 3,
          "5": 1638,
          "6": 18,
        },
        "58.4": {
          "6": 684,
          "7": 189,
        },
        "52.6": {
          "5": 1199,
          "6": 3771,
        },
        "52.8": {
          "5": 1014,
          "6": 3711,
        },
        "47.2": {
          "5": 1302,
          "6": 6,
        },
        "49.6": {
          "5": 2740,
          "6": 718,
        },
        "49.4": {
          "5": 2816,
          "6": 626,
        },
        "54.4": {
          "5": 265,
          "6": 3483,
        },
        "53.8": {
          "5": 450,
          "6": 3858,
        },
        "48.4": {
          "5": 2243,
          "6": 141,
        },
        "53.4": {
          "5": 668,
          "6": 3852,
        },
        "58.8": {
          "6": 570,
          "7": 221,
        },
        "51.6": {
          "5": 2061,
          "6": 2945,
        },
        "54.6": {
          "5": 215,
          "6": 3456,
          "7": 2,
        },
        "48.2": {
          "5": 2199,
          "6": 92,
        },
        "52.4": {
          "5": 1273,
          "6": 3497,
        },
        "57.8": {
          "6": 1095,
          "7": 169,
        },
        "51.4": {
          "5": 2131,
          "6": 2674,
        },
        "56.2": {
          "5": 16,
          "6": 2234,
          "7": 60,
        },
        "51.8": {
          "5": 1817,
          "6": 3057,
        },
        "50.2": {
          "5": 2932,
          "6": 1301,
        },
        "49.8": {
          "5": 2823,
          "6": 921,
        },
        "46.8": {
          "4": 6,
          "5": 1087,
          "6": 1,
        },
        "53.2": {
          "5": 769,
          "6": 3855,
        },
        "47.4": {
          "4": 1,
          "5": 1514,
          "6": 6,
        },
        "57.2": {
          "6": 1385,
          "7": 120,
        },
        "57.4": {
          "5": 1,
          "6": 1302,
          "7": 154,
        },
        "59.2": {
          "6": 417,
          "7": 187,
        },
        "51.2": {
          "5": 2352,
          "6": 2439,
        },
        "54.2": {
          "5": 296,
          "6": 3693,
        },
        "48.8": {
          "5": 2588,
          "6": 256,
        },
        "46.4": {
          "4": 5,
          "5": 839,
        },
        "45.8": {
          "4": 12,
          "5": 540,
        },
        "55.2": {
          "5": 113,
          "6": 3105,
          "7": 12,
        },
        "59.4": {
          "6": 348,
          "7": 198,
        },
        "61.2": {
          "6": 282,
          "7": 1165,
          "8": 14,
        },
        "55.6": {
          "5": 59,
          "6": 2785,
          "7": 25,
        },
        "58.6": {
          "6": 631,
          "7": 207,
        },
        "56.4": {
          "5": 12,
          "6": 2067,
          "7": 95,
        },
        "46.6": {
          "4": 7,
          "5": 884,
        },
        "56.8": {
          "5": 6,
          "6": 1772,
          "7": 131,
        },
        "60.4": {
          "6": 145,
          "7": 198,
        },
        "58.2": {
          "6": 819,
          "7": 167,
        },
        "60.2": {
          "6": 193,
          "7": 173,
        },
        "59.6": {
          "6": 287,
          "7": 180,
        },
        "50.4": {
          "5": 2796,
          "6": 1451,
        },
        "44.8": {
          "4": 264,
          "5": 1069,
        },
        "57.6": {
          "6": 1187,
          "7": 182,
        },
        "47.8": {
          "5": 1838,
          "6": 33,
        },
        "46.2": {
          "4": 8,
          "5": 756,
        },
        "56.6": {
          "5": 9,
          "6": 1916,
          "7": 107,
        },
        "60.8": {
          "6": 102,
          "7": 161,
        },
        "45.2": {
          "4": 24,
          "5": 296,
        },
        "45.6": {
          "4": 28,
          "5": 489,
        },
        "60.6": {
          "6": 91,
          "7": 155,
        },
        "45.4": {
          "4": 21,
          "5": 413,
        },
        "59.8": {
          "6": 257,
          "7": 169,
        },
      },
      KO: {
        "5": {
          "0": 215,
        },
        "6": {
          "0": 314,
        },
        "7": {
          "0": 536,
        },
        "8": {
          "0": 834,
          "1": 35,
        },
        "9": {
          "0": 169,
          "1": 1221,
        },
        "10": {
          "1": 1842,
        },
        "11": {
          "1": 2507,
        },
        "12": {
          "1": 3233,
        },
        "13": {
          "1": 3851,
        },
        "14": {
          "1": 4377,
        },
        "15": {
          "1": 4324,
        },
        "16": {
          "1": 4118,
          "2": 78,
        },
        "17": {
          "1": 2003,
          "2": 1680,
        },
        "18": {
          "1": 204,
          "2": 2788,
        },
        "19": {
          "2": 2153,
        },
        "20": {
          "2": 1469,
        },
        "21": {
          "2": 879,
        },
        "22": {
          "2": 478,
        },
        "23": {
          "2": 256,
        },
        "24": {
          "2": 129,
        },
        "25": {
          "2": 33,
          "3": 9,
        },
        "26": {
          "2": 8,
          "3": 6,
        },
        "27": {
          "2": 4,
          "3": 5,
        },
        "11.2": {
          "1": 2695,
        },
        "20.4": {
          "2": 1289,
        },
        "13.8": {
          "1": 4125,
        },
        "19.2": {
          "2": 2064,
        },
        "15.6": {
          "1": 4322,
          "2": 1,
        },
        "16.6": {
          "1": 3073,
          "2": 748,
        },
        "13.2": {
          "1": 4082,
        },
        "21.6": {
          "2": 717,
        },
        "17.6": {
          "1": 639,
          "2": 2589,
        },
        "14.4": {
          "1": 4356,
        },
        "18.8": {
          "1": 11,
          "2": 2278,
        },
        "15.2": {
          "1": 4315,
        },
        "10.4": {
          "1": 2297,
        },
        "11.4": {
          "1": 2854,
        },
        "17.2": {
          "1": 1526,
          "2": 1987,
        },
        "15.8": {
          "1": 4267,
          "2": 19,
        },
        "15.4": {
          "1": 4361,
        },
        "14.6": {
          "1": 4436,
        },
        "17.8": {
          "1": 388,
          "2": 2785,
        },
        "20.6": {
          "2": 1063,
        },
        "10.6": {
          "1": 2281,
        },
        "16.2": {
          "1": 3852,
          "2": 202,
        },
        "16.4": {
          "1": 3634,
          "2": 453,
        },
        "9.4": {
          "0": 7,
          "1": 1500,
        },
        "14.8": {
          "1": 4333,
        },
        "11.8": {
          "1": 3058,
        },
        "19.4": {
          "2": 1892,
        },
        "12.4": {
          "1": 3534,
        },
        "12.6": {
          "1": 3581,
        },
        "13.4": {
          "1": 4102,
        },
        "12.2": {
          "1": 3327,
        },
        "22.4": {
          "2": 340,
        },
        "9.6": {
          "0": 1,
          "1": 1674,
        },
        "9.8": {
          "1": 1722,
        },
        "8.2": {
          "0": 806,
          "1": 172,
        },
        "10.8": {
          "1": 2396,
        },
        "19.6": {
          "2": 1690,
        },
        "4.2": {
          "0": 994,
        },
        "18.2": {
          "1": 80,
          "2": 2752,
        },
        "7.4": {
          "0": 652,
        },
        "18.4": {
          "1": 40,
          "2": 2724,
        },
        "19.8": {
          "2": 1604,
        },
        "7.6": {
          "0": 747,
        },
        "17.4": {
          "1": 1031,
          "2": 2442,
        },
        "12.8": {
          "1": 3731,
        },
        "10.2": {
          "1": 1966,
        },
        "21.2": {
          "2": 817,
        },
        "25.2": {
          "2": 24,
          "3": 8,
        },
        "14.2": {
          "1": 4269,
        },
        "22.2": {
          "2": 443,
        },
        "9.2": {
          "0": 68,
          "1": 1330,
        },
        "6.4": {
          "0": 420,
        },
        "4.6": {
          "0": 167,
        },
        "21.4": {
          "2": 680,
        },
        "18.6": {
          "1": 16,
          "2": 2475,
        },
        "8.4": {
          "0": 659,
          "1": 357,
        },
        "11.6": {
          "1": 3025,
        },
        "20.2": {
          "2": 1330,
        },
        "13.6": {
          "1": 4108,
        },
        "7.8": {
          "0": 826,
          "1": 1,
        },
        "8.8": {
          "0": 313,
          "1": 878,
        },
        "20.8": {
          "2": 1008,
        },
        "16.8": {
          "1": 2630,
          "2": 1151,
        },
        "6.6": {
          "0": 474,
        },
        "22.8": {
          "2": 244,
        },
        "5.2": {
          "0": 239,
        },
        "21.8": {
          "2": 559,
        },
        "5.4": {
          "0": 255,
        },
        "4.8": {
          "0": 165,
        },
        "8.6": {
          "0": 527,
          "1": 620,
        },
        "22.6": {
          "2": 328,
        },
        "6.2": {
          "0": 353,
        },
        "4.4": {
          "0": 138,
        },
        "24.4": {
          "2": 86,
          "3": 4,
        },
        "23.6": {
          "2": 146,
          "3": 2,
        },
        "6.8": {
          "0": 485,
        },
        "5.8": {
          "0": 284,
        },
        "23.8": {
          "2": 129,
          "3": 2,
        },
        "7.2": {
          "0": 604,
        },
        "26.8": {
          "2": 4,
          "3": 6,
        },
        "5.6": {
          "0": 289,
        },
        "24.6": {
          "2": 61,
          "3": 6,
        },
        "23.4": {
          "2": 187,
        },
        "23.2": {
          "2": 193,
        },
        "27.2": {
          "2": 1,
          "3": 12,
        },
        "24.2": {
          "2": 83,
          "3": 4,
        },
        "24.8": {
          "2": 43,
          "3": 11,
        },
        "25.8": {
          "2": 11,
          "3": 2,
        },
        "26.4": {
          "2": 7,
          "3": 7,
        },
        "25.6": {
          "2": 14,
          "3": 9,
        },
        "26.2": {
          "2": 8,
          "3": 4,
        },
        "25.4": {
          "2": 16,
          "3": 4,
        },
        "26.6": {
          "2": 3,
          "3": 3,
        },
      },
      TD: {
        "11": {
          "1": 377,
        },
        "12": {
          "1": 1151,
        },
        "13": {
          "1": 2930,
        },
        "14": {
          "1": 5448,
        },
        "15": {
          "1": 7573,
        },
        "16": {
          "1": 8140,
          "2": 105,
        },
        "17": {
          "1": 4545,
          "2": 2239,
        },
        "18": {
          "1": 722,
          "2": 3626,
        },
        "19": {
          "1": 6,
          "2": 2093,
        },
        "20": {
          "2": 904,
        },
        "21": {
          "2": 251,
        },
        "17.8": {
          "1": 1188,
          "2": 3623,
        },
        "11.6": {
          "1": 700,
        },
        "17.4": {
          "1": 2555,
          "2": 3262,
        },
        "16.6": {
          "1": 6505,
          "2": 1014,
        },
        "19.2": {
          "2": 1791,
        },
        "13.8": {
          "1": 4865,
        },
        "14.6": {
          "1": 6945,
        },
        "17.2": {
          "1": 3475,
          "2": 2726,
        },
        "15.6": {
          "1": 8092,
          "2": 3,
        },
        "14.8": {
          "1": 7324,
        },
        "12.2": {
          "1": 1452,
        },
        "15.2": {
          "1": 7825,
        },
        "15.8": {
          "1": 8123,
          "2": 27,
        },
        "19.8": {
          "2": 1020,
        },
        "18.6": {
          "1": 80,
          "2": 2794,
        },
        "16.8": {
          "1": 5410,
          "2": 1569,
        },
        "14.4": {
          "1": 6487,
        },
        "13.6": {
          "1": 4336,
        },
        "15.4": {
          "1": 8094,
        },
        "16.4": {
          "1": 7166,
          "2": 647,
        },
        "13.4": {
          "1": 3831,
        },
        "17.6": {
          "1": 1807,
          "2": 3407,
        },
        "11.4": {
          "1": 611,
        },
        "13.2": {
          "1": 3419,
        },
        "18.4": {
          "1": 189,
          "2": 2958,
        },
        "19.4": {
          "1": 1,
          "2": 1494,
        },
        "14.2": {
          "1": 5910,
        },
        "12.6": {
          "1": 2029,
        },
        "16.2": {
          "1": 7746,
          "2": 294,
        },
        "18.2": {
          "1": 437,
          "2": 3366,
        },
        "18.8": {
          "1": 28,
          "2": 2483,
        },
        "12.4": {
          "1": 1681,
        },
        "20.8": {
          "2": 361,
        },
        "21.8": {
          "2": 386,
          "3": 1,
        },
        "20.2": {
          "2": 692,
        },
        "10.8": {
          "0": 14,
          "1": 1023,
        },
        "19.6": {
          "2": 1249,
        },
        "11.8": {
          "1": 914,
        },
        "12.8": {
          "1": 2472,
        },
        "11.2": {
          "1": 469,
        },
        "21.2": {
          "2": 194,
        },
        "20.4": {
          "2": 595,
        },
        "20.6": {
          "2": 449,
        },
        "21.4": {
          "2": 148,
        },
        "21.6": {
          "2": 134,
        },
      },
      KONF: {
        "5": {
          "0": 1107,
        },
        "6": {
          "0": 438,
        },
        "7": {
          "0": 836,
        },
        "8": {
          "0": 1698,
          "1": 56,
        },
        "9": {
          "0": 256,
          "1": 2719,
        },
        "10": {
          "1": 4230,
        },
        "11": {
          "1": 5394,
        },
        "12": {
          "1": 6123,
        },
        "13": {
          "1": 5956,
        },
        "14": {
          "1": 4814,
        },
        "15": {
          "1": 3469,
        },
        "16": {
          "1": 2030,
          "2": 53,
        },
        "17": {
          "1": 543,
          "2": 459,
        },
        "18": {
          "1": 36,
          "2": 411,
        },
        "19": {
          "2": 172,
        },
        "20": {
          "2": 49,
        },
        "9.6": {
          "1": 3737,
        },
        "7.4": {
          "0": 1238,
        },
        "12.2": {
          "1": 6101,
        },
        "12.4": {
          "1": 5974,
        },
        "10.8": {
          "1": 5204,
        },
        "16.4": {
          "1": 1313,
          "2": 202,
        },
        "19.4": {
          "2": 105,
        },
        "15.6": {
          "1": 2575,
        },
        "14.2": {
          "1": 4482,
        },
        "9.4": {
          "0": 24,
          "1": 3443,
        },
        "13.4": {
          "1": 5248,
        },
        "8.8": {
          "0": 614,
          "1": 2075,
        },
        "14.6": {
          "1": 4013,
        },
        "11.8": {
          "1": 5939,
        },
        "10.6": {
          "1": 5208,
        },
        "10.2": {
          "1": 4585,
        },
        "14.8": {
          "1": 3720,
        },
        "7.8": {
          "0": 1552,
          "1": 1,
        },
        "14.4": {
          "1": 4287,
        },
        "17.8": {
          "1": 76,
          "2": 485,
        },
        "12.8": {
          "1": 5867,
        },
        "11.4": {
          "1": 5840,
        },
        "9.8": {
          "1": 4006,
        },
        "5.4": {
          "0": 278,
        },
        "8.2": {
          "0": 1638,
          "1": 296,
        },
        "13.2": {
          "1": 5625,
        },
        "15.4": {
          "1": 2852,
        },
        "10.4": {
          "1": 4913,
        },
        "13.6": {
          "1": 5188,
        },
        "12.6": {
          "1": 6111,
        },
        "11.6": {
          "1": 5964,
        },
        "15.8": {
          "1": 2269,
          "2": 14,
        },
        "11.2": {
          "1": 5770,
        },
        "13.8": {
          "1": 5116,
        },
        "6.6": {
          "0": 693,
        },
        "8.6": {
          "0": 1155,
          "1": 1319,
        },
        "17.4": {
          "1": 228,
          "2": 548,
        },
        "15.2": {
          "1": 2994,
        },
        "6.8": {
          "0": 804,
        },
        "16.2": {
          "1": 1649,
          "2": 125,
        },
        "18.2": {
          "1": 14,
          "2": 355,
        },
        "16.6": {
          "1": 1071,
          "2": 309,
        },
        "6.2": {
          "0": 501,
        },
        "7.6": {
          "0": 1382,
        },
        "17.6": {
          "1": 126,
          "2": 523,
        },
        "9.2": {
          "0": 97,
          "1": 3147,
        },
        "8.4": {
          "0": 1545,
          "1": 663,
        },
        "18.8": {
          "1": 1,
          "2": 214,
        },
        "19.2": {
          "2": 156,
        },
        "18.4": {
          "1": 3,
          "2": 315,
        },
        "16.8": {
          "1": 780,
          "2": 405,
        },
        "5.2": {
          "0": 227,
        },
        "7.2": {
          "0": 1069,
        },
        "17.2": {
          "1": 334,
          "2": 529,
        },
        "5.8": {
          "0": 360,
        },
        "5.6": {
          "0": 342,
        },
        "6.4": {
          "0": 617,
        },
        "18.6": {
          "1": 6,
          "2": 256,
        },
        "19.6": {
          "2": 89,
        },
        "19.8": {
          "2": 78,
        },
        "20.8": {
          "2": 83,
        },
        "20.4": {
          "2": 36,
        },
        "20.6": {
          "2": 19,
        },
        "20.2": {
          "2": 36,
        },
      },
      LEWICA: {
        "3": {
          "0": 2144,
        },
        "4": {
          "0": 8925,
        },
        "5": {
          "0": 14755,
        },
        "6": {
          "0": 10212,
        },
        "7": {
          "0": 3188,
        },
        "8": {
          "0": 435,
          "1": 7,
        },
        "6.6": {
          "0": 5710,
        },
        "3.8": {
          "0": 6950,
        },
        "6.8": {
          "0": 4364,
        },
        "4.8": {
          "0": 14212,
        },
        "4.4": {
          "0": 12084,
        },
        "4.6": {
          "0": 13470,
        },
        "5.4": {
          "0": 14240,
        },
        "5.2": {
          "0": 14884,
        },
        "5.8": {
          "0": 11963,
        },
        "4.2": {
          "0": 10683,
        },
        "7.2": {
          "0": 2196,
        },
        "5.6": {
          "0": 13108,
        },
        "6.2": {
          "0": 8632,
        },
        "7.6": {
          "0": 1130,
        },
        "3.6": {
          "0": 5520,
        },
        "6.4": {
          "0": 7160,
        },
        "2.8": {
          "0": 1475,
        },
        "2.4": {
          "0": 1483,
        },
        "7.4": {
          "0": 1600,
        },
        "3.4": {
          "0": 4148,
        },
        "3.2": {
          "0": 2959,
        },
        "7.8": {
          "0": 758,
          "1": 1,
        },
        "2.6": {
          "0": 913,
        },
        "8.4": {
          "0": 224,
          "1": 176,
        },
        "8.2": {
          "0": 260,
          "1": 31,
        },
      },
    },
    minimumMandatow: {
      KO: 0,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2752,
      TD: 1456,
      LEWICA: 12,
    },
    potencjalneMandaty: {
      KO: 36572,
      TD: 52888,
      LEWICA: 8547,
    },
    ostatnieMandaty: {
      KO: 34150,
      TD: 34701,
      LEWICA: 186,
    },
    dawcyGlosow: {
      KO: 7168,
      TD: 2940,
      LEWICA: 13,
    },
  },
  Płock: {
    procentyWOkreguSrednia: {
      PIS: 47.30716051026893,
      KO: 19.107495038468787,
      TD: 16.231034535256,
      KONF: 8.890008760781463,
      LEWICA: 8.464301155224815,
    },
    odchylenieWOkregu: {
      PIS: 4.442714604858209,
      KO: 1.9430380740972468,
      TD: 2.013774734692939,
      LEWICA: 1.885474386415114,
      KONF: 0.8007455808345489,
    },
    wykresRozkladu: {
      "35.5%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "26.2%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 2,
      },
      "16.1%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "13.5%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "4.1%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "2.2%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "1.6%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "66.2%": {
        DEMO: 4,
        NIEDEMO: 6,
      },
      "19.9%": {
        DEMO: 3,
        NIEDEMO: 7,
      },
      "13.7%": {
        DEMO: 5,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "37": {
          "4": 24,
        },
        "38": {
          "4": 45,
        },
        "39": {
          "4": 115,
          "5": 6,
        },
        "40": {
          "4": 229,
          "5": 40,
        },
        "41": {
          "4": 383,
          "5": 176,
        },
        "42": {
          "4": 526,
          "5": 608,
        },
        "43": {
          "4": 415,
          "5": 1349,
        },
        "44": {
          "4": 212,
          "5": 2603,
          "6": 1,
        },
        "45": {
          "4": 12,
          "5": 3941,
          "6": 10,
        },
        "46": {
          "5": 4918,
          "6": 110,
        },
        "47": {
          "5": 5044,
          "6": 527,
        },
        "48": {
          "5": 4220,
          "6": 1394,
        },
        "49": {
          "5": 2466,
          "6": 2301,
        },
        "50": {
          "5": 826,
          "6": 2848,
        },
        "51": {
          "5": 155,
          "6": 2224,
          "7": 3,
        },
        "52": {
          "5": 19,
          "6": 1369,
          "7": 11,
        },
        "53": {
          "6": 673,
          "7": 28,
        },
        "54": {
          "6": 259,
          "7": 48,
        },
        "55": {
          "6": 68,
          "7": 31,
        },
        "56": {
          "6": 21,
          "7": 20,
        },
        "57": {
          "6": 7,
          "7": 8,
        },
        "58": {
          "7": 5,
        },
        "59": {
          "7": 1,
        },
        "49.6": {
          "5": 1333,
          "6": 2681,
        },
        "49.8": {
          "5": 1053,
          "6": 2869,
        },
        "46.6": {
          "5": 5058,
          "6": 308,
        },
        "48.6": {
          "5": 3133,
          "6": 2040,
        },
        "51.4": {
          "5": 83,
          "6": 1880,
          "7": 6,
        },
        "47.4": {
          "5": 4777,
          "6": 783,
        },
        "44.6": {
          "4": 61,
          "5": 3360,
          "6": 3,
        },
        "48.8": {
          "5": 2701,
          "6": 2116,
        },
        "45.6": {
          "5": 4615,
          "6": 53,
        },
        "43.2": {
          "4": 409,
          "5": 1570,
        },
        "43.8": {
          "4": 251,
          "5": 2224,
        },
        "50.8": {
          "5": 237,
          "6": 2438,
          "7": 2,
        },
        "48.2": {
          "5": 3718,
          "6": 1543,
        },
        "41.2": {
          "4": 362,
          "5": 236,
        },
        "44.8": {
          "4": 27,
          "5": 3732,
          "6": 8,
        },
        "45.8": {
          "5": 4708,
          "6": 75,
        },
        "54.4": {
          "6": 172,
          "7": 45,
        },
        "46.4": {
          "5": 5030,
          "6": 216,
        },
        "53.8": {
          "6": 330,
          "7": 33,
        },
        "46.8": {
          "5": 5040,
          "6": 420,
        },
        "50.2": {
          "5": 627,
          "6": 2810,
        },
        "45.2": {
          "4": 3,
          "5": 4152,
          "6": 12,
        },
        "47.6": {
          "5": 4656,
          "6": 1031,
        },
        "46.2": {
          "5": 4964,
          "6": 176,
        },
        "51.2": {
          "5": 96,
          "6": 2074,
          "7": 4,
        },
        "40.4": {
          "4": 295,
          "5": 78,
        },
        "49.2": {
          "5": 2045,
          "6": 2524,
        },
        "43.4": {
          "4": 364,
          "5": 1826,
        },
        "47.8": {
          "5": 4343,
          "6": 1150,
        },
        "45.4": {
          "5": 4381,
          "6": 42,
        },
        "47.2": {
          "5": 4999,
          "6": 637,
        },
        "50.6": {
          "5": 344,
          "6": 2577,
        },
        "42.4": {
          "4": 517,
          "5": 862,
        },
        "52.2": {
          "5": 11,
          "6": 1200,
          "7": 15,
        },
        "44.4": {
          "4": 93,
          "5": 3106,
          "6": 2,
        },
        "50.4": {
          "5": 453,
          "6": 2675,
        },
        "48.4": {
          "5": 3440,
          "6": 1800,
        },
        "44.2": {
          "4": 186,
          "5": 2843,
          "6": 2,
        },
        "53.2": {
          "5": 2,
          "6": 594,
          "7": 27,
        },
        "39.2": {
          "4": 124,
          "5": 8,
        },
        "53.6": {
          "6": 427,
          "7": 25,
        },
        "41.8": {
          "4": 471,
          "5": 455,
        },
        "43.6": {
          "4": 303,
          "5": 2031,
        },
        "49.4": {
          "5": 1661,
          "6": 2649,
        },
        "40.2": {
          "4": 248,
          "5": 55,
        },
        "51.8": {
          "5": 35,
          "6": 1636,
          "7": 7,
        },
        "42.2": {
          "4": 496,
          "5": 645,
        },
        "52.8": {
          "6": 849,
          "7": 17,
        },
        "51.6": {
          "5": 48,
          "6": 1786,
          "7": 11,
        },
        "42.8": {
          "4": 457,
          "5": 1142,
        },
        "41.4": {
          "4": 360,
          "5": 296,
        },
        "52.6": {
          "5": 4,
          "6": 852,
          "7": 18,
        },
        "40.8": {
          "4": 331,
          "5": 119,
        },
        "39.6": {
          "4": 169,
          "5": 13,
        },
        "54.6": {
          "6": 135,
          "7": 52,
        },
        "52.4": {
          "5": 5,
          "6": 1054,
          "7": 21,
        },
        "40.6": {
          "4": 315,
          "5": 102,
        },
        "42.6": {
          "4": 474,
          "5": 939,
        },
        "53.4": {
          "6": 504,
          "7": 34,
        },
        "41.6": {
          "4": 437,
          "5": 359,
        },
        "37.4": {
          "4": 32,
        },
        "55.2": {
          "6": 58,
          "7": 29,
        },
        "38.4": {
          "4": 69,
          "5": 1,
        },
        "39.4": {
          "4": 138,
          "5": 14,
        },
        "39.8": {
          "4": 215,
          "5": 20,
        },
        "38.6": {
          "4": 75,
          "5": 3,
        },
        "56.2": {
          "6": 11,
          "7": 14,
        },
        "54.2": {
          "6": 241,
          "7": 47,
        },
        "57.4": {
          "6": 1,
          "7": 8,
        },
        "55.6": {
          "6": 29,
          "7": 36,
        },
        "37.6": {
          "4": 29,
        },
        "57.2": {
          "6": 1,
          "7": 6,
        },
        "36.6": {
          "4": 5,
        },
        "56.4": {
          "6": 7,
          "7": 14,
        },
        "55.8": {
          "6": 20,
          "7": 39,
        },
        "54.8": {
          "6": 125,
          "7": 45,
        },
        "55.4": {
          "6": 51,
          "7": 46,
        },
        "38.8": {
          "4": 96,
          "5": 4,
        },
        "56.8": {
          "6": 2,
          "7": 15,
        },
        "38.2": {
          "4": 45,
          "5": 1,
        },
        "36.8": {
          "4": 17,
        },
        "56.6": {
          "6": 6,
          "7": 11,
        },
        "37.8": {
          "4": 38,
        },
        "58.6": {
          "7": 3,
        },
        "37.2": {
          "4": 19,
        },
        "57.6": {
          "6": 1,
          "7": 4,
        },
        "58.2": {
          "7": 5,
        },
        "36.2": {
          "4": 31,
        },
        "59.4": {
          "7": 1,
        },
        "57.8": {
          "7": 5,
        },
        "58.4": {
          "7": 3,
        },
        "36.4": {
          "4": 6,
        },
        "58.8": {
          "7": 2,
        },
        "60.6": {
          "8": 1,
        },
      },
      KO: {
        "15": {
          "1": 663,
          "2": 2,
        },
        "16": {
          "1": 1539,
          "2": 490,
        },
        "17": {
          "1": 580,
          "2": 3889,
        },
        "18": {
          "1": 13,
          "2": 7376,
        },
        "19": {
          "2": 8358,
        },
        "20": {
          "2": 7400,
        },
        "21": {
          "2": 5140,
        },
        "22": {
          "2": 2608,
          "3": 3,
        },
        "23": {
          "2": 989,
          "3": 53,
        },
        "24": {
          "2": 215,
          "3": 116,
        },
        "25": {
          "2": 46,
          "3": 284,
        },
        "18.8": {
          "2": 8646,
        },
        "18.2": {
          "1": 1,
          "2": 7652,
        },
        "20.6": {
          "2": 6074,
        },
        "19.6": {
          "2": 8190,
        },
        "20.4": {
          "2": 6673,
        },
        "20.8": {
          "2": 5677,
        },
        "21.8": {
          "2": 3060,
        },
        "19.2": {
          "2": 8484,
        },
        "20.2": {
          "2": 7215,
        },
        "17.6": {
          "1": 169,
          "2": 5974,
        },
        "16.4": {
          "1": 1468,
          "2": 1507,
        },
        "21.2": {
          "2": 4453,
        },
        "17.4": {
          "1": 327,
          "2": 5221,
        },
        "18.4": {
          "2": 8049,
        },
        "22.4": {
          "2": 1841,
          "3": 8,
        },
        "14.4": {
          "1": 352,
        },
        "17.2": {
          "1": 444,
          "2": 4648,
        },
        "19.8": {
          "2": 7955,
        },
        "19.4": {
          "2": 8319,
        },
        "18.6": {
          "2": 8267,
        },
        "15.8": {
          "1": 1501,
          "2": 254,
        },
        "17.8": {
          "1": 69,
          "2": 6754,
        },
        "15.6": {
          "1": 1291,
          "2": 122,
        },
        "16.6": {
          "1": 1185,
          "2": 2207,
        },
        "21.4": {
          "2": 4043,
        },
        "14.6": {
          "1": 426,
        },
        "16.2": {
          "1": 1670,
          "2": 816,
        },
        "22.6": {
          "2": 1504,
          "3": 10,
        },
        "15.2": {
          "1": 896,
          "2": 13,
        },
        "14.2": {
          "1": 909,
        },
        "22.2": {
          "2": 2178,
          "3": 7,
        },
        "24.2": {
          "2": 167,
          "3": 111,
        },
        "16.8": {
          "1": 879,
          "2": 3109,
        },
        "23.8": {
          "2": 287,
          "3": 126,
        },
        "23.4": {
          "2": 627,
          "3": 89,
        },
        "22.8": {
          "2": 1228,
          "3": 22,
        },
        "23.2": {
          "2": 796,
          "3": 88,
        },
        "14.8": {
          "1": 572,
          "2": 1,
        },
        "15.4": {
          "1": 1114,
          "2": 47,
        },
        "21.6": {
          "2": 3436,
        },
        "23.6": {
          "2": 428,
          "3": 107,
        },
        "24.6": {
          "2": 54,
          "3": 75,
        },
        "24.4": {
          "2": 106,
          "3": 129,
        },
        "24.8": {
          "2": 37,
          "3": 72,
        },
      },
      TD: {
        "12": {
          "1": 663,
        },
        "13": {
          "1": 1911,
        },
        "14": {
          "1": 4175,
        },
        "15": {
          "1": 6783,
          "2": 58,
        },
        "16": {
          "1": 6207,
          "2": 2079,
        },
        "17": {
          "1": 1744,
          "2": 6047,
        },
        "18": {
          "1": 21,
          "2": 5381,
        },
        "19": {
          "2": 2877,
        },
        "20": {
          "2": 1198,
        },
        "21": {
          "2": 411,
        },
        "22": {
          "2": 100,
        },
        "17.4": {
          "1": 684,
          "2": 6269,
        },
        "18.4": {
          "2": 4321,
        },
        "17.2": {
          "1": 1168,
          "2": 6256,
        },
        "16.4": {
          "1": 4382,
          "2": 4131,
        },
        "14.8": {
          "1": 6343,
          "2": 19,
        },
        "14.6": {
          "1": 5780,
          "2": 4,
        },
        "14.2": {
          "1": 4714,
        },
        "16.8": {
          "1": 2280,
          "2": 5721,
        },
        "12.6": {
          "1": 1330,
        },
        "15.4": {
          "1": 7355,
          "2": 347,
        },
        "16.6": {
          "1": 3064,
          "2": 5155,
        },
        "15.8": {
          "1": 7048,
          "2": 1256,
        },
        "15.6": {
          "1": 7183,
          "2": 747,
        },
        "13.6": {
          "1": 3169,
        },
        "17.6": {
          "1": 339,
          "2": 6022,
        },
        "18.2": {
          "2": 4833,
        },
        "15.2": {
          "1": 7210,
          "2": 154,
        },
        "19.4": {
          "2": 2104,
        },
        "13.2": {
          "1": 2390,
        },
        "17.8": {
          "1": 116,
          "2": 5838,
        },
        "18.8": {
          "2": 3359,
        },
        "21.6": {
          "2": 187,
        },
        "14.4": {
          "1": 5424,
          "2": 1,
        },
        "12.4": {
          "1": 1056,
        },
        "16.2": {
          "1": 5414,
          "2": 3036,
        },
        "11.2": {
          "0": 6,
          "1": 865,
        },
        "13.8": {
          "1": 3699,
        },
        "22.2": {
          "2": 268,
          "3": 7,
        },
        "19.2": {
          "2": 2405,
        },
        "13.4": {
          "1": 2745,
        },
        "20.4": {
          "2": 776,
        },
        "18.6": {
          "2": 3787,
        },
        "20.2": {
          "2": 953,
        },
        "19.6": {
          "2": 1760,
        },
        "11.8": {
          "1": 525,
        },
        "12.8": {
          "1": 1611,
        },
        "11.6": {
          "1": 401,
        },
        "19.8": {
          "2": 1404,
        },
        "12.2": {
          "1": 861,
        },
        "21.8": {
          "2": 113,
        },
        "11.4": {
          "1": 309,
        },
        "20.8": {
          "2": 475,
        },
        "21.2": {
          "2": 289,
        },
        "21.4": {
          "2": 227,
        },
        "20.6": {
          "2": 650,
        },
      },
      KONF: {
        "7": {
          "0": 1535,
        },
        "8": {
          "0": 6940,
          "1": 4323,
        },
        "9": {
          "0": 26,
          "1": 17811,
        },
        "10": {
          "1": 8059,
        },
        "11": {
          "1": 1253,
        },
        "8.2": {
          "0": 4904,
          "1": 8879,
        },
        "9.2": {
          "1": 16866,
        },
        "10.4": {
          "1": 4325,
        },
        "8.4": {
          "0": 2610,
          "1": 13059,
        },
        "8.6": {
          "0": 1184,
          "1": 16267,
        },
        "9.4": {
          "1": 14699,
        },
        "9.8": {
          "1": 10586,
        },
        "10.2": {
          "1": 6028,
        },
        "8.8": {
          "0": 387,
          "1": 17687,
        },
        "7.8": {
          "0": 6976,
          "1": 1527,
        },
        "9.6": {
          "1": 12461,
        },
        "10.6": {
          "1": 2984,
        },
        "10.8": {
          "1": 2011,
        },
        "7.6": {
          "0": 5790,
          "1": 352,
        },
        "7.2": {
          "0": 2655,
        },
        "7.4": {
          "0": 4132,
          "1": 50,
        },
        "6.8": {
          "0": 1706,
        },
        "11.2": {
          "1": 1928,
        },
      },
      LEWICA: {
        "4": {
          "0": 426,
        },
        "5": {
          "0": 1428,
        },
        "6": {
          "0": 3448,
        },
        "7": {
          "0": 6247,
        },
        "8": {
          "0": 6447,
          "1": 2252,
        },
        "9": {
          "0": 26,
          "1": 8339,
        },
        "10": {
          "1": 6271,
        },
        "11": {
          "1": 3279,
        },
        "12": {
          "1": 1263,
        },
        "13": {
          "1": 324,
        },
        "14": {
          "1": 76,
        },
        "5.4": {
          "0": 2113,
        },
        "8.8": {
          "0": 296,
          "1": 8644,
        },
        "7.4": {
          "0": 7378,
          "1": 27,
        },
        "7.6": {
          "0": 7849,
          "1": 146,
        },
        "10.2": {
          "1": 5547,
        },
        "8.4": {
          "0": 2224,
          "1": 6813,
        },
        "5.6": {
          "0": 2471,
        },
        "10.6": {
          "1": 4242,
        },
        "8.6": {
          "0": 1065,
          "1": 7747,
        },
        "6.8": {
          "0": 5691,
        },
        "11.8": {
          "1": 1594,
        },
        "10.4": {
          "1": 4924,
        },
        "12.4": {
          "1": 787,
        },
        "9.2": {
          "1": 8224,
        },
        "9.4": {
          "1": 7747,
        },
        "5.2": {
          "0": 1742,
        },
        "5.8": {
          "0": 3011,
        },
        "8.2": {
          "0": 4264,
          "1": 4596,
        },
        "9.8": {
          "1": 6724,
        },
        "7.2": {
          "0": 6945,
          "1": 1,
        },
        "10.8": {
          "1": 3725,
        },
        "6.4": {
          "0": 4591,
        },
        "6.2": {
          "0": 3964,
        },
        "11.4": {
          "1": 2247,
        },
        "9.6": {
          "1": 7213,
        },
        "7.8": {
          "0": 7640,
          "1": 753,
        },
        "12.6": {
          "1": 579,
        },
        "6.6": {
          "0": 5206,
        },
        "11.2": {
          "1": 2763,
        },
        "12.2": {
          "1": 1009,
        },
        "11.6": {
          "1": 1883,
        },
        "3.8": {
          "0": 1197,
        },
        "4.2": {
          "0": 560,
        },
        "4.8": {
          "0": 1122,
        },
        "4.4": {
          "0": 727,
        },
        "13.6": {
          "1": 155,
        },
        "4.6": {
          "0": 920,
        },
        "13.2": {
          "1": 236,
        },
        "12.8": {
          "1": 439,
        },
        "14.2": {
          "1": 143,
          "2": 3,
        },
        "13.4": {
          "1": 189,
        },
        "13.8": {
          "1": 98,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 4244,
      TD: 4628,
      LEWICA: 6844,
    },
    potencjalneMandaty: {
      KO: 18469,
      TD: 55871,
      LEWICA: 57430,
    },
    ostatnieMandaty: {
      KO: 22515,
      TD: 40474,
      LEWICA: 24256,
    },
    dawcyGlosow: {
      KO: 12117,
      TD: 10112,
      LEWICA: 16301,
    },
  },
  Radom: {
    procentyWOkreguSrednia: {
      PIS: 50.86333713610649,
      KO: 20.406674508619925,
      TD: 12.130838148796057,
      KONF: 10.621817243716906,
      LEWICA: 5.9773329627606335,
    },
    odchylenieWOkregu: {
      PIS: 6.15190011268539,
      KO: 1.4496570721659432,
      TD: 2.013774734692939,
      LEWICA: 1.8268491398577198,
      KONF: 1.6497300123243035,
    },
    wykresRozkladu: {
      "84.7%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "5.7%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "4%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 0,
      },
      "3.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.7%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.5%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "85.9%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
      "9.7%": {
        DEMO: 2,
        NIEDEMO: 7,
      },
      "4.4%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "36": {
          "4": 3,
        },
        "37": {
          "4": 12,
        },
        "38": {
          "4": 28,
        },
        "39": {
          "4": 36,
          "5": 1,
        },
        "40": {
          "4": 84,
          "5": 3,
        },
        "41": {
          "4": 110,
          "5": 40,
        },
        "42": {
          "4": 165,
          "5": 122,
        },
        "43": {
          "4": 152,
          "5": 281,
        },
        "44": {
          "4": 139,
          "5": 587,
        },
        "45": {
          "4": 149,
          "5": 966,
        },
        "46": {
          "4": 144,
          "5": 1656,
        },
        "47": {
          "4": 71,
          "5": 2381,
          "6": 1,
        },
        "48": {
          "4": 39,
          "5": 2979,
          "6": 21,
        },
        "49": {
          "4": 2,
          "5": 3761,
          "6": 57,
        },
        "50": {
          "5": 4213,
          "6": 187,
        },
        "51": {
          "5": 4214,
          "6": 405,
        },
        "52": {
          "5": 3702,
          "6": 730,
        },
        "53": {
          "5": 2880,
          "6": 1061,
        },
        "54": {
          "5": 1728,
          "6": 1441,
        },
        "55": {
          "5": 763,
          "6": 1547,
          "7": 1,
        },
        "56": {
          "5": 287,
          "6": 1310,
          "7": 1,
        },
        "57": {
          "5": 50,
          "6": 855,
          "7": 6,
        },
        "58": {
          "5": 2,
          "6": 442,
          "7": 18,
        },
        "59": {
          "5": 1,
          "6": 219,
          "7": 16,
        },
        "60": {
          "6": 76,
          "7": 24,
        },
        "61": {
          "6": 14,
          "7": 10,
        },
        "62": {
          "6": 4,
          "7": 7,
        },
        "63": {
          "7": 1,
        },
        "64": {
          "7": 1,
        },
        "55.2": {
          "5": 692,
          "6": 1486,
        },
        "49.8": {
          "5": 4117,
          "6": 159,
        },
        "48.6": {
          "4": 7,
          "5": 3550,
          "6": 39,
        },
        "56.4": {
          "5": 150,
          "6": 1057,
          "7": 2,
        },
        "45.6": {
          "4": 142,
          "5": 1351,
        },
        "51.4": {
          "5": 3987,
          "6": 486,
        },
        "53.6": {
          "5": 2167,
          "6": 1363,
        },
        "52.6": {
          "5": 3145,
          "6": 941,
        },
        "52.2": {
          "5": 3500,
          "6": 785,
        },
        "49.2": {
          "4": 2,
          "5": 4029,
          "6": 93,
        },
        "51.2": {
          "5": 4164,
          "6": 419,
        },
        "46.4": {
          "4": 119,
          "5": 1834,
        },
        "48.8": {
          "4": 7,
          "5": 3661,
          "6": 34,
        },
        "47.8": {
          "4": 41,
          "5": 2888,
          "6": 19,
        },
        "50.8": {
          "5": 4213,
          "6": 327,
        },
        "44.2": {
          "4": 168,
          "5": 682,
        },
        "49.4": {
          "5": 3977,
          "6": 104,
        },
        "56.2": {
          "5": 196,
          "6": 1163,
          "7": 2,
        },
        "51.6": {
          "5": 3952,
          "6": 575,
        },
        "47.6": {
          "4": 46,
          "5": 2718,
          "6": 10,
        },
        "54.8": {
          "5": 971,
          "6": 1490,
        },
        "46.8": {
          "4": 87,
          "5": 2232,
        },
        "49.6": {
          "5": 4118,
          "6": 126,
        },
        "57.4": {
          "5": 21,
          "6": 669,
          "7": 9,
        },
        "50.6": {
          "5": 4222,
          "6": 310,
        },
        "53.2": {
          "5": 2668,
          "6": 1175,
        },
        "47.2": {
          "4": 73,
          "5": 2462,
          "6": 3,
        },
        "56.6": {
          "5": 110,
          "6": 1085,
          "7": 4,
        },
        "47.4": {
          "4": 81,
          "5": 2598,
          "6": 9,
        },
        "53.8": {
          "5": 1868,
          "6": 1336,
        },
        "50.2": {
          "5": 4186,
          "6": 212,
        },
        "54.4": {
          "5": 1347,
          "6": 1478,
        },
        "57.2": {
          "5": 40,
          "6": 734,
          "7": 10,
        },
        "46.6": {
          "4": 92,
          "5": 2028,
        },
        "51.8": {
          "5": 3754,
          "6": 652,
        },
        "55.4": {
          "5": 533,
          "6": 1403,
          "7": 2,
        },
        "50.4": {
          "5": 4116,
          "6": 261,
        },
        "53.4": {
          "5": 2366,
          "6": 1191,
        },
        "55.6": {
          "5": 422,
          "6": 1359,
        },
        "45.8": {
          "4": 138,
          "5": 1474,
        },
        "58.2": {
          "5": 1,
          "6": 411,
          "7": 15,
        },
        "48.2": {
          "4": 23,
          "5": 3200,
          "6": 24,
        },
        "55.8": {
          "5": 352,
          "6": 1319,
          "7": 3,
        },
        "52.4": {
          "5": 3380,
          "6": 874,
        },
        "46.2": {
          "4": 119,
          "5": 1709,
        },
        "52.8": {
          "5": 2966,
          "6": 979,
        },
        "48.4": {
          "4": 19,
          "5": 3387,
          "6": 35,
        },
        "54.2": {
          "5": 1463,
          "6": 1507,
        },
        "43.8": {
          "4": 165,
          "5": 533,
        },
        "45.4": {
          "4": 139,
          "5": 1247,
        },
        "40.6": {
          "4": 89,
          "5": 17,
        },
        "56.8": {
          "5": 86,
          "6": 947,
          "7": 7,
        },
        "44.4": {
          "4": 146,
          "5": 753,
        },
        "44.6": {
          "4": 159,
          "5": 779,
        },
        "54.6": {
          "5": 1143,
          "6": 1510,
        },
        "58.4": {
          "5": 1,
          "6": 318,
          "7": 14,
        },
        "41.4": {
          "4": 124,
          "5": 71,
        },
        "45.2": {
          "4": 110,
          "5": 1129,
        },
        "42.2": {
          "4": 132,
          "5": 171,
        },
        "43.4": {
          "4": 162,
          "5": 378,
        },
        "58.8": {
          "5": 2,
          "6": 236,
          "7": 26,
        },
        "42.4": {
          "4": 139,
          "5": 205,
        },
        "58.6": {
          "6": 285,
          "7": 16,
        },
        "57.8": {
          "5": 6,
          "6": 547,
          "7": 14,
        },
        "41.6": {
          "4": 125,
          "5": 85,
        },
        "43.6": {
          "4": 155,
          "5": 461,
        },
        "57.6": {
          "5": 12,
          "6": 651,
          "7": 9,
        },
        "44.8": {
          "4": 145,
          "5": 991,
        },
        "40.4": {
          "4": 90,
          "5": 14,
        },
        "40.8": {
          "4": 106,
          "5": 31,
        },
        "42.8": {
          "4": 169,
          "5": 263,
        },
        "59.8": {
          "6": 102,
          "7": 23,
        },
        "43.2": {
          "4": 176,
          "5": 311,
        },
        "37.4": {
          "4": 13,
        },
        "60.4": {
          "6": 47,
          "7": 16,
        },
        "59.4": {
          "6": 148,
          "7": 13,
        },
        "39.8": {
          "4": 87,
          "5": 2,
        },
        "42.6": {
          "4": 154,
          "5": 220,
        },
        "60.2": {
          "6": 65,
          "7": 17,
        },
        "41.8": {
          "4": 140,
          "5": 89,
        },
        "59.6": {
          "6": 115,
          "7": 24,
        },
        "60.8": {
          "6": 25,
          "7": 20,
        },
        "39.4": {
          "4": 41,
          "5": 2,
        },
        "41.2": {
          "4": 123,
          "5": 51,
        },
        "39.2": {
          "4": 49,
        },
        "59.2": {
          "6": 184,
          "7": 18,
        },
        "35.6": {
          "4": 3,
        },
        "40.2": {
          "4": 79,
          "5": 8,
        },
        "60.6": {
          "6": 28,
          "7": 18,
        },
        "37.6": {
          "4": 10,
        },
        "36.2": {
          "4": 13,
        },
        "38.8": {
          "4": 31,
        },
        "61.4": {
          "6": 6,
          "7": 18,
        },
        "63.4": {
          "7": 1,
        },
        "38.2": {
          "4": 20,
        },
        "62.2": {
          "6": 1,
          "7": 4,
        },
        "36.4": {
          "4": 6,
        },
        "39.6": {
          "4": 54,
        },
        "37.2": {
          "3": 2,
          "4": 8,
        },
        "61.6": {
          "6": 8,
          "7": 10,
        },
        "61.2": {
          "6": 16,
          "7": 12,
        },
        "38.6": {
          "4": 36,
        },
        "36.8": {
          "3": 1,
          "4": 14,
        },
        "38.4": {
          "4": 27,
        },
        "61.8": {
          "6": 7,
          "7": 12,
        },
        "62.6": {
          "6": 1,
          "7": 4,
        },
        "37.8": {
          "3": 1,
          "4": 14,
        },
        "35.4": {
          "3": 11,
          "4": 9,
        },
        "62.8": {
          "6": 1,
          "7": 3,
        },
        "62.4": {
          "6": 3,
          "7": 6,
        },
        "36.6": {
          "4": 5,
        },
        "63.2": {
          "7": 3,
        },
        "65.6": {
          "7": 1,
        },
        "63.6": {
          "7": 2,
        },
        "64.6": {
          "7": 3,
        },
        "63.8": {
          "7": 5,
        },
        "64.2": {
          "7": 1,
        },
      },
      KO: {
        "17": {
          "1": 1125,
          "2": 222,
        },
        "18": {
          "1": 1094,
          "2": 2517,
        },
        "19": {
          "1": 95,
          "2": 6731,
        },
        "20": {
          "2": 8634,
        },
        "21": {
          "2": 8049,
        },
        "22": {
          "2": 5539,
        },
        "23": {
          "2": 3220,
        },
        "24": {
          "2": 1427,
          "3": 6,
        },
        "19.4": {
          "1": 23,
          "2": 7744,
        },
        "20.4": {
          "2": 8686,
        },
        "21.4": {
          "2": 7349,
        },
        "19.2": {
          "1": 47,
          "2": 7345,
        },
        "23.2": {
          "2": 2746,
        },
        "21.6": {
          "2": 6784,
        },
        "18.4": {
          "1": 548,
          "2": 4322,
        },
        "19.8": {
          "2": 8532,
        },
        "17.8": {
          "1": 1217,
          "2": 1868,
        },
        "22.6": {
          "2": 3992,
        },
        "20.2": {
          "2": 8829,
        },
        "24.4": {
          "2": 910,
          "3": 29,
        },
        "18.8": {
          "1": 156,
          "2": 6050,
        },
        "24.8": {
          "2": 2502,
          "3": 886,
        },
        "20.8": {
          "2": 8418,
        },
        "19.6": {
          "1": 5,
          "2": 8201,
        },
        "21.2": {
          "2": 7734,
        },
        "20.6": {
          "2": 8616,
        },
        "18.2": {
          "1": 797,
          "2": 3487,
        },
        "24.2": {
          "2": 1193,
          "3": 17,
        },
        "22.2": {
          "2": 5179,
        },
        "17.2": {
          "1": 1277,
          "2": 433,
        },
        "23.4": {
          "2": 2377,
          "3": 3,
        },
        "18.6": {
          "1": 353,
          "2": 5276,
        },
        "17.6": {
          "1": 1296,
          "2": 1233,
        },
        "21.8": {
          "2": 6245,
        },
        "22.8": {
          "2": 3603,
        },
        "16.8": {
          "1": 3440,
          "2": 209,
        },
        "23.8": {
          "2": 1707,
          "3": 10,
        },
        "23.6": {
          "2": 2014,
          "3": 2,
        },
        "24.6": {
          "2": 776,
          "3": 43,
        },
        "22.4": {
          "2": 4653,
        },
        "17.4": {
          "1": 1410,
          "2": 769,
        },
      },
      TD: {
        "7": {
          "0": 907,
        },
        "8": {
          "0": 837,
          "1": 8,
        },
        "9": {
          "0": 713,
          "1": 1583,
        },
        "10": {
          "1": 4409,
        },
        "11": {
          "1": 6966,
        },
        "12": {
          "1": 8087,
        },
        "13": {
          "1": 7087,
        },
        "14": {
          "1": 5094,
        },
        "15": {
          "1": 2770,
        },
        "16": {
          "1": 1174,
          "2": 14,
        },
        "17": {
          "1": 337,
          "2": 94,
        },
        "18": {
          "1": 37,
          "2": 91,
        },
        "11.8": {
          "1": 8063,
        },
        "12.4": {
          "1": 7912,
        },
        "10.4": {
          "1": 5538,
        },
        "10.6": {
          "1": 6066,
        },
        "15.4": {
          "1": 1989,
        },
        "13.6": {
          "1": 6200,
        },
        "12.6": {
          "1": 7816,
        },
        "14.2": {
          "1": 4555,
        },
        "13.2": {
          "1": 6948,
        },
        "13.4": {
          "1": 6496,
        },
        "11.2": {
          "1": 7437,
        },
        "12.2": {
          "1": 8090,
        },
        "9.8": {
          "0": 8,
          "1": 4067,
        },
        "8.6": {
          "0": 1108,
          "1": 419,
        },
        "9.6": {
          "0": 28,
          "1": 3539,
        },
        "11.4": {
          "1": 7760,
        },
        "9.4": {
          "0": 126,
          "1": 3012,
        },
        "8.8": {
          "0": 996,
          "1": 874,
        },
        "12.8": {
          "1": 7855,
        },
        "15.2": {
          "1": 2370,
        },
        "11.6": {
          "1": 8064,
        },
        "10.8": {
          "1": 6488,
        },
        "13.8": {
          "1": 5670,
        },
        "14.8": {
          "1": 3120,
        },
        "14.4": {
          "1": 4161,
        },
        "17.8": {
          "1": 72,
          "2": 70,
        },
        "10.2": {
          "1": 5022,
        },
        "14.6": {
          "1": 3719,
        },
        "16.6": {
          "1": 590,
          "2": 52,
        },
        "8.4": {
          "0": 1157,
          "1": 161,
        },
        "7.6": {
          "0": 516,
        },
        "15.6": {
          "1": 1791,
        },
        "9.2": {
          "0": 341,
          "1": 2231,
        },
        "16.8": {
          "1": 447,
          "2": 67,
        },
        "15.8": {
          "1": 1433,
          "2": 4,
        },
        "8.2": {
          "0": 1013,
          "1": 41,
        },
        "16.2": {
          "1": 1001,
          "2": 23,
        },
        "7.4": {
          "0": 403,
        },
        "7.8": {
          "0": 664,
          "1": 1,
        },
        "16.4": {
          "1": 756,
          "2": 49,
        },
        "7.2": {
          "0": 299,
        },
        "17.2": {
          "1": 229,
          "2": 101,
        },
        "18.2": {
          "1": 38,
          "2": 274,
        },
        "17.4": {
          "1": 148,
          "2": 101,
        },
        "17.6": {
          "1": 94,
          "2": 109,
        },
      },
      KONF: {
        "7": {
          "0": 842,
        },
        "8": {
          "0": 2533,
          "1": 50,
        },
        "9": {
          "0": 1487,
          "1": 4564,
        },
        "10": {
          "1": 9128,
        },
        "11": {
          "1": 9389,
        },
        "12": {
          "1": 6683,
        },
        "13": {
          "1": 3451,
        },
        "14": {
          "1": 1274,
        },
        "15": {
          "1": 352,
        },
        "11.2": {
          "1": 8965,
        },
        "8.4": {
          "0": 3259,
          "1": 661,
        },
        "13.6": {
          "1": 1997,
        },
        "10.6": {
          "1": 9818,
        },
        "8.2": {
          "0": 3059,
          "1": 208,
        },
        "9.4": {
          "0": 215,
          "1": 7261,
        },
        "10.4": {
          "1": 9573,
        },
        "9.8": {
          "0": 8,
          "1": 8571,
        },
        "10.8": {
          "1": 9650,
        },
        "9.6": {
          "0": 58,
          "1": 8014,
        },
        "11.6": {
          "1": 8172,
        },
        "12.4": {
          "1": 5210,
        },
        "12.8": {
          "1": 4020,
        },
        "12.6": {
          "1": 4631,
        },
        "7.8": {
          "0": 2240,
          "1": 1,
        },
        "8.6": {
          "0": 3002,
          "1": 1548,
        },
        "8.8": {
          "0": 2411,
          "1": 2878,
        },
        "11.8": {
          "1": 7283,
        },
        "6.6": {
          "0": 447,
        },
        "12.2": {
          "1": 5969,
        },
        "10.2": {
          "1": 9314,
        },
        "7.6": {
          "0": 1765,
        },
        "7.2": {
          "0": 1067,
        },
        "11.4": {
          "1": 8479,
        },
        "7.4": {
          "0": 1388,
        },
        "6.8": {
          "0": 612,
        },
        "9.2": {
          "0": 673,
          "1": 5920,
        },
        "13.8": {
          "1": 1599,
        },
        "13.2": {
          "1": 2834,
        },
        "14.2": {
          "1": 982,
        },
        "14.4": {
          "1": 837,
        },
        "13.4": {
          "1": 2472,
        },
        "15.2": {
          "1": 258,
        },
        "14.6": {
          "1": 631,
        },
        "14.8": {
          "1": 530,
        },
        "6.4": {
          "0": 940,
        },
        "15.6": {
          "1": 546,
          "2": 37,
        },
        "15.4": {
          "1": 234,
        },
      },
      LEWICA: {
        "2": {
          "0": 711,
        },
        "3": {
          "0": 2251,
        },
        "4": {
          "0": 4705,
        },
        "5": {
          "0": 7668,
        },
        "6": {
          "0": 9122,
        },
        "7": {
          "0": 7604,
        },
        "8": {
          "0": 4613,
          "1": 26,
        },
        "9": {
          "0": 1169,
          "1": 962,
        },
        "10": {
          "1": 657,
        },
        "11": {
          "1": 184,
        },
        "4.6": {
          "0": 6658,
        },
        "7.6": {
          "0": 5949,
        },
        "8.8": {
          "0": 1865,
          "1": 689,
        },
        "5.6": {
          "0": 8866,
        },
        "5.8": {
          "0": 9002,
        },
        "7.4": {
          "0": 6543,
        },
        "2.4": {
          "0": 1193,
        },
        "6.4": {
          "0": 8558,
        },
        "5.4": {
          "0": 8612,
        },
        "4.4": {
          "0": 6127,
        },
        "3.2": {
          "0": 2637,
        },
        "6.8": {
          "0": 8204,
        },
        "7.2": {
          "0": 7081,
        },
        "1.8": {
          "0": 611,
        },
        "5.2": {
          "0": 8018,
        },
        "4.2": {
          "0": 5505,
        },
        "8.6": {
          "0": 2580,
          "1": 494,
        },
        "9.4": {
          "0": 269,
          "1": 1110,
        },
        "3.8": {
          "0": 4117,
        },
        "6.6": {
          "0": 8422,
        },
        "6.2": {
          "0": 8929,
        },
        "7.8": {
          "0": 5214,
          "1": 2,
        },
        "3.4": {
          "0": 2993,
        },
        "10.4": {
          "1": 452,
        },
        "3.6": {
          "0": 3598,
        },
        "4.8": {
          "0": 7258,
        },
        "8.4": {
          "0": 3282,
          "1": 215,
        },
        "1.6": {
          "0": 429,
        },
        "8.2": {
          "0": 4122,
          "1": 74,
        },
        "10.8": {
          "1": 233,
        },
        "10.2": {
          "1": 529,
        },
        "9.2": {
          "0": 689,
          "1": 1071,
        },
        "2.6": {
          "0": 1399,
        },
        "2.2": {
          "0": 996,
        },
        "1.4": {
          "0": 1300,
        },
        "9.8": {
          "0": 11,
          "1": 860,
        },
        "2.8": {
          "0": 1743,
        },
        "10.6": {
          "1": 299,
        },
        "9.6": {
          "0": 86,
          "1": 1016,
        },
        "11.4": {
          "1": 314,
        },
        "11.2": {
          "1": 104,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 5868,
      TD: 3571,
      LEWICA: 743,
    },
    potencjalneMandaty: {
      KO: 17366,
      TD: 12960,
      LEWICA: 34706,
    },
    ostatnieMandaty: {
      KO: 49803,
      TD: 13611,
      LEWICA: 5812,
    },
    dawcyGlosow: {
      KO: 14901,
      TD: 10688,
      LEWICA: 1188,
    },
  },
  Siedlce: {
    procentyWOkreguSrednia: {
      PIS: 53.74500667361862,
      KO: 16.244790066731564,
      TD: 13.504482305978785,
      KONF: 11.401405533349116,
      LEWICA: 5.104315420321909,
    },
    odchylenieWOkregu: {
      PIS: 6.837438632828105,
      KO: 1.4057160388303216,
      TD: 2.013774734692939,
      LEWICA: 1.635497657283561,
      KONF: 3.05926974349673,
    },
    wykresRozkladu: {
      "43%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 1,
      },
      "36.9%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 2,
      },
      "8.2%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
      "3.7%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "3%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 2,
      },
      "2%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 1,
      },
      "0.8%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.5%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 2,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "46.8%": {
        DEMO: 3,
        NIEDEMO: 9,
      },
      "46.5%": {
        DEMO: 4,
        NIEDEMO: 8,
      },
      "4.7%": {
        DEMO: 5,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "37": {
          "5": 4,
        },
        "38": {
          "5": 6,
        },
        "39": {
          "5": 9,
        },
        "40": {
          "5": 24,
          "6": 5,
        },
        "41": {
          "5": 33,
          "6": 25,
        },
        "42": {
          "5": 22,
          "6": 55,
        },
        "43": {
          "5": 25,
          "6": 124,
        },
        "44": {
          "5": 7,
          "6": 252,
        },
        "45": {
          "5": 3,
          "6": 409,
          "7": 16,
        },
        "46": {
          "6": 573,
          "7": 53,
        },
        "47": {
          "6": 669,
          "7": 226,
        },
        "48": {
          "6": 739,
          "7": 654,
        },
        "49": {
          "6": 617,
          "7": 1340,
        },
        "50": {
          "6": 326,
          "7": 2115,
        },
        "51": {
          "6": 124,
          "7": 2912,
          "8": 17,
        },
        "52": {
          "6": 19,
          "7": 3418,
          "8": 168,
        },
        "53": {
          "6": 3,
          "7": 3345,
          "8": 609,
        },
        "54": {
          "7": 2841,
          "8": 1367,
        },
        "55": {
          "7": 1812,
          "8": 2159,
        },
        "56": {
          "7": 951,
          "8": 2510,
        },
        "57": {
          "7": 367,
          "8": 2680,
          "9": 4,
        },
        "58": {
          "7": 98,
          "8": 2186,
          "9": 35,
        },
        "59": {
          "7": 27,
          "8": 1537,
          "9": 106,
        },
        "60": {
          "7": 2,
          "8": 903,
          "9": 149,
        },
        "61": {
          "8": 476,
          "9": 192,
        },
        "62": {
          "8": 210,
          "9": 174,
        },
        "63": {
          "8": 76,
          "9": 121,
        },
        "64": {
          "8": 25,
          "9": 67,
          "10": 1,
        },
        "65": {
          "8": 1,
          "9": 36,
          "10": 1,
        },
        "66": {
          "9": 13,
        },
        "67": {
          "9": 11,
          "10": 1,
        },
        "68": {
          "9": 3,
        },
        "69": {
          "9": 1,
          "10": 1,
        },
        "56.2": {
          "7": 829,
          "8": 2589,
        },
        "54.2": {
          "7": 2687,
          "8": 1500,
        },
        "62.2": {
          "8": 153,
          "9": 151,
        },
        "53.4": {
          "6": 1,
          "7": 3255,
          "8": 869,
        },
        "48.4": {
          "6": 737,
          "7": 896,
        },
        "54.4": {
          "7": 2336,
          "8": 1636,
        },
        "52.8": {
          "6": 5,
          "7": 3476,
          "8": 519,
        },
        "51.2": {
          "6": 80,
          "7": 3027,
          "8": 28,
        },
        "55.8": {
          "7": 1084,
          "8": 2487,
        },
        "57.2": {
          "7": 271,
          "8": 2523,
          "9": 7,
        },
        "52.2": {
          "6": 23,
          "7": 3424,
          "8": 249,
        },
        "49.8": {
          "6": 371,
          "7": 2054,
        },
        "53.2": {
          "7": 3239,
          "8": 752,
        },
        "55.4": {
          "7": 1471,
          "8": 2323,
        },
        "52.4": {
          "6": 13,
          "7": 3457,
          "8": 308,
        },
        "56.4": {
          "7": 662,
          "8": 2620,
        },
        "52.6": {
          "6": 6,
          "7": 3439,
          "8": 396,
        },
        "51.6": {
          "6": 49,
          "7": 3177,
          "8": 77,
        },
        "56.8": {
          "7": 492,
          "8": 2673,
          "9": 2,
        },
        "59.6": {
          "7": 3,
          "8": 1144,
          "9": 152,
        },
        "58.4": {
          "7": 56,
          "8": 1891,
          "9": 49,
        },
        "60.6": {
          "8": 636,
          "9": 177,
        },
        "57.8": {
          "7": 129,
          "8": 2293,
          "9": 23,
        },
        "48.6": {
          "6": 665,
          "7": 1094,
        },
        "57.6": {
          "7": 195,
          "8": 2412,
          "9": 18,
        },
        "58.6": {
          "7": 50,
          "8": 1880,
          "9": 55,
        },
        "59.4": {
          "7": 10,
          "8": 1269,
          "9": 123,
        },
        "51.4": {
          "6": 77,
          "7": 3130,
          "8": 58,
        },
        "53.8": {
          "7": 2861,
          "8": 1115,
        },
        "59.2": {
          "7": 8,
          "8": 1446,
          "9": 114,
        },
        "48.8": {
          "6": 617,
          "7": 1123,
        },
        "46.8": {
          "6": 732,
          "7": 206,
        },
        "50.2": {
          "6": 287,
          "7": 2332,
        },
        "49.6": {
          "6": 431,
          "7": 1763,
        },
        "53.6": {
          "7": 2997,
          "8": 1104,
        },
        "57.4": {
          "7": 251,
          "8": 2523,
          "9": 7,
        },
        "61.6": {
          "8": 288,
          "9": 215,
        },
        "45.8": {
          "6": 552,
          "7": 50,
        },
        "46.2": {
          "6": 582,
          "7": 95,
        },
        "61.4": {
          "8": 303,
          "9": 198,
        },
        "50.8": {
          "6": 165,
          "7": 2803,
          "8": 8,
        },
        "55.6": {
          "7": 1275,
          "8": 2445,
        },
        "58.2": {
          "7": 77,
          "8": 2019,
          "9": 39,
        },
        "50.4": {
          "6": 257,
          "7": 2359,
        },
        "43.8": {
          "5": 15,
          "6": 208,
        },
        "54.6": {
          "7": 2174,
          "8": 1820,
        },
        "50.6": {
          "6": 212,
          "7": 2577,
          "8": 5,
        },
        "54.8": {
          "7": 1978,
          "8": 1981,
        },
        "47.6": {
          "6": 765,
          "7": 450,
        },
        "56.6": {
          "7": 537,
          "8": 2728,
        },
        "55.2": {
          "7": 1650,
          "8": 2241,
        },
        "49.4": {
          "6": 463,
          "7": 1667,
        },
        "51.8": {
          "6": 41,
          "7": 3313,
          "8": 118,
        },
        "44.8": {
          "5": 1,
          "6": 378,
          "7": 2,
        },
        "58.8": {
          "7": 18,
          "8": 1652,
          "9": 88,
        },
        "60.2": {
          "7": 2,
          "8": 857,
          "9": 178,
        },
        "42.2": {
          "5": 21,
          "6": 69,
        },
        "49.2": {
          "6": 520,
          "7": 1487,
        },
        "47.4": {
          "6": 689,
          "7": 352,
        },
        "48.2": {
          "6": 702,
          "7": 727,
        },
        "60.4": {
          "8": 742,
          "9": 171,
        },
        "61.2": {
          "8": 421,
          "9": 185,
        },
        "62.4": {
          "8": 130,
          "9": 183,
        },
        "44.6": {
          "5": 6,
          "6": 337,
          "7": 5,
        },
        "47.2": {
          "6": 743,
          "7": 298,
        },
        "47.8": {
          "6": 736,
          "7": 551,
        },
        "62.8": {
          "8": 104,
          "9": 152,
        },
        "46.6": {
          "6": 694,
          "7": 145,
        },
        "61.8": {
          "8": 232,
          "9": 179,
        },
        "44.4": {
          "5": 8,
          "6": 260,
          "7": 1,
        },
        "43.2": {
          "5": 18,
          "6": 135,
        },
        "45.6": {
          "6": 550,
          "7": 29,
        },
        "63.2": {
          "8": 49,
          "9": 134,
        },
        "38.6": {
          "5": 11,
        },
        "40.4": {
          "5": 24,
          "6": 8,
        },
        "43.6": {
          "5": 12,
          "6": 203,
        },
        "44.2": {
          "5": 16,
          "6": 264,
          "7": 1,
        },
        "45.4": {
          "5": 1,
          "6": 479,
          "7": 19,
        },
        "60.8": {
          "8": 540,
          "9": 175,
        },
        "45.2": {
          "5": 2,
          "6": 467,
          "7": 16,
        },
        "59.8": {
          "7": 2,
          "8": 1036,
          "9": 144,
        },
        "46.4": {
          "6": 647,
          "7": 115,
        },
        "40.6": {
          "5": 29,
          "6": 18,
        },
        "43.4": {
          "5": 16,
          "6": 165,
        },
        "41.8": {
          "5": 23,
          "6": 59,
        },
        "42.8": {
          "5": 15,
          "6": 118,
        },
        "41.4": {
          "5": 25,
          "6": 36,
        },
        "64.6": {
          "8": 8,
          "9": 51,
        },
        "39.4": {
          "5": 14,
          "6": 1,
        },
        "40.8": {
          "5": 25,
          "6": 22,
        },
        "62.6": {
          "8": 106,
          "9": 152,
        },
        "66.8": {
          "9": 6,
          "10": 2,
        },
        "38.2": {
          "5": 8,
        },
        "41.6": {
          "5": 27,
          "6": 38,
        },
        "42.6": {
          "5": 23,
          "6": 121,
        },
        "65.2": {
          "8": 4,
          "9": 37,
        },
        "69.2": {
          "9": 1,
        },
        "63.8": {
          "8": 23,
          "9": 75,
        },
        "42.4": {
          "5": 27,
          "6": 93,
        },
        "63.6": {
          "8": 27,
          "9": 89,
        },
        "64.8": {
          "8": 10,
          "9": 50,
          "10": 2,
        },
        "65.4": {
          "8": 3,
          "9": 24,
          "10": 1,
        },
        "63.4": {
          "8": 39,
          "9": 109,
        },
        "41.2": {
          "5": 27,
          "6": 34,
        },
        "37.4": {
          "5": 7,
        },
        "64.2": {
          "8": 12,
          "9": 62,
        },
        "64.4": {
          "8": 7,
          "9": 63,
          "10": 2,
        },
        "36.6": {
          "3": 1,
          "4": 7,
          "5": 23,
        },
        "40.2": {
          "5": 21,
          "6": 6,
        },
        "39.2": {
          "5": 20,
          "6": 1,
        },
        "65.6": {
          "8": 2,
          "9": 18,
          "10": 1,
        },
        "37.2": {
          "5": 9,
        },
        "66.6": {
          "9": 8,
          "10": 2,
        },
        "39.6": {
          "5": 20,
          "6": 3,
        },
        "38.8": {
          "5": 12,
          "6": 1,
        },
        "39.8": {
          "5": 15,
          "6": 3,
        },
        "66.2": {
          "9": 14,
          "10": 1,
        },
        "65.8": {
          "9": 12,
          "10": 3,
        },
        "66.4": {
          "8": 1,
          "9": 12,
        },
        "38.4": {
          "5": 7,
        },
        "67.2": {
          "9": 1,
        },
        "67.4": {
          "9": 1,
        },
        "37.6": {
          "5": 9,
        },
        "36.8": {
          "5": 3,
        },
        "67.6": {
          "9": 3,
        },
        "37.8": {
          "5": 6,
        },
        "67.8": {
          "9": 1,
          "10": 1,
        },
        "68.2": {
          "9": 1,
        },
      },
      KO: {
        "13": {
          "1": 1290,
          "2": 68,
        },
        "14": {
          "1": 847,
          "2": 3160,
        },
        "15": {
          "1": 9,
          "2": 7534,
        },
        "16": {
          "2": 9244,
        },
        "17": {
          "2": 7812,
        },
        "18": {
          "2": 5283,
        },
        "19": {
          "2": 2715,
          "3": 9,
        },
        "20": {
          "2": 908,
          "3": 214,
        },
        "15.6": {
          "2": 8925,
        },
        "17.4": {
          "2": 6996,
        },
        "16.2": {
          "2": 9126,
        },
        "16.4": {
          "2": 9068,
        },
        "17.2": {
          "2": 7482,
        },
        "16.6": {
          "2": 8657,
        },
        "18.6": {
          "2": 3579,
        },
        "15.8": {
          "2": 9005,
        },
        "12.8": {
          "1": 3697,
          "2": 23,
        },
        "14.4": {
          "1": 302,
          "2": 5088,
        },
        "20.4": {
          "2": 1293,
          "3": 2678,
        },
        "16.8": {
          "2": 8200,
        },
        "19.8": {
          "2": 1160,
          "3": 173,
        },
        "15.4": {
          "2": 8240,
        },
        "18.2": {
          "2": 4715,
        },
        "13.2": {
          "1": 1465,
          "2": 332,
        },
        "13.4": {
          "1": 1529,
          "2": 738,
        },
        "19.4": {
          "2": 1956,
          "3": 64,
        },
        "17.8": {
          "2": 5877,
        },
        "14.8": {
          "1": 42,
          "2": 6833,
        },
        "15.2": {
          "1": 3,
          "2": 7963,
        },
        "18.8": {
          "2": 3158,
        },
        "14.2": {
          "1": 516,
          "2": 4316,
        },
        "18.4": {
          "2": 4063,
        },
        "19.2": {
          "2": 2230,
          "3": 28,
        },
        "14.6": {
          "1": 105,
          "2": 5953,
        },
        "19.6": {
          "2": 1573,
          "3": 104,
        },
        "13.8": {
          "1": 1212,
          "2": 2251,
        },
        "17.6": {
          "2": 6440,
        },
        "13.6": {
          "1": 1483,
          "2": 1356,
        },
        "20.2": {
          "2": 647,
          "3": 263,
        },
      },
      TD: {
        "9": {
          "1": 586,
        },
        "10": {
          "1": 1680,
        },
        "11": {
          "1": 3682,
        },
        "12": {
          "1": 6130,
        },
        "13": {
          "1": 7352,
          "2": 292,
        },
        "14": {
          "1": 2294,
          "2": 5223,
        },
        "15": {
          "1": 26,
          "2": 5817,
        },
        "16": {
          "2": 3553,
        },
        "17": {
          "2": 1893,
        },
        "18": {
          "2": 792,
        },
        "19": {
          "2": 304,
          "3": 1,
        },
        "15.4": {
          "2": 4960,
        },
        "16.4": {
          "2": 2785,
        },
        "13.6": {
          "1": 4911,
          "2": 2892,
        },
        "14.2": {
          "1": 1214,
          "2": 6024,
        },
        "12.8": {
          "1": 7452,
          "2": 54,
        },
        "13.2": {
          "1": 6953,
          "2": 831,
        },
        "10.8": {
          "1": 3259,
        },
        "16.6": {
          "2": 2442,
        },
        "10.6": {
          "1": 2812,
        },
        "8.6": {
          "1": 388,
        },
        "11.8": {
          "1": 5620,
        },
        "14.6": {
          "1": 266,
          "2": 6255,
        },
        "15.6": {
          "2": 4449,
        },
        "11.2": {
          "1": 4183,
        },
        "12.4": {
          "1": 6955,
        },
        "12.6": {
          "1": 7219,
          "2": 3,
        },
        "14.4": {
          "1": 629,
          "2": 6380,
        },
        "15.2": {
          "1": 5,
          "2": 5418,
        },
        "11.6": {
          "1": 5178,
        },
        "13.8": {
          "1": 3473,
          "2": 4224,
        },
        "12.2": {
          "1": 6529,
        },
        "10.4": {
          "1": 2339,
        },
        "13.4": {
          "1": 6097,
          "2": 1733,
        },
        "15.8": {
          "2": 4140,
        },
        "19.6": {
          "2": 441,
          "3": 227,
        },
        "9.8": {
          "1": 1413,
        },
        "18.4": {
          "2": 580,
        },
        "10.2": {
          "1": 2030,
        },
        "17.8": {
          "2": 966,
        },
        "14.8": {
          "1": 86,
          "2": 6060,
        },
        "16.2": {
          "2": 3224,
        },
        "9.2": {
          "1": 762,
        },
        "11.4": {
          "1": 4664,
        },
        "17.4": {
          "2": 1406,
        },
        "9.4": {
          "1": 936,
        },
        "18.6": {
          "2": 418,
        },
        "17.2": {
          "2": 1586,
        },
        "18.8": {
          "2": 354,
        },
        "18.2": {
          "2": 677,
        },
        "16.8": {
          "2": 2174,
        },
        "8.4": {
          "0": 81,
          "1": 959,
        },
        "17.6": {
          "2": 1146,
        },
        "9.6": {
          "1": 1211,
        },
        "8.8": {
          "1": 498,
        },
        "19.4": {
          "2": 167,
          "3": 7,
        },
        "19.2": {
          "2": 227,
          "3": 3,
        },
      },
      KONF: {
        "4": {
          "0": 241,
        },
        "5": {
          "0": 492,
        },
        "6": {
          "0": 927,
        },
        "7": {
          "0": 490,
          "1": 1151,
        },
        "8": {
          "1": 2677,
        },
        "9": {
          "1": 3989,
        },
        "10": {
          "1": 4899,
        },
        "11": {
          "1": 5644,
        },
        "12": {
          "1": 5489,
        },
        "13": {
          "1": 4549,
          "2": 175,
        },
        "14": {
          "1": 1081,
          "2": 2735,
        },
        "15": {
          "1": 7,
          "2": 2540,
        },
        "16": {
          "2": 1595,
        },
        "17": {
          "2": 797,
        },
        "18": {
          "2": 342,
        },
        "19": {
          "2": 162,
        },
        "20": {
          "2": 46,
          "3": 16,
        },
        "10.8": {
          "1": 5535,
        },
        "11.2": {
          "1": 5780,
        },
        "4.6": {
          "0": 333,
        },
        "13.8": {
          "1": 1656,
          "2": 2251,
        },
        "8.4": {
          "1": 3190,
        },
        "8.6": {
          "1": 3428,
        },
        "10.6": {
          "1": 5352,
        },
        "11.6": {
          "1": 5563,
        },
        "7.6": {
          "0": 2,
          "1": 2265,
        },
        "13.4": {
          "1": 3519,
          "2": 1039,
        },
        "10.4": {
          "1": 5198,
        },
        "16.4": {
          "2": 1159,
        },
        "10.2": {
          "1": 5036,
        },
        "11.8": {
          "1": 5583,
        },
        "6.6": {
          "0": 1223,
          "1": 168,
        },
        "12.6": {
          "1": 5193,
          "2": 2,
        },
        "12.2": {
          "1": 5424,
        },
        "15.4": {
          "2": 2070,
        },
        "9.2": {
          "1": 4091,
        },
        "6.4": {
          "0": 1160,
          "1": 16,
        },
        "13.6": {
          "1": 2556,
          "2": 1576,
        },
        "13.2": {
          "1": 4049,
          "2": 442,
        },
        "7.8": {
          "1": 2446,
        },
        "15.8": {
          "2": 1788,
        },
        "12.4": {
          "1": 5225,
        },
        "8.8": {
          "1": 3642,
        },
        "8.2": {
          "1": 2919,
        },
        "11.4": {
          "1": 5684,
        },
        "12.8": {
          "1": 4807,
          "2": 45,
        },
        "14.4": {
          "1": 267,
          "2": 2874,
        },
        "14.8": {
          "1": 32,
          "2": 2702,
        },
        "9.6": {
          "1": 4533,
        },
        "9.8": {
          "1": 4837,
        },
        "14.6": {
          "1": 86,
          "2": 2858,
        },
        "16.6": {
          "2": 1031,
        },
        "17.4": {
          "2": 610,
        },
        "6.8": {
          "0": 941,
          "1": 562,
        },
        "15.2": {
          "1": 1,
          "2": 2288,
        },
        "5.4": {
          "0": 619,
        },
        "4.4": {
          "0": 316,
        },
        "17.2": {
          "2": 645,
        },
        "15.6": {
          "2": 1817,
        },
        "7.2": {
          "0": 168,
          "1": 1684,
        },
        "18.2": {
          "2": 309,
        },
        "16.2": {
          "2": 1381,
        },
        "16.8": {
          "2": 878,
        },
        "7.4": {
          "0": 25,
          "1": 2071,
        },
        "14.2": {
          "1": 602,
          "2": 2820,
        },
        "3.8": {
          "0": 1102,
        },
        "9.4": {
          "1": 4356,
        },
        "6.2": {
          "0": 1041,
        },
        "5.8": {
          "0": 821,
        },
        "4.8": {
          "0": 412,
        },
        "17.6": {
          "2": 514,
        },
        "18.6": {
          "2": 228,
        },
        "17.8": {
          "2": 419,
        },
        "20.6": {
          "2": 30,
          "3": 122,
        },
        "19.6": {
          "2": 93,
          "3": 6,
        },
        "5.6": {
          "0": 733,
        },
        "4.2": {
          "0": 254,
        },
        "18.4": {
          "2": 250,
        },
        "19.8": {
          "2": 70,
          "3": 11,
        },
        "19.2": {
          "2": 146,
        },
        "20.2": {
          "2": 31,
          "3": 15,
        },
        "5.2": {
          "0": 603,
        },
        "18.8": {
          "2": 200,
        },
        "19.4": {
          "2": 84,
          "3": 1,
        },
        "20.4": {
          "2": 24,
          "3": 18,
        },
      },
      LEWICA: {
        "1": {
          "0": 1366,
        },
        "2": {
          "0": 1449,
        },
        "3": {
          "0": 4144,
        },
        "4": {
          "0": 7885,
        },
        "5": {
          "0": 9972,
        },
        "6": {
          "0": 8496,
        },
        "7": {
          "0": 1903,
          "1": 3023,
        },
        "8": {
          "1": 2011,
        },
        "9": {
          "1": 562,
        },
        "10": {
          "1": 388,
        },
        "5.2": {
          "0": 9942,
        },
        "1.8": {
          "0": 1218,
        },
        "5.4": {
          "0": 9809,
        },
        "6.4": {
          "0": 6934,
          "1": 64,
        },
        "4.8": {
          "0": 9807,
        },
        "6.2": {
          "0": 7733,
        },
        "4.4": {
          "0": 8952,
        },
        "3.8": {
          "0": 7216,
        },
        "3.4": {
          "0": 5640,
        },
        "4.6": {
          "0": 9417,
        },
        "6.8": {
          "0": 3887,
          "1": 1845,
        },
        "3.6": {
          "0": 6495,
        },
        "7.4": {
          "0": 90,
          "1": 3424,
        },
        "7.6": {
          "0": 3,
          "1": 3039,
        },
        "5.6": {
          "0": 9470,
        },
        "2.4": {
          "0": 2369,
        },
        "4.2": {
          "0": 8413,
        },
        "8.2": {
          "1": 1540,
        },
        "2.6": {
          "0": 2908,
        },
        "2.8": {
          "0": 3517,
        },
        "3.2": {
          "0": 4874,
        },
        "7.2": {
          "0": 592,
          "1": 3566,
        },
        "5.8": {
          "0": 8949,
        },
        "2.2": {
          "0": 1904,
        },
        "7.8": {
          "1": 2491,
        },
        "9.8": {
          "1": 192,
        },
        "9.2": {
          "1": 420,
        },
        "6.6": {
          "0": 5765,
          "1": 600,
        },
        "1.6": {
          "0": 945,
        },
        "9.6": {
          "1": 223,
        },
        "9.4": {
          "1": 313,
        },
        "8.4": {
          "1": 1276,
        },
        "8.8": {
          "1": 761,
        },
        "1.4": {
          "0": 698,
        },
        "8.6": {
          "1": 1008,
        },
        "1.2": {
          "0": 492,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 4020,
      TD: 3698,
      LEWICA: 2268,
    },
    potencjalneMandaty: {
      KO: 28196,
      TD: 60750,
      LEWICA: 52681,
    },
    ostatnieMandaty: {
      KO: 23817,
      TD: 36851,
      LEWICA: 10910,
    },
    dawcyGlosow: {
      KO: 11138,
      TD: 8710,
      LEWICA: 4517,
    },
  },
  "Warszawa I": {
    procentyWOkreguSrednia: {
      PIS: 20.135700651859665,
      KO: 46.11700458017708,
      TD: 9.061250362556706,
      KONF: 7.228260754593663,
      LEWICA: 17.45778365081287,
    },
    odchylenieWOkregu: {
      PIS: 6.401761091450273,
      KO: 4.507118250712197,
      TD: 2.013774734692939,
      LEWICA: 2.4850740963179185,
      KONF: 2.5705288112617066,
    },
    wykresRozkladu: {
      "10.4%": {
        KO: 10,
        LEWICA: 3,
        NIEDEMO: 5,
        TD: 2,
      },
      "8.9%": {
        KO: 10,
        LEWICA: 3,
        NIEDEMO: 6,
        TD: 1,
      },
      "8.1%": {
        KO: 9,
        LEWICA: 3,
        NIEDEMO: 6,
        TD: 2,
      },
      "7.7%": {
        KO: 10,
        LEWICA: 4,
        NIEDEMO: 5,
        TD: 1,
      },
      "7.1%": {
        KO: 10,
        LEWICA: 4,
        NIEDEMO: 4,
        TD: 2,
      },
      "6.3%": {
        KO: 9,
        LEWICA: 4,
        NIEDEMO: 5,
        TD: 2,
      },
      "5.4%": {
        KO: 9,
        LEWICA: 4,
        NIEDEMO: 6,
        TD: 1,
      },
      "5.3%": {
        KO: 11,
        LEWICA: 3,
        NIEDEMO: 5,
        TD: 1,
      },
      "4.9%": {
        KO: 11,
        LEWICA: 3,
        NIEDEMO: 4,
        TD: 2,
      },
      "4.3%": {
        KO: 11,
        LEWICA: 4,
        NIEDEMO: 4,
        TD: 1,
      },
      "3.4%": {
        KO: 11,
        LEWICA: 4,
        NIEDEMO: 3,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "31.7%": {
        DEMO: 15,
        NIEDEMO: 5,
      },
      "26.6%": {
        DEMO: 14,
        NIEDEMO: 6,
      },
      "20%": {
        DEMO: 16,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "5": {
          "1": 95,
        },
        "6": {
          "1": 155,
        },
        "7": {
          "1": 184,
        },
        "8": {
          "1": 283,
          "2": 11,
        },
        "9": {
          "1": 124,
          "2": 263,
        },
        "10": {
          "2": 526,
        },
        "11": {
          "2": 705,
        },
        "12": {
          "2": 946,
          "3": 6,
        },
        "13": {
          "2": 1008,
          "3": 168,
        },
        "14": {
          "2": 32,
          "3": 1473,
        },
        "15": {
          "3": 1755,
        },
        "16": {
          "3": 2087,
          "4": 1,
        },
        "17": {
          "3": 2327,
          "4": 54,
        },
        "18": {
          "3": 784,
          "4": 1899,
        },
        "19": {
          "4": 2984,
        },
        "20": {
          "4": 3011,
        },
        "21": {
          "4": 3066,
          "5": 6,
        },
        "22": {
          "4": 1979,
          "5": 1059,
        },
        "23": {
          "4": 167,
          "5": 2562,
        },
        "24": {
          "5": 2411,
        },
        "25": {
          "5": 2037,
        },
        "26": {
          "5": 1492,
          "6": 241,
        },
        "27": {
          "5": 405,
          "6": 929,
        },
        "28": {
          "5": 17,
          "6": 958,
        },
        "29": {
          "6": 662,
        },
        "30": {
          "6": 407,
          "7": 21,
        },
        "31": {
          "6": 143,
          "7": 133,
        },
        "32": {
          "6": 20,
          "7": 112,
        },
        "33": {
          "7": 95,
        },
        "34": {
          "7": 58,
        },
        "35": {
          "7": 9,
          "8": 11,
        },
        "36": {
          "7": 3,
          "8": 6,
        },
        "37": {
          "8": 6,
        },
        "38": {
          "8": 2,
        },
        "21.2": {
          "4": 3025,
          "5": 49,
        },
        "8.4": {
          "1": 293,
          "2": 40,
        },
        "24.6": {
          "5": 2222,
        },
        "22.2": {
          "4": 1465,
          "5": 1366,
        },
        "28.6": {
          "6": 831,
        },
        "16.8": {
          "3": 2285,
          "4": 15,
        },
        "19.2": {
          "4": 2949,
        },
        "26.6": {
          "5": 765,
          "6": 653,
        },
        "19.4": {
          "4": 2917,
        },
        "21.6": {
          "4": 2740,
          "5": 290,
        },
        "18.4": {
          "3": 193,
          "4": 2474,
        },
        "27.2": {
          "5": 252,
          "6": 979,
        },
        "7.8": {
          "1": 261,
          "2": 5,
        },
        "16.4": {
          "3": 2169,
          "4": 3,
        },
        "23.8": {
          "5": 2490,
        },
        "16.2": {
          "3": 2150,
          "4": 1,
        },
        "19.8": {
          "4": 2965,
        },
        "18.8": {
          "3": 13,
          "4": 2863,
        },
        "23.4": {
          "4": 20,
          "5": 2550,
        },
        "4.2": {
          "0": 949,
          "1": 31,
        },
        "31.2": {
          "6": 128,
          "7": 145,
        },
        "6.6": {
          "1": 176,
        },
        "8.8": {
          "1": 223,
          "2": 166,
        },
        "20.8": {
          "4": 3084,
          "5": 1,
        },
        "23.6": {
          "4": 4,
          "5": 2552,
        },
        "13.2": {
          "2": 828,
          "3": 379,
        },
        "15.8": {
          "3": 2011,
        },
        "22.6": {
          "4": 685,
          "5": 2119,
        },
        "20.4": {
          "4": 2980,
          "5": 1,
        },
        "26.8": {
          "5": 579,
          "6": 794,
        },
        "17.6": {
          "3": 1734,
          "4": 874,
        },
        "22.4": {
          "4": 1063,
          "5": 1826,
        },
        "21.8": {
          "4": 2529,
          "5": 666,
        },
        "14.2": {
          "2": 2,
          "3": 1474,
        },
        "18.6": {
          "3": 70,
          "4": 2844,
        },
        "17.4": {
          "3": 2124,
          "4": 438,
        },
        "10.8": {
          "2": 673,
        },
        "24.4": {
          "5": 2390,
        },
        "25.2": {
          "5": 2003,
          "6": 1,
        },
        "26.2": {
          "5": 1225,
          "6": 402,
        },
        "36.4": {
          "7": 3,
          "8": 11,
        },
        "17.8": {
          "3": 1302,
          "4": 1312,
        },
        "12.2": {
          "2": 937,
          "3": 5,
        },
        "11.2": {
          "2": 723,
          "3": 1,
        },
        "20.6": {
          "4": 3022,
        },
        "25.6": {
          "5": 1750,
          "6": 53,
        },
        "27.6": {
          "5": 67,
          "6": 1022,
        },
        "13.6": {
          "2": 278,
          "3": 1037,
        },
        "18.2": {
          "3": 455,
          "4": 2286,
        },
        "26.4": {
          "5": 1027,
          "6": 487,
        },
        "22.8": {
          "4": 371,
          "5": 2441,
        },
        "28.8": {
          "6": 714,
        },
        "11.6": {
          "2": 869,
        },
        "23.2": {
          "4": 74,
          "5": 2642,
        },
        "27.4": {
          "5": 133,
          "6": 974,
        },
        "24.2": {
          "5": 2409,
        },
        "7.4": {
          "1": 269,
          "2": 1,
        },
        "24.8": {
          "5": 2193,
        },
        "9.4": {
          "1": 2,
          "2": 447,
        },
        "14.8": {
          "3": 1751,
        },
        "17.2": {
          "3": 2240,
          "4": 189,
        },
        "29.8": {
          "6": 477,
          "7": 14,
        },
        "12.6": {
          "2": 1058,
          "3": 28,
        },
        "14.6": {
          "3": 1684,
        },
        "32.6": {
          "6": 1,
          "7": 125,
        },
        "15.6": {
          "3": 1922,
          "4": 1,
        },
        "6.2": {
          "1": 191,
        },
        "16.6": {
          "3": 2312,
          "4": 8,
        },
        "21.4": {
          "4": 2850,
          "5": 128,
        },
        "19.6": {
          "4": 2908,
        },
        "20.2": {
          "4": 3132,
        },
        "13.8": {
          "2": 109,
          "3": 1274,
        },
        "9.8": {
          "2": 512,
        },
        "14.4": {
          "3": 1622,
        },
        "12.4": {
          "2": 954,
          "3": 13,
        },
        "25.8": {
          "5": 1632,
          "6": 131,
        },
        "10.6": {
          "2": 649,
        },
        "27.8": {
          "5": 24,
          "6": 960,
        },
        "8.6": {
          "1": 303,
          "2": 68,
        },
        "25.4": {
          "5": 1949,
          "6": 29,
        },
        "28.4": {
          "6": 815,
        },
        "15.4": {
          "3": 1988,
        },
        "15.2": {
          "3": 1807,
        },
        "9.6": {
          "2": 496,
        },
        "28.2": {
          "5": 3,
          "6": 886,
        },
        "29.4": {
          "6": 569,
          "7": 1,
        },
        "11.8": {
          "2": 842,
          "3": 1,
        },
        "10.2": {
          "2": 562,
        },
        "30.6": {
          "6": 269,
          "7": 80,
        },
        "12.8": {
          "2": 1185,
          "3": 49,
        },
        "29.6": {
          "6": 528,
          "7": 2,
        },
        "30.2": {
          "6": 359,
          "7": 47,
        },
        "30.4": {
          "6": 312,
          "7": 46,
        },
        "10.4": {
          "2": 566,
        },
        "33.6": {
          "7": 60,
          "8": 1,
        },
        "31.4": {
          "6": 79,
          "7": 143,
        },
        "11.4": {
          "2": 783,
        },
        "33.2": {
          "7": 68,
        },
        "13.4": {
          "2": 515,
          "3": 746,
        },
        "35.4": {
          "7": 11,
          "8": 7,
        },
        "32.8": {
          "6": 1,
          "7": 117,
        },
        "9.2": {
          "1": 27,
          "2": 370,
        },
        "5.8": {
          "1": 135,
        },
        "30.8": {
          "6": 187,
          "7": 96,
        },
        "29.2": {
          "6": 576,
        },
        "8.2": {
          "1": 292,
          "2": 12,
        },
        "7.6": {
          "1": 248,
          "2": 1,
        },
        "7.2": {
          "1": 208,
        },
        "34.8": {
          "7": 25,
          "8": 8,
        },
        "6.8": {
          "1": 176,
        },
        "4.4": {
          "0": 30,
          "1": 50,
        },
        "31.8": {
          "6": 36,
          "7": 152,
        },
        "35.2": {
          "7": 24,
          "8": 7,
        },
        "4.6": {
          "0": 7,
          "1": 98,
        },
        "6.4": {
          "1": 159,
        },
        "5.4": {
          "1": 116,
        },
        "34.4": {
          "7": 29,
          "8": 7,
        },
        "35.6": {
          "7": 12,
          "8": 7,
        },
        "31.6": {
          "6": 56,
          "7": 143,
        },
        "34.6": {
          "7": 22,
          "8": 3,
        },
        "35.8": {
          "7": 8,
          "8": 6,
        },
        "32.4": {
          "6": 8,
          "7": 112,
        },
        "5.2": {
          "1": 111,
        },
        "33.8": {
          "7": 46,
        },
        "4.8": {
          "1": 98,
        },
        "5.6": {
          "1": 133,
        },
        "32.2": {
          "6": 7,
          "7": 137,
        },
        "33.4": {
          "7": 62,
        },
        "36.2": {
          "8": 7,
        },
        "39.4": {
          "8": 2,
          "9": 2,
        },
        "34.2": {
          "7": 42,
        },
        "37.2": {
          "8": 4,
        },
        "37.8": {
          "8": 5,
        },
        "37.4": {
          "8": 2,
        },
        "36.8": {
          "7": 1,
          "8": 7,
        },
        "39.2": {
          "8": 1,
        },
        "36.6": {
          "7": 1,
          "8": 4,
        },
        "37.6": {
          "8": 1,
        },
        "38.8": {
          "8": 1,
        },
      },
      KO: {
        "35": {
          "7": 59,
          "8": 19,
        },
        "36": {
          "7": 48,
          "8": 108,
        },
        "37": {
          "7": 9,
          "8": 274,
        },
        "38": {
          "8": 523,
          "9": 2,
        },
        "39": {
          "8": 780,
          "9": 121,
        },
        "40": {
          "8": 677,
          "9": 638,
        },
        "41": {
          "8": 276,
          "9": 1636,
        },
        "42": {
          "8": 31,
          "9": 2367,
          "10": 3,
        },
        "43": {
          "9": 2885,
          "10": 224,
        },
        "44": {
          "9": 2304,
          "10": 1140,
        },
        "45": {
          "9": 1061,
          "10": 2583,
        },
        "46": {
          "9": 265,
          "10": 3455,
          "11": 3,
        },
        "47": {
          "9": 9,
          "10": 3603,
          "11": 118,
        },
        "48": {
          "10": 2628,
          "11": 655,
        },
        "49": {
          "10": 1350,
          "11": 1499,
        },
        "50": {
          "10": 371,
          "11": 2015,
        },
        "51": {
          "10": 46,
          "11": 1783,
          "12": 30,
        },
        "52": {
          "10": 3,
          "11": 1264,
          "12": 174,
        },
        "53": {
          "11": 651,
          "12": 424,
        },
        "54": {
          "11": 215,
          "12": 507,
        },
        "55": {
          "11": 68,
          "12": 436,
        },
        "56": {
          "11": 3,
          "12": 338,
        },
        "57": {
          "12": 196,
        },
        "58": {
          "12": 131,
        },
        "59": {
          "12": 69,
        },
        "44.6": {
          "9": 1606,
          "10": 2058,
        },
        "49.6": {
          "10": 722,
          "11": 1807,
        },
        "40.4": {
          "8": 480,
          "9": 1023,
        },
        "46.4": {
          "9": 113,
          "10": 3611,
          "11": 15,
        },
        "42.6": {
          "9": 2756,
          "10": 61,
        },
        "43.2": {
          "9": 2691,
          "10": 302,
        },
        "46.8": {
          "9": 36,
          "10": 3511,
          "11": 60,
        },
        "47.4": {
          "10": 3250,
          "11": 274,
        },
        "41.8": {
          "8": 59,
          "9": 2242,
        },
        "46.2": {
          "9": 164,
          "10": 3474,
          "11": 4,
        },
        "40.2": {
          "8": 567,
          "9": 793,
        },
        "38.8": {
          "8": 760,
          "9": 65,
        },
        "45.6": {
          "9": 514,
          "10": 3330,
        },
        "42.4": {
          "8": 8,
          "9": 2618,
          "10": 28,
        },
        "48.6": {
          "10": 1905,
          "11": 1187,
        },
        "41.6": {
          "8": 93,
          "9": 2156,
        },
        "53.4": {
          "11": 440,
          "12": 465,
        },
        "41.4": {
          "8": 148,
          "9": 1991,
        },
        "44.8": {
          "9": 1328,
          "10": 2343,
        },
        "45.2": {
          "9": 868,
          "10": 2861,
        },
        "51.4": {
          "10": 18,
          "11": 1677,
          "12": 82,
        },
        "45.4": {
          "9": 669,
          "10": 3100,
        },
        "59.6": {
          "12": 585,
        },
        "43.6": {
          "9": 2633,
          "10": 695,
        },
        "54.8": {
          "11": 76,
          "12": 459,
        },
        "56.6": {
          "12": 263,
        },
        "57.4": {
          "12": 157,
        },
        "51.8": {
          "10": 6,
          "11": 1344,
          "12": 183,
        },
        "49.4": {
          "10": 830,
          "11": 1798,
        },
        "47.2": {
          "9": 4,
          "10": 3303,
          "11": 192,
        },
        "37.4": {
          "7": 1,
          "8": 359,
        },
        "52.4": {
          "11": 949,
          "12": 279,
        },
        "44.4": {
          "9": 1904,
          "10": 1705,
        },
        "40.8": {
          "8": 338,
          "9": 1325,
        },
        "45.8": {
          "9": 348,
          "10": 3419,
        },
        "39.8": {
          "8": 707,
          "9": 514,
        },
        "44.2": {
          "9": 2088,
          "10": 1447,
        },
        "53.6": {
          "11": 345,
          "12": 471,
        },
        "50.2": {
          "10": 262,
          "11": 2031,
          "12": 1,
        },
        "39.2": {
          "8": 711,
          "9": 176,
        },
        "36.8": {
          "7": 28,
          "8": 268,
        },
        "51.2": {
          "10": 33,
          "11": 1644,
          "12": 61,
        },
        "43.8": {
          "9": 2414,
          "10": 827,
        },
        "47.6": {
          "10": 3121,
          "11": 392,
        },
        "39.6": {
          "8": 704,
          "9": 388,
        },
        "48.2": {
          "10": 2343,
          "11": 880,
        },
        "55.4": {
          "11": 19,
          "12": 417,
        },
        "48.4": {
          "10": 2008,
          "11": 1090,
        },
        "50.6": {
          "10": 151,
          "11": 1913,
          "12": 8,
        },
        "51.6": {
          "10": 5,
          "11": 1518,
          "12": 107,
        },
        "54.4": {
          "11": 136,
          "12": 491,
        },
        "46.6": {
          "9": 55,
          "10": 3563,
          "11": 56,
        },
        "43.4": {
          "9": 2684,
          "10": 493,
        },
        "47.8": {
          "10": 2714,
          "11": 565,
        },
        "53.2": {
          "11": 519,
          "12": 412,
        },
        "57.8": {
          "12": 137,
        },
        "55.2": {
          "11": 32,
          "12": 419,
        },
        "38.6": {
          "8": 702,
          "9": 26,
        },
        "34.8": {
          "6": 7,
          "7": 445,
          "8": 30,
        },
        "35.6": {
          "7": 53,
          "8": 56,
        },
        "48.8": {
          "10": 1526,
          "11": 1333,
        },
        "38.4": {
          "8": 634,
          "9": 16,
        },
        "40.6": {
          "8": 402,
          "9": 1244,
        },
        "52.2": {
          "11": 1121,
          "12": 209,
        },
        "49.2": {
          "10": 1119,
          "11": 1636,
        },
        "54.2": {
          "11": 175,
          "12": 502,
        },
        "53.8": {
          "11": 255,
          "12": 509,
        },
        "49.8": {
          "10": 499,
          "11": 1919,
        },
        "37.6": {
          "7": 1,
          "8": 411,
        },
        "57.2": {
          "12": 193,
        },
        "50.4": {
          "10": 215,
          "11": 1901,
          "12": 4,
        },
        "56.8": {
          "11": 1,
          "12": 245,
        },
        "42.8": {
          "9": 2705,
          "10": 133,
        },
        "42.2": {
          "8": 15,
          "9": 2505,
          "10": 15,
        },
        "38.2": {
          "8": 571,
          "9": 4,
        },
        "35.4": {
          "7": 58,
          "8": 42,
        },
        "58.4": {
          "12": 97,
        },
        "36.6": {
          "7": 24,
          "8": 198,
        },
        "52.6": {
          "11": 855,
          "12": 308,
        },
        "37.8": {
          "8": 459,
        },
        "41.2": {
          "8": 211,
          "9": 1810,
        },
        "35.2": {
          "7": 51,
          "8": 39,
        },
        "50.8": {
          "10": 100,
          "11": 1847,
          "12": 15,
        },
        "39.4": {
          "8": 737,
          "9": 285,
        },
        "52.8": {
          "11": 753,
          "12": 364,
        },
        "37.2": {
          "7": 9,
          "8": 355,
        },
        "35.8": {
          "7": 70,
          "8": 87,
        },
        "56.2": {
          "11": 4,
          "12": 284,
        },
        "36.2": {
          "7": 41,
          "8": 146,
        },
        "55.8": {
          "11": 12,
          "12": 341,
        },
        "59.4": {
          "12": 76,
        },
        "54.6": {
          "11": 82,
          "12": 489,
        },
        "56.4": {
          "11": 2,
          "12": 263,
        },
        "36.4": {
          "7": 35,
          "8": 182,
        },
        "58.8": {
          "12": 109,
        },
        "58.6": {
          "12": 102,
        },
        "58.2": {
          "12": 129,
        },
        "55.6": {
          "11": 20,
          "12": 395,
        },
        "57.6": {
          "12": 166,
        },
        "59.2": {
          "12": 78,
        },
      },
      TD: {
        "4": {
          "0": 1168,
          "1": 3,
        },
        "5": {
          "1": 999,
        },
        "6": {
          "1": 2425,
        },
        "7": {
          "1": 4687,
        },
        "8": {
          "1": 6927,
          "2": 13,
        },
        "9": {
          "1": 2454,
          "2": 5622,
        },
        "10": {
          "2": 7103,
          "3": 1,
        },
        "11": {
          "2": 4941,
          "3": 1,
        },
        "12": {
          "2": 2695,
          "3": 8,
        },
        "13": {
          "2": 1057,
          "3": 183,
        },
        "14": {
          "2": 8,
          "3": 424,
        },
        "15": {
          "3": 116,
        },
        "7.8": {
          "1": 6634,
          "2": 15,
        },
        "11.6": {
          "2": 3440,
          "3": 10,
        },
        "11.8": {
          "2": 3121,
          "3": 3,
        },
        "10.6": {
          "2": 5783,
          "3": 1,
        },
        "6.6": {
          "1": 3799,
        },
        "9.4": {
          "1": 51,
          "2": 7829,
        },
        "8.2": {
          "1": 7250,
          "2": 33,
        },
        "9.8": {
          "2": 7422,
        },
        "11.2": {
          "2": 4356,
          "3": 2,
        },
        "12.2": {
          "2": 2314,
          "3": 7,
        },
        "8.6": {
          "1": 7039,
          "2": 720,
        },
        "9.2": {
          "1": 592,
          "2": 7365,
        },
        "6.4": {
          "1": 3300,
        },
        "10.2": {
          "2": 6784,
        },
        "10.4": {
          "2": 6299,
        },
        "5.4": {
          "1": 1423,
        },
        "7.2": {
          "1": 5306,
        },
        "10.8": {
          "2": 5296,
        },
        "15.2": {
          "3": 478,
          "4": 17,
        },
        "8.8": {
          "1": 5242,
          "2": 2778,
        },
        "9.6": {
          "2": 7659,
        },
        "13.4": {
          "2": 342,
          "3": 478,
        },
        "8.4": {
          "1": 7673,
          "2": 79,
        },
        "5.6": {
          "1": 1685,
        },
        "6.2": {
          "1": 2906,
        },
        "6.8": {
          "1": 4200,
        },
        "4.2": {
          "0": 366,
          "1": 13,
        },
        "12.4": {
          "2": 2004,
          "3": 12,
        },
        "7.4": {
          "1": 5564,
          "2": 2,
        },
        "5.8": {
          "1": 2100,
        },
        "5.2": {
          "1": 1162,
        },
        "12.8": {
          "2": 1352,
          "3": 66,
        },
        "11.4": {
          "2": 3947,
          "3": 1,
        },
        "12.6": {
          "2": 1597,
          "3": 24,
        },
        "7.6": {
          "1": 6252,
          "2": 5,
        },
        "13.2": {
          "2": 620,
          "3": 323,
        },
        "13.6": {
          "2": 149,
          "3": 494,
        },
        "4.8": {
          "1": 733,
        },
        "4.4": {
          "0": 312,
          "1": 177,
        },
        "13.8": {
          "2": 35,
          "3": 475,
        },
        "4.6": {
          "0": 51,
          "1": 534,
        },
        "14.8": {
          "3": 176,
        },
        "14.6": {
          "3": 229,
        },
        "14.4": {
          "3": 302,
        },
        "14.2": {
          "2": 1,
          "3": 356,
        },
      },
      KONF: {
        "1": {
          "0": 302,
        },
        "2": {
          "0": 709,
        },
        "3": {
          "0": 1450,
        },
        "4": {
          "0": 2777,
          "1": 18,
        },
        "5": {
          "1": 4391,
        },
        "6": {
          "1": 5577,
        },
        "7": {
          "1": 6318,
          "2": 1,
        },
        "8": {
          "1": 6179,
          "2": 10,
        },
        "9": {
          "1": 1521,
          "2": 3545,
        },
        "10": {
          "2": 3524,
        },
        "11": {
          "2": 1995,
        },
        "12": {
          "2": 986,
          "3": 1,
        },
        "13": {
          "2": 362,
          "3": 70,
        },
        "14": {
          "2": 5,
          "3": 175,
        },
        "15": {
          "3": 220,
          "4": 6,
        },
        "9.6": {
          "2": 4095,
        },
        "9.2": {
          "1": 368,
          "2": 4478,
        },
        "8.6": {
          "1": 5017,
          "2": 476,
        },
        "10.2": {
          "2": 3113,
        },
        "4.8": {
          "0": 5,
          "1": 3911,
        },
        "12.4": {
          "2": 735,
          "3": 3,
        },
        "8.4": {
          "1": 5746,
          "2": 54,
        },
        "12.8": {
          "2": 478,
          "3": 14,
        },
        "11.8": {
          "2": 1049,
        },
        "6.8": {
          "1": 6302,
        },
        "5.8": {
          "1": 5382,
        },
        "9.4": {
          "1": 31,
          "2": 4426,
        },
        "0.8": {
          "0": 1326,
        },
        "7.6": {
          "1": 6384,
          "2": 4,
        },
        "7.2": {
          "1": 6372,
        },
        "6.2": {
          "1": 5740,
        },
        "3.6": {
          "0": 2176,
          "1": 3,
        },
        "1.4": {
          "0": 444,
        },
        "5.6": {
          "1": 5230,
        },
        "7.4": {
          "1": 6412,
          "2": 1,
        },
        "10.8": {
          "2": 2193,
        },
        "4.2": {
          "0": 2960,
          "1": 78,
        },
        "5.2": {
          "1": 4456,
        },
        "7.8": {
          "1": 6368,
          "2": 8,
        },
        "3.4": {
          "0": 1921,
        },
        "8.2": {
          "1": 6155,
          "2": 30,
        },
        "3.8": {
          "0": 2463,
          "1": 3,
        },
        "12.2": {
          "2": 825,
          "3": 2,
        },
        "6.6": {
          "1": 6396,
        },
        "2.6": {
          "0": 1168,
        },
        "4.6": {
          "0": 360,
          "1": 3229,
        },
        "4.4": {
          "0": 2072,
          "1": 1188,
        },
        "6.4": {
          "1": 6165,
        },
        "5.4": {
          "1": 4899,
        },
        "8.8": {
          "1": 3508,
          "2": 1771,
        },
        "9.8": {
          "2": 3693,
        },
        "10.4": {
          "2": 2785,
        },
        "10.6": {
          "2": 2516,
        },
        "11.6": {
          "2": 1374,
        },
        "3.2": {
          "0": 1756,
        },
        "11.2": {
          "2": 1673,
          "3": 1,
        },
        "1.6": {
          "0": 487,
        },
        "13.6": {
          "2": 36,
          "3": 202,
        },
        "1.2": {
          "0": 376,
        },
        "1.8": {
          "0": 570,
        },
        "2.8": {
          "0": 1284,
        },
        "14.8": {
          "3": 80,
        },
        "14.4": {
          "3": 72,
        },
        "11.4": {
          "2": 1543,
          "3": 1,
        },
        "14.2": {
          "3": 139,
        },
        "2.2": {
          "0": 832,
        },
        "2.4": {
          "0": 992,
        },
        "13.8": {
          "2": 13,
          "3": 204,
        },
        "12.6": {
          "2": 552,
          "3": 9,
        },
        "13.4": {
          "2": 109,
          "3": 168,
        },
        "13.2": {
          "2": 215,
          "3": 106,
        },
        "14.6": {
          "3": 77,
        },
      },
      LEWICA: {
        "12": {
          "2": 524,
          "3": 3,
        },
        "13": {
          "2": 1071,
          "3": 182,
        },
        "14": {
          "2": 34,
          "3": 2465,
        },
        "15": {
          "3": 4015,
          "4": 1,
        },
        "16": {
          "3": 5437,
          "4": 19,
        },
        "17": {
          "3": 6250,
          "4": 154,
        },
        "18": {
          "3": 1835,
          "4": 4212,
        },
        "19": {
          "3": 1,
          "4": 5136,
          "5": 6,
        },
        "20": {
          "4": 3724,
          "5": 11,
        },
        "21": {
          "4": 2293,
          "5": 30,
          "6": 1,
        },
        "22": {
          "4": 831,
          "5": 450,
        },
        "23": {
          "4": 45,
          "5": 641,
          "6": 1,
        },
        "24": {
          "5": 327,
        },
        "25": {
          "5": 655,
          "6": 133,
          "7": 3,
        },
        "16.6": {
          "3": 5922,
          "4": 27,
        },
        "23.6": {
          "5": 439,
          "6": 2,
        },
        "19.8": {
          "4": 3949,
          "5": 20,
        },
        "17.4": {
          "3": 5118,
          "4": 1157,
          "5": 1,
        },
        "14.8": {
          "3": 3732,
          "4": 1,
        },
        "11.2": {
          "1": 24,
          "2": 1000,
        },
        "20.6": {
          "4": 2827,
          "5": 19,
        },
        "18.8": {
          "3": 26,
          "4": 5266,
          "5": 5,
        },
        "16.8": {
          "3": 6049,
          "4": 61,
        },
        "13.4": {
          "2": 709,
          "3": 981,
        },
        "17.2": {
          "3": 5713,
          "4": 526,
        },
        "17.6": {
          "3": 4186,
          "4": 2091,
          "5": 1,
        },
        "20.8": {
          "4": 2565,
          "5": 24,
        },
        "19.2": {
          "4": 4835,
          "5": 5,
        },
        "17.8": {
          "3": 2955,
          "4": 3381,
          "5": 1,
        },
        "19.4": {
          "4": 4552,
          "5": 7,
        },
        "15.8": {
          "3": 5148,
          "4": 8,
        },
        "22.4": {
          "4": 352,
          "5": 599,
          "6": 1,
        },
        "16.2": {
          "3": 5622,
          "4": 10,
        },
        "20.4": {
          "4": 3134,
          "5": 12,
        },
        "18.4": {
          "3": 449,
          "4": 5471,
          "5": 1,
        },
        "14.2": {
          "2": 2,
          "3": 2752,
          "4": 1,
        },
        "15.6": {
          "3": 4866,
          "4": 6,
        },
        "14.4": {
          "3": 3029,
          "4": 1,
        },
        "11.8": {
          "2": 437,
          "3": 4,
        },
        "13.8": {
          "2": 160,
          "3": 1965,
        },
        "15.4": {
          "3": 4655,
          "4": 5,
        },
        "20.2": {
          "4": 3412,
          "5": 17,
        },
        "18.6": {
          "3": 125,
          "4": 5497,
          "5": 2,
        },
        "18.2": {
          "3": 991,
          "4": 4974,
          "5": 2,
        },
        "21.2": {
          "4": 2029,
          "5": 56,
        },
        "19.6": {
          "4": 4339,
          "5": 7,
        },
        "21.8": {
          "4": 1137,
          "5": 309,
          "6": 3,
        },
        "16.4": {
          "3": 5784,
          "4": 37,
          "5": 1,
        },
        "12.8": {
          "2": 1053,
          "3": 41,
        },
        "22.6": {
          "4": 212,
          "5": 658,
          "6": 1,
        },
        "12.2": {
          "2": 676,
          "3": 3,
        },
        "13.2": {
          "2": 929,
          "3": 496,
        },
        "13.6": {
          "2": 384,
          "3": 1562,
          "4": 1,
        },
        "23.4": {
          "4": 5,
          "5": 504,
        },
        "14.6": {
          "3": 3353,
        },
        "15.2": {
          "3": 4326,
          "4": 5,
        },
        "12.4": {
          "2": 772,
          "3": 3,
        },
        "22.2": {
          "4": 623,
          "5": 573,
          "6": 1,
        },
        "11.6": {
          "2": 341,
        },
        "24.4": {
          "5": 224,
          "6": 2,
        },
        "12.6": {
          "2": 859,
          "3": 9,
        },
        "23.2": {
          "4": 18,
          "5": 571,
          "6": 1,
        },
        "21.6": {
          "4": 1510,
          "5": 195,
        },
        "23.8": {
          "5": 349,
          "6": 3,
        },
        "22.8": {
          "4": 118,
          "5": 689,
          "6": 1,
        },
        "21.4": {
          "4": 1846,
          "5": 110,
        },
        "24.2": {
          "5": 272,
          "6": 1,
        },
        "24.8": {
          "5": 155,
          "6": 1,
        },
        "11.4": {
          "2": 278,
        },
        "24.6": {
          "5": 187,
        },
      },
    },
    minimumMandatow: {
      KO: 8,
      TD: 0,
      LEWICA: 2,
    },
    zagrozoneMandaty: {
      KO: 2218,
      TD: 3796,
      LEWICA: 3273,
    },
    potencjalneMandaty: {
      KO: 59981,
      TD: 39396,
      LEWICA: 40068,
    },
    ostatnieMandaty: {
      KO: 65879,
      TD: 18897,
      LEWICA: 33004,
    },
    dawcyGlosow: {
      KO: 5465,
      TD: 9410,
      LEWICA: 8188,
    },
  },
  "Warszawa II": {
    procentyWOkreguSrednia: {
      PIS: 33.4484644782049,
      KO: 32.24008219692681,
      TD: 12.65159663441028,
      KONF: 8.579548433606751,
      LEWICA: 13.08030825685126,
    },
    odchylenieWOkregu: {
      PIS: 3.8778572732326237,
      KO: 1.851687164444039,
      TD: 2.013774734692939,
      LEWICA: 2.163835984426816,
      KONF: 0.9007729760778697,
    },
    wykresRozkladu: {
      "31.3%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "28.7%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "20.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "12.6%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "2.5%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "1.3%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.9%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "0.1%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "62.1%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "33%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
      "4.8%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "24": {
          "3": 44,
        },
        "25": {
          "3": 121,
        },
        "26": {
          "3": 223,
        },
        "27": {
          "3": 427,
          "4": 62,
        },
        "28": {
          "3": 418,
          "4": 533,
        },
        "29": {
          "3": 165,
          "4": 1404,
        },
        "30": {
          "3": 31,
          "4": 2588,
        },
        "31": {
          "4": 3750,
        },
        "32": {
          "4": 4708,
        },
        "33": {
          "4": 5159,
          "5": 299,
        },
        "34": {
          "4": 3676,
          "5": 1831,
        },
        "35": {
          "4": 1755,
          "5": 3209,
        },
        "36": {
          "4": 514,
          "5": 3312,
        },
        "37": {
          "4": 53,
          "5": 2493,
        },
        "38": {
          "4": 2,
          "5": 1532,
          "6": 2,
        },
        "39": {
          "5": 740,
          "6": 6,
        },
        "40": {
          "5": 332,
          "6": 18,
        },
        "41": {
          "5": 119,
          "6": 17,
        },
        "42": {
          "5": 20,
          "6": 17,
        },
        "43": {
          "5": 2,
          "6": 5,
        },
        "44": {
          "6": 4,
        },
        "45": {
          "6": 4,
        },
        "30.8": {
          "4": 3600,
        },
        "34.4": {
          "4": 2828,
          "5": 2448,
        },
        "31.4": {
          "4": 4354,
        },
        "32.2": {
          "4": 5058,
          "5": 5,
        },
        "35.2": {
          "4": 1401,
          "5": 3229,
        },
        "30.6": {
          "3": 2,
          "4": 3282,
        },
        "31.6": {
          "4": 4503,
        },
        "34.8": {
          "4": 2132,
          "5": 3041,
        },
        "37.2": {
          "4": 30,
          "5": 2337,
        },
        "39.2": {
          "5": 676,
          "6": 12,
        },
        "29.4": {
          "3": 96,
          "4": 1931,
        },
        "33.8": {
          "4": 4130,
          "5": 1580,
        },
        "36.2": {
          "4": 332,
          "5": 3233,
        },
        "38.4": {
          "5": 1190,
          "6": 5,
        },
        "37.4": {
          "4": 18,
          "5": 2161,
        },
        "36.6": {
          "4": 161,
          "5": 2924,
        },
        "33.2": {
          "4": 5018,
          "5": 505,
        },
        "29.2": {
          "3": 148,
          "4": 1656,
        },
        "38.2": {
          "5": 1335,
          "6": 1,
        },
        "37.6": {
          "4": 6,
          "5": 1992,
        },
        "32.8": {
          "4": 5217,
          "5": 158,
        },
        "32.4": {
          "4": 5088,
          "5": 23,
        },
        "35.4": {
          "4": 1142,
          "5": 3399,
        },
        "29.6": {
          "3": 77,
          "4": 2193,
        },
        "28.2": {
          "3": 395,
          "4": 663,
        },
        "36.4": {
          "4": 233,
          "5": 3107,
        },
        "31.2": {
          "4": 4022,
        },
        "36.8": {
          "4": 111,
          "5": 2893,
        },
        "33.6": {
          "4": 4485,
          "5": 1147,
        },
        "35.6": {
          "4": 849,
          "5": 3387,
        },
        "31.8": {
          "4": 4573,
        },
        "30.4": {
          "3": 5,
          "4": 3148,
        },
        "38.6": {
          "5": 1031,
          "6": 10,
        },
        "30.2": {
          "3": 11,
          "4": 2846,
        },
        "27.8": {
          "3": 497,
          "4": 358,
        },
        "34.6": {
          "4": 2486,
          "5": 2697,
        },
        "33.4": {
          "4": 4803,
          "5": 820,
        },
        "39.8": {
          "5": 366,
          "6": 15,
        },
        "28.6": {
          "3": 279,
          "4": 1035,
        },
        "35.8": {
          "4": 664,
          "5": 3434,
        },
        "38.8": {
          "5": 873,
          "6": 2,
        },
        "28.4": {
          "3": 322,
          "4": 900,
        },
        "32.6": {
          "4": 5225,
          "5": 60,
        },
        "26.6": {
          "3": 375,
          "4": 16,
        },
        "37.8": {
          "4": 1,
          "5": 1728,
        },
        "28.8": {
          "3": 203,
          "4": 1241,
        },
        "41.4": {
          "5": 65,
          "6": 23,
        },
        "34.2": {
          "4": 3289,
          "5": 2228,
        },
        "27.6": {
          "3": 511,
          "4": 194,
        },
        "26.2": {
          "3": 241,
          "4": 4,
        },
        "27.4": {
          "3": 481,
          "4": 125,
        },
        "29.8": {
          "3": 59,
          "4": 2419,
        },
        "26.8": {
          "3": 419,
          "4": 35,
        },
        "39.4": {
          "5": 593,
          "6": 11,
        },
        "40.6": {
          "5": 184,
          "6": 16,
        },
        "23.8": {
          "2": 16,
          "3": 176,
        },
        "25.6": {
          "3": 180,
        },
        "39.6": {
          "5": 464,
          "6": 12,
        },
        "27.2": {
          "3": 523,
          "4": 90,
        },
        "24.8": {
          "3": 87,
        },
        "25.4": {
          "3": 157,
        },
        "26.4": {
          "3": 308,
          "4": 12,
        },
        "24.6": {
          "3": 69,
        },
        "40.2": {
          "5": 270,
          "6": 13,
        },
        "42.6": {
          "5": 13,
          "6": 13,
        },
        "40.8": {
          "5": 123,
          "6": 15,
        },
        "25.2": {
          "3": 114,
        },
        "25.8": {
          "3": 222,
          "4": 2,
        },
        "40.4": {
          "5": 199,
          "6": 27,
        },
        "41.2": {
          "5": 86,
          "6": 23,
        },
        "24.4": {
          "3": 54,
        },
        "42.4": {
          "5": 8,
          "6": 15,
        },
        "24.2": {
          "3": 62,
        },
        "42.2": {
          "5": 25,
          "6": 13,
        },
        "41.8": {
          "5": 32,
          "6": 15,
        },
        "43.8": {
          "5": 1,
          "6": 3,
        },
        "41.6": {
          "5": 46,
          "6": 23,
        },
        "42.8": {
          "5": 1,
          "6": 6,
        },
        "43.2": {
          "5": 1,
          "6": 1,
        },
        "43.4": {
          "5": 1,
          "6": 6,
        },
        "43.6": {
          "5": 1,
          "6": 2,
        },
        "44.2": {
          "5": 1,
          "6": 1,
        },
        "44.6": {
          "6": 2,
        },
        "44.8": {
          "6": 1,
        },
      },
      KO: {
        "28": {
          "3": 396,
          "4": 370,
        },
        "29": {
          "3": 221,
          "4": 1969,
        },
        "30": {
          "3": 35,
          "4": 4332,
        },
        "31": {
          "4": 6667,
        },
        "32": {
          "4": 7736,
          "5": 3,
        },
        "33": {
          "4": 6754,
          "5": 331,
        },
        "34": {
          "4": 3695,
          "5": 1606,
        },
        "35": {
          "4": 1219,
          "5": 1879,
        },
        "36": {
          "4": 176,
          "5": 1324,
        },
        "37": {
          "4": 16,
          "5": 609,
        },
        "34.8": {
          "4": 1637,
          "5": 1922,
        },
        "33.2": {
          "4": 6438,
          "5": 472,
        },
        "32.4": {
          "4": 7909,
          "5": 37,
        },
        "34.6": {
          "4": 1991,
          "5": 1851,
        },
        "32.8": {
          "4": 7465,
          "5": 163,
        },
        "28.8": {
          "3": 279,
          "4": 1503,
        },
        "31.6": {
          "4": 7574,
          "5": 2,
        },
        "32.2": {
          "4": 7751,
          "5": 13,
        },
        "31.2": {
          "4": 7021,
        },
        "34.2": {
          "4": 3059,
          "5": 1766,
        },
        "30.6": {
          "3": 1,
          "4": 5759,
        },
        "28.4": {
          "3": 360,
          "4": 842,
        },
        "35.8": {
          "4": 291,
          "5": 1499,
        },
        "29.4": {
          "3": 126,
          "4": 2761,
        },
        "28.6": {
          "3": 337,
          "4": 1133,
        },
        "37.4": {
          "4": 6,
          "5": 420,
        },
        "34.4": {
          "4": 2524,
          "5": 1824,
        },
        "32.6": {
          "4": 7589,
          "5": 88,
        },
        "28.2": {
          "3": 403,
          "4": 604,
        },
        "29.8": {
          "3": 50,
          "4": 3763,
        },
        "30.4": {
          "3": 5,
          "4": 5419,
        },
        "33.6": {
          "4": 4950,
          "5": 998,
        },
        "33.4": {
          "4": 5695,
          "5": 770,
        },
        "31.4": {
          "4": 7198,
        },
        "29.6": {
          "3": 86,
          "4": 3380,
        },
        "30.8": {
          "4": 6350,
        },
        "30.2": {
          "3": 13,
          "4": 4784,
        },
        "33.8": {
          "4": 4465,
          "5": 1324,
        },
        "35.4": {
          "4": 621,
          "5": 1659,
        },
        "35.2": {
          "4": 869,
          "5": 1875,
        },
        "31.8": {
          "4": 7737,
          "5": 1,
        },
        "35.6": {
          "4": 437,
          "5": 1655,
        },
        "29.2": {
          "3": 162,
          "4": 2301,
        },
        "36.2": {
          "4": 113,
          "5": 1158,
        },
        "36.6": {
          "4": 28,
          "5": 911,
        },
        "36.8": {
          "4": 25,
          "5": 696,
        },
        "37.8": {
          "4": 1,
          "5": 1321,
          "6": 31,
        },
        "37.2": {
          "4": 8,
          "5": 491,
        },
        "27.6": {
          "3": 1541,
          "4": 347,
        },
        "36.4": {
          "4": 60,
          "5": 954,
        },
        "37.6": {
          "4": 3,
          "5": 352,
        },
        "27.8": {
          "3": 373,
          "4": 262,
        },
      },
      TD: {
        "8": {
          "1": 414,
        },
        "9": {
          "1": 1232,
        },
        "10": {
          "1": 3114,
        },
        "11": {
          "1": 5830,
        },
        "12": {
          "1": 8120,
        },
        "13": {
          "1": 8293,
          "2": 61,
        },
        "14": {
          "1": 2867,
          "2": 3771,
        },
        "15": {
          "1": 64,
          "2": 3807,
        },
        "16": {
          "2": 1651,
        },
        "17": {
          "2": 577,
        },
        "18": {
          "2": 145,
        },
        "9.4": {
          "1": 1811,
        },
        "11.4": {
          "1": 6863,
        },
        "13.4": {
          "1": 6927,
          "2": 936,
        },
        "12.8": {
          "1": 8519,
          "2": 8,
        },
        "15.8": {
          "2": 1979,
        },
        "15.4": {
          "2": 2819,
        },
        "14.6": {
          "1": 433,
          "2": 4412,
        },
        "10.6": {
          "1": 4660,
        },
        "11.6": {
          "1": 7244,
        },
        "8.6": {
          "1": 827,
        },
        "14.4": {
          "1": 977,
          "2": 4527,
        },
        "13.6": {
          "1": 5666,
          "2": 1910,
        },
        "8.8": {
          "1": 1031,
        },
        "11.2": {
          "1": 6157,
        },
        "13.8": {
          "1": 4117,
          "2": 2921,
        },
        "12.2": {
          "1": 8369,
        },
        "11.8": {
          "1": 7744,
        },
        "9.6": {
          "1": 2238,
        },
        "13.2": {
          "1": 7965,
          "2": 305,
        },
        "12.6": {
          "1": 8542,
        },
        "10.8": {
          "1": 5282,
        },
        "12.4": {
          "1": 8471,
        },
        "14.8": {
          "1": 182,
          "2": 4220,
        },
        "10.4": {
          "1": 4094,
        },
        "15.2": {
          "1": 5,
          "2": 3341,
        },
        "14.2": {
          "1": 1763,
          "2": 4260,
        },
        "16.2": {
          "2": 1406,
        },
        "10.2": {
          "1": 3531,
        },
        "15.6": {
          "2": 2390,
        },
        "9.8": {
          "1": 2735,
        },
        "8.4": {
          "1": 682,
        },
        "16.4": {
          "2": 1154,
        },
        "16.6": {
          "2": 901,
        },
        "9.2": {
          "1": 1604,
        },
        "7.6": {
          "0": 327,
          "1": 623,
        },
        "7.8": {
          "1": 327,
        },
        "8.2": {
          "1": 530,
        },
        "17.2": {
          "2": 423,
        },
        "18.6": {
          "2": 205,
          "3": 4,
        },
        "16.8": {
          "2": 771,
        },
        "17.8": {
          "2": 202,
        },
        "18.4": {
          "2": 84,
        },
        "17.6": {
          "2": 206,
        },
        "17.4": {
          "2": 329,
        },
        "18.2": {
          "2": 95,
        },
      },
      KONF: {
        "7": {
          "0": 1003,
          "1": 2844,
        },
        "8": {
          "1": 14355,
        },
        "9": {
          "1": 14961,
        },
        "10": {
          "1": 5260,
        },
        "11": {
          "1": 798,
        },
        "9.6": {
          "1": 9319,
        },
        "8.6": {
          "1": 16944,
        },
        "9.8": {
          "1": 7196,
        },
        "7.8": {
          "1": 12332,
        },
        "9.4": {
          "1": 11604,
        },
        "7.6": {
          "0": 11,
          "1": 10130,
        },
        "7.4": {
          "0": 108,
          "1": 7778,
        },
        "9.2": {
          "1": 13364,
        },
        "8.2": {
          "1": 15506,
        },
        "8.4": {
          "1": 16891,
        },
        "6.6": {
          "0": 1401,
          "1": 238,
        },
        "8.8": {
          "1": 16530,
        },
        "7.2": {
          "0": 432,
          "1": 5329,
        },
        "6.8": {
          "0": 1450,
          "1": 1148,
        },
        "10.8": {
          "1": 1275,
        },
        "10.2": {
          "1": 3947,
        },
        "10.6": {
          "1": 1853,
        },
        "6.4": {
          "0": 2215,
          "1": 24,
        },
        "11.2": {
          "1": 1057,
        },
        "10.4": {
          "1": 2697,
        },
      },
      LEWICA: {
        "8": {
          "1": 326,
        },
        "9": {
          "1": 1057,
        },
        "10": {
          "1": 2461,
        },
        "11": {
          "1": 4527,
        },
        "12": {
          "1": 6846,
        },
        "13": {
          "1": 7963,
          "2": 77,
        },
        "14": {
          "1": 2967,
          "2": 4360,
        },
        "15": {
          "1": 56,
          "2": 4961,
        },
        "16": {
          "2": 2622,
        },
        "17": {
          "2": 1138,
        },
        "18": {
          "2": 371,
        },
        "19": {
          "2": 102,
        },
        "15.2": {
          "1": 8,
          "2": 4564,
        },
        "12.8": {
          "1": 8172,
          "2": 8,
        },
        "12.2": {
          "1": 7220,
        },
        "12.6": {
          "1": 7602,
        },
        "13.2": {
          "1": 7555,
          "2": 358,
        },
        "10.2": {
          "1": 2813,
        },
        "13.8": {
          "1": 4335,
          "2": 3398,
        },
        "15.4": {
          "2": 4012,
        },
        "14.8": {
          "1": 179,
          "2": 5511,
        },
        "14.4": {
          "1": 973,
          "2": 5525,
        },
        "13.4": {
          "1": 6942,
          "2": 1115,
        },
        "12.4": {
          "1": 7722,
        },
        "11.8": {
          "1": 6383,
        },
        "9.8": {
          "1": 2054,
        },
        "10.6": {
          "1": 3723,
        },
        "11.6": {
          "1": 5968,
        },
        "11.2": {
          "1": 5051,
        },
        "14.6": {
          "1": 501,
          "2": 5578,
        },
        "10.8": {
          "1": 4165,
        },
        "14.2": {
          "1": 1739,
          "2": 5081,
        },
        "18.4": {
          "2": 210,
        },
        "16.8": {
          "2": 1412,
        },
        "15.6": {
          "2": 3683,
        },
        "13.6": {
          "1": 5477,
          "2": 2163,
        },
        "11.4": {
          "1": 5756,
        },
        "9.4": {
          "1": 1449,
        },
        "15.8": {
          "2": 3019,
        },
        "10.4": {
          "1": 3150,
        },
        "8.2": {
          "1": 387,
        },
        "9.6": {
          "1": 1694,
        },
        "8.4": {
          "1": 507,
        },
        "16.2": {
          "2": 2214,
        },
        "8.6": {
          "1": 651,
        },
        "18.2": {
          "2": 267,
        },
        "16.4": {
          "2": 1958,
        },
        "17.8": {
          "2": 451,
        },
        "9.2": {
          "1": 1219,
        },
        "7.6": {
          "0": 290,
          "1": 508,
        },
        "18.6": {
          "2": 170,
        },
        "8.8": {
          "1": 789,
        },
        "16.6": {
          "2": 1591,
        },
        "19.4": {
          "2": 52,
        },
        "17.2": {
          "2": 897,
        },
        "17.4": {
          "2": 755,
        },
        "7.8": {
          "1": 263,
        },
        "19.2": {
          "2": 61,
        },
        "17.6": {
          "2": 596,
        },
        "19.6": {
          "2": 121,
          "3": 19,
        },
        "18.8": {
          "2": 132,
        },
      },
    },
    minimumMandatow: {
      KO: 3,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2535,
      TD: 2395,
      LEWICA: 3072,
    },
    potencjalneMandaty: {
      KO: 45209,
      TD: 59597,
      LEWICA: 62658,
    },
    ostatnieMandaty: {
      KO: 37405,
      TD: 30313,
      LEWICA: 37654,
    },
    dawcyGlosow: {
      KO: 6525,
      TD: 5211,
      LEWICA: 6652,
    },
  },
  Opole: {
    procentyWOkreguSrednia: {
      PIS: 34.06816871616592,
      KO: 29.767449262643876,
      TD: 16.0665832171703,
      KONF: 9.036689736456596,
      LEWICA: 11.061109067563304,
    },
    odchylenieWOkregu: {
      PIS: 2.6182463944023135,
      KO: 2.2540670268352816,
      TD: 2.013774734692939,
      LEWICA: 0.714822189759465,
      KONF: 0.9350955578897755,
    },
    wykresRozkladu: {
      "59.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "25%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "10.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "5.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "0%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "84.7%": {
        DEMO: 6,
        NIEDEMO: 5,
      },
      "10.2%": {
        DEMO: 7,
        NIEDEMO: 4,
      },
      "5.1%": {
        DEMO: 5,
        NIEDEMO: 6,
      },
    },
    histogram: {
      PIS: {
        "28": {
          "3": 99,
        },
        "29": {
          "3": 378,
          "4": 23,
        },
        "30": {
          "3": 866,
          "4": 243,
        },
        "31": {
          "3": 924,
          "4": 1693,
        },
        "32": {
          "3": 188,
          "4": 4538,
        },
        "33": {
          "4": 6896,
        },
        "34": {
          "4": 7880,
        },
        "35": {
          "4": 7227,
          "5": 11,
        },
        "36": {
          "4": 4785,
          "5": 210,
        },
        "37": {
          "4": 1953,
          "5": 887,
        },
        "38": {
          "4": 418,
          "5": 822,
        },
        "39": {
          "4": 52,
          "5": 352,
        },
        "40": {
          "4": 2,
          "5": 121,
        },
        "41": {
          "5": 30,
        },
        "42": {
          "5": 11,
        },
        "33.8": {
          "4": 7705,
        },
        "35.4": {
          "4": 6246,
          "5": 51,
        },
        "33.4": {
          "4": 7461,
        },
        "38.4": {
          "4": 189,
          "5": 621,
        },
        "36.8": {
          "4": 2403,
          "5": 798,
        },
        "32.6": {
          "3": 7,
          "4": 5837,
        },
        "33.6": {
          "4": 7517,
        },
        "32.4": {
          "3": 31,
          "4": 5671,
        },
        "36.2": {
          "4": 4040,
          "5": 352,
        },
        "35.2": {
          "4": 6637,
          "5": 38,
        },
        "34.8": {
          "4": 7247,
          "5": 5,
        },
        "31.4": {
          "3": 689,
          "4": 2620,
        },
        "34.6": {
          "4": 7535,
          "5": 4,
        },
        "33.2": {
          "4": 7079,
        },
        "27.6": {
          "3": 250,
        },
        "31.6": {
          "3": 496,
          "4": 3179,
        },
        "32.8": {
          "3": 1,
          "4": 6413,
        },
        "36.6": {
          "4": 3081,
          "5": 644,
        },
        "37.6": {
          "4": 752,
          "5": 951,
        },
        "34.2": {
          "4": 7726,
        },
        "32.2": {
          "3": 83,
          "4": 4866,
        },
        "35.6": {
          "4": 5946,
          "5": 101,
        },
        "37.4": {
          "4": 1016,
          "5": 978,
        },
        "35.8": {
          "4": 5228,
          "5": 142,
        },
        "34.4": {
          "4": 7697,
        },
        "31.2": {
          "3": 816,
          "4": 2020,
        },
        "38.8": {
          "4": 74,
          "5": 416,
        },
        "30.2": {
          "3": 954,
          "4": 374,
        },
        "36.4": {
          "4": 3702,
          "5": 464,
        },
        "29.6": {
          "3": 640,
          "4": 99,
        },
        "38.2": {
          "4": 271,
          "5": 725,
        },
        "30.8": {
          "3": 1002,
          "4": 1127,
        },
        "30.4": {
          "3": 978,
          "4": 590,
        },
        "29.2": {
          "3": 453,
          "4": 34,
        },
        "31.8": {
          "3": 330,
          "4": 3896,
        },
        "29.8": {
          "3": 709,
          "4": 176,
        },
        "37.8": {
          "4": 610,
          "5": 850,
        },
        "28.8": {
          "3": 276,
          "4": 10,
        },
        "30.6": {
          "3": 1057,
          "4": 872,
        },
        "27.8": {
          "3": 91,
        },
        "37.2": {
          "4": 1496,
          "5": 947,
        },
        "29.4": {
          "3": 555,
          "4": 62,
        },
        "39.6": {
          "4": 12,
          "5": 174,
        },
        "39.2": {
          "4": 36,
          "5": 280,
        },
        "39.8": {
          "4": 8,
          "5": 151,
        },
        "38.6": {
          "4": 129,
          "5": 526,
        },
        "40.2": {
          "4": 2,
          "5": 77,
        },
        "39.4": {
          "4": 21,
          "5": 236,
        },
        "40.8": {
          "5": 33,
        },
        "28.4": {
          "3": 189,
          "4": 1,
        },
        "28.2": {
          "3": 124,
          "4": 1,
        },
        "40.6": {
          "5": 42,
        },
        "28.6": {
          "3": 242,
          "4": 7,
        },
        "41.2": {
          "5": 14,
        },
        "40.4": {
          "5": 54,
        },
        "41.4": {
          "5": 11,
        },
        "41.6": {
          "5": 8,
          "6": 1,
        },
        "41.8": {
          "5": 3,
        },
      },
      KO: {
        "25": {
          "3": 381,
        },
        "26": {
          "3": 1167,
        },
        "27": {
          "3": 2947,
        },
        "28": {
          "3": 5399,
          "4": 14,
        },
        "29": {
          "3": 7127,
          "4": 580,
        },
        "30": {
          "3": 5726,
          "4": 2569,
        },
        "31": {
          "3": 2642,
          "4": 4118,
        },
        "32": {
          "3": 280,
          "4": 3869,
        },
        "33": {
          "4": 1975,
        },
        "34": {
          "4": 646,
        },
        "35": {
          "4": 199,
          "5": 1,
        },
        "36": {
          "4": 35,
          "5": 3,
        },
        "29.6": {
          "3": 6817,
          "4": 1740,
        },
        "28.2": {
          "3": 5874,
          "4": 32,
        },
        "31.4": {
          "3": 1482,
          "4": 4486,
        },
        "28.6": {
          "3": 6654,
          "4": 178,
        },
        "26.2": {
          "3": 1431,
        },
        "26.6": {
          "3": 2051,
        },
        "31.6": {
          "3": 976,
          "4": 4365,
        },
        "28.4": {
          "3": 6426,
          "4": 74,
        },
        "30.6": {
          "3": 3970,
          "4": 3659,
        },
        "29.4": {
          "3": 7093,
          "4": 1277,
        },
        "29.8": {
          "3": 6214,
          "4": 2152,
        },
        "28.8": {
          "3": 6973,
          "4": 329,
        },
        "30.8": {
          "3": 3312,
          "4": 3921,
        },
        "26.8": {
          "3": 2516,
        },
        "29.2": {
          "3": 7326,
          "4": 859,
        },
        "35.2": {
          "4": 130,
        },
        "27.8": {
          "3": 4914,
          "4": 7,
        },
        "27.6": {
          "3": 4326,
          "4": 1,
        },
        "27.2": {
          "3": 3317,
        },
        "31.2": {
          "3": 2107,
          "4": 4281,
        },
        "32.2": {
          "3": 115,
          "4": 3593,
        },
        "27.4": {
          "3": 3891,
          "4": 1,
        },
        "26.4": {
          "3": 1689,
        },
        "33.2": {
          "4": 1627,
        },
        "30.4": {
          "3": 4713,
          "4": 3206,
        },
        "24.8": {
          "3": 239,
        },
        "25.4": {
          "3": 616,
        },
        "25.8": {
          "3": 880,
        },
        "30.2": {
          "3": 5322,
          "4": 2947,
        },
        "32.4": {
          "3": 42,
          "4": 3312,
        },
        "34.6": {
          "4": 330,
          "5": 1,
        },
        "31.8": {
          "3": 553,
          "4": 4120,
        },
        "32.8": {
          "3": 1,
          "4": 2331,
        },
        "24.2": {
          "2": 47,
          "3": 338,
        },
        "24.6": {
          "3": 206,
        },
        "32.6": {
          "3": 6,
          "4": 2717,
        },
        "33.4": {
          "4": 1299,
        },
        "33.8": {
          "4": 870,
        },
        "34.4": {
          "4": 380,
        },
        "33.6": {
          "4": 1138,
        },
        "25.6": {
          "3": 761,
        },
        "34.2": {
          "4": 572,
        },
        "34.8": {
          "4": 251,
          "5": 2,
        },
        "25.2": {
          "3": 449,
        },
        "24.4": {
          "3": 171,
        },
        "35.6": {
          "4": 65,
        },
        "36.6": {
          "4": 35,
          "5": 19,
        },
        "35.4": {
          "4": 91,
          "5": 2,
        },
        "36.4": {
          "4": 14,
          "5": 2,
        },
        "36.2": {
          "4": 28,
          "5": 2,
        },
        "35.8": {
          "4": 58,
        },
      },
      TD: {
        "11": {
          "0": 1,
          "1": 712,
        },
        "12": {
          "1": 711,
        },
        "13": {
          "1": 2078,
        },
        "14": {
          "1": 4469,
          "2": 14,
        },
        "15": {
          "1": 4112,
          "2": 3353,
        },
        "16": {
          "1": 215,
          "2": 8628,
        },
        "17": {
          "2": 7885,
        },
        "18": {
          "2": 4941,
        },
        "19": {
          "2": 2388,
        },
        "20": {
          "2": 743,
        },
        "21": {
          "2": 178,
          "3": 2,
        },
        "22": {
          "2": 34,
          "3": 2,
        },
        "16.6": {
          "2": 8660,
        },
        "17.6": {
          "2": 6163,
        },
        "16.8": {
          "2": 8196,
        },
        "15.4": {
          "1": 2288,
          "2": 5894,
        },
        "15.8": {
          "1": 707,
          "2": 8279,
        },
        "19.6": {
          "2": 1208,
        },
        "13.2": {
          "1": 2525,
        },
        "18.8": {
          "2": 2729,
        },
        "18.4": {
          "2": 3766,
        },
        "17.8": {
          "2": 5614,
        },
        "14.4": {
          "1": 5323,
          "2": 332,
        },
        "16.2": {
          "1": 41,
          "2": 8802,
        },
        "15.2": {
          "1": 3177,
          "2": 4726,
        },
        "13.6": {
          "1": 3449,
        },
        "14.6": {
          "1": 5439,
          "2": 970,
        },
        "16.4": {
          "1": 2,
          "2": 8905,
        },
        "21.4": {
          "2": 70,
          "3": 2,
        },
        "17.2": {
          "2": 7474,
        },
        "18.6": {
          "2": 3180,
        },
        "15.6": {
          "1": 1428,
          "2": 7056,
        },
        "17.4": {
          "2": 6817,
        },
        "14.8": {
          "1": 4926,
          "2": 1962,
        },
        "13.4": {
          "1": 2925,
        },
        "14.2": {
          "1": 5066,
          "2": 91,
        },
        "19.4": {
          "2": 1557,
        },
        "13.8": {
          "1": 4006,
          "2": 2,
        },
        "19.2": {
          "2": 1900,
        },
        "11.6": {
          "1": 412,
        },
        "18.2": {
          "2": 4391,
        },
        "11.8": {
          "1": 527,
        },
        "12.6": {
          "1": 1417,
        },
        "21.2": {
          "2": 133,
          "3": 6,
        },
        "19.8": {
          "2": 955,
        },
        "12.4": {
          "1": 1091,
        },
        "12.2": {
          "1": 897,
        },
        "11.4": {
          "1": 308,
        },
        "12.8": {
          "1": 1705,
        },
        "20.2": {
          "2": 567,
        },
        "20.4": {
          "2": 455,
        },
        "20.6": {
          "2": 359,
        },
        "20.8": {
          "2": 238,
        },
        "11.2": {
          "1": 239,
        },
        "22.2": {
          "2": 36,
          "3": 28,
        },
        "21.6": {
          "2": 68,
          "3": 6,
        },
        "21.8": {
          "2": 36,
          "3": 3,
        },
      },
      KONF: {
        "7": {
          "0": 1445,
          "1": 43,
        },
        "8": {
          "0": 170,
          "1": 9009,
        },
        "9": {
          "1": 17068,
        },
        "10": {
          "1": 9987,
        },
        "11": {
          "1": 1897,
        },
        "8.4": {
          "1": 13834,
        },
        "8.8": {
          "1": 16799,
        },
        "7.8": {
          "0": 816,
          "1": 6225,
        },
        "8.6": {
          "1": 15466,
        },
        "7.6": {
          "0": 1582,
          "1": 3529,
        },
        "6.8": {
          "0": 860,
          "1": 2,
        },
        "8.2": {
          "0": 3,
          "1": 11505,
        },
        "10.4": {
          "1": 5815,
        },
        "9.2": {
          "1": 17098,
        },
        "9.4": {
          "1": 15844,
        },
        "9.6": {
          "1": 14105,
        },
        "9.8": {
          "1": 12194,
        },
        "10.2": {
          "1": 7756,
        },
        "10.8": {
          "1": 2868,
        },
        "10.6": {
          "1": 4221,
        },
        "7.4": {
          "0": 2037,
          "1": 1444,
        },
        "11.8": {
          "1": 520,
        },
        "11.2": {
          "1": 1220,
        },
        "7.2": {
          "0": 2061,
          "1": 335,
        },
        "11.4": {
          "1": 739,
        },
        "11.6": {
          "1": 411,
        },
        "6.6": {
          "0": 1092,
        },
      },
      LEWICA: {
        "10": {
          "1": 8257,
        },
        "11": {
          "1": 20407,
        },
        "12": {
          "1": 9858,
        },
        "13": {
          "1": 1129,
        },
        "11.6": {
          "1": 15808,
        },
        "9.8": {
          "1": 5455,
        },
        "10.8": {
          "1": 19397,
        },
        "10.6": {
          "1": 17363,
        },
        "11.4": {
          "1": 18128,
        },
        "11.2": {
          "1": 20154,
        },
        "12.8": {
          "1": 1962,
        },
        "12.2": {
          "1": 7032,
        },
        "9.6": {
          "1": 3382,
        },
        "12.4": {
          "1": 4806,
        },
        "13.2": {
          "1": 1334,
          "2": 3,
        },
        "9.2": {
          "0": 1,
          "1": 1986,
        },
        "10.2": {
          "1": 11376,
        },
        "11.8": {
          "1": 12621,
        },
        "10.4": {
          "1": 14332,
        },
        "12.6": {
          "1": 3229,
        },
        "9.4": {
          "1": 1980,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 1896,
      TD: 5563,
      LEWICA: 38,
    },
    potencjalneMandaty: {
      KO: 58108,
      TD: 53848,
      LEWICA: 408,
    },
    ostatnieMandaty: {
      KO: 55822,
      TD: 60254,
      LEWICA: 30,
    },
    dawcyGlosow: {
      KO: 4461,
      TD: 13055,
      LEWICA: 399,
    },
  },
  Krosno: {
    procentyWOkreguSrednia: {
      PIS: 54.92743385591424,
      KO: 17.863141716644815,
      TD: 9.502639940080229,
      KONF: 12.694434644021136,
      LEWICA: 5.012349843339594,
    },
    odchylenieWOkregu: {
      PIS: 3.529933298988502,
      KO: 1.857743165883472,
      TD: 2.013774734692939,
      LEWICA: 1.5994966676710607,
      KONF: 3.8553061546987184,
    },
    wykresRozkladu: {
      "77.2%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 1,
      },
      "11%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 0,
      },
      "4.1%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 1,
      },
      "3.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "1.5%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 0,
      },
      "1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 1,
      },
      "0.7%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 0,
      },
      "0.6%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
      "0.3%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.1%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 0,
      },
      "0%": {
        KO: 1,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "80%": {
        DEMO: 3,
        NIEDEMO: 8,
      },
      "15.2%": {
        DEMO: 2,
        NIEDEMO: 9,
      },
      "4.7%": {
        DEMO: 4,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "47": {
          "5": 5,
          "6": 168,
        },
        "48": {
          "5": 1,
          "6": 373,
          "7": 3,
        },
        "49": {
          "5": 2,
          "6": 762,
          "7": 53,
        },
        "50": {
          "6": 1321,
          "7": 287,
        },
        "51": {
          "6": 1508,
          "7": 822,
        },
        "52": {
          "6": 1514,
          "7": 1789,
        },
        "53": {
          "6": 1194,
          "7": 3069,
        },
        "54": {
          "6": 580,
          "7": 4307,
          "8": 4,
        },
        "55": {
          "6": 196,
          "7": 4821,
          "8": 50,
        },
        "56": {
          "6": 43,
          "7": 4422,
          "8": 206,
        },
        "57": {
          "6": 9,
          "7": 3457,
          "8": 441,
        },
        "58": {
          "7": 2436,
          "8": 686,
        },
        "59": {
          "7": 1259,
          "8": 825,
        },
        "60": {
          "7": 664,
          "8": 776,
        },
        "61": {
          "7": 225,
          "8": 641,
          "9": 1,
        },
        "62": {
          "7": 87,
          "8": 432,
          "9": 14,
        },
        "63": {
          "7": 17,
          "8": 227,
          "9": 8,
        },
        "64": {
          "7": 4,
          "8": 116,
          "9": 14,
        },
        "65": {
          "7": 1,
          "8": 41,
          "9": 13,
        },
        "55.6": {
          "6": 95,
          "7": 4698,
          "8": 133,
        },
        "51.4": {
          "6": 1635,
          "7": 1146,
        },
        "56.4": {
          "6": 16,
          "7": 4182,
          "8": 300,
        },
        "57.2": {
          "6": 1,
          "7": 3283,
          "8": 468,
        },
        "58.8": {
          "7": 1535,
          "8": 836,
        },
        "52.2": {
          "6": 1493,
          "7": 1962,
        },
        "60.4": {
          "7": 459,
          "8": 726,
        },
        "57.4": {
          "6": 2,
          "7": 3007,
          "8": 591,
        },
        "61.4": {
          "7": 155,
          "8": 536,
          "9": 4,
        },
        "56.8": {
          "6": 9,
          "7": 3644,
          "8": 362,
        },
        "54.2": {
          "6": 493,
          "7": 4602,
          "8": 9,
        },
        "54.8": {
          "6": 260,
          "7": 4758,
          "8": 36,
        },
        "52.4": {
          "6": 1453,
          "7": 2299,
        },
        "50.6": {
          "6": 1415,
          "7": 550,
        },
        "59.8": {
          "7": 771,
          "8": 819,
        },
        "51.6": {
          "6": 1589,
          "7": 1343,
        },
        "53.4": {
          "6": 887,
          "7": 3648,
        },
        "56.2": {
          "6": 33,
          "7": 4280,
          "8": 249,
        },
        "52.8": {
          "6": 1247,
          "7": 2845,
        },
        "63.6": {
          "7": 5,
          "8": 171,
          "9": 16,
        },
        "53.6": {
          "6": 845,
          "7": 3895,
        },
        "52.6": {
          "6": 1422,
          "7": 2582,
        },
        "48.8": {
          "5": 3,
          "6": 691,
          "7": 24,
        },
        "54.4": {
          "6": 388,
          "7": 4591,
          "8": 14,
        },
        "55.2": {
          "6": 165,
          "7": 4857,
          "8": 78,
        },
        "53.2": {
          "6": 1065,
          "7": 3429,
        },
        "55.4": {
          "6": 123,
          "7": 4685,
          "8": 83,
        },
        "46.4": {
          "5": 8,
          "6": 81,
        },
        "49.6": {
          "6": 1064,
          "7": 149,
        },
        "54.6": {
          "6": 354,
          "7": 4707,
          "8": 27,
        },
        "59.4": {
          "7": 1069,
          "8": 823,
        },
        "51.8": {
          "6": 1542,
          "7": 1546,
        },
        "56.6": {
          "6": 11,
          "7": 3873,
          "8": 326,
        },
        "57.6": {
          "6": 1,
          "7": 2844,
          "8": 579,
        },
        "50.2": {
          "6": 1334,
          "7": 331,
        },
        "57.8": {
          "6": 1,
          "7": 2597,
          "8": 673,
        },
        "50.4": {
          "6": 1388,
          "7": 402,
        },
        "55.8": {
          "6": 64,
          "7": 4420,
          "8": 167,
        },
        "58.4": {
          "7": 1905,
          "8": 755,
        },
        "59.6": {
          "7": 905,
          "8": 811,
        },
        "58.6": {
          "7": 1668,
          "8": 782,
        },
        "49.8": {
          "6": 1158,
          "7": 175,
        },
        "53.8": {
          "6": 748,
          "7": 4058,
          "8": 1,
        },
        "58.2": {
          "7": 2106,
          "8": 790,
        },
        "47.8": {
          "5": 2,
          "6": 332,
          "7": 4,
        },
        "62.8": {
          "7": 22,
          "8": 250,
          "9": 13,
        },
        "51.2": {
          "6": 1547,
          "7": 941,
        },
        "59.2": {
          "7": 1169,
          "8": 842,
        },
        "50.8": {
          "6": 1461,
          "7": 648,
        },
        "60.8": {
          "7": 317,
          "8": 691,
          "9": 1,
        },
        "63.8": {
          "7": 5,
          "8": 126,
          "9": 10,
        },
        "48.2": {
          "5": 1,
          "6": 431,
          "7": 10,
        },
        "49.2": {
          "5": 1,
          "6": 879,
          "7": 66,
        },
        "48.6": {
          "5": 4,
          "6": 553,
          "7": 17,
        },
        "62.4": {
          "7": 47,
          "8": 325,
          "9": 3,
        },
        "61.6": {
          "7": 124,
          "8": 505,
          "9": 4,
        },
        "60.2": {
          "7": 541,
          "8": 798,
        },
        "49.4": {
          "5": 1,
          "6": 929,
          "7": 96,
        },
        "61.8": {
          "7": 112,
          "8": 428,
          "9": 2,
        },
        "65.6": {
          "8": 140,
          "9": 71,
        },
        "47.6": {
          "5": 8,
          "6": 302,
        },
        "62.2": {
          "7": 73,
          "8": 373,
          "9": 6,
        },
        "47.4": {
          "5": 3,
          "6": 224,
        },
        "63.4": {
          "7": 8,
          "8": 164,
          "9": 13,
        },
        "60.6": {
          "7": 381,
          "8": 658,
        },
        "65.4": {
          "8": 41,
          "9": 5,
        },
        "61.2": {
          "7": 177,
          "8": 569,
          "9": 1,
        },
        "48.4": {
          "5": 4,
          "6": 524,
          "7": 16,
        },
        "62.6": {
          "7": 25,
          "8": 283,
          "9": 12,
        },
        "63.2": {
          "7": 5,
          "8": 236,
          "9": 8,
        },
        "65.2": {
          "8": 55,
          "9": 14,
        },
        "47.2": {
          "5": 7,
          "6": 217,
        },
        "64.8": {
          "8": 52,
          "9": 15,
        },
        "46.2": {
          "5": 60,
          "6": 319,
        },
        "64.6": {
          "7": 1,
          "8": 78,
          "9": 13,
        },
        "46.6": {
          "5": 7,
          "6": 107,
        },
        "64.2": {
          "7": 2,
          "8": 107,
          "9": 15,
        },
        "46.8": {
          "5": 3,
          "6": 118,
        },
        "64.4": {
          "7": 1,
          "8": 77,
          "9": 11,
        },
      },
      KO: {
        "14": {
          "1": 812,
          "2": 56,
        },
        "15": {
          "1": 865,
          "2": 1720,
        },
        "16": {
          "1": 31,
          "2": 5200,
        },
        "17": {
          "2": 7740,
        },
        "18": {
          "2": 8520,
        },
        "19": {
          "2": 7088,
        },
        "20": {
          "2": 4302,
        },
        "21": {
          "2": 1988,
          "3": 90,
        },
        "22": {
          "2": 526,
          "3": 300,
        },
        "23": {
          "2": 48,
          "3": 213,
        },
        "17.2": {
          "2": 8233,
        },
        "17.6": {
          "2": 8713,
        },
        "16.2": {
          "1": 10,
          "2": 5848,
        },
        "18.2": {
          "2": 8445,
        },
        "15.8": {
          "1": 96,
          "2": 4530,
        },
        "16.6": {
          "2": 6888,
        },
        "17.8": {
          "2": 8758,
        },
        "14.8": {
          "1": 946,
          "2": 1145,
        },
        "18.4": {
          "2": 8331,
        },
        "23.2": {
          "2": 27,
          "3": 168,
        },
        "16.4": {
          "1": 4,
          "2": 6496,
        },
        "15.6": {
          "1": 252,
          "2": 3860,
        },
        "19.4": {
          "2": 6025,
        },
        "17.4": {
          "2": 8487,
        },
        "18.8": {
          "2": 7488,
        },
        "20.2": {
          "2": 3776,
          "3": 3,
        },
        "19.8": {
          "2": 5040,
        },
        "19.2": {
          "2": 6504,
        },
        "16.8": {
          "2": 7373,
        },
        "19.6": {
          "2": 5344,
        },
        "13.2": {
          "1": 1092,
        },
        "20.6": {
          "2": 2890,
          "3": 32,
        },
        "22.2": {
          "2": 359,
          "3": 324,
        },
        "15.2": {
          "1": 617,
          "2": 2325,
        },
        "20.4": {
          "2": 3344,
          "3": 8,
        },
        "18.6": {
          "2": 7759,
        },
        "22.6": {
          "2": 160,
          "3": 269,
        },
        "22.8": {
          "2": 89,
          "3": 209,
        },
        "20.8": {
          "2": 2297,
          "3": 63,
        },
        "13.6": {
          "1": 553,
          "2": 4,
        },
        "15.4": {
          "1": 422,
          "2": 3163,
        },
        "21.6": {
          "2": 941,
          "3": 252,
        },
        "14.4": {
          "1": 1076,
          "2": 361,
        },
        "13.8": {
          "1": 672,
          "2": 20,
        },
        "21.4": {
          "2": 1163,
          "3": 190,
        },
        "14.2": {
          "1": 963,
          "2": 187,
        },
        "14.6": {
          "1": 1123,
          "2": 681,
        },
        "21.2": {
          "2": 1596,
          "3": 151,
        },
        "23.4": {
          "2": 18,
          "3": 546,
        },
        "22.4": {
          "2": 206,
          "3": 276,
        },
        "21.8": {
          "2": 627,
          "3": 272,
        },
        "13.4": {
          "1": 410,
          "2": 1,
        },
      },
      TD: {
        "5": {
          "0": 532,
        },
        "6": {
          "0": 1467,
        },
        "7": {
          "0": 3227,
          "1": 337,
        },
        "8": {
          "0": 54,
          "1": 6043,
        },
        "9": {
          "1": 8144,
        },
        "10": {
          "1": 7933,
        },
        "11": {
          "1": 6100,
        },
        "12": {
          "1": 3451,
        },
        "13": {
          "1": 1530,
        },
        "14": {
          "1": 517,
          "2": 32,
        },
        "15": {
          "1": 49,
          "2": 90,
        },
        "12.6": {
          "1": 2242,
        },
        "11.2": {
          "1": 5573,
        },
        "10.4": {
          "1": 7408,
        },
        "7.6": {
          "0": 987,
          "1": 4017,
        },
        "9.6": {
          "1": 8378,
        },
        "6.6": {
          "0": 2679,
        },
        "7.2": {
          "0": 2847,
          "1": 1242,
        },
        "9.4": {
          "1": 8353,
        },
        "10.8": {
          "1": 6747,
        },
        "4.4": {
          "0": 1070,
        },
        "8.8": {
          "1": 7913,
        },
        "13.6": {
          "1": 875,
          "2": 2,
        },
        "8.2": {
          "0": 2,
          "1": 6536,
        },
        "13.4": {
          "1": 1037,
        },
        "7.8": {
          "0": 318,
          "1": 5205,
        },
        "12.4": {
          "1": 2517,
        },
        "8.4": {
          "1": 7127,
        },
        "9.2": {
          "1": 8453,
        },
        "11.6": {
          "1": 4569,
        },
        "14.2": {
          "1": 335,
          "2": 41,
        },
        "11.4": {
          "1": 5074,
        },
        "10.2": {
          "1": 7850,
        },
        "11.8": {
          "1": 4008,
        },
        "5.6": {
          "0": 1016,
        },
        "13.2": {
          "1": 1294,
        },
        "7.4": {
          "0": 1918,
          "1": 2596,
        },
        "4.6": {
          "0": 316,
        },
        "9.8": {
          "1": 8340,
        },
        "12.8": {
          "1": 1970,
        },
        "10.6": {
          "1": 7152,
        },
        "5.2": {
          "0": 653,
        },
        "8.6": {
          "1": 7358,
        },
        "12.2": {
          "1": 3086,
        },
        "6.2": {
          "0": 1830,
        },
        "6.8": {
          "0": 3007,
          "1": 25,
        },
        "6.4": {
          "0": 2259,
        },
        "14.6": {
          "1": 157,
          "2": 85,
        },
        "15.6": {
          "1": 8,
          "2": 157,
        },
        "5.4": {
          "0": 792,
        },
        "13.8": {
          "1": 649,
          "2": 15,
        },
        "5.8": {
          "0": 1294,
        },
        "4.8": {
          "0": 463,
        },
        "14.8": {
          "1": 90,
          "2": 95,
        },
        "15.2": {
          "1": 27,
          "2": 74,
        },
        "14.4": {
          "1": 234,
          "2": 58,
        },
        "15.4": {
          "1": 12,
          "2": 59,
        },
      },
      KONF: {
        "3": {
          "0": 1048,
        },
        "4": {
          "0": 252,
        },
        "5": {
          "0": 427,
        },
        "6": {
          "0": 767,
        },
        "7": {
          "0": 1123,
          "1": 98,
        },
        "8": {
          "0": 15,
          "1": 1666,
        },
        "9": {
          "1": 2607,
        },
        "10": {
          "1": 3417,
        },
        "11": {
          "1": 3979,
        },
        "12": {
          "1": 4493,
        },
        "13": {
          "1": 4612,
        },
        "14": {
          "1": 4173,
          "2": 161,
        },
        "15": {
          "1": 1826,
          "2": 2056,
        },
        "16": {
          "1": 30,
          "2": 3021,
        },
        "17": {
          "2": 2109,
        },
        "18": {
          "2": 1344,
        },
        "19": {
          "2": 788,
        },
        "20": {
          "2": 430,
        },
        "21": {
          "2": 182,
          "3": 3,
        },
        "22": {
          "2": 45,
          "3": 27,
        },
        "23": {
          "2": 12,
          "3": 20,
        },
        "24": {
          "2": 1,
          "3": 11,
        },
        "8.8": {
          "1": 2407,
        },
        "15.4": {
          "1": 658,
          "2": 2864,
        },
        "11.8": {
          "1": 4386,
        },
        "8.6": {
          "1": 2321,
        },
        "9.2": {
          "1": 2661,
        },
        "9.8": {
          "1": 3232,
        },
        "12.8": {
          "1": 4691,
        },
        "17.6": {
          "2": 1689,
        },
        "10.6": {
          "1": 3793,
        },
        "10.2": {
          "1": 3489,
        },
        "15.6": {
          "1": 289,
          "2": 3045,
        },
        "19.4": {
          "2": 624,
        },
        "6.6": {
          "0": 1001,
        },
        "4.6": {
          "0": 363,
        },
        "14.8": {
          "1": 2354,
          "2": 1578,
        },
        "15.2": {
          "1": 1219,
          "2": 2431,
        },
        "13.8": {
          "1": 4591,
          "2": 57,
        },
        "11.2": {
          "1": 4101,
        },
        "12.6": {
          "1": 4508,
        },
        "14.4": {
          "1": 3505,
          "2": 676,
        },
        "8.4": {
          "1": 2059,
        },
        "12.4": {
          "1": 4583,
        },
        "11.4": {
          "1": 4347,
        },
        "9.4": {
          "1": 2792,
        },
        "17.8": {
          "2": 1470,
        },
        "13.4": {
          "1": 4624,
        },
        "5.2": {
          "0": 517,
        },
        "14.6": {
          "1": 2997,
          "2": 1050,
        },
        "18.4": {
          "2": 1100,
        },
        "19.8": {
          "2": 448,
        },
        "17.2": {
          "2": 1930,
        },
        "10.8": {
          "1": 3971,
        },
        "6.2": {
          "0": 812,
        },
        "7.8": {
          "0": 59,
          "1": 1585,
        },
        "18.6": {
          "2": 1019,
        },
        "8.2": {
          "0": 2,
          "1": 1906,
        },
        "16.2": {
          "1": 9,
          "2": 2776,
        },
        "6.4": {
          "0": 887,
        },
        "13.2": {
          "1": 4488,
        },
        "14.2": {
          "1": 3999,
          "2": 390,
        },
        "19.2": {
          "2": 695,
        },
        "12.2": {
          "1": 4669,
        },
        "10.4": {
          "1": 3690,
        },
        "16.6": {
          "2": 2440,
        },
        "11.6": {
          "1": 4442,
        },
        "3.6": {
          "0": 189,
        },
        "17.4": {
          "2": 1778,
        },
        "16.4": {
          "1": 1,
          "2": 2657,
        },
        "15.8": {
          "1": 103,
          "2": 3100,
        },
        "6.8": {
          "0": 1065,
          "1": 7,
        },
        "4.4": {
          "0": 330,
        },
        "13.6": {
          "1": 4505,
          "2": 13,
        },
        "18.2": {
          "2": 1236,
        },
        "4.8": {
          "0": 378,
        },
        "9.6": {
          "1": 2997,
        },
        "22.4": {
          "2": 28,
          "3": 20,
        },
        "19.6": {
          "2": 572,
        },
        "16.8": {
          "2": 2349,
        },
        "7.2": {
          "0": 917,
          "1": 369,
        },
        "5.6": {
          "0": 646,
        },
        "18.8": {
          "2": 948,
        },
        "7.6": {
          "0": 258,
          "1": 1244,
        },
        "20.4": {
          "2": 313,
        },
        "7.4": {
          "0": 556,
          "1": 860,
        },
        "4.2": {
          "0": 277,
        },
        "5.4": {
          "0": 572,
        },
        "3.8": {
          "0": 211,
        },
        "5.8": {
          "0": 666,
        },
        "3.4": {
          "0": 194,
        },
        "20.2": {
          "2": 345,
        },
        "21.6": {
          "2": 76,
          "3": 16,
        },
        "3.2": {
          "0": 161,
        },
        "21.4": {
          "2": 126,
          "3": 10,
        },
        "21.8": {
          "2": 67,
          "3": 22,
        },
        "21.2": {
          "2": 132,
          "3": 8,
        },
        "24.2": {
          "3": 36,
        },
        "22.6": {
          "2": 22,
          "3": 25,
        },
        "20.6": {
          "2": 226,
          "3": 1,
        },
        "23.4": {
          "2": 1,
          "3": 7,
        },
        "20.8": {
          "2": 193,
          "3": 3,
        },
        "23.2": {
          "2": 3,
          "3": 11,
        },
        "22.8": {
          "2": 13,
          "3": 26,
        },
        "22.2": {
          "2": 30,
          "3": 29,
        },
        "23.6": {
          "2": 1,
          "3": 13,
        },
        "23.8": {
          "3": 6,
        },
      },
      LEWICA: {
        "1": {
          "0": 1349,
        },
        "2": {
          "0": 1580,
        },
        "3": {
          "0": 4390,
        },
        "4": {
          "0": 8296,
        },
        "5": {
          "0": 10553,
        },
        "6": {
          "0": 8523,
        },
        "7": {
          "0": 4308,
          "1": 187,
        },
        "8": {
          "0": 82,
          "1": 1476,
        },
        "9": {
          "1": 347,
        },
        "4.8": {
          "0": 10341,
        },
        "3.6": {
          "0": 6607,
        },
        "4.6": {
          "0": 9877,
        },
        "3.4": {
          "0": 5865,
        },
        "5.6": {
          "0": 9722,
        },
        "3.8": {
          "0": 7610,
        },
        "7.6": {
          "0": 1014,
          "1": 1482,
        },
        "5.2": {
          "0": 10205,
        },
        "6.4": {
          "0": 6819,
        },
        "4.4": {
          "0": 9504,
        },
        "6.2": {
          "0": 7599,
        },
        "3.2": {
          "0": 5090,
        },
        "6.6": {
          "0": 6043,
        },
        "5.8": {
          "0": 9153,
        },
        "5.4": {
          "0": 9938,
        },
        "1.2": {
          "0": 528,
        },
        "2.2": {
          "0": 2003,
        },
        "2.6": {
          "0": 3058,
        },
        "6.8": {
          "0": 5368,
          "1": 19,
        },
        "4.2": {
          "0": 8803,
        },
        "1.4": {
          "0": 729,
        },
        "2.8": {
          "0": 3680,
        },
        "7.8": {
          "0": 335,
          "1": 1723,
        },
        "7.4": {
          "0": 2055,
          "1": 1032,
        },
        "8.6": {
          "1": 688,
        },
        "7.2": {
          "0": 3278,
          "1": 515,
        },
        "8.4": {
          "1": 968,
        },
        "9.2": {
          "1": 233,
        },
        "2.4": {
          "0": 2527,
        },
        "1.8": {
          "0": 1232,
        },
        "1.6": {
          "0": 925,
        },
        "8.2": {
          "0": 3,
          "1": 1260,
        },
        "9.6": {
          "1": 132,
        },
        "8.8": {
          "1": 521,
        },
        "9.4": {
          "1": 184,
        },
        "9.8": {
          "1": 241,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3684,
      TD: 4532,
      LEWICA: 936,
    },
    potencjalneMandaty: {
      KO: 24472,
      TD: 29402,
      LEWICA: 39303,
    },
    ostatnieMandaty: {
      KO: 24629,
      TD: 17726,
      LEWICA: 6303,
    },
    dawcyGlosow: {
      KO: 10271,
      TD: 12653,
      LEWICA: 1567,
    },
  },
  Rzeszów: {
    procentyWOkreguSrednia: {
      PIS: 53.42195548498497,
      KO: 15.967439287745927,
      TD: 9.925603021937647,
      KONF: 14.778859364214929,
      LEWICA: 5.906142841116525,
    },
    odchylenieWOkregu: {
      PIS: 2.8959755029149474,
      KO: 2.266678697397164,
      TD: 2.013774734692939,
      LEWICA: 0.9506670221032995,
      KONF: 2.787579410098326,
    },
    wykresRozkladu: {
      "37.2%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 11,
        TD: 1,
      },
      "14.6%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 12,
        TD: 1,
      },
      "13.9%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 11,
        TD: 1,
      },
      "10.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 1,
      },
      "9.5%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 11,
        TD: 2,
      },
      "7.8%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 2,
      },
      "2.6%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 2,
      },
      "0.8%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 12,
        TD: 1,
      },
      "0.6%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 12,
        TD: 0,
      },
      "0.5%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 11,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "61.6%": {
        DEMO: 4,
        NIEDEMO: 11,
      },
      "21.1%": {
        DEMO: 5,
        NIEDEMO: 10,
      },
      "16.4%": {
        DEMO: 3,
        NIEDEMO: 12,
      },
    },
    histogram: {
      PIS: {
        "47": {
          "7": 51,
          "8": 203,
        },
        "48": {
          "7": 25,
          "8": 676,
          "9": 5,
        },
        "49": {
          "7": 20,
          "8": 1360,
          "9": 48,
        },
        "50": {
          "8": 2205,
          "9": 408,
        },
        "51": {
          "8": 2476,
          "9": 1557,
        },
        "52": {
          "8": 1780,
          "9": 3496,
        },
        "53": {
          "8": 879,
          "9": 5225,
          "10": 9,
        },
        "54": {
          "8": 287,
          "9": 5437,
          "10": 170,
        },
        "55": {
          "8": 30,
          "9": 4282,
          "10": 605,
        },
        "56": {
          "9": 2684,
          "10": 958,
        },
        "57": {
          "9": 1257,
          "10": 1092,
        },
        "58": {
          "9": 503,
          "10": 876,
        },
        "59": {
          "9": 132,
          "10": 587,
          "11": 6,
        },
        "60": {
          "9": 24,
          "10": 261,
          "11": 10,
        },
        "61": {
          "10": 105,
          "11": 16,
        },
        "62": {
          "10": 41,
          "11": 15,
        },
        "53.6": {
          "8": 447,
          "9": 5491,
          "10": 52,
        },
        "54.8": {
          "8": 64,
          "9": 4523,
          "10": 470,
        },
        "58.2": {
          "9": 373,
          "10": 878,
          "11": 1,
        },
        "57.8": {
          "9": 578,
          "10": 990,
        },
        "50.2": {
          "8": 2387,
          "9": 565,
        },
        "57.2": {
          "9": 1094,
          "10": 1108,
        },
        "50.4": {
          "8": 2445,
          "9": 777,
        },
        "49.2": {
          "7": 7,
          "8": 1571,
          "9": 85,
        },
        "57.4": {
          "9": 930,
          "10": 1065,
        },
        "58.6": {
          "9": 200,
          "10": 743,
          "11": 2,
        },
        "48.6": {
          "7": 18,
          "8": 1048,
          "9": 12,
        },
        "49.8": {
          "8": 2071,
          "9": 277,
        },
        "51.8": {
          "8": 1993,
          "9": 3164,
        },
        "53.8": {
          "8": 372,
          "9": 5468,
          "10": 137,
        },
        "51.6": {
          "8": 2075,
          "9": 2773,
        },
        "56.2": {
          "9": 2419,
          "10": 965,
        },
        "52.2": {
          "8": 1616,
          "9": 3864,
        },
        "51.2": {
          "8": 2329,
          "9": 1963,
        },
        "56.8": {
          "9": 1514,
          "10": 1140,
        },
        "52.8": {
          "8": 1049,
          "9": 4886,
        },
        "53.4": {
          "8": 555,
          "9": 5546,
          "10": 50,
        },
        "54.2": {
          "8": 211,
          "9": 5209,
          "10": 255,
        },
        "54.4": {
          "8": 164,
          "9": 5030,
          "10": 325,
        },
        "55.8": {
          "8": 1,
          "9": 3058,
          "10": 883,
        },
        "60.8": {
          "9": 5,
          "10": 151,
          "11": 27,
        },
        "56.4": {
          "9": 2054,
          "10": 1065,
        },
        "55.4": {
          "8": 14,
          "9": 3726,
          "10": 699,
        },
        "54.6": {
          "8": 101,
          "9": 4826,
          "10": 399,
        },
        "58.8": {
          "9": 152,
          "10": 657,
          "11": 8,
        },
        "51.4": {
          "8": 2195,
          "9": 2443,
        },
        "58.4": {
          "9": 294,
          "10": 781,
        },
        "52.6": {
          "8": 1224,
          "9": 4626,
        },
        "49.6": {
          "7": 1,
          "8": 1914,
          "9": 178,
        },
        "48.2": {
          "7": 27,
          "8": 730,
          "9": 2,
        },
        "52.4": {
          "8": 1461,
          "9": 4244,
        },
        "50.6": {
          "8": 2454,
          "9": 1041,
        },
        "59.8": {
          "9": 43,
          "10": 339,
          "11": 17,
        },
        "53.2": {
          "8": 751,
          "9": 5381,
          "10": 24,
        },
        "56.6": {
          "9": 1761,
          "10": 1100,
        },
        "59.6": {
          "9": 55,
          "10": 406,
          "11": 20,
        },
        "50.8": {
          "8": 2441,
          "9": 1328,
        },
        "55.6": {
          "8": 5,
          "9": 3423,
          "10": 841,
        },
        "57.6": {
          "9": 689,
          "10": 998,
        },
        "49.4": {
          "7": 1,
          "8": 1694,
          "9": 109,
        },
        "55.2": {
          "8": 24,
          "9": 4022,
          "10": 614,
        },
        "46.8": {
          "7": 65,
          "8": 145,
        },
        "48.8": {
          "7": 20,
          "8": 1217,
          "9": 24,
        },
        "48.4": {
          "7": 27,
          "8": 889,
          "9": 10,
        },
        "47.8": {
          "7": 37,
          "8": 523,
        },
        "59.2": {
          "9": 101,
          "10": 514,
          "11": 8,
        },
        "60.4": {
          "9": 11,
          "10": 191,
          "11": 23,
        },
        "61.8": {
          "10": 53,
          "11": 17,
        },
        "46.4": {
          "7": 44,
          "8": 99,
        },
        "60.2": {
          "9": 17,
          "10": 238,
          "11": 15,
        },
        "47.4": {
          "7": 48,
          "8": 322,
        },
        "59.4": {
          "9": 60,
          "10": 440,
          "11": 16,
        },
        "47.6": {
          "7": 40,
          "8": 412,
        },
        "47.2": {
          "7": 49,
          "8": 277,
        },
        "46.2": {
          "7": 283,
          "8": 172,
        },
        "61.4": {
          "10": 77,
          "11": 20,
        },
        "62.2": {
          "10": 113,
          "11": 126,
        },
        "46.6": {
          "7": 53,
          "8": 124,
        },
        "60.6": {
          "9": 4,
          "10": 173,
          "11": 33,
        },
        "61.2": {
          "9": 4,
          "10": 110,
          "11": 22,
        },
        "61.6": {
          "9": 1,
          "10": 50,
          "11": 11,
        },
      },
      KO: {
        "11": {
          "1": 383,
          "2": 28,
        },
        "12": {
          "1": 76,
          "2": 1082,
        },
        "13": {
          "2": 2715,
        },
        "14": {
          "2": 4910,
        },
        "15": {
          "2": 6958,
        },
        "16": {
          "2": 7758,
          "3": 35,
        },
        "17": {
          "2": 4204,
          "3": 2735,
        },
        "18": {
          "2": 276,
          "3": 4497,
        },
        "19": {
          "3": 2674,
        },
        "20": {
          "3": 1097,
        },
        "21": {
          "3": 378,
        },
        "22": {
          "3": 95,
          "4": 11,
        },
        "15.6": {
          "2": 7611,
        },
        "14.4": {
          "2": 5866,
        },
        "15.4": {
          "2": 7385,
        },
        "14.6": {
          "2": 6207,
        },
        "13.6": {
          "2": 3984,
        },
        "15.2": {
          "2": 7310,
        },
        "17.6": {
          "2": 1154,
          "3": 4535,
        },
        "11.6": {
          "1": 303,
          "2": 506,
        },
        "14.2": {
          "2": 5333,
        },
        "12.6": {
          "2": 2105,
        },
        "17.4": {
          "2": 1994,
          "3": 4108,
        },
        "15.8": {
          "2": 7698,
        },
        "16.8": {
          "2": 5355,
          "3": 1955,
        },
        "16.4": {
          "2": 6977,
          "3": 549,
        },
        "18.2": {
          "2": 119,
          "3": 4130,
        },
        "18.6": {
          "2": 5,
          "3": 3476,
        },
        "17.8": {
          "2": 589,
          "3": 4658,
        },
        "12.4": {
          "1": 3,
          "2": 1753,
        },
        "12.8": {
          "2": 2357,
        },
        "19.4": {
          "3": 1931,
        },
        "16.6": {
          "2": 6354,
          "3": 1087,
        },
        "14.8": {
          "2": 6606,
        },
        "16.2": {
          "2": 7622,
          "3": 230,
        },
        "11.4": {
          "1": 377,
          "2": 297,
        },
        "12.2": {
          "1": 19,
          "2": 1450,
        },
        "19.8": {
          "3": 1346,
        },
        "19.6": {
          "3": 1582,
        },
        "11.8": {
          "1": 144,
          "2": 874,
        },
        "18.4": {
          "2": 28,
          "3": 3851,
        },
        "13.2": {
          "2": 3072,
        },
        "13.8": {
          "2": 4417,
        },
        "19.2": {
          "3": 2208,
        },
        "18.8": {
          "3": 2910,
        },
        "20.2": {
          "3": 878,
        },
        "20.4": {
          "3": 764,
        },
        "13.4": {
          "2": 3433,
        },
        "22.2": {
          "3": 69,
          "4": 14,
        },
        "17.2": {
          "2": 3076,
          "3": 3392,
        },
        "10.4": {
          "1": 841,
        },
        "10.6": {
          "1": 275,
          "2": 1,
        },
        "20.8": {
          "3": 472,
        },
        "21.6": {
          "3": 154,
          "4": 3,
        },
        "22.4": {
          "3": 33,
          "4": 13,
        },
        "20.6": {
          "3": 566,
        },
        "21.2": {
          "3": 293,
          "4": 1,
        },
        "22.8": {
          "3": 40,
          "4": 57,
        },
        "10.8": {
          "1": 347,
          "2": 4,
        },
        "11.2": {
          "1": 447,
          "2": 108,
        },
        "21.4": {
          "3": 210,
          "4": 1,
        },
        "21.8": {
          "3": 117,
          "4": 5,
        },
        "22.6": {
          "3": 27,
          "4": 17,
        },
      },
      TD: {
        "5": {
          "0": 303,
        },
        "6": {
          "0": 80,
          "1": 900,
        },
        "7": {
          "1": 2491,
        },
        "8": {
          "1": 4985,
        },
        "9": {
          "1": 7526,
        },
        "10": {
          "1": 8405,
        },
        "11": {
          "1": 6604,
          "2": 717,
        },
        "12": {
          "1": 293,
          "2": 4252,
        },
        "13": {
          "2": 2209,
        },
        "14": {
          "2": 753,
        },
        "15": {
          "2": 202,
        },
        "16": {
          "2": 103,
          "3": 21,
        },
        "9.8": {
          "1": 8363,
        },
        "10.4": {
          "1": 8338,
        },
        "7.6": {
          "1": 3780,
        },
        "9.6": {
          "1": 8417,
        },
        "8.2": {
          "1": 5548,
        },
        "11.4": {
          "1": 3363,
          "2": 2915,
        },
        "8.8": {
          "1": 7053,
        },
        "11.6": {
          "1": 1890,
          "2": 3935,
        },
        "10.8": {
          "1": 7549,
          "2": 239,
        },
        "9.2": {
          "1": 7780,
        },
        "11.2": {
          "1": 5144,
          "2": 1775,
        },
        "7.8": {
          "1": 4359,
        },
        "13.2": {
          "2": 1826,
        },
        "6.4": {
          "1": 1503,
        },
        "7.2": {
          "1": 2930,
        },
        "11.8": {
          "1": 875,
          "2": 4360,
        },
        "5.2": {
          "0": 398,
        },
        "8.6": {
          "1": 6479,
        },
        "9.4": {
          "1": 8030,
        },
        "8.4": {
          "1": 6064,
        },
        "12.8": {
          "2": 2623,
        },
        "6.6": {
          "1": 1805,
        },
        "12.6": {
          "2": 3113,
        },
        "12.4": {
          "2": 3531,
        },
        "5.8": {
          "0": 292,
          "1": 503,
        },
        "10.6": {
          "1": 8041,
          "2": 10,
        },
        "10.2": {
          "1": 8458,
        },
        "13.4": {
          "2": 1561,
        },
        "12.2": {
          "1": 75,
          "2": 4082,
        },
        "13.6": {
          "2": 1207,
        },
        "7.4": {
          "1": 3404,
        },
        "6.8": {
          "1": 2123,
        },
        "13.8": {
          "2": 963,
        },
        "5.6": {
          "0": 481,
          "1": 161,
        },
        "14.2": {
          "2": 594,
        },
        "14.6": {
          "2": 379,
        },
        "14.8": {
          "2": 277,
        },
        "6.2": {
          "0": 3,
          "1": 1291,
        },
        "4.8": {
          "0": 901,
        },
        "5.4": {
          "0": 485,
          "1": 13,
        },
        "15.4": {
          "2": 114,
        },
        "14.4": {
          "2": 462,
        },
        "15.8": {
          "2": 57,
        },
        "15.2": {
          "2": 163,
        },
        "15.6": {
          "2": 71,
        },
      },
      KONF: {
        "8": {
          "1": 188,
        },
        "9": {
          "1": 447,
        },
        "10": {
          "1": 1033,
        },
        "11": {
          "1": 1837,
          "2": 136,
        },
        "12": {
          "1": 196,
          "2": 3129,
        },
        "13": {
          "2": 5032,
        },
        "14": {
          "2": 5990,
        },
        "15": {
          "2": 6469,
        },
        "16": {
          "2": 5741,
          "3": 45,
        },
        "17": {
          "2": 2565,
          "3": 1892,
        },
        "18": {
          "2": 112,
          "3": 2692,
        },
        "19": {
          "3": 1499,
        },
        "20": {
          "3": 628,
        },
        "21": {
          "3": 228,
        },
        "22": {
          "3": 68,
          "4": 10,
        },
        "23": {
          "3": 2,
          "4": 7,
        },
        "15.4": {
          "2": 6251,
        },
        "19.4": {
          "3": 1027,
        },
        "12.6": {
          "2": 4409,
        },
        "11.2": {
          "1": 1815,
          "2": 472,
        },
        "13.2": {
          "2": 5235,
        },
        "16.6": {
          "2": 4265,
          "3": 795,
        },
        "16.4": {
          "2": 4838,
          "3": 488,
        },
        "13.4": {
          "2": 5455,
        },
        "14.6": {
          "2": 6388,
        },
        "20.2": {
          "3": 524,
        },
        "17.4": {
          "2": 1057,
          "3": 2720,
        },
        "14.4": {
          "2": 6397,
        },
        "14.8": {
          "2": 6468,
        },
        "13.8": {
          "2": 6043,
        },
        "11.4": {
          "1": 1379,
          "2": 1074,
        },
        "18.2": {
          "2": 30,
          "3": 2358,
        },
        "14.2": {
          "2": 6257,
        },
        "17.6": {
          "2": 613,
          "3": 2826,
        },
        "15.6": {
          "2": 6204,
        },
        "15.2": {
          "2": 6332,
        },
        "10.4": {
          "1": 1424,
        },
        "16.8": {
          "2": 3316,
          "3": 1261,
        },
        "20.6": {
          "3": 345,
        },
        "7.8": {
          "0": 58,
          "1": 771,
        },
        "12.2": {
          "1": 59,
          "2": 3607,
        },
        "12.4": {
          "1": 4,
          "2": 3971,
        },
        "15.8": {
          "2": 5904,
          "3": 2,
        },
        "17.8": {
          "2": 260,
          "3": 2809,
        },
        "12.8": {
          "2": 4482,
        },
        "19.6": {
          "3": 935,
        },
        "18.6": {
          "3": 1919,
        },
        "10.6": {
          "1": 1600,
          "2": 1,
        },
        "11.8": {
          "1": 496,
          "2": 2569,
        },
        "9.6": {
          "1": 820,
        },
        "17.2": {
          "2": 1720,
          "3": 2207,
        },
        "9.4": {
          "1": 664,
        },
        "16.2": {
          "2": 5504,
          "3": 189,
        },
        "20.4": {
          "3": 454,
        },
        "13.6": {
          "2": 5670,
        },
        "18.4": {
          "2": 7,
          "3": 2163,
        },
        "10.8": {
          "1": 1795,
          "2": 34,
        },
        "21.2": {
          "3": 189,
        },
        "20.8": {
          "3": 268,
        },
        "11.6": {
          "1": 946,
          "2": 1829,
        },
        "21.4": {
          "3": 163,
          "4": 2,
        },
        "18.8": {
          "3": 1628,
        },
        "8.2": {
          "1": 238,
        },
        "9.8": {
          "1": 863,
        },
        "9.2": {
          "1": 570,
        },
        "8.8": {
          "1": 418,
        },
        "8.4": {
          "1": 251,
        },
        "19.2": {
          "3": 1266,
        },
        "8.6": {
          "1": 311,
        },
        "10.2": {
          "1": 1206,
        },
        "19.8": {
          "3": 787,
        },
        "21.6": {
          "3": 108,
          "4": 5,
        },
        "22.4": {
          "3": 29,
          "4": 14,
        },
        "21.8": {
          "3": 76,
          "4": 5,
        },
        "22.2": {
          "3": 39,
          "4": 8,
        },
        "22.6": {
          "3": 9,
          "4": 17,
        },
        "23.2": {
          "3": 3,
          "4": 34,
        },
        "22.8": {
          "3": 12,
          "4": 20,
        },
      },
      LEWICA: {
        "4": {
          "0": 2153,
        },
        "5": {
          "0": 10764,
        },
        "6": {
          "0": 654,
          "1": 15964,
        },
        "7": {
          "1": 8564,
        },
        "8": {
          "1": 1476,
        },
        "6.4": {
          "1": 14698,
        },
        "6.8": {
          "1": 10632,
        },
        "5.4": {
          "0": 14136,
          "1": 510,
        },
        "6.2": {
          "0": 16,
          "1": 16158,
        },
        "6.6": {
          "1": 12809,
        },
        "4.8": {
          "0": 8585,
        },
        "7.4": {
          "1": 4911,
        },
        "5.2": {
          "0": 13001,
        },
        "7.2": {
          "1": 6593,
        },
        "3.8": {
          "0": 1361,
        },
        "4.6": {
          "0": 6360,
        },
        "7.6": {
          "1": 3480,
        },
        "5.8": {
          "0": 4577,
          "1": 12203,
        },
        "5.6": {
          "0": 11287,
          "1": 4781,
        },
        "4.4": {
          "0": 4676,
        },
        "4.2": {
          "0": 3300,
        },
        "7.8": {
          "1": 2317,
        },
        "8.6": {
          "1": 370,
        },
        "8.4": {
          "1": 559,
        },
        "8.2": {
          "1": 955,
        },
        "3.6": {
          "0": 1793,
        },
        "8.8": {
          "1": 357,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2937,
      TD: 2901,
      LEWICA: 9088,
    },
    potencjalneMandaty: {
      KO: 46807,
      TD: 44860,
      LEWICA: 73577,
    },
    ostatnieMandaty: {
      KO: 20447,
      TD: 18225,
      LEWICA: 28251,
    },
    dawcyGlosow: {
      KO: 7344,
      TD: 6990,
      LEWICA: 21285,
    },
  },
  Białystok: {
    procentyWOkreguSrednia: {
      PIS: 43.590924833126884,
      KO: 23.392193360469527,
      TD: 14.492151863526747,
      KONF: 11.350928855613256,
      LEWICA: 7.173801087263583,
    },
    odchylenieWOkregu: {
      PIS: 2.772151013324043,
      KO: 2.0663708922593376,
      TD: 2.013774734692939,
      LEWICA: 2.0821953515572607,
      KONF: 2.206644913182207,
    },
    wykresRozkladu: {
      "47.7%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 2,
      },
      "13.6%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 2,
      },
      "12.3%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 2,
      },
      "11.8%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 2,
      },
      "4.7%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 8,
        TD: 1,
      },
      "4.3%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 1,
      },
      "1.4%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 9,
        TD: 1,
      },
      "1.3%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 8,
        TD: 3,
      },
      "1.2%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 3,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 7,
        TD: 2,
      },
      "0.3%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 3,
      },
    },
    wykresRozkladuDemo: {
      "67.5%": {
        DEMO: 6,
        NIEDEMO: 8,
      },
      "17.9%": {
        DEMO: 5,
        NIEDEMO: 9,
      },
      "14.3%": {
        DEMO: 7,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "37": {
          "5": 46,
          "6": 101,
        },
        "38": {
          "5": 41,
          "6": 390,
        },
        "39": {
          "5": 20,
          "6": 1027,
        },
        "40": {
          "6": 2210,
          "7": 34,
        },
        "41": {
          "6": 3255,
          "7": 404,
        },
        "42": {
          "6": 3397,
          "7": 1997,
        },
        "43": {
          "6": 2299,
          "7": 4148,
        },
        "44": {
          "6": 793,
          "7": 5636,
        },
        "45": {
          "6": 139,
          "7": 5407,
          "8": 3,
        },
        "46": {
          "6": 8,
          "7": 3781,
          "8": 147,
        },
        "47": {
          "7": 2077,
          "8": 433,
        },
        "48": {
          "7": 733,
          "8": 505,
        },
        "49": {
          "7": 201,
          "8": 373,
        },
        "50": {
          "7": 34,
          "8": 215,
        },
        "51": {
          "7": 5,
          "8": 79,
          "9": 2,
        },
        "52": {
          "7": 1,
          "8": 92,
          "9": 20,
        },
        "41.8": {
          "6": 3572,
          "7": 1546,
        },
        "40.4": {
          "6": 2643,
          "7": 130,
        },
        "42.2": {
          "6": 3282,
          "7": 2457,
        },
        "49.8": {
          "7": 60,
          "8": 208,
        },
        "42.4": {
          "6": 3047,
          "7": 2817,
        },
        "41.6": {
          "6": 3595,
          "7": 1173,
        },
        "42.6": {
          "6": 2873,
          "7": 3274,
        },
        "43.8": {
          "6": 1041,
          "7": 5607,
        },
        "45.2": {
          "6": 84,
          "7": 5172,
          "8": 23,
        },
        "47.2": {
          "7": 1705,
          "8": 445,
        },
        "38.6": {
          "5": 22,
          "6": 686,
        },
        "42.8": {
          "6": 2528,
          "7": 3707,
        },
        "45.6": {
          "6": 35,
          "7": 4550,
          "8": 68,
        },
        "43.2": {
          "6": 1883,
          "7": 4614,
        },
        "46.4": {
          "7": 3080,
          "8": 253,
        },
        "47.8": {
          "7": 924,
          "8": 507,
        },
        "45.4": {
          "6": 58,
          "7": 4793,
          "8": 49,
        },
        "40.8": {
          "6": 3100,
          "7": 287,
        },
        "36.6": {
          "5": 182,
          "6": 117,
        },
        "41.2": {
          "6": 3485,
          "7": 632,
        },
        "43.6": {
          "6": 1337,
          "7": 5392,
        },
        "39.6": {
          "6": 1627,
          "7": 10,
        },
        "40.6": {
          "6": 2908,
          "7": 190,
        },
        "44.4": {
          "6": 441,
          "7": 5764,
        },
        "41.4": {
          "6": 3499,
          "7": 855,
        },
        "44.2": {
          "6": 608,
          "7": 5717,
        },
        "46.2": {
          "6": 3,
          "7": 3474,
          "8": 224,
        },
        "45.8": {
          "6": 20,
          "7": 4174,
          "8": 120,
        },
        "39.8": {
          "5": 1,
          "6": 1839,
          "7": 12,
        },
        "38.2": {
          "5": 35,
          "6": 493,
        },
        "44.8": {
          "6": 210,
          "7": 5531,
          "8": 2,
        },
        "46.6": {
          "7": 2704,
          "8": 309,
        },
        "38.8": {
          "5": 10,
          "6": 850,
        },
        "38.4": {
          "5": 31,
          "6": 553,
        },
        "44.6": {
          "6": 335,
          "7": 5577,
          "8": 1,
        },
        "47.6": {
          "7": 1208,
          "8": 494,
        },
        "51.8": {
          "8": 30,
          "9": 2,
        },
        "47.4": {
          "7": 1486,
          "8": 497,
        },
        "46.8": {
          "7": 2398,
          "8": 334,
        },
        "48.4": {
          "7": 522,
          "8": 454,
        },
        "37.6": {
          "5": 39,
          "6": 204,
        },
        "43.4": {
          "6": 1668,
          "7": 5031,
        },
        "39.4": {
          "5": 6,
          "6": 1407,
          "7": 2,
        },
        "48.2": {
          "7": 588,
          "8": 527,
        },
        "50.2": {
          "7": 18,
          "8": 157,
        },
        "39.2": {
          "5": 6,
          "6": 1258,
          "7": 2,
        },
        "40.2": {
          "6": 2387,
          "7": 65,
        },
        "48.6": {
          "7": 355,
          "8": 422,
        },
        "48.8": {
          "7": 278,
          "8": 432,
        },
        "37.8": {
          "5": 40,
          "6": 260,
        },
        "50.8": {
          "7": 7,
          "8": 91,
        },
        "50.4": {
          "7": 22,
          "8": 136,
          "9": 1,
        },
        "37.4": {
          "5": 42,
          "6": 140,
        },
        "49.2": {
          "7": 143,
          "8": 311,
        },
        "49.6": {
          "7": 79,
          "8": 250,
        },
        "51.4": {
          "8": 48,
          "9": 1,
        },
        "49.4": {
          "7": 106,
          "8": 316,
        },
        "36.8": {
          "5": 48,
          "6": 61,
        },
        "51.2": {
          "7": 1,
          "8": 63,
          "9": 1,
        },
        "37.2": {
          "5": 52,
          "6": 120,
        },
        "51.6": {
          "7": 3,
          "8": 35,
          "9": 3,
        },
        "50.6": {
          "7": 10,
          "8": 109,
          "9": 1,
        },
      },
      KO: {
        "19": {
          "2": 62,
          "3": 495,
        },
        "20": {
          "3": 1704,
        },
        "21": {
          "3": 3869,
        },
        "22": {
          "3": 6569,
        },
        "23": {
          "3": 8030,
          "4": 294,
        },
        "24": {
          "3": 5205,
          "4": 2639,
        },
        "25": {
          "3": 1046,
          "4": 4786,
        },
        "26": {
          "3": 32,
          "4": 3309,
        },
        "27": {
          "4": 1375,
        },
        "28": {
          "4": 498,
        },
        "29": {
          "4": 120,
          "5": 11,
        },
        "24.8": {
          "3": 1700,
          "4": 4678,
        },
        "23.6": {
          "3": 6864,
          "4": 1403,
        },
        "22.8": {
          "3": 7850,
          "4": 106,
        },
        "22.2": {
          "3": 6921,
        },
        "25.6": {
          "3": 193,
          "4": 4008,
        },
        "19.6": {
          "2": 5,
          "3": 1177,
        },
        "24.2": {
          "3": 4280,
          "4": 3319,
        },
        "25.2": {
          "3": 639,
          "4": 4722,
        },
        "23.8": {
          "3": 6179,
          "4": 1944,
        },
        "23.4": {
          "3": 7243,
          "4": 901,
        },
        "22.4": {
          "3": 7363,
          "4": 5,
        },
        "24.4": {
          "3": 3427,
          "4": 3839,
        },
        "22.6": {
          "3": 7688,
          "4": 36,
        },
        "24.6": {
          "3": 2415,
          "4": 4359,
        },
        "20.4": {
          "3": 2440,
        },
        "18.8": {
          "2": 93,
          "3": 334,
        },
        "21.4": {
          "3": 4918,
        },
        "20.6": {
          "3": 2844,
        },
        "27.2": {
          "4": 1199,
        },
        "28.4": {
          "4": 296,
          "5": 7,
        },
        "28.6": {
          "4": 219,
          "5": 5,
        },
        "25.4": {
          "3": 320,
          "4": 4429,
        },
        "27.4": {
          "4": 948,
        },
        "25.8": {
          "3": 102,
          "4": 3622,
        },
        "23.2": {
          "3": 7719,
          "4": 527,
        },
        "21.8": {
          "3": 5885,
        },
        "21.2": {
          "3": 4240,
        },
        "21.6": {
          "3": 5452,
        },
        "20.2": {
          "3": 2021,
        },
        "26.6": {
          "4": 2114,
        },
        "19.8": {
          "2": 2,
          "3": 1356,
        },
        "27.6": {
          "4": 733,
        },
        "20.8": {
          "3": 3382,
        },
        "18.2": {
          "2": 518,
          "3": 174,
        },
        "18.6": {
          "2": 95,
          "3": 241,
        },
        "19.2": {
          "2": 37,
          "3": 669,
        },
        "26.2": {
          "3": 7,
          "4": 2865,
        },
        "26.4": {
          "3": 4,
          "4": 2374,
        },
        "26.8": {
          "4": 1742,
        },
        "19.4": {
          "2": 19,
          "3": 953,
        },
        "18.4": {
          "2": 96,
          "3": 147,
        },
        "28.2": {
          "4": 385,
          "5": 1,
        },
        "27.8": {
          "4": 588,
        },
        "28.8": {
          "4": 187,
          "5": 6,
        },
        "29.6": {
          "4": 115,
          "5": 66,
        },
        "29.2": {
          "4": 112,
          "5": 8,
        },
        "29.4": {
          "4": 60,
          "5": 16,
        },
      },
      TD: {
        "10": {
          "1": 471,
        },
        "11": {
          "1": 1512,
        },
        "12": {
          "1": 2305,
          "2": 1204,
        },
        "13": {
          "1": 51,
          "2": 6248,
        },
        "14": {
          "2": 8376,
        },
        "15": {
          "2": 8261,
        },
        "16": {
          "2": 6483,
        },
        "17": {
          "2": 3459,
          "3": 26,
        },
        "18": {
          "2": 786,
          "3": 600,
        },
        "19": {
          "2": 35,
          "3": 477,
        },
        "20": {
          "3": 105,
        },
        "14.4": {
          "2": 8626,
        },
        "13.8": {
          "2": 7869,
        },
        "11.2": {
          "1": 1740,
          "2": 1,
        },
        "15.4": {
          "2": 7566,
        },
        "16.2": {
          "2": 5611,
        },
        "15.2": {
          "2": 7994,
        },
        "12.8": {
          "1": 184,
          "2": 5396,
        },
        "13.6": {
          "2": 7663,
        },
        "14.8": {
          "2": 8410,
        },
        "14.6": {
          "2": 8607,
        },
        "17.6": {
          "2": 1789,
          "3": 352,
        },
        "12.6": {
          "1": 583,
          "2": 4560,
        },
        "13.4": {
          "2": 7030,
        },
        "10.4": {
          "1": 765,
        },
        "14.2": {
          "2": 8653,
        },
        "13.2": {
          "1": 2,
          "2": 6759,
        },
        "9.4": {
          "0": 2,
          "1": 772,
        },
        "15.8": {
          "2": 6650,
        },
        "15.6": {
          "2": 7373,
        },
        "17.2": {
          "2": 2804,
          "3": 96,
        },
        "10.8": {
          "1": 1196,
        },
        "16.6": {
          "2": 4582,
        },
        "11.4": {
          "1": 2124,
          "2": 30,
        },
        "11.8": {
          "1": 2504,
          "2": 544,
        },
        "16.4": {
          "2": 5029,
        },
        "10.6": {
          "1": 999,
        },
        "12.2": {
          "1": 1758,
          "2": 2251,
        },
        "18.2": {
          "2": 464,
          "3": 604,
        },
        "11.6": {
          "1": 2402,
          "2": 166,
        },
        "17.8": {
          "2": 1246,
          "3": 488,
        },
        "9.6": {
          "1": 321,
        },
        "12.4": {
          "1": 1052,
          "2": 3376,
        },
        "17.4": {
          "2": 2257,
          "3": 198,
        },
        "16.8": {
          "2": 3852,
          "3": 3,
        },
        "10.2": {
          "1": 603,
        },
        "18.4": {
          "2": 279,
          "3": 624,
        },
        "19.4": {
          "2": 8,
          "3": 230,
        },
        "20.2": {
          "3": 77,
        },
        "18.8": {
          "2": 64,
          "3": 502,
        },
        "19.6": {
          "3": 191,
        },
        "18.6": {
          "2": 149,
          "3": 561,
        },
        "9.8": {
          "1": 357,
        },
        "19.8": {
          "3": 143,
        },
        "19.2": {
          "2": 15,
          "3": 318,
        },
        "20.6": {
          "3": 133,
        },
        "20.4": {
          "3": 44,
        },
      },
      KONF: {
        "6": {
          "0": 196,
          "1": 111,
        },
        "7": {
          "1": 840,
        },
        "8": {
          "1": 1986,
        },
        "9": {
          "1": 3953,
        },
        "10": {
          "1": 6259,
        },
        "11": {
          "1": 7741,
        },
        "12": {
          "1": 5001,
          "2": 2484,
        },
        "13": {
          "1": 60,
          "2": 5595,
        },
        "14": {
          "2": 3426,
        },
        "15": {
          "2": 1459,
        },
        "16": {
          "2": 521,
        },
        "17": {
          "2": 139,
          "3": 2,
        },
        "18": {
          "2": 40,
          "3": 58,
        },
        "9.4": {
          "1": 4848,
        },
        "11.8": {
          "1": 6470,
          "2": 1278,
        },
        "11.4": {
          "1": 7867,
          "2": 125,
        },
        "10.2": {
          "1": 6715,
        },
        "12.8": {
          "1": 261,
          "2": 6002,
        },
        "11.2": {
          "1": 7751,
          "2": 5,
        },
        "9.2": {
          "1": 4437,
        },
        "13.2": {
          "1": 4,
          "2": 5246,
        },
        "16.4": {
          "2": 349,
        },
        "10.8": {
          "1": 7581,
        },
        "12.4": {
          "1": 1769,
          "2": 5148,
        },
        "10.6": {
          "1": 7275,
        },
        "11.6": {
          "1": 7303,
          "2": 488,
        },
        "13.6": {
          "2": 4280,
        },
        "16.8": {
          "2": 176,
        },
        "12.6": {
          "1": 773,
          "2": 5859,
        },
        "15.6": {
          "2": 825,
        },
        "9.6": {
          "1": 5379,
        },
        "14.4": {
          "2": 2536,
        },
        "14.6": {
          "2": 2215,
        },
        "8.4": {
          "1": 2753,
        },
        "10.4": {
          "1": 7052,
        },
        "15.2": {
          "2": 1291,
        },
        "8.2": {
          "1": 2328,
        },
        "8.8": {
          "1": 3433,
        },
        "14.2": {
          "2": 2961,
        },
        "8.6": {
          "1": 3084,
        },
        "14.8": {
          "2": 1834,
        },
        "12.2": {
          "1": 3179,
          "2": 3934,
        },
        "7.4": {
          "1": 1219,
        },
        "13.8": {
          "2": 3832,
        },
        "13.4": {
          "2": 4735,
        },
        "9.8": {
          "1": 5822,
        },
        "7.6": {
          "1": 1466,
        },
        "15.4": {
          "2": 1021,
        },
        "16.2": {
          "2": 387,
        },
        "6.4": {
          "0": 22,
          "1": 438,
        },
        "5.8": {
          "0": 847,
          "1": 17,
        },
        "15.8": {
          "2": 685,
        },
        "7.2": {
          "1": 1051,
        },
        "7.8": {
          "1": 1709,
        },
        "17.2": {
          "2": 108,
          "3": 5,
        },
        "6.8": {
          "1": 677,
        },
        "6.2": {
          "0": 100,
          "1": 231,
        },
        "6.6": {
          "0": 2,
          "1": 522,
        },
        "17.4": {
          "2": 87,
          "3": 7,
        },
        "16.6": {
          "2": 240,
        },
        "17.6": {
          "2": 39,
          "3": 6,
        },
        "17.8": {
          "2": 36,
          "3": 4,
        },
      },
      LEWICA: {
        "2": {
          "0": 1271,
        },
        "3": {
          "0": 908,
        },
        "4": {
          "0": 2257,
        },
        "5": {
          "0": 4315,
        },
        "6": {
          "0": 4157,
          "1": 2541,
        },
        "7": {
          "1": 8178,
        },
        "8": {
          "1": 7580,
        },
        "9": {
          "1": 5219,
        },
        "10": {
          "1": 2897,
        },
        "11": {
          "1": 1182,
        },
        "12": {
          "1": 186,
          "2": 139,
        },
        "13": {
          "2": 79,
        },
        "9.6": {
          "1": 3853,
        },
        "10.4": {
          "1": 2047,
        },
        "9.4": {
          "1": 4226,
        },
        "5.6": {
          "0": 5857,
          "1": 19,
        },
        "7.2": {
          "1": 8137,
        },
        "9.8": {
          "1": 3366,
        },
        "8.2": {
          "1": 7082,
        },
        "3.6": {
          "0": 1572,
        },
        "6.6": {
          "0": 13,
          "1": 7683,
        },
        "5.8": {
          "0": 5683,
          "1": 567,
        },
        "6.8": {
          "1": 8051,
        },
        "7.4": {
          "1": 8190,
        },
        "4.4": {
          "0": 2914,
        },
        "5.4": {
          "0": 5310,
        },
        "6.4": {
          "0": 277,
          "1": 7176,
        },
        "8.4": {
          "1": 6780,
        },
        "8.6": {
          "1": 6369,
        },
        "9.2": {
          "1": 4835,
        },
        "7.6": {
          "1": 8001,
        },
        "4.6": {
          "0": 3437,
        },
        "4.8": {
          "0": 3925,
        },
        "11.2": {
          "1": 899,
          "2": 1,
        },
        "10.6": {
          "1": 1766,
        },
        "3.8": {
          "0": 1893,
        },
        "2.8": {
          "0": 735,
        },
        "7.8": {
          "1": 7709,
        },
        "6.2": {
          "0": 1669,
          "1": 5434,
        },
        "3.4": {
          "0": 1345,
        },
        "3.2": {
          "0": 1040,
        },
        "8.8": {
          "1": 5785,
        },
        "10.8": {
          "1": 1470,
        },
        "5.2": {
          "0": 4827,
        },
        "11.4": {
          "1": 746,
          "2": 18,
        },
        "4.2": {
          "0": 2582,
        },
        "11.6": {
          "1": 547,
          "2": 42,
        },
        "10.2": {
          "1": 2427,
        },
        "2.4": {
          "0": 489,
        },
        "12.4": {
          "1": 47,
          "2": 156,
        },
        "2.2": {
          "0": 364,
        },
        "2.6": {
          "0": 598,
        },
        "12.2": {
          "1": 89,
          "2": 162,
        },
        "12.8": {
          "1": 2,
          "2": 104,
        },
        "11.8": {
          "1": 353,
          "2": 111,
        },
        "13.2": {
          "2": 59,
        },
        "13.4": {
          "2": 108,
        },
        "12.6": {
          "1": 10,
          "2": 134,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2271,
      TD: 3481,
      LEWICA: 4508,
    },
    potencjalneMandaty: {
      KO: 50112,
      TD: 32965,
      LEWICA: 40247,
    },
    ostatnieMandaty: {
      KO: 43875,
      TD: 20792,
      LEWICA: 14524,
    },
    dawcyGlosow: {
      KO: 5875,
      TD: 9408,
      LEWICA: 11834,
    },
  },
  Gdańsk: {
    procentyWOkreguSrednia: {
      PIS: 24.473460114615367,
      KO: 44.27805852063348,
      TD: 10.760672492797145,
      KONF: 8.054987891984032,
      LEWICA: 12.432820979969982,
    },
    odchylenieWOkregu: {
      PIS: 3.074902457101436,
      KO: 4.1939147953330735,
      TD: 2.013774734692939,
      LEWICA: 1.5779548524624414,
      KONF: 1.8890093108757549,
    },
    wykresRozkladu: {
      "56.2%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "11.1%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "9.5%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
      "7.8%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "6.3%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "3%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "2.5%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.8%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "0.7%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 0,
      },
      "0.5%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "0.4%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "68%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
      "24.2%": {
        DEMO: 9,
        NIEDEMO: 3,
      },
      "7%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "17": {
          "2": 149,
        },
        "18": {
          "2": 358,
        },
        "19": {
          "2": 742,
        },
        "20": {
          "2": 1193,
          "3": 261,
        },
        "21": {
          "2": 633,
          "3": 1819,
        },
        "22": {
          "2": 79,
          "3": 3713,
        },
        "23": {
          "2": 1,
          "3": 5183,
        },
        "24": {
          "3": 5898,
        },
        "25": {
          "3": 5892,
        },
        "26": {
          "3": 4843,
          "4": 249,
        },
        "27": {
          "3": 2680,
          "4": 1135,
        },
        "28": {
          "3": 774,
          "4": 1715,
        },
        "29": {
          "3": 65,
          "4": 1406,
        },
        "30": {
          "3": 3,
          "4": 703,
        },
        "31": {
          "4": 285,
        },
        "32": {
          "4": 130,
          "5": 1,
        },
        "33": {
          "4": 36,
          "5": 4,
        },
        "25.6": {
          "3": 5447,
          "4": 78,
        },
        "22.6": {
          "2": 4,
          "3": 4662,
        },
        "20.8": {
          "2": 789,
          "3": 1473,
        },
        "25.4": {
          "3": 5785,
          "4": 19,
        },
        "26.4": {
          "3": 4017,
          "4": 531,
        },
        "26.2": {
          "3": 4473,
          "4": 399,
        },
        "21.4": {
          "2": 408,
          "3": 2565,
        },
        "26.6": {
          "3": 3616,
          "4": 694,
        },
        "20.4": {
          "2": 1148,
          "3": 768,
        },
        "29.4": {
          "3": 15,
          "4": 1069,
        },
        "23.6": {
          "3": 5682,
        },
        "25.2": {
          "3": 5848,
          "4": 2,
        },
        "19.2": {
          "2": 858,
          "3": 6,
        },
        "23.8": {
          "3": 5800,
        },
        "24.2": {
          "3": 5972,
        },
        "27.4": {
          "3": 1711,
          "4": 1502,
        },
        "21.6": {
          "2": 244,
          "3": 2958,
        },
        "21.8": {
          "2": 153,
          "3": 3407,
        },
        "23.2": {
          "3": 5458,
        },
        "24.8": {
          "3": 6007,
        },
        "23.4": {
          "3": 5520,
        },
        "31.4": {
          "4": 219,
        },
        "28.4": {
          "3": 350,
          "4": 1692,
        },
        "21.2": {
          "2": 482,
          "3": 2235,
        },
        "22.8": {
          "2": 1,
          "3": 4921,
        },
        "26.8": {
          "3": 3094,
          "4": 919,
        },
        "20.6": {
          "2": 1001,
          "3": 1074,
        },
        "24.4": {
          "3": 6021,
        },
        "27.2": {
          "3": 2237,
          "4": 1400,
        },
        "22.4": {
          "2": 17,
          "3": 4375,
        },
        "31.2": {
          "4": 232,
        },
        "25.8": {
          "3": 5193,
          "4": 143,
        },
        "27.8": {
          "3": 1089,
          "4": 1672,
        },
        "24.6": {
          "3": 5869,
        },
        "27.6": {
          "3": 1423,
          "4": 1635,
        },
        "29.2": {
          "3": 45,
          "4": 1085,
        },
        "28.2": {
          "3": 523,
          "4": 1779,
        },
        "20.2": {
          "2": 1172,
          "3": 468,
        },
        "19.6": {
          "2": 1045,
          "3": 70,
        },
        "29.6": {
          "3": 11,
          "4": 888,
        },
        "17.8": {
          "2": 277,
        },
        "18.8": {
          "2": 648,
        },
        "28.6": {
          "3": 218,
          "4": 1569,
        },
        "30.2": {
          "3": 3,
          "4": 584,
        },
        "22.2": {
          "2": 44,
          "3": 4052,
        },
        "28.8": {
          "3": 134,
          "4": 1463,
        },
        "30.6": {
          "4": 458,
        },
        "29.8": {
          "3": 5,
          "4": 823,
        },
        "19.8": {
          "2": 1136,
          "3": 156,
        },
        "18.6": {
          "2": 547,
        },
        "19.4": {
          "2": 934,
          "3": 27,
        },
        "18.4": {
          "2": 435,
        },
        "31.8": {
          "4": 143,
        },
        "18.2": {
          "2": 360,
        },
        "17.6": {
          "2": 245,
        },
        "30.4": {
          "4": 512,
        },
        "16.8": {
          "1": 19,
          "2": 494,
        },
        "17.4": {
          "2": 174,
        },
        "32.6": {
          "4": 56,
          "5": 4,
        },
        "32.8": {
          "4": 49,
          "5": 7,
        },
        "30.8": {
          "4": 369,
        },
        "17.2": {
          "2": 134,
        },
        "32.2": {
          "4": 95,
          "5": 1,
        },
        "32.4": {
          "4": 68,
          "5": 3,
        },
        "31.6": {
          "4": 189,
        },
        "33.2": {
          "4": 23,
          "5": 7,
        },
        "33.6": {
          "4": 61,
          "5": 35,
        },
        "33.4": {
          "4": 17,
          "5": 4,
        },
      },
      KO: {
        "34": {
          "4": 15,
          "5": 6,
        },
        "35": {
          "4": 16,
          "5": 38,
        },
        "36": {
          "4": 8,
          "5": 151,
        },
        "37": {
          "4": 4,
          "5": 333,
        },
        "38": {
          "5": 662,
        },
        "39": {
          "5": 1131,
          "6": 49,
        },
        "40": {
          "5": 1560,
          "6": 406,
        },
        "41": {
          "5": 1493,
          "6": 1379,
        },
        "42": {
          "5": 958,
          "6": 2940,
        },
        "43": {
          "5": 429,
          "6": 4443,
        },
        "44": {
          "5": 104,
          "6": 5033,
          "7": 2,
        },
        "45": {
          "5": 14,
          "6": 5027,
          "7": 135,
        },
        "46": {
          "6": 4077,
          "7": 467,
        },
        "47": {
          "6": 2669,
          "7": 864,
        },
        "48": {
          "6": 1372,
          "7": 1155,
        },
        "49": {
          "6": 527,
          "7": 1048,
        },
        "50": {
          "6": 143,
          "7": 743,
        },
        "51": {
          "6": 25,
          "7": 424,
          "8": 2,
        },
        "52": {
          "6": 1,
          "7": 213,
          "8": 15,
        },
        "53": {
          "7": 73,
          "8": 16,
        },
        "54": {
          "7": 19,
          "8": 6,
        },
        "55": {
          "7": 5,
          "8": 6,
        },
        "56": {
          "8": 4,
        },
        "41.2": {
          "5": 1343,
          "6": 1700,
        },
        "45.4": {
          "5": 5,
          "6": 4722,
          "7": 228,
        },
        "49.2": {
          "6": 377,
          "7": 998,
        },
        "51.6": {
          "6": 4,
          "7": 299,
          "8": 10,
        },
        "44.4": {
          "5": 60,
          "6": 5103,
          "7": 22,
        },
        "42.2": {
          "5": 825,
          "6": 3223,
        },
        "41.8": {
          "5": 1076,
          "6": 2745,
        },
        "42.8": {
          "5": 512,
          "6": 4180,
        },
        "45.8": {
          "5": 1,
          "6": 4253,
          "7": 391,
        },
        "40.8": {
          "5": 1559,
          "6": 1156,
        },
        "49.4": {
          "6": 358,
          "7": 967,
        },
        "42.6": {
          "5": 619,
          "6": 3834,
        },
        "45.2": {
          "5": 5,
          "6": 4908,
          "7": 138,
        },
        "35.6": {
          "4": 16,
          "5": 99,
        },
        "44.6": {
          "5": 40,
          "6": 5085,
          "7": 43,
        },
        "39.8": {
          "5": 1476,
          "6": 293,
        },
        "41.4": {
          "5": 1377,
          "6": 1992,
        },
        "44.2": {
          "5": 66,
          "6": 5057,
          "7": 4,
        },
        "41.6": {
          "5": 1214,
          "6": 2368,
        },
        "43.8": {
          "5": 153,
          "6": 5065,
        },
        "43.4": {
          "5": 267,
          "6": 4585,
        },
        "44.8": {
          "5": 29,
          "6": 4991,
          "7": 74,
        },
        "42.4": {
          "5": 679,
          "6": 3503,
        },
        "46.8": {
          "6": 2919,
          "7": 746,
        },
        "49.8": {
          "6": 212,
          "7": 795,
        },
        "43.2": {
          "5": 317,
          "6": 4672,
        },
        "47.2": {
          "6": 2423,
          "7": 941,
        },
        "35.8": {
          "4": 8,
          "5": 135,
        },
        "46.2": {
          "6": 3751,
          "7": 513,
        },
        "39.6": {
          "5": 1377,
          "6": 178,
        },
        "46.4": {
          "6": 3532,
          "7": 592,
        },
        "48.4": {
          "6": 969,
          "7": 1101,
        },
        "47.8": {
          "6": 1627,
          "7": 1160,
        },
        "49.6": {
          "6": 272,
          "7": 927,
        },
        "40.4": {
          "5": 1607,
          "6": 708,
        },
        "45.6": {
          "5": 3,
          "6": 4392,
          "7": 287,
        },
        "47.4": {
          "6": 2074,
          "7": 938,
        },
        "46.6": {
          "6": 3282,
          "7": 678,
        },
        "43.6": {
          "5": 193,
          "6": 4892,
        },
        "40.2": {
          "5": 1567,
          "6": 568,
        },
        "38.8": {
          "5": 1021,
          "6": 21,
        },
        "47.6": {
          "6": 1847,
          "7": 1047,
        },
        "48.2": {
          "6": 1223,
          "7": 1120,
        },
        "38.6": {
          "5": 949,
          "6": 18,
        },
        "40.6": {
          "5": 1571,
          "6": 903,
        },
        "39.2": {
          "5": 1284,
          "6": 90,
        },
        "48.8": {
          "6": 675,
          "7": 1085,
        },
        "38.4": {
          "5": 814,
          "6": 12,
        },
        "36.8": {
          "4": 5,
          "5": 274,
        },
        "39.4": {
          "5": 1325,
          "6": 88,
        },
        "37.6": {
          "4": 1,
          "5": 439,
        },
        "37.4": {
          "4": 2,
          "5": 407,
        },
        "38.2": {
          "5": 750,
          "6": 2,
        },
        "50.4": {
          "6": 69,
          "7": 620,
        },
        "50.2": {
          "6": 110,
          "7": 667,
        },
        "50.8": {
          "6": 32,
          "7": 498,
          "8": 2,
        },
        "48.6": {
          "6": 792,
          "7": 1100,
        },
        "51.4": {
          "6": 12,
          "7": 333,
          "8": 4,
        },
        "50.6": {
          "6": 48,
          "7": 574,
          "8": 2,
        },
        "37.8": {
          "5": 564,
          "6": 1,
        },
        "51.2": {
          "6": 18,
          "7": 382,
          "8": 3,
        },
        "36.4": {
          "4": 9,
          "5": 190,
        },
        "52.4": {
          "7": 122,
          "8": 22,
        },
        "33.8": {
          "4": 73,
          "5": 17,
        },
        "51.8": {
          "6": 4,
          "7": 233,
          "8": 8,
        },
        "36.6": {
          "4": 12,
          "5": 212,
        },
        "55.8": {
          "7": 1,
          "8": 3,
        },
        "52.2": {
          "7": 159,
          "8": 10,
        },
        "54.4": {
          "7": 8,
          "8": 14,
        },
        "36.2": {
          "4": 13,
          "5": 159,
        },
        "35.4": {
          "4": 17,
          "5": 50,
        },
        "52.8": {
          "7": 105,
          "8": 13,
        },
        "35.2": {
          "4": 25,
          "5": 62,
        },
        "53.2": {
          "7": 62,
          "8": 20,
        },
        "55.4": {
          "7": 1,
          "8": 1,
        },
        "53.8": {
          "7": 32,
          "8": 12,
        },
        "37.2": {
          "4": 5,
          "5": 356,
        },
        "52.6": {
          "7": 114,
          "8": 13,
        },
        "34.4": {
          "4": 20,
          "5": 17,
        },
        "53.6": {
          "7": 43,
          "8": 13,
        },
        "34.6": {
          "4": 18,
          "5": 29,
        },
        "34.2": {
          "4": 16,
          "5": 11,
        },
        "54.2": {
          "7": 19,
          "8": 12,
        },
        "54.6": {
          "7": 13,
          "8": 14,
        },
        "34.8": {
          "4": 16,
          "5": 35,
        },
        "54.8": {
          "7": 11,
          "8": 6,
        },
        "56.4": {
          "7": 1,
          "8": 3,
        },
        "53.4": {
          "7": 42,
          "8": 12,
        },
        "55.6": {
          "7": 5,
          "8": 6,
        },
        "56.6": {
          "7": 2,
        },
        "56.2": {
          "7": 1,
          "8": 2,
        },
        "56.8": {
          "8": 5,
        },
        "55.2": {
          "7": 1,
          "8": 3,
        },
      },
      TD: {
        "6": {
          "0": 398,
        },
        "7": {
          "0": 351,
          "1": 821,
        },
        "8": {
          "1": 2898,
        },
        "9": {
          "1": 5543,
        },
        "10": {
          "1": 7713,
        },
        "11": {
          "1": 8451,
        },
        "12": {
          "1": 6773,
        },
        "13": {
          "1": 3987,
          "2": 188,
        },
        "14": {
          "1": 612,
          "2": 1409,
        },
        "15": {
          "1": 2,
          "2": 669,
        },
        "16": {
          "2": 196,
        },
        "10.8": {
          "1": 8219,
        },
        "12.6": {
          "1": 5325,
          "2": 3,
        },
        "8.4": {
          "1": 3796,
        },
        "10.4": {
          "1": 8413,
        },
        "12.4": {
          "1": 5699,
        },
        "11.4": {
          "1": 8025,
        },
        "9.8": {
          "1": 7332,
        },
        "11.2": {
          "1": 8096,
        },
        "11.6": {
          "1": 7590,
        },
        "8.8": {
          "1": 4877,
        },
        "10.6": {
          "1": 8355,
        },
        "10.2": {
          "1": 8100,
        },
        "11.8": {
          "1": 7061,
        },
        "14.8": {
          "1": 12,
          "2": 877,
        },
        "14.6": {
          "1": 50,
          "2": 979,
        },
        "13.4": {
          "1": 2492,
          "2": 681,
        },
        "9.2": {
          "1": 5991,
        },
        "9.4": {
          "1": 6486,
        },
        "9.6": {
          "1": 7065,
        },
        "12.2": {
          "1": 6229,
        },
        "14.2": {
          "1": 295,
          "2": 1309,
        },
        "7.4": {
          "0": 43,
          "1": 1741,
        },
        "8.6": {
          "1": 4465,
        },
        "7.8": {
          "1": 2584,
        },
        "12.8": {
          "1": 4622,
          "2": 54,
        },
        "16.4": {
          "2": 104,
        },
        "13.2": {
          "1": 3215,
          "2": 405,
        },
        "14.4": {
          "1": 131,
          "2": 1235,
        },
        "15.2": {
          "1": 1,
          "2": 531,
        },
        "7.6": {
          "0": 5,
          "1": 2165,
        },
        "13.8": {
          "1": 1028,
          "2": 1291,
        },
        "13.6": {
          "1": 1704,
          "2": 977,
        },
        "8.2": {
          "1": 3353,
        },
        "6.4": {
          "0": 589,
          "1": 7,
        },
        "6.6": {
          "0": 693,
          "1": 90,
        },
        "5.8": {
          "0": 1113,
        },
        "6.8": {
          "0": 647,
          "1": 371,
        },
        "7.2": {
          "0": 142,
          "1": 1337,
        },
        "6.2": {
          "0": 496,
        },
        "16.8": {
          "2": 202,
          "3": 1,
        },
        "16.2": {
          "2": 162,
        },
        "15.4": {
          "2": 438,
        },
        "15.8": {
          "2": 268,
        },
        "15.6": {
          "2": 337,
        },
        "16.6": {
          "2": 85,
        },
      },
      KONF: {
        "4": {
          "0": 757,
        },
        "5": {
          "0": 2100,
        },
        "6": {
          "0": 4533,
        },
        "7": {
          "0": 1651,
          "1": 5828,
        },
        "8": {
          "1": 8798,
        },
        "9": {
          "1": 7808,
        },
        "10": {
          "1": 4905,
        },
        "11": {
          "1": 2234,
        },
        "12": {
          "1": 788,
        },
        "13": {
          "1": 191,
          "2": 14,
        },
        "9.2": {
          "1": 7172,
        },
        "7.6": {
          "0": 2,
          "1": 8641,
        },
        "7.8": {
          "1": 8841,
        },
        "8.8": {
          "1": 8160,
        },
        "6.6": {
          "0": 5302,
          "1": 1187,
        },
        "9.8": {
          "1": 5524,
        },
        "11.8": {
          "1": 974,
        },
        "6.8": {
          "0": 3476,
          "1": 3464,
        },
        "5.4": {
          "0": 2983,
        },
        "5.8": {
          "0": 3979,
        },
        "3.4": {
          "0": 1282,
        },
        "6.2": {
          "0": 5200,
        },
        "10.8": {
          "1": 2667,
        },
        "8.6": {
          "1": 8494,
        },
        "9.4": {
          "1": 6729,
        },
        "7.2": {
          "0": 486,
          "1": 7315,
        },
        "11.4": {
          "1": 1607,
        },
        "9.6": {
          "1": 6084,
        },
        "7.4": {
          "0": 70,
          "1": 8141,
        },
        "10.2": {
          "1": 4379,
        },
        "8.4": {
          "1": 8783,
        },
        "6.4": {
          "0": 5688,
          "1": 136,
        },
        "8.2": {
          "1": 8690,
        },
        "12.4": {
          "1": 444,
        },
        "5.6": {
          "0": 3540,
        },
        "4.8": {
          "0": 1848,
        },
        "3.6": {
          "0": 453,
        },
        "3.8": {
          "0": 577,
        },
        "10.6": {
          "1": 3159,
        },
        "11.2": {
          "1": 1866,
        },
        "10.4": {
          "1": 3818,
        },
        "5.2": {
          "0": 2556,
        },
        "11.6": {
          "1": 1284,
        },
        "12.2": {
          "1": 646,
        },
        "12.6": {
          "1": 385,
          "2": 1,
        },
        "4.2": {
          "0": 919,
        },
        "4.4": {
          "0": 1164,
        },
        "4.6": {
          "0": 1465,
        },
        "12.8": {
          "1": 285,
          "2": 5,
        },
        "13.8": {
          "1": 43,
          "2": 120,
        },
        "13.6": {
          "1": 56,
          "2": 41,
        },
        "13.2": {
          "1": 132,
          "2": 18,
        },
        "13.4": {
          "1": 83,
          "2": 29,
        },
      },
      LEWICA: {
        "9": {
          "1": 906,
        },
        "10": {
          "1": 3045,
        },
        "11": {
          "1": 6748,
        },
        "12": {
          "1": 9822,
        },
        "13": {
          "1": 9080,
          "2": 336,
        },
        "14": {
          "1": 2417,
          "2": 3628,
        },
        "15": {
          "1": 29,
          "2": 2649,
        },
        "16": {
          "2": 821,
        },
        "17": {
          "2": 228,
        },
        "13.4": {
          "1": 6893,
          "2": 1465,
        },
        "10.2": {
          "1": 3668,
        },
        "10.8": {
          "1": 6059,
        },
        "15.6": {
          "2": 1405,
        },
        "13.6": {
          "1": 5323,
          "2": 2337,
        },
        "13.2": {
          "1": 8218,
          "2": 803,
        },
        "12.2": {
          "1": 10225,
        },
        "16.2": {
          "2": 624,
        },
        "11.8": {
          "1": 9429,
        },
        "13.8": {
          "1": 3725,
          "2": 3073,
        },
        "14.4": {
          "1": 706,
          "2": 3767,
        },
        "10.6": {
          "1": 5201,
        },
        "11.6": {
          "1": 8900,
        },
        "11.2": {
          "1": 7537,
        },
        "12.4": {
          "1": 10180,
        },
        "12.6": {
          "1": 10183,
          "2": 8,
        },
        "14.2": {
          "1": 1443,
          "2": 3880,
        },
        "8.4": {
          "0": 32,
          "1": 896,
        },
        "11.4": {
          "1": 8360,
        },
        "9.6": {
          "1": 1958,
        },
        "12.8": {
          "1": 9629,
          "2": 78,
        },
        "15.4": {
          "2": 1766,
        },
        "14.8": {
          "1": 100,
          "2": 3010,
        },
        "14.6": {
          "1": 328,
          "2": 3522,
        },
        "9.4": {
          "1": 1524,
        },
        "10.4": {
          "1": 4507,
        },
        "9.8": {
          "1": 2415,
        },
        "16.6": {
          "2": 378,
        },
        "9.2": {
          "1": 1123,
        },
        "15.2": {
          "1": 2,
          "2": 2128,
        },
        "16.4": {
          "2": 505,
        },
        "17.2": {
          "2": 496,
        },
        "15.8": {
          "2": 1164,
        },
        "8.8": {
          "1": 606,
        },
        "16.8": {
          "2": 256,
        },
        "8.6": {
          "1": 456,
        },
      },
    },
    minimumMandatow: {
      KO: 4,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2059,
      TD: 1986,
      LEWICA: 1833,
    },
    potencjalneMandaty: {
      KO: 66288,
      TD: 31478,
      LEWICA: 60090,
    },
    ostatnieMandaty: {
      KO: 66653,
      TD: 11920,
      LEWICA: 24449,
    },
    dawcyGlosow: {
      KO: 5145,
      TD: 5241,
      LEWICA: 3718,
    },
  },
  Gdynia: {
    procentyWOkreguSrednia: {
      PIS: 28.81445058198593,
      KO: 38.76894892996907,
      TD: 12.662724033261648,
      KONF: 9.022542086725272,
      LEWICA: 10.731334368058066,
    },
    odchylenieWOkregu: {
      PIS: 2.772151013324043,
      KO: 2.2919353611644486,
      TD: 2.013774734692939,
      LEWICA: 0.792796260668243,
      KONF: 0.9075844341778783,
    },
    wykresRozkladu: {
      "54.6%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "21.2%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "8.4%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "8%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "4.7%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "1%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.9%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.5%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.4%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.1%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "68.4%": {
        DEMO: 9,
        NIEDEMO: 5,
      },
      "29.9%": {
        DEMO: 8,
        NIEDEMO: 6,
      },
      "1.6%": {
        DEMO: 10,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "22": {
          "3": 77,
        },
        "23": {
          "3": 252,
          "4": 17,
        },
        "24": {
          "3": 272,
          "4": 435,
        },
        "25": {
          "3": 49,
          "4": 1489,
        },
        "26": {
          "3": 2,
          "4": 3259,
        },
        "27": {
          "4": 5084,
        },
        "28": {
          "4": 6582,
          "5": 3,
        },
        "29": {
          "4": 6393,
          "5": 964,
        },
        "30": {
          "4": 3359,
          "5": 2956,
        },
        "31": {
          "4": 555,
          "5": 3885,
        },
        "32": {
          "4": 2,
          "5": 2489,
        },
        "33": {
          "5": 1224,
        },
        "34": {
          "5": 396,
          "6": 1,
        },
        "35": {
          "5": 115,
          "6": 22,
        },
        "36": {
          "5": 15,
          "6": 19,
        },
        "37": {
          "5": 1,
          "6": 2,
        },
        "22.6": {
          "3": 138,
          "4": 2,
        },
        "27.6": {
          "4": 6268,
        },
        "27.2": {
          "4": 5455,
        },
        "24.6": {
          "3": 113,
          "4": 1103,
        },
        "27.4": {
          "4": 5864,
        },
        "31.8": {
          "4": 25,
          "5": 2812,
        },
        "31.2": {
          "4": 286,
          "5": 3748,
        },
        "25.6": {
          "3": 5,
          "4": 2465,
        },
        "25.4": {
          "3": 16,
          "4": 2177,
        },
        "25.8": {
          "3": 3,
          "4": 2739,
        },
        "28.6": {
          "4": 7105,
          "5": 278,
        },
        "34.4": {
          "5": 230,
          "6": 5,
        },
        "26.4": {
          "4": 3963,
        },
        "29.4": {
          "4": 5327,
          "5": 1758,
        },
        "29.2": {
          "4": 5828,
          "5": 1328,
        },
        "27.8": {
          "4": 6470,
        },
        "31.4": {
          "4": 148,
          "5": 3553,
        },
        "30.6": {
          "4": 1492,
          "5": 3674,
        },
        "29.6": {
          "4": 4669,
          "5": 2309,
        },
        "28.4": {
          "4": 7194,
          "5": 94,
        },
        "30.4": {
          "4": 2028,
          "5": 3522,
        },
        "31.6": {
          "4": 48,
          "5": 3154,
        },
        "26.8": {
          "4": 4597,
        },
        "28.8": {
          "4": 6656,
          "5": 552,
        },
        "30.8": {
          "4": 961,
          "5": 3915,
        },
        "24.8": {
          "3": 73,
          "4": 1319,
        },
        "28.2": {
          "4": 6830,
          "5": 33,
        },
        "30.2": {
          "4": 2793,
          "5": 3311,
        },
        "26.2": {
          "4": 3585,
        },
        "36.6": {
          "5": 7,
          "6": 11,
        },
        "26.6": {
          "4": 4268,
        },
        "29.8": {
          "4": 4097,
          "5": 2592,
        },
        "33.8": {
          "5": 508,
        },
        "24.2": {
          "3": 222,
          "4": 616,
        },
        "32.4": {
          "5": 1842,
        },
        "25.2": {
          "3": 39,
          "4": 1805,
        },
        "23.2": {
          "3": 246,
          "4": 33,
        },
        "32.6": {
          "5": 1593,
        },
        "32.2": {
          "4": 1,
          "5": 2257,
        },
        "23.8": {
          "3": 269,
          "4": 349,
        },
        "24.4": {
          "3": 150,
          "4": 866,
        },
        "22.8": {
          "3": 186,
          "4": 6,
        },
        "33.2": {
          "5": 958,
        },
        "33.6": {
          "5": 650,
        },
        "32.8": {
          "5": 1411,
        },
        "33.4": {
          "5": 769,
        },
        "22.2": {
          "3": 98,
        },
        "21.8": {
          "3": 218,
        },
        "34.2": {
          "5": 318,
          "6": 3,
        },
        "34.8": {
          "5": 142,
          "6": 16,
        },
        "23.6": {
          "3": 336,
          "4": 186,
        },
        "22.4": {
          "3": 137,
        },
        "23.4": {
          "3": 272,
          "4": 90,
        },
        "35.2": {
          "5": 62,
          "6": 11,
        },
        "34.6": {
          "5": 193,
          "6": 10,
        },
        "35.8": {
          "5": 21,
          "6": 23,
        },
        "37.2": {
          "6": 13,
        },
        "35.6": {
          "5": 33,
          "6": 20,
        },
        "36.2": {
          "5": 3,
          "6": 14,
        },
        "35.4": {
          "5": 49,
          "6": 23,
        },
        "36.4": {
          "5": 4,
          "6": 5,
        },
        "36.8": {
          "5": 1,
          "6": 8,
        },
      },
      KO: {
        "33": {
          "4": 2,
          "5": 374,
        },
        "34": {
          "5": 408,
          "6": 6,
        },
        "35": {
          "5": 1100,
          "6": 201,
        },
        "36": {
          "5": 1510,
          "6": 1436,
        },
        "37": {
          "5": 739,
          "6": 4820,
        },
        "38": {
          "5": 57,
          "6": 7601,
        },
        "39": {
          "5": 2,
          "6": 7833,
        },
        "40": {
          "6": 6453,
          "7": 209,
        },
        "41": {
          "6": 3147,
          "7": 1038,
        },
        "42": {
          "6": 904,
          "7": 1223,
        },
        "43": {
          "6": 134,
          "7": 730,
        },
        "44": {
          "6": 11,
          "7": 272,
        },
        "45": {
          "7": 93,
        },
        "38.8": {
          "5": 2,
          "6": 7973,
        },
        "41.2": {
          "6": 2665,
          "7": 1151,
        },
        "39.6": {
          "5": 1,
          "6": 7186,
          "7": 52,
        },
        "40.4": {
          "6": 5134,
          "7": 546,
        },
        "40.6": {
          "6": 4418,
          "7": 753,
        },
        "40.8": {
          "6": 3820,
          "7": 976,
        },
        "38.6": {
          "5": 8,
          "6": 8039,
        },
        "39.2": {
          "6": 7816,
          "7": 1,
        },
        "39.8": {
          "6": 6700,
          "7": 114,
        },
        "36.4": {
          "5": 1353,
          "6": 2551,
        },
        "38.2": {
          "5": 35,
          "6": 7616,
        },
        "37.8": {
          "5": 116,
          "6": 7069,
        },
        "42.2": {
          "6": 734,
          "7": 1088,
        },
        "39.4": {
          "6": 7576,
          "7": 14,
        },
        "35.6": {
          "5": 1496,
          "6": 725,
        },
        "37.2": {
          "5": 474,
          "6": 5415,
        },
        "42.4": {
          "6": 492,
          "7": 1042,
        },
        "36.8": {
          "5": 931,
          "6": 4053,
        },
        "34.4": {
          "5": 719,
          "6": 34,
        },
        "38.4": {
          "5": 11,
          "6": 7876,
        },
        "34.2": {
          "5": 543,
          "6": 10,
        },
        "37.6": {
          "5": 188,
          "6": 6662,
        },
        "42.6": {
          "6": 347,
          "7": 968,
        },
        "36.2": {
          "5": 1511,
          "6": 2121,
        },
        "41.4": {
          "6": 2062,
          "7": 1236,
        },
        "36.6": {
          "5": 1145,
          "6": 3331,
        },
        "40.2": {
          "6": 5804,
          "7": 353,
        },
        "34.6": {
          "5": 791,
          "6": 58,
        },
        "43.6": {
          "6": 29,
          "7": 429,
        },
        "37.4": {
          "5": 317,
          "6": 6062,
        },
        "35.8": {
          "5": 1509,
          "6": 1121,
        },
        "41.6": {
          "6": 1646,
          "7": 1324,
        },
        "44.4": {
          "6": 1,
          "7": 164,
        },
        "35.2": {
          "5": 1216,
          "6": 301,
        },
        "41.8": {
          "6": 1255,
          "7": 1226,
        },
        "43.4": {
          "6": 31,
          "7": 523,
        },
        "33.4": {
          "5": 181,
        },
        "42.8": {
          "6": 225,
          "7": 817,
        },
        "35.4": {
          "5": 1420,
          "6": 511,
        },
        "43.2": {
          "6": 81,
          "7": 653,
        },
        "34.8": {
          "5": 935,
          "6": 109,
        },
        "44.6": {
          "6": 1,
          "7": 111,
        },
        "33.2": {
          "5": 126,
        },
        "33.8": {
          "5": 324,
        },
        "44.8": {
          "7": 104,
        },
        "45.6": {
          "7": 102,
          "8": 11,
        },
        "43.8": {
          "6": 10,
          "7": 331,
        },
        "33.6": {
          "5": 258,
        },
        "44.2": {
          "6": 5,
          "7": 223,
        },
        "45.2": {
          "7": 61,
        },
        "45.4": {
          "7": 39,
        },
      },
      TD: {
        "8": {
          "1": 392,
        },
        "9": {
          "1": 1185,
        },
        "10": {
          "1": 3007,
        },
        "11": {
          "1": 5550,
        },
        "12": {
          "1": 3182,
          "2": 4914,
        },
        "13": {
          "1": 1,
          "2": 8599,
        },
        "14": {
          "2": 6853,
        },
        "15": {
          "2": 3869,
        },
        "16": {
          "2": 1571,
        },
        "17": {
          "2": 478,
          "3": 2,
        },
        "18": {
          "2": 52,
          "3": 42,
        },
        "12.4": {
          "1": 665,
          "2": 8025,
        },
        "12.8": {
          "1": 16,
          "2": 8699,
        },
        "16.2": {
          "2": 1288,
        },
        "12.2": {
          "1": 1811,
          "2": 6729,
        },
        "15.6": {
          "2": 2412,
        },
        "11.4": {
          "1": 6575,
          "2": 231,
        },
        "13.4": {
          "2": 8023,
        },
        "9.8": {
          "1": 2627,
        },
        "13.6": {
          "2": 7784,
        },
        "9.6": {
          "1": 2146,
        },
        "15.8": {
          "2": 1942,
        },
        "13.2": {
          "2": 8458,
        },
        "10.6": {
          "1": 4567,
        },
        "10.8": {
          "1": 5073,
        },
        "13.8": {
          "2": 7215,
        },
        "12.6": {
          "1": 155,
          "2": 8535,
        },
        "8.6": {
          "1": 800,
        },
        "14.2": {
          "2": 6222,
        },
        "14.6": {
          "2": 5062,
        },
        "11.6": {
          "1": 6120,
          "2": 1262,
        },
        "15.2": {
          "2": 3272,
        },
        "15.4": {
          "2": 2835,
        },
        "11.8": {
          "1": 4797,
          "2": 2981,
        },
        "11.2": {
          "1": 6345,
          "2": 12,
        },
        "14.8": {
          "2": 4332,
        },
        "10.2": {
          "1": 3488,
        },
        "14.4": {
          "2": 5535,
        },
        "8.2": {
          "1": 508,
        },
        "9.4": {
          "1": 1820,
        },
        "10.4": {
          "1": 4044,
        },
        "17.4": {
          "2": 265,
          "3": 16,
        },
        "7.6": {
          "0": 38,
          "1": 774,
        },
        "9.2": {
          "1": 1520,
        },
        "16.4": {
          "2": 1018,
        },
        "16.6": {
          "2": 824,
        },
        "8.4": {
          "1": 608,
        },
        "17.6": {
          "2": 176,
          "3": 33,
        },
        "18.2": {
          "2": 24,
          "3": 44,
        },
        "8.8": {
          "1": 963,
        },
        "16.8": {
          "2": 622,
        },
        "7.8": {
          "1": 302,
        },
        "17.2": {
          "2": 337,
          "3": 9,
        },
        "18.6": {
          "2": 3,
          "3": 38,
        },
        "18.4": {
          "2": 13,
          "3": 33,
        },
        "18.8": {
          "2": 2,
          "3": 78,
        },
        "17.8": {
          "2": 99,
          "3": 53,
        },
      },
      KONF: {
        "7": {
          "1": 1348,
        },
        "8": {
          "1": 9300,
        },
        "9": {
          "1": 17667,
        },
        "10": {
          "1": 9806,
        },
        "11": {
          "1": 1768,
        },
        "7.8": {
          "1": 6907,
        },
        "10.2": {
          "1": 7451,
        },
        "8.2": {
          "1": 11908,
        },
        "11.8": {
          "1": 205,
          "2": 206,
        },
        "8.4": {
          "1": 13836,
        },
        "9.2": {
          "1": 17010,
        },
        "6.8": {
          "0": 96,
          "1": 1604,
        },
        "8.6": {
          "1": 16019,
        },
        "8.8": {
          "1": 17128,
        },
        "10.4": {
          "1": 5621,
        },
        "10.8": {
          "1": 2776,
        },
        "9.4": {
          "1": 16097,
        },
        "7.6": {
          "1": 5139,
        },
        "11.2": {
          "1": 1097,
          "2": 1,
        },
        "7.4": {
          "1": 3474,
        },
        "7.2": {
          "1": 2227,
        },
        "9.8": {
          "1": 11977,
        },
        "10.6": {
          "1": 4028,
        },
        "9.6": {
          "1": 14307,
        },
        "11.4": {
          "1": 610,
          "2": 23,
        },
        "11.6": {
          "1": 314,
          "2": 50,
        },
      },
      LEWICA: {
        "9": {
          "1": 2145,
        },
        "10": {
          "1": 13088,
        },
        "11": {
          "1": 17566,
        },
        "12": {
          "1": 3944,
          "2": 2251,
        },
        "13": {
          "1": 4,
          "2": 603,
        },
        "8.8": {
          "1": 2453,
        },
        "12.6": {
          "1": 239,
          "2": 1545,
        },
        "11.2": {
          "1": 16131,
          "2": 13,
        },
        "9.4": {
          "1": 5419,
        },
        "10.2": {
          "1": 15872,
        },
        "10.6": {
          "1": 18835,
        },
        "10.4": {
          "1": 17702,
        },
        "10.8": {
          "1": 18745,
        },
        "11.8": {
          "1": 6707,
          "2": 1707,
        },
        "11.4": {
          "1": 13379,
          "2": 189,
        },
        "12.4": {
          "1": 769,
          "2": 2083,
        },
        "11.6": {
          "1": 10004,
          "2": 782,
        },
        "12.2": {
          "1": 1861,
          "2": 2479,
        },
        "9.8": {
          "1": 10395,
        },
        "12.8": {
          "1": 41,
          "2": 1027,
        },
        "9.2": {
          "1": 3526,
        },
        "9.6": {
          "1": 7745,
        },
        "13.2": {
          "2": 751,
        },
      },
    },
    minimumMandatow: {
      KO: 4,
      TD: 0,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 2427,
      TD: 4788,
      LEWICA: 493,
    },
    potencjalneMandaty: {
      KO: 53633,
      TD: 49346,
      LEWICA: 59562,
    },
    ostatnieMandaty: {
      KO: 58493,
      TD: 39853,
      LEWICA: 11568,
    },
    dawcyGlosow: {
      KO: 6230,
      TD: 11684,
      LEWICA: 680,
    },
  },
  "Bielsko-Biała": {
    procentyWOkreguSrednia: {
      PIS: 38.562456989453224,
      KO: 28.65044572291147,
      TD: 11.653389289592747,
      KONF: 10.35938240221373,
      LEWICA: 10.77432559582883,
    },
    odchylenieWOkregu: {
      PIS: 4.163814642931923,
      KO: 1.8456362847165604,
      TD: 2.013774734692939,
      LEWICA: 1.074651775352692,
      KONF: 1.0615303776213987,
    },
    wykresRozkladu: {
      "48%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "34.2%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "9.9%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "7.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.2%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 0,
      },
      "0%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "51.2%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
      "48.1%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
      "0.7%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
    },
    histogram: {
      PIS: {
        "29": {
          "3": 32,
        },
        "30": {
          "3": 84,
        },
        "31": {
          "3": 220,
        },
        "32": {
          "3": 456,
        },
        "33": {
          "3": 901,
          "4": 2,
        },
        "34": {
          "3": 1531,
          "4": 29,
        },
        "35": {
          "3": 2444,
          "4": 110,
        },
        "36": {
          "3": 3102,
          "4": 465,
        },
        "37": {
          "3": 3161,
          "4": 1499,
        },
        "38": {
          "3": 1800,
          "4": 3620,
        },
        "39": {
          "3": 330,
          "4": 5387,
        },
        "40": {
          "4": 5058,
        },
        "41": {
          "4": 4046,
          "5": 2,
        },
        "42": {
          "4": 2695,
          "5": 18,
        },
        "43": {
          "4": 1651,
          "5": 62,
        },
        "44": {
          "4": 636,
          "5": 156,
        },
        "45": {
          "4": 174,
          "5": 180,
        },
        "46": {
          "4": 21,
          "5": 111,
        },
        "47": {
          "5": 37,
        },
        "48": {
          "5": 10,
        },
        "49": {
          "5": 1,
        },
        "50": {
          "5": 1,
        },
        "40.8": {
          "4": 4295,
          "5": 3,
        },
        "39.6": {
          "3": 24,
          "4": 5289,
        },
        "37.6": {
          "3": 2465,
          "4": 2836,
        },
        "41.2": {
          "4": 3737,
          "5": 4,
        },
        "33.2": {
          "3": 1019,
          "4": 1,
        },
        "39.2": {
          "3": 169,
          "4": 5427,
        },
        "38.4": {
          "3": 1128,
          "4": 4572,
        },
        "36.2": {
          "3": 3285,
          "4": 603,
        },
        "36.6": {
          "3": 3282,
          "4": 986,
        },
        "36.4": {
          "3": 3326,
          "4": 785,
        },
        "40.2": {
          "4": 4886,
        },
        "37.4": {
          "3": 2610,
          "4": 2297,
        },
        "39.8": {
          "3": 4,
          "4": 5243,
        },
        "35.4": {
          "3": 2635,
          "4": 205,
        },
        "43.2": {
          "4": 1314,
          "5": 75,
        },
        "37.2": {
          "3": 3010,
          "4": 1986,
        },
        "34.6": {
          "3": 2015,
          "4": 64,
        },
        "42.4": {
          "4": 2176,
          "5": 27,
        },
        "38.8": {
          "3": 527,
          "4": 5159,
        },
        "39.4": {
          "3": 72,
          "4": 5387,
        },
        "38.2": {
          "3": 1490,
          "4": 4134,
        },
        "29.8": {
          "3": 90,
        },
        "40.4": {
          "4": 4696,
          "5": 1,
        },
        "33.8": {
          "3": 1396,
          "4": 13,
        },
        "34.2": {
          "3": 1611,
          "4": 40,
        },
        "35.6": {
          "3": 2939,
          "4": 292,
        },
        "32.8": {
          "3": 764,
        },
        "34.8": {
          "3": 2155,
          "4": 78,
        },
        "40.6": {
          "4": 4414,
        },
        "34.4": {
          "3": 1868,
          "4": 34,
        },
        "35.8": {
          "3": 3074,
          "4": 362,
        },
        "43.8": {
          "4": 824,
          "5": 134,
        },
        "43.4": {
          "4": 1131,
          "5": 86,
        },
        "46.6": {
          "4": 4,
          "5": 72,
        },
        "45.8": {
          "4": 40,
          "5": 157,
        },
        "41.4": {
          "4": 3461,
          "5": 4,
        },
        "41.6": {
          "4": 3251,
          "5": 6,
        },
        "36.8": {
          "3": 3283,
          "4": 1282,
        },
        "44.8": {
          "4": 234,
          "5": 180,
        },
        "37.8": {
          "3": 2213,
          "4": 3191,
        },
        "33.4": {
          "3": 1085,
          "4": 5,
        },
        "35.2": {
          "3": 2534,
          "4": 167,
        },
        "41.8": {
          "4": 2930,
          "5": 6,
        },
        "31.4": {
          "3": 285,
        },
        "42.8": {
          "4": 1754,
          "5": 40,
        },
        "42.6": {
          "4": 2010,
          "5": 36,
        },
        "38.6": {
          "3": 808,
          "4": 4956,
        },
        "44.4": {
          "4": 442,
          "5": 181,
        },
        "44.2": {
          "4": 539,
          "5": 164,
        },
        "46.2": {
          "4": 14,
          "5": 83,
        },
        "43.6": {
          "4": 1003,
          "5": 103,
        },
        "31.2": {
          "3": 248,
        },
        "32.2": {
          "3": 525,
        },
        "42.2": {
          "4": 2477,
          "5": 15,
        },
        "31.6": {
          "3": 337,
        },
        "44.6": {
          "4": 332,
          "5": 177,
        },
        "28.2": {
          "2": 11,
          "3": 100,
        },
        "32.6": {
          "3": 674,
          "4": 1,
        },
        "46.8": {
          "4": 3,
          "5": 54,
        },
        "30.8": {
          "3": 195,
        },
        "33.6": {
          "3": 1205,
          "4": 10,
        },
        "47.2": {
          "4": 2,
          "5": 30,
        },
        "29.6": {
          "3": 69,
        },
        "31.8": {
          "3": 389,
        },
        "30.6": {
          "3": 157,
        },
        "30.4": {
          "3": 140,
        },
        "32.4": {
          "3": 587,
          "4": 1,
        },
        "45.6": {
          "4": 43,
          "5": 142,
        },
        "28.8": {
          "3": 26,
        },
        "29.2": {
          "3": 56,
        },
        "45.2": {
          "4": 95,
          "5": 193,
        },
        "45.4": {
          "4": 68,
          "5": 156,
        },
        "29.4": {
          "3": 53,
        },
        "30.2": {
          "3": 111,
        },
        "28.4": {
          "2": 1,
          "3": 30,
        },
        "46.4": {
          "4": 7,
          "5": 65,
        },
        "47.8": {
          "5": 15,
        },
        "48.2": {
          "5": 5,
        },
        "28.6": {
          "3": 29,
        },
        "47.4": {
          "5": 22,
        },
        "47.6": {
          "4": 1,
          "5": 22,
        },
        "48.8": {
          "5": 3,
        },
        "49.8": {
          "5": 1,
        },
        "48.6": {
          "5": 4,
        },
        "48.4": {
          "5": 4,
        },
      },
      KO: {
        "24": {
          "2": 1273,
        },
        "25": {
          "2": 1198,
          "3": 8,
        },
        "26": {
          "2": 2975,
          "3": 196,
        },
        "27": {
          "2": 4527,
          "3": 1379,
        },
        "28": {
          "2": 3971,
          "3": 4110,
        },
        "29": {
          "2": 888,
          "3": 7407,
        },
        "30": {
          "3": 6318,
        },
        "31": {
          "3": 3764,
        },
        "32": {
          "3": 1872,
        },
        "33": {
          "3": 733,
        },
        "34": {
          "3": 194,
          "4": 6,
        },
        "26.6": {
          "2": 4146,
          "3": 749,
        },
        "29.2": {
          "2": 420,
          "3": 7427,
        },
        "28.4": {
          "2": 2681,
          "3": 5742,
        },
        "27.8": {
          "2": 4214,
          "3": 3582,
        },
        "26.4": {
          "2": 3719,
          "3": 514,
        },
        "28.2": {
          "2": 3284,
          "3": 4837,
        },
        "29.6": {
          "2": 72,
          "3": 7060,
        },
        "28.8": {
          "2": 1449,
          "3": 6794,
        },
        "25.4": {
          "2": 1804,
          "3": 43,
        },
        "32.8": {
          "3": 897,
          "4": 1,
        },
        "29.4": {
          "2": 181,
          "3": 7557,
        },
        "27.4": {
          "2": 4640,
          "3": 2257,
        },
        "29.8": {
          "2": 6,
          "3": 6816,
        },
        "30.4": {
          "3": 5380,
        },
        "24.6": {
          "2": 784,
        },
        "30.6": {
          "3": 4757,
        },
        "26.8": {
          "2": 4259,
          "3": 997,
        },
        "28.6": {
          "2": 1954,
          "3": 6244,
        },
        "31.4": {
          "3": 2922,
        },
        "31.6": {
          "3": 2545,
        },
        "27.2": {
          "2": 4699,
          "3": 1687,
        },
        "25.6": {
          "2": 2202,
          "3": 71,
        },
        "25.8": {
          "2": 2626,
          "3": 119,
        },
        "34.2": {
          "3": 715,
          "4": 104,
        },
        "31.2": {
          "3": 3411,
        },
        "32.4": {
          "3": 1324,
          "4": 1,
        },
        "30.8": {
          "3": 4325,
        },
        "24.8": {
          "2": 1013,
          "3": 1,
        },
        "33.2": {
          "3": 595,
          "4": 2,
        },
        "24.2": {
          "2": 494,
        },
        "25.2": {
          "2": 1534,
          "3": 12,
        },
        "27.6": {
          "2": 4474,
          "3": 2806,
        },
        "30.2": {
          "3": 5696,
        },
        "26.2": {
          "2": 3465,
          "3": 341,
        },
        "24.4": {
          "2": 621,
        },
        "32.2": {
          "3": 1575,
        },
        "31.8": {
          "3": 2226,
        },
        "33.8": {
          "3": 318,
          "4": 5,
        },
        "32.6": {
          "3": 1068,
        },
        "33.4": {
          "3": 512,
          "4": 1,
        },
        "33.6": {
          "3": 401,
          "4": 3,
        },
      },
      TD: {
        "7": {
          "0": 366,
        },
        "8": {
          "0": 1368,
        },
        "9": {
          "0": 2372,
          "1": 772,
        },
        "10": {
          "0": 5,
          "1": 5755,
        },
        "11": {
          "1": 7871,
        },
        "12": {
          "1": 8507,
        },
        "13": {
          "1": 6617,
        },
        "14": {
          "1": 3844,
        },
        "15": {
          "1": 1701,
        },
        "16": {
          "1": 556,
        },
        "17": {
          "1": 111,
          "2": 1,
        },
        "13.2": {
          "1": 6336,
        },
        "9.8": {
          "0": 138,
          "1": 5225,
        },
        "13.4": {
          "1": 5543,
        },
        "15.2": {
          "1": 1419,
        },
        "10.6": {
          "1": 7086,
        },
        "9.4": {
          "0": 1531,
          "1": 2537,
        },
        "10.4": {
          "1": 6718,
        },
        "15.8": {
          "1": 750,
        },
        "6.6": {
          "0": 915,
        },
        "12.6": {
          "1": 7487,
        },
        "12.4": {
          "1": 8096,
        },
        "11.4": {
          "1": 8463,
        },
        "11.6": {
          "1": 8416,
        },
        "10.2": {
          "1": 6327,
        },
        "10.8": {
          "1": 7758,
        },
        "9.6": {
          "0": 749,
          "1": 3952,
        },
        "11.2": {
          "1": 8457,
        },
        "14.8": {
          "1": 1962,
        },
        "15.4": {
          "1": 1110,
        },
        "8.8": {
          "0": 2345,
          "1": 317,
        },
        "12.2": {
          "1": 8122,
        },
        "13.8": {
          "1": 4318,
        },
        "14.2": {
          "1": 3342,
        },
        "14.4": {
          "1": 2877,
        },
        "12.8": {
          "1": 7021,
        },
        "8.2": {
          "0": 1540,
          "1": 3,
        },
        "7.6": {
          "0": 910,
        },
        "13.6": {
          "1": 4784,
        },
        "11.8": {
          "1": 8530,
        },
        "8.6": {
          "0": 2115,
          "1": 96,
        },
        "14.6": {
          "1": 2412,
        },
        "6.8": {
          "0": 325,
        },
        "16.4": {
          "1": 339,
          "2": 1,
        },
        "9.2": {
          "0": 2046,
          "1": 1515,
        },
        "15.6": {
          "1": 885,
        },
        "7.2": {
          "0": 541,
        },
        "8.4": {
          "0": 1896,
          "1": 13,
        },
        "7.8": {
          "0": 1019,
        },
        "7.4": {
          "0": 679,
        },
        "16.6": {
          "1": 233,
          "2": 3,
        },
        "16.2": {
          "1": 425,
        },
        "16.8": {
          "1": 172,
          "2": 1,
        },
        "17.2": {
          "1": 104,
        },
        "17.6": {
          "1": 103,
          "2": 66,
        },
        "17.4": {
          "1": 72,
          "2": 9,
        },
      },
      KONF: {
        "8": {
          "0": 1285,
          "1": 1,
        },
        "9": {
          "0": 4342,
          "1": 2380,
        },
        "10": {
          "0": 2,
          "1": 14059,
        },
        "11": {
          "1": 12110,
        },
        "12": {
          "1": 4766,
        },
        "13": {
          "1": 913,
        },
        "8.8": {
          "0": 4214,
          "1": 1019,
        },
        "11.4": {
          "1": 9215,
        },
        "9.8": {
          "0": 174,
          "1": 12689,
        },
        "10.4": {
          "1": 14517,
        },
        "11.8": {
          "1": 6001,
        },
        "13.4": {
          "1": 386,
        },
        "10.6": {
          "1": 14195,
        },
        "11.2": {
          "1": 10682,
        },
        "10.2": {
          "1": 14674,
        },
        "10.8": {
          "1": 13453,
        },
        "8.4": {
          "0": 2717,
          "1": 76,
        },
        "12.6": {
          "1": 1902,
        },
        "8.2": {
          "0": 1938,
          "1": 7,
        },
        "9.2": {
          "0": 3857,
          "1": 4489,
        },
        "9.4": {
          "0": 2538,
          "1": 7616,
        },
        "9.6": {
          "0": 1106,
          "1": 10646,
        },
        "8.6": {
          "0": 3534,
          "1": 298,
        },
        "11.6": {
          "1": 7497,
        },
        "12.8": {
          "1": 1290,
        },
        "7.8": {
          "0": 1924,
        },
        "12.4": {
          "1": 2582,
        },
        "12.2": {
          "1": 3685,
        },
        "13.6": {
          "1": 634,
        },
        "13.2": {
          "1": 587,
        },
      },
      LEWICA: {
        "8": {
          "0": 1241,
        },
        "9": {
          "0": 2489,
          "1": 1494,
        },
        "10": {
          "0": 3,
          "1": 11536,
        },
        "11": {
          "1": 14062,
        },
        "12": {
          "1": 7696,
        },
        "13": {
          "1": 1998,
        },
        "14": {
          "1": 687,
        },
        "10.4": {
          "1": 13627,
        },
        "10.8": {
          "1": 14335,
        },
        "11.6": {
          "1": 10691,
        },
        "12.2": {
          "1": 6282,
        },
        "11.2": {
          "1": 13344,
        },
        "9.8": {
          "0": 144,
          "1": 9732,
        },
        "12.8": {
          "1": 2797,
        },
        "10.2": {
          "1": 12642,
        },
        "10.6": {
          "1": 14347,
        },
        "9.2": {
          "0": 2283,
          "1": 2931,
        },
        "9.6": {
          "0": 791,
          "1": 7567,
        },
        "13.2": {
          "1": 1440,
        },
        "9.4": {
          "0": 1649,
          "1": 4994,
        },
        "11.4": {
          "1": 12072,
        },
        "12.4": {
          "1": 4981,
        },
        "11.8": {
          "1": 9248,
        },
        "8.8": {
          "0": 2215,
          "1": 609,
        },
        "13.6": {
          "1": 700,
        },
        "8.2": {
          "0": 908,
          "1": 4,
        },
        "12.6": {
          "1": 3786,
        },
        "8.6": {
          "0": 1791,
          "1": 189,
        },
        "8.4": {
          "0": 1292,
          "1": 38,
        },
        "13.8": {
          "1": 441,
        },
        "13.4": {
          "1": 924,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 4594,
      TD: 5139,
      LEWICA: 9120,
    },
    potencjalneMandaty: {
      KO: 86716,
      TD: 19584,
      LEWICA: 16020,
    },
    ostatnieMandaty: {
      KO: 59663,
      TD: 14104,
      LEWICA: 22965,
    },
    dawcyGlosow: {
      KO: 10238,
      TD: 14481,
      LEWICA: 27723,
    },
  },
  Częstochowa: {
    procentyWOkreguSrednia: {
      PIS: 38.4987274785148,
      KO: 25.056237439739345,
      TD: 12.807660630606811,
      KONF: 8.772238684091457,
      LEWICA: 14.865135767047583,
    },
    odchylenieWOkregu: {
      PIS: 1.8858418967459787,
      KO: 2.5723295189859123,
      TD: 2.013774734692939,
      LEWICA: 2.3228357402502144,
      KONF: 1.9863464621538032,
    },
    wykresRozkladu: {
      "82.8%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "9.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "4.8%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "2.2%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 0,
      },
      "0.2%": {
        KO: 2,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 0,
      },
      "0.1%": {
        KO: 1,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "83.5%": {
        DEMO: 4,
        NIEDEMO: 3,
      },
      "16.4%": {
        DEMO: 3,
        NIEDEMO: 4,
      },
      "0.1%": {
        DEMO: 2,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "34": {
          "2": 75,
          "3": 517,
        },
        "35": {
          "2": 94,
          "3": 1630,
        },
        "36": {
          "2": 50,
          "3": 3689,
        },
        "37": {
          "2": 5,
          "3": 6091,
        },
        "38": {
          "3": 7441,
          "4": 59,
        },
        "39": {
          "3": 7308,
          "4": 205,
        },
        "40": {
          "3": 5303,
          "4": 392,
        },
        "41": {
          "3": 3063,
          "4": 519,
        },
        "42": {
          "3": 1344,
          "4": 587,
        },
        "43": {
          "3": 474,
          "4": 391,
        },
        "44": {
          "3": 101,
          "4": 216,
        },
        "39.2": {
          "3": 6873,
          "4": 235,
        },
        "40.2": {
          "3": 4858,
          "4": 393,
        },
        "40.8": {
          "3": 3513,
          "4": 533,
        },
        "38.6": {
          "3": 7592,
          "4": 127,
        },
        "41.6": {
          "3": 1954,
          "4": 540,
        },
        "37.6": {
          "3": 7025,
          "4": 28,
        },
        "40.6": {
          "3": 3961,
          "4": 471,
        },
        "34.2": {
          "2": 90,
          "3": 732,
        },
        "38.4": {
          "3": 7538,
          "4": 90,
        },
        "39.6": {
          "3": 6150,
          "4": 315,
        },
        "41.2": {
          "3": 2812,
          "4": 574,
        },
        "37.4": {
          "3": 6775,
          "4": 11,
        },
        "37.2": {
          "2": 1,
          "3": 6598,
          "4": 5,
        },
        "36.8": {
          "2": 6,
          "3": 5618,
          "4": 1,
        },
        "37.8": {
          "3": 7258,
          "4": 38,
        },
        "38.8": {
          "3": 7352,
          "4": 161,
        },
        "38.2": {
          "3": 7582,
          "4": 81,
        },
        "39.4": {
          "3": 6814,
          "4": 288,
        },
        "34.8": {
          "2": 85,
          "3": 1345,
        },
        "35.6": {
          "2": 97,
          "3": 2840,
        },
        "36.6": {
          "2": 18,
          "3": 5220,
        },
        "42.8": {
          "3": 654,
          "4": 403,
        },
        "36.4": {
          "2": 24,
          "3": 4689,
        },
        "34.4": {
          "2": 100,
          "3": 842,
        },
        "36.2": {
          "2": 35,
          "3": 4264,
        },
        "42.2": {
          "3": 1180,
          "4": 518,
        },
        "35.8": {
          "2": 68,
          "3": 3269,
        },
        "39.8": {
          "3": 5799,
          "4": 337,
        },
        "41.4": {
          "3": 2330,
          "4": 561,
        },
        "40.4": {
          "3": 4510,
          "4": 453,
        },
        "42.4": {
          "3": 947,
          "4": 475,
        },
        "34.6": {
          "2": 90,
          "3": 1081,
        },
        "41.8": {
          "3": 1665,
          "4": 553,
        },
        "35.2": {
          "2": 75,
          "3": 1999,
        },
        "42.6": {
          "3": 765,
          "4": 407,
        },
        "43.4": {
          "3": 263,
          "4": 304,
        },
        "33.8": {
          "2": 455,
          "3": 1450,
        },
        "35.4": {
          "2": 71,
          "3": 2353,
        },
        "43.2": {
          "3": 381,
          "4": 346,
        },
        "43.6": {
          "3": 195,
          "4": 310,
        },
        "44.2": {
          "3": 277,
          "4": 967,
        },
        "43.8": {
          "3": 152,
          "4": 231,
        },
      },
      KO: {
        "19": {
          "1": 175,
          "2": 1,
        },
        "20": {
          "1": 424,
          "2": 102,
        },
        "21": {
          "1": 558,
          "2": 801,
        },
        "22": {
          "1": 428,
          "2": 2310,
        },
        "23": {
          "1": 284,
          "2": 4379,
        },
        "24": {
          "1": 56,
          "2": 6367,
        },
        "25": {
          "2": 7224,
        },
        "26": {
          "2": 6737,
        },
        "27": {
          "2": 4837,
        },
        "28": {
          "2": 2986,
          "3": 2,
        },
        "29": {
          "2": 1326,
          "3": 46,
        },
        "30": {
          "2": 509,
          "3": 81,
        },
        "31": {
          "2": 119,
          "3": 53,
        },
        "32": {
          "2": 17,
          "3": 23,
        },
        "24.2": {
          "1": 28,
          "2": 6725,
        },
        "22.2": {
          "1": 480,
          "2": 2693,
        },
        "25.8": {
          "2": 6889,
        },
        "26.6": {
          "2": 5766,
        },
        "19.8": {
          "1": 387,
          "2": 55,
        },
        "28.4": {
          "2": 2295,
          "3": 21,
        },
        "26.2": {
          "2": 6321,
        },
        "24.4": {
          "1": 25,
          "2": 6908,
        },
        "23.8": {
          "1": 99,
          "2": 6123,
        },
        "20.6": {
          "1": 479,
          "2": 413,
        },
        "23.4": {
          "1": 173,
          "2": 5462,
        },
        "21.8": {
          "1": 463,
          "2": 1955,
        },
        "23.6": {
          "1": 156,
          "2": 5520,
        },
        "26.8": {
          "2": 5314,
        },
        "25.2": {
          "2": 7198,
        },
        "22.8": {
          "1": 307,
          "2": 3881,
        },
        "23.2": {
          "1": 257,
          "2": 4767,
        },
        "25.4": {
          "2": 7135,
        },
        "25.6": {
          "2": 6954,
        },
        "27.6": {
          "2": 3773,
          "3": 1,
        },
        "29.4": {
          "2": 978,
          "3": 47,
        },
        "24.8": {
          "2": 7211,
        },
        "27.4": {
          "2": 4197,
        },
        "22.4": {
          "1": 402,
          "2": 3156,
        },
        "27.2": {
          "2": 4484,
        },
        "29.6": {
          "2": 765,
          "3": 75,
        },
        "21.2": {
          "1": 544,
          "2": 1037,
        },
        "28.8": {
          "2": 1624,
          "3": 28,
        },
        "20.2": {
          "1": 460,
          "2": 196,
        },
        "28.6": {
          "2": 1915,
          "3": 29,
        },
        "26.4": {
          "2": 6025,
        },
        "31.6": {
          "2": 47,
          "3": 28,
        },
        "30.6": {
          "2": 183,
          "3": 58,
        },
        "21.4": {
          "1": 487,
          "2": 1259,
        },
        "21.6": {
          "1": 492,
          "2": 1668,
        },
        "24.6": {
          "1": 3,
          "2": 7161,
        },
        "22.6": {
          "1": 342,
          "2": 3614,
        },
        "27.8": {
          "2": 3300,
        },
        "20.8": {
          "1": 519,
          "2": 599,
        },
        "30.2": {
          "2": 378,
          "3": 84,
        },
        "29.2": {
          "2": 1202,
          "3": 54,
        },
        "20.4": {
          "1": 478,
          "2": 284,
        },
        "30.4": {
          "2": 269,
          "3": 95,
        },
        "28.2": {
          "2": 2566,
          "3": 6,
        },
        "18.6": {
          "1": 439,
        },
        "32.8": {
          "2": 11,
          "3": 55,
        },
        "29.8": {
          "2": 608,
          "3": 63,
        },
        "30.8": {
          "2": 161,
          "3": 56,
        },
        "19.2": {
          "1": 193,
          "2": 7,
        },
        "19.6": {
          "1": 370,
          "2": 26,
        },
        "18.8": {
          "1": 124,
        },
        "31.4": {
          "2": 54,
          "3": 43,
        },
        "19.4": {
          "1": 286,
          "2": 23,
        },
        "31.8": {
          "2": 29,
          "3": 28,
        },
        "32.4": {
          "2": 10,
          "3": 14,
        },
        "32.2": {
          "2": 10,
          "3": 17,
        },
        "31.2": {
          "2": 95,
          "3": 43,
        },
        "32.6": {
          "2": 7,
          "3": 11,
        },
      },
      TD: {
        "8": {
          "0": 305,
        },
        "9": {
          "0": 1067,
        },
        "10": {
          "0": 1780,
          "1": 983,
        },
        "11": {
          "0": 686,
          "1": 4636,
        },
        "12": {
          "0": 80,
          "1": 7697,
        },
        "13": {
          "1": 8566,
        },
        "14": {
          "1": 6894,
        },
        "15": {
          "1": 4368,
        },
        "16": {
          "1": 1893,
        },
        "17": {
          "1": 629,
        },
        "18": {
          "1": 160,
        },
        "11.4": {
          "0": 388,
          "1": 6050,
        },
        "12.6": {
          "1": 8543,
        },
        "11.6": {
          "0": 263,
          "1": 6579,
        },
        "10.8": {
          "0": 875,
          "1": 3899,
        },
        "14.6": {
          "1": 5453,
        },
        "12.8": {
          "1": 8482,
        },
        "14.4": {
          "1": 5968,
        },
        "13.2": {
          "1": 8710,
        },
        "11.2": {
          "0": 553,
          "1": 5195,
        },
        "13.6": {
          "1": 7780,
        },
        "15.8": {
          "1": 2304,
        },
        "13.4": {
          "1": 8232,
        },
        "10.2": {
          "0": 1571,
          "1": 1645,
        },
        "14.2": {
          "1": 6559,
        },
        "16.2": {
          "1": 1466,
        },
        "15.4": {
          "1": 3252,
        },
        "9.8": {
          "0": 1826,
          "1": 414,
        },
        "12.2": {
          "0": 26,
          "1": 8119,
        },
        "11.8": {
          "0": 151,
          "1": 7373,
        },
        "13.8": {
          "1": 7535,
        },
        "16.4": {
          "1": 1286,
        },
        "8.2": {
          "0": 382,
        },
        "9.2": {
          "0": 1360,
          "1": 5,
        },
        "14.8": {
          "1": 4923,
        },
        "10.4": {
          "0": 1275,
          "1": 2356,
        },
        "12.4": {
          "1": 8249,
        },
        "17.2": {
          "1": 519,
        },
        "9.6": {
          "0": 1761,
          "1": 179,
        },
        "15.6": {
          "1": 2794,
        },
        "10.6": {
          "0": 1087,
          "1": 3201,
        },
        "8.4": {
          "0": 543,
        },
        "8.6": {
          "0": 656,
        },
        "15.2": {
          "1": 3845,
        },
        "9.4": {
          "0": 1562,
          "1": 35,
        },
        "16.6": {
          "1": 1022,
        },
        "18.8": {
          "1": 137,
          "2": 7,
        },
        "8.8": {
          "0": 898,
        },
        "16.8": {
          "1": 813,
        },
        "17.8": {
          "1": 203,
        },
        "7.8": {
          "0": 963,
        },
        "18.4": {
          "1": 97,
        },
        "17.6": {
          "1": 317,
        },
        "18.2": {
          "1": 118,
        },
        "17.4": {
          "1": 391,
        },
        "18.6": {
          "1": 61,
        },
      },
      KONF: {
        "4": {
          "0": 382,
        },
        "5": {
          "0": 1163,
        },
        "6": {
          "0": 2920,
        },
        "7": {
          "0": 5360,
        },
        "8": {
          "0": 7880,
        },
        "9": {
          "0": 8614,
        },
        "10": {
          "0": 6572,
          "1": 444,
        },
        "11": {
          "0": 2568,
          "1": 1610,
        },
        "12": {
          "0": 145,
          "1": 1665,
        },
        "13": {
          "1": 615,
        },
        "14": {
          "1": 149,
        },
        "10.4": {
          "0": 5111,
          "1": 957,
        },
        "8.6": {
          "0": 8638,
        },
        "11.8": {
          "0": 359,
          "1": 1819,
        },
        "11.4": {
          "0": 1307,
          "1": 1895,
        },
        "9.8": {
          "0": 7172,
          "1": 220,
        },
        "9.2": {
          "0": 8407,
          "1": 1,
        },
        "7.4": {
          "0": 6554,
        },
        "8.8": {
          "0": 8564,
        },
        "9.6": {
          "0": 7677,
          "1": 77,
        },
        "9.4": {
          "0": 7999,
          "1": 17,
        },
        "8.2": {
          "0": 8146,
        },
        "11.2": {
          "0": 1911,
          "1": 1732,
        },
        "10.2": {
          "0": 5846,
          "1": 700,
        },
        "10.8": {
          "0": 3371,
          "1": 1412,
        },
        "3.8": {
          "0": 1063,
        },
        "10.6": {
          "0": 4188,
          "1": 1137,
        },
        "6.2": {
          "0": 3262,
        },
        "7.8": {
          "0": 7436,
        },
        "11.6": {
          "0": 789,
          "1": 1868,
        },
        "7.6": {
          "0": 7021,
        },
        "6.4": {
          "0": 3666,
        },
        "12.6": {
          "1": 943,
        },
        "6.6": {
          "0": 4276,
        },
        "12.8": {
          "1": 770,
        },
        "12.4": {
          "0": 5,
          "1": 1261,
        },
        "6.8": {
          "0": 4691,
        },
        "7.2": {
          "0": 5897,
        },
        "5.8": {
          "0": 2377,
        },
        "4.6": {
          "0": 765,
        },
        "8.4": {
          "0": 8382,
        },
        "5.6": {
          "0": 2059,
        },
        "13.8": {
          "1": 184,
        },
        "5.4": {
          "0": 1686,
        },
        "12.2": {
          "0": 23,
          "1": 1502,
        },
        "4.8": {
          "0": 889,
        },
        "5.2": {
          "0": 1441,
        },
        "14.8": {
          "1": 107,
        },
        "13.4": {
          "1": 349,
        },
        "14.2": {
          "1": 119,
        },
        "4.2": {
          "0": 505,
        },
        "13.2": {
          "1": 457,
        },
        "13.6": {
          "1": 252,
        },
        "4.4": {
          "0": 533,
        },
        "14.4": {
          "1": 70,
        },
        "14.6": {
          "1": 48,
        },
      },
      LEWICA: {
        "9": {
          "0": 704,
        },
        "10": {
          "0": 419,
          "1": 133,
        },
        "11": {
          "0": 245,
          "1": 1098,
        },
        "12": {
          "0": 33,
          "1": 2922,
        },
        "13": {
          "1": 5145,
        },
        "14": {
          "1": 7013,
        },
        "15": {
          "1": 7639,
        },
        "16": {
          "1": 6879,
        },
        "17": {
          "1": 4538,
        },
        "18": {
          "1": 2466,
        },
        "19": {
          "1": 982,
          "2": 13,
        },
        "20": {
          "1": 268,
          "2": 48,
        },
        "21": {
          "1": 62,
          "2": 41,
        },
        "16.2": {
          "1": 6421,
        },
        "14.6": {
          "1": 7489,
        },
        "15.2": {
          "1": 7821,
        },
        "10.2": {
          "0": 435,
          "1": 221,
        },
        "16.8": {
          "1": 5003,
        },
        "14.4": {
          "1": 7444,
        },
        "12.8": {
          "1": 4641,
        },
        "16.4": {
          "1": 5889,
        },
        "13.4": {
          "1": 5821,
        },
        "17.4": {
          "1": 3629,
        },
        "18.4": {
          "1": 1682,
        },
        "14.2": {
          "1": 7283,
        },
        "14.8": {
          "1": 7640,
        },
        "13.6": {
          "1": 6416,
        },
        "18.8": {
          "1": 1174,
          "2": 5,
        },
        "15.4": {
          "1": 7566,
        },
        "15.8": {
          "1": 6906,
        },
        "12.2": {
          "0": 17,
          "1": 3232,
        },
        "12.4": {
          "0": 3,
          "1": 3750,
        },
        "11.2": {
          "0": 199,
          "1": 1484,
        },
        "13.8": {
          "1": 6630,
        },
        "16.6": {
          "1": 5463,
        },
        "13.2": {
          "1": 5506,
        },
        "19.2": {
          "1": 812,
          "2": 18,
        },
        "10.4": {
          "0": 414,
          "1": 445,
        },
        "11.4": {
          "0": 164,
          "1": 1771,
        },
        "15.6": {
          "1": 7167,
        },
        "10.8": {
          "0": 279,
          "1": 884,
        },
        "12.6": {
          "1": 4145,
        },
        "17.2": {
          "1": 4026,
        },
        "11.6": {
          "0": 132,
          "1": 2203,
        },
        "17.8": {
          "1": 2850,
        },
        "18.6": {
          "1": 1479,
          "2": 7,
        },
        "10.6": {
          "0": 302,
          "1": 655,
        },
        "17.6": {
          "1": 3252,
        },
        "21.2": {
          "1": 36,
          "2": 31,
        },
        "11.8": {
          "0": 85,
          "1": 2512,
        },
        "18.2": {
          "1": 2075,
        },
        "9.8": {
          "0": 414,
          "1": 29,
        },
        "19.4": {
          "1": 656,
          "2": 29,
        },
        "19.8": {
          "1": 362,
          "2": 29,
        },
        "9.2": {
          "0": 260,
        },
        "20.4": {
          "1": 145,
          "2": 39,
        },
        "21.8": {
          "1": 29,
          "2": 72,
        },
        "9.6": {
          "0": 334,
          "1": 9,
        },
        "20.2": {
          "1": 222,
          "2": 49,
        },
        "20.8": {
          "1": 83,
          "2": 41,
        },
        "21.6": {
          "1": 12,
          "2": 28,
        },
        "9.4": {
          "0": 267,
          "1": 2,
        },
        "19.6": {
          "1": 510,
          "2": 35,
        },
        "21.4": {
          "1": 13,
          "2": 27,
        },
        "20.6": {
          "1": 106,
          "2": 36,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 5739,
      TD: 8280,
      LEWICA: 3689,
    },
    potencjalneMandaty: {
      KO: 29021,
      TD: 30010,
      LEWICA: 11582,
    },
    ostatnieMandaty: {
      KO: 66248,
      TD: 52115,
      LEWICA: 16262,
    },
    dawcyGlosow: {
      KO: 14256,
      TD: 21498,
      LEWICA: 11367,
    },
  },
  Gliwice: {
    procentyWOkreguSrednia: {
      PIS: 31.802472757961873,
      KO: 34.81121923314404,
      TD: 11.362767708704615,
      KONF: 9.679873249811408,
      LEWICA: 12.34366705037808,
    },
    odchylenieWOkregu: {
      PIS: 4.004822905670312,
      KO: 2.4954338635293603,
      TD: 2.013774734692939,
      LEWICA: 0.8249076176529788,
      KONF: 2.4674491781550874,
    },
    wykresRozkladu: {
      "59.5%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "30.9%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "6.8%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "2.3%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "0.1%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "0%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 2,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "66.4%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
      "31.1%": {
        DEMO: 6,
        NIEDEMO: 3,
      },
      "2.4%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "22": {
          "2": 43,
        },
        "23": {
          "2": 115,
        },
        "24": {
          "2": 233,
          "3": 3,
        },
        "25": {
          "2": 450,
          "3": 37,
        },
        "26": {
          "2": 641,
          "3": 219,
        },
        "27": {
          "2": 696,
          "3": 854,
        },
        "28": {
          "2": 284,
          "3": 2079,
        },
        "29": {
          "2": 22,
          "3": 3247,
        },
        "30": {
          "3": 4332,
        },
        "31": {
          "3": 4907,
        },
        "32": {
          "3": 5261,
          "4": 17,
        },
        "33": {
          "3": 4861,
          "4": 141,
        },
        "34": {
          "3": 3520,
          "4": 550,
        },
        "35": {
          "3": 1763,
          "4": 1236,
        },
        "36": {
          "3": 680,
          "4": 1347,
        },
        "37": {
          "3": 212,
          "4": 979,
        },
        "38": {
          "3": 50,
          "4": 601,
        },
        "39": {
          "3": 2,
          "4": 285,
        },
        "40": {
          "4": 100,
        },
        "41": {
          "4": 37,
          "5": 3,
        },
        "42": {
          "4": 10,
          "5": 2,
        },
        "43": {
          "4": 2,
          "5": 1,
        },
        "34.4": {
          "3": 2908,
          "4": 824,
        },
        "33.8": {
          "3": 3809,
          "4": 432,
        },
        "35.4": {
          "3": 1189,
          "4": 1379,
        },
        "36.8": {
          "3": 300,
          "4": 988,
        },
        "32.4": {
          "3": 5301,
          "4": 27,
        },
        "26.2": {
          "2": 699,
          "3": 273,
        },
        "31.8": {
          "3": 5264,
          "4": 3,
        },
        "31.6": {
          "3": 5321,
          "4": 2,
        },
        "28.2": {
          "2": 206,
          "3": 2379,
        },
        "27.8": {
          "2": 415,
          "3": 1876,
        },
        "32.8": {
          "3": 5010,
          "4": 81,
        },
        "35.2": {
          "3": 1541,
          "4": 1279,
        },
        "27.6": {
          "2": 439,
          "3": 1526,
        },
        "27.2": {
          "2": 659,
          "3": 1077,
        },
        "35.8": {
          "3": 801,
          "4": 1458,
        },
        "30.6": {
          "3": 4729,
        },
        "33.2": {
          "3": 4699,
          "4": 162,
        },
        "31.2": {
          "3": 5061,
        },
        "38.4": {
          "3": 18,
          "4": 396,
        },
        "36.2": {
          "3": 526,
          "4": 1316,
        },
        "28.4": {
          "2": 142,
          "3": 2600,
        },
        "32.6": {
          "3": 5167,
          "4": 57,
        },
        "31.4": {
          "3": 5066,
          "4": 2,
        },
        "29.2": {
          "2": 3,
          "3": 3507,
        },
        "34.8": {
          "3": 2168,
          "4": 1140,
        },
        "30.2": {
          "3": 4500,
        },
        "35.6": {
          "3": 940,
          "4": 1371,
        },
        "34.2": {
          "3": 3242,
          "4": 702,
        },
        "33.6": {
          "3": 4078,
          "4": 401,
        },
        "30.8": {
          "3": 4860,
        },
        "29.6": {
          "3": 3977,
        },
        "36.4": {
          "3": 423,
          "4": 1211,
        },
        "32.2": {
          "3": 5159,
          "4": 21,
        },
        "33.4": {
          "3": 4324,
          "4": 279,
        },
        "27.4": {
          "2": 561,
          "3": 1280,
        },
        "30.4": {
          "3": 4699,
        },
        "34.6": {
          "3": 2547,
          "4": 1011,
        },
        "29.4": {
          "3": 3815,
        },
        "24.6": {
          "2": 355,
          "3": 23,
        },
        "37.6": {
          "3": 87,
          "4": 709,
        },
        "29.8": {
          "3": 4185,
        },
        "36.6": {
          "3": 332,
          "4": 1115,
        },
        "28.6": {
          "2": 70,
          "3": 2729,
        },
        "25.2": {
          "2": 494,
          "3": 61,
        },
        "26.6": {
          "2": 752,
          "3": 513,
        },
        "28.8": {
          "2": 43,
          "3": 3110,
        },
        "26.8": {
          "2": 712,
          "3": 645,
        },
        "39.6": {
          "4": 174,
        },
        "25.6": {
          "2": 601,
          "3": 120,
        },
        "37.4": {
          "3": 117,
          "4": 795,
        },
        "38.6": {
          "3": 11,
          "4": 357,
        },
        "23.8": {
          "2": 199,
        },
        "25.8": {
          "2": 654,
          "3": 188,
        },
        "26.4": {
          "2": 762,
          "3": 380,
        },
        "40.6": {
          "4": 65,
          "5": 1,
        },
        "25.4": {
          "2": 540,
          "3": 103,
        },
        "37.2": {
          "3": 169,
          "4": 908,
        },
        "22.8": {
          "2": 90,
        },
        "24.4": {
          "2": 316,
          "3": 14,
        },
        "38.8": {
          "3": 7,
          "4": 284,
        },
        "24.8": {
          "2": 408,
          "3": 32,
        },
        "21.8": {
          "1": 1,
          "2": 241,
        },
        "39.2": {
          "3": 5,
          "4": 206,
        },
        "37.8": {
          "3": 68,
          "4": 634,
        },
        "40.8": {
          "4": 33,
          "5": 2,
        },
        "23.2": {
          "2": 170,
        },
        "23.6": {
          "2": 181,
          "3": 1,
        },
        "24.2": {
          "2": 254,
          "3": 8,
        },
        "38.2": {
          "3": 37,
          "4": 522,
        },
        "39.4": {
          "3": 1,
          "4": 195,
          "5": 1,
        },
        "23.4": {
          "2": 180,
          "3": 1,
        },
        "40.4": {
          "4": 62,
          "5": 1,
        },
        "40.2": {
          "4": 77,
        },
        "42.4": {
          "4": 5,
          "5": 2,
        },
        "22.4": {
          "2": 61,
        },
        "22.2": {
          "2": 61,
        },
        "42.2": {
          "4": 4,
          "5": 1,
        },
        "41.4": {
          "4": 17,
        },
        "22.6": {
          "2": 90,
        },
        "39.8": {
          "4": 120,
        },
        "41.2": {
          "4": 22,
          "5": 2,
        },
        "41.8": {
          "4": 7,
          "5": 4,
        },
        "43.8": {
          "4": 3,
          "5": 3,
        },
        "42.6": {
          "4": 7,
          "5": 2,
        },
        "41.6": {
          "4": 16,
          "5": 2,
        },
        "42.8": {
          "4": 6,
        },
        "43.6": {
          "4": 1,
          "5": 1,
        },
        "43.2": {
          "4": 2,
          "5": 1,
        },
        "43.4": {
          "4": 1,
        },
      },
      KO: {
        "29": {
          "2": 4,
          "3": 308,
        },
        "30": {
          "3": 842,
        },
        "31": {
          "3": 1912,
        },
        "32": {
          "3": 3387,
          "4": 14,
        },
        "33": {
          "3": 4892,
          "4": 141,
        },
        "34": {
          "3": 5499,
          "4": 911,
        },
        "35": {
          "3": 4351,
          "4": 2287,
        },
        "36": {
          "3": 2408,
          "4": 3245,
        },
        "37": {
          "3": 1006,
          "4": 3232,
        },
        "38": {
          "3": 206,
          "4": 2634,
        },
        "39": {
          "3": 11,
          "4": 1578,
        },
        "40": {
          "4": 731,
          "5": 11,
        },
        "41": {
          "4": 321,
          "5": 22,
        },
        "42": {
          "4": 107,
          "5": 24,
        },
        "36.4": {
          "3": 1824,
          "4": 3218,
        },
        "32.6": {
          "3": 4335,
          "4": 76,
        },
        "33.4": {
          "3": 5265,
          "4": 362,
        },
        "33.6": {
          "3": 5278,
          "4": 499,
        },
        "35.8": {
          "3": 2825,
          "4": 3041,
        },
        "38.6": {
          "3": 40,
          "4": 1890,
        },
        "34.8": {
          "3": 4645,
          "4": 1967,
        },
        "36.2": {
          "3": 2098,
          "4": 3319,
        },
        "34.4": {
          "3": 5164,
          "4": 1398,
        },
        "38.2": {
          "3": 116,
          "4": 2375,
        },
        "34.2": {
          "3": 5266,
          "4": 1129,
        },
        "40.8": {
          "4": 385,
          "5": 17,
        },
        "37.6": {
          "3": 418,
          "4": 2951,
        },
        "33.2": {
          "3": 5093,
          "4": 264,
        },
        "35.4": {
          "3": 3557,
          "4": 2805,
        },
        "32.2": {
          "3": 3735,
          "4": 19,
        },
        "32.4": {
          "3": 4097,
          "4": 34,
        },
        "32.8": {
          "3": 4688,
          "4": 106,
        },
        "31.6": {
          "3": 2739,
        },
        "33.8": {
          "3": 5410,
          "4": 666,
        },
        "36.6": {
          "3": 1538,
          "4": 3310,
        },
        "36.8": {
          "3": 1333,
          "4": 3307,
        },
        "35.2": {
          "3": 3888,
          "4": 2519,
        },
        "39.4": {
          "4": 1257,
          "5": 3,
        },
        "28.8": {
          "2": 9,
          "3": 231,
        },
        "35.6": {
          "3": 3185,
          "4": 2947,
        },
        "34.6": {
          "3": 4846,
          "4": 1689,
        },
        "31.8": {
          "3": 3099,
          "4": 2,
        },
        "30.4": {
          "3": 1200,
        },
        "37.4": {
          "3": 606,
          "4": 3035,
        },
        "30.8": {
          "3": 1635,
        },
        "37.8": {
          "3": 266,
          "4": 2649,
        },
        "31.4": {
          "3": 2421,
        },
        "41.4": {
          "4": 213,
          "5": 19,
        },
        "39.6": {
          "4": 987,
          "5": 3,
        },
        "37.2": {
          "3": 747,
          "4": 3199,
        },
        "30.2": {
          "3": 1057,
        },
        "38.4": {
          "3": 50,
          "4": 2088,
        },
        "42.2": {
          "4": 352,
          "5": 218,
        },
        "39.2": {
          "3": 2,
          "4": 1370,
        },
        "30.6": {
          "3": 1410,
        },
        "29.6": {
          "3": 562,
        },
        "38.8": {
          "3": 20,
          "4": 1789,
        },
        "31.2": {
          "3": 2100,
        },
        "40.2": {
          "4": 635,
          "5": 11,
        },
        "29.2": {
          "2": 3,
          "3": 398,
        },
        "28.6": {
          "2": 165,
          "3": 686,
        },
        "29.4": {
          "3": 488,
        },
        "39.8": {
          "4": 909,
          "5": 3,
        },
        "41.6": {
          "4": 192,
          "5": 16,
        },
        "29.8": {
          "3": 689,
        },
        "40.4": {
          "4": 527,
          "5": 15,
        },
        "40.6": {
          "4": 467,
          "5": 12,
        },
        "41.8": {
          "4": 137,
          "5": 23,
        },
        "41.2": {
          "4": 248,
          "5": 17,
        },
      },
      TD: {
        "7": {
          "0": 631,
        },
        "8": {
          "0": 1680,
          "1": 19,
        },
        "9": {
          "0": 1046,
          "1": 2678,
        },
        "10": {
          "1": 6472,
        },
        "11": {
          "1": 8319,
        },
        "12": {
          "1": 7798,
        },
        "13": {
          "1": 5746,
        },
        "14": {
          "1": 3191,
        },
        "15": {
          "1": 1337,
        },
        "16": {
          "1": 444,
          "2": 4,
        },
        "17": {
          "1": 99,
          "2": 21,
        },
        "10.8": {
          "1": 8129,
        },
        "11.4": {
          "1": 8636,
        },
        "10.4": {
          "1": 7478,
        },
        "11.2": {
          "1": 8425,
        },
        "12.8": {
          "1": 6428,
        },
        "12.2": {
          "1": 7690,
        },
        "13.4": {
          "1": 4683,
        },
        "10.6": {
          "1": 7706,
        },
        "14.4": {
          "1": 2253,
        },
        "12.4": {
          "1": 7248,
        },
        "11.6": {
          "1": 8467,
        },
        "13.6": {
          "1": 4164,
        },
        "8.4": {
          "0": 2166,
          "1": 315,
        },
        "11.8": {
          "1": 8228,
        },
        "9.8": {
          "0": 3,
          "1": 5998,
        },
        "10.2": {
          "1": 6966,
        },
        "8.2": {
          "0": 1963,
          "1": 86,
        },
        "9.2": {
          "0": 612,
          "1": 3770,
        },
        "12.6": {
          "1": 6767,
        },
        "13.2": {
          "1": 5249,
        },
        "14.8": {
          "1": 1642,
        },
        "6.4": {
          "0": 1107,
        },
        "9.4": {
          "0": 261,
          "1": 4601,
        },
        "8.6": {
          "0": 2070,
          "1": 817,
        },
        "15.6": {
          "1": 637,
        },
        "13.8": {
          "1": 3688,
        },
        "15.8": {
          "1": 528,
          "2": 1,
        },
        "15.2": {
          "1": 1097,
        },
        "9.6": {
          "0": 42,
          "1": 5383,
        },
        "14.2": {
          "1": 2692,
        },
        "14.6": {
          "1": 1939,
        },
        "8.8": {
          "0": 1687,
          "1": 1710,
        },
        "7.6": {
          "0": 1159,
        },
        "15.4": {
          "1": 836,
        },
        "7.8": {
          "0": 1449,
        },
        "6.8": {
          "0": 510,
        },
        "7.4": {
          "0": 953,
        },
        "16.2": {
          "1": 316,
          "2": 7,
        },
        "16.4": {
          "1": 253,
          "2": 11,
        },
        "6.6": {
          "0": 378,
        },
        "7.2": {
          "0": 737,
        },
        "16.8": {
          "1": 104,
          "2": 26,
        },
        "17.2": {
          "1": 51,
          "2": 28,
        },
        "17.4": {
          "1": 60,
          "2": 126,
        },
        "16.6": {
          "1": 159,
          "2": 20,
        },
      },
      KONF: {
        "4": {
          "0": 366,
        },
        "5": {
          "0": 915,
        },
        "6": {
          "0": 1924,
        },
        "7": {
          "0": 3330,
        },
        "8": {
          "0": 5182,
          "1": 19,
        },
        "9": {
          "0": 2031,
          "1": 4400,
        },
        "10": {
          "1": 6805,
        },
        "11": {
          "1": 5974,
        },
        "12": {
          "1": 4241,
        },
        "13": {
          "1": 2390,
        },
        "14": {
          "1": 1191,
        },
        "15": {
          "1": 414,
        },
        "16": {
          "1": 129,
        },
        "17": {
          "1": 72,
          "2": 40,
        },
        "9.6": {
          "0": 102,
          "1": 7025,
        },
        "13.4": {
          "1": 1814,
        },
        "11.8": {
          "1": 4713,
        },
        "5.8": {
          "0": 1664,
        },
        "10.2": {
          "1": 6779,
        },
        "8.2": {
          "0": 5451,
          "1": 134,
        },
        "11.2": {
          "1": 5658,
        },
        "6.4": {
          "0": 2450,
        },
        "11.6": {
          "1": 4922,
        },
        "10.6": {
          "1": 6542,
        },
        "8.6": {
          "0": 4565,
          "1": 1569,
        },
        "14.2": {
          "1": 945,
        },
        "13.6": {
          "1": 1524,
        },
        "11.4": {
          "1": 5333,
        },
        "9.8": {
          "0": 3,
          "1": 6996,
        },
        "14.4": {
          "1": 820,
        },
        "9.4": {
          "0": 359,
          "1": 6500,
        },
        "10.4": {
          "1": 6658,
        },
        "7.2": {
          "0": 3899,
        },
        "8.8": {
          "0": 3413,
          "1": 3154,
        },
        "7.8": {
          "0": 4915,
          "1": 2,
        },
        "7.6": {
          "0": 4601,
        },
        "7.4": {
          "0": 4204,
        },
        "5.6": {
          "0": 1494,
        },
        "5.4": {
          "0": 1192,
        },
        "10.8": {
          "1": 6265,
        },
        "6.2": {
          "0": 2152,
        },
        "12.6": {
          "1": 3089,
        },
        "13.2": {
          "1": 2127,
        },
        "12.2": {
          "1": 3826,
        },
        "12.8": {
          "1": 2761,
        },
        "9.2": {
          "0": 1022,
          "1": 5763,
        },
        "8.4": {
          "0": 5254,
          "1": 589,
        },
        "3.6": {
          "0": 1209,
        },
        "6.8": {
          "0": 3099,
        },
        "6.6": {
          "0": 2768,
        },
        "4.6": {
          "0": 595,
        },
        "13.8": {
          "1": 1318,
        },
        "15.8": {
          "1": 176,
        },
        "5.2": {
          "0": 1108,
        },
        "4.8": {
          "0": 800,
        },
        "4.4": {
          "0": 541,
        },
        "14.8": {
          "1": 541,
        },
        "14.6": {
          "1": 669,
        },
        "16.4": {
          "1": 88,
          "2": 2,
        },
        "12.4": {
          "1": 3611,
        },
        "16.2": {
          "1": 103,
          "2": 1,
        },
        "4.2": {
          "0": 508,
        },
        "15.6": {
          "1": 201,
        },
        "3.8": {
          "0": 301,
        },
        "15.4": {
          "1": 261,
        },
        "15.2": {
          "1": 318,
        },
        "16.8": {
          "1": 38,
          "2": 4,
        },
        "16.6": {
          "1": 66,
          "2": 3,
        },
      },
      LEWICA: {
        "11": {
          "1": 6577,
        },
        "12": {
          "1": 15152,
        },
        "13": {
          "1": 12325,
        },
        "14": {
          "1": 4301,
        },
        "11.2": {
          "1": 8489,
        },
        "11.6": {
          "1": 12461,
        },
        "12.2": {
          "1": 15654,
        },
        "12.4": {
          "1": 15602,
        },
        "11.8": {
          "1": 14120,
        },
        "12.8": {
          "1": 13819,
        },
        "11.4": {
          "1": 10513,
        },
        "12.6": {
          "1": 15164,
        },
        "10.6": {
          "1": 3440,
        },
        "13.8": {
          "1": 5411,
        },
        "13.6": {
          "1": 7139,
        },
        "13.2": {
          "1": 10604,
        },
        "13.4": {
          "1": 8983,
        },
        "14.8": {
          "1": 2982,
          "2": 11,
        },
        "14.4": {
          "1": 2275,
        },
        "14.2": {
          "1": 3243,
        },
        "10.8": {
          "1": 4823,
        },
        "14.6": {
          "1": 1582,
        },
        "10.4": {
          "1": 2212,
        },
        "10.2": {
          "0": 22,
          "1": 3096,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 1949,
      TD: 5215,
      LEWICA: 1114,
    },
    potencjalneMandaty: {
      KO: 35184,
      TD: 18759,
      LEWICA: 613,
    },
    ostatnieMandaty: {
      KO: 67775,
      TD: 24076,
      LEWICA: 1991,
    },
    dawcyGlosow: {
      KO: 4995,
      TD: 14370,
      LEWICA: 5453,
    },
  },
  Rybnik: {
    procentyWOkreguSrednia: {
      PIS: 41.13334833603273,
      KO: 28.820802645781047,
      TD: 10.69544433778301,
      KONF: 11.329812537679743,
      LEWICA: 8.02059214272347,
    },
    odchylenieWOkregu: {
      PIS: 3.0437130272300394,
      KO: 3.934755851184815,
      TD: 2.013774734692939,
      LEWICA: 1.416840104631582,
      KONF: 1.33869885767542,
    },
    wykresRozkladu: {
      "57.4%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "12.8%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "9.6%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "6.9%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "5.6%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 0,
      },
      "4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "1.7%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 0,
      },
      "1.1%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.7%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.1%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 0,
      },
      "0%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "78.8%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
      "16.4%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
      "4.8%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "34": {
          "3": 98,
          "4": 24,
        },
        "35": {
          "3": 200,
          "4": 193,
        },
        "36": {
          "3": 297,
          "4": 601,
        },
        "37": {
          "3": 297,
          "4": 1535,
        },
        "38": {
          "3": 228,
          "4": 2765,
        },
        "39": {
          "3": 44,
          "4": 4234,
          "5": 1,
        },
        "40": {
          "4": 5664,
          "5": 20,
        },
        "41": {
          "4": 5769,
          "5": 192,
        },
        "42": {
          "4": 5125,
          "5": 528,
        },
        "43": {
          "4": 3405,
          "5": 1091,
        },
        "44": {
          "4": 1754,
          "5": 1569,
        },
        "45": {
          "4": 528,
          "5": 1582,
        },
        "46": {
          "4": 101,
          "5": 1074,
        },
        "47": {
          "4": 27,
          "5": 530,
        },
        "48": {
          "4": 6,
          "5": 296,
        },
        "49": {
          "4": 1,
          "5": 99,
          "6": 7,
        },
        "50": {
          "5": 24,
          "6": 6,
        },
        "42.4": {
          "4": 4435,
          "5": 737,
        },
        "41.8": {
          "4": 5242,
          "5": 448,
        },
        "43.4": {
          "4": 2775,
          "5": 1343,
        },
        "39.4": {
          "3": 10,
          "4": 4997,
          "5": 5,
        },
        "41.4": {
          "4": 5689,
          "5": 271,
        },
        "39.8": {
          "4": 5352,
          "5": 13,
        },
        "43.8": {
          "4": 2109,
          "5": 1462,
        },
        "44.8": {
          "4": 720,
          "5": 1571,
        },
        "37.2": {
          "3": 302,
          "4": 1760,
        },
        "38.2": {
          "3": 163,
          "4": 3085,
        },
        "37.6": {
          "3": 285,
          "4": 2346,
        },
        "39.6": {
          "3": 4,
          "4": 5241,
          "5": 6,
        },
        "46.2": {
          "4": 82,
          "5": 969,
        },
        "33.8": {
          "3": 102,
          "4": 18,
        },
        "34.8": {
          "3": 212,
          "4": 145,
        },
        "45.2": {
          "4": 395,
          "5": 1494,
        },
        "45.8": {
          "4": 176,
          "5": 1147,
        },
        "40.6": {
          "4": 5790,
          "5": 100,
        },
        "44.2": {
          "4": 1421,
          "5": 1614,
        },
        "36.6": {
          "3": 312,
          "4": 1068,
        },
        "45.6": {
          "4": 212,
          "5": 1299,
        },
        "36.8": {
          "3": 280,
          "4": 1284,
        },
        "42.8": {
          "4": 3897,
          "5": 937,
        },
        "38.6": {
          "3": 100,
          "4": 3796,
        },
        "40.8": {
          "4": 5827,
          "5": 124,
        },
        "36.2": {
          "3": 278,
          "4": 733,
        },
        "40.2": {
          "4": 5562,
          "5": 31,
        },
        "38.8": {
          "3": 70,
          "4": 4083,
        },
        "39.2": {
          "3": 34,
          "4": 4569,
          "5": 1,
        },
        "44.4": {
          "4": 1127,
          "5": 1585,
        },
        "33.6": {
          "3": 417,
          "4": 39,
        },
        "41.6": {
          "4": 5566,
          "5": 412,
        },
        "42.2": {
          "4": 4791,
          "5": 665,
        },
        "44.6": {
          "4": 854,
          "5": 1551,
        },
        "41.2": {
          "4": 5668,
          "5": 224,
        },
        "40.4": {
          "4": 5905,
          "5": 59,
        },
        "43.6": {
          "4": 2298,
          "5": 1422,
        },
        "48.2": {
          "4": 3,
          "5": 217,
          "6": 1,
        },
        "35.6": {
          "3": 253,
          "4": 454,
        },
        "43.2": {
          "4": 3201,
          "5": 1127,
        },
        "42.6": {
          "4": 4070,
          "5": 840,
        },
        "36.4": {
          "3": 290,
          "4": 863,
        },
        "38.4": {
          "3": 146,
          "4": 3481,
        },
        "37.4": {
          "3": 290,
          "4": 1967,
        },
        "37.8": {
          "3": 249,
          "4": 2427,
        },
        "47.2": {
          "4": 18,
          "5": 491,
          "6": 1,
        },
        "35.4": {
          "3": 256,
          "4": 328,
        },
        "47.6": {
          "4": 9,
          "5": 374,
          "6": 1,
        },
        "46.6": {
          "4": 54,
          "5": 741,
        },
        "35.8": {
          "3": 249,
          "4": 498,
        },
        "47.4": {
          "4": 10,
          "5": 435,
          "6": 1,
        },
        "35.2": {
          "3": 226,
          "4": 236,
        },
        "48.6": {
          "4": 2,
          "5": 160,
          "6": 5,
        },
        "34.2": {
          "3": 100,
          "4": 51,
        },
        "45.4": {
          "4": 318,
          "5": 1366,
        },
        "49.2": {
          "5": 92,
          "6": 12,
        },
        "46.8": {
          "4": 33,
          "5": 640,
        },
        "48.4": {
          "4": 5,
          "5": 173,
          "6": 6,
        },
        "46.4": {
          "4": 69,
          "5": 847,
        },
        "47.8": {
          "4": 4,
          "5": 317,
          "6": 2,
        },
        "49.4": {
          "5": 80,
          "6": 6,
        },
        "34.6": {
          "3": 158,
          "4": 97,
        },
        "34.4": {
          "3": 160,
          "4": 62,
        },
        "48.8": {
          "5": 121,
          "6": 6,
        },
        "49.6": {
          "5": 66,
          "6": 4,
        },
        "50.2": {
          "5": 111,
          "6": 49,
        },
        "49.8": {
          "5": 35,
          "6": 5,
        },
      },
      KO: {
        "19": {
          "1": 96,
          "2": 180,
        },
        "20": {
          "2": 102,
        },
        "21": {
          "2": 254,
        },
        "22": {
          "2": 492,
        },
        "23": {
          "2": 839,
        },
        "24": {
          "2": 1584,
          "3": 4,
        },
        "25": {
          "2": 2243,
          "3": 171,
        },
        "26": {
          "2": 2115,
          "3": 1162,
        },
        "27": {
          "2": 1124,
          "3": 3114,
        },
        "28": {
          "2": 572,
          "3": 4365,
        },
        "29": {
          "2": 112,
          "3": 5138,
        },
        "30": {
          "3": 5066,
        },
        "31": {
          "3": 3997,
        },
        "32": {
          "3": 3097,
          "4": 10,
        },
        "33": {
          "3": 1919,
          "4": 116,
        },
        "34": {
          "3": 939,
          "4": 211,
        },
        "35": {
          "3": 352,
          "4": 263,
        },
        "36": {
          "3": 106,
          "4": 192,
        },
        "37": {
          "3": 10,
          "4": 125,
        },
        "38": {
          "4": 49,
        },
        "39": {
          "4": 13,
        },
        "40": {
          "4": 3,
        },
        "30.6": {
          "3": 4444,
        },
        "31.6": {
          "3": 3639,
          "4": 2,
        },
        "27.2": {
          "2": 916,
          "3": 3560,
        },
        "25.6": {
          "2": 2276,
          "3": 632,
        },
        "31.4": {
          "3": 3818,
          "4": 2,
        },
        "26.6": {
          "2": 1568,
          "3": 2253,
        },
        "25.8": {
          "2": 2270,
          "3": 885,
        },
        "30.8": {
          "3": 4251,
        },
        "25.2": {
          "2": 2222,
          "3": 238,
        },
        "32.6": {
          "3": 2397,
          "4": 68,
        },
        "31.2": {
          "3": 4012,
        },
        "21.4": {
          "2": 309,
        },
        "34.2": {
          "3": 774,
          "4": 239,
        },
        "31.8": {
          "3": 3373,
          "4": 7,
        },
        "29.8": {
          "2": 1,
          "3": 4979,
        },
        "28.4": {
          "2": 335,
          "3": 4730,
        },
        "29.4": {
          "2": 34,
          "3": 5222,
        },
        "24.8": {
          "2": 2098,
          "3": 108,
        },
        "25.4": {
          "2": 2346,
          "3": 417,
        },
        "29.2": {
          "2": 76,
          "3": 5161,
        },
        "30.2": {
          "3": 4849,
        },
        "28.8": {
          "2": 207,
          "3": 5041,
        },
        "33.8": {
          "3": 1127,
          "4": 243,
        },
        "22.2": {
          "2": 584,
        },
        "33.4": {
          "3": 1504,
          "4": 209,
        },
        "27.8": {
          "2": 593,
          "3": 4228,
        },
        "26.4": {
          "2": 1773,
          "3": 2016,
        },
        "35.2": {
          "3": 306,
          "4": 211,
        },
        "29.6": {
          "2": 7,
          "3": 5121,
        },
        "24.6": {
          "2": 1916,
          "3": 64,
        },
        "28.2": {
          "2": 440,
          "3": 4602,
        },
        "33.6": {
          "3": 1273,
          "4": 214,
        },
        "36.4": {
          "3": 31,
          "4": 127,
        },
        "28.6": {
          "2": 254,
          "3": 4929,
        },
        "26.2": {
          "2": 1975,
          "3": 1577,
        },
        "23.8": {
          "2": 1349,
          "3": 1,
        },
        "32.4": {
          "3": 2516,
          "4": 49,
        },
        "35.4": {
          "3": 220,
          "4": 230,
        },
        "22.6": {
          "2": 696,
        },
        "32.2": {
          "3": 2850,
          "4": 21,
        },
        "30.4": {
          "3": 4766,
        },
        "37.6": {
          "4": 60,
        },
        "24.2": {
          "2": 1627,
          "3": 19,
        },
        "34.4": {
          "3": 711,
          "4": 249,
        },
        "32.8": {
          "3": 2221,
          "4": 91,
        },
        "23.4": {
          "2": 1107,
          "3": 1,
        },
        "22.8": {
          "2": 813,
        },
        "24.4": {
          "2": 1708,
          "3": 23,
        },
        "26.8": {
          "2": 1326,
          "3": 2781,
        },
        "22.4": {
          "2": 616,
        },
        "27.6": {
          "2": 760,
          "3": 3987,
        },
        "27.4": {
          "2": 848,
          "3": 3756,
        },
        "33.2": {
          "3": 1716,
          "4": 136,
        },
        "23.2": {
          "2": 1031,
        },
        "21.6": {
          "2": 357,
        },
        "34.8": {
          "3": 456,
          "4": 243,
        },
        "21.2": {
          "2": 278,
        },
        "20.8": {
          "2": 218,
        },
        "19.8": {
          "2": 103,
        },
        "39.6": {
          "4": 8,
        },
        "35.8": {
          "3": 131,
          "4": 215,
        },
        "34.6": {
          "3": 579,
          "4": 238,
        },
        "36.8": {
          "3": 5,
          "4": 139,
        },
        "21.8": {
          "2": 437,
        },
        "35.6": {
          "3": 169,
          "4": 210,
        },
        "38.4": {
          "4": 20,
        },
        "20.6": {
          "2": 180,
        },
        "23.6": {
          "2": 1260,
        },
        "37.8": {
          "4": 53,
        },
        "36.6": {
          "3": 21,
          "4": 144,
        },
        "20.2": {
          "2": 143,
        },
        "20.4": {
          "2": 194,
        },
        "19.4": {
          "1": 1,
          "2": 57,
        },
        "19.2": {
          "1": 2,
          "2": 44,
        },
        "36.2": {
          "3": 49,
          "4": 181,
        },
        "38.2": {
          "4": 39,
        },
        "37.2": {
          "3": 2,
          "4": 84,
        },
        "37.4": {
          "4": 71,
        },
        "19.6": {
          "2": 104,
        },
        "39.4": {
          "4": 8,
        },
        "39.8": {
          "4": 5,
        },
        "38.8": {
          "4": 16,
        },
        "40.6": {
          "4": 2,
        },
        "39.2": {
          "4": 7,
        },
        "38.6": {
          "4": 16,
        },
        "40.2": {
          "4": 4,
        },
        "40.4": {
          "4": 4,
        },
      },
      TD: {
        "6": {
          "0": 427,
        },
        "7": {
          "0": 1276,
        },
        "8": {
          "0": 3044,
          "1": 29,
        },
        "9": {
          "0": 1256,
          "1": 4326,
        },
        "10": {
          "1": 7893,
        },
        "11": {
          "1": 8235,
        },
        "12": {
          "1": 6710,
        },
        "13": {
          "1": 4005,
        },
        "14": {
          "1": 1803,
        },
        "15": {
          "1": 653,
        },
        "16": {
          "1": 185,
        },
        "7.4": {
          "0": 1819,
        },
        "12.2": {
          "1": 6215,
        },
        "9.2": {
          "0": 798,
          "1": 5376,
        },
        "13.4": {
          "1": 3109,
        },
        "10.8": {
          "1": 8648,
        },
        "14.8": {
          "1": 802,
        },
        "12.8": {
          "1": 4484,
        },
        "11.2": {
          "1": 8172,
        },
        "8.8": {
          "0": 1964,
          "1": 2978,
        },
        "11.8": {
          "1": 7104,
        },
        "9.4": {
          "0": 446,
          "1": 6277,
        },
        "9.8": {
          "0": 37,
          "1": 7574,
        },
        "13.8": {
          "1": 2185,
        },
        "10.6": {
          "1": 8285,
        },
        "11.4": {
          "1": 7902,
        },
        "11.6": {
          "1": 7665,
        },
        "8.6": {
          "0": 2667,
          "1": 1816,
        },
        "13.6": {
          "1": 2522,
        },
        "10.2": {
          "1": 8087,
        },
        "7.6": {
          "0": 2209,
        },
        "12.4": {
          "1": 5639,
        },
        "9.6": {
          "0": 188,
          "1": 6919,
        },
        "13.2": {
          "1": 3427,
        },
        "8.2": {
          "0": 3236,
          "1": 259,
        },
        "8.4": {
          "0": 3174,
          "1": 883,
        },
        "6.2": {
          "0": 555,
        },
        "10.4": {
          "1": 8318,
        },
        "14.2": {
          "1": 1501,
        },
        "12.6": {
          "1": 5098,
        },
        "6.8": {
          "0": 1009,
        },
        "14.6": {
          "1": 970,
        },
        "14.4": {
          "1": 1231,
        },
        "15.2": {
          "1": 488,
        },
        "16.8": {
          "1": 88,
          "2": 64,
        },
        "15.4": {
          "1": 351,
        },
        "15.8": {
          "1": 225,
          "2": 1,
        },
        "6.4": {
          "0": 651,
        },
        "7.8": {
          "0": 2656,
          "1": 3,
        },
        "7.2": {
          "0": 1497,
        },
        "6.6": {
          "0": 838,
        },
        "5.6": {
          "0": 875,
        },
        "16.2": {
          "1": 113,
          "2": 4,
        },
        "5.8": {
          "0": 296,
        },
        "16.4": {
          "1": 86,
          "2": 9,
        },
        "16.6": {
          "1": 58,
          "2": 9,
        },
        "15.6": {
          "1": 298,
        },
      },
      KONF: {
        "8": {
          "0": 1370,
          "1": 10,
        },
        "9": {
          "0": 529,
          "1": 2016,
        },
        "10": {
          "0": 1,
          "1": 7220,
        },
        "11": {
          "1": 11727,
        },
        "12": {
          "1": 10033,
        },
        "13": {
          "1": 5327,
        },
        "14": {
          "1": 1768,
        },
        "15": {
          "1": 365,
        },
        "11.6": {
          "1": 11729,
        },
        "10.4": {
          "1": 9516,
        },
        "12.4": {
          "1": 8437,
        },
        "9.2": {
          "0": 339,
          "1": 2931,
        },
        "10.2": {
          "1": 8483,
        },
        "11.4": {
          "1": 11722,
        },
        "11.8": {
          "1": 10935,
        },
        "9.6": {
          "0": 115,
          "1": 5040,
        },
        "9.8": {
          "0": 30,
          "1": 6389,
        },
        "9.4": {
          "0": 225,
          "1": 4023,
        },
        "10.8": {
          "1": 10929,
        },
        "13.4": {
          "1": 3640,
        },
        "13.6": {
          "1": 2892,
        },
        "11.2": {
          "1": 12024,
        },
        "12.8": {
          "1": 6469,
        },
        "12.6": {
          "1": 7383,
        },
        "12.2": {
          "1": 9653,
        },
        "10.6": {
          "1": 10530,
        },
        "8.6": {
          "0": 807,
          "1": 630,
        },
        "13.2": {
          "1": 4580,
        },
        "14.6": {
          "1": 725,
        },
        "13.8": {
          "1": 2256,
        },
        "15.4": {
          "1": 480,
          "2": 19,
        },
        "8.4": {
          "0": 712,
          "1": 249,
        },
        "14.4": {
          "1": 998,
        },
        "8.2": {
          "0": 622,
          "1": 74,
        },
        "14.2": {
          "1": 1336,
        },
        "8.8": {
          "0": 716,
          "1": 1251,
        },
        "14.8": {
          "1": 517,
        },
        "15.2": {
          "1": 228,
        },
      },
      LEWICA: {
        "5": {
          "0": 1051,
        },
        "6": {
          "0": 3933,
        },
        "7": {
          "0": 8845,
        },
        "8": {
          "0": 11601,
          "1": 38,
        },
        "9": {
          "0": 4423,
          "1": 4651,
        },
        "10": {
          "0": 1,
          "1": 4132,
        },
        "11": {
          "1": 1109,
        },
        "12": {
          "1": 223,
        },
        "5.8": {
          "0": 3214,
        },
        "9.4": {
          "0": 1306,
          "1": 5666,
        },
        "7.2": {
          "0": 9841,
        },
        "7.8": {
          "0": 11492,
        },
        "8.2": {
          "0": 11151,
          "1": 195,
        },
        "8.4": {
          "0": 10366,
          "1": 839,
        },
        "6.6": {
          "0": 6727,
        },
        "7.4": {
          "0": 10524,
        },
        "10.6": {
          "1": 2011,
        },
        "7.6": {
          "0": 10829,
        },
        "8.6": {
          "0": 8655,
          "1": 1852,
        },
        "5.4": {
          "0": 1927,
        },
        "9.8": {
          "0": 51,
          "1": 5029,
        },
        "9.2": {
          "0": 2569,
          "1": 5370,
        },
        "6.4": {
          "0": 5878,
        },
        "5.6": {
          "0": 2422,
        },
        "6.8": {
          "0": 7741,
        },
        "8.8": {
          "0": 6632,
          "1": 3313,
        },
        "9.6": {
          "0": 434,
          "1": 5356,
        },
        "11.6": {
          "1": 435,
        },
        "10.2": {
          "1": 3273,
        },
        "12.2": {
          "1": 392,
        },
        "4.4": {
          "0": 1014,
        },
        "4.6": {
          "0": 491,
        },
        "5.2": {
          "0": 1406,
        },
        "11.2": {
          "1": 885,
        },
        "6.2": {
          "0": 4844,
        },
        "11.4": {
          "1": 599,
        },
        "10.4": {
          "1": 2657,
        },
        "10.8": {
          "1": 1533,
        },
        "4.8": {
          "0": 773,
        },
        "11.8": {
          "1": 301,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3421,
      TD: 6296,
      LEWICA: 4107,
    },
    potencjalneMandaty: {
      KO: 63532,
      TD: 26358,
      LEWICA: 87673,
    },
    ostatnieMandaty: {
      KO: 53897,
      TD: 26223,
      LEWICA: 28361,
    },
    dawcyGlosow: {
      KO: 8442,
      TD: 16661,
      LEWICA: 7096,
    },
  },
  Katowice: {
    procentyWOkreguSrednia: {
      PIS: 31.855532599495895,
      KO: 39.41571729975596,
      TD: 9.472740716211856,
      KONF: 8.303534626081245,
      LEWICA: 10.952474758455066,
    },
    odchylenieWOkregu: {
      PIS: 4.906411497280196,
      KO: 3.3598482600255335,
      TD: 2.013774734692939,
      LEWICA: 1.0022691884339265,
      KONF: 2.787579410098326,
    },
    wykresRozkladu: {
      "58.7%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "22.4%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "6.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "4.4%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 0,
      },
      "4%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "1.2%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.8%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.7%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "0.3%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "0.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "63.6%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "24.9%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
      "10.1%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
    },
    histogram: {
      PIS: {
        "20": {
          "2": 34,
          "3": 18,
        },
        "21": {
          "2": 16,
          "3": 69,
        },
        "22": {
          "2": 1,
          "3": 173,
        },
        "23": {
          "3": 307,
        },
        "24": {
          "3": 524,
        },
        "25": {
          "3": 832,
        },
        "26": {
          "3": 1166,
          "4": 64,
        },
        "27": {
          "3": 1143,
          "4": 612,
        },
        "28": {
          "3": 606,
          "4": 1820,
        },
        "29": {
          "3": 117,
          "4": 2981,
        },
        "30": {
          "3": 1,
          "4": 3739,
        },
        "31": {
          "4": 4054,
        },
        "32": {
          "4": 4338,
          "5": 24,
        },
        "33": {
          "4": 3662,
          "5": 392,
        },
        "34": {
          "4": 2486,
          "5": 1175,
        },
        "35": {
          "4": 1277,
          "5": 1740,
        },
        "36": {
          "4": 419,
          "5": 1882,
        },
        "37": {
          "4": 42,
          "5": 1581,
        },
        "38": {
          "5": 1055,
        },
        "39": {
          "5": 612,
          "6": 19,
        },
        "40": {
          "5": 292,
          "6": 39,
        },
        "41": {
          "5": 95,
          "6": 43,
        },
        "42": {
          "5": 32,
          "6": 47,
        },
        "43": {
          "5": 4,
          "6": 25,
        },
        "44": {
          "6": 10,
        },
        "46": {
          "6": 2,
        },
        "35.2": {
          "4": 1057,
          "5": 1935,
        },
        "32.8": {
          "4": 4066,
          "5": 270,
        },
        "27.6": {
          "3": 874,
          "4": 1304,
        },
        "24.2": {
          "3": 592,
        },
        "30.6": {
          "4": 4100,
        },
        "23.4": {
          "3": 389,
        },
        "29.2": {
          "3": 63,
          "4": 3122,
        },
        "29.6": {
          "3": 17,
          "4": 3451,
        },
        "31.8": {
          "4": 4389,
          "5": 6,
        },
        "35.8": {
          "4": 526,
          "5": 1912,
        },
        "34.2": {
          "4": 2326,
          "5": 1348,
        },
        "33.8": {
          "4": 2801,
          "5": 986,
        },
        "34.8": {
          "4": 1525,
          "5": 1723,
        },
        "31.4": {
          "4": 4344,
        },
        "31.2": {
          "4": 4188,
        },
        "37.4": {
          "4": 7,
          "5": 1380,
        },
        "31.6": {
          "4": 4227,
          "5": 2,
        },
        "37.2": {
          "4": 16,
          "5": 1442,
        },
        "32.4": {
          "4": 4269,
          "5": 112,
        },
        "30.4": {
          "4": 3887,
        },
        "25.4": {
          "3": 946,
          "4": 5,
        },
        "38.8": {
          "5": 653,
          "6": 13,
        },
        "30.2": {
          "3": 1,
          "4": 3790,
        },
        "24.6": {
          "3": 694,
        },
        "26.8": {
          "3": 1198,
          "4": 471,
        },
        "36.2": {
          "4": 286,
          "5": 1822,
        },
        "34.4": {
          "4": 2016,
          "5": 1533,
        },
        "36.4": {
          "4": 201,
          "5": 1891,
        },
        "34.6": {
          "4": 1787,
          "5": 1618,
        },
        "28.4": {
          "3": 382,
          "4": 2439,
        },
        "33.6": {
          "4": 3028,
          "5": 842,
        },
        "27.2": {
          "3": 1114,
          "4": 854,
        },
        "32.2": {
          "4": 4288,
          "5": 54,
        },
        "32.6": {
          "4": 4094,
          "5": 177,
        },
        "25.2": {
          "3": 878,
          "4": 1,
        },
        "29.4": {
          "3": 27,
          "4": 3394,
        },
        "28.6": {
          "3": 244,
          "4": 2679,
        },
        "29.8": {
          "3": 2,
          "4": 3604,
        },
        "33.4": {
          "4": 3355,
          "5": 654,
        },
        "28.2": {
          "3": 469,
          "4": 2040,
        },
        "38.2": {
          "5": 920,
        },
        "27.8": {
          "3": 763,
          "4": 1543,
        },
        "30.8": {
          "4": 3998,
        },
        "35.6": {
          "4": 676,
          "5": 1947,
        },
        "24.8": {
          "3": 748,
        },
        "33.2": {
          "4": 3566,
          "5": 515,
        },
        "39.8": {
          "5": 326,
          "6": 38,
        },
        "38.4": {
          "5": 870,
          "6": 4,
        },
        "26.2": {
          "3": 1193,
          "4": 129,
        },
        "28.8": {
          "3": 170,
          "4": 2772,
        },
        "26.4": {
          "3": 1310,
          "4": 191,
        },
        "27.4": {
          "3": 986,
          "4": 977,
        },
        "38.6": {
          "5": 765,
          "6": 8,
        },
        "37.8": {
          "4": 3,
          "5": 1169,
        },
        "26.6": {
          "3": 1251,
          "4": 347,
        },
        "36.6": {
          "4": 125,
          "5": 1792,
        },
        "35.4": {
          "4": 893,
          "5": 1925,
        },
        "24.4": {
          "3": 658,
        },
        "22.8": {
          "3": 254,
        },
        "41.2": {
          "5": 92,
          "6": 47,
        },
        "39.2": {
          "5": 537,
          "6": 12,
        },
        "22.2": {
          "3": 212,
        },
        "23.2": {
          "3": 335,
        },
        "37.6": {
          "4": 3,
          "5": 1272,
        },
        "23.8": {
          "3": 415,
        },
        "36.8": {
          "4": 83,
          "5": 1726,
        },
        "19.6": {
          "1": 2,
          "2": 262,
          "3": 8,
        },
        "22.6": {
          "3": 260,
        },
        "22.4": {
          "3": 202,
        },
        "25.8": {
          "3": 1148,
          "4": 27,
        },
        "40.2": {
          "5": 249,
          "6": 34,
        },
        "25.6": {
          "3": 1030,
          "4": 11,
        },
        "39.4": {
          "5": 461,
          "6": 26,
        },
        "39.6": {
          "5": 401,
          "6": 36,
        },
        "40.8": {
          "5": 148,
          "6": 49,
        },
        "42.4": {
          "5": 15,
          "6": 38,
        },
        "19.8": {
          "2": 37,
          "3": 10,
        },
        "23.6": {
          "3": 414,
        },
        "40.6": {
          "5": 149,
          "6": 54,
        },
        "21.6": {
          "2": 3,
          "3": 122,
        },
        "21.4": {
          "2": 6,
          "3": 128,
        },
        "21.8": {
          "2": 1,
          "3": 145,
        },
        "41.8": {
          "5": 36,
          "6": 49,
        },
        "40.4": {
          "5": 200,
          "6": 37,
        },
        "41.4": {
          "5": 71,
          "6": 46,
        },
        "20.2": {
          "2": 38,
          "3": 23,
        },
        "21.2": {
          "2": 12,
          "3": 101,
        },
        "20.4": {
          "2": 29,
          "3": 40,
        },
        "43.6": {
          "5": 1,
          "6": 14,
        },
        "20.6": {
          "2": 26,
          "3": 44,
        },
        "42.2": {
          "5": 18,
          "6": 33,
        },
        "43.2": {
          "5": 6,
          "6": 24,
        },
        "20.8": {
          "2": 19,
          "3": 56,
        },
        "44.2": {
          "6": 10,
        },
        "42.6": {
          "5": 9,
          "6": 32,
        },
        "44.6": {
          "6": 4,
        },
        "43.8": {
          "6": 21,
        },
        "43.4": {
          "5": 4,
          "6": 19,
        },
        "41.6": {
          "5": 44,
          "6": 63,
        },
        "42.8": {
          "5": 7,
          "6": 31,
        },
        "45.4": {
          "6": 4,
        },
        "44.8": {
          "5": 1,
          "6": 2,
        },
        "45.6": {
          "6": 6,
        },
        "44.4": {
          "6": 4,
        },
        "45.2": {
          "6": 3,
        },
        "46.2": {
          "6": 1,
        },
      },
      KO: {
        "31": {
          "3": 8,
          "4": 521,
        },
        "32": {
          "4": 242,
          "5": 2,
        },
        "33": {
          "4": 485,
          "5": 66,
        },
        "34": {
          "4": 689,
          "5": 389,
        },
        "35": {
          "4": 641,
          "5": 1211,
        },
        "36": {
          "4": 275,
          "5": 2589,
        },
        "37": {
          "4": 32,
          "5": 3846,
        },
        "38": {
          "4": 1,
          "5": 4597,
          "6": 5,
        },
        "39": {
          "5": 4903,
          "6": 189,
        },
        "40": {
          "5": 4031,
          "6": 843,
        },
        "41": {
          "5": 2585,
          "6": 1769,
        },
        "42": {
          "5": 1230,
          "6": 2378,
        },
        "43": {
          "5": 340,
          "6": 2158,
        },
        "44": {
          "5": 52,
          "6": 1735,
        },
        "45": {
          "5": 1,
          "6": 1063,
          "7": 20,
        },
        "46": {
          "6": 578,
          "7": 80,
        },
        "47": {
          "6": 238,
          "7": 101,
        },
        "48": {
          "6": 97,
          "7": 81,
        },
        "49": {
          "6": 18,
          "7": 55,
        },
        "39.4": {
          "5": 4708,
          "6": 377,
        },
        "33.8": {
          "4": 690,
          "5": 285,
        },
        "42.6": {
          "5": 625,
          "6": 2332,
        },
        "44.4": {
          "5": 11,
          "6": 1476,
          "7": 4,
        },
        "41.6": {
          "5": 1678,
          "6": 2172,
        },
        "40.6": {
          "5": 3237,
          "6": 1365,
        },
        "34.4": {
          "4": 737,
          "5": 607,
        },
        "35.6": {
          "4": 432,
          "5": 1971,
        },
        "38.6": {
          "5": 4959,
          "6": 65,
        },
        "39.6": {
          "5": 4661,
          "6": 462,
        },
        "38.2": {
          "5": 4849,
          "6": 10,
        },
        "41.2": {
          "5": 2333,
          "6": 1955,
        },
        "44.2": {
          "5": 33,
          "6": 1601,
          "7": 1,
        },
        "47.6": {
          "6": 137,
          "7": 89,
        },
        "33.2": {
          "4": 557,
          "5": 73,
        },
        "39.8": {
          "5": 4281,
          "6": 633,
        },
        "36.2": {
          "4": 226,
          "5": 2815,
        },
        "35.4": {
          "4": 503,
          "5": 1682,
        },
        "40.2": {
          "5": 3792,
          "6": 978,
        },
        "41.4": {
          "5": 1966,
          "6": 2073,
        },
        "43.6": {
          "5": 129,
          "6": 1955,
        },
        "38.4": {
          "5": 4863,
          "6": 32,
        },
        "43.4": {
          "5": 178,
          "6": 2073,
        },
        "36.8": {
          "4": 60,
          "5": 3540,
        },
        "37.6": {
          "4": 1,
          "5": 4354,
        },
        "42.4": {
          "5": 832,
          "6": 2366,
        },
        "38.8": {
          "5": 4863,
          "6": 137,
        },
        "43.2": {
          "5": 258,
          "6": 2086,
        },
        "35.2": {
          "4": 579,
          "5": 1476,
        },
        "39.2": {
          "5": 4867,
          "6": 287,
        },
        "36.6": {
          "4": 97,
          "5": 3341,
        },
        "40.8": {
          "5": 2993,
          "6": 1576,
        },
        "35.8": {
          "4": 405,
          "5": 2262,
        },
        "42.2": {
          "5": 978,
          "6": 2367,
        },
        "37.2": {
          "4": 23,
          "5": 4026,
        },
        "34.8": {
          "4": 711,
          "5": 1055,
        },
        "34.2": {
          "4": 745,
          "5": 513,
        },
        "32.4": {
          "4": 338,
          "5": 15,
        },
        "40.4": {
          "5": 3662,
          "6": 1125,
        },
        "36.4": {
          "4": 163,
          "5": 3122,
        },
        "41.8": {
          "5": 1449,
          "6": 2334,
        },
        "32.6": {
          "4": 426,
          "5": 15,
        },
        "47.4": {
          "6": 174,
          "7": 108,
        },
        "46.4": {
          "6": 443,
          "7": 94,
        },
        "37.8": {
          "4": 1,
          "5": 4597,
          "6": 2,
        },
        "37.4": {
          "4": 11,
          "5": 4163,
        },
        "48.8": {
          "6": 34,
          "7": 70,
        },
        "49.4": {
          "6": 48,
          "7": 375,
          "8": 10,
        },
        "42.8": {
          "5": 457,
          "6": 2227,
        },
        "45.4": {
          "6": 847,
          "7": 39,
        },
        "45.2": {
          "5": 1,
          "6": 1002,
          "7": 27,
        },
        "32.8": {
          "4": 485,
          "5": 38,
        },
        "33.6": {
          "4": 671,
          "5": 236,
        },
        "34.6": {
          "4": 679,
          "5": 876,
        },
        "45.6": {
          "6": 734,
          "7": 38,
        },
        "45.8": {
          "6": 660,
          "7": 36,
        },
        "43.8": {
          "5": 76,
          "6": 1809,
        },
        "46.2": {
          "6": 460,
          "7": 59,
        },
        "48.4": {
          "6": 60,
          "7": 71,
        },
        "33.4": {
          "4": 623,
          "5": 142,
        },
        "44.6": {
          "5": 10,
          "6": 1318,
          "7": 7,
        },
        "44.8": {
          "5": 3,
          "6": 1201,
          "7": 17,
        },
        "31.6": {
          "4": 170,
        },
        "32.2": {
          "4": 323,
          "5": 3,
        },
        "46.8": {
          "6": 283,
          "7": 93,
        },
        "46.6": {
          "6": 369,
          "7": 64,
        },
        "47.8": {
          "6": 112,
          "7": 100,
        },
        "49.2": {
          "6": 20,
          "7": 71,
        },
        "31.8": {
          "4": 223,
          "5": 1,
        },
        "47.2": {
          "6": 200,
          "7": 104,
        },
        "31.4": {
          "4": 160,
        },
        "31.2": {
          "4": 125,
        },
        "48.6": {
          "6": 50,
          "7": 74,
        },
        "48.2": {
          "6": 71,
          "7": 83,
        },
      },
      TD: {
        "5": {
          "0": 537,
        },
        "6": {
          "0": 1628,
        },
        "7": {
          "0": 1056,
          "1": 2560,
        },
        "8": {
          "1": 6122,
        },
        "9": {
          "1": 8083,
        },
        "10": {
          "1": 8043,
        },
        "11": {
          "1": 6028,
        },
        "12": {
          "1": 3364,
        },
        "13": {
          "1": 1563,
          "2": 94,
        },
        "14": {
          "1": 146,
          "2": 380,
        },
        "15": {
          "1": 1,
          "2": 162,
        },
        "12.2": {
          "1": 3010,
        },
        "8.4": {
          "1": 6980,
        },
        "11.2": {
          "1": 5459,
        },
        "10.4": {
          "1": 7387,
        },
        "9.4": {
          "1": 8212,
        },
        "6.2": {
          "0": 1997,
          "1": 1,
        },
        "6.4": {
          "0": 2334,
          "1": 43,
        },
        "9.8": {
          "1": 7953,
        },
        "9.6": {
          "1": 8267,
        },
        "7.8": {
          "1": 5663,
        },
        "7.6": {
          "1": 5180,
        },
        "11.8": {
          "1": 4063,
        },
        "8.2": {
          "1": 6531,
        },
        "10.8": {
          "1": 6363,
        },
        "11.4": {
          "1": 5070,
        },
        "8.6": {
          "1": 7443,
        },
        "7.2": {
          "0": 370,
          "1": 3725,
        },
        "12.8": {
          "1": 1913,
          "2": 19,
        },
        "6.8": {
          "0": 1877,
          "1": 1274,
        },
        "10.6": {
          "1": 6996,
        },
        "12.4": {
          "1": 2638,
        },
        "4.4": {
          "0": 1072,
        },
        "11.6": {
          "1": 4499,
        },
        "7.4": {
          "0": 46,
          "1": 4617,
        },
        "10.2": {
          "1": 7709,
        },
        "13.2": {
          "1": 1123,
          "2": 173,
        },
        "8.8": {
          "1": 7707,
        },
        "6.6": {
          "0": 2432,
          "1": 358,
        },
        "9.2": {
          "1": 8289,
        },
        "15.2": {
          "2": 121,
        },
        "4.8": {
          "0": 451,
        },
        "14.6": {
          "1": 9,
          "2": 282,
        },
        "5.8": {
          "0": 1280,
        },
        "13.8": {
          "1": 300,
          "2": 423,
        },
        "5.4": {
          "0": 919,
        },
        "12.6": {
          "1": 2280,
          "2": 2,
        },
        "14.8": {
          "2": 181,
        },
        "13.6": {
          "1": 528,
          "2": 351,
        },
        "13.4": {
          "1": 756,
          "2": 293,
        },
        "4.6": {
          "0": 336,
        },
        "15.6": {
          "2": 246,
        },
        "14.2": {
          "1": 73,
          "2": 332,
        },
        "5.6": {
          "0": 1123,
        },
        "5.2": {
          "0": 706,
        },
        "15.4": {
          "2": 90,
        },
        "14.4": {
          "1": 27,
          "2": 331,
        },
      },
      KONF: {
        "2": {
          "0": 365,
        },
        "3": {
          "0": 860,
        },
        "4": {
          "0": 1528,
        },
        "5": {
          "0": 2688,
        },
        "6": {
          "0": 4150,
        },
        "7": {
          "0": 1581,
          "1": 3741,
        },
        "8": {
          "1": 6031,
        },
        "9": {
          "1": 5956,
        },
        "10": {
          "1": 5022,
        },
        "11": {
          "1": 3591,
        },
        "12": {
          "1": 2309,
        },
        "13": {
          "1": 1126,
          "2": 40,
        },
        "14": {
          "1": 186,
          "2": 289,
        },
        "15": {
          "2": 197,
        },
        "16": {
          "2": 53,
        },
        "5.8": {
          "0": 3778,
        },
        "9.2": {
          "1": 5720,
        },
        "8.6": {
          "1": 6117,
        },
        "7.6": {
          "1": 5880,
        },
        "5.4": {
          "0": 3162,
        },
        "10.8": {
          "1": 3945,
        },
        "11.6": {
          "1": 2680,
        },
        "10.2": {
          "1": 4721,
        },
        "6.8": {
          "0": 3046,
          "1": 1960,
        },
        "5.6": {
          "0": 3448,
        },
        "12.2": {
          "1": 1938,
        },
        "8.2": {
          "1": 6088,
        },
        "10.6": {
          "1": 4197,
        },
        "6.4": {
          "0": 4471,
          "1": 58,
        },
        "7.8": {
          "1": 6058,
        },
        "4.8": {
          "0": 2522,
        },
        "4.2": {
          "0": 1808,
        },
        "12.4": {
          "1": 1730,
        },
        "1.4": {
          "0": 1320,
        },
        "9.8": {
          "1": 5417,
        },
        "7.4": {
          "0": 63,
          "1": 5652,
        },
        "8.4": {
          "1": 6206,
        },
        "10.4": {
          "1": 4507,
        },
        "9.4": {
          "1": 5758,
        },
        "6.2": {
          "0": 4391,
        },
        "13.2": {
          "1": 923,
          "2": 115,
        },
        "6.6": {
          "0": 4229,
          "1": 620,
        },
        "3.4": {
          "0": 1096,
        },
        "15.4": {
          "2": 154,
        },
        "12.8": {
          "1": 1267,
          "2": 12,
        },
        "15.6": {
          "2": 88,
        },
        "7.2": {
          "0": 478,
          "1": 4933,
        },
        "4.6": {
          "0": 2214,
        },
        "5.2": {
          "0": 2945,
        },
        "13.4": {
          "1": 770,
          "2": 189,
        },
        "8.8": {
          "1": 5953,
        },
        "14.2": {
          "1": 84,
          "2": 350,
        },
        "11.8": {
          "1": 2516,
        },
        "11.4": {
          "1": 2949,
        },
        "9.6": {
          "1": 5525,
        },
        "3.8": {
          "0": 1404,
        },
        "4.4": {
          "0": 2006,
        },
        "1.8": {
          "0": 336,
        },
        "13.8": {
          "1": 364,
          "2": 310,
        },
        "11.2": {
          "1": 3256,
        },
        "3.6": {
          "0": 1271,
        },
        "16.4": {
          "2": 41,
        },
        "2.6": {
          "0": 630,
        },
        "13.6": {
          "1": 488,
          "2": 249,
        },
        "2.2": {
          "0": 462,
        },
        "3.2": {
          "0": 949,
        },
        "2.4": {
          "0": 562,
        },
        "2.8": {
          "0": 763,
        },
        "12.6": {
          "1": 1540,
        },
        "14.4": {
          "1": 47,
          "2": 302,
        },
        "15.2": {
          "2": 182,
        },
        "16.2": {
          "2": 56,
        },
        "14.6": {
          "1": 17,
          "2": 273,
        },
        "1.6": {
          "0": 278,
        },
        "16.6": {
          "2": 149,
        },
        "14.8": {
          "1": 8,
          "2": 223,
        },
        "15.8": {
          "2": 70,
        },
      },
      LEWICA: {
        "9": {
          "1": 3158,
        },
        "10": {
          "1": 10397,
        },
        "11": {
          "1": 13348,
        },
        "12": {
          "1": 8754,
        },
        "13": {
          "1": 2968,
          "2": 86,
        },
        "14": {
          "1": 442,
          "2": 1671,
        },
        "10.8": {
          "1": 13789,
        },
        "12.6": {
          "1": 4958,
          "2": 3,
        },
        "12.8": {
          "1": 3937,
          "2": 18,
        },
        "8.4": {
          "0": 24,
          "1": 2419,
        },
        "13.6": {
          "1": 869,
          "2": 398,
        },
        "11.4": {
          "1": 12481,
        },
        "9.8": {
          "1": 8536,
        },
        "12.4": {
          "1": 6223,
        },
        "9.4": {
          "1": 5769,
        },
        "9.6": {
          "1": 7268,
        },
        "11.6": {
          "1": 11456,
        },
        "10.6": {
          "1": 13254,
        },
        "11.8": {
          "1": 9977,
        },
        "10.2": {
          "1": 11426,
        },
        "9.2": {
          "1": 4225,
        },
        "10.4": {
          "1": 12430,
        },
        "11.2": {
          "1": 13162,
        },
        "8.8": {
          "1": 2289,
        },
        "13.4": {
          "1": 1469,
          "2": 329,
        },
        "12.2": {
          "1": 7587,
        },
        "13.8": {
          "1": 508,
          "2": 441,
        },
        "13.2": {
          "1": 2145,
          "2": 201,
        },
        "8.6": {
          "1": 1585,
        },
      },
    },
    minimumMandatow: {
      KO: 3,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2200,
      TD: 3591,
      LEWICA: 197,
    },
    potencjalneMandaty: {
      KO: 59374,
      TD: 28348,
      LEWICA: 16967,
    },
    ostatnieMandaty: {
      KO: 58656,
      TD: 14454,
      LEWICA: 2796,
    },
    dawcyGlosow: {
      KO: 5599,
      TD: 9872,
      LEWICA: 758,
    },
  },
  Sosnowiec: {
    procentyWOkreguSrednia: {
      PIS: 31.17156786051795,
      KO: 31.275195370112446,
      TD: 9.364650412169237,
      KONF: 8.069056362866322,
      LEWICA: 20.11952999433404,
    },
    odchylenieWOkregu: {
      PIS: 2.787597083246589,
      KO: 1.1283962715457772,
      TD: 2.013774734692939,
      LEWICA: 1.5707844471403938,
      KONF: 0.7682860397965359,
    },
    wykresRozkladu: {
      "64.7%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
      "26.7%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 0,
      },
      "5.3%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 0,
      },
      "2.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.2%": {
        KO: 3,
        LEWICA: 3,
        NIEDEMO: 3,
        TD: 0,
      },
      "0.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "0%": {
        KO: 4,
        LEWICA: 3,
        NIEDEMO: 2,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "70.3%": {
        DEMO: 6,
        NIEDEMO: 3,
      },
      "29.4%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
      "0.2%": {
        DEMO: 7,
        NIEDEMO: 2,
      },
    },
    histogram: {
      PIS: {
        "25": {
          "2": 98,
          "3": 22,
        },
        "26": {
          "2": 183,
          "3": 252,
        },
        "27": {
          "2": 194,
          "3": 910,
        },
        "28": {
          "2": 134,
          "3": 2321,
        },
        "29": {
          "2": 31,
          "3": 4400,
        },
        "30": {
          "3": 6537,
        },
        "31": {
          "3": 7516,
          "4": 1,
        },
        "32": {
          "3": 6855,
          "4": 252,
        },
        "33": {
          "3": 4320,
          "4": 1031,
        },
        "34": {
          "3": 1781,
          "4": 1384,
        },
        "35": {
          "3": 406,
          "4": 997,
        },
        "36": {
          "3": 52,
          "4": 461,
        },
        "37": {
          "3": 1,
          "4": 151,
        },
        "38": {
          "4": 26,
        },
        "39": {
          "4": 4,
        },
        "30.6": {
          "3": 7307,
        },
        "32.6": {
          "3": 5391,
          "4": 733,
        },
        "35.8": {
          "3": 116,
          "4": 548,
        },
        "29.4": {
          "2": 4,
          "3": 5068,
        },
        "28.6": {
          "2": 57,
          "3": 3523,
        },
        "28.2": {
          "2": 75,
          "3": 2746,
        },
        "34.4": {
          "3": 1087,
          "4": 1198,
        },
        "28.8": {
          "2": 26,
          "3": 3883,
        },
        "28.4": {
          "2": 81,
          "3": 3116,
        },
        "31.6": {
          "3": 7458,
          "4": 86,
        },
        "32.2": {
          "3": 6415,
          "4": 370,
        },
        "30.8": {
          "3": 7346,
        },
        "33.8": {
          "3": 2233,
          "4": 1369,
        },
        "31.2": {
          "3": 7470,
          "4": 10,
        },
        "29.2": {
          "2": 10,
          "3": 4777,
        },
        "25.8": {
          "2": 157,
          "3": 174,
        },
        "27.2": {
          "2": 176,
          "3": 1149,
        },
        "33.4": {
          "3": 3129,
          "4": 1238,
        },
        "34.6": {
          "3": 792,
          "4": 1216,
        },
        "29.6": {
          "3": 5613,
        },
        "33.2": {
          "3": 3658,
          "4": 1128,
        },
        "32.4": {
          "3": 6006,
          "4": 571,
        },
        "30.2": {
          "3": 6672,
        },
        "32.8": {
          "3": 4684,
          "4": 863,
        },
        "29.8": {
          "3": 6084,
        },
        "31.8": {
          "3": 6966,
          "4": 159,
        },
        "35.2": {
          "3": 329,
          "4": 915,
        },
        "30.4": {
          "3": 6991,
        },
        "36.6": {
          "3": 6,
          "4": 241,
        },
        "33.6": {
          "3": 2563,
          "4": 1296,
        },
        "35.6": {
          "3": 155,
          "4": 690,
        },
        "27.6": {
          "2": 164,
          "3": 1672,
        },
        "34.2": {
          "3": 1386,
          "4": 1336,
        },
        "27.4": {
          "2": 188,
          "3": 1449,
        },
        "26.8": {
          "2": 179,
          "3": 756,
        },
        "35.4": {
          "3": 209,
          "4": 762,
        },
        "34.8": {
          "3": 585,
          "4": 1039,
        },
        "31.4": {
          "3": 7281,
          "4": 55,
        },
        "25.4": {
          "2": 156,
          "3": 85,
        },
        "36.2": {
          "3": 27,
          "4": 395,
        },
        "27.8": {
          "2": 138,
          "3": 2012,
        },
        "26.4": {
          "2": 199,
          "3": 436,
        },
        "24.8": {
          "2": 88,
          "3": 13,
        },
        "26.2": {
          "2": 178,
          "3": 312,
        },
        "26.6": {
          "2": 196,
          "3": 595,
        },
        "38.4": {
          "4": 11,
        },
        "24.4": {
          "2": 58,
          "3": 7,
        },
        "36.8": {
          "3": 2,
          "4": 181,
        },
        "25.6": {
          "2": 190,
          "3": 115,
        },
        "24.6": {
          "2": 92,
          "3": 7,
        },
        "37.8": {
          "4": 37,
        },
        "25.2": {
          "2": 115,
          "3": 39,
        },
        "38.2": {
          "4": 28,
        },
        "36.4": {
          "3": 20,
          "4": 304,
        },
        "37.6": {
          "4": 69,
        },
        "37.4": {
          "4": 84,
        },
        "37.2": {
          "4": 85,
        },
        "24.2": {
          "2": 189,
          "3": 3,
        },
        "38.6": {
          "4": 11,
        },
        "39.6": {
          "4": 6,
        },
        "38.8": {
          "4": 8,
        },
        "39.2": {
          "4": 3,
        },
        "39.4": {
          "4": 1,
        },
      },
      KO: {
        "29": {
          "2": 5,
          "3": 3025,
        },
        "30": {
          "3": 7660,
        },
        "31": {
          "3": 11015,
          "4": 3,
        },
        "32": {
          "3": 9228,
          "4": 295,
        },
        "33": {
          "3": 4365,
          "4": 861,
        },
        "34": {
          "3": 1285,
          "4": 731,
        },
        "29.4": {
          "2": 3,
          "3": 4690,
        },
        "31.4": {
          "3": 11154,
          "4": 53,
        },
        "30.2": {
          "3": 8476,
        },
        "30.4": {
          "3": 9381,
        },
        "32.6": {
          "3": 6214,
          "4": 681,
        },
        "29.2": {
          "2": 3,
          "3": 3791,
        },
        "30.8": {
          "3": 10696,
        },
        "33.4": {
          "3": 2891,
          "4": 809,
        },
        "31.8": {
          "3": 10264,
          "4": 195,
        },
        "32.2": {
          "3": 8419,
          "4": 422,
        },
        "28.4": {
          "2": 109,
          "3": 4092,
        },
        "29.8": {
          "3": 6643,
        },
        "30.6": {
          "3": 10343,
        },
        "31.2": {
          "3": 11212,
          "4": 22,
        },
        "33.6": {
          "3": 2238,
          "4": 838,
        },
        "32.8": {
          "3": 5335,
          "4": 747,
        },
        "33.2": {
          "3": 3564,
          "4": 896,
        },
        "31.6": {
          "3": 10618,
          "4": 93,
        },
        "32.4": {
          "3": 7430,
          "4": 557,
        },
        "28.8": {
          "2": 11,
          "3": 2304,
        },
        "34.4": {
          "3": 655,
          "4": 532,
        },
        "33.8": {
          "3": 1723,
          "4": 801,
        },
        "28.6": {
          "2": 12,
          "3": 1840,
        },
        "29.6": {
          "2": 1,
          "3": 5775,
        },
        "34.6": {
          "3": 1446,
          "4": 2019,
        },
        "34.2": {
          "3": 924,
          "4": 605,
        },
      },
      TD: {
        "5": {
          "0": 567,
        },
        "6": {
          "0": 1801,
        },
        "7": {
          "0": 3801,
        },
        "8": {
          "0": 5973,
          "1": 411,
        },
        "9": {
          "0": 855,
          "1": 7473,
        },
        "10": {
          "1": 8326,
        },
        "11": {
          "1": 5848,
        },
        "12": {
          "1": 3076,
        },
        "13": {
          "1": 1205,
        },
        "14": {
          "1": 308,
        },
        "15": {
          "1": 61,
        },
        "10.8": {
          "1": 6562,
        },
        "8.8": {
          "0": 1544,
          "1": 6483,
        },
        "11.4": {
          "1": 4818,
        },
        "11.8": {
          "1": 3651,
        },
        "10.6": {
          "1": 6898,
        },
        "7.6": {
          "0": 5456,
        },
        "12.2": {
          "1": 2639,
        },
        "9.4": {
          "0": 197,
          "1": 8449,
        },
        "6.8": {
          "0": 3220,
        },
        "9.6": {
          "0": 62,
          "1": 8371,
        },
        "12.4": {
          "1": 2164,
        },
        "11.6": {
          "1": 4100,
        },
        "5.2": {
          "0": 745,
        },
        "10.4": {
          "1": 7461,
        },
        "9.2": {
          "0": 442,
          "1": 8077,
        },
        "12.6": {
          "1": 1894,
        },
        "11.2": {
          "1": 5335,
        },
        "5.8": {
          "0": 1439,
        },
        "12.8": {
          "1": 1466,
        },
        "8.4": {
          "0": 3911,
          "1": 3464,
        },
        "9.8": {
          "0": 7,
          "1": 8444,
        },
        "10.2": {
          "1": 7831,
        },
        "7.4": {
          "0": 4827,
        },
        "8.6": {
          "0": 2633,
          "1": 5230,
        },
        "8.2": {
          "0": 5321,
          "1": 1621,
        },
        "6.2": {
          "0": 2047,
        },
        "7.2": {
          "0": 4489,
        },
        "6.4": {
          "0": 2460,
        },
        "7.8": {
          "0": 5896,
          "1": 26,
        },
        "5.4": {
          "0": 933,
        },
        "5.6": {
          "0": 1221,
        },
        "13.2": {
          "1": 962,
        },
        "6.6": {
          "0": 2849,
        },
        "13.4": {
          "1": 766,
        },
        "4.6": {
          "0": 366,
        },
        "13.6": {
          "1": 586,
        },
        "14.2": {
          "1": 269,
        },
        "4.4": {
          "0": 1226,
        },
        "15.2": {
          "1": 43,
        },
        "14.4": {
          "1": 169,
        },
        "14.6": {
          "1": 137,
        },
        "15.4": {
          "1": 81,
          "2": 2,
        },
        "4.8": {
          "0": 493,
        },
        "13.8": {
          "1": 428,
        },
        "14.8": {
          "1": 84,
        },
      },
      KONF: {
        "7": {
          "0": 8042,
        },
        "8": {
          "0": 19715,
          "1": 534,
        },
        "9": {
          "0": 3948,
          "1": 5969,
        },
        "10": {
          "0": 1,
          "1": 1062,
        },
        "9.2": {
          "0": 2010,
          "1": 5130,
        },
        "8.6": {
          "0": 10272,
          "1": 5934,
        },
        "8.8": {
          "0": 6593,
          "1": 6370,
        },
        "8.2": {
          "0": 18009,
          "1": 2163,
        },
        "7.6": {
          "0": 17353,
        },
        "7.2": {
          "0": 11176,
        },
        "6.2": {
          "0": 2055,
        },
        "7.8": {
          "0": 19533,
          "1": 36,
        },
        "6.8": {
          "0": 5381,
        },
        "9.8": {
          "0": 21,
          "1": 1826,
        },
        "7.4": {
          "0": 14325,
        },
        "6.6": {
          "0": 3301,
        },
        "9.4": {
          "0": 791,
          "1": 4056,
        },
        "8.4": {
          "0": 14224,
          "1": 4210,
        },
        "6.4": {
          "0": 1927,
        },
        "9.6": {
          "0": 175,
          "1": 2791,
        },
        "10.4": {
          "1": 514,
        },
        "10.2": {
          "1": 553,
        },
      },
      LEWICA: {
        "17": {
          "1": 513,
          "2": 587,
        },
        "18": {
          "1": 335,
          "2": 3528,
        },
        "19": {
          "1": 84,
          "2": 8049,
        },
        "20": {
          "2": 10919,
        },
        "21": {
          "2": 9114,
        },
        "22": {
          "2": 4698,
        },
        "23": {
          "2": 1602,
        },
        "24": {
          "2": 346,
          "3": 31,
        },
        "19.4": {
          "1": 29,
          "2": 9740,
        },
        "16.6": {
          "1": 501,
          "2": 126,
        },
        "20.8": {
          "2": 9721,
        },
        "21.2": {
          "2": 8265,
        },
        "20.4": {
          "2": 10867,
        },
        "18.8": {
          "1": 87,
          "2": 7239,
        },
        "20.2": {
          "2": 10924,
        },
        "22.4": {
          "2": 3196,
        },
        "21.8": {
          "2": 5435,
        },
        "19.6": {
          "1": 6,
          "2": 10215,
        },
        "21.4": {
          "2": 7311,
        },
        "20.6": {
          "2": 10216,
        },
        "19.8": {
          "2": 10718,
        },
        "19.2": {
          "1": 34,
          "2": 8985,
        },
        "18.2": {
          "1": 253,
          "2": 4382,
        },
        "18.4": {
          "1": 230,
          "2": 5275,
        },
        "17.6": {
          "1": 454,
          "2": 2026,
        },
        "23.4": {
          "2": 888,
          "3": 12,
        },
        "21.6": {
          "2": 6613,
        },
        "22.6": {
          "2": 2558,
        },
        "18.6": {
          "1": 156,
          "2": 6154,
        },
        "17.8": {
          "1": 350,
          "2": 2718,
        },
        "17.4": {
          "1": 481,
          "2": 1400,
        },
        "22.2": {
          "2": 4026,
        },
        "16.2": {
          "1": 769,
          "2": 13,
        },
        "22.8": {
          "2": 2044,
        },
        "17.2": {
          "1": 555,
          "2": 869,
        },
        "24.2": {
          "2": 201,
          "3": 49,
        },
        "23.8": {
          "2": 459,
          "3": 34,
        },
        "16.4": {
          "1": 372,
          "2": 54,
        },
        "23.6": {
          "2": 663,
          "3": 24,
        },
        "16.8": {
          "1": 489,
          "2": 283,
        },
        "23.2": {
          "2": 1198,
          "3": 1,
        },
        "24.6": {
          "2": 86,
          "3": 27,
        },
        "24.8": {
          "2": 125,
          "3": 103,
        },
        "24.4": {
          "2": 150,
          "3": 35,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 2242,
      TD: 7088,
      LEWICA: 5340,
    },
    potencjalneMandaty: {
      KO: 27541,
      TD: 46330,
      LEWICA: 9780,
    },
    ostatnieMandaty: {
      KO: 21971,
      TD: 47073,
      LEWICA: 44079,
    },
    dawcyGlosow: {
      KO: 6777,
      TD: 17200,
      LEWICA: 14604,
    },
  },
  Kielce: {
    procentyWOkreguSrednia: {
      PIS: 50.56394344332657,
      KO: 19.742169388328428,
      TD: 10.592953110236298,
      KONF: 10.454217140181074,
      LEWICA: 8.646716917927625,
    },
    odchylenieWOkregu: {
      PIS: 5.564403662744632,
      KO: 1.4942817841784481,
      TD: 2.013774734692939,
      LEWICA: 1.758723018211587,
      KONF: 0.8522607257210798,
    },
    wykresRozkladu: {
      "36%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 2,
      },
      "34.2%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 11,
        TD: 1,
      },
      "9.4%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 10,
        TD: 1,
      },
      "6.4%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 10,
        TD: 1,
      },
      "4.8%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 9,
        TD: 2,
      },
      "3.4%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 9,
        TD: 2,
      },
      "1.2%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 11,
        TD: 2,
      },
      "0.9%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 9,
        TD: 1,
      },
      "0.7%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 12,
        TD: 1,
      },
      "0.4%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 10,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "52.3%": {
        DEMO: 6,
        NIEDEMO: 10,
      },
      "36.8%": {
        DEMO: 5,
        NIEDEMO: 11,
      },
      "9.4%": {
        DEMO: 7,
        NIEDEMO: 9,
      },
    },
    histogram: {
      PIS: {
        "37": {
          "6": 2,
          "7": 1,
        },
        "38": {
          "6": 12,
          "7": 5,
        },
        "39": {
          "6": 7,
          "7": 20,
        },
        "40": {
          "6": 6,
          "7": 58,
        },
        "41": {
          "7": 111,
        },
        "42": {
          "7": 206,
          "8": 17,
        },
        "43": {
          "7": 259,
          "8": 87,
        },
        "44": {
          "7": 235,
          "8": 430,
        },
        "45": {
          "7": 81,
          "8": 1078,
        },
        "46": {
          "7": 11,
          "8": 1785,
          "9": 18,
        },
        "47": {
          "8": 2286,
          "9": 249,
        },
        "48": {
          "8": 2148,
          "9": 1274,
        },
        "49": {
          "8": 1258,
          "9": 3088,
        },
        "50": {
          "8": 424,
          "9": 4496,
        },
        "51": {
          "8": 69,
          "9": 4775,
          "10": 134,
        },
        "52": {
          "8": 5,
          "9": 3778,
          "10": 730,
        },
        "53": {
          "9": 2269,
          "10": 1571,
        },
        "54": {
          "9": 1010,
          "10": 1818,
        },
        "55": {
          "9": 301,
          "10": 1684,
        },
        "56": {
          "9": 50,
          "10": 1070,
          "11": 9,
        },
        "57": {
          "9": 1,
          "10": 511,
          "11": 32,
        },
        "58": {
          "10": 216,
          "11": 46,
        },
        "59": {
          "10": 53,
          "11": 51,
        },
        "60": {
          "10": 16,
          "11": 20,
        },
        "61": {
          "10": 2,
          "11": 14,
        },
        "62": {
          "11": 1,
        },
        "63": {
          "11": 1,
        },
        "49.8": {
          "8": 607,
          "9": 4367,
        },
        "49.4": {
          "8": 882,
          "9": 3712,
        },
        "46.8": {
          "8": 2287,
          "9": 171,
        },
        "50.8": {
          "8": 139,
          "9": 4959,
          "10": 67,
        },
        "50.6": {
          "8": 182,
          "9": 4739,
          "10": 31,
        },
        "53.6": {
          "9": 1485,
          "10": 1855,
        },
        "49.6": {
          "8": 730,
          "9": 4009,
        },
        "56.8": {
          "9": 7,
          "10": 620,
          "11": 29,
        },
        "44.6": {
          "7": 132,
          "8": 773,
        },
        "48.6": {
          "8": 1698,
          "9": 2336,
        },
        "53.4": {
          "9": 1738,
          "10": 1692,
        },
        "58.6": {
          "10": 92,
          "11": 56,
        },
        "45.2": {
          "7": 68,
          "8": 1193,
        },
        "47.6": {
          "8": 2408,
          "9": 768,
        },
        "56.2": {
          "9": 32,
          "10": 948,
          "11": 17,
        },
        "46.2": {
          "7": 6,
          "8": 1889,
          "9": 36,
        },
        "51.8": {
          "8": 8,
          "9": 4109,
          "10": 598,
        },
        "41.2": {
          "7": 108,
        },
        "51.2": {
          "8": 56,
          "9": 4713,
          "10": 234,
        },
        "52.6": {
          "9": 2925,
          "10": 1232,
        },
        "52.2": {
          "8": 5,
          "9": 3654,
          "10": 937,
        },
        "48.4": {
          "8": 1886,
          "9": 2027,
        },
        "43.2": {
          "7": 287,
          "8": 144,
        },
        "43.6": {
          "7": 279,
          "8": 264,
        },
        "45.4": {
          "7": 44,
          "8": 1345,
          "9": 2,
        },
        "53.2": {
          "9": 1883,
          "10": 1688,
        },
        "48.8": {
          "8": 1454,
          "9": 2742,
        },
        "52.4": {
          "9": 3212,
          "10": 1068,
        },
        "44.4": {
          "7": 191,
          "8": 682,
        },
        "54.6": {
          "9": 482,
          "10": 1802,
        },
        "57.2": {
          "9": 2,
          "10": 456,
          "11": 41,
        },
        "47.4": {
          "8": 2426,
          "9": 532,
        },
        "52.8": {
          "9": 2634,
          "10": 1360,
        },
        "55.4": {
          "9": 146,
          "10": 1381,
          "11": 1,
        },
        "50.4": {
          "8": 248,
          "9": 4685,
          "10": 10,
        },
        "49.2": {
          "8": 1100,
          "9": 3391,
        },
        "47.2": {
          "8": 2376,
          "9": 367,
        },
        "45.8": {
          "7": 18,
          "8": 1632,
          "9": 9,
        },
        "53.8": {
          "9": 1178,
          "10": 1820,
        },
        "48.2": {
          "8": 2097,
          "9": 1688,
        },
        "46.6": {
          "8": 2160,
          "9": 103,
        },
        "44.8": {
          "7": 88,
          "8": 931,
        },
        "57.4": {
          "10": 393,
          "11": 36,
        },
        "51.6": {
          "8": 31,
          "9": 4336,
          "10": 492,
        },
        "50.2": {
          "8": 319,
          "9": 4601,
          "10": 2,
        },
        "43.8": {
          "7": 271,
          "8": 368,
        },
        "51.4": {
          "8": 33,
          "9": 4555,
          "10": 332,
        },
        "46.4": {
          "7": 3,
          "8": 2021,
          "9": 58,
        },
        "47.8": {
          "8": 2340,
          "9": 996,
        },
        "42.8": {
          "7": 238,
          "8": 79,
        },
        "56.4": {
          "9": 16,
          "10": 851,
          "11": 12,
        },
        "54.2": {
          "9": 733,
          "10": 1896,
        },
        "55.8": {
          "9": 61,
          "10": 1214,
          "11": 3,
        },
        "59.2": {
          "10": 48,
          "11": 37,
        },
        "55.6": {
          "9": 92,
          "10": 1225,
          "11": 3,
        },
        "54.8": {
          "9": 383,
          "10": 1633,
        },
        "45.6": {
          "7": 30,
          "8": 1510,
          "9": 4,
        },
        "56.6": {
          "9": 9,
          "10": 727,
          "11": 16,
        },
        "40.8": {
          "6": 1,
          "7": 94,
          "8": 1,
        },
        "54.4": {
          "9": 597,
          "10": 1750,
        },
        "44.2": {
          "7": 190,
          "8": 549,
        },
        "42.4": {
          "7": 221,
          "8": 33,
        },
        "55.2": {
          "9": 183,
          "10": 1536,
        },
        "42.6": {
          "7": 225,
          "8": 34,
        },
        "57.8": {
          "10": 226,
          "11": 46,
        },
        "43.4": {
          "7": 278,
          "8": 207,
        },
        "58.4": {
          "10": 125,
          "11": 39,
        },
        "57.6": {
          "10": 287,
          "11": 40,
        },
        "41.4": {
          "7": 128,
          "8": 2,
        },
        "40.4": {
          "7": 70,
        },
        "41.8": {
          "7": 172,
          "8": 5,
        },
        "58.2": {
          "10": 166,
          "11": 51,
        },
        "40.6": {
          "7": 70,
        },
        "41.6": {
          "7": 160,
          "8": 2,
        },
        "38.2": {
          "6": 3,
          "7": 6,
        },
        "59.4": {
          "10": 39,
          "11": 29,
        },
        "42.2": {
          "7": 214,
          "8": 20,
        },
        "40.2": {
          "7": 44,
        },
        "59.8": {
          "10": 16,
          "11": 19,
        },
        "36.6": {
          "6": 21,
        },
        "59.6": {
          "10": 42,
          "11": 34,
        },
        "39.6": {
          "6": 4,
          "7": 45,
        },
        "58.8": {
          "10": 79,
          "11": 34,
        },
        "39.8": {
          "6": 4,
          "7": 26,
        },
        "39.4": {
          "6": 7,
          "7": 22,
        },
        "61.4": {
          "11": 7,
        },
        "60.8": {
          "10": 3,
          "11": 11,
        },
        "61.8": {
          "11": 3,
        },
        "38.4": {
          "6": 7,
          "7": 6,
        },
        "39.2": {
          "6": 4,
          "7": 22,
        },
        "38.8": {
          "6": 5,
          "7": 10,
        },
        "37.2": {
          "6": 11,
          "7": 1,
        },
        "61.2": {
          "11": 5,
          "12": 1,
        },
        "36.8": {
          "6": 4,
          "7": 1,
        },
        "37.8": {
          "6": 6,
          "7": 1,
        },
        "60.2": {
          "10": 4,
          "11": 20,
        },
        "38.6": {
          "6": 5,
          "7": 8,
        },
        "60.6": {
          "10": 4,
          "11": 16,
        },
        "60.4": {
          "10": 4,
          "11": 14,
        },
        "61.6": {
          "11": 3,
          "12": 1,
        },
        "37.4": {
          "6": 6,
          "7": 1,
        },
        "37.6": {
          "6": 3,
          "7": 4,
        },
        "63.4": {
          "12": 1,
        },
        "63.2": {
          "11": 1,
        },
        "62.6": {
          "11": 1,
        },
        "62.2": {
          "11": 1,
        },
        "62.4": {
          "11": 1,
          "12": 1,
        },
      },
      KO: {
        "16": {
          "2": 1763,
          "3": 535,
        },
        "17": {
          "2": 36,
          "3": 2484,
        },
        "18": {
          "3": 5716,
        },
        "19": {
          "3": 8752,
        },
        "20": {
          "3": 8989,
        },
        "21": {
          "3": 5617,
          "4": 1191,
        },
        "22": {
          "3": 1018,
          "4": 2839,
        },
        "23": {
          "3": 14,
          "4": 1647,
        },
        "24": {
          "4": 621,
        },
        "18.6": {
          "3": 7820,
        },
        "20.2": {
          "3": 8753,
          "4": 20,
        },
        "19.4": {
          "3": 9198,
        },
        "19.2": {
          "3": 9093,
        },
        "17.8": {
          "3": 4961,
        },
        "16.6": {
          "2": 161,
          "3": 1384,
        },
        "18.4": {
          "3": 7279,
        },
        "16.8": {
          "2": 77,
          "3": 1861,
        },
        "22.4": {
          "3": 291,
          "4": 2552,
        },
        "21.6": {
          "3": 2392,
          "4": 2507,
        },
        "16.2": {
          "2": 318,
          "3": 608,
        },
        "20.4": {
          "3": 8252,
          "4": 127,
        },
        "24.2": {
          "4": 2039,
          "5": 50,
        },
        "18.8": {
          "3": 8361,
        },
        "21.8": {
          "3": 1519,
          "4": 2829,
        },
        "23.2": {
          "3": 7,
          "4": 1379,
        },
        "22.6": {
          "3": 146,
          "4": 2304,
        },
        "19.6": {
          "3": 9349,
        },
        "20.6": {
          "3": 7554,
          "4": 351,
        },
        "19.8": {
          "3": 9180,
        },
        "17.2": {
          "2": 12,
          "3": 2996,
        },
        "18.2": {
          "3": 6245,
        },
        "20.8": {
          "3": 6793,
          "4": 723,
        },
        "21.2": {
          "3": 4547,
          "4": 1666,
        },
        "21.4": {
          "3": 3423,
          "4": 2167,
        },
        "22.8": {
          "3": 58,
          "4": 2036,
        },
        "17.6": {
          "3": 4413,
        },
        "23.8": {
          "4": 742,
        },
        "17.4": {
          "2": 3,
          "3": 3591,
        },
        "22.2": {
          "3": 510,
          "4": 2801,
        },
        "16.4": {
          "2": 254,
          "3": 1018,
        },
        "23.6": {
          "4": 907,
        },
        "23.4": {
          "4": 1151,
        },
      },
      TD: {
        "6": {
          "1": 486,
        },
        "7": {
          "1": 1406,
        },
        "8": {
          "1": 3412,
        },
        "9": {
          "1": 5918,
        },
        "10": {
          "1": 7935,
          "2": 3,
        },
        "11": {
          "1": 1545,
          "2": 6711,
        },
        "12": {
          "2": 6432,
        },
        "13": {
          "2": 3778,
        },
        "14": {
          "2": 1676,
        },
        "15": {
          "2": 588,
        },
        "16": {
          "2": 121,
          "3": 59,
        },
        "11.4": {
          "1": 98,
          "2": 7556,
        },
        "11.2": {
          "1": 492,
          "2": 7684,
        },
        "12.2": {
          "2": 5746,
        },
        "13.2": {
          "2": 3357,
        },
        "11.6": {
          "1": 10,
          "2": 7086,
        },
        "11.8": {
          "2": 6834,
        },
        "10.6": {
          "1": 5507,
          "2": 2840,
        },
        "9.4": {
          "1": 6815,
        },
        "13.4": {
          "2": 2853,
        },
        "10.8": {
          "1": 3228,
          "2": 5100,
        },
        "5.8": {
          "0": 2,
          "1": 406,
        },
        "14.4": {
          "2": 1118,
        },
        "15.4": {
          "2": 367,
          "3": 9,
        },
        "12.6": {
          "2": 4795,
        },
        "14.6": {
          "2": 986,
        },
        "9.2": {
          "1": 6403,
        },
        "10.2": {
          "1": 8053,
          "2": 214,
        },
        "10.4": {
          "1": 7047,
          "2": 1025,
        },
        "7.2": {
          "1": 1759,
        },
        "9.8": {
          "1": 7571,
        },
        "9.6": {
          "1": 7218,
        },
        "7.8": {
          "1": 2868,
        },
        "13.8": {
          "2": 2002,
        },
        "14.2": {
          "2": 1465,
        },
        "8.6": {
          "1": 4805,
        },
        "12.4": {
          "2": 5300,
        },
        "12.8": {
          "2": 4228,
        },
        "5.6": {
          "0": 684,
          "1": 393,
        },
        "8.2": {
          "1": 3777,
        },
        "7.6": {
          "1": 2450,
        },
        "8.4": {
          "1": 4219,
        },
        "8.8": {
          "1": 5335,
        },
        "15.2": {
          "2": 452,
          "3": 4,
        },
        "15.8": {
          "2": 168,
          "3": 41,
        },
        "13.6": {
          "2": 2453,
        },
        "7.4": {
          "1": 2136,
        },
        "6.8": {
          "1": 1193,
        },
        "14.8": {
          "2": 726,
        },
        "16.4": {
          "2": 26,
          "3": 65,
        },
        "6.4": {
          "1": 768,
        },
        "16.2": {
          "2": 57,
          "3": 63,
        },
        "6.2": {
          "1": 571,
        },
        "15.6": {
          "2": 281,
          "3": 28,
        },
        "6.6": {
          "1": 922,
        },
        "16.6": {
          "2": 18,
          "3": 253,
        },
      },
      KONF: {
        "9": {
          "1": 5576,
        },
        "10": {
          "1": 14591,
          "2": 6,
        },
        "11": {
          "1": 3029,
          "2": 10185,
        },
        "12": {
          "2": 5006,
        },
        "13": {
          "2": 2739,
          "3": 2,
        },
        "10.4": {
          "1": 14147,
          "2": 1656,
        },
        "10.6": {
          "1": 10869,
          "2": 4490,
        },
        "9.2": {
          "1": 7545,
        },
        "11.4": {
          "1": 193,
          "2": 9532,
        },
        "11.2": {
          "1": 931,
          "2": 10723,
        },
        "12.4": {
          "2": 2688,
        },
        "8.6": {
          "1": 2635,
        },
        "8.8": {
          "1": 3992,
        },
        "8.4": {
          "1": 3927,
        },
        "12.2": {
          "2": 3691,
        },
        "10.8": {
          "1": 6710,
          "2": 7849,
        },
        "9.4": {
          "1": 9407,
        },
        "9.8": {
          "1": 13181,
        },
        "11.6": {
          "1": 8,
          "2": 8103,
        },
        "10.2": {
          "1": 15233,
          "2": 253,
        },
        "11.8": {
          "2": 6395,
        },
        "12.6": {
          "2": 2013,
        },
        "9.6": {
          "1": 11385,
        },
        "12.8": {
          "2": 1310,
        },
      },
      LEWICA: {
        "5": {
          "0": 892,
          "1": 3,
        },
        "6": {
          "1": 2786,
        },
        "7": {
          "1": 5830,
        },
        "8": {
          "1": 8782,
        },
        "9": {
          "1": 9190,
        },
        "10": {
          "1": 6720,
          "2": 2,
        },
        "11": {
          "1": 780,
          "2": 2902,
        },
        "12": {
          "2": 1376,
        },
        "13": {
          "2": 380,
        },
        "14": {
          "2": 254,
          "3": 2,
        },
        "6.6": {
          "1": 4435,
        },
        "8.2": {
          "1": 9163,
        },
        "11.4": {
          "1": 45,
          "2": 2488,
        },
        "9.8": {
          "1": 7462,
        },
        "8.6": {
          "1": 9520,
        },
        "8.8": {
          "1": 9272,
        },
        "9.6": {
          "1": 7982,
        },
        "11.2": {
          "1": 200,
          "2": 2832,
        },
        "7.2": {
          "1": 6522,
        },
        "9.2": {
          "1": 9013,
        },
        "7.4": {
          "1": 7040,
        },
        "6.2": {
          "1": 3310,
        },
        "8.4": {
          "1": 9156,
        },
        "7.6": {
          "1": 7837,
        },
        "10.8": {
          "1": 1749,
          "2": 2400,
        },
        "10.2": {
          "1": 6056,
          "2": 164,
        },
        "9.4": {
          "1": 8399,
        },
        "6.8": {
          "1": 5286,
        },
        "10.4": {
          "1": 4785,
          "2": 642,
        },
        "7.8": {
          "1": 8390,
        },
        "5.6": {
          "0": 148,
          "1": 1665,
        },
        "5.8": {
          "0": 9,
          "1": 2288,
        },
        "12.8": {
          "2": 481,
        },
        "10.6": {
          "1": 3299,
          "2": 1571,
        },
        "4.2": {
          "0": 1072,
        },
        "6.4": {
          "1": 3960,
        },
        "5.4": {
          "0": 672,
          "1": 883,
        },
        "11.6": {
          "1": 4,
          "2": 2069,
        },
        "5.2": {
          "0": 1050,
          "1": 131,
        },
        "11.8": {
          "2": 1694,
        },
        "4.8": {
          "0": 703,
        },
        "13.6": {
          "2": 153,
        },
        "12.4": {
          "2": 794,
        },
        "13.4": {
          "2": 219,
        },
        "12.2": {
          "2": 1087,
        },
        "13.2": {
          "2": 292,
        },
        "4.4": {
          "0": 397,
        },
        "4.6": {
          "0": 531,
        },
        "13.8": {
          "2": 112,
        },
        "12.6": {
          "2": 669,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2293,
      TD: 4085,
      LEWICA: 2477,
    },
    potencjalneMandaty: {
      KO: 37699,
      TD: 46399,
      LEWICA: 35665,
    },
    ostatnieMandaty: {
      KO: 24921,
      TD: 24885,
      LEWICA: 12549,
    },
    dawcyGlosow: {
      KO: 5919,
      TD: 9697,
      LEWICA: 6257,
    },
  },
  Elbląg: {
    procentyWOkreguSrednia: {
      PIS: 35.15220458953322,
      KO: 31.805673034337417,
      TD: 14.07718330429372,
      KONF: 8.273682562536385,
      LEWICA: 10.691256509299262,
    },
    odchylenieWOkregu: {
      PIS: 2.7335782017545136,
      KO: 3.2809961527132407,
      TD: 2.013774734692939,
      LEWICA: 1.3052581342787142,
      KONF: 1.852616182929728,
    },
    wykresRozkladu: {
      "68.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "14.3%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "13.6%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "1.4%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 1,
      },
      "0.9%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 0,
      },
      "0.4%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "0.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "70.6%": {
        DEMO: 5,
        NIEDEMO: 3,
      },
      "28.8%": {
        DEMO: 4,
        NIEDEMO: 4,
      },
      "0.5%": {
        DEMO: 3,
        NIEDEMO: 5,
      },
    },
    histogram: {
      PIS: {
        "29": {
          "2": 92,
          "3": 103,
        },
        "30": {
          "2": 136,
          "3": 504,
        },
        "31": {
          "2": 127,
          "3": 1363,
        },
        "32": {
          "2": 60,
          "3": 2728,
        },
        "33": {
          "3": 4516,
        },
        "34": {
          "3": 5893,
          "4": 6,
        },
        "35": {
          "3": 6540,
          "4": 94,
        },
        "36": {
          "3": 5954,
          "4": 348,
        },
        "37": {
          "3": 4077,
          "4": 770,
        },
        "38": {
          "3": 2129,
          "4": 1040,
        },
        "39": {
          "3": 712,
          "4": 1117,
        },
        "40": {
          "3": 152,
          "4": 734,
        },
        "41": {
          "3": 17,
          "4": 351,
        },
        "42": {
          "4": 117,
        },
        "43": {
          "4": 33,
        },
        "36.4": {
          "3": 5388,
          "4": 507,
        },
        "33.4": {
          "3": 5170,
        },
        "35.2": {
          "3": 6581,
          "4": 186,
        },
        "34.4": {
          "3": 6516,
          "4": 31,
        },
        "36.8": {
          "3": 4745,
          "4": 638,
        },
        "37.2": {
          "3": 3851,
          "4": 725,
        },
        "32.6": {
          "2": 14,
          "3": 3821,
        },
        "36.2": {
          "3": 5567,
          "4": 463,
        },
        "31.8": {
          "2": 66,
          "3": 2454,
        },
        "34.2": {
          "3": 6199,
          "4": 19,
        },
        "37.4": {
          "3": 3304,
          "4": 868,
        },
        "34.8": {
          "3": 6565,
          "4": 77,
        },
        "33.8": {
          "3": 5739,
          "4": 2,
        },
        "31.4": {
          "2": 110,
          "3": 1763,
        },
        "32.2": {
          "2": 35,
          "3": 3064,
        },
        "35.6": {
          "3": 6376,
          "4": 256,
        },
        "33.2": {
          "3": 4805,
        },
        "32.8": {
          "2": 4,
          "3": 4141,
        },
        "35.4": {
          "3": 6489,
          "4": 210,
        },
        "39.2": {
          "3": 586,
          "4": 993,
        },
        "39.6": {
          "3": 296,
          "4": 952,
        },
        "37.8": {
          "3": 2560,
          "4": 1014,
        },
        "41.4": {
          "3": 8,
          "4": 242,
        },
        "38.6": {
          "3": 1209,
          "4": 1134,
        },
        "40.4": {
          "3": 80,
          "4": 544,
        },
        "38.2": {
          "3": 1815,
          "4": 1113,
        },
        "31.6": {
          "2": 102,
          "3": 2110,
        },
        "36.6": {
          "3": 5023,
          "4": 552,
        },
        "38.8": {
          "3": 956,
          "4": 1108,
        },
        "38.4": {
          "3": 1529,
          "4": 1089,
        },
        "31.2": {
          "2": 120,
          "3": 1501,
        },
        "33.6": {
          "3": 5497,
        },
        "29.2": {
          "2": 97,
          "3": 176,
        },
        "30.2": {
          "2": 128,
          "3": 609,
        },
        "30.6": {
          "2": 147,
          "3": 921,
        },
        "34.6": {
          "3": 6325,
          "4": 57,
        },
        "35.8": {
          "3": 6293,
          "4": 300,
        },
        "37.6": {
          "3": 2921,
          "4": 937,
        },
        "32.4": {
          "2": 22,
          "3": 3350,
        },
        "39.8": {
          "3": 214,
          "4": 758,
        },
        "39.4": {
          "3": 423,
          "4": 926,
        },
        "40.6": {
          "3": 36,
          "4": 485,
        },
        "30.8": {
          "2": 132,
          "3": 1037,
        },
        "41.2": {
          "3": 9,
          "4": 280,
        },
        "28.4": {
          "2": 300,
          "3": 109,
        },
        "40.2": {
          "3": 120,
          "4": 643,
        },
        "43.2": {
          "4": 28,
        },
        "42.8": {
          "4": 38,
          "5": 1,
        },
        "29.6": {
          "2": 130,
          "3": 301,
        },
        "29.8": {
          "2": 126,
          "3": 389,
        },
        "30.4": {
          "2": 145,
          "3": 733,
        },
        "28.8": {
          "2": 89,
          "3": 86,
        },
        "43.4": {
          "4": 96,
          "5": 7,
        },
        "42.6": {
          "4": 60,
        },
        "41.6": {
          "3": 5,
          "4": 167,
        },
        "40.8": {
          "3": 31,
          "4": 414,
        },
        "42.2": {
          "3": 1,
          "4": 105,
        },
        "41.8": {
          "3": 2,
          "4": 142,
        },
        "28.6": {
          "2": 71,
          "3": 64,
        },
        "29.4": {
          "2": 116,
          "3": 203,
        },
        "42.4": {
          "3": 2,
          "4": 98,
        },
      },
      KO: {
        "24": {
          "2": 81,
        },
        "25": {
          "2": 223,
        },
        "26": {
          "2": 545,
          "3": 1,
        },
        "27": {
          "2": 1083,
          "3": 63,
        },
        "28": {
          "2": 1488,
          "3": 563,
        },
        "29": {
          "2": 1148,
          "3": 2229,
        },
        "30": {
          "2": 689,
          "3": 4260,
        },
        "31": {
          "2": 316,
          "3": 5502,
        },
        "32": {
          "2": 85,
          "3": 6065,
        },
        "33": {
          "3": 5499,
        },
        "34": {
          "3": 4424,
          "4": 4,
        },
        "35": {
          "3": 2751,
          "4": 76,
        },
        "36": {
          "3": 1483,
          "4": 151,
        },
        "37": {
          "3": 612,
          "4": 200,
        },
        "38": {
          "3": 213,
          "4": 108,
        },
        "39": {
          "3": 57,
          "4": 56,
        },
        "40": {
          "3": 8,
          "4": 21,
        },
        "41": {
          "4": 9,
        },
        "29.6": {
          "2": 941,
          "3": 3379,
        },
        "33.4": {
          "3": 5204,
        },
        "28.6": {
          "2": 1408,
          "3": 1484,
        },
        "35.8": {
          "3": 1702,
          "4": 148,
        },
        "31.2": {
          "2": 274,
          "3": 5677,
        },
        "29.4": {
          "2": 999,
          "3": 2986,
        },
        "32.4": {
          "2": 34,
          "3": 5879,
        },
        "34.4": {
          "3": 3780,
          "4": 28,
        },
        "30.4": {
          "2": 523,
          "3": 4674,
        },
        "33.6": {
          "3": 4804,
        },
        "29.2": {
          "2": 1008,
          "3": 2724,
        },
        "35.2": {
          "3": 2548,
          "4": 94,
        },
        "31.4": {
          "2": 218,
          "3": 5789,
        },
        "27.8": {
          "2": 1399,
          "3": 426,
        },
        "33.2": {
          "3": 5478,
        },
        "26.8": {
          "2": 928,
          "3": 19,
        },
        "30.2": {
          "2": 663,
          "3": 4402,
        },
        "32.6": {
          "2": 18,
          "3": 5978,
        },
        "33.8": {
          "3": 4758,
          "4": 5,
        },
        "36.4": {
          "3": 1091,
          "4": 172,
        },
        "27.4": {
          "2": 1301,
          "3": 195,
        },
        "28.4": {
          "2": 1382,
          "3": 1149,
        },
        "29.8": {
          "2": 793,
          "3": 3651,
        },
        "34.8": {
          "3": 3101,
          "4": 63,
        },
        "28.8": {
          "2": 1259,
          "3": 1806,
        },
        "31.6": {
          "2": 163,
          "3": 5954,
        },
        "36.2": {
          "3": 1242,
          "4": 152,
        },
        "30.8": {
          "2": 427,
          "3": 5253,
        },
        "34.2": {
          "3": 4017,
          "4": 16,
        },
        "28.2": {
          "2": 1534,
          "3": 911,
        },
        "35.6": {
          "3": 1885,
          "4": 135,
        },
        "34.6": {
          "3": 3350,
          "4": 29,
        },
        "39.8": {
          "3": 17,
          "4": 30,
        },
        "26.4": {
          "2": 741,
          "3": 7,
        },
        "35.4": {
          "3": 2231,
          "4": 119,
        },
        "31.8": {
          "2": 117,
          "3": 5944,
        },
        "30.6": {
          "2": 509,
          "3": 5158,
        },
        "36.6": {
          "3": 910,
          "4": 174,
        },
        "32.2": {
          "2": 55,
          "3": 5887,
        },
        "24.2": {
          "2": 107,
        },
        "37.2": {
          "3": 525,
          "4": 141,
        },
        "38.4": {
          "3": 142,
          "4": 86,
        },
        "23.8": {
          "2": 72,
        },
        "39.6": {
          "3": 18,
          "4": 40,
        },
        "32.8": {
          "2": 7,
          "3": 5667,
        },
        "26.2": {
          "2": 614,
          "3": 1,
        },
        "27.2": {
          "2": 1201,
          "3": 104,
        },
        "27.6": {
          "2": 1360,
          "3": 249,
        },
        "37.4": {
          "3": 439,
          "4": 138,
        },
        "26.6": {
          "2": 825,
          "3": 17,
        },
        "36.8": {
          "3": 781,
          "4": 158,
        },
        "25.8": {
          "2": 462,
          "3": 2,
        },
        "37.8": {
          "3": 305,
          "4": 113,
        },
        "38.6": {
          "3": 105,
          "4": 87,
        },
        "25.4": {
          "2": 329,
        },
        "25.6": {
          "2": 394,
          "3": 1,
        },
        "23.6": {
          "1": 1,
          "2": 235,
        },
        "24.4": {
          "2": 139,
        },
        "25.2": {
          "2": 290,
        },
        "38.8": {
          "3": 69,
          "4": 80,
        },
        "39.2": {
          "3": 45,
          "4": 65,
        },
        "37.6": {
          "3": 338,
          "4": 127,
        },
        "24.6": {
          "2": 158,
        },
        "24.8": {
          "2": 181,
        },
        "38.2": {
          "3": 167,
          "4": 105,
        },
        "40.6": {
          "3": 3,
          "4": 9,
        },
        "41.6": {
          "4": 16,
        },
        "40.2": {
          "3": 10,
          "4": 19,
        },
        "39.4": {
          "3": 37,
          "4": 39,
        },
        "40.4": {
          "3": 6,
          "4": 26,
        },
        "41.4": {
          "4": 5,
        },
        "41.2": {
          "4": 9,
        },
        "40.8": {
          "3": 1,
          "4": 8,
        },
      },
      TD: {
        "9": {
          "0": 759,
          "1": 13,
        },
        "10": {
          "0": 138,
          "1": 597,
        },
        "11": {
          "0": 2,
          "1": 2174,
        },
        "12": {
          "1": 4612,
        },
        "13": {
          "1": 7268,
        },
        "14": {
          "1": 8430,
        },
        "15": {
          "1": 7583,
        },
        "16": {
          "1": 4979,
        },
        "17": {
          "1": 2483,
          "2": 9,
        },
        "18": {
          "1": 827,
          "2": 58,
        },
        "19": {
          "1": 149,
          "2": 123,
        },
        "20": {
          "1": 10,
          "2": 48,
        },
        "15.8": {
          "1": 5656,
        },
        "14.8": {
          "1": 8264,
        },
        "12.6": {
          "1": 6245,
        },
        "15.4": {
          "1": 6616,
        },
        "16.4": {
          "1": 3851,
        },
        "13.2": {
          "1": 7636,
        },
        "10.8": {
          "0": 16,
          "1": 1800,
        },
        "17.6": {
          "1": 1406,
          "2": 33,
        },
        "13.4": {
          "1": 7995,
        },
        "14.6": {
          "1": 8298,
        },
        "9.4": {
          "0": 232,
          "1": 122,
        },
        "13.6": {
          "1": 8275,
        },
        "16.8": {
          "1": 2884,
          "2": 1,
        },
        "12.8": {
          "1": 6800,
        },
        "12.2": {
          "1": 5106,
        },
        "14.4": {
          "1": 8464,
        },
        "12.4": {
          "1": 5671,
        },
        "14.2": {
          "1": 8615,
        },
        "15.2": {
          "1": 7196,
        },
        "13.8": {
          "1": 8522,
        },
        "11.2": {
          "1": 2571,
        },
        "16.6": {
          "1": 3423,
        },
        "11.8": {
          "1": 3995,
        },
        "15.6": {
          "1": 6080,
        },
        "17.8": {
          "1": 1108,
          "2": 61,
        },
        "18.4": {
          "1": 466,
          "2": 124,
        },
        "11.6": {
          "1": 3507,
        },
        "11.4": {
          "1": 3123,
        },
        "9.6": {
          "0": 213,
          "1": 240,
        },
        "10.2": {
          "0": 106,
          "1": 892,
        },
        "19.2": {
          "1": 95,
          "2": 104,
        },
        "16.2": {
          "1": 4460,
        },
        "17.4": {
          "1": 1720,
          "2": 28,
        },
        "9.2": {
          "0": 226,
          "1": 64,
        },
        "17.2": {
          "1": 2064,
          "2": 17,
        },
        "18.2": {
          "1": 657,
          "2": 96,
        },
        "10.6": {
          "0": 37,
          "1": 1421,
        },
        "18.8": {
          "1": 214,
          "2": 121,
        },
        "10.4": {
          "0": 67,
          "1": 1173,
        },
        "20.2": {
          "1": 6,
          "2": 123,
        },
        "19.8": {
          "1": 18,
          "2": 64,
        },
        "9.8": {
          "0": 210,
          "1": 457,
        },
        "18.6": {
          "1": 332,
          "2": 124,
        },
        "19.4": {
          "1": 46,
          "2": 76,
        },
        "19.6": {
          "1": 28,
          "2": 77,
        },
      },
      KONF: {
        "4": {
          "0": 472,
        },
        "5": {
          "0": 1570,
        },
        "6": {
          "0": 3808,
        },
        "7": {
          "0": 6948,
        },
        "8": {
          "0": 9025,
        },
        "9": {
          "0": 7968,
          "1": 481,
        },
        "10": {
          "0": 2224,
          "1": 3345,
        },
        "11": {
          "0": 3,
          "1": 2776,
        },
        "12": {
          "1": 913,
        },
        "13": {
          "1": 230,
        },
        "10.4": {
          "0": 624,
          "1": 3723,
        },
        "9.8": {
          "0": 3253,
          "1": 2966,
        },
        "10.2": {
          "0": 1252,
          "1": 3721,
        },
        "8.2": {
          "0": 9116,
        },
        "7.8": {
          "0": 8845,
        },
        "9.4": {
          "0": 5900,
          "1": 1537,
        },
        "8.8": {
          "0": 8464,
          "1": 170,
        },
        "7.4": {
          "0": 8024,
        },
        "8.6": {
          "0": 8937,
          "1": 40,
        },
        "9.6": {
          "0": 4594,
          "1": 2323,
        },
        "7.2": {
          "0": 7474,
        },
        "10.8": {
          "0": 41,
          "1": 3140,
        },
        "6.2": {
          "0": 4479,
        },
        "10.6": {
          "0": 224,
          "1": 3528,
        },
        "7.6": {
          "0": 8436,
        },
        "5.2": {
          "0": 1909,
        },
        "5.8": {
          "0": 3363,
        },
        "8.4": {
          "0": 9309,
          "1": 2,
        },
        "4.4": {
          "0": 828,
        },
        "9.2": {
          "0": 7036,
          "1": 902,
        },
        "6.4": {
          "0": 5129,
        },
        "3.6": {
          "0": 1138,
        },
        "6.8": {
          "0": 6211,
        },
        "6.6": {
          "0": 5785,
        },
        "11.8": {
          "1": 1126,
        },
        "11.2": {
          "1": 2218,
        },
        "5.6": {
          "0": 2814,
        },
        "11.4": {
          "1": 1834,
        },
        "4.8": {
          "0": 1282,
        },
        "12.8": {
          "1": 313,
        },
        "3.8": {
          "0": 402,
        },
        "12.6": {
          "1": 413,
        },
        "13.4": {
          "1": 126,
        },
        "5.4": {
          "0": 2421,
        },
        "11.6": {
          "1": 1460,
        },
        "4.2": {
          "0": 675,
        },
        "13.6": {
          "1": 89,
        },
        "4.6": {
          "0": 1082,
        },
        "13.2": {
          "1": 151,
        },
        "12.2": {
          "1": 706,
        },
        "13.8": {
          "1": 169,
        },
        "12.4": {
          "1": 533,
        },
      },
      LEWICA: {
        "8": {
          "0": 1287,
        },
        "9": {
          "0": 3976,
          "1": 1262,
        },
        "10": {
          "0": 1255,
          "1": 9502,
        },
        "11": {
          "0": 1,
          "1": 12115,
        },
        "12": {
          "1": 7336,
        },
        "13": {
          "1": 2579,
        },
        "14": {
          "1": 490,
        },
        "10.2": {
          "0": 791,
          "1": 10842,
        },
        "13.6": {
          "1": 1032,
        },
        "11.2": {
          "1": 11346,
        },
        "9.6": {
          "0": 2563,
          "1": 6202,
        },
        "12.4": {
          "1": 5198,
        },
        "10.4": {
          "0": 418,
          "1": 11830,
        },
        "12.2": {
          "1": 6229,
        },
        "8.6": {
          "0": 3148,
          "1": 90,
        },
        "7.8": {
          "0": 969,
        },
        "9.4": {
          "0": 3231,
          "1": 4192,
        },
        "8.2": {
          "0": 1808,
        },
        "10.6": {
          "0": 170,
          "1": 12234,
        },
        "12.8": {
          "1": 3252,
        },
        "13.8": {
          "1": 720,
        },
        "9.8": {
          "0": 1835,
          "1": 7963,
        },
        "11.6": {
          "1": 9595,
        },
        "11.8": {
          "1": 8465,
        },
        "10.8": {
          "0": 30,
          "1": 12177,
        },
        "9.2": {
          "0": 3617,
          "1": 2612,
        },
        "7.6": {
          "0": 660,
        },
        "8.4": {
          "0": 2453,
          "1": 4,
        },
        "14.2": {
          "1": 318,
        },
        "12.6": {
          "1": 4203,
        },
        "8.8": {
          "0": 3717,
          "1": 417,
        },
        "11.4": {
          "1": 10579,
        },
        "13.2": {
          "1": 1958,
        },
        "14.6": {
          "1": 437,
        },
        "13.4": {
          "1": 1524,
        },
        "7.4": {
          "0": 1134,
        },
        "14.4": {
          "1": 234,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3934,
      TD: 1710,
      LEWICA: 10720,
    },
    potencjalneMandaty: {
      KO: 63113,
      TD: 9670,
      LEWICA: 37293,
    },
    ostatnieMandaty: {
      KO: 59673,
      TD: 5904,
      LEWICA: 59385,
    },
    dawcyGlosow: {
      KO: 9679,
      TD: 5688,
      LEWICA: 25366,
    },
  },
  Olsztyn: {
    procentyWOkreguSrednia: {
      PIS: 32.61364502771072,
      KO: 28.424311955690733,
      TD: 16.32296959494628,
      KONF: 10.042293229135316,
      LEWICA: 12.596780192516958,
    },
    odchylenieWOkregu: {
      PIS: 2.179248829990215,
      KO: 2.1598552318221675,
      TD: 2.013774734692939,
      LEWICA: 1.0941809880694036,
      KONF: 0.6816029574977159,
    },
    wykresRozkladu: {
      "47.2%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "46.5%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "3.2%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "50.5%": {
        DEMO: 6,
        NIEDEMO: 4,
      },
      "49.5%": {
        DEMO: 5,
        NIEDEMO: 5,
      },
      "0%": {
        DEMO: 7,
        NIEDEMO: 3,
      },
    },
    histogram: {
      PIS: {
        "28": {
          "3": 370,
        },
        "29": {
          "3": 1179,
        },
        "30": {
          "3": 3092,
          "4": 15,
        },
        "31": {
          "3": 5331,
          "4": 611,
        },
        "32": {
          "3": 5504,
          "4": 2879,
        },
        "33": {
          "3": 3456,
          "4": 5106,
        },
        "34": {
          "3": 1010,
          "4": 5501,
        },
        "35": {
          "3": 67,
          "4": 3810,
        },
        "36": {
          "4": 1533,
        },
        "37": {
          "4": 434,
        },
        "38": {
          "4": 124,
        },
        "39": {
          "4": 24,
        },
        "33.8": {
          "3": 1383,
          "4": 5763,
        },
        "32.4": {
          "3": 4749,
          "4": 3905,
        },
        "34.8": {
          "3": 119,
          "4": 4162,
        },
        "33.6": {
          "3": 1937,
          "4": 5620,
        },
        "34.4": {
          "3": 431,
          "4": 4924,
        },
        "29.8": {
          "3": 2594,
        },
        "31.4": {
          "3": 5505,
          "4": 1411,
        },
        "31.8": {
          "3": 5668,
          "4": 2404,
        },
        "30.6": {
          "3": 4471,
          "4": 198,
        },
        "34.6": {
          "3": 245,
          "4": 4515,
        },
        "31.2": {
          "3": 5558,
          "4": 980,
        },
        "32.6": {
          "3": 4516,
          "4": 4328,
        },
        "32.2": {
          "3": 5251,
          "4": 3287,
        },
        "30.4": {
          "3": 4039,
          "4": 89,
        },
        "29.6": {
          "3": 2188,
        },
        "35.8": {
          "4": 1888,
        },
        "33.4": {
          "3": 2448,
          "4": 5568,
        },
        "32.8": {
          "3": 3981,
          "4": 4803,
        },
        "35.6": {
          "3": 2,
          "4": 2246,
        },
        "35.2": {
          "3": 27,
          "4": 3047,
        },
        "31.6": {
          "3": 5684,
          "4": 1836,
        },
        "34.2": {
          "3": 700,
          "4": 5425,
        },
        "37.8": {
          "4": 165,
          "5": 1,
        },
        "37.4": {
          "4": 299,
        },
        "30.8": {
          "3": 4978,
          "4": 372,
        },
        "28.2": {
          "3": 449,
        },
        "28.4": {
          "3": 606,
        },
        "30.2": {
          "3": 3621,
          "4": 34,
        },
        "28.6": {
          "3": 716,
        },
        "33.2": {
          "3": 3040,
          "4": 5381,
        },
        "35.4": {
          "3": 5,
          "4": 2603,
        },
        "36.4": {
          "4": 1066,
        },
        "29.4": {
          "3": 1839,
        },
        "36.8": {
          "4": 633,
        },
        "36.2": {
          "4": 1266,
        },
        "27.2": {
          "2": 4,
          "3": 353,
        },
        "27.6": {
          "3": 169,
        },
        "29.2": {
          "3": 1475,
        },
        "28.8": {
          "3": 976,
        },
        "36.6": {
          "4": 821,
        },
        "38.4": {
          "4": 71,
        },
        "38.2": {
          "4": 74,
          "5": 1,
        },
        "37.2": {
          "4": 361,
        },
        "38.8": {
          "4": 26,
        },
        "27.8": {
          "3": 236,
        },
        "27.4": {
          "3": 136,
        },
        "37.6": {
          "4": 201,
        },
        "39.2": {
          "4": 35,
          "5": 9,
        },
        "38.6": {
          "4": 36,
          "5": 1,
        },
      },
      KO: {
        "23": {
          "2": 353,
        },
        "24": {
          "2": 393,
          "3": 66,
        },
        "25": {
          "2": 606,
          "3": 878,
        },
        "26": {
          "2": 118,
          "3": 3419,
        },
        "27": {
          "3": 6481,
        },
        "28": {
          "3": 8448,
        },
        "29": {
          "3": 8363,
        },
        "30": {
          "3": 5863,
          "4": 14,
        },
        "31": {
          "3": 2957,
          "4": 298,
        },
        "32": {
          "3": 585,
          "4": 583,
        },
        "33": {
          "3": 48,
          "4": 309,
        },
        "34": {
          "3": 6,
          "4": 84,
        },
        "35": {
          "4": 35,
        },
        "30.2": {
          "3": 5371,
          "4": 24,
        },
        "28.8": {
          "3": 8557,
        },
        "29.8": {
          "3": 6554,
          "4": 1,
        },
        "29.4": {
          "3": 7813,
        },
        "28.4": {
          "3": 8806,
        },
        "30.4": {
          "3": 4796,
          "4": 60,
        },
        "27.4": {
          "3": 7390,
        },
        "27.2": {
          "3": 7127,
        },
        "26.4": {
          "2": 16,
          "3": 4710,
        },
        "26.2": {
          "2": 51,
          "3": 4152,
        },
        "26.8": {
          "3": 5871,
        },
        "29.6": {
          "3": 7194,
        },
        "32.2": {
          "3": 416,
          "4": 594,
        },
        "30.6": {
          "3": 4221,
          "4": 103,
        },
        "23.8": {
          "2": 282,
          "3": 52,
        },
        "28.6": {
          "3": 8795,
        },
        "28.2": {
          "3": 8858,
        },
        "26.6": {
          "2": 5,
          "3": 5200,
        },
        "25.8": {
          "2": 219,
          "3": 2915,
        },
        "27.8": {
          "3": 8402,
        },
        "30.8": {
          "3": 3485,
          "4": 177,
        },
        "24.2": {
          "2": 427,
          "3": 139,
        },
        "29.2": {
          "3": 8273,
        },
        "25.6": {
          "2": 354,
          "3": 2321,
        },
        "33.8": {
          "3": 8,
          "4": 98,
        },
        "31.8": {
          "3": 866,
          "4": 580,
        },
        "25.4": {
          "2": 479,
          "3": 1706,
        },
        "27.6": {
          "3": 8005,
        },
        "31.6": {
          "3": 1282,
          "4": 560,
        },
        "23.4": {
          "2": 149,
          "3": 9,
        },
        "25.2": {
          "2": 545,
          "3": 1256,
        },
        "31.4": {
          "3": 1782,
          "4": 450,
        },
        "33.2": {
          "3": 43,
          "4": 237,
        },
        "31.2": {
          "3": 2301,
          "4": 398,
        },
        "32.4": {
          "3": 270,
          "4": 489,
        },
        "24.6": {
          "2": 576,
          "3": 349,
        },
        "24.8": {
          "2": 622,
          "3": 606,
        },
        "32.8": {
          "3": 102,
          "4": 370,
        },
        "32.6": {
          "3": 177,
          "4": 435,
        },
        "33.6": {
          "3": 7,
          "4": 139,
        },
        "23.2": {
          "2": 143,
          "3": 10,
        },
        "33.4": {
          "3": 29,
          "4": 147,
        },
        "34.6": {
          "4": 26,
        },
        "23.6": {
          "2": 248,
          "3": 22,
        },
        "24.4": {
          "2": 525,
          "3": 204,
        },
        "34.4": {
          "4": 44,
        },
        "34.8": {
          "4": 22,
        },
        "34.2": {
          "4": 46,
        },
      },
      TD: {
        "12": {
          "1": 474,
        },
        "13": {
          "1": 1636,
        },
        "14": {
          "1": 3856,
        },
        "15": {
          "1": 6710,
          "2": 18,
        },
        "16": {
          "1": 6149,
          "2": 2758,
        },
        "17": {
          "1": 945,
          "2": 7436,
        },
        "18": {
          "2": 5766,
        },
        "19": {
          "2": 2794,
        },
        "20": {
          "2": 1010,
        },
        "21": {
          "2": 232,
        },
        "22": {
          "2": 44,
        },
        "17.4": {
          "1": 143,
          "2": 7375,
        },
        "15.8": {
          "1": 6716,
          "2": 1657,
        },
        "14.8": {
          "1": 6139,
        },
        "16.2": {
          "1": 5228,
          "2": 3756,
        },
        "19.4": {
          "2": 1962,
        },
        "14.4": {
          "1": 4974,
        },
        "17.6": {
          "1": 36,
          "2": 6907,
        },
        "16.8": {
          "1": 1770,
          "2": 6936,
        },
        "18.8": {
          "2": 3284,
        },
        "20.6": {
          "2": 486,
        },
        "16.6": {
          "1": 2790,
          "2": 6116,
        },
        "18.4": {
          "2": 4544,
        },
        "13.2": {
          "1": 1883,
        },
        "15.4": {
          "1": 7538,
          "2": 357,
        },
        "13.6": {
          "1": 2812,
        },
        "18.6": {
          "2": 3951,
        },
        "15.2": {
          "1": 7106,
          "2": 102,
        },
        "14.2": {
          "1": 4381,
        },
        "16.4": {
          "1": 3968,
          "2": 5235,
        },
        "20.4": {
          "2": 633,
        },
        "14.6": {
          "1": 5719,
          "2": 1,
        },
        "15.6": {
          "1": 7232,
          "2": 884,
        },
        "13.8": {
          "1": 3245,
        },
        "12.4": {
          "1": 814,
        },
        "17.8": {
          "1": 3,
          "2": 6555,
        },
        "12.6": {
          "1": 1027,
        },
        "18.2": {
          "2": 5165,
        },
        "19.8": {
          "2": 1235,
        },
        "20.8": {
          "2": 330,
        },
        "17.2": {
          "1": 396,
          "2": 7574,
        },
        "19.2": {
          "2": 2478,
        },
        "13.4": {
          "1": 2314,
        },
        "20.2": {
          "2": 770,
        },
        "11.8": {
          "1": 376,
        },
        "19.6": {
          "2": 1641,
        },
        "11.2": {
          "0": 1,
          "1": 568,
        },
        "12.8": {
          "1": 1312,
        },
        "21.4": {
          "2": 124,
        },
        "11.6": {
          "1": 317,
        },
        "21.2": {
          "2": 175,
        },
        "12.2": {
          "1": 682,
        },
        "11.4": {
          "1": 224,
        },
        "21.8": {
          "2": 61,
        },
        "21.6": {
          "2": 82,
        },
        "22.4": {
          "2": 53,
          "3": 1,
        },
        "22.2": {
          "2": 28,
        },
      },
      KONF: {
        "9": {
          "1": 7839,
        },
        "10": {
          "1": 22144,
        },
        "11": {
          "1": 8929,
        },
        "12": {
          "1": 1326,
        },
        "10.4": {
          "1": 19389,
        },
        "8.8": {
          "0": 16,
          "1": 4826,
        },
        "9.2": {
          "1": 11116,
        },
        "9.4": {
          "1": 14984,
        },
        "9.8": {
          "1": 20877,
        },
        "10.2": {
          "1": 21287,
        },
        "10.6": {
          "1": 16262,
        },
        "11.2": {
          "1": 6442,
        },
        "10.8": {
          "1": 12549,
        },
        "9.6": {
          "1": 18495,
        },
        "8.6": {
          "0": 90,
          "1": 2813,
        },
        "11.4": {
          "1": 4039,
        },
        "11.8": {
          "1": 1341,
        },
        "11.6": {
          "1": 2373,
        },
        "8.4": {
          "0": 785,
          "1": 2078,
        },
      },
      LEWICA: {
        "10": {
          "1": 734,
        },
        "11": {
          "1": 4808,
        },
        "12": {
          "1": 12890,
        },
        "13": {
          "1": 14021,
        },
        "14": {
          "1": 6295,
        },
        "15": {
          "1": 1266,
          "2": 6,
        },
        "11.6": {
          "1": 9795,
        },
        "10.8": {
          "1": 3626,
        },
        "12.2": {
          "1": 13920,
        },
        "11.4": {
          "1": 8016,
        },
        "12.8": {
          "1": 14618,
        },
        "13.8": {
          "1": 7868,
        },
        "12.4": {
          "1": 14768,
        },
        "10.6": {
          "1": 2512,
        },
        "11.8": {
          "1": 11522,
        },
        "13.2": {
          "1": 12519,
        },
        "15.6": {
          "1": 291,
          "2": 50,
        },
        "12.6": {
          "1": 14967,
        },
        "14.6": {
          "1": 2513,
        },
        "13.6": {
          "1": 9545,
        },
        "11.2": {
          "1": 6383,
        },
        "14.2": {
          "1": 4776,
        },
        "15.4": {
          "1": 467,
          "2": 39,
        },
        "13.4": {
          "1": 11219,
        },
        "14.4": {
          "1": 3584,
        },
        "9.8": {
          "0": 12,
          "1": 1006,
        },
        "10.4": {
          "1": 1690,
        },
        "15.2": {
          "1": 786,
          "2": 21,
        },
        "10.2": {
          "1": 1151,
        },
        "14.8": {
          "1": 1852,
        },
        "15.8": {
          "1": 305,
          "2": 159,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 3154,
      TD: 3638,
      LEWICA: 56,
    },
    potencjalneMandaty: {
      KO: 34833,
      TD: 56417,
      LEWICA: 5706,
    },
    ostatnieMandaty: {
      KO: 29886,
      TD: 68860,
      LEWICA: 334,
    },
    dawcyGlosow: {
      KO: 8850,
      TD: 7425,
      LEWICA: 421,
    },
  },
  Kalisz: {
    procentyWOkreguSrednia: {
      PIS: 36.09857653488357,
      KO: 27.1936002865142,
      TD: 16.187871252776436,
      KONF: 9.526911866008485,
      LEWICA: 10.993040059817316,
    },
    odchylenieWOkregu: {
      PIS: 3.1764581786746366,
      KO: 1.7614912243680323,
      TD: 2.013774734692939,
      LEWICA: 1.761150734020553,
      KONF: 0.6382966162449232,
    },
    wykresRozkladu: {
      "60.6%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 2,
      },
      "24.7%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "8.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
      "2.7%": {
        KO: 3,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 2,
      },
      "1.1%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 7,
        TD: 1,
      },
      "0.8%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 3,
      },
      "0.7%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 2,
      },
      "0.3%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 7,
        TD: 2,
      },
      "0%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 3,
      },
    },
    wykresRozkladuDemo: {
      "70.2%": {
        DEMO: 6,
        NIEDEMO: 6,
      },
      "28.6%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "1.2%": {
        DEMO: 5,
        NIEDEMO: 7,
      },
    },
    histogram: {
      PIS: {
        "29": {
          "4": 85,
        },
        "30": {
          "4": 269,
        },
        "31": {
          "4": 662,
        },
        "32": {
          "4": 1538,
        },
        "33": {
          "4": 2666,
          "5": 143,
        },
        "34": {
          "4": 3222,
          "5": 1232,
        },
        "35": {
          "4": 2199,
          "5": 3936,
        },
        "36": {
          "4": 719,
          "5": 6049,
        },
        "37": {
          "4": 52,
          "5": 6366,
        },
        "38": {
          "4": 1,
          "5": 4986,
        },
        "39": {
          "5": 3140,
          "6": 9,
        },
        "40": {
          "5": 1581,
          "6": 126,
        },
        "41": {
          "5": 568,
          "6": 180,
        },
        "42": {
          "5": 134,
          "6": 117,
        },
        "43": {
          "5": 18,
          "6": 68,
        },
        "44": {
          "5": 3,
          "6": 17,
        },
        "45": {
          "6": 2,
        },
        "31.6": {
          "4": 1060,
        },
        "32.8": {
          "4": 2472,
          "5": 77,
        },
        "35.2": {
          "4": 1827,
          "5": 4431,
        },
        "34.8": {
          "4": 2525,
          "5": 3231,
        },
        "33.2": {
          "4": 2883,
          "5": 261,
        },
        "30.8": {
          "4": 562,
        },
        "35.6": {
          "4": 1158,
          "5": 5451,
        },
        "37.4": {
          "4": 13,
          "5": 5912,
        },
        "34.6": {
          "4": 2825,
          "5": 2716,
        },
        "36.6": {
          "4": 170,
          "5": 6410,
        },
        "32.2": {
          "4": 1730,
          "5": 1,
        },
        "37.2": {
          "4": 21,
          "5": 6044,
        },
        "34.2": {
          "4": 3161,
          "5": 1612,
        },
        "31.8": {
          "4": 1310,
        },
        "33.6": {
          "4": 3148,
          "5": 639,
        },
        "38.8": {
          "5": 3548,
          "6": 4,
        },
        "30.6": {
          "4": 488,
        },
        "38.6": {
          "5": 3840,
          "6": 2,
        },
        "35.8": {
          "4": 892,
          "5": 5697,
        },
        "32.6": {
          "4": 2314,
          "5": 35,
        },
        "40.8": {
          "5": 704,
          "6": 166,
        },
        "36.4": {
          "4": 291,
          "5": 6452,
        },
        "39.2": {
          "5": 2866,
          "6": 29,
        },
        "37.8": {
          "4": 1,
          "5": 5233,
        },
        "31.4": {
          "4": 930,
        },
        "37.6": {
          "4": 2,
          "5": 5578,
        },
        "40.6": {
          "5": 911,
          "6": 166,
        },
        "36.8": {
          "4": 113,
          "5": 6528,
        },
        "35.4": {
          "4": 1485,
          "5": 4965,
        },
        "34.4": {
          "4": 3088,
          "5": 2155,
        },
        "39.8": {
          "5": 1955,
          "6": 83,
        },
        "33.8": {
          "4": 3183,
          "5": 885,
        },
        "38.2": {
          "5": 4604,
        },
        "36.2": {
          "4": 458,
          "5": 6351,
        },
        "41.2": {
          "5": 433,
          "6": 186,
        },
        "32.4": {
          "4": 1965,
          "5": 12,
        },
        "39.4": {
          "5": 2483,
          "6": 44,
        },
        "30.4": {
          "4": 413,
        },
        "40.2": {
          "5": 1276,
          "6": 106,
        },
        "33.4": {
          "4": 3102,
          "5": 397,
        },
        "39.6": {
          "5": 2116,
          "6": 52,
        },
        "30.2": {
          "4": 357,
        },
        "40.4": {
          "5": 1101,
          "6": 146,
        },
        "38.4": {
          "5": 4192,
        },
        "42.4": {
          "5": 59,
          "6": 101,
        },
        "29.6": {
          "3": 4,
          "4": 178,
        },
        "31.2": {
          "4": 822,
        },
        "41.4": {
          "5": 358,
          "6": 151,
        },
        "42.8": {
          "5": 33,
          "6": 79,
        },
        "44.4": {
          "6": 15,
        },
        "41.8": {
          "5": 193,
          "6": 164,
        },
        "28.2": {
          "3": 54,
          "4": 101,
        },
        "43.8": {
          "5": 3,
          "6": 24,
        },
        "42.6": {
          "5": 47,
          "6": 82,
        },
        "42.2": {
          "5": 95,
          "6": 105,
        },
        "41.6": {
          "5": 259,
          "6": 117,
        },
        "29.8": {
          "4": 243,
        },
        "43.2": {
          "5": 11,
          "6": 56,
        },
        "29.2": {
          "4": 128,
        },
        "29.4": {
          "3": 2,
          "4": 110,
        },
        "28.6": {
          "3": 5,
          "4": 61,
        },
        "43.4": {
          "5": 11,
          "6": 30,
        },
        "43.6": {
          "5": 5,
          "6": 33,
        },
        "28.8": {
          "3": 5,
          "4": 60,
        },
        "28.4": {
          "3": 9,
          "4": 34,
        },
        "44.2": {
          "6": 6,
        },
        "45.6": {
          "6": 5,
          "7": 1,
        },
        "44.8": {
          "6": 5,
        },
        "45.2": {
          "6": 4,
        },
        "45.4": {
          "6": 7,
        },
        "44.6": {
          "6": 4,
        },
      },
      KO: {
        "23": {
          "3": 411,
        },
        "24": {
          "3": 1600,
        },
        "25": {
          "3": 4156,
        },
        "26": {
          "3": 7424,
          "4": 29,
        },
        "27": {
          "3": 7910,
          "4": 1215,
        },
        "28": {
          "3": 4250,
          "4": 4038,
        },
        "29": {
          "3": 660,
          "4": 4646,
        },
        "30": {
          "3": 8,
          "4": 2414,
        },
        "31": {
          "4": 920,
        },
        "32": {
          "4": 221,
        },
        "26.4": {
          "3": 8189,
          "4": 254,
        },
        "26.6": {
          "3": 8333,
          "4": 475,
        },
        "24.6": {
          "3": 3026,
        },
        "30.2": {
          "3": 1,
          "4": 2005,
        },
        "28.2": {
          "3": 3242,
          "4": 4559,
        },
        "28.6": {
          "3": 1613,
          "4": 4884,
        },
        "27.6": {
          "3": 6126,
          "4": 2878,
        },
        "27.8": {
          "3": 5179,
          "4": 3575,
        },
        "29.2": {
          "3": 358,
          "4": 4362,
        },
        "25.8": {
          "3": 6756,
          "4": 7,
        },
        "27.4": {
          "3": 6863,
          "4": 2303,
        },
        "26.2": {
          "3": 7813,
          "4": 117,
        },
        "25.4": {
          "3": 5498,
        },
        "25.6": {
          "3": 6147,
          "4": 2,
        },
        "29.6": {
          "3": 66,
          "4": 3272,
        },
        "29.8": {
          "3": 29,
          "4": 2902,
        },
        "24.4": {
          "3": 2404,
        },
        "28.4": {
          "3": 2367,
          "4": 4757,
        },
        "28.8": {
          "3": 1053,
          "4": 4786,
        },
        "24.8": {
          "3": 3572,
        },
        "29.4": {
          "3": 186,
          "4": 3834,
        },
        "23.6": {
          "3": 993,
        },
        "31.6": {
          "4": 404,
        },
        "26.8": {
          "3": 8286,
          "4": 772,
        },
        "30.6": {
          "4": 1388,
        },
        "22.8": {
          "2": 20,
          "3": 917,
        },
        "30.4": {
          "4": 1666,
        },
        "24.2": {
          "3": 1956,
        },
        "25.2": {
          "3": 4753,
        },
        "27.2": {
          "3": 7623,
          "4": 1683,
        },
        "31.8": {
          "4": 327,
        },
        "23.4": {
          "3": 766,
        },
        "32.2": {
          "4": 173,
        },
        "23.8": {
          "3": 1261,
        },
        "23.2": {
          "3": 500,
        },
        "30.8": {
          "4": 1070,
        },
        "31.4": {
          "4": 525,
        },
        "32.4": {
          "4": 483,
          "5": 35,
        },
        "31.2": {
          "4": 704,
        },
      },
      TD: {
        "12": {
          "1": 675,
        },
        "13": {
          "1": 1933,
          "2": 32,
        },
        "14": {
          "1": 1395,
          "2": 2725,
        },
        "15": {
          "1": 5,
          "2": 6993,
        },
        "16": {
          "2": 9048,
        },
        "17": {
          "2": 7907,
        },
        "18": {
          "2": 5408,
        },
        "19": {
          "2": 2621,
        },
        "20": {
          "2": 827,
          "3": 96,
        },
        "21": {
          "2": 89,
          "3": 162,
        },
        "22": {
          "2": 3,
          "3": 61,
        },
        "18.4": {
          "2": 4142,
        },
        "17.4": {
          "2": 7094,
        },
        "17.2": {
          "2": 7539,
        },
        "16.8": {
          "2": 8236,
        },
        "17.6": {
          "2": 6451,
        },
        "16.2": {
          "2": 8798,
        },
        "19.4": {
          "2": 1683,
          "3": 6,
        },
        "14.2": {
          "1": 900,
          "2": 3801,
        },
        "16.4": {
          "2": 8619,
        },
        "14.6": {
          "1": 200,
          "2": 5767,
        },
        "17.8": {
          "2": 5954,
        },
        "20.4": {
          "2": 442,
          "3": 184,
        },
        "15.6": {
          "2": 8467,
        },
        "14.4": {
          "1": 453,
          "2": 4784,
        },
        "15.4": {
          "2": 8151,
        },
        "15.2": {
          "1": 1,
          "2": 7707,
        },
        "16.6": {
          "2": 8498,
        },
        "18.2": {
          "2": 4699,
        },
        "15.8": {
          "2": 8470,
        },
        "13.8": {
          "1": 1931,
          "2": 1782,
        },
        "20.2": {
          "2": 632,
          "3": 140,
        },
        "18.8": {
          "2": 3091,
        },
        "14.8": {
          "1": 61,
          "2": 6585,
        },
        "13.2": {
          "1": 2115,
          "2": 145,
        },
        "11.2": {
          "1": 794,
        },
        "19.2": {
          "2": 2239,
        },
        "13.6": {
          "1": 2205,
          "2": 985,
        },
        "12.4": {
          "1": 1007,
        },
        "18.6": {
          "2": 3630,
        },
        "19.6": {
          "2": 1492,
          "3": 17,
        },
        "12.6": {
          "1": 1315,
        },
        "13.4": {
          "1": 2196,
          "2": 482,
        },
        "21.4": {
          "2": 21,
          "3": 125,
        },
        "20.6": {
          "2": 282,
          "3": 169,
        },
        "11.6": {
          "1": 364,
        },
        "12.2": {
          "1": 801,
        },
        "21.6": {
          "2": 3,
          "3": 93,
        },
        "20.8": {
          "2": 154,
          "3": 169,
        },
        "12.8": {
          "1": 1564,
          "2": 1,
        },
        "19.8": {
          "2": 1138,
          "3": 55,
        },
        "11.8": {
          "1": 472,
        },
        "11.4": {
          "1": 305,
        },
        "21.8": {
          "2": 6,
          "3": 63,
        },
        "21.2": {
          "2": 50,
          "3": 188,
        },
        "22.2": {
          "2": 1,
          "3": 106,
        },
      },
      KONF: {
        "8": {
          "0": 12,
          "1": 3834,
        },
        "9": {
          "1": 17372,
        },
        "10": {
          "1": 17111,
        },
        "11": {
          "1": 2983,
        },
        "9.8": {
          "1": 20444,
        },
        "10.2": {
          "1": 13670,
        },
        "9.2": {
          "1": 20346,
        },
        "10.4": {
          "1": 10629,
        },
        "9.4": {
          "1": 22021,
        },
        "8.4": {
          "1": 6522,
        },
        "8.8": {
          "1": 13457,
        },
        "9.6": {
          "1": 21957,
        },
        "11.4": {
          "1": 2032,
        },
        "8.6": {
          "1": 9757,
        },
        "10.6": {
          "1": 7390,
        },
        "10.8": {
          "1": 4814,
        },
        "11.2": {
          "1": 1791,
        },
        "8.2": {
          "1": 3858,
        },
      },
      LEWICA: {
        "7": {
          "0": 218,
          "1": 297,
        },
        "8": {
          "1": 1848,
        },
        "9": {
          "1": 4684,
        },
        "10": {
          "1": 8142,
        },
        "11": {
          "1": 9655,
        },
        "12": {
          "1": 8162,
        },
        "13": {
          "1": 4518,
          "2": 20,
        },
        "14": {
          "1": 768,
          "2": 1084,
        },
        "15": {
          "1": 1,
          "2": 472,
        },
        "16": {
          "2": 90,
        },
        "13.6": {
          "1": 2287,
          "2": 537,
        },
        "6.6": {
          "0": 1017,
          "1": 20,
        },
        "11.8": {
          "1": 8640,
        },
        "10.2": {
          "1": 8694,
        },
        "8.8": {
          "1": 3890,
        },
        "12.8": {
          "1": 5197,
          "2": 1,
        },
        "10.4": {
          "1": 9138,
        },
        "9.2": {
          "1": 5229,
        },
        "11.6": {
          "1": 9063,
        },
        "9.6": {
          "1": 6731,
        },
        "15.6": {
          "2": 173,
        },
        "11.2": {
          "1": 9765,
        },
        "10.8": {
          "1": 9646,
        },
        "12.4": {
          "1": 6849,
        },
        "10.6": {
          "1": 9369,
        },
        "11.4": {
          "1": 9482,
        },
        "12.6": {
          "1": 6070,
        },
        "13.2": {
          "1": 3883,
          "2": 105,
        },
        "9.8": {
          "1": 7277,
        },
        "12.2": {
          "1": 7532,
        },
        "13.4": {
          "1": 2983,
          "2": 305,
        },
        "7.8": {
          "1": 1456,
        },
        "14.6": {
          "1": 45,
          "2": 795,
        },
        "9.4": {
          "1": 6048,
        },
        "14.4": {
          "1": 127,
          "2": 928,
        },
        "8.4": {
          "1": 2737,
        },
        "8.2": {
          "1": 2333,
        },
        "8.6": {
          "1": 3450,
        },
        "6.8": {
          "0": 307,
          "1": 92,
        },
        "7.6": {
          "0": 1,
          "1": 1135,
        },
        "13.8": {
          "1": 1417,
          "2": 838,
        },
        "14.2": {
          "1": 348,
          "2": 1078,
        },
        "7.2": {
          "0": 115,
          "1": 572,
        },
        "15.2": {
          "2": 303,
        },
        "15.8": {
          "2": 99,
        },
        "14.8": {
          "1": 14,
          "2": 578,
        },
        "7.4": {
          "0": 37,
          "1": 884,
        },
        "15.4": {
          "2": 241,
        },
        "16.2": {
          "2": 180,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 1,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 1350,
      TD: 4388,
      LEWICA: 1081,
    },
    potencjalneMandaty: {
      KO: 62805,
      TD: 33556,
      LEWICA: 27129,
    },
    ostatnieMandaty: {
      KO: 57039,
      TD: 30852,
      LEWICA: 8506,
    },
    dawcyGlosow: {
      KO: 3304,
      TD: 11883,
      LEWICA: 3399,
    },
  },
  Konin: {
    procentyWOkreguSrednia: {
      PIS: 41.474434281353396,
      KO: 23.07301064613137,
      TD: 13.296685653576628,
      KONF: 8.961194961005736,
      LEWICA: 13.194674457932871,
    },
    odchylenieWOkregu: {
      PIS: 4.858367520536441,
      KO: 1.4002746972692501,
      TD: 2.013774734692939,
      LEWICA: 2.3801641793005643,
      KONF: 1.449765513012304,
    },
    wykresRozkladu: {
      "84%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "6.7%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "3.7%": {
        KO: 2,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "2.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "1.5%": {
        KO: 2,
        LEWICA: 0,
        NIEDEMO: 6,
        TD: 1,
      },
      "0.6%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 0,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.1%": {
        KO: 1,
        LEWICA: 1,
        NIEDEMO: 6,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "84.8%": {
        DEMO: 4,
        NIEDEMO: 5,
      },
      "12.8%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
      "2.2%": {
        DEMO: 3,
        NIEDEMO: 6,
      },
    },
    histogram: {
      PIS: {
        "30": {
          "3": 23,
        },
        "31": {
          "3": 61,
        },
        "32": {
          "3": 119,
        },
        "33": {
          "3": 216,
          "4": 13,
        },
        "34": {
          "3": 258,
          "4": 163,
        },
        "35": {
          "3": 200,
          "4": 508,
        },
        "36": {
          "3": 100,
          "4": 1168,
        },
        "37": {
          "3": 42,
          "4": 1865,
        },
        "38": {
          "3": 1,
          "4": 2649,
        },
        "39": {
          "3": 1,
          "4": 3604,
          "5": 9,
        },
        "40": {
          "4": 3995,
          "5": 228,
        },
        "41": {
          "4": 4019,
          "5": 789,
        },
        "42": {
          "4": 3431,
          "5": 1486,
        },
        "43": {
          "4": 2458,
          "5": 2066,
        },
        "44": {
          "4": 1580,
          "5": 2172,
        },
        "45": {
          "4": 799,
          "5": 2044,
        },
        "46": {
          "4": 317,
          "5": 1444,
        },
        "47": {
          "4": 99,
          "5": 1007,
        },
        "48": {
          "4": 19,
          "5": 592,
          "6": 6,
        },
        "49": {
          "5": 242,
          "6": 12,
        },
        "50": {
          "5": 88,
          "6": 11,
        },
        "51": {
          "5": 33,
          "6": 11,
        },
        "52": {
          "5": 9,
          "6": 7,
        },
        "53": {
          "5": 1,
          "6": 2,
        },
        "54": {
          "6": 1,
        },
        "41.4": {
          "4": 3913,
          "5": 1035,
        },
        "43.8": {
          "4": 1660,
          "5": 2187,
        },
        "44.8": {
          "4": 999,
          "5": 2124,
        },
        "37.4": {
          "3": 17,
          "4": 2202,
        },
        "40.2": {
          "4": 4192,
          "5": 268,
        },
        "36.6": {
          "3": 56,
          "4": 1527,
        },
        "40.6": {
          "4": 4064,
          "5": 502,
        },
        "44.6": {
          "4": 1024,
          "5": 2149,
        },
        "41.6": {
          "4": 3752,
          "5": 1204,
        },
        "47.4": {
          "4": 57,
          "5": 783,
          "6": 2,
        },
        "39.8": {
          "4": 4050,
          "5": 132,
        },
        "46.4": {
          "4": 209,
          "5": 1342,
        },
        "42.6": {
          "4": 2817,
          "5": 1815,
        },
        "43.2": {
          "4": 2163,
          "5": 2064,
        },
        "37.2": {
          "3": 24,
          "4": 2090,
        },
        "42.2": {
          "4": 3170,
          "5": 1652,
        },
        "45.2": {
          "4": 654,
          "5": 2010,
        },
        "42.8": {
          "4": 2663,
          "5": 2038,
        },
        "36.2": {
          "3": 82,
          "4": 1215,
        },
        "43.4": {
          "4": 2136,
          "5": 2162,
        },
        "41.8": {
          "4": 3607,
          "5": 1351,
        },
        "44.2": {
          "4": 1391,
          "5": 2196,
        },
        "36.8": {
          "3": 42,
          "4": 1691,
        },
        "38.8": {
          "4": 3467,
          "5": 2,
        },
        "40.4": {
          "4": 4304,
          "5": 410,
        },
        "38.2": {
          "3": 7,
          "4": 2898,
        },
        "40.8": {
          "4": 4190,
          "5": 666,
        },
        "37.8": {
          "3": 8,
          "4": 2415,
        },
        "43.6": {
          "4": 1837,
          "5": 2180,
        },
        "36.4": {
          "3": 77,
          "4": 1344,
        },
        "39.6": {
          "4": 3978,
          "5": 104,
        },
        "48.2": {
          "4": 5,
          "5": 457,
          "6": 8,
        },
        "35.4": {
          "3": 157,
          "4": 708,
        },
        "38.6": {
          "3": 1,
          "4": 3257,
        },
        "45.8": {
          "4": 412,
          "5": 1646,
        },
        "44.4": {
          "4": 1218,
          "5": 2214,
        },
        "45.4": {
          "4": 539,
          "5": 1875,
        },
        "42.4": {
          "4": 2894,
          "5": 1785,
        },
        "33.6": {
          "3": 249,
          "4": 71,
        },
        "47.8": {
          "4": 22,
          "5": 590,
          "6": 6,
        },
        "48.8": {
          "4": 1,
          "5": 321,
          "6": 14,
        },
        "46.6": {
          "4": 148,
          "5": 1219,
        },
        "35.2": {
          "3": 194,
          "4": 606,
        },
        "46.2": {
          "4": 249,
          "5": 1392,
        },
        "31.4": {
          "3": 65,
        },
        "45.6": {
          "4": 483,
          "5": 1654,
        },
        "33.4": {
          "3": 240,
          "4": 44,
        },
        "41.2": {
          "4": 3996,
          "5": 932,
        },
        "38.4": {
          "3": 1,
          "4": 2996,
        },
        "39.2": {
          "4": 3772,
          "5": 23,
        },
        "32.2": {
          "3": 123,
        },
        "39.4": {
          "4": 3827,
          "5": 56,
        },
        "47.2": {
          "4": 88,
          "5": 877,
          "6": 1,
        },
        "34.2": {
          "3": 279,
          "4": 191,
        },
        "48.4": {
          "4": 7,
          "5": 421,
          "6": 8,
        },
        "35.8": {
          "3": 122,
          "4": 1004,
        },
        "34.6": {
          "3": 243,
          "4": 342,
        },
        "49.2": {
          "5": 227,
          "6": 9,
        },
        "50.2": {
          "5": 72,
          "6": 14,
        },
        "37.6": {
          "3": 23,
          "4": 2330,
        },
        "35.6": {
          "3": 147,
          "4": 851,
        },
        "46.8": {
          "4": 131,
          "5": 1097,
        },
        "49.4": {
          "5": 198,
          "6": 8,
        },
        "49.8": {
          "5": 96,
          "6": 6,
        },
        "33.8": {
          "3": 259,
          "4": 110,
        },
        "47.6": {
          "4": 44,
          "5": 704,
          "6": 6,
        },
        "29.4": {
          "2": 9,
          "3": 86,
        },
        "34.4": {
          "3": 228,
          "4": 293,
        },
        "34.8": {
          "3": 206,
          "4": 433,
        },
        "31.2": {
          "3": 64,
        },
        "31.6": {
          "3": 86,
          "4": 1,
        },
        "32.6": {
          "3": 179,
          "4": 4,
        },
        "51.4": {
          "5": 14,
          "6": 9,
        },
        "32.4": {
          "3": 155,
          "4": 4,
        },
        "50.6": {
          "5": 49,
          "6": 9,
        },
        "33.2": {
          "3": 209,
          "4": 28,
        },
        "48.6": {
          "4": 3,
          "5": 332,
          "6": 10,
        },
        "30.8": {
          "3": 48,
        },
        "49.6": {
          "5": 147,
          "6": 7,
        },
        "29.8": {
          "3": 18,
        },
        "31.8": {
          "3": 101,
        },
        "50.4": {
          "5": 54,
          "6": 14,
        },
        "30.4": {
          "3": 30,
        },
        "32.8": {
          "3": 193,
          "4": 12,
        },
        "50.8": {
          "5": 36,
          "6": 10,
        },
        "30.6": {
          "3": 40,
        },
        "29.6": {
          "3": 14,
        },
        "52.2": {
          "5": 8,
          "6": 7,
        },
        "30.2": {
          "3": 30,
        },
        "51.2": {
          "5": 26,
          "6": 6,
        },
        "52.4": {
          "5": 3,
          "6": 6,
        },
        "51.6": {
          "5": 16,
          "6": 9,
        },
        "52.6": {
          "5": 3,
        },
        "53.2": {
          "5": 1,
          "6": 2,
        },
        "53.8": {
          "5": 1,
          "6": 2,
        },
        "51.8": {
          "5": 6,
          "6": 9,
        },
        "52.8": {
          "5": 2,
          "6": 2,
        },
        "53.4": {
          "5": 3,
        },
      },
      KO: {
        "20": {
          "2": 1738,
        },
        "21": {
          "2": 4652,
        },
        "22": {
          "2": 8064,
        },
        "23": {
          "2": 9093,
        },
        "24": {
          "2": 7623,
          "3": 182,
        },
        "25": {
          "2": 4070,
          "3": 776,
        },
        "26": {
          "2": 1329,
          "3": 960,
        },
        "27": {
          "2": 293,
          "3": 610,
        },
        "23.4": {
          "2": 8989,
          "3": 8,
        },
        "24.8": {
          "2": 4563,
          "3": 699,
        },
        "24.2": {
          "2": 6858,
          "3": 324,
        },
        "20.8": {
          "2": 3931,
        },
        "25.6": {
          "2": 2155,
          "3": 976,
        },
        "22.8": {
          "2": 9101,
        },
        "19.6": {
          "1": 120,
          "2": 3580,
        },
        "26.6": {
          "2": 615,
          "3": 834,
        },
        "21.8": {
          "2": 7328,
        },
        "27.2": {
          "2": 528,
          "3": 2918,
        },
        "21.2": {
          "2": 5322,
        },
        "23.8": {
          "2": 7956,
          "3": 101,
        },
        "24.4": {
          "2": 6170,
          "3": 461,
        },
        "24.6": {
          "2": 5292,
          "3": 605,
        },
        "25.4": {
          "2": 2743,
          "3": 957,
        },
        "23.6": {
          "2": 8486,
          "3": 33,
        },
        "20.4": {
          "2": 2735,
        },
        "23.2": {
          "2": 9164,
          "3": 1,
        },
        "22.4": {
          "2": 8500,
        },
        "25.8": {
          "2": 1679,
          "3": 1020,
        },
        "22.2": {
          "2": 8167,
        },
        "22.6": {
          "2": 9000,
        },
        "21.6": {
          "2": 6707,
        },
        "20.6": {
          "2": 3359,
        },
        "25.2": {
          "2": 3383,
          "3": 914,
        },
        "26.4": {
          "2": 835,
          "3": 846,
        },
        "26.2": {
          "2": 1005,
          "3": 903,
        },
        "21.4": {
          "2": 6044,
        },
        "20.2": {
          "2": 2214,
        },
        "19.8": {
          "2": 1364,
        },
        "26.8": {
          "2": 404,
          "3": 713,
        },
      },
      TD: {
        "9": {
          "0": 119,
          "1": 524,
        },
        "10": {
          "1": 1880,
        },
        "11": {
          "1": 4198,
        },
        "12": {
          "1": 6704,
        },
        "13": {
          "1": 8171,
        },
        "14": {
          "1": 7792,
        },
        "15": {
          "1": 5639,
        },
        "16": {
          "1": 2946,
          "2": 119,
        },
        "17": {
          "1": 869,
          "2": 463,
        },
        "18": {
          "1": 114,
          "2": 327,
        },
        "19": {
          "1": 1,
          "2": 102,
        },
        "13.2": {
          "1": 8398,
        },
        "11.2": {
          "1": 4483,
        },
        "13.4": {
          "1": 8246,
        },
        "11.8": {
          "1": 6197,
        },
        "16.2": {
          "1": 2393,
          "2": 211,
        },
        "16.4": {
          "1": 1875,
          "2": 289,
        },
        "13.6": {
          "1": 8219,
        },
        "15.2": {
          "1": 5004,
        },
        "12.8": {
          "1": 8170,
        },
        "10.8": {
          "1": 3492,
        },
        "12.6": {
          "1": 7898,
        },
        "8.6": {
          "0": 224,
          "1": 173,
        },
        "14.2": {
          "1": 7575,
        },
        "13.8": {
          "1": 8024,
        },
        "17.4": {
          "1": 381,
          "2": 462,
        },
        "12.2": {
          "1": 7014,
        },
        "11.4": {
          "1": 5057,
        },
        "14.6": {
          "1": 6404,
        },
        "8.2": {
          "0": 844,
          "1": 38,
        },
        "10.6": {
          "1": 3170,
        },
        "14.4": {
          "1": 6964,
        },
        "16.6": {
          "1": 1558,
          "2": 380,
        },
        "8.8": {
          "0": 172,
          "1": 343,
        },
        "15.6": {
          "1": 4066,
          "2": 8,
        },
        "11.6": {
          "1": 5631,
        },
        "14.8": {
          "1": 6131,
        },
        "15.8": {
          "1": 3404,
          "2": 51,
        },
        "12.4": {
          "1": 7451,
        },
        "16.8": {
          "1": 1118,
          "2": 450,
        },
        "17.6": {
          "1": 284,
          "2": 449,
        },
        "8.4": {
          "0": 239,
          "1": 71,
        },
        "9.8": {
          "1": 1540,
        },
        "9.2": {
          "0": 68,
          "1": 791,
        },
        "10.2": {
          "1": 2220,
        },
        "15.4": {
          "1": 4614,
        },
        "19.4": {
          "2": 213,
        },
        "10.4": {
          "1": 2649,
        },
        "18.6": {
          "1": 18,
          "2": 203,
        },
        "9.6": {
          "0": 8,
          "1": 1258,
        },
        "17.2": {
          "1": 596,
          "2": 445,
        },
        "9.4": {
          "0": 21,
          "1": 997,
        },
        "17.8": {
          "1": 153,
          "2": 381,
        },
        "18.4": {
          "1": 31,
          "2": 254,
        },
        "18.2": {
          "1": 59,
          "2": 270,
        },
        "19.2": {
          "2": 98,
        },
        "18.8": {
          "1": 8,
          "2": 126,
        },
      },
      KONF: {
        "6": {
          "0": 1207,
        },
        "7": {
          "0": 4352,
        },
        "8": {
          "0": 8598,
          "1": 363,
        },
        "9": {
          "0": 1369,
          "1": 9970,
        },
        "10": {
          "1": 8624,
        },
        "11": {
          "1": 4138,
        },
        "12": {
          "1": 1231,
        },
        "13": {
          "1": 253,
        },
        "9.6": {
          "0": 33,
          "1": 9847,
        },
        "10.6": {
          "1": 5723,
        },
        "8.2": {
          "0": 8208,
          "1": 1632,
        },
        "9.2": {
          "0": 561,
          "1": 10409,
        },
        "9.8": {
          "0": 1,
          "1": 9245,
        },
        "9.4": {
          "0": 144,
          "1": 10341,
        },
        "8.4": {
          "0": 6717,
          "1": 3745,
        },
        "10.8": {
          "1": 4964,
        },
        "10.2": {
          "1": 7599,
        },
        "6.4": {
          "0": 2200,
        },
        "7.4": {
          "0": 6175,
        },
        "7.2": {
          "0": 5125,
        },
        "10.4": {
          "1": 6632,
        },
        "12.2": {
          "1": 905,
        },
        "7.6": {
          "0": 7018,
        },
        "8.8": {
          "0": 2843,
          "1": 8328,
        },
        "6.8": {
          "0": 3498,
        },
        "7.8": {
          "0": 8140,
          "1": 22,
        },
        "11.6": {
          "1": 2056,
        },
        "6.6": {
          "0": 2787,
        },
        "6.2": {
          "0": 1700,
        },
        "8.6": {
          "0": 4688,
          "1": 6222,
        },
        "12.4": {
          "1": 621,
        },
        "11.2": {
          "1": 3195,
        },
        "11.4": {
          "1": 2702,
        },
        "5.4": {
          "0": 1323,
        },
        "13.4": {
          "1": 371,
        },
        "11.8": {
          "1": 1617,
        },
        "5.6": {
          "0": 669,
        },
        "5.8": {
          "0": 892,
        },
        "12.6": {
          "1": 451,
        },
        "12.8": {
          "1": 367,
        },
        "13.2": {
          "1": 179,
        },
      },
      LEWICA: {
        "8": {
          "0": 474,
          "1": 19,
        },
        "9": {
          "0": 216,
          "1": 956,
        },
        "10": {
          "1": 2508,
        },
        "11": {
          "1": 4408,
        },
        "12": {
          "1": 6253,
        },
        "13": {
          "1": 7223,
        },
        "14": {
          "1": 6840,
        },
        "15": {
          "1": 5103,
        },
        "16": {
          "1": 3133,
          "2": 135,
        },
        "17": {
          "1": 948,
          "2": 616,
        },
        "18": {
          "1": 178,
          "2": 478,
        },
        "19": {
          "1": 4,
          "2": 246,
        },
        "20": {
          "2": 67,
        },
        "12.4": {
          "1": 6803,
        },
        "10.4": {
          "1": 3186,
        },
        "11.2": {
          "1": 4755,
        },
        "8.8": {
          "0": 321,
          "1": 661,
        },
        "14.2": {
          "1": 6569,
        },
        "13.2": {
          "1": 7318,
        },
        "10.6": {
          "1": 3538,
        },
        "15.4": {
          "1": 4379,
        },
        "12.6": {
          "1": 6994,
        },
        "9.6": {
          "0": 13,
          "1": 1866,
        },
        "12.2": {
          "1": 6625,
        },
        "12.8": {
          "1": 7283,
        },
        "17.4": {
          "1": 556,
          "2": 590,
        },
        "11.4": {
          "1": 5219,
        },
        "13.8": {
          "1": 6993,
        },
        "14.4": {
          "1": 6138,
        },
        "15.6": {
          "1": 3957,
          "2": 6,
        },
        "15.8": {
          "1": 3504,
          "2": 46,
        },
        "8.4": {
          "0": 498,
          "1": 184,
        },
        "11.6": {
          "1": 5694,
        },
        "15.2": {
          "1": 4816,
        },
        "13.4": {
          "1": 7274,
        },
        "9.8": {
          "0": 2,
          "1": 2129,
        },
        "10.8": {
          "1": 3946,
        },
        "10.2": {
          "1": 2851,
        },
        "9.2": {
          "0": 108,
          "1": 1319,
        },
        "13.6": {
          "1": 7064,
        },
        "16.2": {
          "1": 2586,
          "2": 235,
        },
        "14.8": {
          "1": 5625,
        },
        "11.8": {
          "1": 5815,
        },
        "16.4": {
          "1": 2104,
          "2": 340,
        },
        "17.2": {
          "1": 696,
          "2": 661,
        },
        "18.8": {
          "1": 15,
          "2": 279,
        },
        "18.2": {
          "1": 100,
          "2": 468,
        },
        "14.6": {
          "1": 5885,
        },
        "17.8": {
          "1": 235,
          "2": 592,
        },
        "16.6": {
          "1": 1724,
          "2": 487,
        },
        "9.4": {
          "0": 39,
          "1": 1676,
        },
        "8.6": {
          "0": 436,
          "1": 420,
        },
        "7.6": {
          "0": 297,
        },
        "17.6": {
          "1": 354,
          "2": 595,
        },
        "8.2": {
          "0": 523,
          "1": 72,
        },
        "7.2": {
          "0": 805,
        },
        "16.8": {
          "1": 1283,
          "2": 516,
        },
        "7.4": {
          "0": 225,
        },
        "18.6": {
          "1": 31,
          "2": 322,
        },
        "18.4": {
          "1": 54,
          "2": 432,
        },
        "19.2": {
          "1": 1,
          "2": 186,
        },
        "19.8": {
          "2": 79,
        },
        "7.8": {
          "0": 378,
          "1": 5,
        },
        "20.4": {
          "2": 132,
        },
        "20.2": {
          "2": 49,
        },
        "19.4": {
          "1": 1,
          "2": 132,
        },
        "19.6": {
          "1": 2,
          "2": 98,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 989,
      TD: 1381,
      LEWICA: 1946,
    },
    potencjalneMandaty: {
      KO: 33359,
      TD: 17734,
      LEWICA: 21232,
    },
    ostatnieMandaty: {
      KO: 14533,
      TD: 8618,
      LEWICA: 13053,
    },
    dawcyGlosow: {
      KO: 3214,
      TD: 4547,
      LEWICA: 5981,
    },
  },
  Piła: {
    procentyWOkreguSrednia: {
      PIS: 29.83860846372579,
      KO: 33.220196179334344,
      TD: 17.672977621570283,
      KONF: 8.522967000672,
      LEWICA: 10.745250734697585,
    },
    odchylenieWOkregu: {
      PIS: 2.927018676957331,
      KO: 3.4717648581025973,
      TD: 2.013774734692939,
      LEWICA: 2.1044353893271466,
      KONF: 1.1801901874716998,
    },
    wykresRozkladu: {
      "37.2%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "25.4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "17.3%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "6.9%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 2,
      },
      "5%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 2,
      },
      "3.3%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "2.8%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 2,
      },
      "1.4%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.5%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.1%": {
        KO: 5,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "59.6%": {
        DEMO: 6,
        NIEDEMO: 3,
      },
      "37%": {
        DEMO: 5,
        NIEDEMO: 4,
      },
      "2.9%": {
        DEMO: 7,
        NIEDEMO: 2,
      },
    },
    histogram: {
      PIS: {
        "23": {
          "2": 121,
        },
        "24": {
          "2": 388,
          "3": 4,
        },
        "25": {
          "2": 875,
          "3": 66,
        },
        "26": {
          "2": 1298,
          "3": 602,
        },
        "27": {
          "2": 903,
          "3": 2545,
        },
        "28": {
          "2": 272,
          "3": 4585,
        },
        "29": {
          "2": 71,
          "3": 6027,
        },
        "30": {
          "3": 6392,
        },
        "31": {
          "3": 5811,
        },
        "32": {
          "3": 4264,
          "4": 46,
        },
        "33": {
          "3": 2578,
          "4": 292,
        },
        "34": {
          "3": 1057,
          "4": 555,
        },
        "35": {
          "3": 292,
          "4": 442,
        },
        "36": {
          "3": 47,
          "4": 275,
        },
        "37": {
          "3": 8,
          "4": 117,
        },
        "38": {
          "4": 38,
        },
        "29.4": {
          "2": 17,
          "3": 6355,
        },
        "31.6": {
          "3": 4986,
          "4": 10,
        },
        "27.6": {
          "2": 462,
          "3": 3845,
        },
        "33.2": {
          "3": 2240,
          "4": 358,
        },
        "33.6": {
          "3": 1565,
          "4": 477,
        },
        "29.8": {
          "3": 6424,
        },
        "30.4": {
          "3": 6279,
        },
        "32.6": {
          "3": 3216,
          "4": 160,
        },
        "31.2": {
          "3": 5445,
          "4": 4,
        },
        "28.4": {
          "2": 201,
          "3": 5238,
        },
        "28.6": {
          "2": 149,
          "3": 5561,
        },
        "26.8": {
          "2": 1083,
          "3": 2018,
        },
        "29.6": {
          "2": 3,
          "3": 6495,
        },
        "28.8": {
          "2": 92,
          "3": 5849,
        },
        "29.2": {
          "2": 41,
          "3": 6161,
        },
        "27.2": {
          "2": 712,
          "3": 2859,
        },
        "28.2": {
          "2": 223,
          "3": 5015,
        },
        "32.2": {
          "3": 3927,
          "4": 86,
        },
        "27.4": {
          "2": 566,
          "3": 3370,
        },
        "27.8": {
          "2": 370,
          "3": 4145,
        },
        "30.6": {
          "3": 6234,
        },
        "25.4": {
          "2": 1026,
          "3": 176,
        },
        "26.4": {
          "2": 1323,
          "3": 1220,
        },
        "26.6": {
          "2": 1185,
          "3": 1554,
        },
        "26.2": {
          "2": 1360,
          "3": 848,
        },
        "31.4": {
          "3": 5179,
          "4": 7,
        },
        "32.4": {
          "3": 3641,
          "4": 106,
        },
        "35.4": {
          "3": 149,
          "4": 378,
        },
        "30.2": {
          "3": 6319,
        },
        "34.2": {
          "3": 848,
          "4": 557,
        },
        "33.8": {
          "3": 1235,
          "4": 545,
        },
        "25.8": {
          "2": 1297,
          "3": 431,
        },
        "33.4": {
          "3": 1900,
          "4": 403,
        },
        "23.2": {
          "2": 184,
        },
        "31.8": {
          "3": 4703,
          "4": 28,
        },
        "32.8": {
          "3": 2908,
          "4": 204,
        },
        "34.4": {
          "3": 644,
          "4": 556,
        },
        "30.8": {
          "3": 5910,
        },
        "25.6": {
          "2": 1132,
          "3": 246,
        },
        "36.2": {
          "3": 39,
          "4": 245,
        },
        "24.6": {
          "2": 661,
          "3": 17,
        },
        "25.2": {
          "2": 1032,
          "3": 103,
        },
        "34.6": {
          "3": 503,
          "4": 486,
        },
        "35.6": {
          "3": 122,
          "4": 310,
        },
        "24.4": {
          "2": 603,
          "3": 7,
        },
        "34.8": {
          "3": 442,
          "4": 488,
        },
        "23.8": {
          "2": 348,
          "3": 1,
        },
        "37.4": {
          "3": 1,
          "4": 52,
        },
        "24.8": {
          "2": 776,
          "3": 44,
        },
        "36.4": {
          "3": 19,
          "4": 175,
        },
        "36.8": {
          "3": 5,
          "4": 122,
        },
        "35.2": {
          "3": 232,
          "4": 448,
        },
        "35.8": {
          "3": 79,
          "4": 254,
        },
        "36.6": {
          "3": 7,
          "4": 151,
        },
        "23.6": {
          "2": 264,
        },
        "22.8": {
          "2": 116,
        },
        "22.6": {
          "2": 427,
        },
        "37.6": {
          "4": 50,
        },
        "24.2": {
          "2": 465,
          "3": 3,
        },
        "37.2": {
          "3": 4,
          "4": 99,
        },
        "38.6": {
          "4": 70,
          "5": 1,
        },
        "23.4": {
          "2": 225,
        },
        "37.8": {
          "4": 38,
        },
        "38.4": {
          "4": 21,
        },
        "38.2": {
          "4": 31,
        },
      },
      KO: {
        "25": {
          "2": 80,
          "3": 8,
        },
        "26": {
          "2": 115,
          "3": 55,
        },
        "27": {
          "2": 148,
          "3": 300,
        },
        "28": {
          "2": 132,
          "3": 846,
        },
        "29": {
          "2": 49,
          "3": 1708,
        },
        "30": {
          "3": 2968,
        },
        "31": {
          "3": 4212,
        },
        "32": {
          "3": 5191,
          "4": 58,
        },
        "33": {
          "3": 5473,
          "4": 419,
        },
        "34": {
          "3": 4229,
          "4": 1301,
        },
        "35": {
          "3": 2391,
          "4": 2363,
        },
        "36": {
          "3": 742,
          "4": 2836,
        },
        "37": {
          "3": 77,
          "4": 2115,
        },
        "38": {
          "3": 1,
          "4": 1217,
        },
        "39": {
          "4": 583,
        },
        "40": {
          "4": 272,
          "5": 2,
        },
        "41": {
          "4": 83,
          "5": 11,
        },
        "42": {
          "4": 23,
          "5": 10,
        },
        "43": {
          "4": 2,
          "5": 2,
        },
        "33.6": {
          "3": 4957,
          "4": 970,
        },
        "31.6": {
          "3": 4779,
          "4": 18,
        },
        "29.8": {
          "2": 2,
          "3": 2637,
        },
        "29.4": {
          "2": 16,
          "3": 2111,
        },
        "30.6": {
          "3": 3687,
        },
        "32.2": {
          "3": 5361,
          "4": 95,
        },
        "34.6": {
          "3": 3189,
          "4": 1984,
        },
        "31.8": {
          "3": 5035,
          "4": 25,
        },
        "26.6": {
          "2": 171,
          "3": 179,
        },
        "32.4": {
          "3": 5535,
          "4": 141,
        },
        "33.2": {
          "3": 5286,
          "4": 593,
        },
        "31.4": {
          "3": 4666,
          "4": 5,
        },
        "36.4": {
          "3": 328,
          "4": 2690,
        },
        "32.6": {
          "3": 5450,
          "4": 233,
        },
        "28.4": {
          "2": 117,
          "3": 1175,
        },
        "32.8": {
          "3": 5552,
          "4": 313,
        },
        "42.2": {
          "4": 14,
          "5": 8,
        },
        "34.2": {
          "3": 3751,
          "4": 1592,
        },
        "36.8": {
          "3": 122,
          "4": 2343,
        },
        "35.2": {
          "3": 2076,
          "4": 2544,
        },
        "33.8": {
          "3": 4579,
          "4": 1220,
        },
        "24.6": {
          "2": 244,
        },
        "35.4": {
          "3": 1587,
          "4": 2621,
        },
        "38.4": {
          "4": 932,
        },
        "27.8": {
          "2": 164,
          "3": 687,
        },
        "33.4": {
          "3": 4929,
          "4": 749,
        },
        "37.4": {
          "3": 25,
          "4": 1797,
        },
        "37.8": {
          "3": 14,
          "4": 1455,
        },
        "36.2": {
          "3": 530,
          "4": 2794,
        },
        "31.2": {
          "3": 4417,
          "4": 2,
        },
        "30.4": {
          "3": 3332,
        },
        "35.8": {
          "3": 1012,
          "4": 2698,
        },
        "35.6": {
          "3": 1305,
          "4": 2676,
        },
        "34.8": {
          "3": 2786,
          "4": 2150,
        },
        "30.2": {
          "3": 3129,
        },
        "36.6": {
          "3": 201,
          "4": 2470,
        },
        "29.2": {
          "2": 26,
          "3": 1937,
        },
        "37.2": {
          "3": 49,
          "4": 1887,
        },
        "30.8": {
          "3": 3804,
        },
        "28.6": {
          "2": 76,
          "3": 1295,
        },
        "34.4": {
          "3": 3568,
          "4": 1856,
        },
        "38.2": {
          "3": 4,
          "4": 1027,
        },
        "29.6": {
          "2": 1,
          "3": 2384,
        },
        "28.8": {
          "2": 66,
          "3": 1545,
        },
        "26.4": {
          "2": 161,
          "3": 124,
        },
        "27.6": {
          "2": 158,
          "3": 573,
        },
        "37.6": {
          "3": 11,
          "4": 1522,
        },
        "40.2": {
          "4": 184,
          "5": 11,
        },
        "38.8": {
          "4": 725,
        },
        "28.2": {
          "2": 112,
          "3": 974,
        },
        "39.2": {
          "4": 511,
          "5": 1,
        },
        "38.6": {
          "3": 1,
          "4": 848,
        },
        "41.2": {
          "4": 70,
          "5": 7,
        },
        "27.4": {
          "2": 167,
          "3": 459,
        },
        "39.6": {
          "4": 365,
          "5": 4,
        },
        "40.6": {
          "4": 138,
          "5": 16,
        },
        "27.2": {
          "2": 170,
          "3": 378,
        },
        "24.8": {
          "2": 60,
          "3": 5,
        },
        "25.8": {
          "2": 128,
          "3": 47,
        },
        "39.4": {
          "4": 419,
          "5": 2,
        },
        "26.2": {
          "2": 152,
          "3": 111,
        },
        "25.6": {
          "2": 109,
          "3": 23,
        },
        "39.8": {
          "4": 298,
          "5": 6,
        },
        "40.4": {
          "4": 166,
          "5": 11,
        },
        "41.8": {
          "4": 38,
          "5": 8,
        },
        "41.4": {
          "4": 46,
          "5": 14,
        },
        "40.8": {
          "4": 103,
          "5": 9,
        },
        "25.2": {
          "2": 85,
          "3": 13,
        },
        "25.4": {
          "2": 118,
          "3": 14,
        },
        "26.8": {
          "2": 177,
          "3": 233,
        },
        "42.8": {
          "4": 7,
          "5": 5,
        },
        "43.2": {
          "4": 6,
          "5": 4,
        },
        "41.6": {
          "4": 35,
          "5": 11,
        },
        "43.6": {
          "4": 1,
          "5": 6,
        },
        "42.6": {
          "4": 4,
          "5": 7,
        },
        "42.4": {
          "4": 11,
          "5": 9,
        },
        "43.4": {
          "4": 3,
          "5": 4,
        },
      },
      TD: {
        "13": {
          "1": 387,
        },
        "14": {
          "1": 1287,
        },
        "15": {
          "1": 3136,
        },
        "16": {
          "1": 5694,
          "2": 80,
        },
        "17": {
          "1": 5651,
          "2": 2282,
        },
        "18": {
          "1": 2105,
          "2": 6318,
        },
        "19": {
          "1": 297,
          "2": 6255,
        },
        "20": {
          "2": 4033,
        },
        "21": {
          "2": 1739,
        },
        "22": {
          "2": 671,
        },
        "23": {
          "2": 213,
        },
        "17.8": {
          "1": 2774,
          "2": 5593,
        },
        "21.4": {
          "2": 1221,
        },
        "18.8": {
          "1": 473,
          "2": 6462,
        },
        "20.2": {
          "2": 3376,
        },
        "16.6": {
          "1": 6279,
          "2": 909,
        },
        "18.2": {
          "1": 1489,
          "2": 6643,
        },
        "20.4": {
          "2": 3019,
        },
        "19.6": {
          "1": 23,
          "2": 5068,
        },
        "19.2": {
          "1": 142,
          "2": 5792,
        },
        "18.4": {
          "1": 1082,
          "2": 6721,
        },
        "17.2": {
          "1": 5053,
          "2": 3023,
        },
        "20.8": {
          "2": 2189,
        },
        "15.8": {
          "1": 5091,
          "2": 24,
        },
        "16.2": {
          "1": 6079,
          "2": 213,
        },
        "14.6": {
          "1": 2241,
        },
        "17.6": {
          "1": 3744,
          "2": 4781,
        },
        "18.6": {
          "1": 722,
          "2": 6573,
        },
        "16.8": {
          "1": 6118,
          "2": 1479,
        },
        "19.4": {
          "1": 62,
          "2": 5361,
        },
        "16.4": {
          "1": 6298,
          "2": 424,
        },
        "21.8": {
          "2": 845,
        },
        "14.8": {
          "1": 2632,
        },
        "21.6": {
          "2": 1013,
        },
        "20.6": {
          "2": 2571,
        },
        "15.4": {
          "1": 3997,
        },
        "14.4": {
          "1": 1889,
        },
        "17.4": {
          "1": 4398,
          "2": 3936,
        },
        "15.2": {
          "1": 3620,
        },
        "19.8": {
          "2": 4410,
        },
        "15.6": {
          "1": 4824,
          "2": 4,
        },
        "21.2": {
          "2": 1488,
        },
        "22.2": {
          "2": 485,
        },
        "14.2": {
          "1": 1557,
        },
        "22.8": {
          "2": 272,
        },
        "13.6": {
          "1": 849,
        },
        "22.4": {
          "2": 404,
        },
        "12.8": {
          "1": 282,
        },
        "13.2": {
          "1": 467,
        },
        "13.8": {
          "1": 1048,
        },
        "12.6": {
          "1": 812,
        },
        "23.8": {
          "2": 223,
          "3": 15,
        },
        "13.4": {
          "1": 617,
        },
        "23.4": {
          "2": 101,
        },
        "22.6": {
          "2": 317,
        },
        "23.2": {
          "2": 150,
          "3": 1,
        },
        "23.6": {
          "2": 84,
        },
      },
      KONF: {
        "6": {
          "0": 1343,
        },
        "7": {
          "0": 5686,
        },
        "8": {
          "0": 12148,
          "1": 121,
        },
        "9": {
          "0": 3717,
          "1": 8665,
        },
        "10": {
          "1": 5969,
        },
        "11": {
          "1": 1620,
        },
        "12": {
          "1": 549,
        },
        "7.2": {
          "0": 7293,
        },
        "6.4": {
          "0": 2553,
        },
        "10.2": {
          "1": 4896,
        },
        "8.4": {
          "0": 11617,
          "1": 2129,
        },
        "7.8": {
          "0": 11278,
          "1": 8,
        },
        "9.2": {
          "0": 1787,
          "1": 9559,
        },
        "8.8": {
          "0": 6563,
          "1": 6601,
        },
        "9.6": {
          "0": 239,
          "1": 8636,
        },
        "8.6": {
          "0": 9274,
          "1": 4154,
        },
        "7.4": {
          "0": 8648,
        },
        "10.8": {
          "1": 2225,
        },
        "9.8": {
          "0": 31,
          "1": 7389,
        },
        "7.6": {
          "0": 10063,
        },
        "10.6": {
          "1": 2966,
        },
        "8.2": {
          "0": 12673,
          "1": 612,
        },
        "9.4": {
          "0": 790,
          "1": 9425,
        },
        "10.4": {
          "1": 3845,
        },
        "6.6": {
          "0": 3464,
        },
        "6.8": {
          "0": 4628,
        },
        "6.2": {
          "0": 1830,
        },
        "5.6": {
          "0": 1388,
        },
        "11.2": {
          "1": 1089,
        },
        "11.6": {
          "1": 530,
        },
        "11.8": {
          "1": 365,
        },
        "11.4": {
          "1": 734,
        },
        "5.8": {
          "0": 900,
        },
      },
      LEWICA: {
        "6": {
          "0": 490,
        },
        "7": {
          "0": 1357,
        },
        "8": {
          "0": 3002,
          "1": 33,
        },
        "9": {
          "0": 1187,
          "1": 4121,
        },
        "10": {
          "1": 7594,
        },
        "11": {
          "1": 8233,
        },
        "12": {
          "1": 6786,
        },
        "13": {
          "1": 4192,
        },
        "14": {
          "1": 2040,
        },
        "15": {
          "1": 694,
        },
        "16": {
          "1": 193,
          "2": 10,
        },
        "17": {
          "1": 87,
          "2": 86,
        },
        "11.8": {
          "1": 6963,
        },
        "8.2": {
          "0": 3259,
          "1": 214,
        },
        "10.8": {
          "1": 8297,
        },
        "7.4": {
          "0": 2011,
        },
        "12.4": {
          "1": 5862,
        },
        "11.2": {
          "1": 7824,
        },
        "11.6": {
          "1": 7559,
        },
        "9.4": {
          "0": 277,
          "1": 6054,
        },
        "8.8": {
          "0": 2013,
          "1": 2916,
        },
        "8.4": {
          "0": 3176,
          "1": 777,
        },
        "9.2": {
          "0": 594,
          "1": 5493,
        },
        "12.6": {
          "1": 5250,
        },
        "10.6": {
          "1": 8036,
        },
        "10.2": {
          "1": 7888,
        },
        "11.4": {
          "1": 7608,
        },
        "14.4": {
          "1": 1438,
        },
        "13.6": {
          "1": 2794,
        },
        "8.6": {
          "0": 2667,
          "1": 1679,
        },
        "12.8": {
          "1": 4700,
        },
        "10.4": {
          "1": 7993,
        },
        "13.8": {
          "1": 2396,
        },
        "7.6": {
          "0": 2345,
        },
        "14.2": {
          "1": 1646,
        },
        "12.2": {
          "1": 6183,
        },
        "9.6": {
          "0": 104,
          "1": 6674,
        },
        "15.2": {
          "1": 609,
        },
        "6.2": {
          "0": 558,
        },
        "9.8": {
          "0": 19,
          "1": 7011,
        },
        "15.6": {
          "1": 399,
        },
        "14.8": {
          "1": 963,
        },
        "5.4": {
          "0": 899,
        },
        "16.4": {
          "1": 100,
          "2": 17,
        },
        "7.8": {
          "0": 2597,
          "1": 2,
        },
        "7.2": {
          "0": 1652,
        },
        "13.4": {
          "1": 3228,
        },
        "14.6": {
          "1": 1131,
        },
        "6.8": {
          "0": 1060,
        },
        "6.4": {
          "0": 715,
        },
        "15.8": {
          "1": 282,
          "2": 3,
        },
        "13.2": {
          "1": 3744,
        },
        "16.2": {
          "1": 134,
          "2": 9,
        },
        "16.8": {
          "1": 54,
          "2": 14,
        },
        "6.6": {
          "0": 890,
        },
        "5.8": {
          "0": 341,
        },
        "5.6": {
          "0": 240,
        },
        "15.4": {
          "1": 452,
        },
        "16.6": {
          "1": 65,
          "2": 17,
        },
      },
    },
    minimumMandatow: {
      KO: 2,
      TD: 1,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2407,
      TD: 4759,
      LEWICA: 5372,
    },
    potencjalneMandaty: {
      KO: 37970,
      TD: 54177,
      LEWICA: 26534,
    },
    ostatnieMandaty: {
      KO: 45937,
      TD: 53834,
      LEWICA: 16941,
    },
    dawcyGlosow: {
      KO: 6122,
      TD: 10389,
      LEWICA: 14823,
    },
  },
  Poznań: {
    procentyWOkreguSrednia: {
      PIS: 19.067852333357727,
      KO: 47.81292973442876,
      TD: 11.626777386690101,
      KONF: 6.995743601635331,
      LEWICA: 14.496696943888074,
    },
    odchylenieWOkregu: {
      PIS: 5.355517368596007,
      KO: 3.386160172198673,
      TD: 2.013774734692939,
      LEWICA: 1.8488097948617699,
      KONF: 1.7538849721546788,
    },
    wykresRozkladu: {
      "39.9%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 1,
      },
      "28.8%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "12.9%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 2,
        TD: 1,
      },
      "8.3%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 1,
        TD: 1,
      },
      "2.9%": {
        KO: 7,
        LEWICA: 1,
        NIEDEMO: 1,
        TD: 1,
      },
      "1.1%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 2,
      },
      "0.9%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 1,
        TD: 2,
      },
      "0.7%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 2,
        TD: 0,
      },
      "0.6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.5%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: null,
        TD: 1,
      },
      "0.4%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
    },
    wykresRozkladuDemo: {
      "54.8%": {
        DEMO: 8,
        NIEDEMO: 2,
      },
      "30.7%": {
        DEMO: 7,
        NIEDEMO: 3,
      },
      "12.3%": {
        DEMO: 9,
        NIEDEMO: 1,
      },
    },
    histogram: {
      PIS: {
        "6": {
          "0": 84,
        },
        "7": {
          "0": 138,
        },
        "8": {
          "0": 88,
          "1": 114,
        },
        "9": {
          "0": 1,
          "1": 361,
        },
        "10": {
          "1": 505,
        },
        "11": {
          "1": 780,
        },
        "12": {
          "1": 1028,
        },
        "13": {
          "1": 1353,
        },
        "14": {
          "1": 1807,
        },
        "15": {
          "1": 2086,
          "2": 231,
        },
        "16": {
          "1": 1062,
          "2": 1652,
        },
        "17": {
          "1": 80,
          "2": 2947,
        },
        "18": {
          "1": 2,
          "2": 3458,
        },
        "19": {
          "2": 3532,
        },
        "20": {
          "2": 3646,
        },
        "21": {
          "2": 3352,
        },
        "22": {
          "2": 3033,
          "3": 39,
        },
        "23": {
          "2": 1943,
          "3": 697,
        },
        "24": {
          "2": 574,
          "3": 1410,
        },
        "25": {
          "2": 107,
          "3": 1351,
        },
        "26": {
          "2": 6,
          "3": 983,
        },
        "27": {
          "3": 664,
        },
        "28": {
          "3": 386,
        },
        "29": {
          "3": 184,
          "4": 1,
        },
        "30": {
          "3": 108,
          "4": 5,
        },
        "31": {
          "3": 35,
          "4": 8,
        },
        "32": {
          "3": 10,
          "4": 10,
        },
        "33": {
          "3": 1,
          "4": 10,
        },
        "34": {
          "4": 1,
        },
        "35": {
          "4": 2,
        },
        "19.6": {
          "2": 3635,
        },
        "15.2": {
          "1": 1904,
          "2": 394,
        },
        "9.8": {
          "1": 465,
        },
        "18.6": {
          "2": 3591,
        },
        "24.4": {
          "2": 299,
          "3": 1414,
        },
        "22.4": {
          "2": 2665,
          "3": 201,
        },
        "21.4": {
          "2": 3216,
        },
        "19.8": {
          "2": 3711,
        },
        "13.2": {
          "1": 1461,
        },
        "23.4": {
          "2": 1200,
          "3": 1045,
        },
        "6.8": {
          "0": 148,
        },
        "17.6": {
          "1": 5,
          "2": 3371,
        },
        "18.2": {
          "2": 3480,
        },
        "17.2": {
          "1": 39,
          "2": 3246,
        },
        "25.4": {
          "2": 42,
          "3": 1244,
        },
        "16.2": {
          "1": 767,
          "2": 1956,
        },
        "20.8": {
          "2": 3503,
        },
        "20.4": {
          "2": 3466,
        },
        "21.6": {
          "2": 3211,
          "3": 2,
        },
        "15.8": {
          "1": 1427,
          "2": 1233,
        },
        "18.8": {
          "2": 3647,
        },
        "19.4": {
          "2": 3691,
        },
        "18.4": {
          "2": 3544,
        },
        "16.8": {
          "1": 165,
          "2": 2808,
        },
        "16.6": {
          "1": 280,
          "2": 2711,
        },
        "22.8": {
          "2": 2181,
          "3": 530,
        },
        "17.8": {
          "1": 2,
          "2": 3324,
        },
        "7.2": {
          "0": 178,
        },
        "25.8": {
          "2": 13,
          "3": 1054,
        },
        "14.8": {
          "1": 2162,
          "2": 91,
        },
        "24.6": {
          "2": 232,
          "3": 1414,
        },
        "13.6": {
          "1": 1623,
        },
        "23.6": {
          "2": 944,
          "3": 1226,
        },
        "11.4": {
          "1": 870,
        },
        "13.8": {
          "1": 1656,
        },
        "12.8": {
          "1": 1332,
        },
        "19.2": {
          "2": 3478,
        },
        "10.2": {
          "1": 565,
        },
        "12.6": {
          "1": 1231,
        },
        "20.2": {
          "2": 3525,
        },
        "6.4": {
          "0": 118,
        },
        "24.2": {
          "2": 452,
          "3": 1475,
        },
        "21.8": {
          "2": 3017,
          "3": 9,
        },
        "24.8": {
          "2": 153,
          "3": 1426,
        },
        "20.6": {
          "2": 3498,
        },
        "27.6": {
          "3": 489,
        },
        "11.8": {
          "1": 962,
        },
        "15.6": {
          "1": 1682,
          "2": 951,
        },
        "14.6": {
          "1": 2090,
          "2": 18,
        },
        "15.4": {
          "1": 1841,
          "2": 612,
        },
        "25.6": {
          "2": 21,
          "3": 1155,
        },
        "16.4": {
          "1": 515,
          "2": 2361,
        },
        "12.4": {
          "1": 1185,
        },
        "22.2": {
          "2": 2764,
          "3": 93,
        },
        "26.2": {
          "2": 4,
          "3": 875,
        },
        "12.2": {
          "1": 1085,
        },
        "23.2": {
          "2": 1525,
          "3": 854,
        },
        "21.2": {
          "2": 3251,
        },
        "25.2": {
          "2": 91,
          "3": 1293,
        },
        "26.6": {
          "2": 2,
          "3": 760,
        },
        "14.2": {
          "1": 1922,
        },
        "23.8": {
          "2": 738,
          "3": 1342,
        },
        "6.6": {
          "0": 128,
        },
        "10.4": {
          "1": 570,
        },
        "22.6": {
          "2": 2468,
          "3": 316,
        },
        "14.4": {
          "1": 1957,
          "2": 3,
        },
        "5.6": {
          "0": 803,
        },
        "11.6": {
          "1": 903,
        },
        "17.4": {
          "1": 11,
          "2": 3237,
        },
        "13.4": {
          "1": 1512,
        },
        "10.8": {
          "1": 692,
        },
        "11.2": {
          "1": 763,
        },
        "10.6": {
          "1": 689,
        },
        "29.6": {
          "3": 139,
          "4": 2,
        },
        "28.2": {
          "3": 319,
        },
        "31.2": {
          "3": 29,
          "4": 17,
        },
        "29.8": {
          "3": 148,
          "4": 8,
        },
        "26.8": {
          "3": 681,
        },
        "26.4": {
          "2": 2,
          "3": 874,
        },
        "8.8": {
          "0": 1,
          "1": 311,
        },
        "27.2": {
          "3": 593,
        },
        "27.4": {
          "3": 577,
        },
        "32.8": {
          "3": 5,
          "4": 6,
        },
        "9.6": {
          "1": 434,
        },
        "9.2": {
          "1": 418,
        },
        "8.6": {
          "0": 6,
          "1": 287,
        },
        "9.4": {
          "1": 416,
        },
        "28.4": {
          "3": 307,
        },
        "28.6": {
          "3": 266,
        },
        "27.8": {
          "3": 449,
        },
        "7.8": {
          "0": 160,
          "1": 65,
        },
        "29.2": {
          "3": 184,
          "4": 3,
        },
        "28.8": {
          "3": 218,
        },
        "7.6": {
          "0": 186,
          "1": 17,
        },
        "8.4": {
          "0": 29,
          "1": 247,
        },
        "6.2": {
          "0": 111,
        },
        "31.6": {
          "3": 23,
          "4": 13,
        },
        "30.2": {
          "3": 100,
          "4": 5,
        },
        "8.2": {
          "0": 54,
          "1": 194,
        },
        "29.4": {
          "3": 151,
        },
        "30.8": {
          "3": 38,
          "4": 5,
        },
        "7.4": {
          "0": 189,
          "1": 3,
        },
        "5.8": {
          "0": 79,
        },
        "30.4": {
          "3": 58,
          "4": 3,
        },
        "31.4": {
          "3": 25,
          "4": 7,
        },
        "30.6": {
          "3": 55,
          "4": 5,
        },
        "31.8": {
          "3": 20,
          "4": 12,
        },
        "32.4": {
          "3": 3,
          "4": 7,
        },
        "32.2": {
          "3": 9,
          "4": 10,
        },
        "34.6": {
          "4": 2,
        },
        "33.6": {
          "4": 6,
        },
        "33.2": {
          "3": 1,
          "4": 4,
        },
        "35.2": {
          "4": 2,
        },
        "32.6": {
          "3": 3,
          "4": 12,
        },
        "33.4": {
          "4": 5,
        },
        "34.2": {
          "4": 3,
        },
        "33.8": {
          "4": 2,
        },
        "34.4": {
          "4": 2,
        },
        "34.8": {
          "4": 1,
        },
      },
      KO: {
        "40": {
          "4": 95,
          "5": 178,
        },
        "41": {
          "4": 109,
          "5": 494,
        },
        "42": {
          "4": 57,
          "5": 1015,
        },
        "43": {
          "4": 26,
          "5": 1784,
        },
        "44": {
          "4": 5,
          "5": 2489,
          "6": 49,
        },
        "45": {
          "5": 3048,
          "6": 350,
        },
        "46": {
          "5": 3102,
          "6": 1066,
        },
        "47": {
          "5": 2545,
          "6": 2086,
        },
        "48": {
          "5": 1575,
          "6": 2966,
        },
        "49": {
          "5": 822,
          "6": 3487,
        },
        "50": {
          "5": 319,
          "6": 3244,
        },
        "51": {
          "5": 95,
          "6": 2778,
          "7": 21,
        },
        "52": {
          "5": 14,
          "6": 2074,
          "7": 107,
        },
        "53": {
          "5": 3,
          "6": 1270,
          "7": 215,
        },
        "54": {
          "6": 715,
          "7": 287,
        },
        "55": {
          "6": 383,
          "7": 242,
        },
        "56": {
          "6": 124,
          "7": 268,
        },
        "57": {
          "6": 57,
          "7": 183,
        },
        "58": {
          "6": 66,
          "7": 792,
          "8": 58,
        },
        "49.2": {
          "5": 655,
          "6": 3413,
        },
        "47.4": {
          "5": 2154,
          "6": 2416,
        },
        "54.8": {
          "6": 438,
          "7": 316,
        },
        "48.6": {
          "5": 1057,
          "6": 3277,
        },
        "41.8": {
          "4": 63,
          "5": 904,
        },
        "46.4": {
          "5": 2962,
          "6": 1492,
        },
        "40.6": {
          "4": 120,
          "5": 327,
        },
        "49.4": {
          "5": 595,
          "6": 3398,
        },
        "44.4": {
          "4": 1,
          "5": 2788,
          "6": 118,
        },
        "44.8": {
          "5": 3033,
          "6": 234,
        },
        "56.4": {
          "6": 96,
          "7": 213,
        },
        "43.4": {
          "4": 16,
          "5": 2103,
          "6": 2,
        },
        "57.6": {
          "6": 25,
          "7": 116,
        },
        "47.6": {
          "5": 1972,
          "6": 2594,
        },
        "52.2": {
          "5": 19,
          "6": 1875,
          "7": 101,
        },
        "49.8": {
          "5": 361,
          "6": 3320,
        },
        "43.6": {
          "4": 8,
          "5": 2151,
          "6": 11,
        },
        "48.8": {
          "5": 983,
          "6": 3391,
        },
        "42.6": {
          "4": 41,
          "5": 1489,
        },
        "50.6": {
          "5": 140,
          "6": 3074,
          "7": 2,
        },
        "45.2": {
          "5": 3122,
          "6": 441,
        },
        "45.6": {
          "5": 3223,
          "6": 748,
        },
        "45.8": {
          "5": 3048,
          "6": 939,
        },
        "45.4": {
          "5": 3110,
          "6": 559,
        },
        "51.4": {
          "5": 49,
          "6": 2520,
          "7": 45,
        },
        "50.2": {
          "5": 255,
          "6": 3259,
        },
        "55.2": {
          "6": 319,
          "7": 277,
        },
        "52.4": {
          "5": 6,
          "6": 1780,
          "7": 128,
        },
        "46.8": {
          "5": 2634,
          "6": 1942,
        },
        "48.4": {
          "5": 1272,
          "6": 3179,
        },
        "53.8": {
          "6": 843,
          "7": 264,
        },
        "46.2": {
          "5": 3004,
          "6": 1200,
        },
        "43.2": {
          "4": 26,
          "5": 1802,
        },
        "53.4": {
          "5": 1,
          "6": 1009,
          "7": 235,
        },
        "41.4": {
          "4": 68,
          "5": 682,
        },
        "55.4": {
          "6": 229,
          "7": 267,
        },
        "54.2": {
          "6": 667,
          "7": 290,
        },
        "43.8": {
          "4": 8,
          "5": 2336,
          "6": 26,
        },
        "54.6": {
          "6": 487,
          "7": 301,
        },
        "49.6": {
          "5": 494,
          "6": 3453,
        },
        "48.2": {
          "5": 1415,
          "6": 3010,
        },
        "54.4": {
          "6": 528,
          "7": 300,
        },
        "46.6": {
          "5": 2815,
          "6": 1658,
        },
        "50.8": {
          "5": 107,
          "6": 2862,
          "7": 7,
        },
        "53.6": {
          "6": 928,
          "7": 249,
        },
        "53.2": {
          "5": 1,
          "6": 1154,
          "7": 241,
        },
        "50.4": {
          "5": 187,
          "6": 3169,
          "7": 1,
        },
        "40.2": {
          "4": 101,
          "5": 221,
        },
        "51.2": {
          "5": 74,
          "6": 2689,
          "7": 23,
        },
        "44.6": {
          "5": 2933,
          "6": 172,
        },
        "51.6": {
          "5": 32,
          "6": 2307,
          "7": 68,
        },
        "41.6": {
          "4": 81,
          "5": 718,
        },
        "47.8": {
          "5": 1804,
          "6": 2822,
        },
        "39.4": {
          "4": 686,
          "5": 307,
        },
        "42.4": {
          "4": 34,
          "5": 1270,
        },
        "52.8": {
          "5": 3,
          "6": 1432,
          "7": 187,
        },
        "44.2": {
          "4": 2,
          "5": 2677,
          "6": 67,
        },
        "51.8": {
          "5": 28,
          "6": 2188,
          "7": 75,
        },
        "57.8": {
          "6": 18,
          "7": 102,
          "8": 1,
        },
        "55.8": {
          "6": 166,
          "7": 235,
        },
        "47.2": {
          "5": 2358,
          "6": 2236,
        },
        "55.6": {
          "6": 217,
          "7": 255,
        },
        "39.6": {
          "4": 110,
          "5": 110,
        },
        "42.8": {
          "4": 20,
          "5": 1558,
        },
        "42.2": {
          "4": 43,
          "5": 1127,
        },
        "52.6": {
          "5": 6,
          "6": 1530,
          "7": 172,
        },
        "56.2": {
          "6": 121,
          "7": 219,
        },
        "40.8": {
          "4": 104,
          "5": 399,
        },
        "56.6": {
          "6": 87,
          "7": 204,
        },
        "41.2": {
          "4": 80,
          "5": 556,
        },
        "39.8": {
          "4": 92,
          "5": 143,
        },
        "57.2": {
          "6": 40,
          "7": 174,
        },
        "57.4": {
          "6": 32,
          "7": 140,
        },
        "40.4": {
          "4": 125,
          "5": 283,
        },
        "56.8": {
          "6": 52,
          "7": 178,
        },
      },
      TD: {
        "7": {
          "0": 426,
        },
        "8": {
          "0": 477,
          "1": 865,
        },
        "9": {
          "1": 3324,
        },
        "10": {
          "1": 5929,
        },
        "11": {
          "1": 7849,
        },
        "12": {
          "1": 8146,
        },
        "13": {
          "1": 6441,
        },
        "14": {
          "1": 3846,
        },
        "15": {
          "1": 1737,
          "2": 214,
        },
        "16": {
          "1": 233,
          "2": 465,
        },
        "17": {
          "1": 6,
          "2": 207,
        },
        "12.4": {
          "1": 7512,
        },
        "11.2": {
          "1": 8174,
        },
        "13.4": {
          "1": 5372,
        },
        "11.6": {
          "1": 8417,
        },
        "10.2": {
          "1": 6369,
        },
        "12.6": {
          "1": 6971,
        },
        "10.6": {
          "1": 7287,
        },
        "9.8": {
          "1": 5190,
        },
        "13.8": {
          "1": 4434,
        },
        "14.6": {
          "1": 2505,
          "2": 29,
        },
        "14.8": {
          "1": 2109,
          "2": 96,
        },
        "9.6": {
          "1": 4648,
        },
        "9.4": {
          "1": 4255,
        },
        "16.2": {
          "1": 142,
          "2": 443,
        },
        "13.2": {
          "1": 5696,
        },
        "6.6": {
          "0": 1089,
        },
        "12.2": {
          "1": 7668,
        },
        "12.8": {
          "1": 6692,
        },
        "7.2": {
          "0": 596,
        },
        "11.8": {
          "1": 7893,
        },
        "9.2": {
          "1": 3757,
        },
        "10.8": {
          "1": 7499,
        },
        "10.4": {
          "1": 6827,
        },
        "8.2": {
          "0": 316,
          "1": 1443,
        },
        "7.4": {
          "0": 736,
          "1": 37,
        },
        "15.4": {
          "1": 911,
          "2": 381,
        },
        "13.6": {
          "1": 4722,
        },
        "8.6": {
          "0": 36,
          "1": 2419,
        },
        "8.4": {
          "0": 108,
          "1": 1994,
        },
        "11.4": {
          "1": 8111,
        },
        "8.8": {
          "0": 5,
          "1": 2787,
        },
        "14.2": {
          "1": 3346,
        },
        "16.4": {
          "1": 78,
          "2": 402,
        },
        "16.8": {
          "1": 15,
          "2": 267,
        },
        "7.8": {
          "0": 709,
          "1": 461,
        },
        "15.2": {
          "1": 1288,
          "2": 288,
        },
        "15.6": {
          "1": 679,
          "2": 448,
        },
        "7.6": {
          "0": 803,
          "1": 149,
        },
        "6.8": {
          "0": 348,
        },
        "14.4": {
          "1": 2932,
          "2": 1,
        },
        "17.6": {
          "2": 381,
        },
        "16.6": {
          "1": 37,
          "2": 326,
        },
        "17.4": {
          "2": 131,
        },
        "15.8": {
          "1": 409,
          "2": 493,
        },
        "17.2": {
          "1": 4,
          "2": 164,
        },
      },
      KONF: {
        "3": {
          "0": 598,
        },
        "4": {
          "0": 1914,
        },
        "5": {
          "0": 4792,
        },
        "6": {
          "0": 7804,
        },
        "7": {
          "0": 9251,
        },
        "8": {
          "0": 3341,
          "1": 4331,
        },
        "9": {
          "0": 1,
          "1": 4722,
        },
        "10": {
          "1": 1992,
        },
        "11": {
          "1": 635,
        },
        "12": {
          "1": 142,
        },
        "6.4": {
          "0": 8798,
        },
        "7.4": {
          "0": 8964,
          "1": 235,
        },
        "3.6": {
          "0": 1225,
        },
        "6.2": {
          "0": 8440,
        },
        "6.6": {
          "0": 9339,
        },
        "5.6": {
          "0": 6721,
        },
        "8.4": {
          "0": 501,
          "1": 6202,
        },
        "4.6": {
          "0": 3499,
        },
        "5.8": {
          "0": 7206,
        },
        "4.2": {
          "0": 2437,
        },
        "7.6": {
          "0": 7741,
          "1": 1171,
        },
        "5.4": {
          "0": 5975,
        },
        "8.2": {
          "0": 1541,
          "1": 5686,
        },
        "3.8": {
          "0": 1575,
        },
        "6.8": {
          "0": 9315,
        },
        "7.8": {
          "0": 5573,
          "1": 2693,
        },
        "8.6": {
          "0": 109,
          "1": 5912,
        },
        "5.2": {
          "0": 5359,
        },
        "4.8": {
          "0": 4036,
        },
        "9.6": {
          "1": 2892,
        },
        "10.4": {
          "1": 1317,
        },
        "7.2": {
          "0": 9299,
          "1": 11,
        },
        "2.6": {
          "0": 1221,
        },
        "4.4": {
          "0": 2903,
        },
        "9.4": {
          "1": 3493,
        },
        "9.2": {
          "1": 4051,
        },
        "8.8": {
          "0": 19,
          "1": 5304,
        },
        "11.4": {
          "1": 372,
        },
        "10.2": {
          "1": 1617,
        },
        "11.8": {
          "1": 217,
        },
        "10.8": {
          "1": 813,
        },
        "9.8": {
          "1": 2379,
        },
        "3.2": {
          "0": 782,
        },
        "3.4": {
          "0": 1007,
        },
        "11.2": {
          "1": 500,
        },
        "2.8": {
          "0": 442,
        },
        "10.6": {
          "1": 1015,
        },
        "11.6": {
          "1": 232,
        },
        "12.2": {
          "1": 338,
        },
      },
      LEWICA: {
        "10": {
          "1": 423,
        },
        "11": {
          "1": 1400,
        },
        "12": {
          "1": 3480,
        },
        "13": {
          "1": 6154,
        },
        "14": {
          "1": 8284,
        },
        "15": {
          "1": 7597,
          "2": 516,
        },
        "16": {
          "1": 2650,
          "2": 3433,
        },
        "17": {
          "1": 182,
          "2": 3234,
        },
        "18": {
          "2": 1559,
        },
        "19": {
          "2": 590,
        },
        "20": {
          "2": 751,
          "3": 4,
        },
        "14.8": {
          "1": 8093,
          "2": 207,
        },
        "17.6": {
          "1": 17,
          "2": 2212,
        },
        "16.8": {
          "1": 376,
          "2": 3587,
        },
        "16.2": {
          "1": 1753,
          "2": 3681,
        },
        "13.2": {
          "1": 7008,
        },
        "12.4": {
          "1": 4661,
        },
        "16.6": {
          "1": 643,
          "2": 3774,
        },
        "13.6": {
          "1": 7759,
        },
        "13.4": {
          "1": 7384,
        },
        "15.8": {
          "1": 3702,
          "2": 2957,
        },
        "15.4": {
          "1": 5777,
          "2": 1645,
        },
        "14.4": {
          "1": 8680,
          "2": 4,
        },
        "15.6": {
          "1": 4721,
          "2": 2320,
        },
        "12.6": {
          "1": 5252,
        },
        "12.8": {
          "1": 5731,
        },
        "11.8": {
          "1": 2893,
        },
        "12.2": {
          "1": 3991,
        },
        "14.2": {
          "1": 8384,
        },
        "17.2": {
          "1": 106,
          "2": 2799,
        },
        "10.8": {
          "1": 1130,
        },
        "14.6": {
          "1": 8197,
          "2": 51,
        },
        "10.2": {
          "1": 516,
        },
        "17.8": {
          "1": 4,
          "2": 1899,
        },
        "15.2": {
          "1": 6866,
          "2": 985,
        },
        "19.4": {
          "2": 380,
        },
        "13.8": {
          "1": 8192,
        },
        "11.6": {
          "1": 2508,
        },
        "16.4": {
          "1": 1146,
          "2": 3747,
        },
        "18.2": {
          "2": 1314,
        },
        "11.4": {
          "1": 2044,
        },
        "19.2": {
          "2": 436,
        },
        "18.8": {
          "2": 739,
        },
        "17.4": {
          "1": 42,
          "2": 2483,
        },
        "10.6": {
          "1": 888,
        },
        "9.8": {
          "0": 36,
          "1": 1037,
        },
        "18.4": {
          "2": 1131,
        },
        "10.4": {
          "1": 670,
        },
        "11.2": {
          "1": 1793,
        },
        "18.6": {
          "2": 878,
        },
        "19.6": {
          "2": 279,
        },
        "19.8": {
          "2": 235,
        },
      },
    },
    minimumMandatow: {
      KO: 4,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 1858,
      TD: 2149,
      LEWICA: 1912,
    },
    potencjalneMandaty: {
      KO: 75141,
      TD: 19535,
      LEWICA: 54865,
    },
    ostatnieMandaty: {
      KO: 73698,
      TD: 9239,
      LEWICA: 31024,
    },
    dawcyGlosow: {
      KO: 4644,
      TD: 6355,
      LEWICA: 4065,
    },
  },
  Koszalin: {
    procentyWOkreguSrednia: {
      PIS: 30.561137271353665,
      KO: 34.57322132550458,
      TD: 14.035844018882147,
      KONF: 7.376981479368652,
      LEWICA: 13.452815904890949,
    },
    odchylenieWOkregu: {
      PIS: 2.526439147024559,
      KO: 4.1473458715635765,
      TD: 2.013774734692939,
      LEWICA: 1.4662577969527786,
      KONF: 1.7398244572494754,
    },
    wykresRozkladu: {
      "84.7%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 1,
      },
      "11.1%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 1,
      },
      "2.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "0.6%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 0,
      },
      "0.4%": {
        KO: 3,
        LEWICA: 1,
        NIEDEMO: 2,
        TD: 2,
      },
      "0.3%": {
        KO: 2,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "0.2%": {
        KO: 4,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 1,
      },
      "0.1%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 4,
        TD: 1,
      },
      "0%": {
        KO: 3,
        LEWICA: 0,
        NIEDEMO: 3,
        TD: 2,
      },
    },
    wykresRozkladuDemo: {
      "85.8%": {
        DEMO: 5,
        NIEDEMO: 3,
      },
      "11.5%": {
        DEMO: 6,
        NIEDEMO: 2,
      },
      "2.7%": {
        DEMO: 4,
        NIEDEMO: 4,
      },
    },
    histogram: {
      PIS: {
        "25": {
          "2": 334,
        },
        "26": {
          "2": 924,
          "3": 18,
        },
        "27": {
          "2": 1802,
          "3": 362,
        },
        "28": {
          "2": 1891,
          "3": 1934,
        },
        "29": {
          "2": 1109,
          "3": 4434,
        },
        "30": {
          "2": 476,
          "3": 6123,
        },
        "31": {
          "2": 115,
          "3": 6502,
        },
        "32": {
          "2": 17,
          "3": 5548,
        },
        "33": {
          "3": 3917,
        },
        "34": {
          "3": 2330,
          "4": 1,
        },
        "35": {
          "3": 1243,
          "4": 11,
        },
        "36": {
          "3": 476,
          "4": 31,
        },
        "37": {
          "3": 167,
          "4": 48,
        },
        "38": {
          "3": 54,
          "4": 23,
        },
        "30.2": {
          "2": 333,
          "3": 6330,
        },
        "29.2": {
          "2": 942,
          "3": 4854,
        },
        "28.4": {
          "2": 1670,
          "3": 2843,
        },
        "28.6": {
          "2": 1491,
          "3": 3405,
        },
        "31.4": {
          "2": 70,
          "3": 6317,
        },
        "31.6": {
          "2": 43,
          "3": 5999,
        },
        "37.2": {
          "3": 140,
          "4": 43,
        },
        "25.6": {
          "2": 678,
          "3": 2,
        },
        "27.2": {
          "2": 1827,
          "3": 549,
        },
        "29.8": {
          "2": 550,
          "3": 5926,
        },
        "29.6": {
          "2": 663,
          "3": 5702,
        },
        "32.2": {
          "2": 7,
          "3": 5244,
        },
        "30.8": {
          "2": 163,
          "3": 6538,
        },
        "31.8": {
          "2": 23,
          "3": 5641,
        },
        "29.4": {
          "2": 815,
          "3": 5274,
        },
        "32.6": {
          "2": 3,
          "3": 4609,
        },
        "30.4": {
          "2": 285,
          "3": 6578,
        },
        "26.4": {
          "2": 1325,
          "3": 67,
        },
        "33.2": {
          "3": 3593,
        },
        "26.8": {
          "2": 1669,
          "3": 238,
        },
        "27.8": {
          "2": 1915,
          "3": 1477,
        },
        "32.4": {
          "2": 5,
          "3": 4926,
        },
        "26.2": {
          "2": 1183,
          "3": 37,
        },
        "27.6": {
          "2": 2002,
          "3": 1127,
        },
        "31.2": {
          "2": 70,
          "3": 6337,
        },
        "33.6": {
          "3": 2883,
        },
        "33.4": {
          "3": 3167,
        },
        "30.6": {
          "2": 209,
          "3": 6460,
        },
        "28.2": {
          "2": 1809,
          "3": 2348,
        },
        "28.8": {
          "2": 1258,
          "3": 3811,
        },
        "34.8": {
          "3": 1378,
          "4": 14,
        },
        "26.6": {
          "2": 1452,
          "3": 143,
        },
        "25.8": {
          "2": 854,
          "3": 7,
        },
        "34.2": {
          "3": 1955,
          "4": 2,
        },
        "32.8": {
          "2": 1,
          "3": 4286,
        },
        "33.8": {
          "3": 2583,
        },
        "34.6": {
          "3": 1662,
          "4": 11,
        },
        "34.4": {
          "3": 1837,
          "4": 5,
        },
        "36.8": {
          "3": 228,
          "4": 45,
        },
        "36.4": {
          "3": 354,
          "4": 45,
        },
        "35.4": {
          "3": 849,
          "4": 22,
        },
        "25.4": {
          "2": 563,
        },
        "36.6": {
          "3": 281,
          "4": 41,
        },
        "24.8": {
          "2": 269,
        },
        "35.6": {
          "3": 692,
          "4": 28,
        },
        "27.4": {
          "2": 1979,
          "3": 802,
        },
        "36.2": {
          "3": 437,
          "4": 45,
        },
        "35.2": {
          "3": 1023,
          "4": 25,
        },
        "24.6": {
          "2": 241,
        },
        "24.2": {
          "2": 614,
        },
        "24.4": {
          "2": 180,
        },
        "35.8": {
          "3": 606,
          "4": 36,
        },
        "37.8": {
          "3": 66,
          "4": 42,
        },
        "38.2": {
          "3": 98,
          "4": 160,
        },
        "25.2": {
          "2": 448,
        },
        "37.4": {
          "3": 82,
          "4": 39,
        },
        "37.6": {
          "3": 66,
          "4": 41,
        },
      },
      KO: {
        "25": {
          "2": 64,
        },
        "26": {
          "2": 135,
          "3": 12,
        },
        "27": {
          "2": 155,
          "3": 126,
        },
        "28": {
          "2": 174,
          "3": 395,
        },
        "29": {
          "2": 196,
          "3": 799,
        },
        "30": {
          "2": 137,
          "3": 1580,
        },
        "31": {
          "2": 51,
          "3": 2583,
        },
        "32": {
          "2": 12,
          "3": 3608,
        },
        "33": {
          "2": 1,
          "3": 4501,
        },
        "34": {
          "3": 5064,
          "4": 2,
        },
        "35": {
          "3": 5226,
          "4": 50,
        },
        "36": {
          "3": 4495,
          "4": 202,
        },
        "37": {
          "3": 3210,
          "4": 623,
        },
        "38": {
          "3": 1757,
          "4": 1001,
        },
        "39": {
          "3": 674,
          "4": 1121,
        },
        "40": {
          "3": 140,
          "4": 853,
        },
        "41": {
          "3": 22,
          "4": 463,
        },
        "42": {
          "3": 1,
          "4": 222,
        },
        "43": {
          "4": 87,
        },
        "44": {
          "4": 23,
        },
        "45": {
          "4": 14,
        },
        "46": {
          "4": 2,
        },
        "47": {
          "4": 1,
        },
        "34.2": {
          "3": 5226,
          "4": 2,
        },
        "39.8": {
          "3": 236,
          "4": 987,
        },
        "34.4": {
          "3": 5191,
          "4": 6,
        },
        "35.4": {
          "3": 5085,
          "4": 60,
        },
        "31.8": {
          "2": 16,
          "3": 3317,
        },
        "30.6": {
          "2": 89,
          "3": 2074,
        },
        "37.2": {
          "3": 2989,
          "4": 689,
        },
        "39.4": {
          "3": 376,
          "4": 1085,
        },
        "33.2": {
          "3": 4669,
        },
        "36.6": {
          "3": 3829,
          "4": 413,
        },
        "35.2": {
          "3": 5125,
          "4": 54,
        },
        "36.4": {
          "3": 4163,
          "4": 341,
        },
        "35.6": {
          "3": 4925,
          "4": 96,
        },
        "38.4": {
          "3": 1193,
          "4": 1164,
        },
        "32.6": {
          "2": 2,
          "3": 4253,
        },
        "37.8": {
          "3": 2133,
          "4": 992,
        },
        "33.8": {
          "3": 5084,
        },
        "37.4": {
          "3": 2729,
          "4": 793,
        },
        "27.4": {
          "2": 203,
          "3": 194,
        },
        "28.2": {
          "2": 216,
          "3": 464,
        },
        "31.2": {
          "2": 52,
          "3": 2788,
        },
        "34.6": {
          "3": 5228,
          "4": 6,
        },
        "27.6": {
          "2": 181,
          "3": 260,
        },
        "30.8": {
          "2": 82,
          "3": 2240,
        },
        "30.2": {
          "2": 132,
          "3": 1742,
        },
        "30.4": {
          "2": 121,
          "3": 1929,
        },
        "36.2": {
          "3": 4372,
          "4": 255,
        },
        "33.6": {
          "3": 4919,
        },
        "32.2": {
          "2": 6,
          "3": 3803,
        },
        "35.8": {
          "3": 4698,
          "4": 138,
        },
        "32.4": {
          "2": 6,
          "3": 3875,
        },
        "33.4": {
          "3": 4943,
        },
        "38.6": {
          "3": 1075,
          "4": 1153,
        },
        "29.8": {
          "2": 158,
          "3": 1312,
        },
        "31.6": {
          "2": 25,
          "3": 3200,
        },
        "37.6": {
          "3": 2490,
          "4": 906,
        },
        "36.8": {
          "3": 3517,
          "4": 517,
        },
        "38.2": {
          "3": 1476,
          "4": 1074,
        },
        "31.4": {
          "2": 40,
          "3": 2896,
        },
        "40.6": {
          "3": 33,
          "4": 641,
        },
        "32.8": {
          "3": 4277,
        },
        "29.4": {
          "2": 185,
          "3": 1088,
        },
        "34.8": {
          "3": 5101,
          "4": 24,
        },
        "40.4": {
          "3": 68,
          "4": 749,
        },
        "38.8": {
          "3": 771,
          "4": 1145,
        },
        "39.6": {
          "3": 261,
          "4": 996,
        },
        "28.6": {
          "2": 200,
          "3": 635,
        },
        "29.6": {
          "2": 173,
          "3": 1216,
        },
        "40.8": {
          "3": 23,
          "4": 561,
        },
        "28.8": {
          "2": 190,
          "3": 732,
        },
        "40.2": {
          "3": 92,
          "4": 809,
        },
        "27.8": {
          "2": 211,
          "3": 300,
        },
        "41.4": {
          "3": 9,
          "4": 359,
        },
        "42.2": {
          "4": 184,
        },
        "29.2": {
          "2": 230,
          "3": 950,
        },
        "43.6": {
          "4": 33,
          "5": 1,
        },
        "25.4": {
          "2": 83,
        },
        "28.4": {
          "2": 194,
          "3": 525,
        },
        "39.2": {
          "3": 470,
          "4": 1172,
        },
        "42.6": {
          "4": 147,
        },
        "41.6": {
          "3": 2,
          "4": 287,
        },
        "42.4": {
          "4": 149,
        },
        "41.2": {
          "3": 12,
          "4": 449,
        },
        "27.2": {
          "2": 192,
          "3": 140,
        },
        "26.8": {
          "2": 170,
          "3": 72,
        },
        "43.8": {
          "4": 32,
        },
        "26.4": {
          "2": 142,
          "3": 48,
        },
        "25.6": {
          "2": 132,
          "3": 2,
        },
        "41.8": {
          "3": 2,
          "4": 271,
        },
        "24.4": {
          "2": 37,
        },
        "44.2": {
          "4": 22,
        },
        "26.2": {
          "2": 124,
          "3": 14,
        },
        "26.6": {
          "2": 168,
          "3": 47,
        },
        "24.2": {
          "1": 1,
          "2": 184,
        },
        "24.8": {
          "2": 64,
        },
        "24.6": {
          "2": 54,
        },
        "25.8": {
          "2": 97,
          "3": 2,
        },
        "44.4": {
          "4": 19,
          "5": 1,
        },
        "25.2": {
          "2": 88,
        },
        "42.8": {
          "4": 93,
        },
        "44.6": {
          "4": 19,
        },
        "45.8": {
          "4": 4,
        },
        "43.4": {
          "4": 61,
        },
        "43.2": {
          "4": 65,
        },
        "44.8": {
          "4": 13,
        },
        "46.2": {
          "4": 3,
        },
        "45.4": {
          "4": 8,
        },
        "45.2": {
          "4": 6,
          "5": 1,
        },
        "45.6": {
          "4": 5,
        },
      },
      TD: {
        "9": {
          "0": 869,
          "1": 49,
        },
        "10": {
          "0": 77,
          "1": 775,
        },
        "11": {
          "1": 2252,
        },
        "12": {
          "1": 4662,
        },
        "13": {
          "1": 7521,
        },
        "14": {
          "1": 8345,
        },
        "15": {
          "1": 7385,
        },
        "16": {
          "1": 4887,
        },
        "17": {
          "1": 2472,
          "2": 1,
        },
        "18": {
          "1": 891,
          "2": 83,
        },
        "19": {
          "1": 168,
          "2": 129,
        },
        "20": {
          "1": 17,
          "2": 245,
        },
        "13.4": {
          "1": 7987,
        },
        "13.8": {
          "1": 8337,
        },
        "17.2": {
          "1": 2071,
          "2": 9,
        },
        "15.2": {
          "1": 6954,
        },
        "16.6": {
          "1": 3217,
        },
        "11.8": {
          "1": 4251,
        },
        "11.2": {
          "1": 2793,
        },
        "12.6": {
          "1": 6395,
        },
        "15.4": {
          "1": 6377,
        },
        "13.2": {
          "1": 7795,
        },
        "12.4": {
          "1": 5704,
        },
        "12.2": {
          "1": 5172,
        },
        "10.8": {
          "0": 6,
          "1": 1913,
        },
        "16.4": {
          "1": 3805,
        },
        "14.8": {
          "1": 7819,
        },
        "15.8": {
          "1": 5500,
        },
        "11.6": {
          "1": 3673,
        },
        "15.6": {
          "1": 5841,
        },
        "14.4": {
          "1": 8299,
        },
        "14.2": {
          "1": 8492,
        },
        "16.2": {
          "1": 4486,
        },
        "12.8": {
          "1": 6837,
        },
        "10.6": {
          "0": 7,
          "1": 1573,
        },
        "18.4": {
          "1": 520,
          "2": 117,
        },
        "11.4": {
          "1": 3098,
        },
        "13.6": {
          "1": 8156,
        },
        "14.6": {
          "1": 8181,
        },
        "16.8": {
          "1": 2755,
        },
        "17.8": {
          "1": 1124,
          "2": 55,
        },
        "18.6": {
          "1": 363,
          "2": 143,
        },
        "10.4": {
          "0": 19,
          "1": 1287,
        },
        "18.2": {
          "1": 686,
          "2": 97,
        },
        "10.2": {
          "0": 48,
          "1": 1017,
        },
        "9.6": {
          "0": 181,
          "1": 318,
        },
        "17.4": {
          "1": 1769,
          "2": 18,
        },
        "18.8": {
          "1": 258,
          "2": 130,
        },
        "9.4": {
          "0": 238,
          "1": 185,
        },
        "9.2": {
          "0": 205,
          "1": 99,
        },
        "9.8": {
          "0": 145,
          "1": 563,
        },
        "19.4": {
          "1": 67,
          "2": 88,
        },
        "19.2": {
          "1": 94,
          "2": 132,
        },
        "17.6": {
          "1": 1455,
          "2": 36,
        },
        "19.6": {
          "1": 32,
          "2": 84,
        },
        "19.8": {
          "1": 18,
          "2": 78,
        },
      },
      KONF: {
        "3": {
          "0": 1113,
        },
        "4": {
          "0": 1221,
        },
        "5": {
          "0": 3533,
        },
        "6": {
          "0": 6751,
        },
        "7": {
          "0": 9450,
        },
        "8": {
          "0": 8891,
        },
        "9": {
          "0": 5765,
          "1": 209,
        },
        "10": {
          "0": 965,
          "1": 1845,
        },
        "11": {
          "1": 839,
        },
        "12": {
          "1": 225,
        },
        "8.6": {
          "0": 7164,
          "1": 9,
        },
        "3.8": {
          "0": 948,
        },
        "9.6": {
          "0": 2545,
          "1": 1294,
        },
        "6.6": {
          "0": 8598,
        },
        "9.2": {
          "0": 4760,
          "1": 500,
        },
        "8.4": {
          "0": 7949,
        },
        "6.2": {
          "0": 7425,
        },
        "7.6": {
          "0": 9639,
        },
        "7.8": {
          "0": 9258,
        },
        "5.6": {
          "0": 5414,
        },
        "9.8": {
          "0": 1586,
          "1": 1612,
        },
        "7.2": {
          "0": 9486,
        },
        "7.4": {
          "0": 9529,
        },
        "10.6": {
          "0": 54,
          "1": 1385,
        },
        "4.4": {
          "0": 1935,
        },
        "4.2": {
          "0": 1529,
        },
        "5.2": {
          "0": 4219,
        },
        "8.2": {
          "0": 8592,
        },
        "5.8": {
          "0": 6062,
        },
        "8.8": {
          "0": 6579,
          "1": 72,
        },
        "9.4": {
          "0": 3680,
          "1": 943,
        },
        "10.4": {
          "0": 165,
          "1": 1632,
        },
        "4.8": {
          "0": 2947,
        },
        "5.4": {
          "0": 4715,
        },
        "6.8": {
          "0": 8945,
        },
        "6.4": {
          "0": 8021,
        },
        "3.4": {
          "0": 646,
        },
        "10.2": {
          "0": 478,
          "1": 1803,
        },
        "4.6": {
          "0": 2402,
        },
        "11.2": {
          "1": 719,
        },
        "10.8": {
          "0": 8,
          "1": 1147,
        },
        "11.4": {
          "1": 484,
        },
        "12.6": {
          "1": 217,
        },
        "3.6": {
          "0": 804,
        },
        "3.2": {
          "0": 386,
        },
        "11.6": {
          "1": 378,
        },
        "12.2": {
          "1": 145,
        },
        "12.4": {
          "1": 96,
        },
        "11.8": {
          "1": 289,
        },
      },
      LEWICA: {
        "10": {
          "0": 53,
          "1": 585,
        },
        "11": {
          "1": 2710,
        },
        "12": {
          "1": 6780,
        },
        "13": {
          "1": 10431,
        },
        "14": {
          "1": 10072,
        },
        "15": {
          "1": 6254,
        },
        "16": {
          "1": 2525,
        },
        "17": {
          "1": 654,
        },
        "12.4": {
          "1": 8405,
        },
        "12.6": {
          "1": 9302,
        },
        "12.8": {
          "1": 10020,
        },
        "15.8": {
          "1": 3089,
        },
        "17.2": {
          "1": 511,
          "2": 9,
        },
        "15.2": {
          "1": 5371,
        },
        "13.4": {
          "1": 10920,
        },
        "11.6": {
          "1": 5003,
        },
        "13.6": {
          "1": 10655,
        },
        "13.2": {
          "1": 10503,
        },
        "14.2": {
          "1": 9358,
        },
        "14.6": {
          "1": 7936,
        },
        "10.6": {
          "0": 1,
          "1": 1587,
        },
        "15.4": {
          "1": 4564,
        },
        "13.8": {
          "1": 10400,
        },
        "14.4": {
          "1": 8700,
        },
        "11.2": {
          "1": 3284,
        },
        "14.8": {
          "1": 6906,
        },
        "16.8": {
          "1": 901,
        },
        "17.8": {
          "1": 516,
          "2": 111,
        },
        "11.4": {
          "1": 4000,
        },
        "11.8": {
          "1": 5750,
        },
        "12.2": {
          "1": 7642,
        },
        "15.6": {
          "1": 3695,
        },
        "16.4": {
          "1": 1528,
        },
        "10.4": {
          "0": 15,
          "1": 1155,
        },
        "16.2": {
          "1": 2018,
        },
        "10.2": {
          "0": 19,
          "1": 823,
        },
        "10.8": {
          "0": 3,
          "1": 2115,
        },
        "16.6": {
          "1": 1195,
        },
        "17.6": {
          "1": 240,
          "2": 12,
        },
        "9.8": {
          "0": 572,
          "1": 701,
        },
        "17.4": {
          "1": 397,
          "2": 4,
        },
      },
    },
    minimumMandatow: {
      KO: 1,
      TD: 0,
      LEWICA: 0,
    },
    zagrozoneMandaty: {
      KO: 2604,
      TD: 1668,
      LEWICA: 1538,
    },
    potencjalneMandaty: {
      KO: 43585,
      TD: 9593,
      LEWICA: 2747,
    },
    ostatnieMandaty: {
      KO: 51169,
      TD: 6474,
      LEWICA: 3731,
    },
    dawcyGlosow: {
      KO: 6855,
      TD: 5541,
      LEWICA: 6060,
    },
  },
  Szczecin: {
    procentyWOkreguSrednia: {
      PIS: 28.609084415743144,
      KO: 38.77004479934519,
      TD: 12.50889393895673,
      KONF: 7.502779954357488,
      LEWICA: 12.609196891597438,
    },
    odchylenieWOkregu: {
      PIS: 2.1198153435825584,
      KO: 2.4061054823864407,
      TD: 2.013774734692939,
      LEWICA: 1.135856841313191,
      KONF: 1.661711513728957,
    },
    wykresRozkladu: {
      "43%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 1,
      },
      "21.1%": {
        KO: 5,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 2,
      },
      "16%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 4,
        TD: 1,
      },
      "13%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 1,
      },
      "1.8%": {
        KO: 4,
        LEWICA: 1,
        NIEDEMO: 5,
        TD: 2,
      },
      "1.4%": {
        KO: 6,
        LEWICA: 1,
        NIEDEMO: 3,
        TD: 2,
      },
      "1.3%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 2,
      },
      "1.1%": {
        KO: 6,
        LEWICA: 2,
        NIEDEMO: 3,
        TD: 1,
      },
      "0.7%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 1,
      },
      "0.3%": {
        KO: 4,
        LEWICA: 2,
        NIEDEMO: 4,
        TD: 2,
      },
      "0.1%": {
        KO: 5,
        LEWICA: 2,
        NIEDEMO: 5,
        TD: 0,
      },
    },
    wykresRozkladuDemo: {
      "50.5%": {
        DEMO: 8,
        NIEDEMO: 4,
      },
      "45.7%": {
        DEMO: 7,
        NIEDEMO: 5,
      },
      "3.8%": {
        DEMO: 9,
        NIEDEMO: 3,
      },
    },
    histogram: {
      PIS: {
        "24": {
          "3": 402,
        },
        "25": {
          "3": 1280,
        },
        "26": {
          "3": 3103,
          "4": 77,
        },
        "27": {
          "3": 3724,
          "4": 2211,
        },
        "28": {
          "3": 1578,
          "4": 6631,
        },
        "29": {
          "3": 205,
          "4": 8175,
        },
        "30": {
          "3": 4,
          "4": 6516,
        },
        "31": {
          "4": 3704,
        },
        "32": {
          "4": 1575,
          "5": 68,
        },
        "33": {
          "4": 418,
          "5": 149,
        },
        "34": {
          "4": 59,
          "5": 71,
        },
        "35": {
          "4": 11,
          "5": 72,
        },
        "28.8": {
          "3": 384,
          "4": 8022,
        },
        "25.8": {
          "3": 2763,
          "4": 46,
        },
        "27.6": {
          "3": 2421,
          "4": 5050,
        },
        "27.2": {
          "3": 3256,
          "4": 3176,
        },
        "27.8": {
          "3": 2041,
          "4": 5879,
        },
        "29.2": {
          "3": 110,
          "4": 8005,
        },
        "25.6": {
          "3": 2231,
          "4": 23,
        },
        "29.8": {
          "3": 8,
          "4": 7067,
        },
        "29.4": {
          "3": 61,
          "4": 7804,
        },
        "31.6": {
          "4": 2337,
          "5": 17,
        },
        "28.4": {
          "3": 868,
          "4": 7663,
        },
        "29.6": {
          "3": 21,
          "4": 7353,
        },
        "30.4": {
          "4": 5295,
        },
        "30.2": {
          "3": 2,
          "4": 5803,
        },
        "26.8": {
          "3": 3825,
          "4": 1460,
        },
        "23.8": {
          "3": 301,
        },
        "27.4": {
          "3": 2809,
          "4": 4116,
        },
        "25.2": {
          "3": 1545,
        },
        "28.6": {
          "3": 566,
          "4": 8124,
        },
        "33.8": {
          "4": 104,
          "5": 91,
        },
        "31.4": {
          "4": 2792,
          "5": 2,
        },
        "30.8": {
          "4": 4378,
        },
        "25.4": {
          "3": 1854,
          "4": 4,
        },
        "28.2": {
          "3": 1171,
          "4": 7197,
        },
        "31.2": {
          "4": 3164,
        },
        "24.4": {
          "3": 621,
        },
        "33.4": {
          "4": 203,
          "5": 119,
        },
        "30.6": {
          "4": 4883,
        },
        "24.6": {
          "3": 793,
        },
        "26.2": {
          "3": 3490,
          "4": 224,
        },
        "24.2": {
          "3": 508,
        },
        "26.6": {
          "3": 4003,
          "4": 863,
        },
        "32.2": {
          "4": 1245,
          "5": 80,
        },
        "26.4": {
          "3": 3797,
          "4": 480,
        },
        "31.8": {
          "4": 1937,
          "5": 37,
        },
        "32.8": {
          "4": 583,
          "5": 128,
        },
        "32.6": {
          "4": 768,
          "5": 171,
        },
        "24.8": {
          "3": 997,
        },
        "33.2": {
          "4": 299,
          "5": 130,
        },
        "23.6": {
          "3": 211,
        },
        "34.6": {
          "4": 10,
          "5": 43,
        },
        "32.4": {
          "4": 973,
          "5": 135,
        },
        "34.2": {
          "4": 39,
          "5": 59,
        },
        "23.4": {
          "2": 8,
          "3": 575,
        },
        "34.8": {
          "4": 20,
          "5": 36,
        },
        "33.6": {
          "4": 128,
          "5": 94,
        },
        "34.4": {
          "4": 26,
          "5": 42,
        },
      },
      KO: {
        "33": {
          "4": 108,
          "5": 7,
        },
        "34": {
          "4": 326,
          "5": 143,
        },
        "35": {
          "4": 455,
          "5": 838,
        },
        "36": {
          "4": 195,
          "5": 2892,
        },
        "37": {
          "4": 15,
          "5": 5304,
        },
        "38": {
          "5": 7389,
          "6": 25,
        },
        "39": {
          "5": 7397,
          "6": 398,
        },
        "40": {
          "5": 5027,
          "6": 1427,
        },
        "41": {
          "5": 1812,
          "6": 2412,
        },
        "42": {
          "5": 362,
          "6": 1909,
        },
        "43": {
          "5": 23,
          "6": 835,
        },
        "44": {
          "5": 3,
          "6": 328,
        },
        "45": {
          "6": 97,
          "7": 4,
        },
        "46": {
          "6": 50,
          "7": 24,
        },
        "40.8": {
          "5": 2403,
          "6": 2371,
        },
        "40.4": {
          "5": 3639,
          "6": 2024,
        },
        "37.2": {
          "4": 12,
          "5": 5943,
        },
        "42.4": {
          "5": 138,
          "6": 1469,
        },
        "37.8": {
          "5": 6989,
          "6": 8,
        },
        "39.4": {
          "5": 6690,
          "6": 733,
        },
        "39.6": {
          "5": 6314,
          "6": 972,
        },
        "40.2": {
          "5": 4433,
          "6": 1683,
        },
        "34.8": {
          "4": 476,
          "5": 647,
        },
        "35.8": {
          "4": 278,
          "5": 2372,
        },
        "41.8": {
          "5": 571,
          "6": 2060,
        },
        "41.4": {
          "5": 1035,
          "6": 2308,
        },
        "41.6": {
          "5": 741,
          "6": 2205,
        },
        "38.4": {
          "5": 7622,
          "6": 120,
        },
        "38.2": {
          "4": 1,
          "5": 7353,
          "6": 60,
        },
        "35.2": {
          "4": 484,
          "5": 1146,
        },
        "38.8": {
          "5": 7759,
          "6": 303,
        },
        "40.6": {
          "5": 3011,
          "6": 2075,
        },
        "37.6": {
          "4": 3,
          "5": 6819,
          "6": 1,
        },
        "33.8": {
          "4": 258,
          "5": 80,
        },
        "36.4": {
          "4": 98,
          "5": 3843,
        },
        "41.2": {
          "5": 1417,
          "6": 2367,
        },
        "36.2": {
          "4": 126,
          "5": 3352,
        },
        "44.6": {
          "6": 145,
          "7": 3,
        },
        "39.2": {
          "5": 7101,
          "6": 552,
        },
        "36.6": {
          "4": 67,
          "5": 4472,
        },
        "39.8": {
          "5": 5834,
          "6": 1191,
        },
        "37.4": {
          "4": 7,
          "5": 6378,
        },
        "44.4": {
          "5": 1,
          "6": 186,
          "7": 2,
        },
        "34.6": {
          "4": 467,
          "5": 485,
        },
        "38.6": {
          "5": 7829,
          "6": 186,
        },
        "36.8": {
          "4": 35,
          "5": 4812,
        },
        "45.4": {
          "6": 46,
          "7": 6,
        },
        "34.4": {
          "4": 410,
          "5": 288,
        },
        "43.6": {
          "5": 10,
          "6": 465,
        },
        "34.2": {
          "4": 372,
          "5": 194,
        },
        "43.2": {
          "5": 15,
          "6": 711,
        },
        "44.8": {
          "6": 127,
          "7": 3,
        },
        "32.8": {
          "4": 333,
          "5": 12,
        },
        "35.4": {
          "4": 422,
          "5": 1542,
        },
        "42.8": {
          "5": 45,
          "6": 1159,
        },
        "35.6": {
          "4": 379,
          "5": 1899,
        },
        "42.2": {
          "5": 225,
          "6": 1650,
        },
        "33.4": {
          "4": 169,
          "5": 27,
        },
        "33.6": {
          "4": 220,
          "5": 44,
        },
        "43.4": {
          "5": 11,
          "6": 621,
        },
        "42.6": {
          "5": 98,
          "6": 1227,
        },
        "44.2": {
          "6": 239,
        },
        "45.8": {
          "6": 20,
          "7": 6,
        },
        "33.2": {
          "4": 149,
          "5": 21,
        },
        "43.8": {
          "5": 2,
          "6": 366,
        },
        "45.2": {
          "6": 58,
          "7": 4,
        },
        "45.6": {
          "6": 29,
          "7": 6,
        },
      },
      TD: {
        "8": {
          "1": 444,
        },
        "9": {
          "1": 1429,
        },
        "10": {
          "1": 3475,
        },
        "11": {
          "1": 6213,
        },
        "12": {
          "1": 8240,
        },
        "13": {
          "1": 7897,
          "2": 434,
        },
        "14": {
          "1": 1715,
          "2": 4564,
        },
        "15": {
          "1": 1,
          "2": 3388,
        },
        "16": {
          "2": 1317,
        },
        "17": {
          "2": 372,
        },
        "18": {
          "2": 80,
        },
        "14.8": {
          "1": 14,
          "2": 4063,
        },
        "10.8": {
          "1": 5577,
        },
        "14.2": {
          "1": 844,
          "2": 4962,
        },
        "11.4": {
          "1": 7375,
        },
        "10.4": {
          "1": 4451,
        },
        "12.8": {
          "1": 8461,
          "2": 129,
        },
        "13.2": {
          "1": 7190,
          "2": 1042,
        },
        "14.4": {
          "1": 345,
          "2": 4870,
        },
        "13.4": {
          "1": 5684,
          "2": 1907,
        },
        "12.4": {
          "1": 8673,
        },
        "8.2": {
          "1": 565,
        },
        "12.6": {
          "1": 8695,
          "2": 3,
        },
        "11.8": {
          "1": 8047,
        },
        "13.8": {
          "1": 2795,
          "2": 3964,
        },
        "9.8": {
          "1": 2904,
        },
        "15.6": {
          "2": 2099,
        },
        "15.2": {
          "2": 2926,
        },
        "11.6": {
          "1": 7810,
        },
        "11.2": {
          "1": 6785,
        },
        "9.6": {
          "1": 2421,
        },
        "8.4": {
          "1": 764,
        },
        "10.6": {
          "1": 5028,
        },
        "8.8": {
          "1": 1262,
        },
        "13.6": {
          "1": 4315,
          "2": 3081,
        },
        "9.2": {
          "1": 1633,
        },
        "14.6": {
          "1": 92,
          "2": 4513,
        },
        "9.4": {
          "1": 2077,
        },
        "16.2": {
          "2": 1089,
        },
        "12.2": {
          "1": 8657,
        },
        "17.2": {
          "2": 272,
        },
        "7.6": {
          "1": 281,
        },
        "7.8": {
          "1": 354,
        },
        "10.2": {
          "1": 3831,
        },
        "15.4": {
          "2": 2512,
        },
        "16.8": {
          "2": 518,
        },
        "17.8": {
          "2": 122,
        },
        "15.8": {
          "2": 1667,
        },
        "7.4": {
          "0": 342,
          "1": 465,
        },
        "8.6": {
          "1": 914,
        },
        "16.6": {
          "2": 666,
        },
        "16.4": {
          "2": 826,
        },
        "17.4": {
          "2": 221,
        },
        "18.2": {
          "2": 76,
        },
        "18.6": {
          "2": 74,
          "3": 4,
        },
        "17.6": {
          "2": 137,
        },
        "18.4": {
          "2": 37,
        },
      },
      KONF: {
        "4": {
          "0": 891,
        },
        "5": {
          "0": 2773,
        },
        "6": {
          "0": 6361,
        },
        "7": {
          "0": 1794,
          "1": 7739,
        },
        "8": {
          "1": 9707,
        },
        "9": {
          "1": 6533,
        },
        "10": {
          "1": 2793,
        },
        "11": {
          "1": 777,
        },
        "12": {
          "1": 149,
        },
        "10.6": {
          "1": 1431,
        },
        "6.8": {
          "0": 4426,
          "1": 4767,
        },
        "5.8": {
          "0": 5479,
        },
        "8.4": {
          "1": 8617,
        },
        "9.8": {
          "1": 3355,
        },
        "7.4": {
          "0": 30,
          "1": 9946,
        },
        "7.2": {
          "0": 434,
          "1": 9703,
        },
        "3.4": {
          "0": 1312,
        },
        "10.4": {
          "1": 1846,
        },
        "9.6": {
          "1": 4084,
        },
        "9.2": {
          "1": 5659,
        },
        "8.6": {
          "1": 8098,
        },
        "10.2": {
          "1": 2296,
        },
        "6.6": {
          "0": 6961,
          "1": 1548,
        },
        "6.4": {
          "0": 7714,
          "1": 178,
        },
        "8.8": {
          "1": 7316,
        },
        "7.8": {
          "1": 10198,
        },
        "5.2": {
          "0": 3442,
        },
        "5.4": {
          "0": 4204,
        },
        "8.2": {
          "1": 9286,
        },
        "7.6": {
          "1": 10035,
        },
        "9.4": {
          "1": 4985,
        },
        "5.6": {
          "0": 4953,
        },
        "11.4": {
          "1": 453,
        },
        "4.6": {
          "0": 1869,
        },
        "3.8": {
          "0": 659,
        },
        "6.2": {
          "0": 7264,
          "1": 1,
        },
        "4.4": {
          "0": 1450,
        },
        "11.2": {
          "1": 577,
        },
        "10.8": {
          "1": 1058,
        },
        "4.8": {
          "0": 2329,
        },
        "3.6": {
          "0": 533,
        },
        "4.2": {
          "0": 1199,
        },
        "12.4": {
          "1": 172,
          "2": 10,
        },
        "11.6": {
          "1": 312,
        },
        "11.8": {
          "1": 204,
        },
        "12.2": {
          "1": 90,
        },
      },
      LEWICA: {
        "10": {
          "1": 950,
        },
        "11": {
          "1": 5183,
        },
        "12": {
          "1": 12324,
        },
        "13": {
          "1": 12722,
          "2": 473,
        },
        "14": {
          "1": 2252,
          "2": 4392,
        },
        "15": {
          "1": 10,
          "2": 1557,
        },
        "16": {
          "2": 475,
        },
        "11.4": {
          "1": 7901,
        },
        "12.2": {
          "1": 13513,
        },
        "11.8": {
          "1": 11012,
        },
        "12.8": {
          "1": 13915,
          "2": 127,
        },
        "10.8": {
          "1": 3889,
        },
        "11.6": {
          "1": 9517,
        },
        "13.8": {
          "1": 4015,
          "2": 3996,
        },
        "13.4": {
          "1": 8664,
          "2": 2265,
        },
        "9.8": {
          "1": 1366,
        },
        "12.4": {
          "1": 14063,
        },
        "13.6": {
          "1": 6112,
          "2": 3428,
        },
        "13.2": {
          "1": 10954,
          "2": 1227,
        },
        "10.6": {
          "1": 2908,
        },
        "11.2": {
          "1": 6412,
        },
        "14.6": {
          "1": 159,
          "2": 2863,
        },
        "14.2": {
          "1": 1086,
          "2": 3995,
        },
        "15.6": {
          "2": 479,
        },
        "12.6": {
          "1": 14221,
          "2": 12,
        },
        "15.4": {
          "2": 736,
        },
        "14.4": {
          "1": 426,
          "2": 3501,
        },
        "10.2": {
          "1": 1397,
        },
        "10.4": {
          "1": 1980,
        },
        "14.8": {
          "1": 48,
          "2": 2152,
        },
        "15.8": {
          "2": 283,
        },
        "15.2": {
          "2": 1040,
        },
      },
    },
    minimumMandatow: {
      KO: 4,
      TD: 0,
      LEWICA: 1,
    },
    zagrozoneMandaty: {
      KO: 2505,
      TD: 2911,
      LEWICA: 1683,
    },
    potencjalneMandaty: {
      KO: 48625,
      TD: 57799,
      LEWICA: 76379,
    },
    ostatnieMandaty: {
      KO: 36709,
      TD: 26063,
      LEWICA: 23749,
    },
    dawcyGlosow: {
      KO: 6485,
      TD: 6241,
      LEWICA: 3030,
    },
  },
};